import * as React from "react";
import { DiagramEngine, PortWidget } from "@projectstorm/react-diagrams";
import styled from '@emotion/styled';
import {BoolSwitchNodeModel, ConnectionNodeModel, EndNodeModel} from "./FlowControlModels";
import _ from "lodash";
import {CmlPortLabel} from "../ports/CmlPortLabelWidget";
import { S as SCml } from '../ports/CmlPortLabelWidget'

const S = {
    Node: styled.div<{ background: string | undefined; selected: boolean }>`
		background-color: ${p => p.background};
		border-radius: 5px;
		font-family: sans-serif;
		color: white;
		border: solid 2px black;
		overflow: visible;
		font-size: 11px;
		border: solid 2px ${p => (p.selected ? 'rgb(0,192,255)' : 'black')};
	`,
    Ports: styled.div`
		display: flex;
		background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
	`,
    PortsContainer: styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		&:first-child {
			margin-right: 10px;
		}

		&:only-child {
			margin-right: 0px;
		}
	`,
    TriangleLeft: styled.div`
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-right: 40px solid rgb(160,224,59);
        border-bottom: 20px solid transparent;
    `,
};

export interface EnginePropsBase {
    engine: DiagramEngine;
}

export interface EndNodeProps extends EnginePropsBase{
    node: EndNodeModel;
}

export class EndNodeWidget extends React.Component<EndNodeProps>{
    // @ts-ignore
    generatePort = port => {
        return <CmlPortLabel engine={this.props.engine} port={port} key={port.id} />;
    };

    render() {
        return (
            <S.Node
                data-default-node-name={this.props.node.getOptions().name}
                selected={this.props.node.isSelected()}
                background={this.props.node.getOptions().isInput ? 'rgb(238,33,39)' : 'rgb(192,255,0)'}>
                <S.Ports>
                    <S.PortsContainer>
                        <S.PortsContainer>{_.map(this.props.node.getPorts(), this.generatePort)}</S.PortsContainer>
                    </S.PortsContainer>
                </S.Ports>
            </S.Node>
        );
    }
}

export interface ConnectionNodeProps extends EnginePropsBase{
    node: ConnectionNodeModel,
}

export class ConnectionNodeWidget extends React.Component<ConnectionNodeProps>{
    render() {

        // @ts-ignore
        const widget = <PortWidget port={this.props.node.getPort('conn')} engine={this.props.engine}/>
        return <div style={{position: 'relative'}}>
            <SCml.Port style={{position: 'absolute', top: '-9px', left: '-9px'}} background={this.props.node.isSelected() ? 'rgb(0,192,255)' : '#7f7f7f'} size={'14px'}/>
            {widget}
          </div>
    }
}

export interface BoolSwitchNodeProps extends EnginePropsBase {
    node: BoolSwitchNodeModel;
}

export class BoolSwitchNodeWidget extends React.Component<BoolSwitchNodeProps> {
    render() {
        const size = 80;
        return <div style={{ position: 'relative' }}>
            <svg height={size} width={size}>
                <polygon points={`2,${(size -2) / 2} ${size/6 + 4}, ${(size -2) / 2}`}
                         style={{ fill:'#7f7f7f', stroke: '#7f7f7f', strokeWidth: 3 }} />
                <polygon points={`${size - size / 6},${size / 3} ${size}, ${size / 3}`}
                         style={{ fill:'#7f7f7f', stroke: '#7f7f7f', strokeWidth: 3 }} />
                <polygon points={`${size - size / 6},${size - size / 3} ${size}, ${size - size / 3}`}
                         style={{ fill:'#7f7f7f', stroke: '#7f7f7f', strokeWidth: 3 }} />
                <polygon points={`${size/6 + 2},${(size - 2) / 2} ${size - size / 6},${size / 7} ${size - size / 6},${size - (size / 7)}`}
                         style={{ fill:'lime', stroke: this.props.node.isSelected() ? 'rgb(0,192,255)' : '#7f7f7f', strokeWidth: 3 }} />
            </svg>
            <PortWidget
                style={{
                    top: size / 2 - 10,
                    left: -10,
                    position: 'absolute'
                }}
                // @ts-ignore
                port={this.props.node.getPort('boolIn')}
                engine={this.props.engine}>
                <SCml.Port background={'rgb(90,255,24)'} size={'14px'}/>
            </PortWidget>
            <PortWidget
                style={{
                    top: size / 3 - 9,
                    left: size - 3,
                    position: 'absolute'
                }}
                // @ts-ignore
                port={this.props.node.getPort('processTrue')}
                engine={this.props.engine}>
                <SCml.Port background={'rgb(209,105,36)'} size={'14px'}/>
            </PortWidget>
            <PortWidget
                style={{
                    top: size - size / 3 - 9,
                    left: size - 3,
                    position: 'absolute'
                }}
                // @ts-ignore
                port={this.props.node.getPort('processFalse')}
                engine={this.props.engine}>
                <SCml.Port background={'rgb(209,105,36)'} size={'14px'}/>
            </PortWidget>
            <div style={{
                top: size / 3 - 9,
                left: size - 27,
                position: 'absolute'
            }}>
                T
            </div>
            <div style={{
                top: size - size / 3 - 12,
                left: size - 27,
                position: 'absolute'
                }}>
                F
            </div>
        </div>;
    }
}
