import {CanView} from "../../Main";
import {IDispatchingAttributesCustom} from "@cml/types";
import ItemsSelector from "../../ItemsSelector";
import OrderEdit from "../../orders/Edit";
import {SmallOrderPreview} from "../../orders/Preview";
import {Orders, Roles, Attributes, DataRow} from "@cml/models";
import React, { useState } from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getAttributes, getOrders} from "../../../fetchApi/dispatching";
import { swrOption } from "../DataRow";
import store from "../../../redux/store";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import { fillOrder } from "../components/datarowBase";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrOrder({ index, attribute, singleRowEditMode }: Props) {
  const [orderToShow, setOrderToShow] = useState<Orders | null>(null);
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const { orders, isValidating: ordersValidating } = getOrders(
    { params: { where: { isConfirmed: 0 }}}, );
  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes(
    'price', );

  const orderId = useSelector(selectDataRowValue(index, singleRowEditMode, 'orderId'));

  if (!orders)
    return <div>Loading</div>

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canOrderNumber && dispatchOther.canOrderNumber}>
    <Modal onHide={() => {}} show={orderToShow !== null}>
      <ModalHeader>
        <Modal.Title>Upozornění</Modal.Title>
      </ModalHeader>
      <ModalBody>
        Chcete automaticky vyplnit data ze zakázky ? Kliknutím na "Ne" se pouze přiřadí číslo zakázky k danému řádku.
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setOrderToShow(null)}> Ne</Button>
        <Button onClick={() => {
          const order = orderToShow;
          store.dispatch(setDataRowValue(index, singleRowEditMode, {
            ...fillOrder(singleRowEditMode
              ? store.getState().dispatching.singleEditRow as DataRow
              : store.getState().dispatching.dataRows[index],
              priceAttributes, order as Orders)
          }))
          setOrderToShow(null)
        }
        }> Ano</Button>
      </ModalFooter>
    </Modal>
    <td className='text-right' >
      <ItemsSelector externList={orders}
                     newEditComponent={ OrderEdit }
                     preview={SmallOrderPreview}
                     itemsToFetch={'orders'}
                     overrideToggleFunc={(item: Orders) => {
                       if (item.id === 0) {
                         return <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                           Neznámá
                         </div>
                       }
                       if (item.customNumber) {
                         return <div style={{ display: 'inline-block' }}
                                     title={`${item.label} ${item.loading} - ${item.unloading}`}>{item.customNumber}</div>;
                       }
                       if (item.foreignBookKeepingNumber)
                         return item.foreignBookKeepingNumber +
                           (item.customNumber && item.customNumber !== '' ? ` - ${item.customNumber}` : '' );
                       if (item.receivedOrderNumber !== '')
                         return item.receivedOrderNumber +
                           (item.customNumber && item.customNumber !== '' ? ` - ${item.customNumber}` : '' );
                       else
                         return item.label
                     }}
                     bsSize="xsmall"
                     appendItemsToStart={[
                       {id: 0, label: 'Nevybrán'},
                     ]}
                     filterEnable
                     value={'label'}
                     onSelected={(order: Orders) => {
                       const originOrderId = orderId;
                       if (order.id === 0) {
                         if (originOrderId !== order.id && originOrderId)
                           store.dispatch(setDataRowValue(index, singleRowEditMode, {
                             orderId: order.id === 0 ? null : order.id,
                           }))
                         return;
                       } else {
                         store.dispatch(setDataRowValue(index, singleRowEditMode, {
                           orderId: order.id === 0 ? null : order.id,
                         }))
                       }
                       setOrderToShow(order);
                     }}
                     defaultSelectedId={orderId}/>
    </td>
  </CanView>
}