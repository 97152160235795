import * as React from 'react';
import { NodeModel, NodeModelGenerics } from '@projectstorm/react-diagrams';
import {RightAnglePortModel} from "../../Defaults";
import { BasePositionModelOptions, DeserializeEvent } from '@projectstorm/react-canvas-core';
import {CmlPortModel} from "../ports/CmlPortModel";


export interface EndNodeModelOptions extends BasePositionModelOptions {
    name?: string;
    isInput?: boolean;
}

export interface EndNodeModelGenerics extends NodeModelGenerics {
    OPTIONS: EndNodeModelOptions;
}

class BoolSwitchNodeModel extends NodeModel<NodeModelGenerics> {
    constructor() {
        super({
            type: 'boolSwitch'
        });
        this.addPort(new CmlPortModel(true, 'boolIn', '', false));
        this.addPort(new CmlPortModel(false, 'processTrue', '', false));
        this.addPort(new CmlPortModel(false, 'processFalse', '', false));
    }
}

class ConnectionNodeModel extends NodeModel<NodeModelGenerics> {
    constructor() {
        super({
            type: 'connection'
        });
        this.addPort(new CmlPortModel(false, 'conn', '', true));
    }
}

class EndNodeModel extends NodeModel<EndNodeModelGenerics>{
    constructor(name: string, isInput: boolean);
    constructor(options?: EndNodeModelOptions);
    constructor(options: any = {}, isInput?: boolean) {
        if (typeof options === 'string') {
            options = {
                name: options,
                isInput: isInput,
            };
        }
        super({
            type: 'endnode',
            name: 'Untitled',
            //isInput: false,
            ...options,
        });
        // During deserialize it creates two Ports because there is switch based on isInput.
        // If this happen factory is creating this node with empty options and port is later added
        // So constructor should not create port in this case
        if (typeof options.isInput !== 'undefined') {
            // @ts-ignore
            this.addPort(new CmlPortModel(this.getOptions().isInput, `${this.getOptions().isInput? 'in' : 'out'}-1`, this.getOptions().isInput ? 'Konec' : 'Start', false));
        }

    }

    serialize(): any {
        return {
            ...super.serialize(),
            isInput: this.options.isInput,
        }
    }

    deserialize(event: DeserializeEvent<this>): void {
        super.deserialize(event);
        this.options.isInput = event.data.isInput;
    }
}

export {
    EndNodeModel,
    BoolSwitchNodeModel,
    ConnectionNodeModel,
}