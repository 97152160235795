import React, { FormEvent } from 'react';
import request, {AxiosResponse} from 'axios';
import {
  Col, DropdownButton, Form, FormGroup, MenuItem, Row, ControlLabel, FormControl, Checkbox
} from "react-bootstrap";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {BaseEdit, BaseEditProps, BaseEditState, IBaseEdit} from "../CommonListContainerInterfaces";
import ItemsSelector from "../ItemsSelector";
import {DiagramEditState} from "../diagrams/Edit";
import {CanvasModel} from "@projectstorm/react-canvas-core";
import TaskEditablePreview, {TaskEditablePreviewState, TaskObject} from './EditablePreview';
import {FileObject} from "../other/FileViewer";
import { AuthenticateState } from "@cml/redux-store";
import {connect} from "react-redux";
import {Response, ResponseError} from "superagent";

export interface TaskEditState extends BaseEditState, TaskEditablePreviewState {
  name: string;
  actualStateName: string;
  diagramModelId: number | null;
  diagram: {
    diagramModel: undefined | ReturnType<CanvasModel['serialize']>,
  };
  userLinks: {
    id: number,
    name: string,
    surname: string,
  }[] | null;
}

interface TaskProps extends BaseEditProps{
  authenticate: AuthenticateState,
}

const mapStateToProps = (state: { authenticate: AuthenticateState })  => {
  return {
    authenticate: state.authenticate,
  }
};

class TaskEdit extends BaseEdit<TaskProps, TaskEditState> implements IBaseEdit{
  constructor(props: TaskProps) {
    super(props, 'Úkol');
    super.setListeners(this);
    this.state = {
      ...this.state,
      actualStateName: '',
      actualNodeId: '',
      name: '',
      diagramModelId: null,
      attachments: {
        listOfFiles: [],
      },
      taskState: {
        tasksDone: [],
      },
      taskChangesHistory: [],
      userLinks: null
    };

    if (typeof this.props.location.state !== 'undefined') {
      this.state = {
        ...this.state,
        ...this.props.location.state as object
      }
    }
  }

  receivedItemData(res: AxiosResponse<any>): void {
    const { authenticate } = this.props;
    this.setState({
      // @ts-ignore
      disableSubmitButton: res.data.userLinks.filter((o) => o.userId === authenticate.loggedUser.id).length === 0 && !authenticate.actualUserRole.isAdmin,
      requestActive: false,
      ...res.data,
      attachments: res.data.attachments === null ? {
        listOfFiles: [],
      } : res.data.attachments,
    });
  }

  handleSubmit(e: FormEvent<Form> | MouseEvent | undefined, data?: TaskEditState) {
    if(e)
      e.preventDefault();
    if (typeof this.state.id === 'undefined') {
      this.finalizeSubmit(data ? data :{
        ...this.state,
      }, object => {
        this.setState({
          ...object,
          attachments: (object as TaskEditState).attachments === null ? {
            listOfFiles: [],
          } : (object as TaskEditState).attachments,
        });
      });
    } else {
      this.saveFiles();
    }
  }

  saveFiles(){
    const formData = new FormData();
    const listOfFiles = [] as FileObject[];
    const { attachments } = this.state;
    if (attachments) {
      this.setState({ submitActive: true });
      attachments.listOfFiles.forEach((attach: File | FileObject) => {
        if ((attach as FileObject).url) {
          listOfFiles.push(attach);
        } else {
          formData.append(attach.name, attach as File);
        }
      });

      formData.append('values', JSON.stringify({
        ...attachments,
        listOfFiles,
        itemId: this.state.id,
        itemTableName: 'tasks',
        date: null,
      }));

      if (typeof attachments.id === 'undefined') {
        request.post('/items/items', formData)
            .then((res) => {
              this.setState({
                attachments: res.data,
              });
              this.finalizeSubmit({
                ...this.state,
              }, object => {
                this.setState({
                  ...object,
                  attachments: (object as TaskEditState).attachments === null ? {
                    listOfFiles: [],
                  } : (object as TaskEditState).attachments,
                });
              });
            }).catch((err: ResponseError) => {
          this.setState({ submitActive: false });
        })
      } else {
        request.put('/items/items', formData)
            .then((res) => {
              /*
                Little hack ... The attachments is updating here in state but has no dependency to editablePreview
                But for end user doesnt matter because editablePreview will remain same until its changed from it and then
                callback is used and attachments here is updated again
              */
              this.setState({
                attachments: res.data,
              });
              this.finalizeSubmit({
                ...this.state,
              });
            }).catch((err: ResponseError) => {
              this.setState({ submitActive: false });
            })
      }
    }
  }

  renderTaskType() {
    if (!this.props.match) {
      return null;
    }
    if (this.props.match.params.id === 'new') {
      return <Col sm={4}>
        <h4>Typ úkolu</h4>
        <FormGroup>
          <ItemsSelector
              value={'name'}
              defaultQuery={{attributes: ['name', 'id'] }}
              onSelected={(item: DiagramEditState) => this.setState({ diagramModelId: item.id as number })}
              defaultSelectedId={this.state.diagramModelId}
              itemsToFetch={'diagrams/tasks'}/>
        </FormGroup>
      </Col>;
    }
    return null;
  }

  renderBody() {
    return <>
      <Row>
        <Col sm={4}>
          <h4>Název</h4>
          <FormGroup>
            <FormControl type='text'
                         disabled={this.state.disableSubmitButton}
                         value={this.state.name}
                         onChange={(e) => this.setState({ name: (e.target as HTMLTextAreaElement).value })}
                         placeholder='Název'
            />
          </FormGroup>
        </Col>
        {
          this.renderTaskType()
        }
      </Row>

      {
        // @ts-ignore
        this.state.diagram ? <TaskEditablePreview
            disabled={this.state.disableSubmitButton}
            onItemChange={(item, saveRequested) => {
              this.setState({ ...this.state, ...item });
              if (saveRequested) {
                this.handleSubmit(undefined, { ...this.state, ...item });
              }
            }}
            diagramModel={this.state.diagram ? (this.state.diagram.diagramModel as ReturnType<CanvasModel['serialize']>) : undefined}
            baseState={this.state as TaskObject}/> : null
      }
      {
        this.state.disableSubmitButton ? <div className='text-center' style={{color: 'darkRed', marginTop: '15px',marginBottom: '-15px', fontSize: '16px', fontWeight: 'bold'}}>
          Nelze upravit protože tento úkol řeší jiná pověřená osoba
        </div> : null
      }
    </>
  }
}

// @ts-ignore
export default connect(mapStateToProps)(TaskEdit);