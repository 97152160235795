import React, {Component} from 'react';
import {BaseEditPropTypes} from '../CommonListContainer';
import {SketchPicker} from 'react-color';
import {
  Col,
  Form,
  Row,
  ControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  Popover,
  OverlayTrigger,
  Button, Dropdown, MenuItem, DropdownButton,
} from "react-bootstrap";
import SubmitButton from "../../loginsrc/SubmitButton";
import request from 'axios';
import {BackHeader} from "../Setting";
import PropTypes from 'prop-types';
import reactCSS from 'reactcss';
import HeaderGenerator from "../dispatching/components/HeaderGenerator";
import DataRow from "../dispatching/DataRow";
import {CanView} from "../Main";
import FontAwesome from "react-fontawesome";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import ItemsSelector from "../ItemsSelector";
import AddressBookEdit from "../addressBook/Edit";
import {show} from "react-notification-system-redux";
import store from "../../redux/store";
import {dropDownOrder} from "../dispatching/components/DispatchingFilter";
import {useSelector} from "react-redux";
import {AuthenticateState} from "@cml/redux-store";

const dispatchLabelMap = {
  isVehicleWrittingEnabled: 'Používat stroje označené pro subdodavatele',
  userAsAddressBook: 'Použít dodavatele místo strojníka',
  canNote: 'Používat poznámku',
  canOrderNumber: 'Používat číslo zakázky',
  canVehicle: 'Používat sloupec se stroji',
  canRealVehicle: 'Používat sloupec se skutečnými stroji dodavatele',
  canUser: 'Používat sloupec se strojníky/dodavateli',
  canTool: 'Používat sloupec s vybavením',
  canLoading: 'Používat sloupce nákladky',
  canUnLoading: 'Používat sloupce výkladky',
  canCompany: 'Používat sloupec "Firma"',
  canConstruction: 'Používat sloupec "Stavba"',
  canContactPerson: 'Používat sloupec "Kontaktní osoba"',
  canTime: 'Používat sloupce pro záznam docházky (od, do, přestávka)',
  canFraction: 'Používat sloupec "Frakce"',
  canCalculation: 'Používat sloupce pro kalkulaci',
  canUseCosts: 'Používat sloupce pro náklady (např. subdodavatel), a zisk',
  disableUsageInSalary: 'Zakázat atribut ve mzdách',
  disableUsageInInvoice: 'Zakázat atribut v přehledu fakturace',
};

function OrderingWrapper ({item, onChange}) {

  const authenticate = useSelector(
    (state) => state.authenticate);

  return <FormGroup>
    <ControlLabel>Řazení dle</ControlLabel>
    <div>
      <DropdownButton id='validState'
                      disabled={!authenticate.actualUserRole.isAdmin}
                      onSelect={(key) => {
                        let obj = item;
                        obj['baseOrdering'] = key;
                        onChange(obj);
                      }}
                      title={dropDownOrder[item['baseOrdering']]}>
        {Object.keys(dropDownOrder).map((key) => <MenuItem key={key} eventKey={key}>{
          dropDownOrder[key]}</MenuItem>)}
      </DropdownButton>
    </div>
  </FormGroup>
}

class dispatchingEditCustom extends Component{

    constructor(props) {
      super(props);
      this.state = {
        company: '',
        construction: '',
        loading: '',
        unloading: '',
        fraction: '',
        note: '',
        from: 0,
        to: 0,
        break: 0,
        contactUserId: null,
        userRaw: '',
        vehicleRaw: '',
        priceEq: [],
        userId: 0,
        userId_a: 0,
        isConfirmed: 0,
        orderForVis: 0,
        addressBookId: 0,
        vehicleId: 0,
        attributeId: 0,
        costs: 0,
        tools: [],
        date: '',
        orderNumber: null,
        activeEmailTemplate: 0,
      }
    }

  static CheckCustom(item) {
    return false;
  }

    render() {
      const activeEmailTemplate = this.state.activeEmailTemplate;
      const orderEmailTemplates = this.props.item['orderEmailTemplates'] ? this.props.item['orderEmailTemplates'] : [];
      return [
        <Row style={{ marginTop: '10px', marginBottom: '20px'}}>
          <Col sm={6}>
            {
              Object.keys(dispatchLabelMap).map((key) => {
                if (key === 'canUseCosts') {
                  if (!this.props.item['canCalculation']) {
                    return null;
                  }
                }
                return <Checkbox checked={this.props.item[key]}
                                 key={key}
                                 onChange={(e) => {
                                   let obj = this.props.item;
                                   obj[key] = e.target.checked;
                                   this.props.onChange(obj);
                                 }}
                                 style={{paddingLeft: '10px', paddingRight: '10px', marginTop: '-7px'}}>
                  {dispatchLabelMap[key]}
                </Checkbox>
              })
            }
          </Col>
          <Col sm={6}>
            <CanView visible={!this.props.item.userAsAddressBook}>
              <h4 className='text-center'>
                Šablona SMS při stavu ze Založeno do Aktivní
              </h4>
              <textarea style={{ width: '100%', minHeight: '140px', padding:'10px'}}
                        onChange={(e) => {
                          let obj = this.props.item;
                          obj['smsTemplateText'] = e.target.value;
                          this.props.onChange(obj);
                        }}
                        value={this.props.item['smsTemplateText']} />
              <p>
                <b>%date%</b> - Datum, <b>%on%</b> - Číslo zakázky, <b>%onf%</b> - Číslo objednávky,
                <b>%oml%</b> - Místo setkání, <b>%onc%</b> - Vlastní číslo zakazky,
                <b>%veh%</b> - Spz stroje, <b>%user%</b> - Strojník, <b>%tool%</b> - Vybavení, <b>%suser%</b> - Odesílatel,
                <b>%load%</b> - Nákladka, <b>%unlo%</b> - Výkladka, <b>%com%</b> - Firma, <b>%con%</b> - Stavba, <b>%cusn%</b> - Kontaktní osoba jméno,
                <b>%cust%</b> - Kontaktní osoba číslo, <b>%tf%</b> - Čas od, <b>%tt%</b> - Čas do, <b>%brea%</b> - Přestávka, <b>%frac%</b> - Frakce, <b>%note%</b> - Poznámka
              </p>
            </CanView>
            <CanView visible={this.props.item.canContactPerson}>
              <h4 className='text-center'>
                Šablona SMS pro kontaktní osobu
              </h4>
              <textarea style={{ width: '100%', minHeight: '140px', padding:'10px'}}
                        onChange={(e) => {
                          let obj = this.props.item;
                          obj['smsContactTemplateText'] = e.target.value;
                          this.props.onChange(obj);
                        }}
                        value={this.props.item['smsContactTemplateText']} />
              <p>
                <b>%date%</b> - Datum, <b>%on%</b> - Číslo zakázky, <b>%merge%</b> - Spz stroje strojník kontakt a od do, <b>%veh%</b> - Stroje, <b>%user%</b> - Strojníci, <b>%con%</b> - Stavba
              </p>
            </CanView>
            <CanView visible={this.props.item.userAsAddressBook}>
              <Dropdown
                onSelect={(e) => {
                  if (e === -1) {
                    let obj = this.props.item;
                    obj['orderEmailTemplates'] = obj['orderEmailTemplates'] ? obj['orderEmailTemplates'] : [];
                    obj['orderEmailTemplates'].push({
                      name: `Šablona ${obj['orderEmailTemplates'].length + 1}`,
                      text: '',
                      note: '',
                    });
                    this.props.onChange(obj);
                    this.setState({
                      activeEmailTemplate: obj['orderEmailTemplates'].length - 1,
                    })
                  } else {
                    this.setState({
                      activeEmailTemplate: e,
                    })
                  }
                }}
                id="prices">
                <Dropdown.Toggle>
                  {orderEmailTemplates.length === 0 ? 'Vytvořte šablonu' : orderEmailTemplates[activeEmailTemplate].name}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    Object.keys(orderEmailTemplates).map(key =>
                      <MenuItem eventKey={key} key={key}>
                        {orderEmailTemplates[key].name}
                      </MenuItem>
                    )
                  }
                  <MenuItem eventKey={-1}>
                    <FontAwesome style={{ fontSize: '12px', marginRight: '10px', }} name='fas fa-plus'/>
                    Nová šablona
                  </MenuItem>
                </Dropdown.Menu>
              </Dropdown>
              {
                orderEmailTemplates.length > 0 ? <>
                  <button className='text-right transparentButton' onClick={(e) => {
                    e.preventDefault();
                    let obj = this.props.item;
                    obj['orderEmailTemplates'].splice(activeEmailTemplate, 1);
                    this.props.onChange(obj);
                    this.setState({
                      activeEmailTemplate: obj['orderEmailTemplates'].length - 1,
                    });
                  }}>
                    <FontAwesome style={{fontSize:'25px', color: 'black', }} name='times' />
                  </button>
                  <h4 className='text-center'>
                    Jméno šablony
                  </h4>
                  <FormControl type="text"
                               value={orderEmailTemplates[activeEmailTemplate].name}
                               placeholder="Jméno šablony"
                               //onFocus={() => this.setState({focusedInput: formObject.email})}
                               onChange={(e) => {
                                 let obj = this.props.item;
                                 obj['orderEmailTemplates'][activeEmailTemplate].name = e.target.value;
                                 this.props.onChange(obj);
                               }}
                  />
                  <h4 className='text-center'>
                    Šablona objednávky při stavu ze Založeno do Aktivní (s obj.)
                  </h4>
                  <textarea style={{ width: '100%', minHeight: '140px', padding:'10px'}}
                    onChange={(e) => {
                      let obj = this.props.item;
                      obj['orderEmailTemplates'][activeEmailTemplate].text = e.target.value;
                      this.props.onChange(obj);
                      }}
                    value={orderEmailTemplates[activeEmailTemplate].text} />
                  <p>
                    <b>%date%</b> - Datum, <b>%on%</b> - Číslo zakázky, <b>%veh%</b> - Spz stroje, <b>%user%</b> - Strojník, <b>%tool%</b> - Vybavení,
                    <b>%load%</b> - Nákladka, <b>%unlo%</b> - Výkladka, <b>%com%</b> - Firma, <b>%con%</b> - Stavba, <b>%cusn%</b> - Kontaktní osoba jméno,
                    <b>%cust%</b> - Kontaktní osoba číslo, <b>%tf%</b> - Čas od, <b>%tt%</b> - Čas do, <b>%brea%</b> - Přestávka, <b>%frac%</b> - Frakce
                    , <b>%ccp%</b> - Cena dodavatele (náklady)
                  </p>
                  <h4 className='text-center'>
                    Poznámka objednávky
                  </h4>
                  <textarea style={{ width: '100%', minHeight: '140px', padding:'10px'}}
                    onChange={(e) => {
                      let obj = this.props.item;
                      obj['orderEmailTemplates'][activeEmailTemplate].note = e.target.value;
                      this.props.onChange(obj);
                    }}
                    value={orderEmailTemplates[activeEmailTemplate].note} />
                </> : null
              }

            </CanView>
            <FormGroup>
              <ControlLabel>Počet hodin povoleno vymazat řádek od založení</ControlLabel>
              <FormControl type="number"
                           value={this.props.item['enableDeletePeriod']}
                           placeholder="Počet hodin povoleno mazání"
                           title="Počet hodin povoleno vymazat řádek od založení ikdyž nemá uživatel práva řádek odstranit"
                           onChange={(e) => {
                             let obj = this.props.item;
                             obj['enableDeletePeriod'] = e.target.value === "0" ? undefined : e.target.value;
                             this.props.onChange(obj);
                           }}
              />
            </FormGroup>
            <OrderingWrapper item={this.props.item} onChange={(item) => this.props.onChange(item)} />
          </Col>
        </Row>,
        <Row style={{marginTop: '20px'}}>
          <div className='dataTable editFormMarginButtonTop' >
            <table>
              <tbody>
                <HeaderGenerator unresolvedMode={false} attribute={{customOptions: this.props.item}}/>
              </tbody>
            </table>
          </div>
        </Row>
      ]
    }
}

class priceEditCustom extends Component{

  handleRangeEdit = (name, value, index) => {
    const { item } = this.props;
    item.rangeList[index][name] = value;
    this.props.onChange(item);
  };


  static CheckCustom(item) {
    return false;
  }

  render() {
    const { item } = this.props;
    item.rangeList = item.rangeList ? item.rangeList : [];
    return <Row>
      <Col sm={3} smOffset={3} componentClass={ ControlLabel }>
        Název pro účetní systém
      </Col>
      <Col sm={6}>
        <FormGroup>
          <FormControl type='text'
                       value={item.nameForAccounting}
                       maxLength={10}
                       onChange={(e) => {
                         let obj = item;
                         obj['nameForAccounting'] = e.target.value;
                         this.props.onChange(obj);
                       }}
                       placeholder='Název pro účetní systém'
          />
        </FormGroup>
      </Col>
      <Col sm={3} smOffset={3} componentClass={ ControlLabel }>
        Výchozí cena
      </Col>
      <Col sm={6}>
        <FormGroup>
          <FormControl type='number'
                       value={item.defaultPrice}
                       onChange={(e) => {
                         let obj = item;
                         obj['defaultPrice'] = e.target.value;
                         this.props.onChange(obj);
                       }}
                       placeholder='Výchozí cena'
          />
        </FormGroup>
      </Col>
      <Col sm={6} smOffset={6}>
        <Checkbox checked={item.isHourAttribute}
                  onChange={(e) => {
                    let obj = item;
                    obj['isHourAttribute'] = e.target.checked;
                    this.props.onChange(obj);
                  }}
                  style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          Použít výsledný počet hodin
        </Checkbox>
        <Checkbox checked={item.isKmAttribute}
                  onChange={(e) => {
                    let obj = item;
                    obj['isKmAttribute'] = e.target.checked;
                    this.props.onChange(obj);
                  }}
                  style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          Atribut pro kilometry
        </Checkbox>
        <CanView visible={item.isKmAttribute}>
          <Checkbox checked={item.useKmRange}
                    onChange={(e) => {
                      let obj = item;
                      obj['useKmRange'] = e.target.checked;
                      this.props.onChange(obj);
                    }}
                    style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            Použít různé ceny pro různé vzdálenosti
          </Checkbox>
        </CanView>
         <CanView visible={item.useKmRange}>
           <div style={{marginBottom: '20px'}} className='dataTable'>
             <table>
               <tbody>
                <tr>
                  <th>Od</th>
                  <th>Do</th>
                  <th>Výchozí cena</th>
                  <th>Zafixovat cenu</th>
                  <th>Nasobit výsledným počtem hodin</th>
                  <th/>
                </tr>
                {
                  item.rangeList.map((range,index) => {
                    return <tr>
                      <td><FormControl type='number'
                                       style={{backgroundColor: range.fixed ? '#ffc846' : ''}}
                                       value={range.from}
                                       onChange={(e) => this.handleRangeEdit('from', e.target.value, index)}/>
                      </td>
                      <td><FormControl type='number'
                                       style={{backgroundColor: range.fixed ? '#ffc846' : ''}}
                                       value={range.to}
                                       onChange={(e) => this.handleRangeEdit('to', e.target.value, index)}/>
                      </td>
                      <td><FormControl type='number'
                                       style={{backgroundColor: range.fixed ? '#ffc846' : ''}}
                                       value={range.defaultPrice}
                                       onChange={(e) => this.handleRangeEdit('defaultPrice', e.target.value, index)}/>
                      </td>
                      <td>
                        <Checkbox checked={range.fixed}
                                  onChange={(e) => this.handleRangeEdit('fixed', e.target.checked, index)}
                                  style={{ paddingLeft: '10px', paddingRight: '10px' }}/>
                      </td>
                      <td>
                        <Checkbox checked={range.multipleByHours}
                                  disabled={!range.fixed}
                                  onChange={(e) => this.handleRangeEdit('multipleByHours', e.target.checked, index)}
                                  style={{ paddingLeft: '10px', paddingRight: '10px' }}/>
                      </td>
                      <td><button onClick={() => {
                        let list = item.rangeList.slice();
                        list.splice(index, 1);
                        item.rangeList = list;
                        this.props.onChange(item);
                      }} className='transparentButton'><FontAwesome name='fas fa-times'/></button></td>
                    </tr>;
                  })
                }
               </tbody>
             </table>
           </div>
           <Button onClick={() => {
             let list = item.rangeList.slice();
             list.push({
               from: 0,
               to: 0,
               defaultPrice: 0,
               fixed: false,
               multipleByHours: false,
             });
             item.rangeList = list;
             this.props.onChange(item);
           }}>Nový řádek</Button>
         </CanView>
      </Col>
    </Row>
  }
}

class attendanceEditCustom extends Component {

  static CheckCustom(item) {
    return false;
  }

  render() {
    const { item } = this.props;
    return <Row>
      <FormGroup>
        <Col sm={3} smOffset={3} componentClass={ ControlLabel }>
          Placené ?
        </Col>
        <Col sm={6}>
          <Checkbox checked={item.paidAttendance}
                    onChange={(e) => {
                      let obj = item;
                      obj['paidAttendance'] = e.target.checked;
                      this.props.onChange(obj);
                    }}
                    style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          </Checkbox>
        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={3} smOffset={3} componentClass={ ControlLabel }>
          Použít jako výchozí při přesčasech ?
        </Col>
        <Col sm={6}>
          <Checkbox checked={item.overtimeAttribute}
                    onChange={(e) => {
                      let obj = item;
                      obj['overtimeAttribute'] = e.target.checked;
                      this.props.onChange(obj);
                    }}
                    style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          </Checkbox>
        </Col>
      </FormGroup>
      <FormGroup>
        <Col sm={3} smOffset={3} componentClass={ ControlLabel }>
          Použít jako výchozí pro dovolenou ?
        </Col>
        <Col sm={6}>
          <Checkbox checked={item.basicVacation}
                    onChange={(e) => {
                      let obj = item;
                      obj['basicVacation'] = e.target.checked;
                      this.props.onChange(obj);
                    }}
                    style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          </Checkbox>
        </Col>
      </FormGroup>
      {/*<FormGroup>
        <Col smOffset={6}>
          <Checkbox style={{ marginTop: '35px', marginLeft: '20px' }}>
            Viditelný v dispečinku
          </Checkbox>
        </Col>
      </FormGroup>*/}
    </Row>
  }
}

class vehiclesEditCustom extends Component {

  static CheckCustom(item) {
    return false;
  }

  render() {
    const { item } = this.props;
    return <Row>
      <FormGroup>
        <Col sm={3} smOffset={3} componentClass={ ControlLabel }>
          Fakturační činnost
        </Col>
        <Col sm={6}>
          <ItemsSelector itemsToFetch={'attributes/invoice-activity'}
                         newEditComponent={ AttributeEdit }
                         newEditComponentDefaultProps={{
                           attributeName: 'invoice-activity',
                         }}
                         overrideToggleFunc={(item) =>  item.id > 0 ? item.name : <div style={{fontSize: '13px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                           Nevybrán
                         </div>}
                         appendItemsToStart={[{id: 0, name: 'Nevybrán', color: '#ffffff'},]}
                         drawFirstWithDividers
                         onListSplitterCondition={(index, item) => item.id !== 0}
                         onSelected={(item_) => {
                           let obj = item;
                           obj['activityId'] = item_.id;
                           obj['activityName'] = item_.name;
                           this.props.onChange(obj);
                         }}
                         defaultSelectedId={item.activityId}
                         value={'name'}
          />
        </Col>

        <Col sm={3} smOffset={3} componentClass={ ControlLabel }>
          Vyditelný v kalendáři
        </Col>
        <Col sm={6}>
          <Checkbox checked={item.visibleForCalendar}
                    onChange={(e) => {
                      let obj = item;
                      obj['visibleForCalendar'] = e.target.checked;
                      this.props.onChange(obj);
                    }}
                    style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          </Checkbox>
        </Col>
      </FormGroup>
      {/*<FormGroup>
        <Col smOffset={6}>
          <Checkbox style={{ marginTop: '35px', marginLeft: '20px' }}>
            Viditelný v dispečinku
          </Checkbox>
        </Col>
      </FormGroup>*/}
    </Row>
  }
}

class materialEditCustom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressBook: [],
    }
  }

  componentDidMount() {
    request.get('/items/addressBook')
      .then((res) => {
        this.setState({ addressBook: res.data} );
      })
  }

  static CheckCustom(item) {
    if (item.prices.length === 0) {
      return false;
    }
    let bool = false;
    item.prices.forEach(price => {
      if (!price.addressBookId) {
        bool = true;
        return;
      }
      if (!price.storeId) {
        bool = true;
        return;
      }
      if (!price.priceId) {
        bool = true;
      }
    })
    return bool;
  }

  render() {
    const { item } = this.props;
    return <Row>
      <FormGroup>
        <Col sm={3} smOffset={3}>

          <ControlLabel>
            Tabulka cen dodavatelů
          </ControlLabel>
          <div className='dataTable'>
            <table>
              <tbody>
                <tr>
                  <th>Dodavatel</th>
                  <th>Místo odběru</th>
                  <th>Cena dodavatele</th>
                  <th>Naše cena</th>
                  <th>Jednotka</th>
                </tr>
                {Object.keys(item.prices).map(key => {
                  return <tr key={key}>
                    <td>
                      <ItemsSelector
                        appendItemsToStart={[{id: 0, companyWithIc: 'Nevybrán'}]}
                        overrideToggleFunc={(item) =>  item.id > 0 ? item.companyWithIc : <div style={{fontSize: '13px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                          Nevybrán
                        </div>}
                        filterEnable
                        defaultSelectedId={item.prices[key].addressBookId}
                        newEditComponent={ AddressBookEdit }
                        itemsToFetch='addressBook'
                        externList={this.state.addressBook}
                        onSelected={(address) => {
                          item.prices[key].addressBookId = address.id;
                          item.prices[key].name = address.companyWithIc;
                          this.props.onChange(item);
                        }}
                        value='companyWithIc'/>
                    </td>
                    <td>
                      <ItemsSelector itemsToFetch={'attributes/store'}
                                     newEditComponent={ AttributeEdit }
                                     newEditComponentDefaultProps={{
                                       attributeName: 'store',
                                     }}
                                     appendItemsToStart={[{name: 'Nevybrán', id: 0}]}
                                     overrideToggleFunc={(store) => item.prices[key].storeId > 0 ?
                                       store.name : <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                       Nevybrán
                                     </div>}
                                     onSelected={(store) => {
                                       item.prices[key].storeId = store.id;
                                       item.prices[key].storeName = store.name;
                                       this.props.onChange(item);
                                     }}
                                     defaultSelectedId={item.prices[key].storeId}
                                     value={'name'}
                      />
                    </td>
                    <td>
                      <FormControl type='number'
                                   value={item.prices[key].basePrice}
                                   onChange={(e) => {
                                     item.prices[key].basePrice = e.target.value;
                                     this.props.onChange(item);
                                   }}
                      />
                    </td>
                    <td>
                      <FormControl type='number'
                                   value={item.prices[key].price}
                                   onChange={(e) => {
                                     item.prices[key].price = e.target.value;
                                     this.props.onChange(item);
                                   }}
                      />
                    </td>
                    <td>
                      <ItemsSelector itemsToFetch={'attributes/price'}
                                     newEditComponent={ AttributeEdit }
                                     newEditComponentDefaultProps={{
                                       attributeName: 'price',
                                     }}
                                     appendItemsToStart={[{name: 'Nevybrán', id: 0}]}
                                     overrideToggleFunc={(store) => item.prices[key].priceId > 0 ?
                                       store.name : <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                         Nevybrán
                                       </div>}
                                     onSelected={(store) => {
                                       item.prices[key].priceId = store.id;
                                       this.props.onChange(item);
                                     }}
                                     defaultSelectedId={item.prices[key].priceId}
                                     value={'name'}
                      />
                    </td>
                    <td>
                      <button className='transparentButton' onClick={() => {
                        const list = item.prices.slice();
                        list.splice(key, 1)
                        item.prices = list;
                        this.props.onChange(item);
                      }}>
                        <FontAwesome style={{fontSize:'18px', color: 'grey', }} name='fas fa-times' />
                      </button>
                    </td>
                  </tr>
                })}

              </tbody>
            </table>
          </div>
          <Button onClick={() => {
            const list = item.prices.slice();
            list.push({
              addressBookId: 0,
              basePrice: 0,
              price: 0,
              storeId: 0,
              priceId: 0,
              name: '',
            })
            item.prices = list;
            this.props.onChange(item);
          }} style={{ marginTop: '5px' }}>
            Nová cena
          </Button>
        </Col>

      </FormGroup>
    </Row>;
  }
}

dispatchingEditCustom.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

priceEditCustom.propTypes = {
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

const attributesCustomOption = {
  attendance: {
    edit: attendanceEditCustom,
    customOptions: {
      paidAttendance: false,
      overtimeAttribute: false,
      basicVacation: false,
    },
    colorEnabled: true,
  },
  dispatching: {
    customOptions: {
      isVehicleWrittingEnabled: false,
      userAsAddressBook: false,
      canOrderNumber: false,
      canVehicle: false,
      canUser: false,
      canNote: false,
      canTool: false,
      canLoading: false,
      canUnLoading: false,
      canCompany: false,
      canConstruction: false,
      canContactPerson: false,
      canTime: false,
      canFraction: false,
      canCalculation: false,
      canUseCosts: false,
      smsTemplateText: '',
      orderEmailTemplates: [],
      orderEmailTemplateText: '',
      orderEmailTemplateNote: '',
      baseOrdering: '',
      enableDeletePeriod: 0,
    },
    edit:dispatchingEditCustom,
    colorEnabled: true,
  },
  price: {
    customOptions: {
      defaultPrice: 0,
      isHourAttribute: false,
      isKmAttribute: false,
      useKmRange: false,
      rangeList: [],
      nameForAccounting: '',
    },
    edit: priceEditCustom,
    colorEnabled: false,
  },
  vehicles: {
    colorEnabled: false,
    customOptions: {
      activityId: null,
      activityName: '',
      visibleForCalendar: false,
    },
    edit: vehiclesEditCustom,
  },
  material: {
    colorEnabled: false,
    customOptions: {
      prices: []
    },
    edit: materialEditCustom,
  },
  store: {
    edit: null,
    customOptions: {},
    colorEnabled: false,
  },
  'invoice-activity': {
    colorEnabled: false,
    customOptions: {
    },
    edit: null,
  }
};

export default class AttributeEdit extends Component {

  constructor(props) {
    super(props);
    this.attributeName =  this.props.disableUrlCheck ? props.defaultProps.attributeName : (this.props.match.url
      .substring(this.props.match.url.indexOf('attributes/') + 'attributes/'.length,this.props.match.url.indexOf('/'+ this.props.match.params.id)));
    this.state = {
      requestActive: this.props.disableUrlCheck ? false : this.props.match.params.id !== 'new',
      submitActive: false,
      name: '',
      color: '#000000',
      customOptions: attributesCustomOption[this.attributeName].customOptions,
    };
  }

  componentWillMount() {
    if (this.props.disableUrlCheck)
      return;
    if (this.props.match.params.id !== 'new' ) {
      request.get('/items/attributes/byid/' + this.props.match.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState(res.data);
            this.setState({ requestActive: false });
          }
        })
        .catch(err => {
          this.setState({ requestActive: false });
        })
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if(attributesCustomOption[this.attributeName].edit) {
      if(attributesCustomOption[this.attributeName].edit.CheckCustom(this.state.customOptions)) {
        store.dispatch(show({
          title: 'Problém',
          level: 'error',
          autoDismiss: 10,
          children: (
            <div>
              Nelze uložit některá položka není správně vyplněna
            </div>
          ),
        }, 'error'));
        return;
      }
    }

    this.props.onSubmit(this.props.disableUrlCheck ? 'new' : this.props.match.params.id, this, this.state);
  }

  render() {

    const styles = reactCSS({
      default: {
        color: {
          width: '25px',
          height: '25px',
          marginLeft: '5px',
          borderRadius: '50%',
          background: this.state.color,
          display: 'inline-block',
          cursor: 'pointer',
        },
      },
    });

    return (<div className='editForm'>
      <ContentWithFixedHeader drawOnlyChildren={this.props.disableUrlCheck} header={
        <CanView visible={!this.props.disableUrlCheck}>
          <BackHeader
            fontSize='12'
            headerText={this.state.name === '' ? 'Atribut' : this.state.name}
            onBackClick={() => this.props.history.replace(
              this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
        </CanView>
      }>
        <div className='nest'>
          <Row>
            <Col sm={3} smOffset={3} componentClass={ ControlLabel }>
              Jméno
            </Col>
            <Col sm={5}>
              <FormGroup>
                <FormControl type='text'
                             value={this.state.name}
                             onChange={(e) => this.setState({ name: e.target.value })}
                             placeholder='Jméno'
                />
              </FormGroup>
            </Col>
          </Row>
          {attributesCustomOption[this.attributeName].colorEnabled ? <Row>
            <Col sm={3} smOffset={3} componentClass={ ControlLabel }>
              Barva
            </Col>
            <Col sm={3}>
              <OverlayTrigger ref='overlay' trigger='click' placement='right' overlay={
                <Popover id='playerColorPicker' placement='right'>
                  <SketchPicker
                    onChangeComplete={(color) => this.setState({color: color.hex})}
                    color={this.state.color}/>
                </Popover>
              }>
                <div style={ styles.color }/>
              </OverlayTrigger>
            </Col>
          </Row> : null}
          {
            attributesCustomOption[this.attributeName].edit !== null ?
              React.createElement(attributesCustomOption[this.attributeName].edit, {
                item: this.state.customOptions ? this.state.customOptions
                  : attributesCustomOption[this.attributeName].customOptions,
                onChange: (obj) => this.setState({ customOptions: obj }),
              }) : null
          }
          <Row>
            <Col smOffset={3} sm={6}>
              <Form onSubmit={(e) => this.handleSubmit(e)}>
                <SubmitButton
                  onClick={this.props.disableUrlCheck ? (e) => this.handleSubmit(e) : null}
                  bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                  Uložit
                </SubmitButton>
              </Form>
            </Col>
          </Row>
        </div>
      </ContentWithFixedHeader>
    </div>);
  }
}

export {
  attributesCustomOption,
}

AttributeEdit.propTypes = {
  disableUrlCheck: PropTypes.bool,
  ...BaseEditPropTypes,
};

AttributeEdit.defaultProps = {
  AttributeEdit: false,
};
