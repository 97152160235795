import React, { Component } from 'react';
import { BaseViewPropTypes } from '../CommonListContainer';
import {Row, Col, ControlLabel} from "react-bootstrap";
import AttributePreview from "../attributes/Preview";
import {SmallUserPreview} from "../users/Preview";
import {dateFormatVisible, dateFromat} from "../../AppObjects";
import moment from "moment";

export default class AttendancePreview extends Component {
  render() {
    const { item } = this.props;
    return (
      <div>
        <ControlLabel>
          <span>
              {moment(item.from, dateFromat).format(dateFormatVisible)}
            { item.isPartDay ?
              <span style={{marginLeft: '10px'}}>{`od: ${item.timeFrom} do: ${item.timeTo}`}</span> :
              <span style={{marginLeft: '10px'}}>
                -
                <span style={{marginLeft: '10px'}}>
                  {moment(item.to, dateFromat).format(dateFormatVisible)}
                </span>
              </span>}
            </span>
        </ControlLabel>
        <div style={{ marginBottom: '6px' }}>
          <SmallUserPreview item={item.user}/>
        </div>
        <div style={{ marginBottom: '6px' }}>
          <AttributePreview
            width='16px'
            height='16px'
            style={{display: 'inline-block'}} item={item.attribute}/>
        </div>
        <div>
          <span>Počet dní:<span style={{marginLeft: '20px'}}>{item.workingDays}</span></span>
        </div>
      </div>
    )
  }
}

AttendancePreview.propTypes = BaseViewPropTypes;