import React, { Component } from 'react';
import {
  Button, Checkbox,
  Modal,
} from "react-bootstrap";

import PropTypes from "prop-types";
import ReactToPrint from "react-to-print";
import {CanView} from "../../Main";
import store from "../../../redux/store";
import { show as show_ } from 'react-notification-system-redux';
import {roundFloatTwoDigit} from "../../../AppObjects";
import DataRowPreview from "../DataRowPreview";

const labels = {
  canOrderNumber: 'Zakázka',
  canVehicle: 'Stroj',
  canUser: 'Strojník/dodavatel',
  canTool: 'Vybavení',
  canLoading: 'Nákladka/výkladka',
  canCompany: 'Firma',
  canConstruction: 'Stavba',
  canContactPerson: 'Kontaktní osoba',
  canTime: 'Časové údaje',
  canFraction: 'Frakce',
  canCalculation: 'Kalkulace',
  canUseCosts: 'Sloupce se zisky',
};

export default class Print extends Component {
  constructor(props){
    super(props);
    this.state = {
      show: false,
      filter: {
        canOrderNumber: false,
        canVehicle: true,
        canUser: true,
        canTool: false,
        canLoading: true,
        canCompany: true,
        canConstruction: true,
        canContactPerson: false,
        canTime: true,
        canFraction: false,
        canCalculation: false,
        canUseCosts: false,
      }
    }
  }

  render() {
    const { show, filter } = this.state;
    const { priceAttributes, dataRows, sum, costsSum, priceSum, profitSum } = this.props;
    return <>
      <Button
        onClick={() => {
          if (dataRows.filter(o => o.isSelected).length === 0) {
            store.dispatch(show_({ title: 'Chyba', level: 'error', autoDismiss: 3,
              children: (
                <div>
                  Není vybrán žádný řádek pro tisk
                </div>
              ),
            }))
            return;
          }
          this.setState({show: true})
        }}
        bsStyle='primary' style={this.props.style}>
        Tisk
      </Button>

      <Modal show={show}
             onHide={() => this.setState({show: false})}>
        <Modal.Header closeButton>
          <h3 className='text-center'>Tisk</h3>
        </Modal.Header>
        <Modal.Body>
          <h3>Zahrnout v tisku</h3>
          {Object.keys(labels).map(key => {
            return <Checkbox key={key}
                             checked={filter[key]}
                             onChange={(e) => {
                               let f = this.state.filter;
                               f[key] = e.target.checked;
                               this.setState({
                                 filter: f,
                               })
                             }}
                             style={{ paddingLeft: '10px', paddingRight: '10px' }}>
              {labels[key]}
            </Checkbox>
          })}
          <ReactToPrint
            pageStyle={{paddingTop: '100px', paddingBottom: '100px'}}
            trigger={() => <Button bsStyle='primary' style={{ width: '100%' }}>Tisk</Button>}
            content={() => this.componentRef}
          />
        </Modal.Body>
      </Modal>
      {
        show ? <div style={{ display: 'none' }}>
          <div className='dataTable' ref={el => (this.componentRef = el)}>
            <table>
              <tbody>
              <tr>
                <th>Datum</th>
                <CanView visible={filter.canOrderNumber}><th>Č.Z.</th></CanView>
                <CanView visible={filter.canVehicle}><th>Stroj</th></CanView>
                <CanView visible={filter.canUser}><th>Strojník / Dodavatel</th></CanView>
                <CanView visible={filter.canTool}><th>Vybavení</th></CanView>
                <CanView visible={filter.canLoading}><th>Nákladka</th></CanView>
                <CanView visible={filter.canLoading}><th>Výkladka</th></CanView>
                <CanView visible={filter.canCompany}><th>Firma</th></CanView>
                <CanView visible={filter.canConstruction}><th>Stavba</th></CanView>
                <CanView visible={filter.canContactPerson}><th>Kontakt</th></CanView>
                <CanView visible={filter.canTime}><th>od</th></CanView>
                <CanView visible={filter.canTime}><th>do</th></CanView>
                <CanView visible={filter.canTime}><th>Přestávka</th></CanView>
                <CanView visible={filter.canTime}><th>Hodiny</th></CanView>
                <CanView visible={filter.canFraction}><th>Frakce</th></CanView>
                <CanView visible={filter.canCalculation}><th>Kalkulace</th></CanView>
                <CanView visible={filter.canCalculation}><th>Cena</th></CanView>
                <CanView visible={filter.canUseCosts}><th>Náklady</th></CanView>
                <CanView visible={filter.canUseCosts}><th>Zisk</th></CanView>
              </tr>
              {
                dataRows.filter(o => o.isSelected).map((row, index) => (
                  <DataRowPreview isForLog pFilter={filter} isForPrint priceAttributes={priceAttributes} key={index} item={row}/>
                ))
              }
              </tbody>
            </table>
            <table style={{marginTop: '20px'}}>
              <tbody >
              <tr>
                <CanView visible={filter.canTime}><th>Hodiny celkem</th></CanView>
                <CanView visible={filter.canCalculation}>
                  <th>Cena celkem</th>
                  <th>Náklady celkem</th>
                  <th>Zisk celkem</th>
                </CanView>
              </tr>
              <tr>
                <CanView visible={filter.canTime}>
                  <td className='transparent' style={{fontSize: '16px', fontWeight: 'bold'}}>
                    {dataRows.length === 0 ? '' : roundFloatTwoDigit(sum)}
                  </td>
                </CanView>
                <CanView visible={filter.canCalculation}>
                  <td className='transparent' style={{fontSize: '16px', fontWeight: 'bold'}}>
                    {dataRows.length === 0 ? '' :  roundFloatTwoDigit(Math.ceil(priceSum))}
                  </td>
                  <td className='transparent' style={{color: 'red', fontSize: '16px', fontWeight: 'bold'}}>
                    {dataRows.length === 0 ? '' :  roundFloatTwoDigit(Math.ceil(costsSum * -1))}
                  </td>
                  <td className='transparent' style={{color: profitSum < 0 ? 'red' : 'darkgreen', fontSize: '16px', fontWeight: 'bold'}}>
                    {dataRows.length === 0 ? '' : roundFloatTwoDigit(Math.ceil(profitSum > 0 ? `+${profitSum}` : profitSum))}
                  </td>
                </CanView>
              </tr>
              </tbody>
            </table>
          </div>
        </div> : null
      }

    </>
  }
}

Print.propTypes = {
  sum: PropTypes.any,
  priceSum: PropTypes.any,
  costsSum: PropTypes.any,
  profitSum: PropTypes.any,
  priceAttributes: PropTypes.object,
  style: PropTypes.object,
  dataRows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Print.defaultProps = {
  sum: 0,
  priceSum: 0,
  costsSum: 0,
  profitSum: 0,
  style: {},
};
