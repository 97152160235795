import React, {Dispatch, useEffect, useReducer} from 'react';
import {Button, Dropdown, FormControl, MenuItem, ControlLabel} from 'react-bootstrap';
import FontAwesome from "react-fontawesome";
import {Mention, MentionItem, MentionsInput} from 'react-mentions'
import {useContextSelector} from 'use-context-selector';
import {
  ADD_LIST,
  CHANGE_LIST,
  CHANGE_MAIN_STATE,
  DELETE_LIST,
  initState,
  INumberQueue, IRegexTable,
  NumberQueueGeneratorActionTypes,
  NumberQueueGeneratorState,
  reducer,
  SET_MAIN_STATE
} from './numberQueueGenerator/reducer';

import mentionStyle from './numberQueueGenerator/styles';
import NumberQueue from "./NumberQueue";

function RegexCondition({index}: {index: number}) {
  const regex = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value[index].regex)
  const regexTable = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value[index].regexTable)
  const type = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value[index].type)
  const dispatch = useContextSelector(NumberQueueGeneratorState, v =>
    v[1]) as Dispatch<NumberQueueGeneratorActionTypes>


  const onChange = (e: any) => {
    dispatch({
      type: CHANGE_LIST,
      payload: {
        index,
        data: { regex: (e.target as HTMLInputElement).value }
      }
    })
  }

  const onAdd = () => {
    const list = (regexTable ? regexTable : []).slice();
    list.push({ description: '', item: '' })
    dispatch({
      type: CHANGE_LIST,
      payload: {
        index,
        data: { regexTable: list }
      }
    })
  }

  const onChangeItem = (value: string, rIndex: number) => (e: any) => {
    const list = regexTable.slice();
    // @ts-ignore
    list[rIndex][value] = (e.target as HTMLInputElement).value;
    dispatch({
      type: CHANGE_LIST,
      // @ts-ignore
      payload: {
        index,
        data: { regexTable: list }
      }
    })
  }

  const onDelete = (rIndex: number) => (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const list = regexTable.slice();
    list.splice(rIndex, 1);
    dispatch({
      type: CHANGE_LIST,
      // @ts-ignore
      payload: {
        index,
        data: { regexTable: list }
      }
    })
  }

  if (type !== "enum") {
    return <FormControl type='text'
                        value={regex as string}
                        onChange={onChange}
                        placeholder='Regex'
    />
  } else {
    return <div className={'dataTable'}>
      <table>
        <tbody>
          <tr>
            <th>Hodnota</th>
            <th>Popis</th>
          </tr>
          {
            (regexTable ? regexTable : []).map((r, i) => <tr>
              <td>
                <FormControl type='text'
                             value={r.item}
                             onChange={onChangeItem("item", i)}
                             placeholder='Hodnota'/>
              </td>
              <td>
                <FormControl type='text'
                             value={r.description}
                             onChange={onChangeItem("description", i)}
                             placeholder='Popis'/>
              </td>
              <td>
                <button className='transparentButton' onClick={onDelete(i)}>
                  <FontAwesome style={{fontSize:'16px', color: 'black', }} name='times' />
                </button>
              </td>
            </tr>)
          }
        </tbody>
        <Button onClick={onAdd}>
          Nová
        </Button>
      </table>
    </div>
  }
}

function DescriptionEdit({index}: {index: number}) {

  const description = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value[index].description)
  const dispatch = useContextSelector(NumberQueueGeneratorState, v =>
    v[1]) as Dispatch<NumberQueueGeneratorActionTypes>


  const onChange = (e: any) => {
    dispatch({
      type: CHANGE_LIST,
      payload: {
        index,
        data: { description: (e.target as HTMLInputElement).value }
      }
    })
  }
  return <FormControl type='text'
                      value={description}
                      onChange={onChange}
                      placeholder='Popis'
  />
}

function NameEdit({index}: {index: number}) {
  const name = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value[index].name )
  const dispatch = useContextSelector(NumberQueueGeneratorState, v =>
    v[1]) as Dispatch<NumberQueueGeneratorActionTypes>

  const onChange = (e: any) => {
    dispatch({
      type: CHANGE_LIST,
      payload: {
        index,
        data: { name: (e.target as HTMLInputElement).value }
      }
    })
  }
  return <FormControl type='text'
                      value={name}
                      onChange={onChange}
                      placeholder='Název'
  />
}

function LengthSpec({index}: {index: number}) {
  const length = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value[index].length )
  const dispatch = useContextSelector(NumberQueueGeneratorState, v =>
    v[1]) as Dispatch<NumberQueueGeneratorActionTypes>;
  const type = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value[index].type )

  const onChange = (e: any) => {
    dispatch({
      type: CHANGE_LIST,
      payload: {
        index,
        data: { length: (e.target as HTMLInputElement).value }
      }
    })
  }
  return <FormControl type='text'
                      disabled={type === "enum"}
                      value={length}
                      onChange={onChange}
                      placeholder='Délka'
  />
}

function TypeSelect({index}: {index: number}) {
  const type = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value[index].type )
  const dispatch = useContextSelector(NumberQueueGeneratorState, v =>
    v[1]) as Dispatch<NumberQueueGeneratorActionTypes>

  const onChange = (e: any) => {
    dispatch({
      type: CHANGE_LIST,
      payload: {
        index,
        data: { type: e }
      }
    })
  }

  const types: {[key: string]: string} = {
    "string": "Text",
    "number": "Číslo",
    "enum": "Tabulka",
  }


  return <Dropdown
    // @ts-ignore
    bsSize="xsmall"
    onSelect={onChange}
    id="prices">
    <Dropdown.Toggle>
      {types[type]}
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {
        Object.keys(types).map((key, vehIndex) => {
          return <MenuItem key={vehIndex} eventKey={key}>
            {types[key]}
          </MenuItem>
        })
      }
    </Dropdown.Menu>
  </Dropdown>
}

const ListRenderer = () => {
  const listLength = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value.length )

  return <>
    {[...Array(listLength)].map((i, index) => <tr>
      <td><NameEdit index={index}/></td>
      <td><TypeSelect index={index}/></td>
      <td><LengthSpec index={index}/></td>
      <td><RegexCondition index={index}/></td>
      <td><DescriptionEdit index={index}/></td>
      <td><DeleteButton index={index}/></td>
    </tr>)
    }
  </>
}

const StateProvider = ({ children, defaultState, onChange }: {
  children: React.ReactNode;
  defaultState?: INumberQueue | null;
  onChange: (state: INumberQueue) => void;
}) => {
  const [state, dispatch] = useReducer(reducer, defaultState ? defaultState : initState);

  useEffect(() => {
    if(defaultState)
      dispatch({
        type: SET_MAIN_STATE,
        payload: defaultState,
      })
  }, [defaultState])

  onChange(state);

  return (
    <NumberQueueGeneratorState.Provider
      // @ts-ignore
      value={[state, dispatch]}>
      {children}
      <NumberQueue template={state}/>
    </NumberQueueGeneratorState.Provider>
  );
};

const NewButton = () => {
  const dispatch = useContextSelector(NumberQueueGeneratorState, v =>
    v[1] as Dispatch<NumberQueueGeneratorActionTypes>)
  const onAdd = () => {
    dispatch({
      type: ADD_LIST,
    });
  }

  return <Button onClick={onAdd}>
    Nový
  </Button>
}

const DeleteButton = ({index}: {index: number}) => {

  const dispatch = useContextSelector(NumberQueueGeneratorState, v =>
    v[1] as Dispatch<NumberQueueGeneratorActionTypes>)

  const onDelete = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    dispatch({
      type: DELETE_LIST,
      payload: {
        index
      },
    });
  }

  return <button className='transparentButton' onClick={onDelete}>
    <FontAwesome style={{fontSize:'16px', color: 'black', }} name='times' />
  </button>
}

const TemplateTextBox = () => {
  const list = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).value )

  const template = useContextSelector(NumberQueueGeneratorState, v =>
    (v[0] as INumberQueue).template );

  const dispatch = useContextSelector(NumberQueueGeneratorState, v =>
    v[1] as Dispatch<NumberQueueGeneratorActionTypes>)

  const onChange = (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]) => {
    dispatch({
      type: CHANGE_MAIN_STATE,
      payload: {
        template: newValue
      }
    })
  }

  return <MentionsInput
    style={mentionStyle}
    singleLine
    value={template} onChange={onChange}>
    <Mention
      trigger="@"
      style={{ backgroundColor: '#BBBBBB',
        paddingTop: '6px',
        paddingBottom: '6px' }}
      //displayTransform={(id, display) => `[${display}]`}
      data={list.map((l,i) => ({id: i, display: l.name}))}
    />

  </MentionsInput>
}

export default function NumberQueueGenerator({ defaultState, onChange }: {
  defaultState?: INumberQueue | null;
  onChange: (state: INumberQueue) => void;
}) {

  return <div className='dataTable'>
    <StateProvider onChange={onChange} defaultState={defaultState}>
      <table>
        <tbody>
        <tr>
          <th>Název</th>
          <th>Typ</th>
          <th>Délka</th>
          <th>Podmínka</th>
          <th>Popis</th>
        </tr>
        <ListRenderer/>
        </tbody>
      </table>
      <NewButton/>

      <div style={{ marginTop: '15px' }}>
        <ControlLabel>
          Šablona čísla (Vyvolejte nabídku stiskem @)
        </ControlLabel>

        <TemplateTextBox/>
      </div>

      <div style={{ marginTop: '15px' }}>
        <ControlLabel>
          Ukázka psaní čísla
        </ControlLabel>
      </div>

    </StateProvider>
  </div>
}