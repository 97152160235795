import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import request, {AxiosError, AxiosResponse} from 'axios';
import { FormEvent } from "react";
import { Form } from 'react-bootstrap';
import ContentWithFixedHeader from "./ContentWithFixedHeader";
import { BackHeader } from "./Setting";
import { LoadingOrRender } from "../loginsrc/Profile";
import SubmitButton from "../loginsrc/SubmitButton";

interface MatchParams {
    id: string | undefined;
}

export interface BaseEditProps extends RouteComponentProps<MatchParams> {
    onSubmit: (id: string | number, _this: React.Component<BaseEditProps, BaseEditState>, object: object, callback?: (object: object) => void) => {},
    baseUrl: string | undefined,
}

export interface BaseFilterProps {
    onFilter: (query: object) => {}
}

export interface BaseViewProps {
    item: object | undefined,
    onItemChange?: (data: object) => {},
}

export interface BaseEditState {
    id: string | number | undefined,
    requestActive: boolean,
    submitActive: boolean,
    disableSubmitButton: boolean;
}

export interface IBaseEdit {
    receivedItemData(res: AxiosResponse<any> | void) : void;
    handleSubmit(e?: FormEvent<Form> | MouseEvent) : void;
    renderBody() : React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined;
}

export class BaseEdit<P extends BaseEditProps, S extends BaseEditState> extends React.Component<P, S> {
    private _impl : IBaseEdit;
    private readonly _headerText: string;
    constructor(props: P, headerText: string = '') {
        super(props);
        this._headerText = headerText;
        this._impl = {
            receivedItemData(res: Response | void): void {},
            handleSubmit(e?: FormEvent<Form>) : void {},
            renderBody() {
                return undefined;
            }
        } as IBaseEdit;
        this.state = {
            requestActive: this.props.match ? (this.props.match.params.id !== 'new') : false,
            submitActive: false,
            disableSubmitButton: false,
        } as S;
    }

    setListeners(impl: IBaseEdit) {
        this._impl = impl;
    }

    checkRequestError(req: any) {
        req.catch((err: AxiosError) => {
            this.setState({ requestActive: false });
        });
        return req;
    }

    componentWillMount(): void {
        this.checkIfShouldRequest();
    }

    checkIfShouldRequest(): void {
        if (!this.props.match) {
            this.setState( { requestActive: false });
            return;
        }
        if (this.props.match.params.id !== 'new') {
            this.setState( { requestActive: true });
            request.get(`/items/${this.props.baseUrl}/${this.props.match.params.id}`)
                .catch((err: AxiosError) => {
                    this.setState({ requestActive: false });
                })
                .then(res => this._impl.receivedItemData(res))
        }
    }

    finalizeSubmit(object: object, callback?: (object: object) => void, id?: string | number) {
        if (typeof id === 'undefined') {
            const id = this.props.match ? (this.props.match.params.id ? this.props.match.params.id : 'new') : 'new';
            this.props.onSubmit(id, this, object, callback);
        } else {
            this.props.onSubmit(id, this, object, callback);
        }
    }

    backUrl() {
        if(this.props.history) {
            this.props.history.replace(
                this.props.match.url.split('/' + this.props.match.params.id)[0])
        }
    }

    render() {
        return <div className='editForm'>
            <ContentWithFixedHeader drawOnlyChildren={!this.props.match}  header={
                <BackHeader
                    fontSize='18px'
                    headerText={ this._headerText }
                    onBackClick={() => this.backUrl()}/>
            }>
                <div className='nest'>
                    <LoadingOrRender requestActive={this.state.requestActive}>
                        <Form onSubmit={(e) => this._impl.handleSubmit(e)}>
                            {this._impl.renderBody()}
                            <SubmitButton onClick={this.props.match ? (e: MouseEvent) => this._impl.handleSubmit(e) : null}
                                          disabled={this.state.disableSubmitButton}
                                          bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                                Uložit
                            </SubmitButton>
                        </Form>
                    </LoadingOrRender>
                </div>
            </ContentWithFixedHeader>
        </div>
    }
}

