import {CanView} from "../../Main";
import {IDispatchingAttributesCustom} from "@cml/types";
import FontAwesome from "react-fontawesome";
import ItemsSelector from "../../ItemsSelector";
import {Attributes, DataRow, Orders, Roles, Vehicles} from "@cml/models";
import {SmallVehiclePreview} from "../../vehicles/Preview";
import VehicleEdit from "../../vehicles/Edit";
import {MenuItem} from "react-bootstrap";
import React from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getAttributes, getOrders, getVehicles} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import {fillPrice} from "../components/datarowBase";
import store from "../../../redux/store";
import {setEditedRowIndex} from "../reducerResults";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrVehicles({ index, attribute, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const mapVehiclesDoubled = useSelector((state: { dispatching: DataRowsState }) =>
    index === -1 ? [] : state.dispatching.mapVehiclesDoubled[state.dispatching.dataRows[index].vehicleId as number]);

  const vehicleUsed = useSelector((state: { dispatching: DataRowsState }) =>
    state.dispatching.vehicleUsed);

  const { orders, isValidating: ordersValidating } = getOrders(
    { params: { where: { isConfirmed: 0 }}}, );
  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes(
    'price', );

  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { allDetails: 0, onlyForDispatching: 1  }},singleRowEditMode ? {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  } : undefined );

  const vehicleId_a = useSelector(selectDataRowValue(index, singleRowEditMode, 'vehicleId_a'));

  const vehicleId = useSelector(selectDataRowValue(index, singleRowEditMode, 'vehicleId'));

  const vehicleData = useSelector(selectDataRowValue(index, singleRowEditMode, 'vehicle'));

  const handleVehicleChange = (vehicle: Vehicles) => {
    const data = singleRowEditMode
      ? store.getState().dispatching.singleEditRow as DataRow
      : store.getState().dispatching.dataRows[index];
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      priceEq: fillPrice(data, orders.find(o => o.id === data.orderId) as Orders,
        priceAttributes, vehicle.id).priceEq,
      vehicleId: vehicle.id,
      vehicleId_a: 0
    }))
  }

  if(!vehicles)
    return <div>Loading</div>

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canVehicle && dispatchOther.canVehicle}>
    <td>
      {mapVehiclesDoubled && !singleRowEditMode && !(attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook ?
        (mapVehiclesDoubled > 1 ? <span>
            <FontAwesome name='fas fa-exclamation' style={{marginRight: '2px', color: 'darkorange'}}/>
          {`${mapVehiclesDoubled}x `}
          </span> : '') : ''}
      {
        <ItemsSelector externList={vehicles}
                       itemsToFetch='vehicles'
                       overrideToggleFunc={(vehicle: Vehicles) =>  vehicleId_a ? <SmallVehiclePreview item={vehicleData}/>
                         : (vehicle.id === 0 ? <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                           Nevybrán
                         </div> : <SmallVehiclePreview item={vehicle}/>)}
                       preview={SmallVehiclePreview}
                       newEditComponent={ VehicleEdit }
                       newEditComponentDefaultProps={(attribute.customOptions as IDispatchingAttributesCustom).isVehicleWrittingEnabled ? {
                         isForSupliers: true,
                         spaceId: authenticate.activeSpaceId,
                       } : {spaceId: authenticate.activeSpaceId}}
                       bsSize="xsmall"
                       appendItemsToStart={[
                         {id: 0, identifier: 'Nevybrán'},
                       ]}
                       drawFirstWithDividers={!singleRowEditMode}
                       filterEnable
                       listSplitter={[
                         {
                           header: <CanView visible={!singleRowEditMode}>
                             <MenuItem header>Dostupné</MenuItem>
                           </CanView>
                         },
                         {
                           header: [
                             singleRowEditMode ? null : <MenuItem divider />,
                             <MenuItem header>{singleRowEditMode ? 'Vozidla' : 'Použité'}</MenuItem>,
                           ]
                         }
                       ]}
                       onListSplitterCondition={(index: number, vehicle: Vehicles) => {
                         if ((singleRowEditMode && index === 0) || vehicle.id === 0)
                           return false;
                         return !(
                           (singleRowEditMode ? false : (index === 0 ? vehicleUsed.includes(vehicle.id) : !vehicleUsed.includes(vehicle.id))) ||
                           ((attribute.customOptions as IDispatchingAttributesCustom).isVehicleWrittingEnabled && !vehicle.isForSupliers) ||
                           (!(attribute.customOptions as IDispatchingAttributesCustom).isVehicleWrittingEnabled && vehicle.isForSupliers) || vehicle.isExternal
                         );
                       }}
                       value={'identifier'}
                       onSelected={handleVehicleChange}
                       defaultSelectedId={vehicleId}/>
      }
    </td>
  </CanView>
}