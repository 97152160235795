import React, { Component } from 'react';
import {Col, FormControl, Row} from "react-bootstrap";
import FontAwesome from "react-fontawesome";

export default class AddressBookFiltr extends Component{

  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
    }
  }

  render() {
    return (<div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll filterStyle'>
      <Row>
        <Col sm={1}>
          <div className='text-right'>
            <FontAwesome style={{fontSize:'18px', marginTop: '8px'}} name='fas fa-search' />
          </div>
        </Col>
        <Col sm={3}>
          <FormControl
            value={this.state.filterValue}
            onChange={(e) => {
              this.setState({filterValue: e.target.value});
              this.doFiltr(e.target.value);
            }}
            type='text'/>
        </Col>
      </Row>
    </div>)
  }

  doFiltr(value) {
    this.props.onFilter({
      where: {
        $or: [
          { company: { $like: `%${value}%` }},
          { ic: { $like: `%${value}%` }},
        ]
      }
    })
  }
}