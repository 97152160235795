import * as React from 'react';
import { BaseViewProps } from "../CommonListContainerInterfaces";
import { DiagramEditState } from "./Edit";
import FontAwesome from 'react-fontawesome';

export interface DiagramPreviewProps extends BaseViewProps{
    item: DiagramEditState;
}

export default class DiagramPreview extends React.Component<DiagramPreviewProps> {
    render() {
        return <div>
            <span>
              <FontAwesome name='fas fa-project-diagram'/>
            </span>
            <span style={{marginLeft: '30px'}}>
             {this.props.item.name}
            </span>
        </div>
    }
}