import {DefaultPortModelOptions} from "@projectstorm/react-diagrams-defaults";
import {AbstractModelFactory, BaseEvent, DeserializeEvent} from "@projectstorm/react-canvas-core";
import {LinkModel, PortModelGenerics, PortModel, PortModelAlignment} from "@projectstorm/react-diagrams-core";
import {RightAngleLinkModel} from "@projectstorm/react-diagrams-routing";
import { Point, Rectangle } from "@projectstorm/geometry";
import _ from "lodash";

export interface CmlPortModelOptions extends DefaultPortModelOptions {
    inOut?: boolean;
}

export interface CmlPortModelGenerics extends PortModelGenerics {
    OPTIONS: CmlPortModelOptions;
}

export class CmlPortModel extends PortModel<CmlPortModelGenerics> {
    constructor(isIn: boolean, name?: string, label?: string, inOut?: boolean);
    constructor(options: CmlPortModelOptions);
    constructor(options: CmlPortModelOptions | boolean, name?: string, label?: string, inOut?: boolean) {
        if (!!name) {
            options = {
                in: !!options,
                name: name,
                label: label,
                inOut: inOut,
            };
        }
        options = options as CmlPortModelOptions;
        super({
            label: options.label || options.name,
            alignment: options.in ? PortModelAlignment.LEFT : PortModelAlignment.RIGHT,
            type: 'cmlport',
            ...options
        });
    }

    deserialize(event: DeserializeEvent<this>) {
        super.deserialize(event);
        this.options.in = event.data.in;
        this.options.label = event.data.label;
        this.options.inOut = event.data.inOut;
    }

    serialize() {
        return {
            ...super.serialize(),
            in: this.options.in,
            label: this.options.label,
            inOut: this.options.inOut,
        };
    }

    canLinkToPort(port: PortModel): boolean {
        /*if (port instanceof CmlPortModel) {
            return this.options.in !== port.getOptions().in;
        }*/
        if (this === port) {
            return false;
        }
        return true;
    }

    createLinkModel(factory?: AbstractModelFactory<LinkModel>) {
        const model = new RightAngleLinkModel();
        model.registerListener({
            eventDidFire: (e: BaseEvent) => {
                //console.log(e);
            }
        });
        return model;
    }
}