import {Attendance, EDataRow} from "@cml/models";
import FontAwesome from "react-fontawesome";
import React, { useState } from "react";
import {Button, Modal, ModalFooter} from "react-bootstrap";
import AttendancePreview from "../../attendances/Preview";
import {dateFormatVisible, dateFromat, getFloatTime} from "../../../AppObjects";
import moment from "moment";
import request from "axios";
import {getAttributes} from "../../../fetchApi/dispatching";
import {useDispatch, useSelector} from "react-redux";
import {DataRowsState, setDataRows} from "@cml/redux-store";
import store from "../../../redux/store";

interface Props {
  attendance: Attendance,
  requestedDate: string;
  index: number;
}

export default function AttendanceDelete({ attendance, requestedDate, index }: Props) {

  const [show, setShow] = useState(false);

  const { attributes: attendanceAttributes, isValidating: attendanceValidating } = getAttributes('attendance', {
    revalidateOnFocus: false,
    revalidateOnMount: false,
  });

  const dispatch = useDispatch();

  const onDelete = (onlyPart: boolean) => () => {
    // @ts-ignore
    const groups = [];
    const toDelete = [];
    let count = 0;
    if (onlyPart) {
      if (moment(requestedDate).isAfter(moment(attendance.from))){
        groups.push({...attendance,
          to: moment(requestedDate).add( -1, 'days').format(dateFromat),
          workingDays: moment(attendance.from)
            .businessDiff(moment(requestedDate).add( -1, 'days').businessAdd(1))
        });
        count++;
      }
      if(moment(requestedDate).isBefore(moment(attendance.to))) {
        groups.push({...attendance, id: count > 0 ? -1 : attendance.id,
          from: moment(requestedDate).add( 1, 'days').format(dateFromat),
          workingDays: moment(requestedDate).add( 1, 'days')
            .businessDiff(moment(attendance.to).businessAdd(1))
        });
        count++;
      }
    } else {
      toDelete.push(attendance.id);
    }
    request.post('/items/multiAttendance', {
      items: groups,
      toDelete
    })
      .then(res => {
        setShow(false);
        let attendanceRows: Partial<EDataRow>[] = [];
        if(attendance.workingDays > 1) {
          // @ts-ignore
          groups.filter(g => g.id !== -1).concat(res.data).forEach((att: Attendance) => {
            let from = moment(att.from);
            const to = moment(att.to);
            do {
              if (from.isBusinessDay()) {
                const attribute = attendanceAttributes.find(a => a.id === att.attributeId);
                if (attribute) {

                  attendanceRows.push({
                    isAttendance: true,
                    date: from.format(dateFromat),
                    construction: attribute.name,
                    // @ts-ignore
                    attribute,
                    ...att,
                    originAttendance: att,
                    from: att.isPartDay ? getFloatTime(att.timeFrom) : 8,
                    to: att.isPartDay ? getFloatTime(att.timeTo) : 16,
                    break: 0,
                  })
                }
              }
              from.add(1, 'days');
            } while(!from.isAfter(to))
          })
        }
        const dataRows = store.getState().dispatching.dataRows as EDataRow[];
        dispatch(setDataRows(dataRows.filter(d => !d.isAttendance
          || ((d.id !== attendance.id || d.id === -1) && d.isAttendance)).concat(attendanceRows as EDataRow[])
          .sort((a,b) => moment(a.date).valueOf() - moment(b.date).valueOf())))
      })
      .catch(err => {})
  }

  const renderButtons = () => {
    return (
      <>
        <Button onClick={onDelete(false)}>Vymazat docházku</Button>
        {attendance.workingDays > 1 && <Button onClick={onDelete(true)}>
          {`Vymazat pouze vybraný den z docházky (${moment(requestedDate).format(dateFormatVisible)})`}</Button>}
      </>
    )
  }

  const renderWaringMessage = () => {
    if (attendance.workingDays > 1) {
      return <p>Snažíte se vymazat docházku která obsahuje více dnů,
        veberte prosím zda chcete vymazat docházku pouze v tento den
        nebo chcete vymazat všechny dny z této docházky
      </p>
    }
    return null;
  }

  return <>
    <button
      disabled={!attendance.id}
      title={!attendance.id ? 'Nelze mazat docházka, která není uložena' : undefined}
      className='transparentButton' onClick={(e) => {
      e.preventDefault();
      setShow(true);
    }}>
      <FontAwesome style={{fontSize:'14px'}} name='fas fa-trash' />
    </button>
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton />
      <Modal.Body>
        <AttendancePreview item={attendance}/>
        {renderWaringMessage()}
        {renderButtons()}
      </Modal.Body>
    </Modal>
  </>

}