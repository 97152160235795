import React, { Component } from 'react';
import { BaseViewPropTypes } from '../CommonListContainer';
import { Row, Col } from 'react-bootstrap';
import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import {Right} from "../roles/Preview";
import {Tag} from "../Tag";
import moment from "moment";
import {dateFormatVisible} from "../../AppObjects";

const getColorOfStk = (date, item) => {
  const stkDate = moment(date);
  const daysLeft = stkDate.isValid() ? moment().diff(stkDate, 'days') : 0;
  let color = 'darkred';
  if (daysLeft > -8) {
    color = 'darkred';
  } else if (daysLeft > -30) {
    color = 'darkorange';
  } else {
    color = 'darkgreen';
  }
  if (item.isExternal || item.isForSupliers) {
    color = 'black';
  }
  return {
    color,
    daysLeft,
  };
};

class SmallVehiclePreview extends Component {
  render() {
    const colorAndDays = getColorOfStk(this.props.item.stkDate, this.props.item);
    return <div style={{display: 'inline-block', color: colorAndDays.color}}>
      {`${this.props.item.identifier}${!this.props.item.isForSupliers && colorAndDays.daysLeft > -30 ? ' (' + colorAndDays.daysLeft + ')': ''} `}
    </div>
  }
}

SmallVehiclePreview.propTypes = {
  item: PropTypes.object,
};

SmallVehiclePreview.defaultProps = {
  item: {
    identifier: 'No Props',
  }
};

export default class VehiclePreview extends Component{

  renderIcon() {
    if(this.props.item.type === 'webdispecink')
      return <div className='webdispecink'/>;
    else if(this.props.item.type === 'myvisionlink')
      return <div className='unifiedfleet'/>;
    else if(this.props.item.type === 'volvo')
      return <div className='volvo'/>;
  }

  render() {
    const { item, isForLog } = this.props;
    const stkDate = moment(item.stkDate);
    const odoDate = moment(item.odometerCheckDate);
    const insuDate = moment(item.insuranceDate);
    const insuHavDate = moment(item.insuranceHavDate);
    let color = getColorOfStk(item.stkDate, this.props.item).color;
    let colorOdo = getColorOfStk(item.odometerCheckDate, this.props.item).color;
    let colorInsurance = getColorOfStk(item.insuranceDate, this.props.item).color;
    let colorHavInsurance = getColorOfStk(item.insuranceHavDate, this.props.item).color;
    // Because baseState is returned as raw 'space.name'
    return (<div style={{verticalAlign: 'middle'}}>
      <Row>
        <Col sm={1}>
          {
            this.renderIcon()
          }
          <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
            {item.attribute ? item.attribute.name : ''}
          </div>
        </Col>
        <Col sm={4}>
          <div style={{marginBottom: '7px'}}>
            <SmallVehiclePreview item={this.props.item}/>
          </div>
          {
            !item.isForSupliers &&
            <div>
              <Tag style={{
                backgroundColor: color,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '8px',
                paddingRight: '8px',
                borderRadius: '5px',
                width: 'fit-content',
                color: 'white',
              }} disableClose item={`STK ${stkDate.isValid() ? stkDate.format(dateFormatVisible) : 'Nevyplněno' }`}/>
              <Tag style={{
                backgroundColor: colorOdo,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '8px',
                paddingRight: '8px',
                borderRadius: '5px',
                width: 'fit-content',
                color: 'white',
                marginLeft: '15px',
              }} disableClose item={`TCHG ${odoDate.isValid() ? odoDate.format(dateFormatVisible) : 'Nevyplněno' }`}/>
            </div>
          }
          {
            !item.isForSupliers && <div style={{ marginTop: '10px' }}>
              <Tag style={{
                backgroundColor: colorInsurance,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '8px',
                paddingRight: '8px',
                borderRadius: '5px',
                width: 'fit-content',
                color: 'white',
              }} disableClose item={`POV: ${item.insuranceCompany || item.insuranceCompany === '' ?
                item.insuranceCompany : 'Poj.' } ${insuDate.isValid() ? insuDate.format(dateFormatVisible) : 'Nevyplněno' }`}/>
              <Tag style={{
                backgroundColor: colorHavInsurance,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '8px',
                paddingRight: '8px',
                borderRadius: '5px',
                width: 'fit-content',
                color: 'white',
                marginLeft: '15px',
              }} disableClose item={`HAV: ${item.insuranceHavCompany || item.insuranceHavCompany === '' ?
                item.insuranceHavCompany : 'Poj.' } ${insuHavDate.isValid() ? insuHavDate.format(dateFormatVisible) : 'Nevyplněno' }`}/>
            </div>
          }


        </Col>
        <Col sm={2}>
          <FontAwesome style={{ fontSize: '26px', marginRight: '10px' }} name='fas fa-building'/>
          {item.space ? item.space.name : 'Nevybrán'}
        </Col>
        <Col sm={3}>
          <div>
            <Right style={{marginRight: '10px'}} can={item.isForSupliers}/>
            <span>Používat pouze pro subdodavatele</span>
          </div>
          <div>
            <Right style={{marginRight: '10px'}} can={item.isZeroDph}/>
            <span>Fakturace PDP</span>
          </div>
          <div>
            <Right style={{marginRight: '10px'}} can={item.isExternal}/>
            <span>Skutečný stroj subdodavatele</span>
          </div>
        </Col>
        <Col sm={2}>
          <div style={{ marginBottom: '5px' }}>
            <FontAwesome style={{ fontSize: '26px', marginRight: '10px' }} name='fas fa-gas-pump'/>
            {item.tankSize + ' l'}
          </div>
          <div>
            <Right style={{marginRight: '10px'}} can={item.bookingActivityId}/>
            <span>Existuje vazba na činnosti v uč. systému</span>
          </div>
          <div>
            <Right style={{marginRight: '10px'}} can={item.bookingCentreId}/>
            <span>Existuje vazba na středisko v uč. systému</span>
          </div>
        </Col>
      </Row>
    </div>)
  }
}

VehiclePreview.propTypes = {
  isForLog: PropTypes.bool,
 ...BaseViewPropTypes
};

VehiclePreview.defaultProps = {
  isForLog: false
};

export {
  getColorOfStk,
  SmallVehiclePreview,
}
