import {IDiet, ISalary} from "../../users/Salary";
import {FormControl} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import React from "react";

interface Props {
  salary?: ISalary | null,
    diets: IDiet[],
    customItems: {
    name: string;
    price: number;
    fixed: boolean;
  }[],
  originCustomItems: {
    name: string;
    price: number;
    fixed: boolean;
  }[]
    changed: (list: {
    name: string;
    price: number;
    fixed: boolean;
  }[]) => void;
  deleted: (index: number) => void;
}

const SalaryCustomItems = (props: Props) => {
  const {customItems, originCustomItems, diets } = props;
  return props.customItems.map((item, index) => {
    return <tr key={index}>
      <td>
        <FormControl
          style={{paddingRight: '10px', fontSize: '11px', display: 'inline-block', textAlign: 'right' }}
          id="ItemCustomName"
          disabled={item.fixed}
          value={item.name}
          min={0}
          onChange={(e) => {
            const list = originCustomItems.slice();
            list[index].name = (e.target as HTMLInputElement).value;
            props.changed(list);
          }}
          type='text'/>
      </td>
      <td colSpan={16 + diets.length}/>
      <td>
        <FormControl
          style={{paddingRight: '10px', fontSize: '11px', display: 'inline-block', textAlign: 'right' }}
          id="ItemCustomPrice"
          disabled={item.fixed}
          value={item.price}
          onChange={(e) => {
            const list = originCustomItems.slice();
            list[index].price = parseFloat((e.target as HTMLInputElement).value);
            props.changed(list);
          }}
          type='number'/>
      </td>
      {!item.fixed && <td style={{ backgroundColor: 'transparent', border: 0 }}>
        <button className='transparentButton' onClick={() => props.deleted(index)}>
          <FontAwesome style={{fontSize:'14px', marginLeft: '5px'}} name='fas fa-times' />
        </button>
      </td>}

    </tr>
  })
}

export default SalaryCustomItems;