import React, { Component } from 'react';
import { BaseViewPropTypes } from '../CommonListContainer';
import { Row, Col, ControlLabel } from "react-bootstrap";
import PropTypes from "prop-types";
import {S} from "./EditablePreview";
import {SmallUserPreview} from "../users/Preview";


export default class TaskPreview extends Component {

  render() {

    const { item } = this.props;
    let style = {
      date: {
        paddingTop: '4px',
        paddingBottom: '4px',
        paddingLeft: '20px',
        paddingRight: '20px',
        borderRadius: '5px',
        margin: '2px',
        width: 'fit-content',
      }
    };
    return (
      <div>
        <Row>
          <Col sm={2}>
            <ControlLabel>
              <div style={{ marginTop: '9px' }}>
                {item.name}
              </div>
            </ControlLabel>
          </Col>
          <Col sm={4}>
            <ControlLabel>Typ úkolu</ControlLabel>
            <S.status style={{ display: 'inline-block', marginRight: '20px'  }} background={'rgb(0,0,0)'} color='white'>
              {item.diagram.name}
            </S.status>
            <ControlLabel>Stav</ControlLabel>
            <S.status style={{ display: 'inline-block' }} background={item.isConfirmed ? 'rgb(28,205,19)' : 'rgb(255,165,0)'} color='white'>
              {item.actualStateName}
            </S.status>
          </Col>
          <Col>
            <ControlLabel>Zodpovědní</ControlLabel>
            {
              item.userLinks.map((link, index) => <S.status key={index} style={{ display: 'inline-block', marginLeft: '15px' }} background={'rgb(0,0,0)'} color='white'>
                <SmallUserPreview iconColor={'white'} style={{ display: 'inline-block', color: 'white' }} item={link.user}/>
              </S.status>)
            }
          </Col>
        </Row>
      </div>
    )
  }
}

TaskPreview.propTypes = {
  isForLog: PropTypes.bool,
  ...BaseViewPropTypes
};

TaskPreview.defaultProps = {
  isForLog: false
};