import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {getBaseUrl} from "../../../AppObjects";
import {OverlayTrigger, Popover} from "react-bootstrap";
import FontAwesome from "react-fontawesome";

export default class Marker extends Component {
  constructor(props) {
    super(props);
  }

  hideOverlay(){
    this.refs.overlay.hide();
  }

  render() {
    const {id, color, type, morePrintOptions, disableClick} = this.props;
    let agent = null;
    let printId = null;
    switch (type) {
      case 'orderForeignId':
        agent = 'vydane_objednavky';
        printId = '380';
        break;
      case 'incomeInvoiceForeignId':
        agent = 'prijate_faktury';
        printId = '2600';
        break;
      case 'invoiceForeignId':
        agent = 'vydane_faktury';
        printId = '190';
        //printId = '235';
        break;
      default:
        break;
    }

    if (id === null || typeof id === 'undefined')
      return null;

    if (disableClick)
      return <div style={{ backgroundColor: color, cursor: 'auto' }} className='buttonMarker'/>

    if (morePrintOptions.length > 0) {
      return <OverlayTrigger ref='overlay' trigger='click' placement='right' overlay={
        <Popover id={`popover-contained ${id}`}>
          {
            morePrintOptions.map((item,index) =>
              <a onClick={() => this.hideOverlay()} style={{marginRight: '10px'}} target="_blank" href={`${getBaseUrl()}/items/pohoda/print?id=${id}&agenda=${agent}&printId=${item.id}`}>{item.label}</a>
            )
          }
          <button className='text-right transparentButton' onClick={(e) => {
            this.hideOverlay();
          }}>
            <FontAwesome style={{fontSize:'13px', color: 'black', }} name='times' />
          </button>
        </Popover>
      }>
        <div style={{ backgroundColor: color }} className='buttonMarker'></div>
      </OverlayTrigger>
    }
    return <a style={{ backgroundColor: color }} className='buttonMarker' target="_blank" href={`${getBaseUrl()}/items/pohoda/print?id=${id}&agenda=${agent}&printId=${printId}`}/>
  }
}

Marker.propTypes = {
  color: PropTypes.any,
  id: PropTypes.any,
  type: PropTypes.string,
  morePrintOptions: PropTypes.arrayOf(PropTypes.string),
  disableClick: PropTypes.bool,
};

Marker.defaultProps = {
  color: '#000000',
  morePrintOptions: [],
  disableClick: false,
};
