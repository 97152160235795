import React, { Component } from 'react';
import { BaseViewPropTypes } from '../CommonListContainer';
import FontAwesome from "react-fontawesome";

export default class SpacePreview extends Component {
  render() {
    const { item } = this.props;
    return (
      <div>
        <span>
          <FontAwesome name='fas fa-building'/>
        </span>
        <span style={{marginLeft: '30px'}}>
          {item.name}
        </span>
      </div>
    )
  }
}

SpacePreview.propTypes = BaseViewPropTypes;