import * as React from 'react';
import * as _ from 'lodash';
import { TrayWidget } from './TrayWidget';
import { TrayItemWidget } from './TrayItemWidget';
import { DefaultNodeModel, DiagramEngine, DiagramModel, NodeModel, LinkModel} from '@projectstorm/react-diagrams';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import styled from '@emotion/styled';
import {RightAnglePortModel} from "./Defaults";
import {BoolSwitchNodeModel, EndNodeModel} from "./nodes/flowControlNodes/FlowControlModels";
import {CmlPortModel} from "./nodes/ports/CmlPortModel";
import {TaskNodeModel} from "./nodes/taskDescriptionNodes/TaskDescriptionModels";
import FontAwesome from 'react-fontawesome';
import ModalHeader from 'react-bootstrap/lib/ModalHeader';
import { Modal, ModalBody } from 'react-bootstrap';
import {createCmlEngine} from "../Diagram";

export interface BodyWidgetProps {
	diagram: DiagramEngine;
	readonly?: boolean;
}

const S = {
	Body: styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		min-height: 100%;
		background-color: #333333;
	`,
	Header: styled.div`
		display: flex;
		background: rgb(30, 30, 30);
		flex-grow: 0;
		flex-shrink: 0;
		color: white;
		font-family: Helvetica, Arial, sans-serif;
		padding: 10px;
		align-items: center;
	`,
	Content: styled.div`
		display: flex;
		flex-grow: 1;
		background-color: rgb(60, 60, 60);
		background-image: 
		linear-gradient(#474747 1px, transparent 1px),
		linear-gradient(90deg, #474747 1px, transparent 1px);
		background-size: 20px 20px, 20px 20px;
		background-position:-2px -2px, -2px -2px;
	`,
	Layer: styled.div`
		position: relative;
		flex-grow: 1;
	`
};

export interface BodyWidgetState {
	locked: boolean;
	modal: boolean;
}

export class BodyWidget extends React.Component<BodyWidgetProps, BodyWidgetState> {
	private secondDiagram: DiagramEngine | null;

	constructor(props: BodyWidgetProps) {
		super(props);
		this.state = {
			locked: props.readonly ? true : props.diagram.getModel().isLocked(),
			modal: false,
		};
		this.secondDiagram = null;
		if (props.readonly) {
			this.secondDiagram = createCmlEngine();
			const model = new DiagramModel();
			model.deserializeModel(props.diagram.getModel().serialize(), this.secondDiagram);
			this.secondDiagram.setModel(model);
		}

	}

	componentDidMount(): void {
		this.props.diagram.zoomToFit();
		this.props.diagram.getModel().setLocked(typeof this.state.locked === 'undefined' ? false : this.state.locked);
		if (this.props.readonly && this.secondDiagram !== null) {
			this.secondDiagram.getModel().setLocked(this.state.locked);
		}
	}

	render() {
		const { diagram, readonly } = this.props;
		const { modal } = this.state;
		if (readonly && this.secondDiagram !== null) {
			return <>
				<span onClick={() => {
					this.props.diagram.getModel().setZoomLevel(diagram.getModel().getZoomLevel() + 50);
					this.setState({ modal: true });
				}}>
				<CanvasWidget className='srd-diagram' engine={this.props.diagram} />
			</span>
				<Modal dialogClassName="modal-90w"  show={modal} onHide={() => {
					this.props.diagram.getModel().setZoomLevel(diagram.getModel().getZoomLevel() - 50);
					this.setState({ modal: false });
				}}>
					<ModalHeader closeButton>
						<Modal.Title>Náhled diagramu úkolu</Modal.Title>
					</ModalHeader>
					<ModalBody>
						<div style={{ height: '600px' }}>
							<CanvasWidget className='srd-diagram' engine={this.secondDiagram} />
						</div>
					</ModalBody>
				</Modal>
			</>
		}
		return (
			<S.Body >

				<S.Content>
					<TrayWidget>
						<S.Header>
							<div className="title">Diagram chování</div>
						</S.Header>
						<div style={{ padding: '3px', paddingTop: '10px',  }}>
							<TrayItemWidget diagram={diagram} model={{ type: 'endnode', isInput: false }} name="Start" color="rgb(192,255,0)" />
							<TrayItemWidget diagram={diagram} model={{ type: 'endnode', isInput: true }} name="Stop" color="rgb(192,255,0)" />
							<TrayItemWidget diagram={diagram} model={{ type: 'tasknode' }} name="In Node" color="rgb(192,255,0)" />
						</div>
					</TrayWidget>
					<S.Layer

						onDrop={event => {
							const data = JSON.parse(event.dataTransfer.getData('storm-diagram-node'));
							const nodesCount = _.keys(
								this.props.diagram
									.getModel()
									.getNodes()
							).length;

							let node = null;
							if (data.type === 'tasknode') {
								node = new TaskNodeModel({
									name: 'Proces ' + (nodesCount + 1),
									color: 'rgb(255,165,0)',
								});
								node.addPort(new CmlPortModel(true, "input", "Vstup", false));
								node.addPort(new CmlPortModel(false, "out-1", "OK", false));
							} else if (data.type === 'endnode') {
								node = new EndNodeModel('Node ' + (nodesCount + 1), data.isInput);
							} else if (data.type === 'boolSwitch') {
                                node = new BoolSwitchNodeModel();
                            } else {
								node = new DefaultNodeModel('Node ' + (nodesCount + 1), 'rgb(0,192,255)');
							}
							const point = this.props.diagram.getRelativeMousePoint(event);
							node.setPosition(point);
							this.props.diagram
								.getModel()
								.addNode(node);
							this.forceUpdate();
						}}
						onDragOver={event => {
							event.preventDefault();
						}}>
						<S.Header>
							<button type='button' onClick={() => {
								diagram.getModel().setZoomLevel(diagram.getModel().getZoomLevel() + 5);
								diagram.repaintCanvas();
							}} style={{ padding: '0px', paddingLeft: '5px' }} className='transparentButton'>
								<FontAwesome name='fas fa-search-plus'/>
							</button>
							<button type='button' onClick={() => {
								diagram.getModel().setZoomLevel(diagram.getModel().getZoomLevel() - 5);
								diagram.repaintCanvas();
							}} style={{ padding: '0px', paddingLeft: '5px' }} className='transparentButton'>
								<FontAwesome name='fas fa-search-minus'/>
							</button>
							<button type='button' onClick={() => {
								diagram.zoomToFit();
							}} style={{ padding: '0px', paddingLeft: '5px' }} className='transparentButton'>
								<FontAwesome name='fas fa-compress'/>
							</button>
							<button type='button' onClick={() => {
								this.setState({ locked: !diagram.getModel().isLocked()});
								diagram.getModel().setLocked(!diagram.getModel().isLocked());
							}} style={{ padding: '0px', paddingLeft: '5px' }} className='transparentButton'>
								<FontAwesome name={'fas fa-' + (!this.state.locked ? 'lock-open': 'lock')}/>
							</button>
						</S.Header>
						<CanvasWidget className='srd-diagram' engine={this.props.diagram} />
					</S.Layer>
				</S.Content>
			</S.Body>
		);
	}
}
