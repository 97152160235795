import React, { Component } from 'react';
import {Checkbox, ControlLabel, DropdownButton, MenuItem} from "react-bootstrap";
import request from 'axios';
import {SmallUserPreview} from "../users/Preview";
import { BaseFilterPropTypes } from '../CommonListContainer';
import {getFilterList, mapAuthStateToProps} from "../../AppObjects";
import PropTypes from 'prop-types';
import Filter from "../utils/Filter";
import FontAwesome from "react-fontawesome";
import {connect} from "react-redux";
import qs from 'qs';

const tableTranslation = {
  'datarow': 'Dispečink',
  'addressBook': 'Adresář',
  'attributes': 'Atributy',
  'attendance': 'Docházka',
  'roles': 'Role',
  'sms': 'Sms',
  'users': 'Uživatelé',
  'vehicles': 'Vozidla',
  'tasks': 'Úkoly',
  'foreignComInvoice': 'Vydané faktury'
};

const tableActionType = {
  0: {
    name: 'Přidáno',
    icon: 'fas fa-plus',
    color: 'green',
  },
  1: {
    name: 'Upraveno',
    icon: 'fas fa-pen',
    color: 'orange',
  },
  2: {
    name: 'Vymazáno',
    icon: 'fas fa-trash',
    color: 'red',
  },
  3: {
    name: 'Stornováno',
    icon: 'fas fa-ban',
    color: 'red',
  }

};

class LogFiltr extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      focusedInput: null,
      userFilterValue: '',
      tableNameFilter: null,
      actionType: null,
    }
  }

  render() {
    return (
      <div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll filterStyle'>

            <DropdownButton
              title="Filter uživatelů"
              id='UserDropDown'>
              <Filter filterValue={this.state.userFilterValue}
                      onValueChange={(e) => this.setState({userFilterValue: e.target.value})}/>
              {
                Object.keys(this.state.users).map((key) => {
                  if (!(this.state.users[key].name + this.state.users[key].surname).toLowerCase()
                      .includes(this.state.userFilterValue.toLowerCase()))
                    return null;
                  return <Checkbox key={key}
                                   checked={this.state.users[key].filtered}
                                   onChange={(checked) => this.onFilterChange('users', checked, key)}
                                   style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <SmallUserPreview item={this.state.users[key]}/>
                  </Checkbox>
                })
              }
            </DropdownButton>
            <ControlLabel style={{marginLeft: '10px', marginRight: '10px'}}>Filter položek</ControlLabel>
            <DropdownButton
              onSelect={(e) => {
                this.setState({tableNameFilter: e});
                const users = getFilterList(this.state.users.slice());
                let filtr = {
                  where: {
                    userId: users.length === 0 ? undefined : users,
                    tableName: e,
                    actionType: this.state.actionType,
                  }
                };
                if (this.state.actionType === null )
                  delete filtr.where.actionType;
                if (e === null )
                  delete filtr.where.tableName;
                this.props.onFilter(filtr);
              }}
              title={this.state.tableNameFilter === null ? 'Všechny' : tableTranslation[this.state.tableNameFilter]}
              id='tableDropDown'>
              <MenuItem eventKey={null}>Všechny</MenuItem>
              {
                Object.keys(tableTranslation).map((key) =>
                  <MenuItem eventKey={key} key={key}>{tableTranslation[key]}</MenuItem>
                )
              }
            </DropdownButton>
            <ControlLabel style={{marginLeft: '10px', marginRight: '10px'}}>Filter akce</ControlLabel>
            <DropdownButton
              onSelect={(e) => {
                this.setState({actionType: e});
                const users = getFilterList(this.state.users.slice());
                let filtr = {
                  where: {
                    userId: users.length === 0 ? undefined : users,
                    tableName: this.state.tableNameFilter,
                    actionType: e,
                  }
                };
                if (e === null )
                  delete filtr.where.actionType;
                if (this.state.tableNameFilter === null )
                  delete filtr.where.tableName;
                this.props.onFilter(filtr);
              }}
              title={this.state.actionType === null ? 'Všechny' : tableActionType[this.state.actionType].name}
              id='tableDropDownAction'>
              <MenuItem eventKey={null}>Všechny</MenuItem>
              {
                Object.keys(tableActionType).map((key) =>
                  <MenuItem eventKey={key} key={key}>
                    <span>
                      <FontAwesome style={{marginRight: '15px', fontSize:'18px', color: tableActionType[key].color}} name={tableActionType[key].icon} />
                    </span>
                    <span>
                      {tableActionType[key].name}
                    </span>
                  </MenuItem>
                )
              }
            </DropdownButton>

      </div>
    )
  }

  onFilterChange(name, item, key) {
    let list = this.state[name];
    if (key === '0') {
      Object.keys(list).map((keyInter) => {
        list[keyInter].filtered = keyInter === '0' && item.target.checked;
        return null;
      });
    } else {
      list[0].filtered = false;
      list[key].filtered = item.target.checked;
    }

    this.setState({
      [name]: list,
    });

    const users = getFilterList(this.state.users.slice());
    let filtr = {
      where: {
        userId: users.length === 0 ? undefined : users,
        tableName: this.state.tableNameFilter,
        actionType: this.state.actionType,
      }
    };
    if (this.state.actionType === null )
      delete filtr.where.actionType;
    if (this.state.tableNameFilter === null )
      delete filtr.where.tableName;

    this.props.onFilter(filtr);
  }

  componentWillMount() {
    this.requestUsers();
  }

  initFiltr(name, list) {
    Object.keys(list).map(function (key) {
      list[key].filtered = key === '0';
      return null;
    });
    this.setState({ [name]: list });
  }

  requestUsers() {
    const { authenticate } = this.props;
    request.get('/items/users', {
      params: {attributes: ['name', 'id', 'surname'] },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
      .then((res) => {
        if (res.status === 200) {
          let list = [];
          list.push({ name: 'Všichni', surname: '' });
          if (authenticate.actualUserRole.attendance.onlySelf)
            list.push(...res.data.filter(o => o.id === authenticate.loggedUser.id));
          else
            list.push(...res.data);

          this.initFiltr('users', list);
        }
      })
  }
}

LogFiltr.propTypes = {
  items: PropTypes.array.isRequired,
  authenticate: PropTypes.object,
  ...BaseFilterPropTypes,
};

export default connect(mapAuthStateToProps)(LogFiltr);

