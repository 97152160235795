import { createContext } from 'use-context-selector';
import {Dispatch} from "react";

export const SET_BASE_SALARY_STATE = 'SET_BASE_SALARY_STATE';
export const SET_ERROR_FLAG = 'SET_ERROR_FLAG';
export const SET_CHANGED_FLAG = 'SET_CHANGED_FLAG';

interface State {
  overriderFinalSalary: {
    [key: string]: {
      enabled: boolean,
      salary: number,
      salaryStrategy: number,
      canChangeSalaryStrategy: boolean,
    };
  } | null;
  dietUnknownInfo: {
    [key: string]: {
      canUseTravelTime: boolean;
      travelTime: number;
      travelFixedPrice: number;
    }
  } | null;
  customItems: {
    name: string;
    price: number;
    fixed: boolean;
  }[];
  fond: number | null;
  attendanceError: boolean;
  attendanceChanged: boolean;
  rowExistsButGpsNot: boolean;
  totalExtraHours: number;
  totalSavedOvertimeHours: number;
  totalGpsHours: number;
}


const SalaryStateContext =
  // @ts-ignore
  createContext<{state: State, dispatch: Dispatch<SalaryActionTypes>}>({});

interface SetBaseSalaryState {
  type: typeof SET_BASE_SALARY_STATE,
  payload: Partial<State>
}

interface SetErrorFlag {
  type: typeof SET_ERROR_FLAG,
  flag: boolean;
}

interface SetChangedFlag {
  type: typeof SET_CHANGED_FLAG,
  flag: boolean;
}

export type SalaryActionTypes = SetBaseSalaryState | SetErrorFlag | SetChangedFlag;

export const setBaseState = (payload: Partial<State>): SetBaseSalaryState => ({
  type: SET_BASE_SALARY_STATE,
  payload,
})

export const setAttendanceError = (flag: boolean): SetErrorFlag => ({
  type: SET_ERROR_FLAG,
  flag,
})

export const setAttendanceChanged = (flag: boolean): SetChangedFlag => ({
  type: SET_CHANGED_FLAG,
  flag,
})

const reducer = (state: State, action: SalaryActionTypes) => {
  if (action.type === "SET_BASE_SALARY_STATE") {
    let baseState = {
      ...state,
      ...action.payload
    }
    if (action.payload['overriderFinalSalary']) {
      baseState.overriderFinalSalary = {...action.payload['overriderFinalSalary']}
    }
    if (action.payload['dietUnknownInfo']) {
      baseState.dietUnknownInfo = {...action.payload['dietUnknownInfo']}
    }
    return baseState;
  } else if (action.type === "SET_ERROR_FLAG") {
    return {
      ...state,
      attendanceError: action.flag,
    }
  } else if (action.type === "SET_CHANGED_FLAG") {
    return {
      ...state,
      attendanceChanged: action.flag,
    }
  }
  return state;
}

export {
  SalaryStateContext,
  reducer,
}