import React, {Component, useEffect, useState} from "react";
import request from 'axios';
import ItemsSelector from "../ItemsSelector";
import store from "../../redux/store";
import { Button } from "react-bootstrap";

interface IVolvoDriver {
  id?: string;
  driverId: {
    id: string;
  };
  displayName: string;
}

interface IWebDriver {
  id?: string;
  iddriver: string;
  displayName?: string;
  jmeno: string;
  prijmeni: string;
}

export interface IForeignApiDriverId {
  type: string,
  driver: IWebDriver | IVolvoDriver
}

interface Props {
  foreignApiDriverId: IForeignApiDriverId[];
  onDriverChange: (drivers: object[]) => void;
}

export default function LoadUsersForeign(props: Props) {
    const [show, setShow] = useState(false);
    const [volvoDrivers, setVolvoDrivers] = useState([]);
    const [webDrivers, setWebDrivers] = useState([]);
    const [load, setLoad] = useState(false);

    useEffect(() => {

      /*request.get('/items/volvoForeignDrivers')
        .then(res => {
          setVolvoDrivers(res.data.map((o: IVolvoDriver) => ({
            id: o.driverId.id,
            ...o,
          })))
        })*/
      if(load) {
        request.get('/items/webForeignDrivers')
          .then(res => {
            setWebDrivers(res.data.map((o: IWebDriver) => ({
              displayName: o.jmeno + " " + o.prijmeni,
              id: o.iddriver,
              ...o,
            })));
          })
      }
    }, [load])

    const onSelect = (type: string) => (item: object) => {
      const list = props.foreignApiDriverId.slice();
      const matchIndex = list.findIndex(o => o.type === type);
      if (matchIndex > -1) {
        list[matchIndex].driver = item as IVolvoDriver | IWebDriver;
      } else {
        list.push({
          type,
          driver: item as IVolvoDriver | IWebDriver,
        });
      }
      props.onDriverChange(list);
    }

    if(!props.foreignApiDriverId) {
      return null;
    }
    const volvoDefault = props.foreignApiDriverId.find(o => o.type === 'volvoApi');
    const webDefault = props.foreignApiDriverId.find(o => o.type === 'webDisApi');

  return <div className='dataTable'>
      <table>
        <tbody>
          <tr>
            <th>Služba</th>
            <th>Jméno</th>
          </tr>
          {volvoDrivers.length > 0 && <tr>
            <td>VOLVO</td>
            <td>
              <ItemsSelector
                appendItemsToStart={[
                  {id: 0, displayName: 'Nevybrán'}
                ]}
                onSelected={onSelect('volvoApi')}
                itemsToFetch='users'
                value='displayName'
                defaultSelectedId={volvoDefault ? volvoDefault.driver.id : undefined}
                externList={volvoDrivers}
                filterEnable
              />
            </td>
          </tr>}
          {!load && <Button onClick={() => setLoad(true)}>Načíst data</Button>}
          {(webDrivers.length > 0 && load) && <tr>
            <td>Webdispečink</td>
            <td>
              <ItemsSelector
                appendItemsToStart={[
                  {id: 0, displayName: 'Nevybrán'}
                ]}
                onSelected={onSelect('webDisApi')}
                itemsToFetch='users'
                value='displayName'
                defaultSelectedId={webDefault ? ((webDefault.driver.id &&
                  // @ts-ignore
                  webDrivers.find(o => o.id === webDefault.driver.id) ? webDefault.driver.id : undefined)) : undefined}
                externList={webDrivers}
                filterEnable
              />
            </td>
          </tr>}
        </tbody>
      </table>
    </div>;
}