import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState} from "@cml/redux-store";
import {DataRow as DataRowModel, Roles} from "@cml/models";
import {IDispatchingAttributesCustom} from "@cml/types";
import {
  getAttributes,
  getUsersForDispatching,
} from "../../../fetchApi/dispatching";
import {dateFromat} from "../../../AppObjects";
import {Moment} from "moment";
import {LoadingOrRender} from "../../../loginsrc/Profile";
import {Button} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import React, {useEffect, useState} from "react";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {Prompt} from "react-router";

interface Props {
  selectedDate: Moment;
  onLoadRequested: () => void;
  filterUsed: boolean;
  onSave: () => void;
}

export default function SaveButton({ selectedDate, onLoadRequested, onSave, filterUsed }: Props) {
  const [submitActive, setSubmitActive] = useState<boolean>(false);

  const dataRows = useSelector((state: {dispatching: DataRowsState}) => state.dispatching.dataRows);
  const changesMadeState = useSelector((state: {dispatching: DataRowsState}) => state.dispatching.changesMade);

  const { users, isValidating: usersValidating, revalidate: usersRevalidate } = getUsersForDispatching(
    { params: { date: selectedDate.format(dateFromat) }});
  const { attributes, isValidating: attributesValidating } = getAttributes('dispatching');

  const authenticate = useSelector(
    (state: { authenticate: AuthenticateState}) => state.authenticate);

  const dispatchingRole = (authenticate.actualUserRole as Roles).dispatching as DispatchingRights;

  const checkRowsForErrors = (rows: DataRowModel[]) => {
    if (!users)
      return 0;
    let ret = 1;
    for (let i = 0; i < rows.length; i++) {
      const item = rows[i];
      let attr = attributes[attributes.findIndex(o => o.id === item.attributeId)];
      if (!attr) {

      } else {
        if (!(attr.customOptions as IDispatchingAttributesCustom).userAsAddressBook) {
          if ((item.userId === 0 && item.userId_a === 0
            && (attr.customOptions as IDispatchingAttributesCustom).canUser)) {
            ret = 2;
            return ret;
          }
        } else {

          if (item.addressBookId === 0) {
            ret = 2;
            return ret;
          }
        }
        let userIndex = users.findIndex(o => o.id === item.userId);

        if (userIndex > -1 && !filterUsed) {
          if (users[userIndex].attendance.length > 0) {
            if (users[userIndex].attendance.filter(o => !o.isPartDay).length > 0) {
              ret = 2;
              return ret;
            }
          }
        }
        if (((item.vehicleId !== 0 || item.vehicleId_a !== 0)
          && (attr.customOptions as IDispatchingAttributesCustom).canVehicle)
          || ((item.subOriginVehicleId !== 0 || item.subOriginVehicleId_a !== 0)
            && (attr.customOptions as IDispatchingAttributesCustom).canRealVehicle)) {
          ret = 1;
          //return ret;
        } else {
          ret = 2;
          return ret;
        }
      }
    }
    return ret;
  }

  const changesMade = checkRowsForErrors(dataRows) === 2 ? 2 : changesMadeState;

  useEffect(() => {
    if (changesMade) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = null
    }
  }, [changesMade, !users])

  if (!attributes || attributesValidating)
    return null;

  if(!users)
    return <div>Loading</div>

  let saveButtonStyle = null;
  let message = null;
  switch (changesMade) {
    case 0: saveButtonStyle = 'success';
      break;
    case 1:
      saveButtonStyle = 'warning';
      message = <span>
        <FontAwesome style={{fontSize:'18px', color: 'orange', marginRight: '10px' }} name='exclamation' />
        Tabulka obsahuje neuložené změny
      </span>;
      break;
    case 2: saveButtonStyle = 'danger';
      message = <span>
        <FontAwesome style={{fontSize:'18px', color: 'red', marginRight: '10px' }} name='exclamation' />
        Tabulka má chybný údaj, nelze uložit
      </span>;
      break;
    default: saveButtonStyle = 'success';
      break;
  }

  return <>
    <Prompt
      when={changesMade > 0}
      message="Data nejsou uložena opravdu chcete pokračovat ??"
    />
    <span style={{marginLeft: '10px', marginRight: '10px'}}>
            <LoadingOrRender requestActive={submitActive}>
              <Button
                disabled={changesMade !== 1 || !dispatchingRole.update}
                bsStyle={saveButtonStyle}
                onClick={onSave}
              >
              Uložit
            </Button>
            </LoadingOrRender>
          </span>
    <span style={{marginLeft: '30px'}}>
                { message }
      { changesMade !== 0 && <button onClick={onLoadRequested} title='Zahodit změny' style={{ fontSize: '16px', marginLeft: '10px', }} className='transparentButton'>
        <FontAwesome  name='fas fa-times'/>
      </button> }
    </span>
  </>
}