import React, { Component } from 'react';
import {Form, FormControl, FormGroup, HelpBlock} from 'react-bootstrap';
import request from 'axios';
import PropTypes from 'prop-types';
import SubmitButton from './SubmitButton';
import FontAwesome from 'react-fontawesome';
import {passwordChecker} from '../AppObjects';
import store from "../redux/store";
import { show } from 'react-notification-system-redux';
import {rememberEmailForNextUse} from "@cml/redux-store";

const formObject = {
  email: 'email',
  validationToken: 'validationToken',
  password: 'password',
  password2: 'password2',
};

export default class ChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state= {
      email: '',
      password: '',
      password2: '',
      focusedInput: '',
      requestActive: false,
      emailOk: false,
      validationToken: '',
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.emailOk) {
      if (this.checkEmail(true)) {
        this.setState({ requestActive:true });
        store.dispatch(rememberEmailForNextUse(this.state.email));
        request.put('/changeuserpassword', {
          email: this.state.email,
        }, { timeout: 4000})
          .then((res) => {
            if (res.status === 200) {
              this.setState({
                emailOk: true,
              })
            }
            this.setState({ requestActive:false });
          })
          .catch((err) => {
            if (err.status === 403) {
              store.dispatch(show({
                title: 'Chyba !',
                level: 'error',
                autoDismiss: 3,
                children: (
                  <div>
                    Zadaný email nenalezen !!
                  </div>
                ),
              }, 'error'));
            }
            this.setState({ requestActive:false });
          })
      }
    } else {
      if (this.checkInputs(true)) {
        this.setState({ requestActive:true });
        request.put('/changeuserpassword2', {
          validationToken: this.state.validationToken,
          password: this.state.password,
        }, { timeout: 4000 })
          .then((res) => {
            if (res.status === 200) {
              this.props.history.push('/login');
              return;
            }
            this.setState({ requestActive:false });
          })
          .catch((err) => {
            if (err.status === 403) {
              store.dispatch(show({
                title: 'Chyba !',
                level: 'error',
                autoDismiss: 3,
                children: (
                  <div>
                    Zadaný token není spravny !!
                  </div>
                ),
              }, 'error'));
            }
            this.setState({ requestActive:false });
          })
      }
    }
  }

  checkEmail(canFocus) {
    let helpMessage = [];
    helpMessage[formObject.email] = {
      message: null,
      error: this.state.email.length < 1,
    };

    switch (this.state.focusedInput) {
      case formObject.email:
        if (helpMessage[formObject.email].error)
          helpMessage[formObject.email].message = <HelpBlock>
            <ul>
              <li>Email musí být vyplněn</li>
            </ul>
          </HelpBlock>;
        break;
      default:
        break;
    }
    let ok = true;
    if(canFocus) {
      for (let key in helpMessage) {
        if(helpMessage[key].error){

          this.setState({focusedInput: key});
          ok = false;
          break;
        }
      }
      return ok;
    }

    return helpMessage;
  }

  checkInputs(canFocus) {
    let passStatus = passwordChecker(this.state.password);
    let pass2Status = passwordChecker(this.state.password2);
    let passSame = this.state.password2 === this.state.password;
    let notSameMessage = !passSame && !pass2Status.error ? <HelpBlock>
      <ul>
        <li>Hesla nejsou stejná !</li>
      </ul>
    </HelpBlock> : null;

    let helpMessage = [];

    helpMessage[formObject.validationToken] = {
      message: null,
      error: this.state.validationToken.length < 1,
    };
    helpMessage[formObject.password] = {
      message: null,
      error: passStatus.error,
    };
    helpMessage[formObject.password2] = {
      message: null,
      error: pass2Status.error | !passSame,
    };
    switch (this.state.focusedInput) {
      case formObject.validationToken:
        if (helpMessage[formObject.validationToken].error)
          helpMessage[formObject.validationToken].message = <HelpBlock>
            <ul>
              <li>Validacni kod musí být vyplněn</li>
            </ul>
          </HelpBlock>;
        break;
      case formObject.password:
        helpMessage[formObject.password].message = passStatus.messages;
        break;
      case formObject.password2:
        helpMessage[formObject.password2].message = <div>
          {pass2Status.messages}
          {notSameMessage}
        </div>;
        break;
      default:
        break;
    }
    let ok = true;
    if(canFocus) {
      for (let key in helpMessage) {
        if(helpMessage[key].error){

          this.setState({focusedInput: key});
          ok = false;
          break;
        }
      }
      return ok;
    }
    return helpMessage;
  }


  render() {

    let helpMessage = this.checkInputs(false);
    let helpMessageEmail = this.checkEmail(false);

    let toRender = null;
    if(this.state.emailOk) {
      toRender = <div>
        <FormGroup controlId={formObject.validationToken}
                   validationState={this.state.validationToken.length > 0 ? null:'error'}>
          <FormControl type="text"
                       value={this.state.validationToken}
                       placeholder="Validační kod"
                       onChange={(e) => this.setState({validationToken: e.target.value})}/>
          {helpMessage[formObject.validationToken].message}
        </FormGroup>
        <FormGroup controlId={formObject.password}
                   validationState={helpMessage[formObject.password].error ? 'error' : 'success'}
        >
          <FormControl type="password"
                       value={this.state.password}
                       placeholder="Heslo"
                       onFocus={() => this.setState({focusedInput: formObject.password})}
                       onChange={(e) => this.setState({password: e.target.value})}/>
          {helpMessage[formObject.password].message}
        </FormGroup>


        <FormGroup controlId={formObject.password2}
                   validationState={helpMessage[formObject.password2].error ? 'error' : 'success'}
        >
          <FormControl type="password"
                       value={this.state.password2}
                       placeholder="Heslo znovu"
                       onFocus={() => this.setState({focusedInput: formObject.password2})}
                       onChange={(e) => this.setState({password2: e.target.value})}/>
          {helpMessage[formObject.password2].message}
        </FormGroup>

        <FormGroup>
          <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.requestActive}>Registrovat</SubmitButton>
        </FormGroup>
      </div>;
    }
    else {
      toRender = <div>
        <FormGroup controlId={'email'}
                   validationState={this.state.email.length > 0 ? null:'error'}>
          <FormControl type="email"
                       value={this.state.email}
                       placeholder="Email"
                       onFocus={() => this.setState({focusedInput: formObject.email})}
                       onChange={(e) => this.setState({email: e.target.value})}/>
          {helpMessageEmail[formObject.email].message}
        </FormGroup>
          <FormGroup>
            <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.requestActive}>Pokračovat</SubmitButton>
          </FormGroup>
        </div>

    }

    return <div className="login-clean">

          <Form onSubmit={(e) => this.handleSubmit(e)} horizontal>
            <div className='illustration'>
              <FontAwesome name='fas fa-unlock-alt' />
            </div>
            {toRender}
          </Form>

    </div>
  }
}

ChangePassword.propTypes = {

};
