import React, { Component } from 'react';
import {Col, DropdownButton, Row, FormControl, MenuItem} from "react-bootstrap";
import FontAwesome from "react-fontawesome";

const isAvailableNames = {
  all: 'Vše',
  available: 'Na skladě',
  unavailable: 'Nedostupné',
};

export default class ToolsFiltr extends Component{

  constructor(props){
    super(props);
    this.state = {
      filterValue: '',
      isAvailable: 'all',
    }
  }

  render() {
    return (<div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll'>
      <Row>
        c
        <Col sm={2}>
          <DropdownButton id='isAvailableFiltr'
                          onSelect={(key) => {
                            this.setState({ isAvailable: key });
                            this.onFilterChange(this.state.filterValue, key)
                          }}
                          title={isAvailableNames[this.state.isAvailable]}>
            <MenuItem eventKey='all'>{isAvailableNames.all}</MenuItem>
            <MenuItem eventKey='available'>{isAvailableNames.available}</MenuItem>
            <MenuItem eventKey='unavailable'>{isAvailableNames.unavailable}</MenuItem>
          </DropdownButton>
        </Col>
      </Row>
    </div>)
  }

  onFilterChange(filterValue, isAvailable) {

    let isAvailableWhere = null;
    if (isAvailable !== 'all')
      isAvailableWhere = {
        isAvailable: isAvailable === 'available' ? 1 : 0,
      };

    this.props.onFilter({
      where: {
        $or: [
          {
            name: {
              $like: '%' + filterValue + '%'
            },
          },
          {
            identifier: {
              $like: '%' + filterValue + '%'
            },
          }
        ],
        ...isAvailableWhere
      },
    })
  }
}