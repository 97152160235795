import React, {CSSProperties} from "react";
// @ts-ignore
import reactCSS from 'reactcss';

interface Props {
  color: string;
  style?: CSSProperties,
}

export default function Circle({ color, style }: Props) {
  const styles = reactCSS({
    default: {
      color: {
        width: '25px',
        height: '25px',
        marginLeft: '5px',
        borderRadius: '50%',
        background: color,
        display: 'inline-block',
        cursor: 'pointer',
        ...style
      },
    },
  });

  return <div style={styles.color}/>
}