import {CanView} from "../../Main";
import {IDispatchingAttributesCustom, IVehicleData} from "@cml/types";
import TimeField from "react-simple-timefield";
import {getFloatTime, getTimeString} from "../../../AppObjects";
import {FormControl} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import GPSDataViewer from "../components/GPSDataViewer";
import React from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRow, setDataRowValue} from "@cml/redux-store";
import {Attributes, DataRow, Roles} from "@cml/models";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getTimeDiff, recalculate} from "../components/datarowBase";
import store from "../../../redux/store";
import {getAttributes, getVehicles} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrTimeFields({ attribute, index, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const from = useSelector(selectDataRowValue(index, singleRowEditMode, 'from'));
  const to = useSelector(selectDataRowValue(index, singleRowEditMode, 'to'));
  const break_ = useSelector(selectDataRowValue(index, singleRowEditMode, 'break'));

  const vehiclesData = useSelector((state: { dispatching: DataRowsState }) =>
    state.dispatching.vehiclesData);

  const vehicleId = useSelector(selectDataRowValue(index, singleRowEditMode, 'vehicleId'));

  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes(
    'price', );

  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { allDetails: 0, onlyForDispatching: 1  }}, );

  if(!vehicles)
    return <div>Loading</div>;

  const selVehicle = vehicles.find(o => o.id === vehicleId);
  let vehicleData: IVehicleData[] = [];
  if (selVehicle && (selVehicle.type !== 'custom')) {
    vehicleData = vehiclesData.filter(o => o.vehicleId === selVehicle.foreignApiVehicleId);
  }

  const handleDataOrderChange = (name: string, value: any) => {
    const data = singleRowEditMode
      ? store.getState().dispatching.singleEditRow as DataRow
      : store.getState().dispatching.dataRows[index];
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      [name]: value,
      priceEq: recalculate({
        ...data,
        [name]: value,
      } as DataRow, priceAttributes).priceEq,
    }))

  }
  const timeDiff = getTimeDiff({
    from, to, break: break_,
  });


  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canTime && dispatchOther.canTime}>
    <td >
      <TimeField
        style={{padding: '5px', fontSize: '11px', minWidth: '45px',maxWidth: '45px', display: 'inline-block'}}
        value={getTimeString(from)}                     // {String}   required, format '00:00' or '00:00:00'
        onChange={(e) => handleDataOrderChange('from', getFloatTime(e.target.value))}      // {Function} required
        input={<FormControl onFocus={(e) => (e.target as any).select()}/>} // {Element}  default: <input type="text" />
        colon=":"                        // {String}   default: ":"
      />
      { timeDiff.overNight && <FontAwesome style={{fontSize:'12px', color: 'black', marginLeft: '3px' }} name='fas fa-moon' /> }
      <GPSDataViewer style={{ marginTop: '3px', textAlign: 'center', fontSize: '11px', }} vehicleData={vehicleData} fromTime={true} />
    </td>
    <td >
      <TimeField
        style={{padding: '5px', fontSize: '11px', minWidth: '45px',maxWidth: '45px', display: 'inline-block'}}
        value={getTimeString(to)}                     // {String}   required, format '00:00' or '00:00:00'
        onChange={(e) => handleDataOrderChange('to', getFloatTime(e.target.value))}      // {Function} required
        input={<FormControl onFocus={(e) => (e.target as any).select()}/>} // {Element}  default: <input type="text" />
        colon=":"                        // {String}   default: ":"
      />
      <GPSDataViewer style={{ marginTop: '3px', textAlign: 'center', fontSize: '11px', }} vehicleData={vehicleData} fromTime={false} />
    </td>
    <td >
      <TimeField
        style={{padding: '5px', fontSize: '11px', minWidth: '45px',maxWidth: '45px'}}
        value={getTimeString(break_)}                     // {String}   required, format '00:00' or '00:00:00'
        onChange={(e) => handleDataOrderChange('break', getFloatTime(e.target.value))}      // {Function} required
        input={<FormControl onFocus={(e) => (e.target as any).select()}/>} // {Element}  default: <input type="text" />
        colon=":"                        // {String}   default: ":"
      />
    </td>
    <td>
      {getTimeString(timeDiff.sum)}
    </td>
  </CanView>
}