import React, { Component } from 'react';
import { BaseViewPropTypes } from '../CommonListContainer';
import { Row, Col } from 'react-bootstrap';
import FontAwesome from "react-fontawesome";

export default class AddressBookPreview extends Component{

  render() {
    // Because baseState is returned as raw 'space.name'
    return (<div style={{verticalAlign: 'middle'}}>
      <Row>
        <Col sm={3}>
            {this.props.item.company}
        </Col>
        <Col sm={3}>
          {this.props.item.ic}
        </Col>
        <Col sm={3}>
          {this.props.item.telnumb}
        </Col>
        <Col sm={3}>
          {this.props.item.email}
        </Col>
      </Row>
    </div>)
  }
}

AddressBookPreview.propTypes = BaseViewPropTypes;
