import React, { Component } from 'react';
import {Form, FormControl, FormGroup, ControlLabel, HelpBlock} from 'react-bootstrap';
import ReactPhoneInput from 'react-phone-input-2';
import request from 'axios';
import PropTypes from 'prop-types';
import SubmitButton from './SubmitButton';
import FontAwesome from 'react-fontawesome';
import {passwordChecker} from '../AppObjects';
import store from "../redux/store";
import { show } from 'react-notification-system-redux';
import {rememberEmailForNextUse} from "@cml/redux-store";

const formObject = {
  email: 'email',
  telnumb: 'telnumb',
  password: 'password',
  password2: 'password2',
};

export default class SingUp extends Component {

  constructor(props) {
    super(props);
    this.state= {
      email: '',
      telNumb: '',
      password: '',
      password2: '',
      focusedInput: '',
      requestActive: false,
      regTokenOk: false,
      registrationHash: '',
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.regTokenOk) {
      this.setState({ requestActive:true});
      request.post('/validregistration', {
        values: {
          registrationHash: this.state.registrationHash,
        }
      }, { response: 20000 })
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              email: res.data.email,
              registrationHash: res.data.registrationHash,
              telNumb: res.data.telnumb,
              regTokenOk: true,
            })
          }
          this.setState({ requestActive:false });
        })
        .catch((error) => {
          store.dispatch(show({
            title: 'Chyba !',
            level: 'error',
            autoDismiss: 15,
            children: (
              <div>
                {error.response.data.message}
              </div>
            ),
          }, 'error'));
          this.setState({ requestActive:false });
        });
      return;
    }
    if(this.checkInputs(true)) {
      this.setState({ requestActive:true});
      store.dispatch(rememberEmailForNextUse(this.state.email));
      request.post('/newregistration', {
        values: {
          email: this.state.email,
          telnumb: this.state.telNumb,
          password: this.state.password,
          registrationHash: this.state.registrationHash,
        }
      }, { timeout: 20000 })
        .then((res) => {
          this.setState({ password: '', password2: '' });
          if (res.status === 200) {
            this.setState({ requestActive:false });
            this.props.history.push('/emailvalidation');
          }
        })
        .catch((error) => {
          this.setState({ password: '', password2: '' });
          store.dispatch(show({
            title: 'Chyba !',
            level: 'error',
            autoDismiss: 15,
            children: (
              <div>
                {error.response.data.message}
              </div>
            ),
          }, 'error'));
          this.setState({ requestActive:false });
        });
    }
  }

  checkInputs(canFocus) {
    let passStatus = passwordChecker(this.state.password);
    let pass2Status = passwordChecker(this.state.password2);
    let passSame = this.state.password2 === this.state.password;
    let notSameMessage = !passSame && !pass2Status.error ? <HelpBlock>
      <ul>
        <li>Hesla nejsou stejná !</li>
      </ul>
    </HelpBlock> : null;

    let helpMessage = [];
    helpMessage[formObject.email] = {
      message: null,
      error: this.state.email.length < 1,
    };
    helpMessage[formObject.telnumb] = {
      message: null,
      error: this.state.telNumb.length < 6,
    };
    helpMessage[formObject.password] = {
      message: null,
      error: passStatus.error,
    };
    helpMessage[formObject.password2] = {
      message: null,
      error: pass2Status.error | !passSame,
    };
    switch (this.state.focusedInput) {
      case formObject.email:
        if (helpMessage[formObject.email].error)
          helpMessage[formObject.email].message = <HelpBlock>
            <ul>
              <li>Email musí být vyplněn</li>
            </ul>
          </HelpBlock>;
        break;
      case formObject.telnumb:
        if (helpMessage[formObject.telnumb].error)
          helpMessage[formObject.telnumb].message = <HelpBlock>
            <ul>
              <li>Telefonní číslo musí být zadáno ve správném formátu</li>
            </ul>
          </HelpBlock>;
        break;
      case formObject.password:
        helpMessage[formObject.password].message = passStatus.messages;
        break;
      case formObject.password2:
        helpMessage[formObject.password2].message = <div>
          {pass2Status.messages}
          {notSameMessage}
        </div>;
        break;
      default:
        break;
    }
    let ok = true;
    if(canFocus) {
      for (let key in helpMessage) {
        if(helpMessage[key].error){

          this.setState({focusedInput: key});
          ok = false;
          break;
        }
      }
      return ok;
    }

    return helpMessage;
  }


  render() {

    let helpMessage = this.checkInputs(false);

    let toRender = null;
    if(this.state.regTokenOk) {
      toRender = <div>
        <FormGroup controlId={formObject.email}
                   validationState={this.state.email.length > 0 ? null:'error'}>
          <div className='text-center'>
            <ControlLabel>{this.state.email}</ControlLabel>
          </div>
          {helpMessage[formObject.email].message}
        </FormGroup>

        <FormGroup controlId={formObject.telnumb}
                   validationState={this.state.telNumb.length > 5 ? null: 'error'}
        >

          <ReactPhoneInput defaultCountry={'cz'}
                           inputStyle={{width:'100%'}}
                           value={this.state.telNumb}
                           onFocus={() => this.setState({focusedInput: formObject.telnumb})}
                           onChange={(value) => this.setState({telNumb: value})}/>
          {helpMessage[formObject.telnumb].message}
        </FormGroup>

        <FormGroup controlId={formObject.password}
                   validationState={helpMessage[formObject.password].error ? 'error' : 'success'}
        >
          <FormControl type="password"
                       value={this.state.password}
                       placeholder="Heslo"
                       onFocus={() => this.setState({focusedInput: formObject.password})}
                       onChange={(e) => this.setState({password: e.target.value})}/>
          {helpMessage[formObject.password].message}
        </FormGroup>


        <FormGroup controlId={formObject.password2}
                   validationState={helpMessage[formObject.password2].error ? 'error' : 'success'}
        >
          <FormControl type="password"
                       value={this.state.password2}
                       placeholder="Heslo znovu"
                       onFocus={() => this.setState({focusedInput: formObject.password2})}
                       onChange={(e) => this.setState({password2: e.target.value})}/>
          {helpMessage[formObject.password2].message}
        </FormGroup>

        <FormGroup>
          <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.requestActive}>Registrovat</SubmitButton>
        </FormGroup>
      </div>;
    }
    else {
      toRender = <div>
        <FormGroup controlId={formObject.registrationHash}
                   validationState={this.state.registrationHash.length > 0 ? null:'error'}>
          <FormControl type="text"
                       value={this.state.registrationHash}
                       placeholder="Registrační kod"
                       onChange={(e) => this.setState({registrationHash: e.target.value})}/>
        </FormGroup>
          <FormGroup>
            <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.requestActive}>Pokračovat</SubmitButton>
          </FormGroup>
        </div>

    }

    return <div className="login-clean">

          <Form onSubmit={(e) => this.handleSubmit(e)} horizontal>
            <div className='illustration'>
              <FontAwesome name='fas fa-user-plus' />
            </div>
            {toRender}
          </Form>

    </div>
  }
}

SingUp.propTypes = {
  onSignSucces: PropTypes.func.isRequired,
};
