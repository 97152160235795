import React, {Component} from 'react';
import {BaseEditPropTypes} from '../CommonListContainer';
import request from 'axios';
import {BackHeader} from "../Setting";
import {LoadingOrRender} from "../../loginsrc/Profile";
import {
  Checkbox,
  Col,
  ControlLabel, Dropdown,
  Form,
  FormControl,
  FormGroup, MenuItem,
  Row
} from "react-bootstrap";
import SubmitButton from "../../loginsrc/SubmitButton";
import {dateFormatVisible} from "../../AppObjects";
import RecordComponent from "../RecordsComponent";
import PropTypes from "prop-types";
import {CanView} from "../Main";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import ItemsSelector from "../ItemsSelector";
import {SingleDatePicker} from "react-dates";
import moment from "moment";
import AttributeEdit from "../attributes/Edit";
import {SmallCreditCardView} from "../creditCard/Preview";

export default class VehicleEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type: 'custom',
      identifier: '',
      requestActive: props.disableUrlCheck ? false : (this.props.match.params.id === 'new' ? false : true),
      submitActive: false,
      isForSupliers: false,
      isZeroDph: false,
      isExternal: false,
      spaceId: 0,
      attributeId: 0,
      hourPrice: 0,
      stkDate: null,
      odometerCheckDate: null,
      datePickerFocused: false,
      insuranceDate: null,
      insuranceDatePickerFocused: false,
      insuranceHavDate: null,
      insuranceHavDatePickerFocused: false,
      insuranceHavCompany: '',
      insuranceCompany: '',
      odoDatePickerFocused: false,
      selectedIndex: 0,
      bookingActivityId: 0,
      bookingCentreId: 0,
      spaces: [],
      vehicleData: [],
      dateMarkers: [],
      creditCards: [],
      other: {
        priceEq: [],
      },
      ...props.defaultProps,
    };
  }

  componentWillMount() {
    request.get('/items/spaces')
      .then((res) => {
        const spaces = res.data;
        request.get('/items/attributes/price')
          .then(res => {
            this.prices = res.data;
            if (this.props.disableUrlCheck) {
              this.setState({
                spaces,
                requestActive: false,
              });
              return;
            }
            if (this.props.match.params.id !== 'new' ) {
              request.get('/items/vehicles/' + this.props.match.params.id)
                .then((res) => {
                  const vehicle = res.data;
                  const date = moment(vehicle.stkDate);
                  const odoDate = moment(vehicle.odometerCheckDate);
                  const insuDate = moment(vehicle.insuranceDate);
                  const insuHavDate = moment(vehicle.insuranceHavDate);
                  if (res.status === 200) {
                    this.setState({
                      spaces,
                      requestActive: false,
                      ...vehicle,
                      stkDate: date.isValid() ? date : null,
                      odometerCheckDate: odoDate.isValid() ? odoDate : null,
                      insuranceDate: insuDate.isValid() ? insuDate : null,
                      insuranceHavDate: insuHavDate.isValid() ? insuHavDate : null,
                    });
                  }
                })
                .catch(err => {
                  this.setState({ requestActive: false })
                })
            } else {
              this.setState({
                spaces,
                requestActive: false,
              });
            }
          })
          .catch(err => {
            this.setState({ requestActive: false })
          })

      })
      .catch(err => {
        this.setState({ requestActive: false })
      })
  }

  handleSpaceChange(selectedIndex) {
    this.setState({spaceId: selectedIndex});
  }

  handleSubmit(e,selectedSpace) {
    e.preventDefault();
    let state = this.state;
    state['space.name'] = selectedSpace.name;
    this.props.onSubmit(this.props.disableUrlCheck ?
      'new' : this.props.match.params.id, this, this.state);
  }

  render() {
    let selectedSpace = this.state.spaces.find(o => o.id === this.state.spaceId);
    return (
      <div>
        <ContentWithFixedHeader drawOnlyChildren={this.props.disableUrlCheck} header={
          <CanView visible={!this.props.disableUrlCheck}>
            <BackHeader
              fontSize='18px'
              headerText={this.state.identifier === '' ? 'Nové vozidlo' : this.state.identifier}
              onBackClick={() => this.props.history.replace(
                this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
          </CanView>
        }>
          <div className='editForm'>
            <div className='nest'>
              <LoadingOrRender requestActive={this.state.requestActive}>
                <Form onSubmit={(e) => {
                  this.handleSubmit(e, selectedSpace);
                }}>
                  <Row>
                    <Col sm={6}>
                      <h3>Detail</h3>
                      <Row>
                        <Col sm={12}>
                          <FormGroup>
                            <ControlLabel>Identifikátor</ControlLabel>
                            <FormControl type='text'
                                         //disabled={this.state.type !== 'custom'}
                                         value={this.state.identifier}
                                         onChange={(e) => this.setState({ identifier: e.target.value })}
                                         placeholder='Identifikátor'
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        {/*<Col sm={6}>
                          <FormGroup>
                            <ControlLabel>Hodinová sazba</ControlLabel>
                            <FormControl type='number'
                                         value={this.state.hourPrice}
                                         onChange={(e) => this.setState({ hourPrice: e.target.value })}
                                         placeholder='Hodinová sazba'
                            />
                          </FormGroup>
                        </Col>*/}
                        <Col sm={6}>
                          <FormGroup>
                            <ControlLabel style={{ marginTop: '5px' }}>Skupina vozidel</ControlLabel>
                            <div>
                              <ItemsSelector itemsToFetch={'attributes/vehicles'}
                                             newEditComponent={ AttributeEdit }
                                             newEditComponentDefaultProps={{
                                               attributeName: 'vehicles',
                                             }}
                                             overrideToggleFunc={(item) =>  item.id > 0 ? item.name : <div style={{fontSize: '13px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                                 Nevybrán
                                               </div>}
                                             appendItemsToStart={[{id: 0, name: 'Nevybrán', color: '#ffffff'},]}
                                             drawFirstWithDividers
                                             onListSplitterCondition={(index, item) => item.id !== 0}
                                             onSelected={(item) => this.setState({ attributeId: item.id, attribute: item })}
                                             defaultSelectedId={this.state.attributeId}
                                             value={'name'}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <ControlLabel>Prostor</ControlLabel>
                            <div>
                              <Dropdown
                                onSelect={(e) => this.handleSpaceChange(e)}
                                id="users">
                                <Dropdown.Toggle>
                                  {

                                    typeof selectedSpace === 'undefined' ? <div style={{ fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                      Nevybrán
                                    </div> : <div style={{ display: 'inline'}}>
                                      { selectedSpace.name }
                                    </div>
                                  }
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <MenuItem eventKey={0}>
                                    Nevybrán
                                  </MenuItem>
                                  {
                                    this.state.spaces.map((item,index) => {
                                      return <MenuItem key={index} eventKey={item.id}>
                                        {
                                          item.name
                                        }
                                      </MenuItem>
                                    })
                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <div style={{ display: 'inline-block' }}>
                            <FormGroup>
                              <ControlLabel>STK platné do</ControlLabel>
                              <div>
                                <SingleDatePicker
                                  // @ts-ignore
                                  small
                                  date={this.state.stkDate} // momentPropTypes.momentObj or null
                                  displayFormat={ dateFormatVisible }
                                  onDateChange={date => {
                                    if (date) {
                                      // @ts-ignore
                                      this.setState({ stkDate: date });
                                    }
                                  }} // PropTypes.func.isRequired
                                  // @ts-ignore
                                  focused={this.state.datePickerFocused} // PropTypes.bool
                                  onFocusChange={({ focused }) => {
                                    this.setState({ datePickerFocused: focused })
                                  }} // PropTypes.func.isRequired
                                  id='stkDatePicker' // PropTypes.string.isRequired,
                                />
                              </div>
                            </FormGroup>
                          </div>
                          <div style={{ display: 'inline-block', marginLeft: '30px' }}>
                            <FormGroup>
                              <ControlLabel>TCHG platné do</ControlLabel>
                              <div>
                                <SingleDatePicker
                                  // @ts-ignore
                                  small
                                  date={this.state.odometerCheckDate} // momentPropTypes.momentObj or null
                                  displayFormat={ dateFormatVisible }
                                  onDateChange={date => {
                                    if (date) {
                                      // @ts-ignore
                                      this.setState({ odometerCheckDate: date });
                                    }
                                  }} // PropTypes.func.isRequired
                                  // @ts-ignore
                                  focused={this.state.odoDatePickerFocused} // PropTypes.bool
                                  onFocusChange={({ focused }) => {
                                    this.setState({ odoDatePickerFocused: focused })
                                  }} // PropTypes.func.isRequired
                                  id='odoMeterDatePicker' // PropTypes.string.isRequired,
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <div style={{ display: 'inline-block' }}>
                            <FormGroup>
                              <h4>POV</h4>
                              <ControlLabel>Poji. Společnost</ControlLabel>
                              <FormControl type='text'
                                           value={this.state.insuranceCompany}
                                           onChange={(e) => this.setState({ insuranceCompany: e.target.value })}
                                           placeholder='Poji. Společnost'
                              />
                            </FormGroup>
                          </div>
                          <div style={{ display: 'inline-block', marginLeft: '30px' }}>
                            <FormGroup>
                              <ControlLabel>Pojistka platná do</ControlLabel>
                              <div>
                                <SingleDatePicker
                                  // @ts-ignore
                                  small
                                  date={this.state.insuranceDate} // momentPropTypes.momentObj or null
                                  displayFormat={ dateFormatVisible }
                                  onDateChange={date => {
                                    if (date) {
                                      // @ts-ignore
                                      this.setState({ insuranceDate: date });
                                    }
                                  }} // PropTypes.func.isRequired
                                  // @ts-ignore
                                  focused={this.state.insuranceDatePickerFocused} // PropTypes.bool
                                  onFocusChange={({ focused }) => {
                                    this.setState({ insuranceDatePickerFocused: focused })
                                  }} // PropTypes.func.isRequired
                                  id='odoMeterDatePicker' // PropTypes.string.isRequired,
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div style={{ display: 'inline-block' }}>
                            <FormGroup>
                              <h4>HAV</h4>
                              <ControlLabel>Poji. Společnost</ControlLabel>
                              <FormControl type='text'
                                           value={this.state.insuranceHavCompany}
                                           onChange={(e) => this.setState({ insuranceHavCompany: e.target.value })}
                                           placeholder='Poji. Společnost'
                              />
                            </FormGroup>
                          </div>
                          <div style={{ display: 'inline-block', marginLeft: '30px' }}>
                            <FormGroup>
                              <ControlLabel>Pojistka platná do</ControlLabel>
                              <div>
                                <SingleDatePicker
                                  // @ts-ignore
                                  small
                                  date={this.state.insuranceHavDate} // momentPropTypes.momentObj or null
                                  displayFormat={ dateFormatVisible }
                                  onDateChange={date => {
                                    if (date) {
                                      // @ts-ignore
                                      this.setState({ insuranceHavDate: date });
                                    }
                                  }} // PropTypes.func.isRequired
                                  // @ts-ignore
                                  focused={this.state.insuranceHavDatePickerFocused} // PropTypes.bool
                                  onFocusChange={({ focused }) => {
                                    this.setState({ insuranceHavDatePickerFocused: focused })
                                  }} // PropTypes.func.isRequired
                                  id='odoMeterDatePicker' // PropTypes.string.isRequired,
                                />
                              </div>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <ControlLabel>Velikost nádrže v litrech</ControlLabel>
                            <FormControl type='number'
                                         value={this.state.tankSize}
                                         onChange={(e) => this.setState({ tankSize: e.target.value })}
                                         placeholder='Velikost [l]'
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <ControlLabel>Vazba na činnost v účetním systému</ControlLabel>
                            <div>
                              <ItemsSelector
                                onMapFetchedList={(list) => {
                                  return list.map(o => {
                                    return {
                                      code: o['acv:activityHeader']['acv:code'],
                                      id: parseInt(o['acv:activityHeader']['acv:id']),
                                    }
                                  })
                                }}
                                defaultSelectedId={ this.state.bookingActivityId }
                                onSelected={(item) => { this.setState({ bookingActivityId: item.id}) }}
                                overrideToggleFunc={(item) => item.id === 0 ? <div style={{ fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                  Nevybrán
                                </div> : item.code}
                                appendItemsToStart={[{code: 'Nevybrán', id: 0}]}
                                value={'code'}
                                filterEnable
                                otherPreviewProps={{
                                  style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
                                }}
                                itemsToFetch={'pohoda/getactivities'}/>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <ControlLabel>Vazba na středisko v účetním systému</ControlLabel>
                            <div>
                              <ItemsSelector
                                onMapFetchedList={(list) => {
                                  console.log(this.state.bookingCentreId);
                                  return list.map(o => {
                                    return {
                                      code: o['cen:centreHeader']['cen:code'],
                                      id: parseInt(o['cen:centreHeader']['cen:id']),
                                    }
                                  })
                                }}
                                defaultSelectedId={ this.state.bookingCentreId }
                                onSelected={(item) => { this.setState({ bookingCentreId: item.id}) }}
                                overrideToggleFunc={(item) => item.id === 0 ? <div style={{ fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                  Nevybrán
                                </div> : item.code}
                                appendItemsToStart={[{code: 'Nevybrán', id: 0}]}
                                value={'code'}
                                filterEnable
                                otherPreviewProps={{
                                  style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
                                }}
                                itemsToFetch={'pohoda/getcenters'}/>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col sm={6}/>
                        <Col sm={6}>
                          <FormGroup>
                            <ControlLabel>Vazba na webdispečink</ControlLabel>
                            <div>
                              <ItemsSelector
                                loadAfterButton
                                onMapFetchedList={(list) => {
                                  return list.map(o => {
                                    return {
                                      identifikator: o.identifikator,
                                      id: o.carid,
                                    }
                                  })
                                }}
                                defaultSelectedId={this.state.foreignApiVehicleId}
                                onSelected={(item) => { this.setState({
                                  foreignApiVehicleId: item.id, type: 'webdispecink'}) }}
                                overrideToggleFunc={(item) => item.id === 0 ? <div style={{ fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                  Nevybrán
                                </div> : item.identifikator}
                                appendItemsToStart={[{identifikator: 'Nevybrán', id: 0}]}
                                value={'identifikator'}
                                filterEnable
                                otherPreviewProps={{
                                  style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
                                }}
                                itemsToFetch={'getWebDispatchVehicles'}/>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <h4 style={{marginTop: '15px'}}>Další nastavení</h4>
                          <Checkbox
                            checked={this.state.isForSupliers}
                            onChange={(e) => {
                              this.setState({
                                isForSupliers: e.target.checked,
                              });
                            }}
                          >Používat pro subdodavatele</Checkbox>
                          <Checkbox
                            checked={this.state.isZeroDph}
                            onChange={(e) => {
                              this.setState({
                                isZeroDph: e.target.checked,
                              });
                            }}
                          >Používat při fakturaci přenesenou daňovou povinnost</Checkbox>
                          <Checkbox
                            checked={this.state.isExternal}
                            onChange={(e) => {
                              this.setState({
                                isExternal: e.target.checked,
                              });
                            }}
                          >Toto vozidlo patři subdodavateli</Checkbox>
                        </Col>
                        <Col sm={6}>
                          <h4 title='Karty se přiřazují v hlavním menu -> platební karty -> upravit'>Platební karty</h4>
                          {
                            this.state.creditCards.map((item, index) => {
                              return <SmallCreditCardView style={{ marginTop: '-10px' }} item={item} />
                            })
                          }
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col smOffset={3} sm={6}>
                      <SubmitButton onClick={this.props.disableUrlCheck ? (e) => this.handleSubmit(e, selectedSpace) : null}
                                    disabled={this.state.spaceId === 0}
                                    bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                        {this.state.spaceId === 0 ? 'Vyberte prostor': 'Uložit'}
                      </SubmitButton>
                    </Col>
                  </Row>
                </Form>
              </LoadingOrRender>
            </div>
          </div>
          {
            this.props.disableUrlCheck ? null : (this.props.match.params.id === 'new' ? null : (this.state.requestActive ? null : <div className='Record'>
              <div className='nest'><RecordComponent
                cards={this.state.creditCards}
                identificationProps={{
                $or: [
                  {
                    itemId: this.state.id,
                    itemTableName: 'vehicles',
                  },
                  {
                    itemId: this.state.creditCards.map(o => o.id),
                    itemTableName: 'creditCard',
                  }
                ]
              }}/></div></div>))
          }
        </ContentWithFixedHeader>
      </div>);
  }
}

VehicleEdit.propTypes = {
  disableUrlCheck: PropTypes.bool,
  defaultProps: PropTypes.object,
  ...BaseEditPropTypes
};

VehicleEdit.defaultProps = {
  disableUrlCheck: false,
  defaultProps: {},
};