import React, {useState} from "react";
import {CanView} from "../../Main";
import FontAwesome from "react-fontawesome";
import {Attributes, Roles} from "@cml/models";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {IDispatchingAttributesCustom} from "@cml/types";
import {getUsersForDispatching, getUsersWithSpecificRoleWhere, getVehicles} from "../../../fetchApi/dispatching";
import {dateFromat} from "../../../AppObjects";
import {Moment} from "moment";
import {SidePanel2} from "./SidePanel";
import {SmallUserPreview} from "../../users/Preview";
import {CleverSms} from "./CleverSms";

interface OrderingArrowsProps {
  onOrderRequired: (order: boolean) => void;
}

function OrderingArrows(props: OrderingArrowsProps) {
  return <div style={{display: 'inline-block'}}>
    <button onClick={() => props.onOrderRequired(true)}
            className='transparentButton2'>
      <FontAwesome style={{marginLeft: '10px', fontSize:'11px', color: 'grey' }} name='fas fa-arrow-up' />
    </button>
    <button onClick={() => props.onOrderRequired(false)}
            className='transparentButton2'>
      <FontAwesome style={{fontSize:'11px', color: 'grey' }} name='fas fa-arrow-down' />
    </button>
  </div>

}

interface HeaderGeneratorProps {
  unresolvedMode: boolean;
  attribute: Attributes;
  singleRowEditMode: boolean;
  onOrderRequired: (order: boolean, itemName: string) => void;
  selectedDate: Moment,
}

export default function HeaderGenerator(props: HeaderGeneratorProps) {
  
  const authenticate = useSelector((state: {authenticate: AuthenticateState}) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other
  const { users, isValidating: usersValidating, revalidate: usersRevalidate } = getUsersForDispatching(
    { params: { date: props.selectedDate ? props.selectedDate.format(dateFromat) : '1999-01-01' }});
  const { vehicles, isValidating: vehiclesValidating, revalidate: vehiclesRevalidate }
  = getVehicles({params: { allDetails: 0, onlyForDispatching: 1  }});
  const customOptions = (props.attribute.customOptions as IDispatchingAttributesCustom);
  const [ cleverSmsShow, setCleverSmsShow ] = useState(false);
  const [ cleverSmsContactShow, setCleverSmsContactShow ] = useState(false);



  const usersUsed = useSelector((state: { dispatching: DataRowsState }) =>
    state.dispatching.usersUsed);
  const vehicleUsed = useSelector((state: { dispatching: DataRowsState }) =>
    state.dispatching.vehicleUsed);

  const filteredVehicles = vehicles ?
    vehicles.filter(o => !o.isForSupliers && !o.isExternal) : [];
  const vehicleHeader = !props.singleRowEditMode && vehicles && customOptions.canVehicle && !customOptions.userAsAddressBook ?
    <SidePanel2 text={`${vehicleUsed.length} / ${
      filteredVehicles.length}`}>
      {filteredVehicles.map((v, index) => {
        if (vehicleUsed.includes(v.id))
          return null;
        return <div style={{
          padding: '5px',
          marginLeft: '10px',
          fontSize: '11px'
        }} key={`usedVeh_${v.id}`}>{v.identifier}</div>
      })}
    </SidePanel2> : undefined;

  const usersLength = users ?
    users.filter(u => u.spaces.filter(s => s.space.id === authenticate.activeSpaceId
      && s.homeSpace).length > 0 && u.attendance.length < 1 && u.dataRows.length === 0).length : 0;
  const usersHeader = !props.singleRowEditMode && customOptions.canUser && users ?
    <SidePanel2 text={`${usersUsed.length} / ${usersLength}`}>
      {users.filter(u => u.spaces.filter(s => s.space.id === authenticate.activeSpaceId
        && s.homeSpace).length > 0).sort((u1, u2) => u2.attendance.length - u1.attendance.length)
        .map((u, index) => {
          if (usersUsed.includes(u.id))
            return null;
          return <div key={`usedVeh_${u.id}`} style={{
            padding: '5px',
            marginLeft: '10px',
            fontSize: '11px'
          }}>
            <SmallUserPreview style={{ fontSize: '11px' }}
                              errorIfForeignDataRows
                              colorIfAttendance
                              item={u}/>
          </div>
        })}
      <div style={{ marginTop: 10, marginBottom: 10 }}>Dostupní z jiných prostorů</div>
      {users.filter(u => u.spaces.filter(s => s.space.id === authenticate.activeSpaceId
        && s.homeSpace).length === 0).sort((u1, u2) => u2.attendance.length - u1.attendance.length)
        .map((u, index) => {
          if (usersUsed.includes(u.id))
            return null;
          return <div key={`usedVeh_${u.id}`} style={{
            padding: '5px',
            marginLeft: '10px',
            fontSize: '11px'
          }}>
            <SmallUserPreview style={{ fontSize: '11px' }}
                              errorIfForeignDataRows
                              colorIfAttendance
                              item={u}/>
          </div>
        })}
    </SidePanel2>: undefined;


    return <tr>
      <CanView visible={props.unresolvedMode}>
        <th>Datum</th>
      </CanView>
      <CanView visible={!props.unresolvedMode}>
        <th></th>
      </CanView>
      <th></th>
      <CanView visible={customOptions.canNote && dispatchOther.canNote}>
        <th>
          Pozn.
        </th>
      </CanView>
      <CanView visible={customOptions.canNote && dispatchOther.canNote}>
        <th>Při.</th>
      </CanView>
      <CanView visible={customOptions.canOrderNumber && dispatchOther.canOrderNumber}>
        <th>
          Č.Z.
        </th>
      </CanView>
      <CanView visible={customOptions.canVehicle && dispatchOther.canVehicle}>
        <th style={{minWidth: '100px'}}>
          Stroj
          <span style={{marginLeft: '5px'}}>
            {vehicleHeader}
          </span>

        </th>
      </CanView>
      <CanView visible={customOptions.canRealVehicle && dispatchOther.canRealVehicle}>
        <th style={{minWidth: '100px'}}>
          Sk. stroj
        </th>
      </CanView>
      <CanView visible={customOptions.canUser && dispatchOther.canUser && !customOptions.userAsAddressBook}>
        <th>
          Strojník
          <span style={{marginLeft: '5px'}}>
            {usersHeader}
          </span>

        </th>
      </CanView>
      <CanView visible={customOptions.userAsAddressBook && dispatchOther.canUser}>
        <th style={{minWidth: '130px'}}>
          Dodavatel
        </th>
      </CanView>
      <CanView visible={customOptions.canTool && dispatchOther.canTool}>
        <th>
          Vybavení
        </th>
      </CanView>
      <CanView visible={customOptions.canLoading && dispatchOther.canLoadingUnloading}>
        <th>
          {authenticate.activeSpaceId === 1 ? 'Místo setkání' : 'Nákladka'}
        </th>
      </CanView>
      <CanView visible={customOptions.canUnLoading && dispatchOther.canLoadingUnloading}>
        <th>
          Výkladka
        </th>
      </CanView>
      <CanView visible={customOptions.canCompany && dispatchOther.canCompany}>
        <th>
          Firma
        </th>
      </CanView>
      <CanView visible={customOptions.canConstruction && dispatchOther.canConstruction}>
        <th>
          Stavba
        </th>
      </CanView>
      <CanView visible={customOptions.canContactPerson && dispatchOther.canContactPerson}>
        <th>
          Kontakt
          <button
            style={{ marginLeft: 5 }}
            onClick={() => setCleverSmsContactShow(true)}
            className='transparentButton'><FontAwesome name='envelope'/></button>
          {cleverSmsContactShow && <CleverSms
            modeContactUser
            date={props.selectedDate}
            singleRowEditMode={props.singleRowEditMode}
            attribute={props.attribute}
            onHide={() => setCleverSmsContactShow(false)}/>}
        </th>
      </CanView>
      <CanView visible={customOptions.canTime && dispatchOther.canTime}>
        <th>
          Od
        </th>
        <th>
          Do
        </th>
        <th>
          Př.
        </th>
        <th>
          Hod.
        </th>
      </CanView>
      <CanView visible={customOptions.canFraction && dispatchOther.canFraction}>
        <th>
          Frakce
        </th>
      </CanView>
      <th>
        Stav
        {!customOptions.userAsAddressBook
        && <button
          style={{ marginLeft: 5 }}
          onClick={() => setCleverSmsShow(true)}
          className='transparentButton'><FontAwesome name='envelope'/></button>}
        {cleverSmsShow && <CleverSms
          date={props.selectedDate}
          singleRowEditMode={props.singleRowEditMode}
          attribute={props.attribute}
          onHide={() => setCleverSmsShow(false)}/>}
      </th>
      <CanView visible={customOptions.canCalculation && dispatchOther.canCalculation}>
        <th>
          Kalkulace
        </th>
        <th>
          Cena
        </th>
      </CanView>
      <CanView visible={customOptions.canUseCosts && dispatchOther.canUseCosts}>
        <th>
          Náklady
        </th>
        <th>
          Zisk
        </th>
      </CanView>
    </tr>;
}