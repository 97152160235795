import {Component} from "react";
import React from "react";
import {BaseViewPropTypes} from '../CommonListContainer';
import {Col, Row, Button, ControlLabel, ButtonToolbar} from 'react-bootstrap';
import {Right} from "../roles/Preview";
import {LoadingOrRender} from "../../loginsrc/Profile";
import {SmallUserPreview} from "../users/Preview";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import {dateFormatVisible, dateFromat, mapAuthStateToProps} from "../../AppObjects";
import PropTypes from "prop-types";
import TaskPreview from "../tasks/Preview";
import {CanView} from "../Main";
import {connect} from "react-redux";

class SmsView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitActive: false,
    };
    this.role = props.authenticate.actualUserRole;
  }

  readSms() {
    let item = this.props.item;
    item.readBy = this.props.authenticate.loggedUser.id;
    this.props.onSubmit(this.props.item.id, this, item)
  }

  render() {

    let { item } = this.props;

    return (<div>
      <Row>
        <Col sm={1}>
          {
            item.wentOut ? <FontAwesome style={{fontSize:'25px', color: 'blue' }} name='fas fa-arrow-up' /> :
              <FontAwesome style={{fontSize:'25px', color: 'green' }} name='fas fa-arrow-right' />
          }
        </Col>
        {
          this.props.isForLog ?
            <Col sm={2} componentClass={ControlLabel}>
              {item.wentOut ? item.to : item.from}
            </Col> :
            <Col sm={2} componentClass={ControlLabel}>
              {item.users.length > 0 ? Object.keys(item.users).map((key) => {
                return <SmallUserPreview key={key} item={item.users[key]}/>
              }) : item.wentOut ? item.to : item.from
              }
            </Col>
        }

        <Col sm={2} >
          {moment(item.createdAt, dateFromat + ' HH:mm:ss').format(dateFormatVisible + ' HH:mm:ss')}
        </Col>
        <Col sm={4}>
          {item.text}
        </Col>
        <Col sm={3}>
          <CanView visible={!this.props.isForLog}>
            <ButtonToolbar>
              {
                item.readBy === null && !item.wentOut ? <Button disabled={!this.role.sms.update} onClick={() => this.readSms()}>
                  Potvrdit
                </Button> : null
              }
              <Button
                disabled={!this.role.tasks.update}
                onClick={() => this.props.history.push({
                  pathname: '/tasks/new',
                  state: {
                    name: item.text.substring(0, 100) + '...',
                    note: item.text,
                  },
                })}>
                Vytvořit úkol
              </Button>
              <Button onClick={() => this.props.history.push({
                pathname: this.props.match.url + '/sendsms',
                state: {
                  to: item.wentOut ? item.to : item.from,
                  defaultText: '',
                },
              })}>
                Konverzace
              </Button>
            </ButtonToolbar>
          </CanView>
        </Col>
      </Row>
      </div>)
  }
}

SmsView.propTypes = {
  isForLog: PropTypes.bool,
  authenticate: PropTypes.object,
  ...BaseViewPropTypes
};

SmsView.defaultProps = {
  isForLog: false
};

export default connect(mapAuthStateToProps)(SmsView);
