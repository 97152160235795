import {Component} from "react";
import React from "react";
import { BaseViewPropTypes } from '../CommonListContainer';
import {dateFormatVisible, mapAuthStateToProps} from "../../AppObjects";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import moment from "moment";
import {Tag} from "../Tag";
import {SmallUserPreview} from "../users/Preview";
import FontAwesome from "react-fontawesome";
import {CanView} from "../Main";
import request from "axios";
import store from "../../redux/store";
import {show} from "react-notification-system-redux";

const getValidDate = (date) => {
  const validDate = moment(date);
  const daysLeft = validDate.isValid() ? moment().diff(validDate, 'days') : 0;
  let color = 'darkred';
  if (daysLeft > -8) {
    color = 'darkred';
  } else if (daysLeft > -60) {
    color = 'darkorange';
  } else {
    color = 'darkgreen';
  }

  return {
    color,
    daysLeft,
  };
};

export class SmallCreditCardView extends Component {

  renderIcon() {
    if(this.props.item.type === 0)
      return <div className='uta-logo16'/>;
    else if(this.props.item.type === 1)
      return <div className='shell-logo16'/>;
    else if(this.props.item.type === 2)
      return <div className='wag-logo16'/>;
  }

  render() {
    let { item } = this.props;
    return <div style={this.props.style}>
      {this.renderIcon()}
      <div style={{ display: 'inline-block', verticalAlign: 'top', marginTop: '7px' }}>{item.identifier + (item.isForPrivateUse ? ' (P)' : '')}</div>
    </div>
  }
}

SmallCreditCardView.propTypes = {
  style: PropTypes.object,
  ...BaseViewPropTypes
};

SmallCreditCardView.defaultProps = {
  style: {}
}


class CreditCardView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitActive: false,
      decryptedPin: null,
    };
    this.role = props.authenticate.actualUserRole;
  }

  renderIcon() {
    if(this.props.item.type === 0)
      return <div className='uta-logo'/>;
    else if(this.props.item.type === 1)
      return <div className='shell-logo'/>;
    else if(this.props.item.type === 2)
      return <div className='wag-logo'/>;
  }

  getRawPin = () => {
    request.get('/items/creditcard/showpin/' + this.props.item.id)
      .then((res) => {
        if (res.status === 200) {
          this.setState({ decryptedPin: res.data.pin });
        }
      })
      .catch(err => {
        if (err.response.status === 404) {
          store.dispatch(show({
            title: 'Info',
            level: 'info',
            autoDismiss: 3,
            children: (
              <div>
                Pin není vyplněn
              </div>
            ),
          }, 'info'));
        }
      })
  }

  render() {
    let { item } = this.props;
    const dayLeft = getValidDate(item.validDate);
    const validDate = moment(item.validDate);
    return (<div>
      <Row>
        <Col sm={1}>
          {this.renderIcon()}
        </Col>
        <Col sm={2}>
          {item.number}
        </Col>
        <Col sm={2}>
          {item.identifier}
        </Col>
        <Col sm={2}>
          <Tag style={{
            backgroundColor: dayLeft.color,
            paddingTop: '4px',
            paddingBottom: '4px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '5px',
            width: 'fit-content',
            color: 'white',
          }} disableClose item={`Platné do ${validDate.isValid() ? validDate.format(dateFormatVisible) : 'Nevyplněno' }`}/>
        </Col>
        <Col sm={2}>
          {item.isForPrivateUse && <div title='Karta je označena jako privátní, pro vlastní účely' style={{ display: 'inline-block' }}>
            PRIV.
          </div>}
          <SmallUserPreview item={item.user} />

        </Col>
        <Col sm={2}>
          {item.vehicle && item.vehicle.identifier}
        </Col>
        <CanView visible={this.role.creditCard.pin}>
          <Col sm={1}>
            {this.state.decryptedPin ? this.state.decryptedPin : <button onClick={this.getRawPin} className='transparentButton'>
              <FontAwesome style={{fontSize:'22px', color: 'grey', }} name='fas fa-key' />
            </button>}
          </Col>
        </CanView>
      </Row>

      </div>)
  }
}

CreditCardView.propTypes = {
  authenticate: PropTypes.object,
  ...BaseViewPropTypes
};

CreditCardView.defaultProps = {
  isForLog: false
};

export default connect(mapAuthStateToProps)(CreditCardView);
