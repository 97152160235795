import {Attributes, Roles, Users} from "@cml/models";
import {CanView} from "../../Main";
import {IDispatchingAttributesCustom, ISmsInfo} from "@cml/types";
import ItemsSelector from "../../ItemsSelector";
import {SmallUserPreview} from "../../users/Preview";
import UserEdit from "../../users/Edit";
import React from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getUsersWithSpecificRoleWhere} from "../../../fetchApi/dispatching";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";
import FontAwesome from "react-fontawesome";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrContactUser({index, attribute, singleRowEditMode}: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const contactUserId_a = useSelector(selectDataRowValue(index, singleRowEditMode, 'contactUserId_a'));

  const contactUserId = useSelector(selectDataRowValue(index, singleRowEditMode, 'contactUserId'));

  const contactUserData = useSelector(selectDataRowValue(index, singleRowEditMode, 'contactUser'));

  const smsInfo = useSelector(selectDataRowValue(index, singleRowEditMode, 'smsInfo'));


  const { contactUsers, isValidating: contactValidating } = getUsersWithSpecificRoleWhere(
    { params: { roleWhere: { isContactUser: 1 }}}, singleRowEditMode ? {
      revalidateOnFocus: false,
      revalidateOnMount: true,
    } : undefined);


  const handleUserChange = (user: Users) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode,{
      contactUserId: user.id,
      contactUserId_a: 0
    }));
  }

  if(!contactUsers)
    return <div>Loading</div>;

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canContactPerson && dispatchOther.canContactPerson}>
    <td>
      {smsInfo && (smsInfo as ISmsInfo).contactUserSmsId &&
      <FontAwesome style={{ marginRight: 10, color: '#00AA00' }} title='SMS s infem odeslána kontaktu' name='check'/>}
      <ItemsSelector externList={contactUsers}
                     itemsToFetch='users'
                     bsSize="xsmall"
                     overrideToggleFunc={(user: Users) => contactUserId_a ?
                       <SmallUserPreview style={{fontSize: '11px', display: 'inline', marginRight: '5px'}}
                                         detailEnabled={true}
                                         item={contactUserData}/> : (user.id === 0 ?
                         <div
                           style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                           Nevybrán
                         </div> : <SmallUserPreview
                           style={{fontSize: '11px', display: 'inline', marginRight: '5px'}}
                           detailEnabled={true}
                           item={user}/>)}
                     value={'nameSurname'}
                     appendItemsToStart={[
                       {id: 0, nameSurname: 'Nevybrán'},
                     ]}
                     drawFirstWithDividers
                     onListSplitterCondition={(index: number, item: Users) => item.id !== 0}
                     newEditComponent={ UserEdit }
                     newEditComponentDefaultProps={{
                       disableAdmin: true,
                       rolesWhere: {where: {isContactUser: 1}}
                     }}
                     filterEnable
                     getExternIndex
                     onSelected={handleUserChange}
                     defaultSelectedId={contactUserId}/>
    </td>
  </CanView>
}