import {CHANGE_MAIN_STATE, INumberQueue} from "./numberQueueGenerator/reducer";
import {Mention, MentionItem, MentionsInput} from 'react-mentions';
import mentionStyle from './numberQueueGenerator/styles';
import React, {useState, useRef, useEffect} from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {type} from "os";
import request from "axios";

interface Props {
  template: INumberQueue;
  value?: string;
  onChangeText?: (text: string) => void;
  production?: boolean;
  orderId?: number | undefined;
  subOrder?: boolean;
  disabled?: boolean;
};


export const getValuesOfCustomNumber = (template: INumberQueue, text: string) => {
  const positions: { from: number; to: number; isEnum: boolean; index: number; value: string; }[] = [];
  let usedText = template.template;
  template.value.forEach((t, index) => {
    let n;
    let isEnum = false;
    switch (t.type) {
      case "enum":
        isEnum = true;
        let best = '';
        let bestLength = 0;
        t.regexTable.forEach(r => {
          const index  = text.indexOf(r.item);
          if(index > -1) {
            if (r.item.length > bestLength) {
              best = r.item;
              bestLength = r.item.length;
            }
          }
        })
        n = (best !== '' ? best : 'NA');
        break;
      case "number":
        n = "0".repeat(t.length);
        break;
      case "string":
        n = "-".repeat(t.length);
        break;
    }
    const indexStart = usedText.indexOf(`@[${t.name}](${index})`);
    usedText = usedText.replace(`@[${t.name}](${index})`, n);
    positions.push({
      from: indexStart,
      to: indexStart + n.length,
      isEnum,
      index,
      value: text.substring(indexStart, indexStart + n.length),
    })
  })
  return positions;
}

export default function NumberQueue({
                                      template,
                                      onChangeText,
                                      value,
                                      production,
                                      orderId,
                                      disabled,
                                      subOrder }: Props) {


  const positions = useRef<{
    from: number, to: number, isEnum: boolean, index: number
  }[]>([]);

  const generateFirstNumber = (text: string) => {
    positions.current.splice(0, positions.current.length)
    template.value.forEach((t, index) => {
      let n;
      let isEnum = false;
      switch (t.type) {
        case "enum":
          isEnum = true;
          n = t.regexTable[0] ? t.regexTable[0].item : 'NA'
          break;
        case "number":
          n = "0".repeat(t.length);
          break;
        case "string":
          n = "-".repeat(t.length);
          break;
      }
      const indexStart = text.indexOf(`@[${t.name}](${index})`);
      positions.current.push({
        from: indexStart,
        to: indexStart + n.length,
        isEnum,
        index
      })
      text = text.replace(`@[${t.name}](${index})`, n);
    })
    return text;
  }

  useEffect(() => {
    if(!value || value === '')
      setNumber(generateFirstNumber(template.template))
  }, [template, value])

  const [previousNumber, setPreviousNumber] = useState('');

  useEffect(() => {
    if(value) {
      const v = generateFirstNumber(template.template);
      setNumber((v.length !== value.length && !production) ? v : value);
      positions.current = getValuesOfCustomNumber(template, value as string);
      if (production) {
        checkNumber(value as string);
      }
      /*if (v.length !== value.length)
        setPreviousNumber(value);*/
    }

  }, [!value])


  const [number, setNumber] = useState('');
  const [cursorPos, setCursorPos] = useState(0);
  const [trigger, setTrigger] = useState('@');
  const [actualEnum, setActualEnum] = useState(0);


  useEffect(() => {
    if(onChangeText)
      onChangeText(number);
  }, [number])

  const textareaRef = useRef();
  const cursorPosition = 0;

  const checkNumber = (n: string) => {
    const p = getValuesOfCustomNumber(template, n);
    if(p.length > 1 && production) {
      request.get('/items/orders', {
        params: {
          attributes: ['customNumber', 'id'],
          where: { customNumber: { $like: `${p[0].value}.%` } }, //force dot, dont have time to play with that
        }
      }).then(res => {
        const numbers = res.data.sort((a: { customNumber: string; }, b: { customNumber: string; }) => {
          return parseInt(a.customNumber.substring(p[1].from, p[1].to)) - parseInt(b.customNumber.substring(p[1].from, p[1].to))
        });
        if (numbers.filter((o: { customNumber: any; }) =>
          o.customNumber.includes(n.substring(p[0].from, p[1].to))).length > 0) {
          const newNumber = numbers[numbers.length - 1];
          const foundedOrder = numbers.filter((o: { id: number | undefined; }) =>
            o.id === orderId).length > 0;
          if (!foundedOrder && !subOrder) {
            let newNumber2 = parseInt(newNumber.customNumber.substring(p[1].from, p[1].to)) + 1;
            setNumber(n.substring(0, p[1].from) +
              String(newNumber2).padStart(p[1].to - p[1].from, '0') +
              n.substring(p[1].to, n.length))
          }
        } else {
          setNumber(n.substring(0, p[1].from) +
            String(numbers.length > 0 ? (parseInt(numbers[numbers.length - 1].customNumber.substring(p[1].from, p[1].to))) + 1
              : 0).padStart(p[1].to - p[1].from, '0') +
            n.substring(p[1].to, n.length))
        }
      })
    }
  }

  const onChange = (
    event: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[]) => {
    // @ts-ignore
    const se = textareaRef.current.selectionEnd - 1;
    const pos = positions.current.find(o => o.from <= se && se < o.to);
    if(mentions.length === 0) {
      if(pos) {
        if(pos.isEnum)
          return;
      } else {
        return;
      }
    }
    let n;
    if(mentions.length > 0) {
      const posIndex = positions.current.findIndex(o => o.from <= cursorPos && cursorPos <= o.to);
      let offset = 0
      positions.current.forEach((p, i) => {
        if (i === posIndex) {
          offset = (p.from + mentions[0].display.length) - p.to;
          p.to = p.from + mentions[0].display.length;
        } else if(i > posIndex) {
          p.to += offset;
          p.from += offset;
        }
      })
      n = newValue.replace(
        `@[${mentions[0].display}](${mentions[0].id})`, mentions[0].display);
      checkNumber(n);
      setNumber(n);
    } else {
      n = newValue;
      setNumber(n);
    }


  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>
    | React.KeyboardEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (textareaRef.current) {
      if (event.nativeEvent.key === 'Backspace' || event.nativeEvent.key === 'Delete')
        event.preventDefault();
      // @ts-ignore
      const se = textareaRef.current.selectionEnd;
      // @ts-ignore
      const sa = textareaRef.current.selectionStart;
      const pos = positions.current.find(o => o.from <= se && se <= o.to);
      if(pos) {
        if(pos.isEnum)
          event.preventDefault();
        if(positions.current[positions.current.length - 1]) {
          if(positions.current[positions.current.length - 1].to <= number.length && se === sa)
            event.preventDefault();
        }
        if(template.value[pos.index].type === "number" && !re.test(event.nativeEvent.key))
          event.preventDefault();
      } else {
        event.preventDefault();
      }
      // @ts-ignore
      setCursorPos(textareaRef.current.selectionEnd);
    }
  }

  const onClick = () => {
    if (textareaRef.current) {
      // @ts-ignore
      const ss = textareaRef.current.selectionStart;
      // @ts-ignore
      const se = textareaRef.current.selectionEnd;
      const pos = positions.current.find(o => o.from <= se && se <= o.to);
      if(pos) {
        if(pos.isEnum) {
          //setNumber(replaceRange(number, pos.from, pos.to, '@'));
          // @ts-ignore
          setTrigger(number.substring(pos.from, pos.to));
          setActualEnum(pos.index);
          // @ts-ignore
          textareaRef.current.setSelectionRange(pos.to - 1, pos.to - 1);
          // Fake changing cursor position to pop up selection
          setTimeout(() => {
            // @ts-ignore
            textareaRef.current.setSelectionRange(pos.to, pos.to);
          }, 20)

        } else {
          // @ts-ignore
          textareaRef.current.setSelectionRange(pos.from, pos.to)
        }
      }
      setCursorPos(se);
      //console.log(`${textareaRef.current.selectionStart} - ${textareaRef.current.selectionEnd}`)
    }
  }

  const renderTableLegend = () => {
    return template.value.map((l,index) => {
      return <div>{`${l.name} ${l.type === "enum" ? 
        ': [' + l.regexTable.map(o => `${o.item} - ${o.description}`).join(", ") + ']' : ''}`}</div>
    })
  }

  return <div style={{flexDirection: "row"}}>
    {previousNumber !== '' && <div>
      {`Formát čísla byl změněn, předchozí hodnota čísla: ${previousNumber}`}
    </div>}
    <MentionsInput
      // @ts-ignore
      inputRef={textareaRef}
      onKeyDown={onKeyDown}
      disabled={disabled}
      onClick={onClick}
      style={{
        ...mentionStyle,
        opacity: disabled ? 0.5 : 1,
      }}
      singleLine
      value={number}
      allowSpaceInQuery={true}
      onChange={onChange}>
      <Mention
        trigger={new RegExp(
          `(?:^|\)(${trigger}([^${'\\s'}${trigger}]*))$`
        )}
        //trigger={trigger}
        style={{ backgroundColor: '#BBBBBB',
          paddingTop: '6px',
          paddingBottom: '6px' }}
        //displayTransform={(id, display) => `[${display}]`}
        data={template.value[actualEnum] ?
          (template.value[actualEnum].regexTable ? template.value[actualEnum].regexTable : [])
          .map((o, oIndex) => ({ id: oIndex, display: o.item })) : []}
      />
    </MentionsInput>
    <OverlayTrigger rootClose trigger='click' placement='bottom' overlay={
      <Popover id='playerColorPicker' placement='bottom'>
        <div>
          <MentionsInput
            style={mentionStyle}
            singleLine
            disabled
            value={template.template}>
            <Mention
              trigger=''
              style={{ backgroundColor: '#BBBBBB',
                paddingTop: '6px',
                paddingBottom: '6px' }}
              //displayTransform={(id, display) => `[${display}]`}
              data={template.value.map((l,i) => ({id: i, display: l.name}))}
            />
          </MentionsInput>
        </div>
        {renderTableLegend()}
      </Popover>
    }>
      <a style={{ cursor: 'pointer' }}>Legenda</a>
    </OverlayTrigger>
  </div>
}