import {CanView} from "../../Main";
import {IDispatchingAttributesCustom} from "@cml/types";
import ItemsSelector from "../../ItemsSelector";
import {SmallVehiclePreview} from "../../vehicles/Preview";
import {Attributes, Roles, Vehicles} from "@cml/models";
import VehicleEdit from "../../vehicles/Edit";
import React from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getVehicles} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";


interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrRealVehicle({ index, attribute, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const subOriginVehicleId_a = useSelector(selectDataRowValue(index, singleRowEditMode, 'subOriginVehicleId_a'));

  const subOriginVehicleId = useSelector(selectDataRowValue(index, singleRowEditMode, 'subOriginVehicleId'));

  const realVehicle = useSelector(selectDataRowValue(index, singleRowEditMode, 'realVehicle'));

  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { allDetails: 0, onlyForDispatching: 1  }}, );

  const vehicleChange = (vehicle: Vehicles) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      subOriginVehicleId: vehicle.id,
      subOriginVehicleId_a: 0,
    }))
  }

  if (!vehicles)
    return <div>Loading</div>

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canRealVehicle && dispatchOther.canRealVehicle}>
    <td>
      <ItemsSelector externList={vehicles}
                     itemsToFetch='vehicles'
                     preview={SmallVehiclePreview}
                     overrideToggleFunc={(vehicle: Vehicles) =>
                       subOriginVehicleId_a ? <SmallVehiclePreview item={realVehicle}/>
                         : (vehicle.id === 0 ? <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                           Neznámý
                         </div> : <SmallVehiclePreview item={vehicle}/>)}
                     newEditComponent={ VehicleEdit }
                     newEditComponentDefaultProps={{
                       isForSupliers: true,
                       isExternal: true,
                       spaceId: authenticate.activeSpaceId,
                     }}
                     bsSize="xsmall"
                     appendItemsToStart={[
                       {id: 0, identifier: 'Nevybrán'},
                     ]}
                     drawFirstWithDividers
                     filterEnable
                     onListSplitterCondition={(index: number, vehicle: Vehicles) => {
                       if (vehicle.id === 0)
                         return false;
                       return vehicle.isExternal;
                     }}
                     value={'identifier'}
                     onSelected={vehicleChange}
                     defaultSelectedId={subOriginVehicleId}/>
    </td>
  </CanView>
}