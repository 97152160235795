import {IDiet, ISalary} from "../../users/Salary";
import FontAwesome from "react-fontawesome";
import React from "react";

interface Props {
  diets: IDiet[]
  salary?: ISalary | null;
  onHeaderClick?: () => void;
  plusEnabled: boolean;
}

const SalaryHeader = (props: Props) => {
  const { salary, onHeaderClick, plusEnabled, diets } = props;
  return [
    <tr>
      <th colSpan={2} style={{backgroundColor: 'transparent', border: 0}}/>
      <th colSpan={3}> Pracovní dny </th>
      <th colSpan={4}>Hodiny</th>
      <th colSpan={5}>Detail mzdy za:</th>
      <th colSpan={3 + diets.length}>Diety</th>
    </tr>,
    <tr>
      <th>Název{plusEnabled && <button
        className='transparentButton'
        title='Přidat vlastní položku'
        onClick={onHeaderClick}>
        <FontAwesome style={{fontSize:'14px', marginLeft: '5px'}} name='fas fa-plus' />
      </button>}</th>
      <th>Strategie</th>
      <th>Standard</th>
      <th>Víkend</th>
      <th>Svátek</th>
      <th>Fond</th>
      <th>Přesčas</th>
      <th>Celkem</th>
      <th>Svátek</th>
      <th>Hodiny</th>
      <th>Svátek</th>
      <th>Víkendy</th>
      <th>Faktur.</th>
      <th>Fakt. Mzda</th>
      <th>Info</th>
      {diets.map(diet => {
        return <th key={diet.price}>{diet.price}</th>
      })}
      <th>Vlastní</th>
      <th>Prémie</th>
      <th>Celkem mzda</th>
    </tr>
  ]
}

export default SalaryHeader;