import React, { Component } from "react";
import ItemsSelector from "../../ItemsSelector";
import {Button, ControlLabel, FormControl} from "react-bootstrap";

interface Props {
    priceAttributes: object[];
    onPriceBudgetDone?: (id: number, amount: number, price: number) => void;
    disablePrice?: boolean;
}

interface State {
    attrId: number;
    amount: number;
    price: number;
}

export default class BudgetOption extends Component<Props, State> {

    state = {
        attrId: 0,
        amount: 0,
        price: 0,
    };

    static defaultProps = {
        priceAttributes: [],
        disablePrice: false,
    };

    handleClick = () => {
        if (this.props.onPriceBudgetDone)
            this.props.onPriceBudgetDone(this.state.attrId, this.state.amount, this.state.price);
    };

    handleSelected = (item: {id: number, price?: string}, index: string) => {
        this.setState({ attrId: item.id, price: item.price ? parseFloat(item.price) : this.state.price });
    };

    render() {
        return <div className='row'>
            <div className='col-sm-2'>
                <ControlLabel>
                    Množství
                </ControlLabel>
                <div>
                    <FormControl type="number"
                                 // @ts-ignore
                                 onFocus={(e) => e.target.select()}
                                 min={0}
                                 value={this.state.amount}
                                 onChange={(e) => this.setState({
                                     amount: parseFloat((e.target as HTMLInputElement).value),
                                 })}
                    />
                </div>
            </div>
            <div className='col-sm-2'>
                <ControlLabel/>
                <div style={{ marginTop: '5px' }}>
                    <ItemsSelector onSelected={this.handleSelected}  value={'name'} externList={this.props.priceAttributes} />
                </div>
            </div>
            <div className='col-sm-2'>
                <ControlLabel>
                    Cena / J
                </ControlLabel>
                <div>
                    <FormControl type="number"
                                 disabled={this.props.disablePrice}
                                 // @ts-ignore
                                 onFocus={(e) => e.target.select()}
                                 min={0}
                                 value={this.state.price}
                                 onChange={(e) => this.setState({
                                     price: parseFloat((e.target as HTMLInputElement).value),
                                 })}
                    />
                </div>
            </div>
            <div className='col-sm-2'>
                <ControlLabel>
                    Celkem
                </ControlLabel>
                <div style={{ marginTop: '7px' }}>
                    { `${this.state.price * this.state.amount} Kč` }
                </div>
            </div>
            <div className='col-sm-2'>
                <ControlLabel/>
                <div style={{ marginTop: '5px' }}>
                    <Button onClick={this.handleClick}>
                        Rozpočet
                    </Button>
                </div>
            </div>

        </div>
    }
}