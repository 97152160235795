import {Modal, Button} from "react-bootstrap";
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {Attributes, DataRow, Users, Orders} from "@cml/models";
import {
  getAttributes, getCompanies, getOrders,
  getUsersForDispatching,
  getUsersWithSpecificRoleWhere,
  getVehicles
} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import moment, {Moment} from "moment";
import {SmallOrderPreview} from "../../orders/Preview";
import {dateFormatVisible, dateFromat, getTimeString} from "../../../AppObjects";
import {SmallUserPreview} from "../../users/Preview";
import {getStore} from "./datarowBase";
import {IDispatchingAttributesCustom, ISmsInfo, Material, OrderOther} from "@cml/types";
import {getSmsText, getSmsTextPrice, getSmsTextSenderUser} from "../dataRowComponents/DrState";
import FontAwesome from "react-fontawesome";
import store from "../../../redux/store";
import request from "axios";
import {show} from "react-notification-system-redux";
import {LoadingOrRender} from "../../../loginsrc/Profile";
import MaterialTable from "./MaterialTable";
import _ from "lodash";
import {getValuesOfCustomNumber} from "../../utils/NumberQueue";

interface Props {
  onHide: () => void;
  attribute: Attributes;
  singleRowEditMode: boolean;
  date: Moment;
  modeContactUser?: boolean;
}

const orderDivStyle = {
  marginBottom: 20,
}

export function CleverSms({ onHide, attribute, singleRowEditMode, date, modeContactUser }: Props) {

  const [active, setActive] = useState(false);

  const dataRows = useSelector((state: {dispatching: DataRowsState}) =>
    modeContactUser ? state.dispatching.dataRows : state.dispatching.dataRows.filter(o => o.attributeId === attribute.id))

  const uniqueOrders = !modeContactUser ?
    Array.from(new Set([...dataRows.map(o => o.orderId).filter(o => o)])) : []

  const uniqueContactUsers = modeContactUser ?
    Array.from(new Set([...dataRows.map(o => o.contactUserId).filter(o => o)])) : []

  const { users, isValidating: usersValidating } = getUsersForDispatching(
    { params: { date: date.format(dateFromat) }}, singleRowEditMode
      ? swrOption : undefined);

  const { contactUsers, isValidating: contactValidating } = getUsersWithSpecificRoleWhere(
    { params: { roleWhere: { isContactUser: 1 }}}, );

  const { attributes: priceAttributes,
    isValidating: priceValidating } = getAttributes(
    'price' );
  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes(
    'material' );
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store' );

  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { allDetails: 0, onlyForDispatching: 1  }}, );

  const { orders, isValidating: ordersValidating, revalidate: ordersRevalidate }
  = getOrders({ params: { where: { isConfirmed: 0 }}}, );

  if(!vehicles || !users || !contactUsers)
    return <div>Loading</div>;


  const renderText = (actualUsers: {user: Users | undefined}[]) => (row: DataRow, index: number) => {
    const user = users.find(u => u.id === row.userId);
    const vehicle = vehicles.find(v => v.id === row.vehicleId);
    const order = orders.find(o => o.id === row.orderId);
    const contactUser = contactUsers.find(u => u.id === row.contactUserId);
    const oneStore = getStore(row, storeAttributes);
    if (!user || !vehicle)
      return <div>Chyba načítání SMS textu</div>
    let text = ((attribute.customOptions as IDispatchingAttributesCustom) as IDispatchingAttributesCustom).smsTemplateText;
    text = getSmsText(text, row, vehicle, oneStore, user, order as Orders, contactUser);
    text = getSmsTextSenderUser(text, (store.getState().authenticate as AuthenticateState).loggedUser as Users);
    text = getSmsTextPrice(text, row, priceAttributes);
    text = text.replace('%frac%', `${MaterialTable.GenerateString({
      materialTable: row.materialTable as Material[],
      priceAttributes,
      // @ts-ignore
      materialAttributes,
      storeAttributes
    }, true)}`);

    if(actualUsers.length > 1) {
      text += 'Kolegové: ' + actualUsers.map(u => u.user ? u.user.surname : null)
        .filter(u => u).join(', ');
    }
    return <tr style={{ marginBottom: 5, borderBottom: '1px solid #BBBBBB'}}
               key={`clever_sms_order_${index}`}>
      <td style={{ paddingRight: 20, minWidth: 200 }}><SmallUserPreview item={user}/></td>
      <td style={{ whiteSpace: 'unset' }}>{text}</td>
      <td><FontAwesome
        style={{ color: row.isConfirmed > 0 ? 'darkgreen' : 'darkred'}}
        name={row.isConfirmed > 0 ? 'check': 'times'}/></td>
    </tr>;
  }

  const sendTextForOrderHandler = (
    rows: DataRow[],
    actualUsers: {user: Users | undefined}[]) => () => {
    setActive(true);
    sendTextForOrder(rows, actualUsers)
      .then(onOk(true))
      .catch(onError)
  }

  const onOk = (stay: boolean) => () => {
    setActive(false);
    store.dispatch(show({
      title: 'Info',
      level: 'info',
      autoDismiss: 3,
      children: (
        <div>
          Odesláno
        </div>
      ),
    }, 'info'))
    if (!stay)
      onHide();
  }

  const onError = (telnumb: string) => {
    setActive(false);
    store.dispatch(show({
      title: 'Chyba',
      level: 'error',
      autoDismiss: 10,
      children: (
        <div>
          {`SMS nelze odeslat číslu: ${telnumb},zkontrolujte zda číslo existuje. Pokud potíže přetrvají kontaktujte podporu`}
        </div>
      ),
    }, 'error'))
  }

  const sendTextForOrder = (
    rows: DataRow[],
    actualUsers: {user: Users | undefined}[]) => {
    return rows.reduce((chain, row, index) => chain.then(() =>
      new Promise((resolve, reject) => {
        if (row.isConfirmed === 0) {
          const user = users.find(u => u.id === row.userId);
          const vehicle = vehicles.find(v => v.id === row.vehicleId);
          const contactUser = contactUsers.find(u => u.id === row.contactUserId);
          const order = orders.find(o => o.id === row.orderId);
          const oneStore = getStore(row, storeAttributes);
          if (!user || !vehicle || !contactUser)
            return <div>Chyba načítání SMS textu</div>
          let text = ((attribute.customOptions as IDispatchingAttributesCustom) as IDispatchingAttributesCustom).smsTemplateText;
          text = getSmsText(text, row, vehicle, oneStore, user, order as Orders, contactUser);
          text = getSmsTextSenderUser(text, (store.getState().authenticate as AuthenticateState).loggedUser as Users);
          text = getSmsTextPrice(text, row, priceAttributes);
          text = text.replace('%frac%', `${MaterialTable.GenerateString({
            materialTable: row.materialTable as Material[],
            priceAttributes,
            // @ts-ignore
            materialAttributes,
            storeAttributes
          }, true)}`);

          if(actualUsers.length > 1) {
            text += 'Kolegové: ' + actualUsers.map(u => u.user ? u.user.surname : null)
              .filter(u => u).join(', ');
          }
          const originRowIndex = store.getState().dispatching.dataRows.findIndex(o => o.id === row.id);
          if(originRowIndex > -1) {
            /*setTimeout(() => {
              store.dispatch(setDataRowValue(originRowIndex, {
                isConfirmed: 1,
              }))
              resolve();
            }, 500)*/
            request.post('/items/sms', {
              to: user.telnumb.replace('+', ''),
              text: text,
            }, { timeout: 7000 })
              .then((res) => {
                if (res.status === 200) {
                  store.dispatch(setDataRowValue(originRowIndex, singleRowEditMode, {
                    isConfirmed: 1,
                  }))
                  resolve();
                }
              })
              .catch((error) => {
                if(user) {
                  reject(user.telnumb);
                }
              })
          }
        } else {
          resolve();
        }
      })), Promise.resolve())
  }

  const sendAll = () => {
    setActive(true);
    uniqueOrders.reduce((acc, o, index) => acc.then(() =>
    new Promise((resolve, reject) => {
      if (!o)
        return null;
      const actualRows = dataRows.filter(d => d.orderId === o);
      const actualUsers = actualRows.map(d => ({
        user: users.find(u => u.id === d.userId)
      }));
      sendTextForOrder(actualRows, actualUsers)
        .then(() => resolve())
        .catch((telnumb) => reject(telnumb))

    })), Promise.resolve())
      .then(onOk(false))
      .catch(onError)
  }

  const renderOrders = (length: number) => (o: number | null, index: number) => {
    if (!o)
      return null;
    const order = orders.find(or => or.id === o);
    const actualRows = dataRows.filter(d => d.orderId === o);
    const actualUsers = actualRows.map(d => ({
      user: users.find(u => u.id === d.userId)
    }))

    return <div style={orderDivStyle}  key={`clever_sms_order_${index}`}>

      <div style={{ marginBottom: 5 }}>
        <h4 style={{ display: 'inline-block', marginRight: '20px' }}>{`${index + 1}/${length}:`}</h4>
        <SmallOrderPreview item={order}/>
      </div>

      <div className='dataTable'>
        <table>
          <tbody>
          {actualRows.map(renderText(actualUsers))}
          </tbody>
        </table>
      </div>
      <LoadingOrRender requestActive={active}>
        <Button onClick={sendTextForOrderHandler(actualRows, actualUsers)}
                bsStyle='primary' style={{ marginTop: '10px' }}>
          Odeslat zakázku
        </Button>
      </LoadingOrRender>
    </div>
  }

  const getContactUserSmsText = (o: number) => {
    const actualRows = dataRows.filter(d => d.contactUserId === o);
    const uniqueOrders = Array.from(new Set([...actualRows.map(o => o.orderId)]));
    let topText: { text: string; done: boolean; rowsId: number[] }[] = [];
    uniqueOrders.forEach(u => {
      const actualRows2 = actualRows.filter(d => d.orderId === u);
      const actualConstructions = _.uniq(actualRows2.map(d => d.construction));
      const actualVehicles = actualRows2.map(d => vehicles.find(v => v.id === d.vehicleId)).filter(o => o);
      const actualUsers = actualRows2.map(d => users.find(u => u.id === d.userId)).filter(o => o);

      const merged = actualRows2.map(d => ({
        vehicle: vehicles.find(v => v.id === d.vehicleId),
        user: users.find(u => u.id === d.userId),
        from: d.from,
        to: d.to,
        priceEq: d.priceEq
      }));

      const actualOrder = orders.find(o => o.id === u);

      const rowDoneLength = actualRows2.reduce((acc, row) => acc +=
        row.smsInfo ? ((row.smsInfo as ISmsInfo).contactUserSmsId !== null ? 1 : 0) : 0 ,0)

      let text = ((attribute.customOptions as IDispatchingAttributesCustom) as IDispatchingAttributesCustom).smsContactTemplateText;
      text = text.replace('%date%', moment(date, dateFromat).format(dateFormatVisible));
      text = text.replace('%con%', actualConstructions.join(', '));
      text = text.replace('%onf%', `${actualOrder ? actualOrder.receivedOrderNumber : 'neznámo'}`)
      text = text.replace('%oml%', `${actualOrder ? actualOrder.meetingLocation : 'neznámo'}`)
      text = text.replace('%load%', `${actualOrder ? actualOrder.loading : 'neznámo'}`);
      text = text.replace('%unlo%', `${actualOrder ? actualOrder.unloading : 'neznámo'}`);
      text = text.replace('%com%', `${actualOrder ? actualOrder.company : 'neznámo'}`);
      text = text.replace('%con%', `${actualOrder ? actualOrder.construction : 'neznámo'}`);

      if (actualOrder) {
        const defTime = (actualOrder.other as OrderOther).dayWorkTime[moment(date).day()];

        text = text.replace('%tf%', `${getTimeString(defTime.from)}`);
        text = text.replace('%tt%', `${getTimeString(defTime.to)}`);
        text = text.replace('%brea%', `${getTimeString(defTime.break)}`);

        const positions =
          // @ts-ignore
          getValuesOfCustomNumber((store.getState().authenticate as AuthenticateState).activeSpace.setting.ordersNumberTemplate,
            (actualOrder && actualOrder.customNumber) ? actualOrder.customNumber : '');


        while(text.indexOf('%onc') > -1) {
          const index = text.indexOf('%onc');
          let posIndex = -1;
          let actIndex = index + '%onc'.length;
          while(posIndex === -1 && actIndex < text.length) {
            if(text[actIndex] === '%') {
              posIndex = parseInt(text.substring(index + '%onc'.length, actIndex))
            }
            actIndex++
          }
          if(isNaN(posIndex))
            break;
          text = text.replace(`%onc${posIndex}%`,
            `${positions ? (posIndex < positions.length ? positions[posIndex].value : 'neznámo') : 'neznámo'}`)
        }
        // @ts-ignore
        /*text = getSmsTextPrice(text, {priceEq: (actualOrder.other as OrderOther)
            .prices.filter(p => p.enabled)}, priceAttributes);*/
        text = text.replace('%on%', actualOrder.customNumber ? actualOrder.customNumber : 'neznámo');
      }
      text = text.replace('%veh%', `${actualVehicles.map(o => o ? o.identifier : null).join(', ')}`);
      text = text.replace('%user%', `${actualUsers.map(u => u ? (u.nameSurname + ': ' + u.telnumb) : null)
        .join(', ')}`);
      text = getSmsTextSenderUser(text, (store.getState().authenticate as AuthenticateState).loggedUser as Users);
      text = text.replace('%merge%',
        merged.map(m => `${m.vehicle ? m.vehicle.identifier : ''} ${m.user ?
          m.user.nameSurname + ' ' + m.user.telnumb : ''} (${getTimeString(m.from)} - ${getTimeString(m.to)}) učtovat: ${
          getSmsTextPrice('%price%', m as DataRow, priceAttributes)}`).join('\n\n'))

      topText.push({
        text,
        done: rowDoneLength === actualRows2.length,
        rowsId: actualRows2.map(o => o.id),
      });
    })

    return topText;
  }

  const sendSmsToContactUsersAll = () => {
    setActive(true);
    uniqueContactUsers.reduce((acc, o, index) => acc.then(() => {
      return new Promise((resolve, reject) => {
        sendSmsToContactUsers(o)
          .then(() => resolve())
          .catch(err => reject(err))
      })}), Promise.resolve())
      .then(onOk(false))
      .catch(onError)
  }

  const sendSmsToContactUsersHandler = (o: number | null) => {
    setActive(true);
    sendSmsToContactUsers(o)
      .then(onOk(true))
      .catch(onError)
  }

  const sendSmsToContactUsers = (o: number | null) => {
    return new Promise((resolve, reject) => {
      if (!o) {
        return;
      }
      const contactUser = contactUsers.find(or => or.id === o) as Users;
      let text = getContactUserSmsText(o);
      text.reduce((acc, t) => acc.then(() => new Promise((resolve1) => {
        if(!t.done) {
          request.post('/items/sms', {
            to: contactUser.telnumb.replace('+', ''),
            text: t.text,
          }, { timeout: 7000 })
            .then((res) => {
              t.rowsId.forEach(ar => {
                const originRowIndex = store.getState().dispatching.dataRows.findIndex(o => o.id === ar);
                const row = store.getState().dispatching.dataRows[originRowIndex];
                if (row.smsInfo)
                  (row.smsInfo as ISmsInfo).contactUserSmsId = 1;
                else {
                  // @ts-ignore
                  row.smsInfo = {
                    contactUserSmsId: 1,
                    contactUserSmsDate: null,
                    driverUserSmsDate: null,
                    driverUserSmsId: null,
                  }
                }
                store.dispatch(setDataRowValue(originRowIndex, singleRowEditMode, {
                  // @ts-ignore
                  smsInfo: { ...row.smsInfo },
                }))
              })
              resolve1()
            })
            .catch((error) => {
              if(contactUser) {
                reject(contactUser.telnumb);
              }
            })
        } else {
          resolve1();
        }
      })), Promise.resolve())
        .then(() => resolve())
    })
  }

  const renderContactUsers = (length: number) => (o: number | null , index: number) => {
    if (!o)
      return null;
    const contactUser = contactUsers.find(or => or.id === o);
    let text = getContactUserSmsText(o);

    return <div style={orderDivStyle}  key={`clever_sms_order_${index}`}>
      <SmallUserPreview item={contactUser}/>
      <ul>
        {text.map(t => <li>{t.text} {t.done && <FontAwesome style={{ marginLeft: 10, color: '#00AA00' }}
                                                            title='SMS s infem odeslána kontaktu'
                                                            name='check'/>}</li>)}
      </ul>
      <LoadingOrRender requestActive={active}>
        <Button onClick={() => sendSmsToContactUsersHandler(o)}
                bsStyle='primary' style={{ marginTop: '10px' }}>
          Odeslat info kontaktu
        </Button>
      </LoadingOrRender>
    </div>
  }

  return <Modal dialogClassName='modal-90w' show={true} onHide={onHide}>
    <Modal.Header closeButton>Chytrá SMS</Modal.Header>
    <Modal.Body>
      {!modeContactUser && <h4>{`Celkem zakázek: ${uniqueOrders.length}`}</h4>}
      {!modeContactUser && uniqueOrders.map(renderOrders(uniqueOrders.length))}
      {modeContactUser && uniqueContactUsers.map(renderContactUsers(uniqueContactUsers.length))}
      <div style={{ textAlign: 'right' }}>
        <LoadingOrRender requestActive={active}>
          <Button onClick={modeContactUser ? sendSmsToContactUsersAll : sendAll} bsStyle='primary' style={{ marginTop: '10px' }}>
            Odeslat všechno
          </Button>
        </LoadingOrRender>
      </div>

    </Modal.Body>
  </Modal>
}