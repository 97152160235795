import * as React from 'react';
import styled from '@emotion/styled';

const S = {
	Tray: styled.div`
		background: rgb(50, 50, 50);
		flex-grow: 0;
		flex-shrink: 0;
	`,
};

export class TrayWidget extends React.Component {
	render() {
		return <S.Tray>{this.props.children}</S.Tray>;
	}
}
