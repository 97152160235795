import React, {useRef, useState} from "react";
import {MenuItem, Navbar, Nav, NavItem} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { useHistory } from "react-router";

function NavigationItem(props: {
  name: string,
  icon: string;
  eventKey: any;
  menuVisible: boolean,
  handleSelect: (e: any) => void;
}) {



  return <li className="nav-item text-left">
    <NavItem onClick={() => props.handleSelect(props.eventKey)} eventKey={props.eventKey} className='MenuItemHome nav-link'>
      <div title={props.menuVisible ? undefined : props.name} className='icon'>
        <FontAwesome
          // @ts-ignore
          name={props.icon}/>
      </div>
      {props.menuVisible && <div className='rectangle' style={{ display: 'inline-block'}}>
        {props.name}
      </div>}

    </NavItem>
  </li>
}

export default function SideMenuNavBar(props: {full: boolean, setFull: (d: boolean) => void}) {

  const toggle = useRef<HTMLButtonElement>(null);
  const history = useHistory();

  const handleSelect = (e: string) => {
    history.push(e as any);
    if (toggle.current && toggle.current.clientWidth)
      toggle.current.click();
  }

  const handleFull = () => {
    props.setFull(!props.full);
  }

  return <Navbar
    style={{ width: props.full ? '23rem' : '5rem' }}
    className="navbar  navbar-dark  "
    id="sideNavHome"
  >
    <Navbar.Header style={{ width: props.full ? '100%' : undefined }}>
      <div style={{ width: '100%', color: '#eeeeee',
        marginTop: '10px', fontSize: '20px' }}
           className='text-right'>
        <button style={{ marginRight: props.full ? '15px' : undefined }} onClick={handleFull} className='transparentButton2'>
          <FontAwesome name={props.full ? 'angle-left' : 'angle-right'}/>
        </button>
      </div>
      <Navbar.Toggle />
    </Navbar.Header>
    <Navbar.Collapse style={{ marginLeft: props.full ? undefined : '-2.5rem' }}>
      <Nav>
        <NavItem className='splitter'>{props.full ? 'Kartotéka': '-'}</NavItem>
        <NavigationItem handleSelect={handleSelect} eventKey={'/users'} icon='users' menuVisible={props.full} name={'Lidé'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/vehicles'} icon='car' menuVisible={props.full} name={'Vozidla'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/addressbook'} icon='address-book' menuVisible={props.full} name={'Adresář'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/creditcard'} icon='credit-card' menuVisible={props.full} name={'Platební karty'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/tools'} icon='toolbox' menuVisible={props.full} name={'Vybavení'}/>
        <NavItem className='splitter'>{props.full ? 'Dispečink': '-'}</NavItem>
        <NavigationItem handleSelect={handleSelect} eventKey={'/dispatching'} icon='calendar-alt' menuVisible={props.full} name={'Kalendář'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/orders'} icon='hands-helping' menuVisible={props.full} name={'Zakázky'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/attendance'} icon='coffee' menuVisible={props.full} name={'Docházka'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/tasks'} icon='tasks' menuVisible={props.full} name={'Úkoly'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/sms'} icon='comments' menuVisible={props.full} name={'SMS'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/results'} icon='search' menuVisible={props.full} name={'Výsledky'}/>
        <NavItem className='splitter'>{props.full ? 'Zdroje': '-'}</NavItem>
        <NavigationItem handleSelect={handleSelect} eventKey={'/salary'} icon='money-check-alt' menuVisible={props.full} name={'Mzdy'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/phm'} icon='gas-pump' menuVisible={props.full} name={'PHM'}/>
        <NavItem className='splitter'>{props.full ? 'Ostatní': '-'}</NavItem>
        <NavigationItem handleSelect={handleSelect} eventKey={'/logs'} icon='info-circle' menuVisible={props.full} name={'Log'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/import'} icon='file-import' menuVisible={props.full} name={'Import'}/>
        <NavigationItem handleSelect={handleSelect} eventKey={'/setting'} icon='cog' menuVisible={props.full} name={'Nastavení'}/>
      </Nav>
    </Navbar.Collapse>

  </Navbar>
}