import {Attributes, Roles, Users} from "@cml/models";
import {CanView} from "../../Main";
import {IDispatchingAttributesCustom} from "@cml/types";
import FontAwesome from "react-fontawesome";
import ItemsSelector from "../../ItemsSelector";
import {SmallUserPreview} from "../../users/Preview";
import UserEdit from "../../users/Edit";
import {MenuItem} from "react-bootstrap";
import React from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getUsersForDispatching} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
  showDate: boolean | undefined;
}

export default function DrUser({index, attribute, singleRowEditMode, showDate}: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const mapUsersDoubled = useSelector((state: { dispatching: DataRowsState }) =>
    index === -1 ? [] : state.dispatching.mapUsersDoubled[state.dispatching.dataRows[index].userId as number]);

  const usersUsed = useSelector((state: { dispatching: DataRowsState }) =>
    state.dispatching.usersUsed);

  const userId_a = useSelector(selectDataRowValue(index, singleRowEditMode, 'userId_a'));

  const userId = useSelector(selectDataRowValue(index, singleRowEditMode, 'userId'));

  const userData = useSelector(selectDataRowValue(index, singleRowEditMode, 'user'));

  const date = useSelector(selectDataRowValue(index, singleRowEditMode, 'date'));

  const { users, isValidating: usersValidating } = getUsersForDispatching(
    { params: { date: date }}, showDate || singleRowEditMode
      ? swrOption : undefined);

  if(!users)
    return <div>Loading</div>

  const handleUserChange = (user: Users) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      userId: user.id,
      userId_a: 0
    }));
  }

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canUser && dispatchOther.canUser && !(attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook}>
    <td>
      {mapUsersDoubled && !singleRowEditMode ? (mapUsersDoubled > 1 ? <span>
            <FontAwesome name='fas fa-exclamation' style={{marginRight: '2px', color: 'darkorange'}}/>
        {`${mapUsersDoubled}x `}
          </span> : '') : ''}
      <ItemsSelector externList={users}
                     itemsToFetch='users'
                     overrideToggleFunc={(user: Users) => (userId_a as number) > 0 ?
                       <SmallUserPreview style={{fontSize: '11px', display: 'inline', marginRight: '5px'}}
                                         item={userData}/> : (
                         userId === 0 ? <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                           Nevybrán
                         </div> : <SmallUserPreview style={{fontSize: '11px', display: 'inline', marginRight: '5px'}}
                                                    colorIfAttendance={true}
                                                    errorIfForeignDataRows
                                                    item={user}/>
                       )}
                     newEditComponent={ UserEdit }
                     newEditComponentDefaultProps={{
                       disableAdmin: true,
                       rolesWhere: { where: { visibleForCalendar: 1 }}
                     }}
                     bsSize="xsmall"
                     appendItemsToStart={singleRowEditMode ? [] : [
                       {id: 0, nameSurname: 'Nevybrán'},
                     ]}
                     drawFirstWithDividers={!singleRowEditMode}
                     filterEnable
                     listSplitter={[
                       {
                         header: <CanView visible={!singleRowEditMode}>
                           <MenuItem header>Dostupní</MenuItem>
                         </CanView>
                       },
                       {
                         header: [
                           singleRowEditMode ? null : <MenuItem divider />,
                           <MenuItem header>{singleRowEditMode ? 'Strojníci' : 'Použití'}</MenuItem>,
                         ]
                       }
                     ]}
                     onListSplitterCondition={(index: number, user: Users) => {

                       if ((singleRowEditMode && index === 0) || user.id === 0 || user.dataRows.length)
                         return false;
                       return !(
                         (singleRowEditMode ? false: (index === 0 ? usersUsed.includes(user.id) : !usersUsed.includes(user.id))) ||
                         (!singleRowEditMode && user.attendance.length > 0 && user.attendance.filter(o => !o.isPartDay).length > 0)
                       );
                     }}
                     value={'nameSurname'}
                     onSelected={handleUserChange}
                     defaultSelectedId={userId}/>
    </td>
  </CanView>
}