import React, { Component } from 'react';
import {Form,
  FormControl,
  FormGroup,
  ControlLabel,
  Panel,
  Col,
  Modal,
  HelpBlock} from 'react-bootstrap';
import PropTypes from 'prop-types';
import request from 'axios';
import SubmitButton from './SubmitButton';
import FontAwesome from 'react-fontawesome';
import store from "../redux/store";
import { show } from 'react-notification-system-redux';

const formObject = {
  email: 'email',
  token: 'token',
};

export default class EmailValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
      token: '',
      showmodal: false,
      focusedInput: '',
      requestActive: false,
    };
  }

  handleSubmit(e) {
    /*if(this.checkPasswordInputs(true))
      this.props.history.push('/');*/
    if(this.checkInputs(true)) {
      this.setState({ requestActive:true });
      request.post('/validemail',{
        values: {
          email: this.state.email,
          token: this.state.token,
        }
      }, { timeout: 20000 } )
        .then((res) => {
          if (res.status === 200) {
            this.setState({showmodal: true});
            setTimeout(() => {
                this.setState({showmodal: false});
                this.props.history.push('/login')
              }, 2000);
          }
          this.setState({ requestActive:false });
        })
        .catch((error) => {
          store.dispatch(show({
            title: 'Chyba !',
            level: 'error',
            autoDismiss: 15,
            children: (
              <div>
                {error.response.data.message}
              </div>
            ),
          }, 'error'));
          this.setState({ requestActive:false });
        });
    }
    e.preventDefault();
  }

  checkInputs(canFocus) {
    let helpMessage = [];
    helpMessage[formObject.email] = {
      message: null,
      error: this.state.email.length < 1,
    };
    helpMessage[formObject.token] = {
      message: null,
      error: this.state.token.length < 1,
    };
    switch (this.state.focusedInput) {
      case formObject.email:
        if (helpMessage[formObject.email].error)
          helpMessage[formObject.email].message = <HelpBlock>
            <ul>
              <li>Email musí být vyplněn</li>
            </ul>
          </HelpBlock>;
        break;
      case formObject.token:
        if (helpMessage[formObject.token].error)
          helpMessage[formObject.token].message = <HelpBlock>
            <ul>
              <li>Potvrzovací token musí být zadán</li>
            </ul>
          </HelpBlock>;
        break;
    }
    let ok = true;
    if(canFocus) {
      for (let key in helpMessage) {
        if(helpMessage[key].error){

          this.setState({focusedInput: key});
          ok = false;
          break;
        }
      }
      return ok;
    }

    return helpMessage;
  }

  render() {

    let helpMessage = this.checkInputs(false);

    return(
      <div>
        <Modal show={this.state.showmodal}>
          <Modal.Body>
            <div style={{display: 'table', width:'100%',
              marginRight: '10px'}}>
              <div style={{display: 'table-cell', verticalAlign:'middle',}}>
                Registrace byla dokončena, váš účet je aktivovaný, nyní se můžete přihlásit.
              </div>
              <div style={{display: 'table-cell', verticalAlign:'middle',}}>
                <FontAwesome style={{fontSize:'40px', color: 'green', }} name='check-circle' />
              </div>

            </div>

          </Modal.Body>
        </Modal>
        <div className="login-clean">
            <Form onSubmit={(e) => this.handleSubmit(e)} horizontal>
              <div className='illustration'>
                <FontAwesome name='fas fa-user-check' />
              </div>
              <FormGroup controlId={'email'}
                         validationState={this.state.email.length > 0 ? null:'error'}>
                  <FormControl type="email"
                               value={this.state.email}
                               placeholder="Email"
                               onFocus={() => this.setState({focusedInput: formObject.email})}
                               onChange={(e) => this.setState({email: e.target.value})}/>
                  {helpMessage[formObject.email].message}
              </FormGroup>
              <FormGroup controlId={'token'}
                         validationState={this.state.token.length > 0 ? null:'error'}>
                  <FormControl type="text"
                               value={this.state.token}
                               placeholder="Potvrzovací token"
                               onFocus={() => this.setState({focusedInput: formObject.token})}
                               onChange={(e) => this.setState({token: e.target.value})}/>
                  {helpMessage[formObject.token].message}
              </FormGroup>
              <FormGroup>
                  <SubmitButton bsClass='btn btn-primary btn-block'  loading={this.state.requestActive}>Potvrdit</SubmitButton>
              </FormGroup>
            </Form>
        </div>
      </div>
    )
  }
}

EmailValidation.propTypes = {
  email: PropTypes.string,
  onValidationSucces: PropTypes.func.isRequired,
};