import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';

export default class ContentWithFixedHeader extends Component{
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: null,
    }
  }

  render() {
    if (this.props.drawOnlyChildren)
      return this.props.children;

    return <>
      {this.props.header && <div className='fixed-top' ref={ (topFixedElement) => this.topFixedElement = topFixedElement}>
        {this.props.header}
        <ReactResizeDetector handleHeight onResize={(h) => {
          if (this.topFixedElement.clientHeight !== this.state.headerHeight)
            this.setState({
              headerHeight: this.topFixedElement.clientHeight,
            })
        }} />
      </div>}

      <div style={{ marginTop: `${(this.state.headerHeight !== null ? this.state.headerHeight
          : (this.topFixedElement ? this.topFixedElement.clientHeight : 0)) + this.props.baseMargin}px` }}>
        {this.props.children}
      </div>
    </>
  }
}

ContentWithFixedHeader.propTypes = {
  header: PropTypes.object,
  baseMargin: PropTypes.number,
  drawOnlyChildren: PropTypes.bool,
};

ContentWithFixedHeader.defaultProps = {
  header: null,
  baseMargin: 0,
  drawOnlyChildren: false,
};