import { useMemo, useState } from "react";
import React from "react";
import FontAwesome from "react-fontawesome";
import moment from "moment";
import {
  dateFromat,
} from "../../AppObjects";
import {CanView} from "../Main";
import store from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import {
  Attributes,
  DataRow as IDataRow,
  Roles,
} from "@cml/models";
import {AuthenticateState, DataRowsState, deleteDataRow, setActualRowChanging} from '@cml/redux-store';
import { IDispatchingAttributesCustom } from "@cml/types";
import { DispatchingRights } from "@cml/types/dist/types/roles";
import DrMenu from "./dataRowComponents/DrMenu";
import DrMarkersView from "./dataRowComponents/DrMarkersView";
import DrNoteAndAttachments from "./dataRowComponents/DrNoteAndAttachments";
import DrOrder from "./dataRowComponents/DrOrder";
import DrVehicles from "./dataRowComponents/DrVehicles";
import DrRealVehicle from "./dataRowComponents/DrRealVehicle";
import DrUser from "./dataRowComponents/DrUser";
import DrAddressBook from "./dataRowComponents/DrAddressBook";
import DrTools from "./dataRowComponents/DrTools";
import DrLoading from "./dataRowComponents/DrLoading";
import DrUnLoading from "./dataRowComponents/DrUnLoading";
import DrCompany from "./dataRowComponents/DrCompany";
import DrConstruction from "./dataRowComponents/DrConstruction";
import DrContactUser from "./dataRowComponents/DrContactUser";
import DrTimeFields from "./dataRowComponents/DrTimeFields";
import DrMaterial from "./dataRowComponents/DrMaterial";
import DrState from "./dataRowComponents/DrState";
import DrPrice from "./dataRowComponents/DrPrice";
import {Draggable, DraggingStyle} from "react-beautiful-dnd";
import {rowStateColors, rowStateColorsOdd} from "./components/datarowBase";
import {selectDataRowValue} from "./dataRowComponents/selector";
import DrDate from "./dataRowComponents/DrDate";
import DrAttributeSelect from "./dataRowComponents/DrAttributeSelect";


interface Props {
  singleRowEditMode: boolean;
  attribute: Attributes;
  index: number;
  onAddConnWithInvoice: (type: string, index: number) => void;
  onLogRequested: (index: number) => void;
  onOrderRequired: (index: number) => void;
  shouldRender: boolean;
  showDate?: boolean;
}

export const swrOption = {
  revalidateOnFocus: false,
  revalidateOnMount: true,
}

interface ContainerProps {
  index: number;
  attribute: Attributes;
  showDate: boolean;
  dataRow: { id: number,
    indexForColoring: number;
    attributeId: number }
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle,
  index: number,
  isConfirmed: number,
  invoiceForeignId: string | null,
  isSelected: boolean) => (
  {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    //opacity: isHighlight ? 1.0 : 0.2,

    // change background colour if dragging
    background: index % 2 === 0 ? rowStateColors[isConfirmed] : rowStateColorsOdd[isConfirmed],
    //border: isSelected ? '2px solid red' : 'none',


    // styles we need to apply on draggables
    ...draggableStyle,
  });

export function DataRowContainer({ attribute, dataRow, index, showDate }: ContainerProps) {
  const highLighted = useSelector((state: {dispatching: DataRowsState}) =>
    state.dispatching.actualRowIsChangingIndex === index);

  const isConfirmed = useSelector(selectDataRowValue(index, false, 'isConfirmed'));

  const invoiceForeignId = useSelector(selectDataRowValue(index, false, 'invoiceForeignId'));

  if (dataRow.attributeId !== attribute.id)
    return <Draggable key={dataRow.id} draggableId={"dndId" + dataRow.id} index={index}>
      {(draggableProvided, draggableSnapshot) => (<tr
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
      >
      </tr>)}
    </Draggable>;
  else {
    return <Draggable key={`${dataRow.id}`} draggableId={"dndId" + dataRow.id} index={index}>
      {(draggableProvided, draggableSnapshot) => (<tr
        tabIndex={index}
        onClick={() => store.dispatch(setActualRowChanging(index))}
        className={highLighted ? 'red' : 'normal'}
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        // @ts-ignore
        style={getItemStyle(
          draggableSnapshot.isDragging,
          draggableProvided.draggableProps.style as DraggingStyle,
          // @ts-ignore
          dataRow.indexForColoring,
          isConfirmed,
          invoiceForeignId,
          highLighted,
        )}
      >
        <DataRow
          unresolvedMode={false}
          singleRowEditMode={false}
          attribute={attribute}
          index={index}
          onAddConnWithInvoice={() => {}}
          shouldRender={highLighted}
          showDate={showDate}
          onLogRequested={() => {}}
          mapUsersDoubled={0}
          mapVehiclesDoubled={0}
          onOrderRequired={() => {}}
        />
      </tr>)}
    </Draggable>;
  }
}

function DataRow(props: Props) {
  
  const date = useSelector(selectDataRowValue(props.index, props.singleRowEditMode, 'date'));
  const attributeId = useSelector(selectDataRowValue(props.index, props.singleRowEditMode, 'attributeId'));
  const rowId = useSelector(selectDataRowValue(props.index, props.singleRowEditMode, 'id'));

  const authenticate =
    useSelector((state: {authenticate: AuthenticateState}) => state.authenticate);

  const dispatchingRights = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights);

  if((!date || !attributeId) && props.singleRowEditMode) {
    return null
  }

  if(!date)
    return null;
  let {
    attribute,
    singleRowEditMode,
    onLogRequested,
    onAddConnWithInvoice,
    showDate,
    onOrderRequired,
    index } = props;

    const elapsedHoursFromCreation = date ? moment().diff(moment(date, dateFromat), 'hours') : 0;

    return <>
      <DrMenu
        onLogRequested={onLogRequested}
        onAddConnWithInvoice={onAddConnWithInvoice}
        index={index}
        showDate={props.showDate}
        attribute={attribute}
        singleRowEditMode={singleRowEditMode}
        elapsedHoursFromCreation={elapsedHoursFromCreation}
      />
      <DrMarkersView index={index} singleRowEditMode={singleRowEditMode}/>
      <DrNoteAndAttachments index={index} attribute={attribute}
                            singleRowEditMode={singleRowEditMode}/>
      <DrOrder index={index} attribute={attribute} singleRowEditMode={singleRowEditMode}/>
      <DrVehicles index={index} attribute={attribute} singleRowEditMode={singleRowEditMode}/>
      <DrRealVehicle index={index} attribute={attribute} singleRowEditMode={singleRowEditMode}/>
      <DrUser index={index} attribute={attribute}
              singleRowEditMode={singleRowEditMode} showDate={showDate}/>
      <DrAddressBook index={index} attribute={attribute}
                     singleRowEditMode={singleRowEditMode}/>
      <DrTools index={index} attribute={attribute}
               singleRowEditMode={singleRowEditMode}/>
      <DrLoading index={index} attribute={attribute}
                 singleRowEditMode={singleRowEditMode}/>
      <DrUnLoading index={index} attribute={attribute}
                   singleRowEditMode={singleRowEditMode}/>
      <DrCompany index={index} attribute={attribute}
                 singleRowEditMode={singleRowEditMode}/>
      <DrConstruction index={index} attribute={attribute}
                      singleRowEditMode={singleRowEditMode}/>
      <DrContactUser index={index} attribute={attribute}
                     singleRowEditMode={singleRowEditMode}/>
      <DrTimeFields index={index} attribute={attribute}
                     singleRowEditMode={singleRowEditMode}/>
      <DrMaterial index={index} attribute={attribute}
                  singleRowEditMode={singleRowEditMode}/>
      <DrState index={index} attribute={attribute}
               onOrderRequired={onOrderRequired}
               singleRowEditMode={singleRowEditMode}/>
      <DrPrice index={index} attribute={attribute}
               singleRowEditMode={singleRowEditMode}/>
      <CanView visible={!singleRowEditMode && dispatchingRights.delete}>
        <td>
          <button
            disabled={(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod < elapsedHoursFromCreation && !dispatchingRights.delete}
            title={(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod < elapsedHoursFromCreation
              && !dispatchingRights.delete? `Vypršela možnost vymazat řádek, na mazání máte povoleno ${(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod} h` : undefined}
            className='transparentButton' onClick={() => store.dispatch(deleteDataRow(index))}>
            <FontAwesome style={{fontSize:'18px', color: 'grey', }} name='fas fa-times' />
          </button>
        </td>
      </CanView>
    </>;
}

DataRow.defaultProps = {
  unresolvedMode: false,
  singleRowEditMode: false,
  onHighlightRequested: () => {},
  actualHighLightOrder: null,
  actualHighLightUser: null,
  actualHighLightVehicle: null,
  actualHighLightAddressBook: null,
  mapUsersDoubled: {},
  mapVehiclesDoubled: {},
  onAddConnWithInvoice: () => {},
  vehiclesData: [],
  materialAttributes: [],
  storeAttributes: [],

};

export default React.memo(DataRow,(props: Props, nextProps) => {
  return !nextProps.shouldRender;
});