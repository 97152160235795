import {CanView} from "../../Main";
import {IDispatchingAttributesCustom} from "@cml/types";
import Autocomplete from "react-autocomplete";
import React, {ChangeEvent} from "react";
import {Attributes, Roles} from "@cml/models";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getCompanies} from "../../../fetchApi/dispatching";
import store from "../../../redux/store";
import {swrOption} from "../DataRow";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrCompany({ attribute, index, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const { companies } = getCompanies();

  const company = useSelector(selectDataRowValue(index, singleRowEditMode, 'company'));

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      company: e.target.value,
    }))
  }

  const onSelect = (val: string) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      company: val,
    }))
  }

  if (!companies)
    return <div>Loading</div>

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canCompany && dispatchOther.canCompany}>
    <td >
      {
        <div className='autocomplete'>
          <Autocomplete
            getItemValue={(item) => item.company}
            items={companies.filter((o: { company: string; }) => o.company.toLowerCase()
              .indexOf(company.toLowerCase()) > -1)}
            renderItem={(item, isHighlighted) =>
              <div style={{ cursor: 'pointer', padding: '3px', background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.company}
              </div>
            }
            value={company}
            onChange={onChange}
            onSelect={onSelect}
          />
        </div>
      }
    </td>
  </CanView>
}