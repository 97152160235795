import React, { Component } from 'react';
import {ControlLabel, FormGroup, } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import { ResponseError } from 'superagent';
import request from 'axios';
import store from '../../redux/store';
import { show } from 'react-notification-system-redux';
import Col from "react-bootstrap/lib/Col";
import {LoadingOrRender} from "../../loginsrc/Profile";

export interface ImportPhmCardsState {
    importActive: boolean,
}

export interface ImportPhmCardsProps {
    type: number;
}

const PhmImportTypes = {
    uta: 0,
    shell: 1,
    eurowag: 2,
};

export default class ImportPhmCards extends Component<ImportPhmCardsProps, ImportPhmCardsState> {
    private inputRef: React.RefObject<HTMLInputElement>;
    constructor(props: ImportPhmCardsProps) {
        super(props);
        this.state = {
            importActive: false,
        };
        this.inputRef = React.createRef();
    }

    private importUta(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target) {
            if (typeof (e.target as HTMLInputElement).files === 'undefined')
                return;
            if (e.target.files)
                if (e.target.files.length > 0) {
                    this.finishImport(e.target.files[0]);
                }
        }
    }

    private finishImport(file: File) {
        let formData = new FormData();
        const routeTranslation = {
            0: 'uta',
            1: 'shell',
            2: 'eurowag',
        }
        formData.append(file.name, file);
        this.setState({ importActive: true });
        // @ts-ignore
        request.post(`/items/creditCard/import/${routeTranslation[this.props.type]}`, formData)
          .then((res) => {
              this.setState({ importActive: false });
              if (this.inputRef.current)
                  this.inputRef.current.value = '';
              store.dispatch(show({
                  title: 'Info',
                  level: 'info',
                  autoDismiss: 15,
                  children: (
                    <div>
                        Import byl v pořádku proveden
                    </div>
                  ),
              }, 'info'));
          })
          .catch((err: ResponseError) => {
              this.setState({importActive: false});
          })

    }

    render() {
        return <>
            <Col sm={4}>
                <LoadingOrRender requestActive={this.state.importActive}>
                    <FormGroup>
                        <ControlLabel>Soubor pro import</ControlLabel>
                        <div>
                            <input onChange={(e) => this.importUta(e)}
                                   ref={this.inputRef}
                                   id={`new${this.props.type}CardsFile`} type='file'
                                   accept={this.props.type === PhmImportTypes.eurowag ? '.xml' : '.csv,.xlsx'} className='inputfile'/>
                            <label htmlFor={`new${this.props.type}CardsFile`}>
                                <FontAwesome style={{ marginRight: '10px', fontSize: '16px', color: '#055ADA' }} name='fas fa-plus-circle' />
                                {this.props.type !== PhmImportTypes.eurowag ?
                                  `Import karet ${this.props.type === PhmImportTypes.uta ? 'UTA' : 'SHELL'} csv` :
                                  'Import karet Eurowag xml'
                                }
                            </label>
                        </div>
                    </FormGroup>
                </LoadingOrRender>
            </Col>

        </>
    }
}

export {
    PhmImportTypes,
}
