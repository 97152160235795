import request from "axios";
import {dateFromat} from "../../../AppObjects";
import moment, {Moment} from "moment";
import React, { useState } from "react";
import {getVehicles} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import {useSelector, useDispatch} from "react-redux";
import {DataRowsState, setVehiclesData, setVehiclesDataLoading} from "@cml/redux-store";
import {Button} from "react-bootstrap";
import { LoadingOrRender } from "../../../loginsrc/Profile";
import store from "../../../redux/store";

interface Props {
  selectedDate: Moment,
}

export default function GPSLoadButton (props: Props) {

  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { allDetails: 0, onlyForDispatching: 1 }});

  const vehicleUsed = useSelector((state: { dispatching: DataRowsState }) =>
    state.dispatching.vehicleUsed);

  const vehiclesDataLoading = useSelector((state: { dispatching: DataRowsState }) =>
    state.dispatching.vehiclesDataLoading);

  const dispatch = useDispatch();

  const loadGpsData = () => {
    dispatch(setVehiclesDataLoading(true));
    request.get('/items/volvotrackingdata', {
      params: {
        where: {
          vehicleId: vehicles.filter(o => (o.type === 'myvisionlink' || o.type === 'volvo') && vehicleUsed.includes(o.id)).map(o => o.foreignApiVehicleId),
          timestamp: { $between: [props.selectedDate.format(dateFromat), moment(props.selectedDate).add(1, 'days').format(dateFromat)] }
        },
        webVehicleId: vehicles.filter(o => o.type === 'webdispecink' && vehicleUsed.includes(o.id)).map(o => o.foreignApiVehicleId)
      }
    }).then((res) => {
      dispatch(setVehiclesDataLoading(false));
      store.dispatch(setVehiclesData(res.data));
    }).catch(() => {
      dispatch(setVehiclesDataLoading(false))
    })
  }

  return <span>
      <LoadingOrRender requestActive={vehiclesDataLoading}>
        <Button onClick={loadGpsData}
                bsStyle='primary'>
          Načíst GPS data
      </Button>
    </LoadingOrRender>
  </span>;
}