import passwordValidator from "password-validator";
import {HelpBlock} from "react-bootstrap";
import React from "react";
import 'moment/locale/cs';
import momentbuss from 'moment-business-days'; // Important
import moment from 'moment';
import request from 'axios';
import store from "./redux/store";
import { show } from 'react-notification-system-redux';
import { doConnectionLost } from "@cml/redux-store";

let companies = [];
let constructions = [];

const requestTimeout = 20000;

const buildNumber = '%develop%';

const googleMapsUrl = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAAK9Twcbw5ZlYtRQxbAeZzRBpdwsBNyoE&libraries=places';

function generateValueIfIsNotForRanges(value, rangeList, defValue) {
  if (!Array.isArray(value))
    return rangeList.map(o => defValue ? o.defaultValue : value);
  else return value;
}

export const mapAuthStateToProps = state => {
  return {
    authenticate: state.authenticate
  }
};

function markersLegendTable() {
  return <table>
    <tbody>
    <tr>
      <td><div className='circle' style={{ backgroundColor: 'brown' }}></div></td>
      <td className='legendFont'>Objednávka</td>
      <td><div className='circle' style={{ backgroundColor: 'darkblue' }}></div></td>
      <td className='legendFont'>Přijatá faktura</td>
    </tr>
    <tr>
      <td><div className='circle' style={{ backgroundColor: 'purple' }}></div></td>
      <td className='legendFont'>Vydaná faktura</td>
      <td><div className='circle' style={{ backgroundColor: 'darkgreen' }}></div></td>
      <td className='legendFont'>Mzda</td>
    </tr>
    </tbody>
  </table>
}

const twoDigits = (num) => {
  return num > 9 ? num : '0' + num;
};

const getFloatTime = (text) => {
  return parseFloat(text.substring(0,2)) + parseFloat(text.substring(3)) / 60;
};

const getTimeString = (number) => {
  let num = Math.abs(number);
  return `${number < 0 ? '-' : ''}${twoDigits(Math.floor(num))}:${twoDigits(Math.round((num % 1) * 60))}`
};

const roundFloatTwoDigit = (number) => {
  if (typeof number === 'string')
    number = parseFloat(number);
  return Math.round((number + Number.EPSILON) * 100) / 100;
};

function getRangeIndex(value, rangeList) {
  if (typeof value !== 'number')
    value = parseInt(value, 10);
  let index = -1;
  rangeList.map((range, i) => {
    const from = typeof range.from !== 'number' ? parseInt(range.from, 10) : range.from;
    const to = typeof range.to !== 'number' ? parseInt(range.to, 10) : range.to;
    if (value >= from && value <= to || value > to) {
      index = i;
    }
  });
  return index;
}

function getCompanies() {
  request.get('/items/distinctCompanies')
    .then((res) => {
      if(res.status === 200) {
        companies = res.data;
      }
    })
}

function getConstructions() {
  request.get('/items/distinctConstructions')
      .then((res) => {
        if(res.status === 200) {
          constructions = res.data;
        }
      })
}

function getMonthFonds(year) {
  const fonds = [];
  ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    .forEach(i => {
      const m = moment(`${year}-${i}-01`, dateFromat);
      const diff = m
        .businessDiff(m.clone().endOf('month'));
      fonds.push(diff * 8);
    })

  return fonds;
}

function setPublicHolidays() {
  request.get('/items/publicHolidays')
    .then((res) => {
      if(res.status === 200) {
        moment.locale('cs', {
          holidays: res.data.map(o => o.date),
          holidayFormat: dateFromat,
          workingWeekdays: [1,2,3,4,5],
        });
      }
    })
}

function getBaseUrl() {
  const baseUrl = window.location;
  const port = baseUrl.host.split(':')[1];
  return baseUrl.protocol + "//" + (port === '3000' ? baseUrl.host.split(':')[0] + ':8080' : baseUrl.host)
}

function passwordChecker (pass) {
  // Validate lowercase letters
  let errorList = [];
  if(! new passwordValidator().has().lowercase().validate(pass)) {
    errorList.push('Heslo musí obsahovat malé písmeno');
  }

  // Validate capital letters
  if(!new passwordValidator().has().uppercase().validate(pass)) {
    errorList.push('Heslo musí obsahovat velké písmeno');
  }

  // Validate numbers
  if(!new passwordValidator().has().digits().validate(pass)) {
    errorList.push('Heslo musí obsahovat číslici');
  }

  // Validate no spaces
  if(!new passwordValidator().has().not().spaces().validate(pass)) {
    errorList.push('Heslo obsahuje mezeru !');
  }

  // Validate length
  if(pass.length < 8) {
    errorList.push('Heslo musí mít alespoň 8 znaků');
  }

  return {
    messages: errorList.length > 0 ? <HelpBlock><ul>
      {
        Object.keys(errorList).map(function (key) {
          return <li key={key}>{errorList[key]}</li>
        })
      }
    </ul></HelpBlock> : null,
    error: errorList.length > 0,
  }
}

function getFilterList(list, full) {
  let ids = [];
  if (!list[0].filtered ){
    Object.keys(list).map((keyInter) => {
      if (list[keyInter].filtered && keyInter !== '0')
        ids.push(full ? list[keyInter] : list[keyInter].id)
    });

    if (ids.length === 0 && !full)
      ids.push(0);
  }
  return ids;
}

const HeaderNames = {
  roles: 'Projektové role',
  users: 'Kartotéka',
  'attributes/attendance': 'Atributy docházky',
  'attributes/dispatching': 'Atributy dispečinku',
  'attributes/price': 'Atribut ceny dispečinku',
  'attributes/vehicles': 'Skupiny vozidel',
  'attributes/material': 'Materiál',
  'attributes/store': 'Místa odběru',
  'attributes/invoice-activity': 'Fakturační činnost',
  attendance: 'Docházka',
  vehicles: 'stroje',
  tools: 'Vybavení',
  tasks: 'Úkoly',
  sms: 'Sms zprávy',
  spaces: 'Prostory',
  addressbook: 'Adresář',
  logs: 'Log změn',
  orders: 'Zakázky',
  'diagrams/tasks': 'Diagramy úkolů',
  salary: 'Mzdy',
  creditCard: 'Platební karty',
};

const AddButtonNames = {
  roles: 'Nová role',
  users: 'Nový zaměstnanec',
  'attributes/attendance': 'Nový atribut',
  'attributes/dispatching': 'Nový atribut',
  'attributes/price': 'Nový atribut',
  'attributes/vehicles': 'Nová skupina',
  'attributes/material': 'Nová položka',
  'attributes/store': 'Nová položka',
  'attributes/invoice-activity': 'Nová činnost',
  attendance: 'Nový záznam',
  vehicles: 'Nový stroj',
  tools: 'Nové vybavení',
  tasks: 'Nový úkol',
  sms: 'Nová sms',
  spaces: 'Nový prostor',
  addressbook: 'Nový záznam',
  orders: 'Nová zakázka',
  'diagrams/tasks': 'Nový diagram',
};

const Error403 = {
  roles: 'Role je označena jako admin, nelze vymazat, musí existovat !',
  users: 'Operace zamítnuta, nemáte právo',
  vehicles: 'Nelze manipulovat s vozidlem, protože je načteno z externího API !',
};

const dateFromat = 'YYYY-MM-DD';
const dateFormatVisible = 'DD.MM.YYYY';

export {
  getFilterList,
  passwordChecker,
  HeaderNames,
  AddButtonNames,
  Error403,
  dateFromat,
  dateFormatVisible,
  companies,
  googleMapsUrl,
  buildNumber,
  getCompanies,
  constructions,
  getConstructions,
  getBaseUrl,
  getRangeIndex,
  generateValueIfIsNotForRanges,
  markersLegendTable,
  requestTimeout,
  setPublicHolidays,
  twoDigits,
  getTimeString,
  getFloatTime,
  roundFloatTwoDigit,
  getMonthFonds
}
