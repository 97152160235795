import React, { Component } from 'react';
import {Checkbox, Col, DropdownButton, FormControl, Row} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import ItemsSelector from "../ItemsSelector";
import {mapAuthStateToProps} from "../../AppObjects";
import { connect } from "react-redux";

class UserFiltr extends Component{

  constructor(props) {
    super(props);
    this.state = {
      selectedRoles: [],
      selectedSpaces: [],
      filterValue: '',
    }
  }

  render() {
    const {selectedRoles, selectedSpaces, filterValue} = this.state;
    const { authenticate } = this.props;
    return (<div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll'>
      <Row>
        <Col sm={1}>
          <div className='text-right'>
            <FontAwesome style={{fontSize:'18px', marginTop: '8px'}} name='fas fa-search' />
          </div>
        </Col>
        <Col sm={3}>
          <FormControl
            value={filterValue}
            onChange={(e) => {
              this.setState({filterValue: e.target.value});
              this.doFiltr(selectedSpaces, selectedRoles, e.target.value);
            }}
            type='text'/>
        </Col>
        <Col sm={2} smOffset={1}>
          <ItemsSelector value={'name'}
                         overrideToggle={'Filtr role'}
                         listChanged={(list, isFromInit) => {
                           this.setState({selectedRoles: list});
                           if (!isFromInit)
                              this.doFiltr(selectedSpaces, list, filterValue);
                         }}
                         appendItemsToStart={[
                           {name: 'Všechny', id: 0}
                         ]}
                         isFilterable
                         itemsToFetch={'roles'}/>
        </Col>
        {
          authenticate.actualUserRole.isAdmin ? <Col sm={2}>
            <ItemsSelector value={'name'}
                           overrideToggle={'Filtr prostorů'}
                           listChanged={(list, isFromInit) => {
                             this.setState({selectedSpaces: list});
                             if (!isFromInit)
                               this.doFiltr(list, selectedRoles, filterValue);
                           }}
                           appendItemsToStart={[
                             {name: 'Všechny', id: 0}
                           ]}
                           isFilterable
                           itemsToFetch={'spaces'}/>
          </Col> : null
        }

      </Row>
    </div>)
  }

  doFiltr(spaces, roles, value) {
    this.props.onFilter({
      spaceId: spaces,
      roleId: roles,
      where: {
        $and: [
          { $or: [
              { name: { $like: `%${value}%` }},
              { surname: { $like: `%${value}%` }},
            ] }
        ]
      }
    })
  }
}

export default connect(mapAuthStateToProps)(UserFiltr);