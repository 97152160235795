import React, {Component, FormEvent, ReactNode, useEffect, useReducer, useRef, useState} from "react";
import {ISalary, SalaryPreview, IDiet} from "../users/Salary";
import moment, {Moment} from "moment";
import * as _ from 'lodash';
import {
  dateFormatVisible,
  dateFromat,
  getFloatTime,
  getTimeString,
  mapAuthStateToProps,
  roundFloatTwoDigit,
  getMonthFonds
} from "../../AppObjects";
import store from "../../redux/store";
import {Button, Checkbox, Dropdown, FormControl, MenuItem} from "react-bootstrap";
// @ts-ignore
import TimeField from "react-simple-timefield";
import { SalaryStrategy } from "../orders/Edit";
import request from 'axios';
import {connect, useSelector} from "react-redux";
import { AuthenticateState } from "@cml/redux-store";
import {show} from "react-notification-system-redux";
import SalaryHeader from "./salaryComponents/SalaryHeader";
import SalaryTotals from "./salaryComponents/SalaryTotals";
import SalaryNoWorking from "./salaryComponents/SalaryNoWorking";
import SalaryCustomItems from "./salaryComponents/SalaryCustomItems";
import MonthAttendance from "./salaryComponents/MonthAttendance";
import {reducer, SalaryStateContext, SET_BASE_SALARY_STATE, setBaseState} from "./reducer";
import {useContextSelector} from "use-context-selector";
import {IAttendanceAttributesCustom, IDispatchingAttributesCustom} from "@cml/types";
import {DataRow, EDataRow} from "@cml/models";

interface Props {
  user: {
    id: string | number;
    spaces: { salary: ISalary, spaceId: number }[];
    creditCards: { number: string; id: number, isForPrivateUse: boolean }[];
  };
  dataRows: EDataRow[];
  startDate: Moment;
  endDate: Moment;
  authenticate: AuthenticateState;
  salaryCreated: (id: number) => void;
  rowsAttendanceChanged: (rows: EDataRow[]) => void;
}

export interface IFinalTable {
  rows: {
      name: string
      strategy: number,
      bdrCount: number,
      wrCount: number,
      hrCount: number,
      standard: number,
      extra: number,
      totalHours: number,
      holidayHours: number,
      salaryS: number,
      salaryH: number,
      salaryW: number,
      profit: number,
      profitSalary: number,
      travelTime: number,
      travelFixedPrice: number,
      dietsComplete: object[],
      ownDietBonus: number,
      ownBonusTotal: number,
      finSalary: number,
  }[],
  diets?: IDiet[] | null,
  salary?: ISalary | null,
  noWorkHolidayBonus: string[],
  totalHoursStandard: number,
  totalHoursExtra: number,
  replacedHours: number,
  totalSavedOvertimeHours: number,
  totalHolidayHours: number,
  totalDiets: number,
  totalSalary: number,
  workdaysRowsLength: number,
  weekendRowsLength: number,
  holidaysRowsLength: number,
  fond: number | null;
  totalGpsHours: number;
}

const getUniqueIden = (row: EDataRow) => {
  if (row.order) {
    return row.order.label;
  }
  if (row.construction !== '')
    return row.construction;
  if (row.company !== '')
    return row.company;
  if (row.loading !== '' && row.unloading !== '')
    return row.loading + ' <-> ' + row.unloading;
  if (row.attribute)
    return row.attribute.name;
  return 'unknown';
}

const SalaryStateProvider = ({ children }: { children: ReactNode}) => {
  const [state, dispatch] = useReducer(reducer, {
    overriderFinalSalary: null,
    dietUnknownInfo: null,
    customItems: [],
    fond: null,
    attendanceError: false,
    attendanceChanged: false,
    totalExtraHours: 0,
    totalSavedOvertimeHours: 0,
    totalGpsHours: 0,
    rowExistsButGpsNot: false,
  })

  return <SalaryStateContext.Provider value={{ state, dispatch }}>
    {children}
  </SalaryStateContext.Provider>
}

export function SalaryResult ({ startDate,
                         dataRows,
                         user, salaryCreated,
                         rowsAttendanceChanged,
                         endDate }: Props) {

  const multiRowDays = useRef<{
    [key: string]: boolean,
  }>({})

  const onlyEnabledRows = dataRows.filter(d => (d.isAttendance || (d.attribute && !(d.attribute.customOptions as
    IDispatchingAttributesCustom).disableUsageInSalary)));

  const authenticate = useSelector(
    (state: { authenticate: AuthenticateState}) => state.authenticate);

  const dispatch = useContextSelector(SalaryStateContext, v => v.dispatch);

  const customItems = useContextSelector(SalaryStateContext, v => v.state.customItems);
  const dietUnknownInfo = useContextSelector(SalaryStateContext, v => v.state.dietUnknownInfo);
  const overriderFinalSalary = useContextSelector(SalaryStateContext, v => v.state.overriderFinalSalary);
  const fond = useContextSelector(SalaryStateContext, v => v.state.fond);
  const attendanceError = useContextSelector(SalaryStateContext, v => v.state.attendanceError);
  const attendanceChanged = useContextSelector(SalaryStateContext, v => v.state.attendanceChanged);
  const totalSavedOvertimeHours = useContextSelector(SalaryStateContext, v => v.state.totalSavedOvertimeHours);
  const totalExtraHours = useContextSelector(SalaryStateContext, v => v.state.totalExtraHours);
  const totalGpsHours = useContextSelector(SalaryStateContext, v => v.state.totalGpsHours);
  const rowExistsButGpsNot = useContextSelector(SalaryStateContext, v => v.state.rowExistsButGpsNot);

  const finalTable = useRef<IFinalTable>({
    diets: [],
    rows: [],
    totalHoursStandard: 0,
    totalHoursExtra: 0,
    totalHolidayHours: 0,
    replacedHours: 0,
    totalDiets: 0,
    totalSalary: 0,
    holidaysRowsLength: 0,
    weekendRowsLength: 0,
    totalSavedOvertimeHours: 0,
    workdaysRowsLength: 0,
    fond: null,
    noWorkHolidayBonus: [],
    totalGpsHours: 0,
  });

  const diets = useRef<IDiet[]>([]);

  useEffect(() => {
    const actSpace = (store.getState().authenticate as AuthenticateState).activeSpace;
    diets.current = [];
    if(actSpace) {
      if ((actSpace.setting as {diets: IDiet[]}).diets)
        diets.current = (actSpace.setting as {diets: IDiet[]}).diets
    }
    const fond = getFond();

    dispatch(setBaseState({ fond }));

    request.get('/items/items', {
      params: {
        where: {
          $or: [
            {
              itemId: user.id,
              itemTableName: 'users',
            },
            {
              itemId: user.creditCards.map(o => o.id),
              itemTableName: 'creditCard',
            }
          ],
          date: startDate !== null && endDate !== null ? {
            $between: [startDate.format(dateFromat),
              moment(endDate).add(1, 'days').format(dateFromat)],
          } : undefined,
        }
      }
    })
      .then(res => {
        const sumPrivate = res.data.reduce((acc: number, o: { cardNumber: string; amount: number; unitPrice: number; }) => {
          const card = user.creditCards.find(c => c.number === o.cardNumber)
          if (card) {
            if (card.isForPrivateUse)
              return acc + o.amount * o.unitPrice;
          }
          return acc;
        }, 0);
        const custom = customItems.slice();
        custom.push({name: 'Osobní tankování', price: -sumPrivate, fixed: true});
        dispatch(setBaseState({ customItems: custom, }))
      })
  }, [])

  const [fondFromPreviousPeriods, setFondFromPreviousPeriods] =
    useState<any | null>(null);


  // @ts-ignore
  const foreignDriver = user ? user.foreignApiDriverId.find(o => o.type === 'webDisApi') : undefined;

  const fondFromPreviousPeriodsTotal = _.reduce(fondFromPreviousPeriods, (acc, item) =>
    acc += item[user.id] ? item[user.id].saved : 0, 0)

  useEffect(() => {
    request.get('/items/salaryOvertimes', {
      params: {
        where: {userId: user.id}
      }
    }).then(res => {
      setFondFromPreviousPeriods(res.data);
      startDate.format('YYYY')
    });
  }, []);

  useEffect(() => {
    if (totalGpsHours !== 0) {
      const fondMissing = totalGpsHours - (finalTable.current.fond as number);
      dispatch(setBaseState({totalExtraHours: fondMissing + fondFromPreviousPeriodsTotal}))
    } else {
      const fondMissing = finalTable.current.totalHoursStandard - (finalTable.current.fond as number);
      dispatch(setBaseState({totalExtraHours: finalTable.current.totalHoursExtra + fondMissing + fondFromPreviousPeriodsTotal}))
    }

  }, [finalTable.current.totalHoursExtra, fondFromPreviousPeriodsTotal ,totalGpsHours]);

  const weekendBonusPaid = useRef<{[key: string]: boolean}>({});
  weekendBonusPaid.current = {};


  const getFond = () => {
    const dateStart = startDate.clone();
    const dateEnd = endDate.clone();
    const timeValues = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push(parseInt(dateStart.format('MM')));
      dateStart.add(1,'month');
    }
    const fonds = getMonthFonds(startDate.year());
    if (timeValues.length !== 1)
      return null;
    else
      return fonds[timeValues[0] - 1];
  }

  const getDaysDescription = () => {
    const sId = (store.getState().authenticate as AuthenticateState).activeSpaceId;
    const space = user.spaces.find(o => o.spaceId === sId);
    let salary = null;
    if (space)
      salary = space.salary;
    const sd = moment(startDate.format(dateFromat));
    const noWorkHolidayBonus: string[] = [];
    do {
      if (sd.isHoliday() && sd.isoWeekday() !== 6 && sd.isoWeekday() !== 7)
        noWorkHolidayBonus.push(sd.format(dateFromat));
      sd.add(1, 'days');
    } while (sd.isBefore(endDate))
    const workDaysRows: EDataRow[] = [];
    const holidaysRows: EDataRow[] = [];
    const weekendsRows: EDataRow[] = [];
    const dietUnknownInfo_: {
      [key: string]: {
        canUseTravelTime: boolean;
        travelTime: number;
        travelFixedPrice: number;
      }
    } = {};
    let shouldAssignTop = false;

    const overriderFinalSalary_:
      {[key: string]: {
        enabled: boolean,
          salary: number,
          salaryStrategy: number,
          canChangeSalaryStrategy: boolean
      }} = {}
    const constructions = _.unionBy(onlyEnabledRows, r => getUniqueIden(r)).map(o => {
      let salaryInfo = {};
      if (o.order) {
        salaryInfo = {
          canUseTravelTime: o.order.canUseTravelTime,
          travelTime: o.order.canUseTravelTime ? o.order.travelTime : 0,
          travelFixedPrice: o.order.canUseTravelTime ? o.order.travelFixedPrice : 0,
        }
      }
      let shouldAssign = false;
      if (dietUnknownInfo === null) {
        shouldAssign = true;
      } else {
        if (!dietUnknownInfo[getUniqueIden(o)])
          shouldAssign = true
      }
      if (shouldAssign) {
        shouldAssignTop = true;
        dietUnknownInfo_[getUniqueIden(o)] = {
          canUseTravelTime: false,
          travelTime: 0,
          travelFixedPrice: 0,
          ...salaryInfo
        }
      }
      shouldAssign = false;
      if (overriderFinalSalary === null) {
        shouldAssign = true;
      } else {
        if (!overriderFinalSalary[getUniqueIden(o)])
          shouldAssign = true
      }
      if (shouldAssign) {
        shouldAssignTop = true;
        overriderFinalSalary_[getUniqueIden(o)] = {
          enabled: false,
          salary: 0,
          salaryStrategy: o.order ? o.order.salaryStrategy : (o.isAttendance ? 2 : 0),
          canChangeSalaryStrategy: o.order ? !o.order.salaryStrategy : true,
        };
      }
      let isPaid = o.isAttendance ? (o.attribute.customOptions as IAttendanceAttributesCustom).paidAttendance : true
      return {
        isPaid,
        isAttendance: o.isAttendance,
        name: getUniqueIden(o),
        canUseTravelTime: false,
        travelTime: 0,
        travelFixedPrice: 0,
        ...salaryInfo
      };
    }).sort((c1, c2) => (c1.isAttendance ? 0 : 1) - (c2.isAttendance ? 0 : 1));
    if (shouldAssignTop) {
      dispatch(setBaseState({
        dietUnknownInfo: {
          ...dietUnknownInfo,
          ...dietUnknownInfo_,
        },
        overriderFinalSalary: {
          ...overriderFinalSalary,
          ...overriderFinalSalary_,
        }
      }))
    }

    let replacedHours = 0;
    onlyEnabledRows.forEach(row => {
      const m = moment(row.date);
      if (m.isBusinessDay()) {
        workDaysRows.push(row);
      } else if (m.isHoliday()) {
        holidaysRows.push(row);
      } else {
        weekendsRows.push(row);
      }
      const index = noWorkHolidayBonus.findIndex(o => o === row.date);
      if (index > -1 && !row.isAttendance)
        noWorkHolidayBonus.splice(index, 1);
      if (row.isAttendance) {
        if ((row.attribute.customOptions as IAttendanceAttributesCustom).overtimeAttribute) {
          replacedHours += 8;
        }
      }
    })
    return {
      replacedHours,
      shouldAssignTop,
      noWorkHolidayBonus,
      constructions,
      workDaysRows,
      holidaysRows,
      weekendsRows,
      salary,
    }
  }

  const getHours = (rows: EDataRow[], c: {
    canUseTravelTime: boolean;
    travelTime: number;
    name: string;
  }, salary: ISalary | null) => {
    if (!dietUnknownInfo)
      return {
        standard: 0,
        extra: 0,
      }
    let realC = c.canUseTravelTime ? c : dietUnknownInfo[c.name];
    let standard = 0;
    let extra = 0;
    let dietsSalary = new Array(diets.current.length);
    for(let i = 0; i < dietsSalary.length; i++) {
      dietsSalary[i] = 0;
    }

    rows.forEach(r => {
      let amount = r.to - r.from - r.break;
      if (amount < 0) amount += 24;
      const multiRows = onlyEnabledRows.filter(o => o.date === r.date/* && getUniqueIden(o) === c.name*/);
      if (salary && realC.canUseTravelTime) {
        let total = amount + realC.travelTime + r.break;
        if (total > 18)
          total = 19;
        if (multiRows.length === 1) {
          const index = diets.current.findIndex(o => o.from <= total && o.to > total);
          if (index > -1) {
            dietsSalary[index] += diets.current[index].price;
          }
          standard += amount > 8 ? 8 : amount;
          extra += amount > 8 ? (amount - 8) : 0;
        } else if (multiRows.length > 1) {
          let multiAmount = 0;
          let maxDietHour = 0;
          let breaks = 0;
          let amountForRow = 0;
          multiRows.forEach(mR => {
            let realCMulti = dietUnknownInfo[getUniqueIden(mR)];
            if (mR.order) {
              if(mR.order.canUseTravelTime) {
                realCMulti = {
                  travelTime: mR.order.travelTime,
                  canUseTravelTime: mR.order.canUseTravelTime,
                  travelFixedPrice: mR.order.travelFixedPrice
                }
              }
            }
            if (realCMulti.canUseTravelTime && realCMulti.travelTime > maxDietHour) {
              maxDietHour = realCMulti.travelTime;
            }
            let a = mR.to - mR.from - mR.break;
            if (a < 0) a += 24;
            multiAmount += a;
            breaks += mR.break;
            if (mR.id === r.id) {
              amountForRow = a;
            }
          })
          standard += amountForRow > 8 ? 8 : amountForRow;
          extra += amountForRow > 8 ? (amountForRow - 8) : 0;
          let multiTotal = multiAmount + maxDietHour + breaks;
          if (multiTotal > 18)
            multiTotal = 19;
          const index = diets.current.findIndex(o => o.from <= multiTotal && o.to > multiTotal);
          // add diets only for one day
          if (index > -1 && !multiRowDays.current[r.date]) {
            multiRowDays.current[r.date] = true;
            dietsSalary[index] += diets.current[index].price;
          }
        }
      } else {
        if (multiRows.length === 1) {
          standard += amount > 8 ? 8 : amount;
          extra += amount > 8 ? (amount - 8) : 0;
        } else if(multiRows.length > 1) {
          let multiAmount = 0;
          let amountForRow = 0;
          multiRows.forEach(mR => {
            let a = mR.to - mR.from - mR.break;
            if (a < 0) a += 24;
            multiAmount += a;
            if (mR.id === r.id) {
              amountForRow = a;
            }
          })
          standard += amountForRow > 8 ? 8 : amountForRow;
          extra += amountForRow > 8 ? (amountForRow - 8) : 0;
        }
      }
    })
    return {
      standard,
      extra,
      dietsSalary,
    }
  }

  const addCustomItem = () => {
    const list = customItems.slice();
    list.push({
      name: '',
      price: 0,
      fixed: false,
    })
    dispatch(setBaseState({ customItems: list }));
  }

  const deleteCustomItem = (index: number) => {
    const list = customItems.slice();
    list.splice(index,1);
    dispatch(setBaseState({ customItems: list }));
  }

  const getProfitSum = (rows: EDataRow[]) => {
    let priceSum = 0;
    rows.forEach((row) => {
      if (!row.isAttendance && row.calculation) {
        priceSum += row.calculation.priceEq.reduce((acc, p) =>
          acc += (!p.isMaterial ? p.total : 0), 0);
      }
    });
    return priceSum;
  }

  const saveSalary = () => {
    request.post('/items/salary', {
      startDate: startDate.utc().format(dateFromat),
      endDate: endDate.utc().format(dateFromat),
      userId: user.id,
      customItems,
      dietUnknownInfo,
      overriderFinalSalary,
      dataRowsId: onlyEnabledRows.filter(o => !o.isAttendance).map(o => o.id),
      items: finalTable.current,
    }).then((res) => {
      salaryCreated(res.data.id);
      store.dispatch(show({
        title: 'Info',
        level: 'success',
        autoDismiss: 5,
        children: (
          <div>
            {`Mzda uložena`}
          </div>
        ),
      }, 'success'));
    });
  }

  // @ts-ignore
  if (!authenticate.actualUserRole.salary.view) {
    return null;
  }
  if (!fond) {
    return <div>
      Datumový rozsah není jeden měsíc, nelze počítat výplatu
    </div>
  }



  multiRowDays.current = {};
  const { constructions, workDaysRows, holidaysRows, shouldAssignTop, replacedHours,
    weekendsRows, noWorkHolidayBonus, salary } = getDaysDescription();

  if (dietUnknownInfo === null || overriderFinalSalary === null || shouldAssignTop)
    return <div className='dataTable'>
      <MonthAttendance
        user={user}
        rowsAttendanceChanged={rowsAttendanceChanged}
        dataRows={dataRows}
        startDate={startDate}
        endDate={endDate}/>
    </div>;

  const availableFond = fondFromPreviousPeriodsTotal +
    /*((totalGpsHours - fond) >= 0 ? (totalGpsHours - fond) : 0 )*/  - replacedHours;
  const neededHoursFromFond = ((totalGpsHours - fond) >= 0 ? (totalGpsHours - fond) : 0 )  - replacedHours;

  finalTable.current.workdaysRowsLength = _.uniqBy(workDaysRows, 'date').filter(o => !o.isAttendance).length;
  finalTable.current.weekendRowsLength = _.uniqBy(weekendsRows, 'date').filter(o => !o.isAttendance).length;
  finalTable.current.holidaysRowsLength = _.uniqBy(holidaysRows, 'date').filter(o => !o.isAttendance).length

  finalTable.current.diets = diets.current;
  finalTable.current.salary = salary;
  finalTable.current.noWorkHolidayBonus = noWorkHolidayBonus;
  finalTable.current.rows = [];
  finalTable.current.totalSavedOvertimeHours = totalSavedOvertimeHours
    + (neededHoursFromFond < 0 ? neededHoursFromFond : 0);
  finalTable.current.totalHoursStandard = 0;
  finalTable.current.totalHoursExtra = 0;
  finalTable.current.totalHolidayHours = 0;
  finalTable.current.totalDiets = 0;
  finalTable.current.replacedHours = replacedHours;
  finalTable.current.fond = fond;
  finalTable.current.totalGpsHours = totalGpsHours;
  finalTable.current.totalSalary = salary ? (noWorkHolidayBonus.length * 8 * salary.pricePerHour) : 0;

  const strategyCheck = constructions.filter(o => overriderFinalSalary[o.name].salaryStrategy === 0).length > 0;
  const salaryCheck = onlyEnabledRows.filter(o => o.salaryId).length > 0;
  const noRowButGpsCheck = onlyEnabledRows.filter(o => o.id < 0).length > 0;
  const stateCheck = onlyEnabledRows.filter(o => o.isConfirmed !== 3 && !o.isAttendance).length > 0;
  const timeOverlapsCheck = onlyEnabledRows.filter(o => o.isTimeOverlaps).length > 0;
  const dietCheck =  constructions.filter(c => !dietUnknownInfo[c.name].canUseTravelTime && !c.canUseTravelTime && !c.isAttendance).length > 0;




  const replacedCustom = [{
    fixed: true,
    name: 'Srážka za náhradní volno',
    price: -replacedHours *
      (salary ? (salary.pricePerHour) : 0)},
    {
      fixed: true,
      name: 'Fond náhradního volna',
      price: -totalSavedOvertimeHours *
        (salary ? (salary.pricePerHour) : 0)},
  ]

  return <>
    {fondFromPreviousPeriods && <div style={{display: 'inline-block'}}>
      <div>{Object.keys(fondFromPreviousPeriods).map(key =>
        <div key={key}>
          <h6 style={{ fontWeight: 'bold' }}>Fond náhradního volna v roce {key}</h6>
          <h6>{fondFromPreviousPeriods[key][user.id].saved}</h6>
        </div>
      )}</div>
    </div>}
    {(salary && foreignDriver) ? <div style={{display: 'inline-block'}}>
      <SalaryPreview salary={salary} onSalaryChange={() => {}}/>
    </div> :  (<div>
      {!foreignDriver && <h4>Strojník nemá vazbu na webdispečink</h4>}
      {!salary && <h4>Strojník nemá vyplněnou mzdu, upravte strojníka v kartotéce</h4>}
    </div>) }
    <div className='dataTable'>
      <MonthAttendance
        user={user}
        rowsAttendanceChanged={rowsAttendanceChanged}
        dataRows={dataRows}
        startDate={startDate}
        endDate={endDate}/>
    </div>
    <div className='dataTable'>
      {/* @ts-ignore */ }
      <table align='right'>
        <tbody>
        {SalaryHeader({
          diets: diets.current,
          salary,
          onHeaderClick: addCustomItem,
          plusEnabled: true
        })}
        {noWorkHolidayBonus.length > 0 && SalaryNoWorking({
          diets: diets.current,
          salary,
          noWorkHolidayBonus
        }) }
        {
          constructions.map((c,index) => {

            const bdr = workDaysRows.filter(o => getUniqueIden(o) === c.name)
            const bdrHours = getHours(bdr, c, salary);
            const hr = holidaysRows.filter(o => getUniqueIden(o) === c.name);
            const hrHours = getHours(hr, c, salary);
            const wr = weekendsRows.filter(o => getUniqueIden(o) === c.name);
            const wrHours = getHours(wr, c, salary);
            const standard = bdrHours.standard + (!c.isAttendance ? (hrHours.standard + wrHours.standard) : 0);
            if (!c.isAttendance)
              finalTable.current.totalHoursStandard += standard;
            const extra = bdrHours.extra + hrHours.extra + wrHours.extra;
            if (!c.isAttendance) {
              finalTable.current.totalHoursExtra += extra;
              finalTable.current.totalHolidayHours += hrHours.standard + hrHours.extra;
            }
            const profit = getProfitSum(bdr) + getProfitSum(hr) + getProfitSum(wr);
            const profitSalary = roundFloatTwoDigit(salary ? (salary.invoicePercentage * profit / 100) : 0);

            const bdrCount = _.uniqBy(bdr, 'date').length;
            const hrCount = _.uniqBy(hr, 'date').length;
            const wrCount = _.uniqBy(wr.filter(r => !weekendBonusPaid.current[r.date]), 'date').length;

            wr.forEach(r => {
              weekendBonusPaid.current[r.date] = true;
            })

            const dietsComplete = salary ? new Array(diets.current.length) : [];
            const realC = c.canUseTravelTime ? c : dietUnknownInfo[c.name];
            for(let i = 0; i < dietsComplete.length; i++) {
              // @ts-ignore
              dietsComplete[i] = bdrHours.dietsSalary[i] + wrHours.dietsSalary[i] + hrHours.dietsSalary[i];
            }
            const ownBonusTotal =  realC.travelFixedPrice && realC.canUseTravelTime ?
              (realC.travelFixedPrice * (bdrCount + hrCount + wrCount)) : 0
            const thisDiets = realC.travelFixedPrice && realC.canUseTravelTime ?
              (realC.travelFixedPrice * (bdrCount + hrCount + wrCount)) : dietsComplete.reduce((acc, o) => acc += o, 0);
            finalTable.current.totalDiets += thisDiets
            const totalDietsByLaw = dietsComplete.reduce((acc, o) => acc += o, 0);
            const salaryS = salary ? salary.pricePerHour * (standard + extra) : 0;
            const salaryH = !c.isAttendance ? (salary ? salary.pricePerHour * (hrHours.standard + hrHours.extra) : 0) : 0;
            const salaryW = !c.isAttendance ? (salary ? salary.weekendBonus * wrCount : 0) : 0;
            let finSalary = overriderFinalSalary[c.name].enabled ? overriderFinalSalary[c.name].salary : salaryH + salaryS + salaryW + thisDiets + profitSalary;
            if (overriderFinalSalary[c.name].salaryStrategy !== 2)
              finSalary -= salaryS;
            if (overriderFinalSalary[c.name].salaryStrategy !== 1)
              finSalary -= profitSalary;
            if (!c.isPaid) {
              finSalary = 0;
            }
            const dietBonus = ownBonusTotal - totalDietsByLaw;
            finalTable.current.totalSalary += finSalary;

            const toSave = {
              name: c.name,
              // @ts-ignore
              strategy: overriderFinalSalary[c.name].salaryStrategy,
              bdrCount,
              wrCount,
              hrCount,
              standard,
              extra,
              totalHours: extra + standard,
              holidayHours: hrHours.standard + hrHours.extra,
              salaryS,
              salaryH,
              salaryW,
              profit,
              profitSalary,
              travelTime: realC.travelTime,
              travelFixedPrice: realC.travelFixedPrice,
              dietsComplete,
              ownDietBonus: realC.travelFixedPrice && realC.canUseTravelTime ?
                (realC.travelFixedPrice  * (bdrCount + hrCount + wrCount)) : 0,
              ownBonusTotal: ownBonusTotal === 0 ? 0 :dietBonus,
              finSalary,
            }

            finalTable.current.rows.push(toSave);


            return <tr key={index}>
              <td style={{ maxWidth: '200px', whiteSpace: 'unset', wordBreak: 'break-word'}}>
                {c.isAttendance ? <Checkbox checked={overriderFinalSalary[c.name].enabled}
                                            style={{ display: 'inline-block' }}
                                            title='Přepsat výslednou mzdu za tuto položku'
                                            onChange={(e) => {
                                              overriderFinalSalary[c.name].enabled = (e.target as HTMLInputElement).checked
                                              dispatch(setBaseState({ overriderFinalSalary }))
                                            }}>{c.name}</Checkbox> : c.name }

              </td>
              <td>
                {overriderFinalSalary[c.name].canChangeSalaryStrategy ? <Dropdown id='SalaryDropdown' onSelect={(e: any) => {
                    overriderFinalSalary[c.name].salaryStrategy = e;
                    dispatch(setBaseState({ overriderFinalSalary }))
                  }}>
                    <Dropdown.Toggle>
                      {
                        // @ts-ignore
                        SalaryStrategy[overriderFinalSalary[c.name].salaryStrategy]}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <MenuItem eventKey={0}>{SalaryStrategy[0]}</MenuItem>
                      <MenuItem eventKey={1}>{SalaryStrategy[1]}</MenuItem>
                      <MenuItem eventKey={2}>{SalaryStrategy[2]}</MenuItem>
                    </Dropdown.Menu>
                  </Dropdown> :
                  // @ts-ignore
                  SalaryStrategy[overriderFinalSalary[c.name].salaryStrategy]}
              </td>
              <td>{bdrCount}</td>
              <td>{!c.isAttendance ? wrCount : 0}</td>
              <td>{!c.isAttendance ? hrCount : 0}</td>
              <td>{standard.toFixed(1)}</td>
              <td>{extra.toFixed(1)}</td>
              <td>{(extra + standard).toFixed(1)}</td>
              <td>{(hrHours.standard + hrHours.extra).toFixed(1)}</td>
              <td style={{ color: overriderFinalSalary[c.name].salaryStrategy === 2 ? 'green': '' }}>{salaryS.toFixed(1)}</td>
              <td>{salaryH}</td>
              <td>{salaryW}</td>
              <td >{profit}</td>
              <td style={{ color: overriderFinalSalary[c.name].salaryStrategy === 1 ? 'green': '' }}>{profitSalary}</td>
              <td>{c.canUseTravelTime || c.isAttendance ? (getTimeString(c.travelTime) + (c.travelFixedPrice ? `  (${ c.travelFixedPrice })` : '')) : [
                <Checkbox checked={dietUnknownInfo[c.name].canUseTravelTime}
                          style={{ display: 'inline-block' }}
                          title='Povolit připočtení diety'
                          onChange={(e) => {
                            dietUnknownInfo[c.name].canUseTravelTime = (e.target as HTMLInputElement).checked
                            dispatch(setBaseState({ dietUnknownInfo }));
                          }}/>,
                <TimeField
                  style={{padding: '5px', fontSize: '11px', minWidth: '55px',maxWidth: '55px', display: 'inline-block'}}
                  value={getTimeString(dietUnknownInfo[c.name].travelTime)}  // {String}   required, format '00:00' or '00:00:00'
                  // @ts-ignore
                  onChange={(e: FormEvent<FormControl>) => {
                    dietUnknownInfo[c.name].travelTime =  (e.target as HTMLInputElement).value === '' ?
                      0 : getFloatTime((e.target as HTMLInputElement).value);
                    dispatch(setBaseState({ dietUnknownInfo }));
                  }}
                  input={<FormControl
                    title='Celkový čas ztrávený na cestě, připočtený k pracovní době' onFocus={(e) =>
                    // @ts-ignore
                    (e.target).select()}/>}
                  colon=":"
                />,
                <FormControl
                  style={{padding: '5px', fontSize: '11px', display: 'inline-block', minWidth: '55px',maxWidth: '55px', }}
                  id="fixedTravelPrice"
                  title='Vlastní částka pro dietu za jeden den'
                  value={dietUnknownInfo[c.name].travelFixedPrice}
                  min={0}
                  onChange={(e) => {
                    dietUnknownInfo[c.name].travelFixedPrice = parseFloat((e.target as HTMLInputElement).value);
                    dispatch(setBaseState({dietUnknownInfo}));
                  }}
                  type='number'/>
              ]}</td>
              {dietsComplete.map((d, index) => {
                return <td key={index}>{d}</td>
              })}

              <td>{realC.travelFixedPrice && realC.canUseTravelTime ?
                (realC.travelFixedPrice  * (bdrCount + hrCount + wrCount)) : 0}</td>
              <td style={{color: dietBonus > 0 ? 'darkgreen' : ''}}>{`${dietBonus > 0 ? '+' : ''}${ownBonusTotal === 0 ? 0 :dietBonus}`}</td>
              <td>{
                overriderFinalSalary[c.name].enabled ? <FormControl
                  style={{paddingRight: '10px', fontSize: '11px', display: 'inline-block', textAlign: 'right' }}
                  id="ItemCustomPrice"
                  value={overriderFinalSalary[c.name].salary}
                  onChange={(e) => {
                    overriderFinalSalary[c.name].salary = parseFloat((e.target as HTMLInputElement).value);
                    dispatch(setBaseState({ dietUnknownInfo }));
                  }}
                  type='number'/> : roundFloatTwoDigit(finSalary)
              }
              </td>
            </tr>
          })
        }
        {
          SalaryCustomItems({
            diets: diets.current,
            originCustomItems: customItems,
            customItems: customItems.concat(replacedCustom),
            changed: (customItems) =>
              dispatch(setBaseState({ customItems })),
            deleted: deleteCustomItem,
            salary,
          })
        }
        {SalaryTotals({
          diets: diets.current,
          finalTable: finalTable.current,
          salary,
          customItems: customItems.concat(replacedCustom),
        })}
        <tr>
          <td title='Vyberte kladnou hodnotu pro přidání hodin do fondu
          (v kalkulačce se odečte od současné mzdy). Zápornou hodnotou odeberete z fondu hodiny, respektive příčtete ke stávající výplatě' colSpan={5}>Fond přesčasů</td>
          <td>
            <FormControl
              style={{paddingRight: '10px', fontSize: '11px', display: 'inline-block', textAlign: 'right' }}
              id="ItemCustomPrice"
              value={totalSavedOvertimeHours}
              onChange={(e) => {
                dispatch(setBaseState({
                  totalSavedOvertimeHours: Number((e.target as HTMLInputElement).value)}))
              }}
              type='number'/>
            {`  Hodnota -${availableFond} až ${(neededHoursFromFond > 0 ?
              (neededHoursFromFond) : 0).toFixed(1)}`}
          </td>
        </tr>
        {timeOverlapsCheck && <tr>
          <td style={{ color: 'red' }} colSpan={18 + (salary ? diets.current.length : 0)}>
            Některý z řádků překrývá svůj čas s jiným (sledujte červenou značku ve sloupci od)</td></tr>}
        {strategyCheck && <tr>
          <td style={{ color: 'red' }} colSpan={18 + (salary ? diets.current.length : 0)}>Některá položka nemá vybranou strategii</td></tr>}
        {salaryCheck && <tr>
          <td style={{ color: 'red' }} colSpan={18 + (salary ? diets.current.length : 0)}>Některý řádek již má mzdu</td></tr>}
        {attendanceError && <tr>
          <td style={{ color: 'red' }} colSpan={18 + (salary ? diets.current.length : 0)}>Docházka není kompletní</td></tr>}
        {attendanceChanged && <tr>
          <td style={{ color: 'red' }} colSpan={18 + (salary ? diets.current.length : 0)}>Byla provedena změna docházky, která není uložena</td></tr>}
        {noRowButGpsCheck && <tr>
          <td style={{ color: 'red' }} colSpan={18 + (salary ? diets.current.length : 0)}>
            Existují GPS data, ale řádek není vyplněn a uložen, sledujte červený vykřičník</td></tr>}
        {stateCheck && <tr>
          <td style={{ color: 'red' }} colSpan={18 + (salary ? diets.current.length : 0)}>
            Některý z řádků není ve stavu evidováno</td></tr>}
        {!(-availableFond <= totalSavedOvertimeHours && totalSavedOvertimeHours <= (neededHoursFromFond > 0 ?
          (neededHoursFromFond) : 0)) && <tr>
          <td style={{ color: 'red' }} colSpan={18 + (salary ? diets.current.length : 0)}>
            Přesčasový fond je mimo rozsah</td></tr>}
        {dietCheck && <tr>
          <td style={{ color: 'orange' }} colSpan={18 + (salary ? diets.current.length : 0)}>Pozor některá položka nepoužívá diety</td></tr>}
        {rowExistsButGpsNot && <tr>
          <td style={{ color: 'orange' }} colSpan={18 + (salary ? diets.current.length : 0)}>
            Pro některý z řádků neexistují GPS data</td></tr>}
        {
          // @ts-ignore
          authenticate.actualUserRole.salary.update && <tr>
            <td colSpan={18 + (salary ? diets.current.length : 0)}>
              <Button
                disabled={strategyCheck
                || salaryCheck
                || stateCheck
                || noRowButGpsCheck
                || attendanceChanged
                || timeOverlapsCheck
                || attendanceError}
                onClick={saveSalary}>
                Uložit mzdu
              </Button>
            </td>
          </tr>}
        </tbody>
      </table>
    </div>
  </>
}

export default SalaryStateProvider;