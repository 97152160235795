import React, {Component, useState} from 'react';
import { BaseViewPropTypes } from '../CommonListContainer';
import {Row, Col, Button, FormGroup, ControlLabel, Checkbox} from 'react-bootstrap';
import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import request from 'axios';
import {LoadingOrRender} from "../../loginsrc/Profile";
import {getValuesOfCustomNumber} from "../utils/NumberQueue";
import store from '../../redux/store';

function SmallOrderPreview({item, enabledSuborders, offsetIfSuborder}) {

  const [showOld, setShowOld] = useState(false);

  const renderItem = (item) => {
    const abi = item.addressBookIdsParent ? (item.addressBookIdsParent.length ? item.addressBookIdsParent
      : item.addressBookIds) : item.addressBookIds;
    const customNumber = (item.customNumber && item.customNumber !== '' ? item.customNumber : null);
    return <>
      <span style={{ marginRight: '5px', fontWeight: 'bold'}}>{`${customNumber ?
        customNumber + ': ' : ' - '} ${item.foreignBookKeepingNumber ?
        item.foreignBookKeepingNumber + ' / ' : ''}${item.receivedOrderNumber} `}</span>
      {abi && <span><FontAwesome style={{ fontSize: '12px', marginRight: '3px' }} name='fas fa-building'/></span>}
      {abi && <span style={{ marginRight: '8px' }}>{abi.map(o => o.addressBookItem.company).join(', ')}</span>}
        {
          <>
            {
              item.projectLeaderUser ? <>
                <span><FontAwesome style={{ fontSize: '12px', marginRight: '3px', color: 'brown' }} name='fas fa-user-tie'/></span>
                <span style={{ marginRight: '8px', color: 'brown'  }}>{item.projectLeaderUser.nameSurname}</span>
              </> : null
            }
            {
              item.contactUser ? <>
                <span><FontAwesome style={{ fontSize: '12px', marginRight: '3px' }} name='fas fa-user'/></span>
                <span style={{ marginRight: '8px' }}>{item.contactUser.nameSurname}</span>
              </> : null
            }
            <span><FontAwesome style={{ fontSize: '12px', marginRight: '3px' }} name='fas fa-wrench'/></span>
            <span style={{ marginRight: '5px' }}>{item.construction}</span>
            {
              item.loading !== "" || item.unloading !== "" ? <>
                <span><FontAwesome style={{ fontSize: '12px', marginRight: '3px' }} name='fas fa-truck'/></span>
                <span style={{ marginRight: '5px' }}>{`${item.loading} - ${item.unloading}`}</span>
              </> : null
            }
          </>
        }
    </>
  }

  const someIsConfirmed = item.suborders ? (item.suborders.filter(o => o.isConfirmed).length > 0) : false

  if (item.id === 0)
    return item.label;
  return <div style={{display: 'inline-block', marginLeft:
      (offsetIfSuborder && item.parentOrderId) ? '20px' : undefined,}}>
    {renderItem(item)}
    { enabledSuborders && <ul>
      { item.suborders.map((o,index) => (!o.isConfirmed || showOld ) && <li style={{
        opacity: o.isConfirmed ? 0.3 : 1}} key={index}>
        {renderItem(o)}
      </li>)}
      {someIsConfirmed && <a onClick={() => setShowOld(true)} style={{ cursor: 'pointer' }}>Načíst dokončené podzakázky</a>}
    </ul> }

  </div>
}

SmallOrderPreview.propTypes = {
  enabledSuborders: PropTypes.bool,
  offsetIfSuborder: PropTypes.bool,
}

SmallOrderPreview.defaultProps = {
  enabledSuborders: false,
  offsetIfSuborder: false
}

export default class OrderPreview extends Component{

  constructor(props) {
    super(props);
    this.state = {
      requestActive: false,
    }
  }

  importToBookeeping() {
    this.setState({requestActive: true});
    request.post('/items/pohoda/importreceivedorder', this.props.item)
      .then((res) => {
        if (res.status === 200) {
          const item = {
            ...this.props.item,
            ...res.data,
          };
          this.props.onItemChange(item);
          this.importContract();
        }
      })
      .catch(err => {
        this.setState({requestActive: false});
      })
  }

  deleteFromBookeeping() {
    this.setState({requestActive: true});
    request.post('/items/pohoda/deleterecorder', {
      id: this.props.item.id,
      foreignBookKeepingId: this.props.item.foreignBookKeepingId,
    })
      .then((res) => {
        if (res.status === 200) {
          const item = {
            ...this.props.item,
            foreignBookKeepingId: null,
            foreignBookKeepingNumber: null,
          };
          this.props.onItemChange(item);
          this.setState({requestActive: false});
        }
      })
      .catch(err => {
        this.setState({requestActive: false});
      })
  }

  importContract() {
    this.setState({requestActive: true});
    request.post('/items/pohoda/importcontract', this.props.item)
      .then((res) => {
        if (res.status === 200) {
          const { item } = this.props;
          item.foreignBookKeepingContractId = 'filled';
          if (item.suborders) {
            item.suborders.forEach(i => i.foreignBookKeepingContractId = 'filled');
          }
          this.props.onItemChange(item);
          this.setState({requestActive: false});
        }
      })
      .catch(err => {
        this.setState({requestActive: false});
      })
  }

  render() {
    const {item} = this.props;
    const template = (store.getState().authenticate).activeSpace.setting.ordersNumberTemplate;
    const isSynchronized = (item.suborders ? item.suborders.concat(item) : [item])
      .filter(o => !o.isConfirmed).filter(o => !o.foreignBookKeepingContractId).length === 0;

    let orderType = '';
    if (template) {
      const positions =
        // @ts-ignore
        getValuesOfCustomNumber(template,
          (item && item.customNumber) ? item.customNumber : '');
      if (positions[0]) {
        if (positions[0].isEnum) {
          const regex = template.value[0].regexTable.find(f => f.item === positions[0].value);
          if (regex) {
            orderType = regex.description;
          }
        }
      }
    }

    return (<div style={{verticalAlign: 'middle',
      opacity: item.isConfirmed ? 0.3 : 1}}>
      <Row>
        <Col sm={6}>
          <SmallOrderPreview enabledSuborders item={item}/>
        </Col>
        <Col sm={2}>
          {orderType}
        </Col>
        <Col sm={2}>
          {!isSynchronized && <LoadingOrRender requestActive={this.state.requestActive}>
            <Button onClick={() => this.importContract()}>
              Synchronizovat zakázky v ú.s
            </Button>
          </LoadingOrRender>}

          {/*
            item.foreignBookKeepingNumber ? <>
                {item.foreignBookKeepingNumber}
                <Button onClick={() => this.deleteFromBookeeping()} style={{ marginLeft: '10px' }}> Vymazat z ús.</Button>
              </> :
              <LoadingOrRender height='20px' width='20px' requestActive={this.state.requestActive}>
                <Button onClick={() => this.importToBookeeping()}>
                  Přidat do ús.
                </Button>
              </LoadingOrRender>
          */}
        </Col>
        <Col>
          <FormGroup>
            <ControlLabel>{item.isConfirmed ? 'Dokončeno' : 'Aktivní'}</ControlLabel>
          </FormGroup>
        </Col>
      </Row>
    </div>)
  }
}

OrderPreview.propTypes = {
  isForLog: PropTypes.bool,
 ...BaseViewPropTypes
};

OrderPreview.defaultProps = {
  isForLog: false
};

export {
  SmallOrderPreview,
}
