import * as React from 'react';
import createEngine, {
    DefaultDiagramState,
    DiagramEngine,
    DiagramModel, RightAngleLinkModel,
} from '@projectstorm/react-diagrams';
import { RightAngleLinkFactory } from '@projectstorm/react-diagrams-routing'
import { BodyWidget } from "./components/BodyWidget";
import {
    BoolSwitchNodeFactory,
    ConnectionNodeFactory,
    EndNodeFactory
} from "./components/nodes/flowControlNodes/FlowControlFactories";
import {TaskNodeFactory} from "./components/nodes/taskDescriptionNodes/TaskDescriptionFactories";
import {CmlPortFactory} from "./components/nodes/ports/CmlPortFactory";
import {
    InputType, CanvasModel, Toolkit,
} from '@projectstorm/react-canvas-core';
import {CmlDragNewLinkState} from "./components/CmlDragNewLinkState";
import {
    ConnectionNodeModel,
    EndNodeModel,
    EndNodeModelOptions
} from "./components/nodes/flowControlNodes/FlowControlModels";
import {CmlPortModel, CmlPortModelOptions} from "./components/nodes/ports/CmlPortModel";
import {TaskNodeModel} from "./components/nodes/taskDescriptionNodes/TaskDescriptionModels";

export interface DiagramProps {
    defaultModel?: ReturnType<CanvasModel['serialize']> | undefined;
    externDiagram?: DiagramEngine;
    readonly?: boolean;
}

export const createCmlEngine = (): DiagramEngine => {
    const engine = createEngine();
    engine.getLinkFactories().registerFactory(new RightAngleLinkFactory());
    engine.getNodeFactories().registerFactory(new EndNodeFactory());
    engine.getNodeFactories().registerFactory(new BoolSwitchNodeFactory());
    engine.getNodeFactories().registerFactory(new TaskNodeFactory());
    engine.getPortFactories().registerFactory(new CmlPortFactory());
    engine.getNodeFactories().registerFactory(new ConnectionNodeFactory());
    const zoomAction = engine.getActionEventBus().getActionsForType(InputType.MOUSE_WHEEL)[0];
    engine.getActionEventBus().deregisterAction(zoomAction);
    const state = engine.getStateMachine().getCurrentState();
    if (state instanceof DefaultDiagramState) {
        state.dragNewLink = new CmlDragNewLinkState();
        state.dragNewLink.config.allowLooseLinks = false;
    }
    return engine;
};

const continueConnection = (port: CmlPortModel, connPort: CmlPortModel, blackListedConnPorts: CmlPortModel[]): CmlPortModel[] => {
    // Disable to return back
    blackListedConnPorts.push(connPort);

    // Convert Port links to array
    const connLinks = connPort.getLinks();
    const connLinksArray = [] as RightAngleLinkModel[];
    Object.keys(connLinks).map(key => {
        connLinksArray.push(connLinks[key] as RightAngleLinkModel);
    });

    // Filter ports which are opposite input/output to find where link is connected
    const connTargetPorts = connLinksArray.map(o => {
        if (o.getSourcePort() === connPort) {
            if (o.getTargetPort() === port) {
                return null;
            }
            return o.getTargetPort();
        }
        if (o.getSourcePort() === port) {
            return null;
        }
        return o.getSourcePort();
    }).filter(o => {
        return o !== null && ((port.getOptions() as CmlPortModelOptions).in !== (o.getOptions() as CmlPortModelOptions).in
            || o.getParent() instanceof ConnectionNodeModel) && blackListedConnPorts.filter(b => b === o).length === 0 ;

    });

    let candidatesPorts = connTargetPorts.filter(o => !((o as CmlPortModel).getParent() instanceof ConnectionNodeModel)) as CmlPortModel[];
    const anotherConn = connTargetPorts.filter(o => ((o as CmlPortModel).getParent() instanceof ConnectionNodeModel));

    if (anotherConn.length > 0) {
        anotherConn.forEach(conn =>  {
            if (conn) {
                const r = continueConnection(port, conn as CmlPortModel, blackListedConnPorts);
                if (r) {
                    candidatesPorts = candidatesPorts.concat(r);
                }
            }
        });
    }
    return candidatesPorts ? candidatesPorts : [];
};

export const getTargetPorts = (port: CmlPortModel): CmlPortModel[] => {
    const blackListedConnPorts = [] as CmlPortModel[];
    const links = port.getLinks();
    const link = links[Object.keys(links)[0]];
    if (!link)
        return [];
    let parentNode = null;
    let retPorts = [] as CmlPortModel[];
    if (link.getSourcePort() === port) {
        parentNode = link.getTargetPort().getParent();
        retPorts.push(link.getTargetPort() as CmlPortModel);
    } else {
        parentNode = link.getSourcePort().getParent();
        retPorts.push(link.getSourcePort() as CmlPortModel);
    }
    // Now this falls into recursive calls
    if (parentNode instanceof ConnectionNodeModel) {
        const connPort = parentNode.getPort('conn') as CmlPortModel;
        if (connPort) {
            const ret = continueConnection(port, connPort, blackListedConnPorts);
            return ret ? ret : [];
        } else {
            return [];
        }
    }
    return retPorts;
};

export const getFirstProcessPort = (engine: DiagramEngine): CmlPortModel[] => {
    const startNodes = engine.getModel().getNodes().filter(o => o instanceof EndNodeModel )
        .filter(o => !(o.getOptions() as EndNodeModelOptions).isInput);
    if (startNodes.length > 0) {
        const startNode = startNodes[0];
        const port = startNode.getPorts()['out-1'] as CmlPortModel;
        const targetPorts = getTargetPorts(port);
        return targetPorts;
    } else {
        return [];
    }
} ;

export default class Diagram extends React.Component<DiagramProps> {
    engine: DiagramEngine;
    constructor(props: DiagramProps) {
        super(props);

        //1) setup the diagram engine
        this.engine = props.externDiagram ? props.externDiagram : createCmlEngine();

        //2) setup the diagram model
        let model = new DiagramModel();

        //5) load model into engine
        this.engine.setModel(model);
    }

    componentWillMount(): void {
        if (this.props.defaultModel) {
            const model = new DiagramModel();
            model.deserializeModel(this.props.defaultModel, this.engine);
            model.getNodes().map(node => {
                node.setSelected(false);
                /*if (node instanceof TaskNodeModel) {
                    node.getSetting().taskList.forEach(task => {
                        if (typeof task.id === 'undefined')
                            task.id = Toolkit.UID();
                    })
                }*/
            });
            model.getLinks().map(link => link.setSelected(false));
            this.engine.setModel(model);
        }
    }

    render() {
        return <BodyWidget readonly={this.props.readonly} diagram={this.engine} />;
    }
}