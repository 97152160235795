import React, { Component } from "react";
import moment, {Moment} from "moment";
import {ControlLabel, Modal, ModalBody, ModalHeader, FormGroup, Col, Row} from "react-bootstrap";
import Timeline, {TimelineItemBase} from 'react-calendar-timeline';
import _ from 'lodash';
import { IVehicleData } from "@cml/types";

interface Props {
  vehicleData: IVehicleData[],
  fromTime?: boolean,
  style?: object,
}

interface State {
  show: boolean;
}

const GPStypes: {
  [key:string]: string
} = {
  webdispecink: 'Webdispečink',
  myvisionlink: 'Visionlink',
  volvo: 'Volvo',
}

export default class GPSDataViewer extends Component<Props, State> {

  static defaultProps = {
    vehicleData: []
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  createTimeline() {
    const { vehicleData, } = this.props;
    if (vehicleData.length === 0)
      return {
        groups: [],
        items: [],
      }
    const groups = _.uniqBy(vehicleData, 'currentDriverActivity').map((o, index) => ({
      id: index + 1,
      title: o.currentDriverActivity,
    }));
    const items: TimelineItemBase<Moment>[] = [];
    let lastActivity = vehicleData[0].currentDriverActivity;
    let startStamp = moment(vehicleData[0].timestamp).add(2, 'hours');
    let counter = 0;
    vehicleData.forEach(d => {
      if (d.currentDriverActivity !== lastActivity || vehicleData[vehicleData.length - 1] === d) {
        counter++;
        const group = groups.find(o => o.title === lastActivity);
        items.push({
          id: counter,
          group: group ? group.id : 0,
          start_time: startStamp,
          end_time: moment(d.timestamp).add(2, 'hours'),
        });
        startStamp = moment(d.timestamp).add(2, 'hours');
        lastActivity = d.currentDriverActivity;
      }
    })
    return {
      groups,
      items
    }

  }

  onClickHandler = () => this.setState({show: true});

  render() {
    const { vehicleData, fromTime, style } = this.props;
    //console.log(vehicleData.map(o => ({ timestamp: o.timestamp, m: o.motoHours, a: o.currentDriverActivity, od: o.odometer })));
    if (vehicleData.length === 0)
      return null;
    const timeline = this.createTimeline();
    return [vehicleData.length > 0 && <div onClick={this.onClickHandler} className='gpsLink' style={{ ...style }}>
      {moment(vehicleData[fromTime ? 0 : vehicleData.length - 1].timestamp).add(2, 'hours').format("HH:mm")}
    </div>,
      <Modal dialogClassName="modal-90w" show={this.state.show} onHide={() => this.setState({ show: false })}>
        <ModalHeader closeButton>
          <Modal.Title>{`GPS Data vozidla (${GPStypes[vehicleData[0].type]})`}</Modal.Title>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <ControlLabel>
                  Odpracované motohodiny
                </ControlLabel>
                <div>
                  {vehicleData[vehicleData.length - 1].motoHours - vehicleData[0].motoHours}
                </div>
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  {`Motohodiny v ${moment(vehicleData[0].timestamp).add(2, 'hours').format("HH:mm")}`}
                </ControlLabel>
                <div>
                  {vehicleData[0].motoHours}
                </div>
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  {`Motohodiny v ${moment(vehicleData[vehicleData.length - 1].timestamp).add(2, 'hours').format("HH:mm")}`}
                </ControlLabel>
                <div>
                  {vehicleData[vehicleData.length - 1].motoHours}
                </div>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <ControlLabel>
                  Odjeté kilometry
                </ControlLabel>
                <div>
                  {vehicleData[vehicleData.length - 1].odometer - vehicleData[0].odometer}
                </div>
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  {`Tachometr v ${moment(vehicleData[0].timestamp).add(2, 'hours').format("HH:mm")}`}
                </ControlLabel>
                <div>
                  {vehicleData[0].odometer}
                </div>
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  {`Tachometr v ${moment(vehicleData[vehicleData.length - 1].timestamp).add(2, 'hours').format("HH:mm")}`}
                </ControlLabel>
                <div>
                  {vehicleData[vehicleData.length - 1].odometer}
                </div>
              </FormGroup>
            </Col>
          </Row>


          <Row>
            <Col sm={12}>
              <FormGroup>
                <ControlLabel>
                  Časová osa
                </ControlLabel>
                <Timeline
                  canMove={false}
                  groups={timeline.groups}
                  items={timeline.items}
                  defaultTimeStart={moment(vehicleData[0].timestamp).add(90, 'minutes')}
                  defaultTimeEnd={moment(vehicleData[vehicleData.length - 1].timestamp).add(150, 'minutes')}
                />
              </FormGroup>
            </Col>
          </Row>


        </ModalBody>
      </Modal>
    ];
  }
}