import {Component, useState} from "react";
import React from "react";
import {Button, Col, Navbar, Row} from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from "react-router-dom";
import {Setting} from "./Setting";
import Profile from "../loginsrc/Profile";
import SingUp from "../loginsrc/SignUp";
import ChangePassword from "../loginsrc/ChangePassword";
import EmailValidation from "../loginsrc/EmailValidation";
import Login from "../loginsrc/Login";
import CommonListContainer from "./CommonListContainer";
import UserPreview from './users/Preview';
import UserEdit from './users/Edit';
import UserFiltr from './users/Filtr';
import AttendancePreview from './attendances/Preview';
import AttendanceEdit from './attendances/Edit';
import AttendanceFiltr from './attendances/Filtr';
import VehicleEdit from './vehicles/Edit';
import VehiclePreview from './vehicles/Preview';
import VehicleFilter from './vehicles/Filtr';
import ToolEdit from './tools/Edit';
import ToolPreview from './tools/Preview';
import ToolsFiltr from './tools/Filtr';
import TaskPreview from './tasks/Preview';
import TaskEdit from "./tasks/Edit";
import TasksFiltr from "./tasks/Filtr";
import SmsView from './sms/Preview';
import SmsEdit from './sms/Edit';
import SmsFilter from './sms/Filtr';
import Dispatching from './dispatching/Dispatching';
import ResultsProvider, { Results } from './dispatching/Results';
import AddressBookView from './addressBook/Preview';
import AddressBookEdit from './addressBook/Edit';
import AddressBookFiltr from './addressBook/Filtr';
import LogView from "./log/Preview";
import LogFiltr from "./log/Filtr";
import OrderEdit from './orders/Edit';
import OrderPreview from './orders/Preview';
import PHM from "./phm/Preview";
import * as SRD from "@projectstorm/react-diagrams";
import Import from "./import/Import";
import {connect, useSelector} from "react-redux";
import {mapAuthStateToProps} from "../AppObjects";
import OrdersFiltr from "./orders/Filtr";
import SalaryFilter from "./salary/Filtr";
import CreditCardView from "./creditCard/Preview";
import CreditCardFilter from "./creditCard/Filtr";
import CreditCardEdit from "./creditCard/Edit";
import AttendanceTimeline from "./attendances/AttendanceTimeline";
import SideMenuNavBar from "./SideMenuNavBar";
import {AuthenticateState} from "@cml/redux-store";
import SalaryFinalPreview from "./salary/Preview";

const timeout = 10;

class DelayRenderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    }
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({visible: true})
    }, this.props.delay)
  }

  render() {
    if (this.props.disabled)
      return null;
    let toRender = null;
    if (this.state.visible) {
      toRender = this.props.children;
    }
    return (<div style={{display: this.state.visible ? '' : 'none'}}>
      {toRender}
    </div>)
  }
}

DelayRenderContainer.propTypes = {
  delay: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

class CanView extends Component {
  render() {
    if (!this.props.visible)
      return null;
    return typeof this.props.children !== 'undefined' ? this.props.children : null;
  }
}

CanView.propTypes = {
  visible: PropTypes.bool.isRequired,
};

CanView.defaultProps = {
  visible: false,
};

const sumRights = (array) => {
  let sum = 0;
  array.forEach((item) => {
    if(item)
      sum++
  });
  return sum;
};

export {
  DelayRenderContainer,
  timeout,
  CanView,
  sumRights,
}

export default function Main(props) {
  const [full, setFull] = useState(false);
  const authenticate = useSelector(
    (state) => state.authenticate);

    if (!authenticate.isAuthenticated) {
      let toRender = null;
      if (authenticate.isAuthenticatedRaw) {
        toRender = <div className="buttons">
          <Button bsClass='btn btn-primary'
                  onClick={() => props.history.push('/emailvalidation')}>Dokončení
            registrace</Button>
        </div>
      } else {
        toRender = <div className="buttons">
          <Button bsClass='btn btn-default'
                  onClick={() => props.history.push('/login')}>Přihlášení</Button>
          <Button bsClass='btn btn-primary'
                  onClick={() => props.history.push('/signup')}>Registrace</Button>
        </div>
      }
      if (authenticate.firstAuthDone) {
        return <div>
          <Switch>
            <Route path="/signup"
                   render={(props) => <SingUp {...props}/>}
            />
            <Route path="/changepassword"
                   render={(props) => <ChangePassword {...props}/>}
            />
            <Route path='/emailvalidation'
                   render={(props) => <EmailValidation {...props}
                                                       email={authenticate.registeredEmail}/>}
            />
            <Route path='/login'
                   render={(props) => <Login {...props}
                                             email={authenticate.registeredEmail}/>}
            />
            <Route exact path='/'
                   render={(props) => <div className="highlight-clean" style={{height: "100%"}}>
                     <div className="container">
                       <div className="intro">
                         <h2 className="text-center">Tondau</h2>
                         <p className="text-center">Pro používání tohoto systému se musíte přihlásit nebo
                           registrovat</p>
                       </div>
                       {toRender}
                     </div>
                   </div>}
                   />
            <Redirect from='*' to='/' />
          </Switch>
        </div>
      }
      else return null;

    } else {
      let role = authenticate.actualUserRole;
      let rightsArray = [
        role['users'].view,
        role['vehicles'].view,
        role['dispatching'].view,
        role['attendance'].view,
        role['tasks'].view,
        role['tools'].view,
        role['sms'].view,
        role['addressBook'].view,
        role['orders'].view,
        role['phm'].view,
        role['salary'].view,
        role['creditCard'].view,
      ];
      return <div>
        <div>
          <SideMenuNavBar full={full} setFull={setFull}/>
          <div style={{ marginLeft: full ? '23rem' : '5rem' }} className='homeMain'>
            <Route path='/setting'
                   render={(props) => <Setting {...props}/>}/>
            <Route path='/dispatching'
                   render={(props) => <Dispatching {...props}
                                                   onListChange={props.onListChange}/>}/>
            <Route path='/unresolved'
                   render={(props) => <Dispatching {...props}
                                                   unresolvedMode={true}
                                                   onListChange={props.onListChange}/>}/>
            <Route path='/results'
                   render={(props) =>
                     <ResultsProvider>
                       <Results {...props}/>
                     </ResultsProvider>
                     }/>
            <Route path='/profile'
                   render={(props) => <Profile {...props}/>}/>
            <Route path='/phm'
                   render={(props) => <PHM {...props}/>}/>
            <Route path='/import'
                   render={(props) => <Import {...props}/>}/>
            <Route path='/users'
                   render={(props) => <CommonListContainer {...props} previewMode={ UserPreview }
                                                           item='users'
                                                           filter={ UserFiltr }
                                                           orderForVis
                                                           defaultQuery={{allSpaces: true}}
                                                           checkRights='users'
                                                           backUrl='/'
                                                           leftIconStyle='fas fa-address-card'
                                                           editMode={ UserEdit }/>}/>
            {/*<Route path='/attendance'
               render={(props) => <CommonListContainer {...props} previewMode={ AttendancePreview }
                                                       item='attendance'
                                                       filter={ AttendanceFiltr }
                                                       checkRights='attendance'
                                                       backUrl='/'
                                                       leftIconStyle='fas fa-edit'
                                                       editMode={ AttendanceEdit }/>}/>*/}
            <Route path='/attendance'
                   render={(props) => <AttendanceTimeline {...props}
                                                          editMode={ AttendanceEdit }
                                                          filter={ AttendanceFiltr }
                                                          item='attendance'
                                                          checkRights='attendance'
                                                          backUrl='/' />}/>
            <Route path='/vehicles'
                   render={(props) => <CommonListContainer {...props} previewMode={ VehiclePreview }
                                                           filter={ VehicleFilter }
                                                           item='vehicles'
                                                           orderForVis
                                                           defaultQuery={{allSpaces: true, allDetails: 0 }}
                                                           checkRights='vehicles'
                                                           backUrl='/'
                                                           leftIconStyle='fas fa-edit'
                                                           editMode={ VehicleEdit }/>}/>
            <Route path='/tools'
                   render={(props) => <CommonListContainer {...props} previewMode={ ToolPreview }
                                                           item='tools'
                                                           filter={ ToolsFiltr }
                                                           checkRights='tools'
                                                           orderForVis
                                                           backUrl='/'
                                                           leftIconStyle='fas fa-edit'
                                                           editMode={ ToolEdit }/>}/>
            <Route path='/tasks'
                   render={(props) => <CommonListContainer {...props} previewMode={ TaskPreview }
                                                           item='tasks'
                                                           stayOnEditedObjectDuringAdd
                                                           checkRights='tasks'
                                                           filter={ TasksFiltr }
                                                           onListChange={props.onListChange}
                                                           backUrl='/'
                                                           leftIconStyle='fas fa-edit'
                                                           editMode={ TaskEdit }/>}/>
            <Route path='/sms'
                   render={(props) => <CommonListContainer {...props} previewMode={ SmsView }
                                                           filter={SmsFilter}
                                                           item='sms'
                                                           checkRights='sms'
                                                           editMode={ SmsEdit }
                                                           onListChange={props.onListChange}
                                                           backUrl='/'/>}/>
            <Route path='/logs'
                   render={(props) => <CommonListContainer {...props} previewMode={ LogView }
                                                           item='logs'
                                                           filter={LogFiltr}
                                                           requestMorePreviewProps={['/items/attributes/price']}
                                                           disableDelete
                                                           disableNew
                                                           onListChange={props.onListChange}
                                                           backUrl='/'/>}/>
            <Route path='/addressbook'
                   render={(props) => <CommonListContainer {...props} previewMode={ AddressBookView }
                                                           item='addressbook'
                                                           filter={ AddressBookFiltr }
                                                           checkRights='addressBook'
                                                           editMode={ AddressBookEdit }
                                                           backUrl='/'
                                                           leftIconStyle='fas fa-edit'/>}/>
            <Route path='/orders'
                   render={(props) => <CommonListContainer {...props} previewMode={ OrderPreview }
                                                           defaultQuery={{
                                                             parentOrderId: 0,
                                                           }}
                                                           item='orders'
                                                           filter={ OrdersFiltr }
                                                           checkRights='orders'
                                                           editMode={ OrderEdit }
                                                           backUrl='/'
                                                           leftIconStyle='fas fa-edit'/>}/>
            <Route path='/salary'
                   render={(props) => <CommonListContainer {...props} previewMode={ SalaryFinalPreview }
                                                           item='salary'
                                                           filter={ SalaryFilter }
                                                           disableNew
                                                           checkRights='salary'
                                                           backUrl='/'/>}/>
            <Route path='/creditcard'
                   render={(props) => <CommonListContainer {...props} previewMode={ CreditCardView }
                                                           item='creditCard'
                                                           filter={ CreditCardFilter }
                                                           editMode={ CreditCardEdit }
                                                           disableNew
                                                           leftIconStyle='fas fa-edit'
                                                           checkRights='creditCard'
                                                           backUrl='/'/>}/>
            <Route exact path='/'
                   render={() => <div className="features-boxed">
                     <div className="container">
                       <div className="intro">
                         <h2 className="text-center">Hlavní menu </h2>
                       </div>
                       <Row bsClass="features">
                         <CanView visible={role['users'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,1)) - 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>

                                 <div className='item' onClick={() => props.history.push('/users')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fas fa-users'/>
                                     </div>
                                     <h3 className="name">Kartotéka</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['vehicles'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,2)) - 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/vehicles')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-car'/>
                                     </div>
                                     <h3 className="name">Vozidla</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['dispatching'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,3)) - 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/dispatching')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-calendar-alt'/>
                                     </div>
                                     <h3 className="name">Kalendář</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['attendance'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,4)) - 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/attendance')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-coffee'/>
                                     </div>
                                     <h3 className="name">Docházka</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['tasks'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,5)) - 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/tasks')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-tasks'/>
                                     </div>
                                     <h3 className="name">Úkoly</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['tools'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,6)) - 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/tools')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-toolbox'/>
                                     </div>
                                     <h3 className="name">Vybavení</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['sms'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,7)) - 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/sms')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-comments'/>
                                     </div>
                                     <h3 className="name">SMS</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <Col sm={4} md={3}>
                           <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,7))) * timeout}>
                             <ReactCSSTransitionGroup
                               transitionName="main"
                               transitionAppear={true}
                               transitionAppearTimeout={5000}
                               transitionEnter={false}
                               transitionLeave={false}>
                               <div className='item' onClick={() => props.history.push('/setting')}>
                                 <div className="box">
                                   <div className="icon">
                                     <FontAwesome name='fas fa-cog'/>
                                   </div>
                                   <h3 className="name">Nastavení</h3>
                                 </div>
                               </div>
                             </ReactCSSTransitionGroup>
                           </DelayRenderContainer>
                         </Col>
                         <CanView visible={role['dispatching'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,7)) + 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/results')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-search'/>
                                     </div>
                                     <h3 className="name">Výsledky</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['addressBook'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,8)) + 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/addressbook')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-address-book'/>
                                     </div>
                                     <h3 className="name">Adresář</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['orders'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,9)) + 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/orders')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-hands-helping'/>
                                     </div>
                                     <h3 className="name">Zakázky</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={true}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,9)) + 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/logs')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-info-circle'/>
                                     </div>
                                     <h3 className="name">Log</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,9)) + 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/import')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-file-import'/>
                                     </div>
                                     <h3 className="name">Import</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['phm'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,10)) + 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/phm')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-gas-pump'/>
                                     </div>
                                     <h3 className="name">PHM</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['salary'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,11)) + 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/salary')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-money-check-alt'/>
                                     </div>
                                     <h3 className="name">Mzdy</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                         <CanView visible={role['creditCard'].view}>
                           <Col sm={4} md={3}>
                             <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,11)) + 1) * timeout}>
                               <ReactCSSTransitionGroup
                                 transitionName="main"
                                 transitionAppear={true}
                                 transitionAppearTimeout={5000}
                                 transitionEnter={false}
                                 transitionLeave={false}>
                                 <div className='item' onClick={() => props.history.push('/creditcard')}>
                                   <div className="box">
                                     <div className="icon">
                                       <FontAwesome name='fas fa-credit-card'/>
                                     </div>
                                     <h3 className="name">Platební karty</h3>
                                   </div>
                                 </div>
                               </ReactCSSTransitionGroup>
                             </DelayRenderContainer>
                           </Col>
                         </CanView>
                       </Row>
                     </div>
                   </div>
                   }/>
          </div>
        </div>


      </div>
    }
}

Main.propTypes = {
  authenticate: PropTypes.object,
  onListChange: PropTypes.func.isRequired,
};
