import * as React from 'react';
import { NodeModel, NodeModelGenerics, PortModelAlignment } from '@projectstorm/react-diagrams';
import {RightAnglePortModel} from "../../Defaults";
import { BasePositionModelOptions, DeserializeEvent, Toolkit } from '@projectstorm/react-canvas-core';
import {CmlPortModel} from "../ports/CmlPortModel";
import _ from 'lodash';


export interface TaskNodeModelOptions extends BasePositionModelOptions {
    timeToSolve?: number;
    name?: string;
    color?: string;
}

export interface TaskNodeModelGenerics extends NodeModelGenerics {
    OPTIONS: TaskNodeModelOptions;
}

export interface TaskNodeSetting {
    usersList: Array<number | object>;
    taskList: Array<TaskNodeSubtask>;
}

export interface TaskNodeSubtask {
    description: string;
    id: string;
}

class TaskNodeModel extends NodeModel<TaskNodeModelGenerics>{
    protected portsIn: CmlPortModel[];
    protected portsOut: CmlPortModel[];
    private setting: TaskNodeSetting;

    constructor(options?: TaskNodeModelGenerics['OPTIONS']) {
        super({
            type: 'tasknode',
            name: 'Untitled',
            color: 'rgb(0,0,0)',
            ...options,
        });

        this.portsOut = [];
        this.portsIn = [];
        this.setting = {
            usersList: [],
            taskList: [],
        };
    }

    getSetting() :TaskNodeSetting {
        return this.setting;
    }

    setSetting(setting: TaskNodeSetting) {
        this.setting = setting;
    }

    doClone(lookupTable: {}, clone: any): void {
        clone.portsIn = [];
        clone.portsOut = [];
        super.doClone(lookupTable, clone);
    }

    removePort(port: CmlPortModel): void {
        super.removePort(port);
        if (port.getOptions().in) {
            this.portsIn.splice(this.portsIn.indexOf(port));
        } else {
            this.portsOut.splice(this.portsOut.indexOf(port));
        }
    }

    addPort<T extends CmlPortModel>(port: T): T {

        super.addPort(port);
        if (port.getOptions().in) {
            if (this.portsIn.indexOf(port) === -1) {
                this.portsIn.push(port);
                if (port.getOptions().inOut) {
                    if (port.getOptions().name.slice(-2) !== '_O') {
                        const p = new CmlPortModel({
                            ...port.getOptions(),
                            in: false,
                            name: port.getOptions().name + '_O',
                            id: Toolkit.UID(),
                        });
                        super.addPort(p);
                        this.portsOut.push(p);
                    }
                }
            }
        } else {
            if (this.portsOut.indexOf(port) === -1) {
                this.portsOut.push(port);
                if (port.getOptions().inOut) {
                    if (port.getOptions().name.slice(-2) !== '_O') {
                        const p = new CmlPortModel({
                            ...port.getOptions(),
                            in: true,
                            name: port.getOptions().name + '_O',
                            id: Toolkit.UID(),
                        });
                        super.addPort(p);
                        this.portsIn.push(p);
                    }
                }
            }
        }

        return port;
    }

    addInPort(label: string, after = true): CmlPortModel {
        const p = new CmlPortModel({
            in: true,
            name: label,
            label: label,
            alignment: PortModelAlignment.LEFT
        });
        if (!after) {
            this.portsIn.splice(0, 0, p);
        }
        return this.addPort(p);
    }

    addOutPort(label: string, after = true): CmlPortModel {
        const p = new CmlPortModel({
            in: false,
            name: label,
            label: label,
            alignment: PortModelAlignment.RIGHT
        });
        if (!after) {
            this.portsOut.splice(0, 0, p);
        }
        return this.addPort(p);
    }

    deserialize(event: DeserializeEvent<this>) {
        super.deserialize(event);
        this.options.name = event.data.name;
        this.options.color = event.data.color;
        this.setting = event.data.setting;
        this.portsIn = _.map(event.data.portsInOrder, id => {
            return this.getPortFromID(id);
        }) as CmlPortModel[];
        this.portsOut = _.map(event.data.portsOutOrder, id => {
            return this.getPortFromID(id);
        }) as CmlPortModel[];
    }

    serialize(): any {
        return {
            ...super.serialize(),
            name: this.options.name,
            color: this.options.color,
            setting: this.setting,
            portsInOrder: _.map(this.portsIn, port => {
                return port.getID();
            }),
            portsOutOrder: _.map(this.portsOut, port => {
                return port.getID();
            })
        };
    }

    getInPorts(): CmlPortModel[] {
        return this.portsIn;
    }

    getOutPorts(): CmlPortModel[] {
        return this.portsOut;
    }
}

export {
    TaskNodeModel,
}