import React, { Component } from 'react';
import { Col, ControlLabel, DropdownButton, FormControl, MenuItem, Row } from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import {mapAuthStateToProps} from "../../AppObjects";
import {connect} from "react-redux";

const stateNames = {
  all: 'Všechny stavy',
  valid: 'Platná',
  warning: 'Blíží se konec',
  notvalid: 'Neplatná',
};

const typeNames = {
  0: 'Všechny',
  1: 'Uta',
  2: 'Shell',
  3: 'Eurowag',
};

class CreditCardFilter extends Component{

  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      validState: 'all',
      type: '0',
    }
  }

  render() {
    const { filterValue, validState, type } = this.state;
    return (<div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll'>
      <Row>
        <Col sm={1}>
          <div className='text-right'>
            <FontAwesome style={{fontSize:'18px', marginTop: '8px'}} name='fas fa-search' />
          </div>
        </Col>
        <Col sm={3}>
          <FormControl
            value={this.state.filterValue}
            onChange={(e) => {
              this.setState({filterValue: e.target.value});
              this.doFiltr(e.target.value, validState, type);
            }}
            type='text'/>
        </Col>
        <Col sm={3}>
          <div style={{display: 'inline-block', marginRight: '10px'}}><ControlLabel>
            Platnost
          </ControlLabel></div>
          <DropdownButton id='validState'
                          onSelect={(key) => {
                            this.setState({ validState: key });
                            this.doFiltr(filterValue, key, type);
                          }}
                          title={stateNames[this.state.validState]}>
            <MenuItem eventKey='all'>{stateNames.all}</MenuItem>
            <MenuItem eventKey='valid'>{stateNames.valid}</MenuItem>
            <MenuItem eventKey='warning'>{stateNames.warning}</MenuItem>
            <MenuItem eventKey='notvalid'>{stateNames.notvalid}</MenuItem>
          </DropdownButton>
        </Col>
        <Col>
          <div style={{display: 'inline-block', marginRight: '10px'}}><ControlLabel>
            Typ
          </ControlLabel></div>
          <DropdownButton id='type'
                          onSelect={(key) => {
                            this.setState({ type: key });
                            this.doFiltr(filterValue, validState, key);
                          }}
                          title={typeNames[this.state.type]}>
            {Object.keys(typeNames).map(key => <MenuItem key={key} eventKey={key}>
              {typeNames[key]}
            </MenuItem>)}
          </DropdownButton>
        </Col>

      </Row>
    </div>)
  }

  doFiltr(value, valid, type) {
    this.props.onFilter({
      where: {
        $or: [
          { identifier: { $like: `%${value}%` },},
          { number: { $like: `%${value}%` },}
        ],
        validDate: valid, // Server is translating key into date ranges !!
        type: (type === '0' ? undefined : (type - 1))
      }
    })
  }
}

export default connect(mapAuthStateToProps)(CreditCardFilter);