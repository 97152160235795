import {IDiet, ISalary} from "../../users/Salary";
import React from "react";

interface Props {
  diets: IDiet[],
  salary?: ISalary | null,
  noWorkHolidayBonus: string[],
}

const SalaryNoWorking = (props: Props) => {
  const { noWorkHolidayBonus, salary, diets } = props;
  return <tr>
    <td>Náhrada svátku</td>
    <td></td>
    <td>{noWorkHolidayBonus.length}</td>
    <td>0</td>
    <td>0</td>
    <td>{noWorkHolidayBonus.length * 8}</td>
    <td>0</td>
    <td>{noWorkHolidayBonus.length * 8}</td>
    <td>0</td>
    <td>{salary ? (noWorkHolidayBonus.length * 8 * salary.pricePerHour) : 0}</td>
    <td>0</td>
    <td>0</td>
    <td>0</td>
    <td>0</td>
    <td>0</td>
    {diets.map(diet => {
      return <td key={diet.price}>0</td>
    })}
    <td>0</td>
    <td>0</td>
    <td>{salary ? (noWorkHolidayBonus.length * 8 * salary.pricePerHour) : 0}</td>
  </tr>
}

export default SalaryNoWorking;