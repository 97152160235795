import React, { Component } from 'react';
import {Button} from 'react-bootstrap';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

export default class SubmitButton extends Component {
  render() {
    let toRender = <Button disabled={this.props.disabled}
                           onClick={(e) => {
                             if (this.props.onClick)
                               this.props.onClick(e);
                           }}
                           bsClass={this.props.bsClass}
                           type={this.props.onClick ? "button" : "submit"}>{this.props.children}</Button>;

    if (this.props.loading) {

      toRender = <ReactLoading type={'spinningBubbles'}
                               color={'#337ab7'}
                               height={this.props.loadH}
                               width={this.props.loadW}/>
    }
    return(
      <div style={this.props.style}>
        {toRender}
      </div>
    )
  }
}

SubmitButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadH: PropTypes.number,
  loadW: PropTypes.number,
  bsClass: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

SubmitButton.defaultProps = {
  loadH: 40,
  loadW: 40,
  style: {},
  onClick: null,
};
