import React, { Component } from "react";
import {Button, Checkbox, FormControl, Modal} from "react-bootstrap";
import {rowStateColors, rowStateColorsOdd} from "../components/datarowBase";

interface Group {
  name: string;
  id: number;
  noGroup: boolean;
  price: number;
  totalPrice: number;
  fixedPrice: boolean;
  enabled: boolean;
  missing?: boolean;
}

interface State {
  groups: Group[];
  noRecount: boolean;
}

interface Props {
  groups: Group[];
  onCloseClick: () => {};
  onDone: (groups: Group[]) => void;
  onUpdate?: (groups: Group[]) => void;
  show?: boolean;
  disable?: boolean;
  totalPrice: number;
  noRecount?: boolean;
}

class GroupItem extends Component<{
  group: Group,
  onGroupChange: (group: Group) => void,
  disable?: boolean,
  noRecount?: boolean;
}> {
  render() {
    const { group, disable } = this.props;
    return <tr style={{ backgroundColor: group.missing ? rowStateColors[0] : '',}}>
      <td style={{ maxWidth: '300px', whiteSpace: 'unset', wordBreak: 'break-word' }}>{group.name}</td>
      <td>
        <FormControl type="number"
                     disabled={group.fixedPrice || disable || group.missing}
                     // @ts-ignore
                     onFocus={(e) => e.target.select()}
                     min={0}
                     value={group.price}
                     onChange={(e) => {
                       const val = (e.target as HTMLInputElement).value
                       if (val === '') {
                         // @ts-ignore
                         e.target.select()
                       }
                       const g = {...group};
                       g.price = parseFloat(val === '' ? "0" : val);
                       this.props.onGroupChange(g);
                     }}/>
      </td>
      <td>
        <Checkbox checked={group.fixedPrice}
                  disabled={disable || group.missing}
                  onChange={(e) => {
                    const g = {...group};
                    g.fixedPrice = (e.target as HTMLInputElement).checked;
                    this.props.onGroupChange(g);
                  }}
        >
        </Checkbox>
      </td>
      <td>
        <Checkbox checked={group.enabled}
                  disabled={disable}
                  onChange={(e) => {
                    const g = {...group};
                    g.enabled = (e.target as HTMLInputElement).checked;
                    this.props.onGroupChange(g);
                  }}
        >
        </Checkbox>
      </td>
    </tr>;
  }
}


export default class BudgetModal extends Component<Props, State> {
  static defaultProps = {
    show: true,
    totalPrice: 0,
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      groups: props.groups,
      noRecount: props.noRecount === true,
    }
  }

  groupChange = (key: number) => (group: Group) => {
    const { noRecount } = this.state;
    const groups = this.state.groups.slice();
    if (!group.enabled && groups[Number(key)].enabled) {
      group.price = 0;
      group.fixedPrice = false;
    }
    if (!noRecount) {
      const countFixedPrice = (group.fixedPrice ? 0 : 1) + groups.filter(o => (o.fixedPrice || !o.enabled) && o.id !== group.id).length;
      if (countFixedPrice === groups.length || (!groups[Number(key)].enabled && !group.enabled)
        || (groups[Number(key)].fixedPrice && group.fixedPrice)) {
        return;
      }
      const priceDiff = (group.price - groups[key].price);
      const pricePiece = priceDiff / (groups.length - countFixedPrice);
      Object.keys(groups).map(k => {
        if (Number(k) !== key && !groups[Number(k)].fixedPrice && groups[Number(k)].enabled) {
          groups[Number(k)].price -= pricePiece;
        }
      })
    }

    groups[key] = group;

    const sum = groups.filter(o => !o.missing).map(o => o.price).reduce((sum, act) => sum += act);
    const checkDiff = this.props.totalPrice - sum;

    this.props.onUpdate && this.props.onUpdate(groups);
    this.setState({ groups });
  }

  render() {
    const { groups, noRecount } = this.state;
    const { totalPrice } = this.props;
    const sum = groups.length > 0 ? groups.filter(o => !o.missing).map(o => o.price).reduce((sum, act) => sum += act) : 0;
    return <Modal dialogClassName="modal-60w-margin" show={this.props.show} onHide={this.props.onCloseClick}>
      <Modal.Header closeButton>
        <h3>Rozdělení ceny mezi skupiny</h3>
      </Modal.Header>
      <Modal.Body>
        <div className='text-center'>
          <Checkbox checked={noRecount}
                    disabled={this.props.disable}
                    onChange={(e) => {
                      this.setState({
                        noRecount: (e.target as HTMLInputElement).checked,
                      })
                    }}
          >
            Zakázat přepočítávání
          </Checkbox>
          <div className='dataTable' style={{ marginTop: '10px',
            width: '100%',
            maxHeight: '500px',
            overflowY: 'auto'
          }}>
            <table>
              <thead>
              <th>Jméno skupiny</th>
              <th>Cena</th>
              <th>Fixace</th>
              <th>Použít</th>
              </thead>
              <tbody>
              {Object.keys(groups).map(key => <GroupItem
                noRecount={noRecount}
                disable={this.props.disable && !groups[Number(key)].missing}
                key={key}
                onGroupChange={this.groupChange(Number(key))}
                group={groups[Number(key)]}/>)}
              </tbody>
            </table>
          </div>
          <div style={{ marginTop: '5px', color: totalPrice !== sum ? 'red' : '' }}>{`Kontrolní součet ${sum} / ${totalPrice}`}</div>
          {/*<p>Po stisku tlačítka "Dokončit" dojde k nahrazení rovnice příslušných řádků a rozpočtu množství</p>*/}
          <Button disabled={totalPrice !== sum} style={{ marginTop: '10px' }} bsClass='btn btn-primary' onClick={() => this.props.onDone(groups)}>
            Dokončit rozpočet
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  }
}