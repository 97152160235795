import moment from "moment";
import {generateValueIfIsNotForRanges, getRangeIndex} from "../../../AppObjects";
import store from "../../../redux/store";
import {show} from "react-notification-system-redux";
import React from "react";
import {Attributes, DataRow, Orders} from "@cml/models";
import {OrderOther, IPriceAttributesCustom,
  BaseCalculationItem, Material, OrderOtherPrice} from "@cml/types";
import {AuthenticateState} from "@cml/redux-store";


export const createDataRow = (defaultValues: Partial<DataRow>) => {
  let item: Partial<DataRow> = {
    id: -1, // workaround when new rows are added id causing render, when it is not there render is not called (strange needs to be investigated further)
    company: '',
    construction: '',
    loadingGeo: {
      location: '',
      latitude: 0,
      longitude: 0,
    },
    unloadingGeo: {
      location: '',
      latitude: 0,
      longitude: 0,
    },
    smsInfo: {
      contactUserSmsDate: null,
      contactUserSmsId: null,
      driverUserSmsDate: null,
      driverUserSmsId: null,
    },
    loading: '',
    unloading: '',
    fraction: '',
    orderForeignId: null,
    from: 0,
    to: 0,
    break: 0,
    costs: 0,
    addressBookId: 0,
    contactUserId: null,
    userRaw: '',
    note: '',
    vehicleRaw: '',
    priceEq: [],
    userId: 0,
    userId_a: 0,
    isConfirmed: 0,
    orderForVis: 0,
    subOriginVehicleId: 0,
    vehicleId: 0,
    vehicleId_a: 0,
    attributeId: 0,
    tools: [],
    date: undefined,
    orderNumber: 0,
    incomeInvoiceForeignId: null,
    invoiceForeignId: null,
    orderId: null,
    spaceId: (store.getState().authenticate as AuthenticateState).activeSpaceId,
  };

  return {
    ...item,
    ...defaultValues,
  };
}

export const rowStates: {[key: number]: string} = {
  0: 'Založeno',
  1: 'Aktivní',
  2: 'Aktivní (bez SMS)',
  3: 'Evidováno',
};

export const rowStatesSupp: {[key: number]: string} = {
  0: 'Založeno',
  1: 'Aktivní (s obj.)',
  2: 'Aktivní (bez obj.)',
  3: 'Evidováno',
};

export const rowStateColors: {[key: number]: string} = {
  0: 'rgba(255, 0, 0, 0.4)',
  1: 'rgba(244, 176, 66, 0.4)',
  2: 'rgba(244, 176, 66, 0.4)',
  3: 'rgba(0, 195, 0, 0.4)',
};

export const rowStateColorsOdd: {[key: number]: string} = {
  0: 'rgba(255, 0, 0, 0.2)',
  1: 'rgba(244, 176, 66, 0.2)',
  2: 'rgba(244, 176, 66, 0.2)',
  3: 'rgba(0, 195, 0, 0.2)',
};

interface IFillOrderOptions {
  contactUserId?: boolean;
  company?: boolean;
  loading?: boolean;
  unloading?: boolean;
  fraction?: boolean;
  construction?: boolean;
  time?: boolean;
  price?: boolean;
}

export const fillOrder = (data: DataRow,
                          priceAttributes: Attributes[],
                          order: Orders,
                          options?: IFillOrderOptions) => {
  if (typeof options === 'undefined')
    options = {
      contactUserId: true,
      company: true,
      loading: true,
      unloading: true,
      fraction: true,
      construction: true,
      time: true,
      price: true,
    }
  if (options.contactUserId)
    data.contactUserId = order.contactUserId;
  if (options.company)
    data.company = order.company;
  if (options.loading)
    data.loading = order.loading;
  if (options.unloading)
    data.unloading = order.unloading;
  if (options.fraction)
    data.fraction = order.fraction;
  if (options.construction)
    data.construction = order.construction;

  if (options.time) {
    const defTime = (order.other as OrderOther).dayWorkTime[moment(data.date).day()];
    data.from = defTime.from;
    data.to = defTime.to;
    data.break = defTime.break;
  }

  if (options.price) {
    return fillPrice(data, order, priceAttributes, data.vehicleId as number);
  } else {
    return data;
  }

};

export const fillPrice = function(data: DataRow, order: Orders, priceAttributes: Attributes[], vehicleId: number) {
  if (order) {
    const orderPrices = (order.other as OrderOther).prices.filter(o => o.enabled);
    const selVehiclePrice = (order.other as OrderOther).vehiclePrices.find(o => o.id === vehicleId);
    const defPrices: {attributeId: number, price: number | number[]}[] = selVehiclePrice ? (selVehiclePrice.priceEq.length > 0 ? selVehiclePrice.priceEq : orderPrices) : orderPrices;
    if (priceAttributes.length > 0) {
      if (!data.priceEq)
      { // @ts-ignore
        data.priceEq = [];
      }
      defPrices.forEach((p) => {
        const price = (data.priceEq as BaseCalculationItem[]).find(o => o.attributeId === p.attributeId);
        if (price) {
          const attr = priceAttributes.find(o => o.id === price.attributeId) as Attributes;
          const attrCustomOptions = attr.customOptions as IPriceAttributesCustom;
          if (attrCustomOptions.isHourAttribute) {
            price.amount = getTimeDiff(data).sum;
          }
          //if (!attr.customOptions.isHourAttribute) { // If
          price.price = p.price;
          //}
          if (attrCustomOptions.useKmRange) {
            price.price = generateValueIfIsNotForRanges(price.price, attrCustomOptions.rangeList);
            const i = getRangeIndex(price.amount, attrCustomOptions.rangeList);
            price.total = (attrCustomOptions.rangeList[i].fixed ? 1 : price.amount) * (price.price as number[])[i];
          } else {
            price.total = price.amount * (price.price as number);
          }
        } else {
          const attr = priceAttributes.find(o => o.id === p.attributeId);
          if (!attr) {
            store.dispatch(show({
              title: 'Chyba !',
              level: 'error',
              autoDismiss: 15,
              children: (
                <div>{ `V zakázce ${
                  order.label} je u právě vybraného vozidla neexistující atribut ceny ${(p as OrderOtherPrice).attributeName}` }</div>
              ),
            }, 'error'));
            return;
          }
          const attrCustomOptions = attr.customOptions as IPriceAttributesCustom;
          let total = (attrCustomOptions.isHourAttribute ? getTimeDiff(data).sum : 0) * (p.price as number);
          if (attrCustomOptions.useKmRange) {
            total = 0;
          }
          (data.priceEq as BaseCalculationItem[]).push({
            attributeId: attr.id,
            isMaterial: false,
            price: p.price,
            costPrice: attrCustomOptions.useKmRange ? attrCustomOptions.rangeList.map( o => 0) : 0,
            amount: attrCustomOptions.isHourAttribute ? getTimeDiff(data).sum : 0,
            total: total,
            costTotal: 0
          });
        }
      });
      let i = 0;
      (data.priceEq as BaseCalculationItem[]).forEach(p => {
        const index = defPrices.findIndex(o => o.attributeId === p.attributeId);
        if (index === -1) {
          (data.priceEq as BaseCalculationItem[]).splice(i, 1);
        }
        i++;
      })
    }
  }
  return data;
};

export const recalculate = function(data: DataRow, priceAttributes: Attributes[]) {
  const sum = getTimeDiff(data).sum;
  if (data.priceEq !== null) {
    (data.priceEq as BaseCalculationItem[]).forEach((item) => {
      const index = priceAttributes.findIndex(o => o.id === item.attributeId);
      const pA = priceAttributes[index];
      const pACustomOption = pA.customOptions as IPriceAttributesCustom
      if (index > -1) {
        if (pACustomOption.isHourAttribute) {
          item.amount = sum;
          item.total = (item.price as number) * sum;
          item.costTotal = (item.costPrice as number) * sum;
        }
      }
      if (pACustomOption.useKmRange) {
        item.price = generateValueIfIsNotForRanges(item.price, pACustomOption.rangeList);
        item.costPrice = generateValueIfIsNotForRanges(item.costPrice, pACustomOption.rangeList);
        const i = getRangeIndex(item.amount, pACustomOption.rangeList);
        if (pACustomOption.rangeList[i].multipleByHours) {
          item.total = (item.price as number[])[i] * sum;
          item.costTotal = (item.costPrice as number[])[i] * sum;
        }
      }
    });
  }

  return {
    ...data,
    priceEq: data.priceEq ? data.priceEq.slice() : [],
  } as DataRow;
}

export const getTimeDiff = (data: {
  from: number;
  to: number;
  break: number;
}) => {
  let sum = (data.to - data.from) - data.break;
  let overNight = false;
  if (sum < 0) {
    sum += 24;
    overNight = true;
  }
  return {
    sum,
    overNight,
  }
}

export const getStore = function(data: {materialTable: Material[] | string}, storeAttributes: Attributes[]) {
  const storeIds = [...new Set(data.materialTable ? (data.materialTable as Material[]).map(o => o.storeId) : [])];
  const oneStore = storeIds.length === 1 ? storeAttributes.find(o => o.id === storeIds[0]) : null
  return oneStore;
}