import React, { Component } from 'react';
import {dateFormatVisible, dateFromat, HeaderNames, mapAuthStateToProps} from "../../AppObjects";
import {BackHeader} from "../Setting";
import {Button, Checkbox, Col, ControlLabel, Row} from "react-bootstrap";
import {LoadingOrRender} from "../../loginsrc/Profile";
import request from 'axios';
import moment from "moment";
import FontAwesome from "react-fontawesome";
import {DateRangePicker} from "react-dates";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import MainTable from "./components/MainTable";
import VehicleData from "./components/VehicleData";
import {CanView} from "../Main";
import TanksDetail from "./components/TanksDetail";
import SuspiciousLeaks from "./components/SuspiciousLeaks";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import {connect} from "react-redux";

class PHM extends Component{
  constructor(props) {
    super(props);
    this.state = {
      vehicleId: 0,
      requestActive: false,
      requestVehicleData: false,
      requestVehiclePosition: false,
      importActive: false,
      vehicleData: [],
      phmData: [],
      records: [],
      tankIndexes: [],
      unresolvedTankIndexes: [],
      startDate: null,
      endDate: null,
      focusedInput: null,
      showUnresolved: false,
      showAllVehiclePositions: false,
      mapHeaderText: 'Mapa',
    };
    this.unresolvedItems = [];
    this.records = [];
    this.markers = [];
    this.unresolvedComponents = React.createRef();
  }

  componentDidMount() {
    /*global google*/
    setTimeout(() => { // Give time to load google scripts
      let marker;
      let parliament = new google.maps.LatLng(49.835140, 18.290030);
      let myOptions = {
        zoom: 12, center: parliament, mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);

    },200);
  }

  setMarkersMap(map) {
    /*global google*/
    let bounds  = new google.maps.LatLngBounds();
    this.markers.map((marker, i) => {
      marker.setMap(map);
      marker.addListener('click', () => {
        /*this.selectMarker(i);
        this.requestVehicleData(this.records[i].timestamp, this.records[i].vehicleId);
        this.setState({selRecordIndex: i})*/
      });
      bounds.extend(new google.maps.LatLng(marker.position.lat(), marker.position.lng()));
    });
    if (map) {
      map.fitBounds(bounds);
      map.panToBounds(bounds);
      const zoom = map.getZoom();
      map.setZoom(zoom > 16 ? 16 : zoom)
    }
  }

  selectMarker(index) {
    this.markers.map((marker,i) => {
      marker.setIcon(`http://maps.google.com/mapfiles/ms/icons/${index === i ? 'red' : 'blue' }-dot.png`);
      marker.setZIndex(index === i ? 1000 : 1);
      //marker.url.icon = `http://maps.google.com/mapfiles/ms/icons/${index === i ? 'red' : 'blue' }-dot.png`
    });
    this.map.panTo(this.markers[index].position);

  }

  generateNewMarkers() {
    /*global google*/
    let markers = [];
    this.records.forEach(r => {
      markers.push(new google.maps.Marker({
        icon: {
          url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
        },
        position: new google.maps.LatLng(r.positionLatitude, r.positionLongitude), map: this.map, title: `
Vozidlo: ${r.identifier}
Datum: ${moment(r.timestamp).format(dateFormatVisible + ' HH:mm:ss')}
Nádrž: ${r.Consuption} %
        `,
      }))
    });
    this.markers = markers;
  }

  requestVehicleData(timestamp, vehicleId) {
    const stamp = moment(timestamp);
    request.get('/items/volvotrackingdata', {
      params: {
        where: {
          timestamp: {
            $between: [stamp.add(-2, 'days').format(dateFromat), stamp.add(5, 'days').format(dateFromat)]
          },
          vehicleId: vehicleId,
        }
      }
    })
      .then(res => {
        this.setState({ vehicleData: res.data});
      })
  }

  requestVehicleData2(vehicleId, identifier) {
    this.setState({ requestVehicleData: true });
    request.get('/items/volvotrackingdata', {
      params: {
        where: {
          timestamp: {
            $between: [this.state.startDate.format(dateFromat), this.state.endDate.format(dateFromat)]
          },
          vehicleId: vehicleId,
        },
      }
    })
      .then(res => {
        request.get('/items/getfishilyleaks',
          {
            params: {
              vehicleId,
              from:  this.state.startDate.format(dateFromat),
              to: this.state.endDate.format(dateFromat),
            }
          })
          .then(resLeaks => {
            const ratio = Math.ceil(res.data.length / 450);
            const data = res.data.filter((val, i) => i % ratio === ratio -1);
            const indexes = [];
            const unresolvedIndexes = [];
            const { phmData } = this.state;
            const tanks = phmData[identifier].list;

            Object.keys(tanks).map(key => {
              const date = moment(tanks[key].date);
              for (let i = 0; i < data.length ; i++) {
                if (moment(data[i].timestamp).isAfter(date) || (i === data.length - 1)) {
                  indexes.push({
                    index: i,
                    unresolved: false,
                    ...tanks[key],
                  });
                  break;
                }
              }
            });
            this.unresolvedItems.forEach(rec => {
              const date = moment(rec.date);
              for (let i = 0; i < data.length ; i++) {
                if (moment(data[i].timestamp).isAfter(date) || (i === data.length - 1)) {
                  unresolvedIndexes.push({
                    index: i,
                    unresolved: true,
                    ...rec,
                  });
                  break;
                }
              }
            });
            const suspiciousLeaks = resLeaks.data;
            suspiciousLeaks.forEach(leak => {
              const date = moment(leak.prevTime);
              for (let i = 0; i < data.length ; i++) {
                if (moment(data[i].timestamp).isAfter(date) || (i === data.length - 1)) {
                  leak.index = i;
                  break;
                }
              }
            });
            this.setState({
              records: resLeaks.data,
              requestVehicleData: false,
              vehicleData: data,
              tankIndexes: indexes.sort((a,b) => a.index - b.index),
              unresolvedTankIndexes: unresolvedIndexes.sort((a,b) => a.index - b.index),
            });
          })
          .catch(err => {
            this.setState({requestVehicleData: false});
          })

      })
  }

  importUta(item) {
    if (typeof item.target.files[0] === 'undefined')
      return;
    const file = item.target.files[0];
    let formData = new FormData();
    formData.append(file.name, file);
    item.target.value = '';

    this.setState({importActive: true});
    request.post('/items/items/utaxlsx', formData)
      .then(res => {
        this.setState({importActive: false});
        store.dispatch(show({
          title: 'Info',
          level: 'info',
          autoDismiss: 3,
          children: (
            <div>
              Import byl v pořádku proveden
            </div>
          ),
        }, 'info'))
      })
      .catch(err => {
        this.setState({importActive: false});
      })
  }

  importShellCsv(item) {
    if (typeof item.target.files[0] === 'undefined')
      return;
    const file = item.target.files[0];
    let formData = new FormData();
    formData.append(file.name, file);
    item.target.value = '';

    this.setState({importActive: true});
    request.post('/items/items/shellcsv', formData)
      .then(res => {
        this.setState({importActive: false});
        store.dispatch(show({
          title: 'Info',
          level: 'info',
          autoDismiss: 3,
          children: (
            <div>
              Import byl v pořádku proveden
            </div>
          ),
        }, 'info'));
      })
      .catch(err => {
        this.setState({importActive: false});
      })
  }

  checkDate() {
    if (this.state.endDate === null || this.state.startDate === null) {
      this.setState({
        focusedInput: this.state.startDate !== null ? 'endDate' : 'startDate'
      });
      store.dispatch(show({
        title: 'Chyba',
        level: 'error',
        autoDismiss: 3,
        children: (
          <div>
            Vyberte prosím období
          </div>
        ),
      }, 'error'));
      return false;
    }
    return true;
  }

  compareBalance() {
    if (!this.checkDate()) {
      return;
    }
    this.setState({
      vehicleData: [],
      phmData: [],
      tankIndexes: [],
    });
    const from = this.state.startDate.format(dateFromat);
    const to = this.state.endDate.format(dateFromat);

    this.setState({requestActive: true});
    request.get('/items/getphmbalance',
      {
        params: {
          from: from,
          to: to,
        }
      })
      .then(res => {
        request.get('/items/items', {
          params: {
            where: {
              itemId: null,
              spaceId: this.props.authenticate.activeSpaceId,
              itemTableName: 'vehicles',
              type: 1,
              date: {
                $between: [from, to],
              }
            }
          }
        }).then((items) => {
          this.unresolvedItems = items.data;
          const unresolvedIndexes = [];
          this.unresolvedItems.map((rec, i) => {
            unresolvedIndexes.push({
              index: i,
              unresolved: true,
              ...rec,
            });
          });
          this.setState({requestActive: false});
          let phmData = res.data.reduce((resultArray, item, index) => {
            if (!resultArray[item.identifier]) {
              resultArray[item.identifier] = {
                list: [],
                sum: 0,
              }
            }
            resultArray[item.identifier].list.push(item);
            resultArray[item.identifier].sum += parseFloat(item.amount);
            return resultArray;
          }, []);
          this.setState({
            requestActive: false,
            phmData,
            unresolvedTankIndexes: unresolvedIndexes.sort((a,b) => a.index - b.index),
          });
        });
      })
      .catch(err => {
        this.setState({requestActive: false});
      })
  }

  showPositionOfLeak(index) {
    const r = this.state.records[index];
    this.setMarkersMap(null);
    let markers = [];
    markers.push(new google.maps.Marker({
      icon: {
        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
      },
      position: new google.maps.LatLng(r.positionLatitude, r.positionLongitude), map: this.map, title: `
Vozidlo: ${r.identifier}
Datum: ${moment(r.timestamp).format(dateFormatVisible + ' HH:mm:ss')}
Nádrž: ${r.Consuption} %
        `,
    }));
    markers.push(new google.maps.Marker({
      icon: {
        url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
      },
      position: new google.maps.LatLng(r.prevPositionLatitude, r.prevPositionLongitude), map: this.map, title: `
Vozidlo: ${r.identifier}
Datum: ${moment(r.prevTime).format(dateFormatVisible + ' HH:mm:ss')}
Nádrž: ${r.Consuption} %
        `,
    }));
    this.markers = markers;
    this.setMarkersMap(this.map);
    this.setState({
      mapHeaderText: 'Mapa - Pokles v nádrží',
    });
  }

  showPositionOfTankAndVehicle(index, unresolved) {
    const { vehicleData } = this.state;
    this.setMarkersMap(null);
    let markers = [];
    const tankIndexes = unresolved ? this.state.unresolvedTankIndexes : this.state.tankIndexes;
    request.get('/items/getVehiclePositionByTimeStamp', {params:{
        timestamp: moment(tankIndexes[index].date).add(-2, 'hours').format(dateFromat + ' HH:mm:ss')
      }}).then((res) => {
        res.data.forEach(veh => {
          if (this.state.showAllVehiclePositions || tankIndexes[index].identifier === veh.identifier) {
            markers.push(new google.maps.Marker({
              icon: {
                url: `http://maps.google.com/mapfiles/ms/icons/${tankIndexes[index].identifier === veh.identifier ? 'blue' : 'red'}-dot.png`
              },
              position: new google.maps.LatLng(veh.positionLatitude,
                veh.positionLongitude), map: this.map, title: `
Vozidlo: ${veh.identifier}
Datum: ${moment(veh.timestamp).add(0, 'hours').format(dateFormatVisible + ' HH:mm:ss')}
        `,
            }));
          }
        });
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode( { 'address': `${tankIndexes[index].name.split(" ")[0]} ${tankIndexes[index].location}`}, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            markers.push(new google.maps.Marker({
              icon: {
                url: "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"
              },
              position: results[0].geometry.location, map: this.map, title: `
  Stanice: ${tankIndexes[index].location}
  Datum: ${moment(tankIndexes[index].date).format(dateFormatVisible + ' HH:mm:ss')}
          `,
            }));
          }
          this.markers = markers;
          this.setMarkersMap(this.map);
          this.setState({
            mapHeaderText: 'Mapa - Poloha vozidla/vozidel vůči poloze tankování',
          });
        });
      });
  }

  render() {
    const { requestActive, importActive, phmData, tankIndexes, unresolvedTankIndexes, vehicleData } = this.state;
    const temp = tankIndexes.find(o => !o.unresolved);
    const actualIdentifier = temp ? temp.identifier : '';
    return <ContentWithFixedHeader baseMargin={60} header={
      <Row>
        <Col sm={2}>
          <BackHeader
            headerText='PHM'
            fontSize='18px'
            onBackClick={() => this.props.history.replace(
              this.props.match.url.split('/phm')[0])}/>
        </Col>
        <Col sm={4}>
          <ControlLabel style={{ marginRight: '10px' }}>Období</ControlLabel>
          <DateRangePicker
            displayFormat={ dateFormatVisible }
            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
            startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
            startDatePlaceholderText='Začátek'
            endDatePlaceholderText='Konec'
            isOutsideRange = {() => false}
            small
            minimumNights={0}
            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
            endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => this.setState({startDate, endDate})} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
          />
          <LoadingOrRender requestActive={requestActive}>
            <Button onClick={() => this.compareBalance()}>
              Vyhledat
            </Button>
          </LoadingOrRender>

        </Col>
        <Col sm={3}>
          <Checkbox checked={this.state.showUnresolved}
                    onChange={(e) => this.setState({ showUnresolved: !this.state.showUnresolved })}>Zobrazovat nepřiřazené</Checkbox>
          <Checkbox checked={this.state.showAllVehiclePositions}
                    onChange={(e) => this.setState({ showAllVehiclePositions: !this.state.showAllVehiclePositions })}>Zobrazovat Všechna vozidla na mapě při lokalizaci tankování</Checkbox>
        </Col>
        <Col sm={3}>
          <LoadingOrRender requestActive={importActive}>
            <div>
              <div style={{display: 'inline-block'}}>
                <input onChange={(e) => this.importShellCsv(e)}
                       id='newFile' type='file'
                       accept='.csv' className='inputfile'/>
                <label htmlFor='newFile'>
                  <FontAwesome style={{ marginRight: '10px', fontSize: '16px', color: '#055ADA' }} name='fas fa-plus-circle' />
                  Import Shell csv
                </label>

              </div>
              <div style={{display: 'inline-block'}}>
                <input onChange={(e) => this.importUta(e)}
                       id='newUtaFile' type='file'
                       accept='.csv,.xlsx' className='inputfile'/>
                <label htmlFor='newUtaFile'>
                  <FontAwesome style={{ marginRight: '10px', fontSize: '16px', color: '#055ADA' }} name='fas fa-plus-circle' />
                  Import UTA csv
                </label>
              </div>
            </div>
          </LoadingOrRender>
        </Col>
      </Row>
      }>
        <div className='fixHowirizonalScroll' style={{ marginLeft: '20px' }}>
          <Row>

            <Col md={7} sm={12}>
              <h3>Hlavní přehledová tabulka</h3>
              <MainTable phmData={phmData} rowSelected={(vehicleId, identifier) => this.requestVehicleData2(vehicleId, identifier)}/>
            </Col>
            <Col md={5} sm={12}>
              <CanView visible={unresolvedTankIndexes.length > 0 || this.state.requestActive}>
                <LoadingOrRender requestActive={ this.state.requestActive }>
                  <h3 style={{marginTop: '20px'}}>{`Nepřiřazené tankování`}</h3>
                  <TanksDetail onRowClick={(index) => this.showPositionOfTankAndVehicle(index, true)} tankIndexes={unresolvedTankIndexes} vehicleData={vehicleData} />
                </LoadingOrRender>
              </CanView>
            </Col>
            <Col sm={12} md={7}>
              <CanView visible={vehicleData.length > 0 || this.state.requestVehicleData}>
                <LoadingOrRender requestActive={ this.state.requestVehicleData }>
                  <div style={{height: 400}}>
                    <h4 style={{marginTop: '20px', marginBottom: '-20px'}}>{`Data: ${actualIdentifier}`}</h4>
                    <VehicleData unresolvedTankIndexes={unresolvedTankIndexes}
                                 showUnresolved={this.state.showUnresolved}
                                 suspiciousLeaks={this.state.records}
                                 tankIndexes={tankIndexes} vehicleData={this.state.vehicleData}/>
                  </div>
                </LoadingOrRender>
              </CanView>
            </Col>
            <Col md={5} sm={12}>
              <CanView visible={tankIndexes.length > 0 || this.state.requestVehicleData}>
                <LoadingOrRender requestActive={ this.state.requestVehicleData }>
                  <h4 style={{marginTop: '20px'}}>{`Tankování: ${actualIdentifier}`}</h4>
                  <TanksDetail onRowClick={(index) => this.showPositionOfTankAndVehicle(index, false)} tankIndexes={tankIndexes} vehicleData={vehicleData} />
                </LoadingOrRender>
              </CanView>
            </Col>
            <Col sm={12} md={7}>
              <CanView visible={vehicleData.length > 0 || this.state.requestVehicleData}>
                <LoadingOrRender requestActive={ this.state.requestVehicleData }>
                  <h4 style={{marginTop: '20px'}}>{`Podezřelé poklesy v nádrži: ${actualIdentifier}`}</h4>
                  <SuspiciousLeaks onRowClick={(index) => this.showPositionOfLeak(index)} records={this.state.records} />
                </LoadingOrRender>
              </CanView>
            </Col>
            <Col md={12}>
              <h4>{this.state.mapHeaderText}</h4>
              <div id="map_canvas" style={{width:'100%', height:'500px'}}></div>
            </Col>
          </Row>
        </div>
      </ContentWithFixedHeader>
  }
}
export default connect(mapAuthStateToProps)(PHM);