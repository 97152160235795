import React, { Component } from 'react';
import { BaseEditPropTypes } from '../CommonListContainer';
import request from 'axios';
import {BackHeader} from "../Setting";
import {
  Col,
  Form,
  FormGroup,
  Row,
  ControlLabel, FormControl, Button, Checkbox, Dropdown, MenuItem
} from "react-bootstrap";
import {LoadingOrRender} from "../../loginsrc/Profile";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import SubmitButton from "../../loginsrc/SubmitButton";
import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import {CanView} from "../Main";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import ItemsSelector from "../ItemsSelector";
import AddressBookEdit from "../addressBook/Edit";
import NumberQueueGenerator from "../utils/NumberQueueGenerator";
import {DietsTable} from "../users/Salary";

const headers = {
  pohoda: 'POHODA',
  orderEmail: 'Objednávkový email',
  volvoApi: 'Volvo dynafleet',
  webDisApi: 'Webdispečink API',
  visionLinkApi: 'Vision link API',
  utaFTP: 'UTA FTP',
  shellApi: 'SHELL API',
  nexmo: 'NEXMO SMS',
  integromatSms: 'INTEGROVAT SMS',
};

class Connection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testActive: false,
      vehicleLoading: false,
      connectionState: 0,
    }
  }

  handleChange(index, item) {
    this.setState({
      connectionState: 0,
    });
    this.props.onChangeConnection(index, item);
  }

  render() {
    const {index, item} = this.props;
    let connState = '';
    switch (this.state.connectionState) {
      case 0:
        connState = 'default';
        break;
      case 1:
        connState = 'success';
        break;
      case 2:
        connState = 'danger';
        break;
      default:
        break;
    }
    let other = null;
    const loadVehicles = <>
      <Col sm={2} componentClass={ControlLabel}>
        Automatické načtení vozidel
      </Col>
      <Col sm={10}>
        <LoadingOrRender requestActive={this.state.vehicleLoading}>
          <Button onClick={() => {
            this.setState({vehicleLoading: true});
            request.get('/items/loadvehicles/' + item.connName)
              .then(res => {
                store.dispatch(show({
                  title: 'Info !',
                  level: 'info',
                  autoDismiss: 3,
                  children: (
                    <div>
                      Vozidla úspěšně synchronizována
                    </div>
                  ),
                }, 'info'));
                this.setState({vehicleLoading: false})
              })
              .catch(err => {
                this.setState({vehicleLoading: false});
              })
          }}>
            Načíst vozidla
          </Button>
        </LoadingOrRender>
      </Col>
    </>;
    switch (item.connName) {
      case 'pohoda':
        other = <>
          <Col sm={2} componentClass={ControlLabel}>
            Cesta k dokumentům
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.documentsPath}
                         onChange={(e) => {
                           item.other.documentsPath = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_documentsPath`}
                         placeholder='Cesta k dokumentům'/>
          </Col>
          <Col sm={2} componentClass={ControlLabel}>
            Cesta k programu (Pohoda.exe)
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.programPath}
                         onChange={(e) => {
                           item.other.programPath = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_programPath`}
                         placeholder='Cesta k programu (Pohoda.exe)'/>
          </Col>
          <Col sm={2} componentClass={ControlLabel}>
            Jméno mServeru
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.mServerName}
                         onChange={(e) => {
                           item.other.mServerName = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_mServerName`}
                         placeholder='Jméno mServeru'/>
          </Col>
          <Col sm={2} componentClass={ControlLabel}>
            Url Pohoda konektoru
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.connectorUrl}
                         onChange={(e) => {
                           item.other.connectorUrl = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_connectorUrl`}
                         placeholder='Url Pohoda konektoru'/>
          </Col>
        </>;
        break;
      case 'orderEmail':
        other = <>
          <Col sm={2} componentClass={ControlLabel}>
            Číslo portu
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.smtpPort}
                         onChange={(e) => {
                           item.other.smtpPort = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_smtpPort`}
                         placeholder='Číslo portu'/>
          </Col>
        </>;
        break;
      case 'volvoApi':
      case 'visionLinkApi':
        other = loadVehicles;
        break;
      case 'utaFTP':
        other = <>
          <Col sm={2} componentClass={ControlLabel}>
            Dodavatel
          </Col>
          <Col sm={10}>
            <ItemsSelector
              newEditComponent={ AddressBookEdit }
              filterEnable
              itemsToFetch={'addressBook'}
              defaultQuery={{ where: {spaceId: this.props.spaceId }}}
              defaultSelectedId={item.other.addressBookId}
              onListSplitterCondition={(index,item) => item.id !== 0}
              value={'companyWithIc'}
              onSelected={(address) => {
                item.other.addressBookId = address.id;
                this.handleChange(index, item);
              }}
              otherPreviewProps={{style: {display: 'inline'}}}/>
          </Col>
        </>
        break;
      case 'shellApi':
        other = <>
          <Col sm={2} componentClass={ControlLabel}>
            Číslo plátce
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.PayerNumber}
                         onChange={(e) => {
                           item.other.PayerNumber = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_PayerNumber`}
                         placeholder='Číslo plátce'/>
          </Col>
          <Col sm={2} componentClass={ControlLabel}>
            Dodavatel
          </Col>
          <Col sm={10}>
            <ItemsSelector
              newEditComponent={ AddressBookEdit }
              filterEnable
              itemsToFetch={'addressBook'}
              defaultSelectedId={item.other.addressBookId}
              onListSplitterCondition={(index,item) => item.id !== 0}
              value={'companyWithIc'}
              onSelected={(address) => {
                item.other.addressBookId = address.id;
                this.handleChange(index, item);
              }}
              otherPreviewProps={{style: {display: 'inline'}}}/>
          </Col>
        </>
        break;
      case 'webDisApi':
        other = <>
          <Col sm={2} componentClass={ControlLabel}>
            KodF
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.kodF}
                         onChange={(e) => {
                           item.other.kodF = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_kodf`}
                         placeholder='KodF'/>
          </Col>
          <Col sm={2} componentClass={ControlLabel}>
            ID Skupiny
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.groupId}
                         onChange={(e) => {
                           item.other.groupId = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_groupId`}
                         placeholder='ID Skupiny'/>
          </Col>
          {loadVehicles}
        </>;
        break;
      case 'nexmo':
        other = <>
          <Col sm={2} componentClass={ControlLabel}>
            Telefonní číslo
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.telnumb}
                         onChange={(e) => {
                           item.other.telnumb = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_telnumb`}
                         placeholder='Telefonní číslo'/>
          </Col>
          <Col sm={2} componentClass={ControlLabel}>
            Webhook
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.smsWebhook}
                         onChange={(e) => {
                           item.other.smsWebhook = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_smswebhook`}
                         placeholder='Webhook'/>
          </Col>
        </>;
      case 'integromatSms':
        other = <>
          <Col sm={2} componentClass={ControlLabel}>
            Telefonní číslo
          </Col>
          <Col sm={10}>
            <FormControl type='text'
                         value={item.other.telnumb}
                         onChange={(e) => {
                           item.other.telnumb = e.target.value;
                           this.handleChange(index, item);
                         }}
                         controlId={`${index}_telnumb`}
                         placeholder='Telefonní číslo'/>
          </Col>
        </>;
      break;
    }

    return <div className='row nest'>
      <Col sm={12}>
        <button className='text-right transparentButton' onClick={(e) => {
          e.preventDefault();
          this.props.onDeleteConnection(index)
        }}>
          <FontAwesome style={{fontSize:'25px', color: 'black', }} name='times' />
        </button>
      </Col>
      <h3 className='text-center'>
        {headers[item.connName]}
      </h3>
      <Col sm={2} componentClass={ControlLabel}>
        Uživatelské jméno
      </Col>
      <Col sm={10}>
        <FormControl type='text'
                     value={item.connUser}
                     onChange={(e) => {
                       item.connUser = e.target.value;
                       this.handleChange(index, item);
                     }}
                     controlId={`${index}_name`}
                     placeholder='Jméno'
        />
      </Col>
      <Col sm={2} componentClass={ControlLabel}>
        Heslo
      </Col>
      <CanView visible={item.isPassEncrypted}>
        <Col sm={2}>
          <Button onClick={() => {
            item.isPassEncrypted = false;
            item.connPass = '';
            this.handleChange(index, item);
          }}>
            Nové heslo
          </Button>
        </Col>
      </CanView>
      <Col sm={item.isPassEncrypted ? 8 : 10}>
        <FormControl type='password'
                     disabled={item.isPassEncrypted}
                     value={item.connPass}

                     onChange={(e) => {
                       item.connPass = e.target.value;
                       this.handleChange(index, item);
                     }}
                     controlId={`${index}_pass`}
                     placeholder='heslo'
        />
      </Col>
      <Col sm={2} componentClass={ControlLabel}>
        Adresa služby
      </Col>
      <Col sm={10}>
        <FormControl type='text'
                     controlId={`${index}_url`}
                     value={item.connUrl}
                     onChange={(e) => {
                       item.connUrl = e.target.value;
                       this.handleChange(index, item);
                     }}
                     placeholder='adresa služby'
        />
      </Col>
      <Col sm={2} componentClass={ControlLabel}>
        Povoleno
      </Col>
      <Col sm={10}>
        <Checkbox
          checked={item.enabled}
          onChange={(e) => {
            item.enabled = e.target.checked;
            this.handleChange(index, item);
          }}
        />
      </Col>
      {other}

      <div style={{marginTop: '260px', marginRight: '20px'}} className='text-right'>
        <LoadingOrRender requestActive={this.state.testActive}>
          <Button bsStyle={connState} onClick={() => {
            this.setState({testActive: true});
            request.get('/items/' + item.connName.toLowerCase() + '/conntest', {
              params: {connection: item}
            }).then((res) => {
                if (res.status === 200) {
                  this.setState({testActive: false, connectionState: 1});
                }
              })
              .catch((err) => {
                this.setState({testActive: false, connectionState: 2});
              });
          }}>
            Test připojení
          </Button>
        </LoadingOrRender>
      </div>
    </div>
  }
}

Connection.propTypes = {
  onDeleteConnection: PropTypes.func.isRequired,
  onChangeConnection: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  spaceId: PropTypes.number
};

export default class SpaceEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      requestActive: this.props.match.params.id !== 'new',
      submitActive: false,
      name: '',
      smsWebhook: '',
      ico: '',
      bookKeepingConnectionInfo: {
        connections: [],
      },
      aresRequestActive: false,
      icoOk: false,
      setting: {
        diets: [],
      },
    };
  }

  componentWillMount = async () => {
    if (this.props.match.params.id !== 'new' ) {
      request.get('/items/spaces/' + this.props.match.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              requestActive: false,
              ...res.data,
            });
          }
        })
        .catch(err => {
          this.setState({ requestActive: false });
        })
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.props.match.params.id, this, {
      ...this.state,
      setting: {
        ...this.state.setting,
        ordersNumberTemplate: this.ordersNumberTemplate,
      }
    });
  }

  handleConnChange(index, conn) {
    let list = this.state.bookKeepingConnectionInfo.connections.slice();
    list[index] = conn;
    this.setState({
      bookKeepingConnectionInfo: {
        connections: list,
      }
    })
  }

  render() {

    return (
      <div className='editForm'>
        <ContentWithFixedHeader header={
          <BackHeader
            fontSize='18px'
            headerText={'Nový prostor'}
            onBackClick={() => this.props.history.replace(
              this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
        }>
          <div className='nest'>
            <LoadingOrRender requestActive={this.state.requestActive}>

              <Form onSubmit={(e) => this.handleSubmit(e)}>
                <FormGroup controlId="name">
                  <Row>
                    <Col sm={2} componentClass={ControlLabel}>
                      Jméno
                    </Col>
                    <Col sm={10}>
                      <FormControl type='text'
                                   value={this.state.name}
                                   onChange={(e) => this.setState({ name: e.target.value })}
                                   placeholder='Jméno'
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="webhook">
                  <Row>
                    <Col sm={2} componentClass={ControlLabel}>
                      Sms webhook
                    </Col>
                    <Col sm={10}>
                      <FormControl type='text'
                                   value={this.state.smsWebhook}
                                   onChange={(e) => this.setState({ smsWebhook: e.target.value })}
                                   placeholder='Sms webhook'
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="telnumb">
                  <Row>
                    <Col sm={2} componentClass={ControlLabel}>
                      Číslo sms brány
                    </Col>
                    <Col sm={10}>
                      <FormControl type='number'
                                   value={this.state.telnumb}
                                   onChange={(e) => this.setState({ telnumb: e.target.value })}
                                   placeholder='tel. cislo brány'
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="ic">
                  <Row>
                    <Col sm={2} componentClass={ControlLabel}>
                      Identifikační číslo:
                    </Col>
                    <Col sm={8}>
                      <FormControl type='text'
                                   value={this.state.ico}
                                   onChange={(e) => this.setState({ ico: e.target.value, icoOk: false })}
                                   placeholder='ico'
                      />
                    </Col>
                    <Col sm={2}>
                      <LoadingOrRender requestActive={this.state.aresRequestActive}>
                        <Button bsStyle={this.state.icoOk ? 'success' : 'default'} onClick={() => {
                          this.setState({
                            aresRequestActive: true,
                          });
                          request.get('/items/addressbookares', { params: {ico: this.state.ico}})
                            .then((res) => {
                              if (res.status === 200) {
                                this.setState({
                                  aresRequestActive: false,
                                  icoOk: true,
                                });
                              }
                            })
                            .catch((err) => {
                              this.setState({
                                aresRequestActive: false,
                              });
                              store.dispatch(show({
                                title: 'Error',
                                level: 'error',
                                autoDismiss: 3,
                                children: (
                                  <div>
                                    Nenalezen žádný podnikatelský subjekt
                                  </div>
                                ),
                              }, 'error'));
                            })
                        }}>
                          Kontrola
                        </Button>
                      </LoadingOrRender>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px',
                    marginBottom: '20px',
                    paddingBottom: '20px',
                    borderBottom: 'solid 2px #DDDDDD'}}>
                    <Col sm={2} componentClass={ControlLabel}>
                      Diety
                    </Col>
                    <Col sm={8}>
                      <DietsTable
                        diets={this.state.setting.diets ? this.state.setting.diets : []}
                        onDietsChanged={(diets) => this.setState({
                          setting: {
                            ...this.state.setting,
                            diets,
                          }
                        })}/>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px',
                    marginBottom: '20px',
                    paddingBottom: '20px',
                    borderBottom: 'solid 2px #DDDDDD'}}>
                    <Col sm={2} componentClass={ControlLabel}>
                      Generátor čísla zakázky
                    </Col>
                    <Col sm={8}>
                      <NumberQueueGenerator
                        onChange={(state) => this.ordersNumberTemplate = state}
                        defaultState={this.state.setting.ordersNumberTemplate}/>
                    </Col>
                  </Row>
                  <Row style={{marginTop: '20px'}}>
                    <Col sm={12}>
                      <Dropdown id={'dispatching-row-menu'} onSelect={(eventKey) => {
                        let list = this.state.bookKeepingConnectionInfo.connections.slice();
                        let obj = {
                          type: 0,
                          connName: eventKey,
                          connUser: '',
                          connPass: '',
                          isPassEncrypted: false,
                          enabled: true,
                          connUrl: '',
                          other: {},
                        };
                        switch (eventKey) {
                          case 'pohoda':
                            obj.other = {
                              documentsPath: "",
                              programPath: "",
                              mServerName: "",
                              connectorUrl: "",
                            };
                            break;
                          case 'orderEmail':
                            obj.other = {
                              smtpPort: '',
                            };
                            break;
                          case 'webDisApi':
                            obj.other = {
                              kodF: '',
                              groupId: '',
                            };
                            break;
                          case 'utaFTP':
                            obj.other = {
                              addressBookId: null,
                            }
                            break;
                        }

                        list.push(obj);
                        this.setState({
                          bookKeepingConnectionInfo: {
                            connections: list,
                          }
                        })
                      }}>
                        <Dropdown.Toggle>
                          Nové připojení
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {
                            Object.keys(headers).map(key => <MenuItem eventKey={key}>
                              <FontAwesome style={{marginRight: '10px'}} name='fas fa-plus-square'/>
                              {headers[key]}
                            </MenuItem>)
                          }
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                  <Row>
                    {
                      Object.keys(this.state.bookKeepingConnectionInfo.connections).map((key) => {
                        return <Col key={key}>
                          <Connection index={key}
                                      spaceId={this.props.match.params.id}
                                      item={this.state.bookKeepingConnectionInfo.connections[key]}
                                      onChangeConnection={(index, item) => this.handleConnChange(index, item)}
                                      onDeleteConnection={(index) => {
                                        let list = this.state.bookKeepingConnectionInfo.connections.slice();
                                        list.splice(index, 1);
                                        this.setState({
                                          bookKeepingConnectionInfo: {
                                            connections: list,
                                          }
                                        })
                                      }}/>
                        </Col>
                      })
                    }
                  </Row>

                </FormGroup>
                <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                  Uložit
                </SubmitButton>
              </Form>
            </LoadingOrRender>
          </div>
        </ContentWithFixedHeader>
      </div>
    )
  }
}

SpaceEdit.propTypes = BaseEditPropTypes;