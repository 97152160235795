import React, {Component, FormEvent} from "react";
import {
  Button,
  Checkbox,
  ControlLabel,
  FormControl,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "react-bootstrap";
import SubmitButton from "../../loginsrc/SubmitButton";
import {SmallUserPreview} from "./Preview";
import ItemsSelector from "../ItemsSelector";
import store from "../../redux/store";
import {show} from "react-notification-system-redux";
import {AuthenticateState} from "@cml/redux-store";
import FontAwesome from "react-fontawesome";

interface State {
  show: boolean;
  loadUserSalaryActive: boolean;
}

export interface IDiet {
  from: number,
  to: number,
  price: number,
}

export interface ISalary {
  diets: IDiet [],
  pricePerHour: number,
  isPercentageFromInvoice: boolean,
  invoicePercentage: number,
  weekendBonus: number,
  publicHolidayBonusPercentage: number,
}


interface Props {
  salary: ISalary;
  onSalaryChange: (salary: object) => void;
}

interface DietsPreviewProps {
  diets: IDiet[]
}

export function DietsPreview({ diets }: DietsPreviewProps) {
  return <div style={{marginBottom: '40px', display: 'inline-block'}} className='dataTable'>
    <table>
      <tbody>
      <tr>
        <th>Od</th>
        <th>Do</th>
        <th>Příplatek</th>
      </tr>
      {
        diets.map((diet, index) => {
          return <tr key={index}>
            <td>{diet.from}</td>
            <td>{diet.to}</td>
            <td>{diet.price}</td>
          </tr>
        })
      }
      </tbody>
    </table>
  </div>
}

export class SalaryPreview extends Component<Props> {
  render() {
    const diets = this.props.salary.diets.slice();
    const { weekendBonus, isPercentageFromInvoice, publicHolidayBonusPercentage, pricePerHour, invoicePercentage } = this.props.salary;
    return <div style={{marginLeft: '20px', display: 'inline-block'}}>
      {isPercentageFromInvoice && <FormGroup style={{maxWidth: '200px'}}>
        <ControlLabel>Procenta z fakturace</ControlLabel>
        <div>{ invoicePercentage }</div>
      </FormGroup>}
      <FormGroup style={{maxWidth: '200px'}}>
        <ControlLabel>Příplatek za odpracovaný den o víkendu</ControlLabel>
        <div>{ weekendBonus }</div>
      </FormGroup>
      <FormGroup style={{maxWidth: '200px'}}>
        <ControlLabel>Příplatek za svátek (procentuálně)</ControlLabel>
        <div>{ publicHolidayBonusPercentage }</div>
      </FormGroup>
      <FormGroup style={{maxWidth: '200px'}}>
        <ControlLabel>{!isPercentageFromInvoice ? 'Mzda za hodinu' : 'Náhrada mzdy za hodinu'}</ControlLabel>
        <div>{pricePerHour}</div>
      </FormGroup>
    </div>;
  }
}

interface DietsTableProps {
  diets: IDiet[];
  onDietsChanged: (diets: IDiet[]) => void;
};

export function DietsTable({ diets, onDietsChanged }: DietsTableProps) {

  const handleDietChange = (key: string, index: number) => (e: FormEvent<FormControl>) => {
    const l = diets.slice();
    // @ts-ignore
    l[index][key] = parseFloat((e.target as HTMLInputElement).value);
    onDietsChanged(l);
  }
  const newDiet = () => {
    const l = diets.slice();
    l.push({
      from: 0,
      to: 0,
      price: 0,
    })
    onDietsChanged(l);
  }

  const deleteDiet = (index: number) => (e: any) => {
    const l = diets.slice();
    l.splice(index, 1);
    onDietsChanged(l);
  }


  return <div style={{marginBottom: '20px'}} className='dataTable'>
    <table>
      <tbody>
      <tr>
        <th>Od</th>
        <th>Do</th>
        <th>Příplatek</th>
      </tr>
      {
        diets.map((diet, index) => {
          return <tr key={index}>
            <td><FormControl type='number'
                             value={diet.from}
                             onChange={handleDietChange('from', index)}/>
            </td>
            <td><FormControl type='number'
                             value={diet.to}
                             onChange={handleDietChange( 'to', index)}/>
            </td>
            <td><FormControl type='number'
                             value={diet.price}
                             onChange={handleDietChange( 'price', index)}/>
            </td>
            <td><button onClick={deleteDiet(index)} className='transparentButton'><FontAwesome name='fas fa-times'/></button></td>
          </tr>
        })
      }
      </tbody>
    </table>
    <Button onClick={newDiet}>Nový řádek</Button>
  </div>
}

export default class Salary extends Component<Props, State> {

  static defaultProps = {
    salary: {
      diets: [],
      pricePerHour: 0,
      isPercentageFromInvoice: false,
      invoicePercentage: 0,
      weekendBonus: 0,
      publicHolidayBonusPercentage: 0,
    }
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
      loadUserSalaryActive: false,
    }
  }


  handleSalaryChange = (key: string) => (e: FormEvent<FormControl>) => {
    const salary = this.props.salary;
    switch (key) {
      case "weekendBonus":
        salary.weekendBonus = parseFloat((e.target as HTMLInputElement).value);
        break;
      case 'isPercentageFromInvoice':
        salary.isPercentageFromInvoice = (e.target as HTMLInputElement).checked;
        break;
      case 'publicHolidayBonusPercentage':
        salary.publicHolidayBonusPercentage = parseInt((e.target as HTMLInputElement).value)
        break;
      case 'invoicePercentage':
        salary.invoicePercentage = parseFloat((e.target as HTMLInputElement).value);
        break;
      case 'pricePerHour':
        salary.pricePerHour = parseFloat((e.target as HTMLInputElement).value);
        break;
    }
    this.props.onSalaryChange(salary);
  }

  handleDefaultSelected = (user: {spaces: {
      spaceId: number,
      salary: object
    }[]}, index: number, isFromInit?: boolean) => {
    if (isFromInit)
      return;
    const sId = (store.getState().authenticate as AuthenticateState).activeSpaceId;
    const space = user.spaces.find(o => o.spaceId === sId);
    if (space && space.salary) {
      this.props.onSalaryChange(space.salary);
    } else {
      store.dispatch(show({
        title: 'Upozornění',
        level: 'warning',
        autoDismiss: 3,
        children: (
          <div>
            Vybraný uživatel nemá vyplněnou mzdu, nelze použít
          </div>
        ),
      }, 'warning'));
    }
  }

  render() {
    const { show } = this.state;
    const diets = this.props.salary.diets.slice();
    const { weekendBonus, isPercentageFromInvoice, publicHolidayBonusPercentage, pricePerHour, invoicePercentage } = this.props.salary;
    return <>
      <Button onClick={() => this.setState({ show: true })}>Mzda</Button>
      <Modal show={show} onHide={() => this.setState({ show: false })}>
        <ModalHeader closeButton>
          Mzda
        </ModalHeader>
        <ModalBody>
          <ItemsSelector
            value={'nameSurname'}
            defaultQuery={{attributes: ['name', 'id', 'surname', 'nameSurname'] }}
            preview={SmallUserPreview}
            overrideToggle={'Načíst mzdu zaměstnance jako výchozí hodnotu'}
            filterEnable
            onSelected={this.handleDefaultSelected}
            otherPreviewProps={{
              style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
            }}
            itemsToFetch={'users'}/>
          <FormGroup>
            <Checkbox checked={isPercentageFromInvoice}
                      onChange={this.handleSalaryChange('isPercentageFromInvoice')}
                      style={{ paddingLeft: '10px', paddingRight: '10px' }}><ControlLabel>Je placen procentuálně z fakturace ?</ControlLabel></Checkbox>
          </FormGroup>
          {isPercentageFromInvoice &&<FormGroup>
            <ControlLabel>Procenta z fakturace</ControlLabel>
            <div>
              <FormControl
                placeholder={'Procenta z fakturace'}
                id="invoice_salary_percentage_input"
                value={invoicePercentage}
                onChange={this.handleSalaryChange('invoicePercentage')}
                type='number'/>
            </div>
          </FormGroup>}
          <FormGroup>
            <ControlLabel>Příplatek za odpracovaný den o víkendu</ControlLabel>
            <div>
              <FormControl
                placeholder={'Příplatek za víkend'}
                id="weekend_bonus_input"
                value={weekendBonus}
                onChange={this.handleSalaryChange('weekendBonus')}
                type='number'/>
            </div>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Příplatek za svátek (procentuálně)</ControlLabel>
            <div>
              <FormControl
                placeholder={'Příplatek za svátek (procentuálně)'}
                id="public_holiday_bonus_input"
                value={publicHolidayBonusPercentage}
                onChange={this.handleSalaryChange('publicHolidayBonusPercentage')}
                type='number'/>
            </div>
          </FormGroup>
          {<FormGroup>
            <ControlLabel>{!isPercentageFromInvoice ? 'Mzda za hodinu' : 'Náhrada mzdy za hodinu'}</ControlLabel>
            <div>
              <FormControl
                placeholder={!isPercentageFromInvoice ? 'Mzda za hodinu' : 'Náhrada mzdy za hodinu'}
                id="hour_salary_input"
                value={pricePerHour}
                onChange={this.handleSalaryChange('pricePerHour')}
                type='number'/>
            </div>
          </FormGroup>}
        </ModalBody>
        <ModalFooter>
          <SubmitButton
            onClick={() => this.setState({ show: false })}
            bsClass='btn btn-primary btn-block' loading={this.state.loadUserSalaryActive}>
            Uložit
          </SubmitButton>
        </ModalFooter>
      </Modal>
    </>;
  }
}