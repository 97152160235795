import { createContext } from 'use-context-selector';
import { Dispatch } from 'react';

export interface IRegexTable{
  item: string;
  description: string;
}

export interface INumberQueueElement {
  name: string;
  type: 'number' | 'string' | 'enum';
  length: number;
  description: string;
  regex: string;
  regexTable: IRegexTable[];
}

export interface INumberQueue {
  description: string;
  name: string;
  template: string;
  value: INumberQueueElement[];
}

export const initState: INumberQueue = {
  name: '',
  description: '',
  template: '',
  value: [],
}

const NumberQueueGeneratorState =
  createContext<(INumberQueue | Dispatch<NumberQueueGeneratorActionTypes>)[]>([]);

export const SET_MAIN_STATE = 'SET_MAIN_STATE';
export const CHANGE_MAIN_STATE = 'CHANGE_MAIN_STATE';
export const SET_MAIN_STATE_LIST = 'SET_MAIN_STATE_LIST';
export const CHANGE_LIST = 'CHANGE_LIST';
export const DELETE_LIST = 'DELETE_LIST';
export const ADD_LIST = 'ADD_LIST';

interface SetMainStateAction {
  type: typeof SET_MAIN_STATE;
  payload: INumberQueue;
}

interface ChangeMainStateAction {
  type: typeof CHANGE_MAIN_STATE;
  payload: object;
}

interface SetMainStateListAction {
  type: typeof SET_MAIN_STATE_LIST;
  payload: INumberQueueElement[];
}

interface ChangeListValue {
  type: typeof CHANGE_LIST,
  payload: {
    index: number,
    data: object,
  }
}

interface AddToList {
  type: typeof ADD_LIST,
}

interface DeleteFromList {
  type: typeof DELETE_LIST,
  payload: {
    index: number,
  }
}

type NumberQueueGeneratorActionTypes = SetMainStateAction | SetMainStateListAction
  | ChangeListValue | AddToList | DeleteFromList | ChangeMainStateAction;

const reducer = (state: INumberQueue, action: NumberQueueGeneratorActionTypes) => {
  if (action.type === SET_MAIN_STATE) {
    return {
      ...state,
      ...action.payload
    }
  } else if (action.type === CHANGE_MAIN_STATE) {
    return {
      ...state,
      ...action.payload
    }
  } else if (action.type === SET_MAIN_STATE_LIST) {
    return {
      ...state,
      value: action.payload
    }
  } else if (action.type === CHANGE_LIST) {
    const value = state.value.slice();
    value[action.payload.index] = {
      ...value[action.payload.index],
      ...action.payload.data,
    }
    return {
      ...state,
      value,
    }
  } else if (action.type === ADD_LIST) {
    const value = state.value.slice();
    value.push({
      description: '',
      name: '',
      length: 1,
      type: "number",
      regex: '',
      regexTable: [],
    })
    return {
      ...state,
      value,
    }
  } else if (action.type === DELETE_LIST) {
    const value = state.value.slice();
    value.splice(action.payload.index, 1);
    return {
      ...state,
      value,
    }
  }
  return state;
}

export {
  NumberQueueGeneratorState,
  reducer
};

export type { NumberQueueGeneratorActionTypes };
