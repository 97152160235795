import {CanView} from "../../Main";
import {IDispatchingAttributesCustom} from "@cml/types";
import {OverlayTrigger, Popover} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import Attachments from "../../other/Attachments";
import React, {useMemo} from "react";
import {Attributes, Roles} from "@cml/models";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";


interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrNoteAndAttachments( { index, attribute, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const id = useSelector(selectDataRowValue(index, singleRowEditMode, 'id'));

  const attachments = useSelector(selectDataRowValue(index, singleRowEditMode, 'attachments'));

  const note = useSelector(selectDataRowValue(index, singleRowEditMode, 'note'));

  const view = useMemo(() => {
    return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canNote && dispatchOther.canNote}>
      <td style={{

      }} className='text-center'>
        <OverlayTrigger rootClose trigger='click' placement='bottom' overlay={
          <Popover id={`popover-contained ${index}`}>
            <h4>Poznámka</h4>
            <textarea style={{ width: '100%', minHeight: '140px', padding:'10px'}}
                      onFocus={(e) => e.target.focus()}
                      onChange={(e) => {
                        store.dispatch(setDataRowValue(index, singleRowEditMode, {
                          note: e.target.value
                        }))
                      }}
                      value={note} />
          </Popover>
        }>
          <div>
            {
              note.length ? <div style={{
                maxWidth: '130px',
                width: `${note.length * 5}px`,
                whiteSpace: 'break-spaces', wordBreak: 'break-word',
                padding: '2px', fontSize: '11px' }}>
                {note}
              </div> : <FontAwesome style={{fontSize:'22px', color: note ? (note.length > 0 ? '#2c2c2c' : 'grey') : 'grey', }} name='fas fa-clipboard' />
            }
          </div>

        </OverlayTrigger>
      </td>
      <td>
        {
          id > 0 && <div style={{ display: 'inline-block' }}>
            <Attachments identificationProps={{
              itemId: id,
              itemTableName: 'datarow',
            }}
              //ref={attachmentsRef}
                         useModal
                         initObject={attachments}
                         saveButton
                         selfListed
                         updating={true}
                         smSize={3}
                         mdSize={2}/>
          </div>
        }
      </td>
    </CanView>
  }, [note]);

  return view;
}