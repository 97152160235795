import React, { Component } from 'react';
import { BaseViewPropTypes } from '../CommonListContainer';
import PropTypes from 'prop-types';
import { attributesCustomOption } from "./Edit";

export default class AttributePreview extends Component{
  render() {
    const { item } = this.props;
    let style = {
      width: this.props.width,
      height: this.props.height,
      display: 'inline-block',
      marginRight: this.props.marginRight,
      borderRadius: '50%',
      verticalAlign: 'middle',
      backgroundColor: this.props.item.color,
    };
    return (<div style={this.props.style}>
      <div style={style}/>
      <div style={{ display: 'inline-block', marginRight: '10px', fontWeight: 'bold' }}>{ this.props.item.name }</div>
      {attributesPreview[item.type] && React.createElement(attributesPreview[item.type], { item: item.customOptions })}
    </div>)
  }
}

class materialPreview extends Component {
  render() {
    const { item } = this.props;
    return Object.keys(item.prices).map(key => <div key={key} style={{ display: 'inline-block', marginRight: '20px' }}>
      <div style={{display: 'inline-block', marginRight: '5px'}}>{`${item.prices[key].storeName}:`}</div>
      <div style={{display: 'inline-block', fontWeight: 'bold'}}> {`${item.prices[key].basePrice} / ${item.prices[key].price}`}</div>
    </div>);
  }
}

class vehiclesPreview extends Component {
  render() {
    const { item } = this.props;
    return <div style={{ display: 'inline-block', marginRight: '20px' }}>{ item.activityId ? `(${item.activityName})` : '' }</div>
  }
}

const attributesPreview = {
  material: materialPreview,
  vehicles: vehiclesPreview,
}

AttributePreview.propTypes = {
  style: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
  marginRight: PropTypes.string,
  ...BaseViewPropTypes,
};

AttributePreview.defaultProps = {
  width: '30px',
  height: '30px',
  marginRight: '20px',
};
