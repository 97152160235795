import React, {Component, FormEvent} from 'react';
import {Form, FormControl, FormGroup, HelpBlock, Modal, Button} from 'react-bootstrap';
import SubmitButton from './SubmitButton'
import FontAwesome from 'react-fontawesome';
import store from "../redux/store";
import { show } from 'react-notification-system-redux';
import {doAuthenticate, rememberEmailForNextUse, reloadNavBar, wsConnect} from "@cml/redux-store";
import { formObject, BaseLoginState, BaseLogin, HelpMessages, IBaseLogin } from '@cml/base-login';
import { RouteComponentProps } from 'react-router-dom';
import {setPublicHolidays} from "../AppObjects";

interface LoginProps extends RouteComponentProps{
    email: string;
}

class Login extends BaseLogin<LoginProps, BaseLoginState> implements IBaseLogin {

    constructor(props: LoginProps) {
        super(props);
        this.state = {
            ...this.state,
            email: this.props.email,
        };
        this.setListeners(this);
    }

    onInputsValidated() {
        store.dispatch(rememberEmailForNextUse(this.state.email));
    };
    onLoginSuccess(data: {token: string, verification: boolean}) {

        store.dispatch(doAuthenticate(data.token))
            .then(() => {
                setPublicHolidays();
                const https = window.location.protocol.includes('https');
                store.dispatch(wsConnect( (https ? 'wss' : 'ws')
                    + '://' +window.location.host, // for development if localhost:3000 -> .split(':')[0] + ':8080'
                    // @ts-ignore
                    store.getState().authenticate.loggedUser.id));
                reloadNavBar(store);
            });
        if(data.verification) {
            if (this.props.history)
                this.props.history.push('/');
        }
    };
    onLoginError (error: any) {
        // @ts-ignore
        const message = error.response.data.message;
        store.dispatch(show({
            title: 'Chyba !',
            level: 'error',
            autoDismiss: 15,
            children: (
                <div>{ message }</div>
            ),
        }, 'error'));
    };

    handleSubmit(e: FormEvent<Form> | MouseEvent) {
        e.preventDefault();
        this.doLogin();
    }

    render() {

        let helpMessage = this.checkInputs(false) as HelpMessages;
        return(
            <div>
                <Modal onHide={() => {
                    this.setState({errorModalShow: false});
                    this.props.history.push('/');
                }} show={this.state.errorModalShow}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Upozornění
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div style={{display: 'table', width:'100%',
                            marginRight: '10px'}}>
                            <div style={{display: 'table-cell', verticalAlign:'middle',}}>
                                <FontAwesome style={{fontSize:'25px', color: 'red', paddingRight: '10px'}} name='fas fa-exclamation-circle' />
                            </div>
                            <div style={{display: 'table-cell', verticalAlign:'middle',}}>
                                Registrace není dokončena, pro její dokončení použijte tlačítko "Dokončit registraci"
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.props.history.push('/emailvalidation')}>Dokončit registraci</Button>
                    </Modal.Footer>
                </Modal>
                <div className="login-clean">
                    <Form onSubmit={(e) => this.handleSubmit(e)} horizontal>
                        <div className='illustration'>
                            <FontAwesome name='fas fa-user' />
                        </div>
                        <FormGroup controlId={'email'}
                                   bsClass='form-group'
                                   validationState={this.state.email.length > 0 ? null:'error'}>
                            <FormControl type="email"
                                         bsClass='form-control'
                                         value={this.state.email}
                                         placeholder="Email"
                                         onFocus={() => this.setState({focusedInput: formObject.email})}
                                         onChange={(e) => this.setState({email: (e.target as HTMLTextAreaElement).value})}/>
                            {
                                helpMessage[formObject.email].error && this.state.focusedInput === formObject.email ?
                                <HelpBlock>
                                    <ul>
                                        <li>{helpMessage[formObject.email].message}</li>
                                    </ul>
                                </HelpBlock> : null
                            }
                        </FormGroup>
                        <FormGroup controlId={'password'}
                                   bsClass='form-group'
                                   validationState={this.state.password.length > 0 ? null:'error'}>
                            <FormControl type="password"
                                         bsClass='form-control'
                                         value={this.state.password}
                                         placeholder="Heslo"
                                         onFocus={() => this.setState({focusedInput: formObject.password})}
                                         onChange={(e) => this.setState({password: (e.target as HTMLTextAreaElement).value})}/>
                            {
                                helpMessage[formObject.password].error && this.state.focusedInput === formObject.password? <HelpBlock>
                                    <ul>
                                        <li>{helpMessage[formObject.password].message}</li>
                                    </ul>
                                </HelpBlock> : null
                            }
                        </FormGroup>
                        <FormGroup>
                            <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.requestActive}>Potvrdit</SubmitButton>
                        </FormGroup>
                        {
                            this.props.history ?
                                <a onClick={() => this.props.history.push('/changepassword')} className="forgot">Zapomenuté heslo nebo email?</a> : null
                        }
                    </Form>
                </div>
            </div>
        )
    }
}

export default Login;