import {Component} from "react";
import React from "react";
import {Col, Row} from "react-bootstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import FontAwesome from "react-fontawesome";
import {CanView, DelayRenderContainer, sumRights, timeout} from './Main';
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types';
import CommonListContainer from './CommonListContainer';
import RoleEdit from './roles/Edit';
import RoleView from './roles/Preview';
import AttributeView from './attributes/Preview';
import AttributeEdit from './attributes/Edit';
import SpaceView from './spaces/Preview';
import SpaceEdit from './spaces/Edit';
import ContentWithFixedHeader from "./ContentWithFixedHeader";
import DiagramPreview from "./diagrams/Preview.";
import DiagramEdit from "./diagrams/Edit";
import {connect} from "react-redux";
import {mapAuthStateToProps} from "../AppObjects";

class BackHeader extends Component {
  render() {
    const {fontSize} = this.props;
    return <table style={{width: "100%"}}>
      <tbody>
      <tr style={{color: this.props.color}}>
        <td style={{width: "33%"}}>
          <button style={{fontSize: fontSize, width: '100%'}}
                  onClick={this.props.onBackClick}
                  className='transparentButton'>
            <FontAwesome name='fas fa-arrow-left'/>
          </button>
        </td>
        <td style={{width: "33%"}}>
          <div style={{fontSize: fontSize, fontWeight: 'bold'}}
               className="text-center">{this.props.headerText}</div>
        </td>
        <td style={{width: "33%"}}/>
      </tr>
      </tbody>
    </table>;
  }
}

BackHeader.propTypes = {
  fontSize: PropTypes.string,
  color: PropTypes.string,
  onBackClick: PropTypes.func,
  headerText: PropTypes.string,
};

BackHeader.defaultProps = {
  fontSize: '28px',
  color: '#000000'
};

class SettingClass extends Component {
  constructor(props) {
    super(props);
    const loc = props.location.pathname.split('/');
    this.state = {
      attribute: typeof this.props.location.defaultAttribute === 'undefined' ?
        (loc.includes("attributes") ? loc[loc.indexOf("attributes") + 1] : 'attendance') : this.props.location.defaultAttribute,
    }
  }

  render() {
    const { authenticate } = this.props;
    let role = authenticate.actualUserRole;
    let rightsArray = [
      role['roles'].view,
      role['attendance'].view,
      role['dispatching'].view,
    ];
    return (
      <div>
        <Route exact path='/setting'
               render={() =>
                 <div>
                   <ContentWithFixedHeader baseMargin={60} header={
                     <BackHeader
                       fontSize='18px'
                       headerText={'Nastavení'}
                       onBackClick={() => this.props.history.replace('/')}/>
                   }>
                     <div className="features-boxed">
                       <div className="container">
                         <div className="intro">

                         </div>
                         <Row bsClass="features">
                           <CanView visible={role['roles'].view}>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,1)) - 1) * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => this.props.history.push('/setting/roles')}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-ruler-combined'/>
                                       </div>
                                       <h3 className="name">Projektové role</h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                           </CanView>
                           <CanView visible={role['attendance'].view}>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,2)) - 1) * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => {
                                          this.setState({
                                            attribute: 'attendance',
                                          });
                                          this.props.history.push('/setting/attributes/attendance');
                                        }}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-dot-circle'/>
                                       </div>
                                       <h3 className="name">Atributy docházky</h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                           </CanView>
                           <CanView visible={role['dispatching'].view}>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,3)) - 1) * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => {
                                          this.setState({
                                            attribute: 'dispatching',
                                          });
                                          this.props.history.push('/setting/attributes/dispatching');
                                        }}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-dot-circle'/>
                                       </div>
                                       <h3 className="name">Atributy dispečinku</h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                           </CanView>
                           <CanView visible={role['dispatching'].view}>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,3)) - 1) * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => {
                                          this.setState({
                                            attribute: 'price',
                                          });
                                          this.props.history.push('/setting/attributes/price');
                                        }}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-dot-circle'/>
                                       </div>
                                       <h3 className="name">Atribut ceny dispečinku </h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                           </CanView>
                           <CanView visible={role['dispatching'].view}>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,3)) - 1) * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => {
                                          this.setState({
                                            attribute: 'material',
                                          });
                                          this.props.history.push('/setting/attributes/material');
                                        }}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-dot-circle'/>
                                       </div>
                                       <h3 className="name">Materiál </h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                           </CanView>
                           <CanView visible={role['dispatching'].view}>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,4)) - 1) * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => {
                                          this.setState({
                                            attribute: 'store',
                                          });
                                          this.props.history.push('/setting/attributes/store');
                                        }}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-dot-circle'/>
                                       </div>
                                       <h3 className="name">Odběrné místa</h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                           </CanView>
                           <CanView visible={role['vehicles'].view}>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,5)) - 1) * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => {
                                          this.setState({
                                            attribute: 'vehicles',
                                          });
                                          this.props.history.push('/setting/attributes/vehicles');
                                        }}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-dot-circle'/>
                                       </div>
                                       <h3 className="name">Skupiny vozidel</h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                           </CanView>
                           <CanView visible={role['dispatching'].view}>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={(sumRights(rightsArray.slice(0,6)) - 1) * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => {
                                          this.setState({
                                            attribute: 'invoice-activity',
                                          });
                                          this.props.history.push('/setting/attributes/invoice-activity');
                                        }}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-dot-circle'/>
                                       </div>
                                       <h3 className="name">Fakturační činnosti</h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                           </CanView>
                           <CanView visible={role.isAdmin}>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={4 * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => {
                                          this.props.history.push('/setting/spaces');
                                        }}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-building'/>
                                       </div>
                                       <h3 className="name">Prostory</h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                             <Col sm={4} md={3}>
                               <DelayRenderContainer delay={5 * timeout}>
                                 <ReactCSSTransitionGroup
                                   transitionName="main"
                                   transitionAppear={true}
                                   transitionAppearTimeout={5000}
                                   transitionEnter={false}
                                   transitionLeave={false}>

                                   <div className='item'
                                        onClick={() => {
                                          this.props.history.push('/setting/diagrams/tasks');
                                        }}>
                                     <div className="box">
                                       <div className="icon">
                                         <FontAwesome name='fas fa-project-diagram'/>
                                       </div>
                                       <h3 className="name">Diagramy úkolů</h3>
                                     </div>
                                   </div>
                                 </ReactCSSTransitionGroup>
                               </DelayRenderContainer>
                             </Col>
                           </CanView>
                         </Row>
                       </div>
                     </div>
                   </ContentWithFixedHeader>
                 </div>}/>
        <Route path='/setting/roles'
                render={(props) => <CommonListContainer {...props} previewMode={ RoleView }
                                                        item='roles'
                                                        checkRights='roles'
                                                        backUrl='/setting'
                                                        defaultQuery={!authenticate.actualUserRole.isAdmin ? { isAdmin: 0 } : null}
                                                        leftIconStyle='fas fa-edit'
                                                        editMode={ RoleEdit }/>}/>
        <Route path='/setting/attributes/:attid'
                render={(props) => <CommonListContainer {...props}
                                                        editMode={ AttributeEdit }
                                                        previewMode={ AttributeView }
                                                        orderForVis
                                                        item={'attributes/' + this.state.attribute}
                                                        checkRights={
                                                          this.state.attribute === 'price'
                                                          || this.state.attribute === 'material'
                                                          || this.state.attribute === 'store'
                                                          || this.state.attribute === 'invoice-activity' ? 'dispatching' : this.state.attribute}
                                                        backUrl='/setting'
                                                        leftIconStyle='fas fa-edit'/>}/>
        <CanView visible={role.isAdmin}>
          <Route path='/setting/spaces'
                 render={(props) => <CommonListContainer {...props}
                                                         editMode={ SpaceEdit }
                                                         previewMode={ SpaceView }
                                                         item={'spaces'}
                                                         backUrl='/setting'
                                                         leftIconStyle='fas fa-edit'/>}
          />
          <Route path='/setting/diagrams/tasks'
                 render={(props) => <CommonListContainer {...props}
                                                         editMode={ DiagramEdit }
                                                         previewMode={ DiagramPreview }
                                                         item={'diagrams/tasks'}
                                                         backUrl='/setting'
                                                         leftIconStyle='fas fa-edit'/>}
          />
        </CanView>

      </div>


    )
  }
}

SettingClass.propTypes = {
  authenticate: PropTypes.object,
};

const Setting = connect(mapAuthStateToProps)(SettingClass);

export {
  Setting,
  BackHeader,
}