import * as React from 'react';
import { DiagramEngine, PortWidget } from '@projectstorm/react-diagrams-core';
import styled from '@emotion/styled';
import {DefaultPortLabelProps, DefaultPortModel} from '@projectstorm/react-diagrams';
import {CmlPortModel} from "./CmlPortModel";

export interface CmlPortLabelProps {
    port: CmlPortModel;
    engine: DiagramEngine;
}

export const S = {
    PortLabel: styled.div`
		display: flex;
		margin-top: 1px;
		align-items: center;
	`,

    Label: styled.div`
		padding: 0 5px;
		flex-grow: 1;
	`,

    Port: styled.div<{ background: string | undefined; size: string | undefined }>`
		width: ${p => p.size};
		height: ${p => p.size};
		border-radius: 50%;
		margin: 2px;
		background: ${p => p.background};

		&:hover {
			background: rgb(192, 255, 0);
		}
	`
};

export class CmlPortLabel extends React.Component<CmlPortLabelProps> {

    render() {
        const port = (
            <PortWidget engine={this.props.engine} port={this.props.port}>
                <S.Port background={'rgba(255, 255, 255, 0.3)'} size={'15px'} />
            </PortWidget>
        );
        const label = <S.Label>{this.props.port.getOptions().label}</S.Label>;

        return (
            <S.PortLabel>
                {this.props.port.getOptions().in ? port : label}
                {this.props.port.getOptions().in ? label : port}
            </S.PortLabel>
        );
    }
}
