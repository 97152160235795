import {BaseEditProps} from "../CommonListContainerInterfaces";
import {AuthenticateState} from "@cml/redux-store";
import {BaseEditState} from "@cml/common-list-container";
import React, {Component} from "react";
import {IDiet} from "../users/Salary";
import {SmallUserPreview} from "../users/Preview";
import moment from "moment";
import {dateFormatVisible, getTimeString, mapAuthStateToProps} from "../../AppObjects";
import {Right} from "../roles/Preview";
import ReactToPrint from "react-to-print";
import request from "axios";
import {Button} from "react-bootstrap";
import SalaryHeader from "./salaryComponents/SalaryHeader";
import SalaryNoWorking from "./salaryComponents/SalaryNoWorking";
import {SalaryStrategy} from "../orders/Edit";
import SalaryCustomItems from "./salaryComponents/SalaryCustomItems";
import SalaryTotals from "./salaryComponents/SalaryTotals";
import { CanView } from "../Main";
import { connect } from "react-redux";
import { IFinalTable } from "./SalaryResult";
import { DataRow as IDataRow } from "@cml/models";
import store from "../../redux/store";
import DataRowPreview from "../dispatching/DataRowPreview";

interface SalaryFinalPreviewProps extends BaseEditProps {
  item: {
    items: IFinalTable,
    customItems: {
      name: string;
      price: number;
      fixed: boolean;
    }[],
    user: object;
    startDate: string;
    endDate: string;
    paid: boolean;
    id: string;
    spaceId: string;
  };
  authenticate: AuthenticateState;
}

interface SalaryFinalPreviewState extends BaseEditState {
  customItems: {
    name: string;
    price: number;
    fixed: boolean;
  }[],
  printing: boolean;
  dataRows: IDataRow[];
}

export class SalaryFinalPreviewClass extends Component<SalaryFinalPreviewProps, SalaryFinalPreviewState> {
  private componentRef: HTMLDivElement | null;
  private filter: {
    canNote: boolean;
    canOrderNumber: boolean;
    canVehicle: boolean;
    canRealVehicle: boolean;
    canUser: boolean;
    canTool: boolean;
    canLoadingUnloading: boolean;
    canCompany: boolean;
    canConstruction: boolean;
    canContactPerson: boolean;
    canTime: boolean;
    canFraction: boolean;
    canCalculation: boolean;
    canUseCosts: boolean;
    canLoading: boolean;
  };
  private priceAttributes: any;

  constructor(props: SalaryFinalPreviewProps) {
    super(props);
    this.state = {
      ...this.state,
      customItems: [],
      printing: false,
      dataRows: [],
    };
    this.componentRef = null;
    this.filter = {
      canOrderNumber: false,
      canLoadingUnloading: false,
      canNote: false,
      canRealVehicle: false,
      canVehicle: true,
      canUser: true,
      canTool: false,
      canLoading: true,
      canCompany: true,
      canConstruction: true,
      canContactPerson: false,
      canTime: true,
      canFraction: false,
      canCalculation: false,
      canUseCosts: false,
    }
  }

  confirmClicked = () => {
    let item = this.props.item;
    // @ts-ignore
    if (item.paid || !this.props.authenticate.actualUserRole.salary.update)
      return;
    item.paid = true;
    this.props.onSubmit(this.props.item.id, this, item);
  }

  saveSalary = () => {
    let item = this.props.item;
    item.customItems = item.customItems.concat(this.state.customItems);
    this.setState({
      customItems: [],
    })
    this.props.onSubmit(this.props.item.id, this, item);
  }



  render() {
    const { salary, noWorkHolidayBonus, rows, replacedHours, totalSavedOvertimeHours, fond, totalGpsHours } = this.props.item.items;
    const { user, startDate, endDate, paid } = this.props.item;


    // @ts-ignore
    const neededHoursFromFond = ((totalGpsHours - fond) >= 0 ? (totalGpsHours - fond) : 0 )  - replacedHours;

    const replacedCustom = [{
      fixed: true,
      name: 'Srážka za náhradní volno',
      price: -replacedHours *
        (salary ? (salary.pricePerHour) : 0)},
      {
        fixed: true,
        name: 'Fond náhradního volna',
        price: -(totalSavedOvertimeHours - (neededHoursFromFond < 0 ? neededHoursFromFond : 0)) *
          (salary ? (salary.pricePerHour) : 0)},
    ]

    const actSpace = (store.getState().authenticate as AuthenticateState).activeSpace;
    let diets: IDiet[] = [];
    if(actSpace) {
      if ((actSpace.setting as {diets: IDiet[]}).diets)
        diets = (actSpace.setting as {diets: IDiet[]}).diets
    }

    return <div style={{fontSize: '11px'}} ref={el => (this.componentRef = el)}>
      <SmallUserPreview item={user}/>
      <div>
        {`${moment(startDate).format(dateFormatVisible)} - ${moment(endDate).format(dateFormatVisible)}`}
      </div>
      {!this.state.printing && <div onClick={this.confirmClicked} title='Po kliku potvrzujete že mzda byla uhrazena, již nepůjde přidat položky' style={{ cursor: 'pointer', display: 'inline-block' }}>
        <Right style={{fontSize: '54px', display: 'inline-block' }} can={paid}/>
      </div>}
      {!this.state.printing && <div style={{ verticalAlign: 'top', display: 'inline-block', marginLeft: '30px', marginTop: '10px' }}>
        <ReactToPrint
          onBeforeGetContent={ async () => {
            this.setState({ printing: true });
            const res = await request.get('/items/datarowsResults', {
              params: {where: { salaryId: this.props.item.id }, from: startDate, to: endDate }
            });
            const res2 = await request.get('/items/attributes/price', {
              params: { where: { spaceId: this.props.item.spaceId }}
            });
            this.priceAttributes = res2.data;
            this.setState({ printing: true, dataRows: res.data });
          }}
          onAfterPrint={() => this.setState({ printing: false })}
          pageStyle={{
            // @ts-ignore
            paddingTop: '100px', paddingBottom: '100px'}}
          trigger={() => <Button bsStyle='primary' style={{ width: '100%' }}>Tisk</Button>}
          content={() => this.componentRef}
        />
      </div>}
      <div className='dataTable' >
        <table >
          {/* @ts-ignore */ }
          <tbody align='right'>
          {
            SalaryHeader({
              diets,
              salary,
              onHeaderClick: () => {
                const list = this.state.customItems.slice();
                list.push({
                  price: 0,
                  name: '',
                  fixed: false,
                })
                this.setState({ customItems: list });
              },
              // @ts-ignore
              plusEnabled: !paid && this.props.authenticate.actualUserRole.salary.update
            })
          }
          {
            SalaryNoWorking({
              diets,
              salary,
              noWorkHolidayBonus,
            })
          }
          {
            rows.map((item,key) => <tr key={key}>
              <td style={{ maxWidth: '200px', whiteSpace: 'unset', wordBreak: 'break-word'}}>{rows[key].name}</td>
              <td>{
                // @ts-ignore
                SalaryStrategy[rows[key].strategy]}</td>
              <td>{rows[key].bdrCount}</td>
              <td>{rows[key].wrCount}</td>
              <td>{rows[key].hrCount}</td>
              <td>{rows[key].standard}</td>
              <td>{rows[key].extra}</td>
              <td>{rows[key].totalHours}</td>
              <td>{rows[key].holidayHours}</td>
              <td style={{ color: rows[key].strategy === 2 ? 'green': '' }}>{rows[key].salaryS}</td>
              <td>{rows[key].salaryH}</td>
              <td>{rows[key].salaryW}</td>
              <td>{rows[key].profit}</td>
              <td style={{ color: rows[key].strategy === 1 ? 'green': '' }}>{rows[key].profitSalary}</td>
              <td>{(getTimeString(rows[key].travelTime) + (rows[key].travelFixedPrice ? `  (${ rows[key].travelFixedPrice })` : ''))}</td>
              {rows[key].dietsComplete.map((d, index) => {
                return <td key={index}>{d}</td>
              })}
              <td>{rows[key].ownDietBonus}</td>
              <td style={{color: rows[key].ownBonusTotal > 0 ? 'darkgreen' : ''}}>{`${rows[key].ownDietBonus > 0 ? '+' : ''}${rows[key].ownDietBonus}`}</td>
              <td>{rows[key].finSalary}</td>
            </tr>)
          }

          {
            this.props.item.customItems.concat(replacedCustom).map((item, index) => {
              return <tr key={index}>
                <td>{item.name}</td>
                <td colSpan={16 + diets.length}/>
                <td>{item.price}</td>
              </tr>
            })
          }
          {
            SalaryCustomItems({
              diets,
              salary,
              changed: (customItems) => {
                this.setState({ customItems });
              },
              originCustomItems: this.state.customItems,
              customItems: this.state.customItems,
              deleted: (index: number) => {
                const list = this.state.customItems.slice();
                list.splice(index,1);
                this.setState({
                  customItems: list,
                });
              }
            })
          }
          {
            SalaryTotals({
              diets,
              finalTable: this.props.item.items,
              customItems: this.props.item.customItems.concat(this.state.customItems),
              salary,
            })
          }
          {this.state.customItems.length > 0 && <tr>
            <td colSpan={18 + diets.length}>
              <Button
                onClick={this.saveSalary}>
                Uložit mzdu
              </Button>
            </td>
          </tr>}
          </tbody>
        </table>
        {this.state.printing && <table style={{ marginTop: '20px' }}>
          <tbody>
          <tr>
            <th>Datum</th>
            <CanView visible={this.filter.canOrderNumber}><th>Č.Z.</th></CanView>
            <CanView visible={this.filter.canVehicle}><th>Stroj</th></CanView>
            <CanView visible={this.filter.canUser}><th>Strojník / Dodavatel</th></CanView>
            <CanView visible={this.filter.canTool}><th>Vybavení</th></CanView>
            <CanView visible={this.filter.canLoading}><th>Nákladka</th></CanView>
            <CanView visible={this.filter.canLoading}><th>Výkladka</th></CanView>
            <CanView visible={this.filter.canCompany}><th>Firma</th></CanView>
            <CanView visible={this.filter.canConstruction}><th>Stavba</th></CanView>
            <CanView visible={this.filter.canContactPerson}><th>Kontakt</th></CanView>
            <CanView visible={this.filter.canTime}><th>od</th></CanView>
            <CanView visible={this.filter.canTime}><th>do</th></CanView>
            <CanView visible={this.filter.canTime}><th>Přestávka</th></CanView>
            <CanView visible={this.filter.canTime}><th>Hodiny</th></CanView>
            <CanView visible={this.filter.canFraction}><th>Frakce</th></CanView>
            <CanView visible={this.filter.canCalculation}><th>Kalkulace</th></CanView>
            <CanView visible={this.filter.canCalculation}><th>Cena</th></CanView>
            <CanView visible={this.filter.canUseCosts}><th>Náklady</th></CanView>
            <CanView visible={this.filter.canUseCosts}><th>Zisk</th></CanView>
          </tr>
          {
            this.state.dataRows.map((row, index) => (
              <DataRowPreview isForLog pFilter={this.filter} isForPrint
                              index={index}
                              key={index} item={row}/>
            ))
          }
          </tbody>
        </table>}
      </div>
    </div>;
  }
}

const SalaryFinalPreview = connect(mapAuthStateToProps)(SalaryFinalPreviewClass);

export default SalaryFinalPreview;