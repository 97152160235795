import * as React from 'react';
import styled from '@emotion/styled';
import {BoolSwitchNodeWidget, EndNodeWidget} from "./nodes/flowControlNodes/FlowControlWidgets";
import {BoolSwitchNodeModel, EndNodeModel} from "./nodes/flowControlNodes/FlowControlModels";
import {DiagramEngine} from "@projectstorm/react-diagrams-core";
import { DefaultNodeWidget, DefaultNodeModel } from '@projectstorm/react-diagrams';
import {RightAnglePortModel} from "./Defaults";
import {TaskNodeWidget} from "./nodes/taskDescriptionNodes/TaskDescriptionWidgets";
import {TaskNodeModel} from "./nodes/taskDescriptionNodes/TaskDescriptionModels";
import {CmlPortModel} from "./nodes/ports/CmlPortModel";

export interface TrayItemWidgetProps {
	model: any;
	color?: string;
	name: string;
	diagram?: DiagramEngine;
}

const S = {
	Tray: styled.div<{ color: string | undefined }>`
		color: white;
		font-family: Helvetica, Arial;
		padding: 1px;
		margin: 0px 10px;
		cursor: pointer;
	`,
};

export class TrayItemWidget extends React.Component<TrayItemWidgetProps> {
	render() {
		let preview = this.props.name;
		const { type } = this.props.model;
		if (type === 'endnode') {
			// @ts-ignore
			preview = <EndNodeWidget node={new EndNodeModel('node', this.props.model.isInput)} engine={this.props.diagram}/>
		} else if (type === 'tasknode') {
			let node = new TaskNodeModel({
				name: 'Proces',
				color: 'rgb(255,165,0)',
			});
			node.addPort(new CmlPortModel(true, "input", "Vstup", false));
			node.addPort(new CmlPortModel(false, "out-1", "OK", false));
			//node.addPort(new CmlPortModel(false, "output", "Výstup", false));
			// @ts-ignore
			preview = <TaskNodeWidget node={node} engine={this.props.diagram}/>
		} else if (type === 'boolSwitch') {
			// @ts-ignore
			preview = <BoolSwitchNodeWidget node={new BoolSwitchNodeModel()} engine={this.props.diagram}/>
		}
		return (
			<S.Tray
				color={'#ffffff'}
				draggable={true}
				onDragStart={event => {
					event.dataTransfer.setData('storm-diagram-node', JSON.stringify(this.props.model));
				}}>
				{preview}
			</S.Tray>
		);


	}
}
