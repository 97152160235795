import {Component} from "react";
import React from "react";
import { BaseEditPropTypes } from '../CommonListContainer';
import {Form, FormControl, FormGroup, Col, Row, ControlLabel, Checkbox} from 'react-bootstrap';
import SubmitButton from "../../loginsrc/SubmitButton";
import { LoadingOrRender } from '../../loginsrc/Profile';
import request from 'axios';
import {BackHeader} from "../Setting";
import ContentWithFixedHeader from "../ContentWithFixedHeader";

export default class ToolEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      identifier: '',
      isAvailable: 1,
      requestActive: this.props.match.params.id === 'new' ? false : true,
      submitActive: false,
    }
  }

  componentWillMount() {
    if (this.props.match.params.id !== 'new' ) {
      request.get('/items/tools/' + this.props.match.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState(res.data);
            this.setState({ requestActive: false })
          }
        })
        .catch(err => {
          this.setState({ requestActive: false })
        })
    }
  }

  render() {
     return (
       <div className='editForm'>
         <ContentWithFixedHeader header={
           <BackHeader
             fontSize='18px'
             headerText={this.state.name === '' ? 'Nové vybavení' : this.state.name}
             onBackClick={() => this.props.history.replace(
               this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
         }>
           <div className='nest'>
             <LoadingOrRender requestActive={this.state.requestActive}>
               <Form onSubmit={(e) => {
                 delete this.state.nameWithIdentifier;
                 e.preventDefault();
                 this.props.onSubmit(this.props.match.params.id, this, this.state);
               }}>
                 <Row>
                   <Col sm={2} componentClass={ ControlLabel }>
                     Jméno
                   </Col>
                   <Col sm={4}>
                     <FormGroup>
                       <FormControl type='text'
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    placeholder='Jméno'
                       />
                     </FormGroup>
                   </Col>
                 </Row>
                 <Row>
                   <Col sm={2} componentClass={ ControlLabel }>
                     Identifikační značka
                   </Col>
                   <Col sm={4}>
                     <FormGroup>
                       <FormControl type='text'
                                    value={this.state.identifier}
                                    onChange={(e) => this.setState({ identifier: e.target.value })}
                                    placeholder='Identifikační značka'
                       />
                     </FormGroup>
                   </Col>
                 </Row>
                 <Row>
                   <Col sm={2} componentClass={ ControlLabel }>
                     Je na skladě ?
                   </Col>
                   <Col sm={4}>
                     <Checkbox checked={this.state.isAvailable ? 1 : 0}
                               onChange={(e) => this.setState({ isAvailable: e.target.checked ? 1 : 0 })}
                               style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                     </Checkbox>
                   </Col>
                 </Row>
                 <Row>
                   <Col sm={6}>
                     <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                       Uložit
                     </SubmitButton>
                   </Col>
                 </Row>
               </Form>
             </LoadingOrRender>
           </div>
         </ContentWithFixedHeader>
       </div>
     )
  }
}

ToolEdit.propTypes = BaseEditPropTypes;
