import React, {useMemo} from "react";
import Marker from "../components/Marker";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {useSelector} from "react-redux";
import {DataRowsState} from "@cml/redux-store";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  singleRowEditMode: boolean;
}

export default function DrMarkersView( {index, singleRowEditMode}: Props) {

  const incomeInvoiceForeignId = useSelector(selectDataRowValue(index, singleRowEditMode, 'incomeInvoiceForeignId'));

  const invoices = useSelector(selectDataRowValue(index, singleRowEditMode, 'invoices'));

  const orderForeignId = useSelector(selectDataRowValue(index, singleRowEditMode, 'orderForeignId'));

  return <td>
    <div style={{ border: 'none', background: 'transparent' }} className='buttonMarkerWrapper'>
      <Marker color='brown' type='orderForeignId' id={orderForeignId}/>
      <Marker color='darkblue' type='incomeInvoiceForeignId' id={incomeInvoiceForeignId}/>
      {
        invoices && invoices.map((inv: { logItem: { itemId: any; description: { invoice: { text: string; }; }; }; }, index: string | number | null | undefined) => {
          if(inv.logItem.itemId) {
            return <Marker color='purple'
                           key={index}
                           morePrintOptions={[{id: '190', label: 'Faktura'}, {id:'235', label: 'Součet'}]}
                           type='invoiceForeignId' id={inv.logItem.itemId}/>
          }
          return <OverlayTrigger rootClose trigger='click' placement='right' overlay={
            <Popover id={`popover-contained ${inv.logItem}`}>

              {(inv.logItem.description as {invoice: {text: string}}).invoice.text}
            </Popover>
          }>
            <div style={{ backgroundColor: 'purple' }} className='buttonMarker line'>
              <div className='buttonMarkerLine'/>
            </div>
          </OverlayTrigger>

        })
      }
    </div>
  </td>
}