import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import NavigationBar from './loginsrc/NavigationBar'
import './assets/css/Login-Form-Clean.css';
import './assets/css/ProfileLoginForm.css';
import './assets/css/EditForm.css';
import './assets/css/style.css';
import './assets/css/Highlight-Clean.css';
import './assets/css/Features-Blue.css';
import './assets/css/Features-Boxed.css';
import './assets/css/style.css';
import './assets/css/SmsConverasiton.css';
import './assets/css/dispatchingDatePicker.css';
import './assets/css/RecordComponent.css';
import './assets/css/stormDiagram.css';
import './assets/css/SideNavHome.css';
import './assets/css/SideFilter.css';
import 'react-calendar-timeline/lib/Timeline.css'
// or you can use classic theme
import 'react-times/css/classic/default.css';
import Main from './components/Main'
import {buildNumber, setPublicHolidays,} from "./AppObjects";
import Modal from "react-bootstrap/es/Modal";
import FontAwesome from "react-fontawesome";
import {Button, Form, FormControl, FormGroup} from "react-bootstrap";
import SubmitButton from "./loginsrc/SubmitButton";
import Login from "./loginsrc/Login";
import store from './redux/store';
import {doConnectionLost, wsConnect, setAxiosMiddleware as setAxiosMiddlewareStore} from "@cml/redux-store";
import { setAxiosMiddleware as setAxiosMiddlewareContainer } from '@cml/common-list-container';
import Notifications, { show } from 'react-notification-system-redux';
import {connect} from "react-redux";
import {doAuthenticate, reloadNavBar} from "@cml/redux-store";
import request from 'axios';

const mapStateToProps = state => {
  return {
    notifications: state.notifications,
    authenticate: state.authenticate,
  }
};

const requestErrorHandle = (error) => {
  if (request.isCancel(error)) {
    console.log('Request canceled', error.message);
    return Promise.reject(error);
  }
  // Do something with error
  if (store.getState().authenticate.isAuthenticated && error.config.url === '/token')
    store.dispatch(doConnectionLost())

  if (error && error.response && error.response.status === 401) {
    return request.get('/token')
      .then(() => request(error.config))
  } else if (error && error.response) {
    if (!['/token', '/items/webForeignDrivers', '/items/volvoForeignDrivers'].includes(error.config.url)) {
      store.dispatch(show({
        title: 'Chyba !',
        level: 'error',
        autoDismiss: 7,
        children: (
          <div>
            <div style={{marginBottom: '15px'}}>
              {`Server hlásí chybu: ${error.response.status}`}
            </div>
            <div>
              { error.response.data ? error.response.data.message : error.response.message }
            </div>
          </div>
        ),
      }, 'error'));
    }
  }
  return Promise.reject(error);
}

request.interceptors.response.use(function (response) {
  return response;
}, requestErrorHandle);

setAxiosMiddlewareStore(undefined, requestErrorHandle);
setAxiosMiddlewareContainer(undefined, requestErrorHandle);

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      checkVersion: false,
      serverBuildNumber: '',
      requestActive: false,
    };
    this._notificationSystem = null;
    request.get('/buildnumber')
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            checkVersion: !(res.data.text === buildNumber),
            serverBuildNumber: res.data.text,
          });
        }
      })
  }

  componentDidMount () {
    store.dispatch(doAuthenticate(null))
      .then(() => {
        setPublicHolidays();
        const https = window.location.protocol.includes('https');
        store.dispatch(wsConnect( (https ? 'wss' : 'ws')
          + '://' + window.location.host, // for development if localhost:3000 -> .split(':')[0] + ':8080'
          store.getState().authenticate.loggedUser.id));
        reloadNavBar(store);
      });
  }

  render() {
    const { authenticate } = this.props;
    let style = {
      NotificationItem: { // Override the notification baseState
        DefaultStyle: { // Applied to every notification, regardless of the notification level
          borderRadius: '5px',
          borderTop: 'none',
          isVisible: {
            opacity: 0.9,
          },
        },
      },
      Containers: {
        tr: {
          top: '50px',
          bottom: 'auto',
          left: 'auto',
          right: '0px',
        },
      },
    };

    if (authenticate.loggedUser && !authenticate.actualUserRole) {
      return <div className="login-clean">
        <Form onSubmit={(e) => {
          e.preventDefault();
          request.get('/items/returnToWorkingSpace')
            .then(res => {
              if (res.status === 200) {
                window.location.reload();
              }
            })
            .catch(err => {
              store.dispatch(show({
                title: 'Chyba !',
                level: 'error',
                autoDismiss: 3,
                children: (
                  <div>
                    Neexistuje prostor ve kterém máte nějakou roli
                  </div>
                ),
              }, 'error'));
            })
        }} horizontal>
          <div className='illustration'>
            <FontAwesome style={{color: 'red'}} name='fas fa-times' />
          </div>
          <div>
            {`Pro tento prostor nemáte přiřazenou roli, prosím požádejte zodpovědnou osobu aby Vám jí přiřadil/a.
            Pokud máte k dispozici jiný prostor nastavte si roli v sekci uživatelé -> Váš uživatel -> upravit.`}
          </div>
          <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.requestActive}>Zpět</SubmitButton>
        </Form>
      </div>
    }

    return (
      <div>

        <Notifications
          notifications={this.props.notifications}
          style={style}
        />
          <Modal show={!authenticate.connectionActive && authenticate.loggedUser}>
            <Modal.Body>
              <h3 className='text-center'>Ztráta připojení</h3>
              <div className='text-center'>Server potřebuje Vaše přihlášení</div>
              <Login email={authenticate.registeredEmail}/>
            </Modal.Body>
          </Modal>
          <Modal show={this.state.checkVersion}>
            <Modal.Body>
              <div style={{display: 'table', width:'100%',
                marginRight: '10px'}}>
                <div style={{display: 'table-cell', verticalAlign:'middle',}}>
                  <div>Aplikace není kompatibilní se serverem, prosím stiskněte klávesy CTRL+F5, pro znovunačtení aplikace ze serveru!</div>
                  <div>{`Server verze ${this.state.serverBuildNumber}, web verze ${buildNumber}`}</div>
                </div>
                <div style={{display: 'table-cell', verticalAlign:'middle',}}>
                  <FontAwesome style={{fontSize:'40px', color: 'red', }} name='exclamation' />
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Router>
            <div>
              <NavigationBar />
              <div style={{marginTop: '50px'}}>
                <Route path='/'
                       render={(props) => <Main {...props}/>}/>
              </div>
              <footer className="page-footer font-small blue footerText">
                <div className="footer-copyright text-center py-3">
                  2019 - 2020 © <a href="https://ldapps.cz"> Ldapps</a>, Inc. All rights reserved
                </div>
                <div className="footer-copyright text-center py-3">
                  {`build number: ${buildNumber}`}
                </div>
              </footer>
            </div>
          </Router>
      </div>
    );
  }
}

export {
  request
}

export default connect(mapStateToProps)(App);
