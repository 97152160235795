import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import request from 'axios';
import {LoadingOrRender} from "../loginsrc/Profile";
import {mapAuthStateToProps} from "../AppObjects";
import {connect} from "react-redux";

class SmsConversationItem extends Component {
  render() {
    const { item } = this.props;
    let c = 'smsConv ';
    c += item.wentOut ? 'out' : 'in';

    let style = {
      marginLeft:0, marginRight:'auto'
    };
    if (item.wentOut)
      style = {
        marginLeft:'auto', marginRight:0
      };
    return <div>
      <div style={style} className={c}>
        {item.text}
      </div>
    </div>
  }
}

SmsConversationItem.propTypes = {
  item: PropTypes.object.isRequired,
};

class SendSms extends Component {
  constructor(props) {
    super(props);
    this.selectedUsers = this.props.location.state ? this.props.location.state.selectedUsers : [];
    if (!this.selectedUsers)
      this.selectedUsers = [];
    this.state = {
      text: typeof this.props.location.state === 'undefined' ?
        '' : this.props.location.state.defaultText,
      requestActive: false,
      requestGetConversation: this.selectedUsers.length < 2,
    };

    this.conversation = [];
  }

  sendSms() {
    this.setState({
      requestActive: true,
    });
    if (this.selectedUsers.length < 2) {
      request.post('/items/sms', {
        to: this.props.location.state.to.replace('+', ''),
        text: this.state.text,
      }, { timeout: 4000 })
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              requestActive: false,
            });
            this.props.history.replace(
              this.props.match.url.split('/sendsms')[0]);
            if (typeof this.props.onSended !== 'undefined')
              this.props.onSended(res.data, this.props.location);
          }
        })
        .catch((error) => { this.setState({ requestActive: false, });})
    } else {
      request.post('/items/multisms', {
        numbers: this.selectedUsers.map(o => o.telnumb.replace('+', '')),
        text: this.state.text,
      })
        .then((res) => {
          this.setState({
            requestActive: false,
          });

          this.props.history.replace(
            this.props.match.url.split('/sendsms')[0]);
          if (typeof this.props.onMultiSmsSended !== 'undefined')
            this.props.onMultiSmsSended(res.data);

        }).catch((error) => { this.setState({ requestActive: false, });})
    }
  }

  componentWillMount() {
    if (this.selectedUsers.length < 2) {
      request.get('/items/sms', {
        params: {
          where: {
            $or: [
              {
                to: {
                  $eq: this.props.location.state.to.replace('+', ''),
                }
              },
              {
                from: {
                  $eq: this.props.location.state.to.replace('+', ''),
                }
              }
            ],
          },
        }
      }).then((res) => {
        if(res.status === 200) {
          this.conversation = res.data;
          this.setState({
            requestGetConversation: false,
          });
        }
      })
        .catch(err => {
          this.setState({ requestGetConversation: false, });
        })
    }
  }

  render() {
    const { authenticate } = this.props;
    if (typeof this.props.location.state === 'undefined')
      return <div>Bad parametrs to SendSms</div>;
    let { to } = this.props.location.state;

    return (
      <div>
        <Modal
          onHide={() => this.props.history.replace(
            this.props.match.url.split('/sendsms')[0])}
          show={true}>
          <Modal.Header closeButton>
            <Modal.Title>{this.selectedUsers.length < 2 ? to : this.selectedUsers.map(o => o.nameSurname).join(', ')}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <LoadingOrRender requestActive={this.state.requestGetConversation}>
              <div style={{maxHeight: '300px', marginBottom: '10px', overflow: 'auto'}}>
                {
                  Object.keys(this.conversation).map((key) => {
                    return <SmsConversationItem item={this.conversation[key]} key={key}/>
                  })
                }
              </div>
            </LoadingOrRender>
            <div className='editForm'>
              <textarea value={this.state.text}
                        placeholder='Zpráva'
                        className=' textarea'
                        onChange={(e) => this.setState({ text: e.target.value })}/>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <LoadingOrRender requestActive={this.state.requestActive}>
              <Button
                disabled={!authenticate.actualUserRole.sms.update}
                onClick={() => this.sendSms()}
                bsStyle="primary">Odeslat SMS</Button>
            </LoadingOrRender>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

SendSms.propTypes = {
  onSended: PropTypes.func,
  authenticate: PropTypes.object,
};

export default connect(mapAuthStateToProps)(SendSms);