import React, { Component } from 'react';
import {
  Col, ControlLabel,
  Dropdown,
  Form, FormControl, FormGroup,
  MenuItem,
  Modal,
  Row
} from "react-bootstrap";
import _ from "lodash";
import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import SubmitButton from "../../loginsrc/SubmitButton";
import moment from "moment";
import request from 'axios';
import {SingleDatePicker} from "react-dates";
import {
  dateFormatVisible,
  dateFromat, Error403,
  getRangeIndex,
  mapAuthStateToProps,
  roundFloatTwoDigit
} from "../../AppObjects";
import {rowStateColors, rowStateColorsOdd, getTimeDiff} from './components/datarowBase';
import DataRow from "./DataRow";
import {InvoicePreview} from "../log/Preview";
import {LoadingOrRender, UpdateButtonOnHover} from "../../loginsrc/Profile";
import AddressBookEdit from "../addressBook/Edit";
import ItemsSelector from "../ItemsSelector";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import {connect} from "react-redux";
import BudgetOption from "./components/BudgetOption";
import BudgetModal from "./components/BudgetModal";

class InvoiceItem extends Component {
  render() {
    const {item, attributes, highlightedVehicle} = this.props;

    const materialTableLength = item.materialTable ? (item.materialTable.filter(m => m.useInCalculation)).length : 0;

    return <>
      {item.calculation ? item.calculation[materialTableLength ? "sumPriceEq" : "priceEq"]
        .map((price, index) => {
        const attribute = attributes.find(o => o.id === price.attributeId);
        let itemPrice = price.price;
        let itemCost = price.costPrice;
        let amount = price.amount;
        let otherText = '';
        if (attribute.customOptions.useKmRange) {
          const i = getRangeIndex(price.amount, attribute.customOptions.rangeList);
          if (i > -1) {
            itemPrice = price.price[i];
            if (price.costPrice)
              itemCost = price.costPrice[i];
            if (attribute.customOptions.rangeList[i].multipleByHours) {
              amount = getTimeDiff(item).sum;
              otherText = `(KM: ${price.amount})`;
            }
          }

        }
        let costTotal = price.costTotal;
        if (isNaN(costTotal))
          costTotal = 0;
        return <tr style={{
          opacity: (item.realVehicle ? item.realVehicle.id === highlightedVehicle : false) || item.vehicleId === highlightedVehicle || !highlightedVehicle ? '1.0' : '0.5',
          backgroundColor: this.props.index % 2 === 0 ? rowStateColors[item.isConfirmed] : rowStateColorsOdd[item.isConfirmed],
          borderLeft: item.invoices.length > 0 ? 'solid 10px purple' : 'none',
        }} key={index}>
          <td>
            {
              this.props.onEditRequired ? <button className='transparentButton' onClick={(e) => {
                e.preventDefault();
                this.props.onEditRequired(item);
              }}>
                <FontAwesome style={{fontSize:'14px'}} name='fas fa-pen' />
              </button> : null
            }
            {
              item.isConfirmed < 3 ? <span>
                <FontAwesome style={{fontSize:'14px', color: 'red'}} name='fas fa-exclamation' />
              </span> : null
            }
          </td>
          <td>{moment(item.date, dateFromat).format(dateFormatVisible)}</td>
          <td>
            {<div className='linkStyle' onClick={() => this.props.onHighlightRequested(item.realVehicle ? item.realVehicle.id : item.vehicle.id)}>
              {`${item.realVehicle ? item.realVehicle.identifier : item.vehicle.identifier} ${(item.realVehicle ? item.realVehicle.isZeroDph : item.vehicle.isZeroDph) ? '(PDP)' : ''} ${
                price.isMaterial ? '(' + price.name + ')' : ''
              } ${otherText}`}
            </div>}
          </td>
          <td>{attribute.name}</td>
          <td>{amount <= 0 ? <span>
            <FontAwesome style={{fontSize:'14px', color: 'red', marginRight: '10px'}} name='fas fa-exclamation' />
            {roundFloatTwoDigit(amount)}
          </span> : roundFloatTwoDigit(amount)}</td>
          <td>{itemPrice <= 0 ? <span>
            <FontAwesome style={{fontSize:'14px', color: 'red', marginRight: '10px'}} name='fas fa-exclamation' />
            {roundFloatTwoDigit(itemPrice)}
          </span> : roundFloatTwoDigit(itemPrice)}
            {materialTableLength > 0 ? <FontAwesome
              title='Byla vytvořena franko cena'
              style={{fontSize:'14px', color: 'orange', marginLeft: '10px', marginRight: '10px'}}
              name='fas fa-exclamation' /> : null}
          </td>
          <td>{price.total <= 0 ? <span>
            <FontAwesome style={{fontSize:'14px', color: 'red', marginRight: '10px'}} name='fas fa-exclamation' />
            {roundFloatTwoDigit(price.total)}
          </span> : roundFloatTwoDigit(price.total)}</td>
          {
            (item.attribute.customOptions.canUseCosts || price.isMaterial) ? <td>{itemCost <= 0 ? <span>
            <FontAwesome style={{fontSize:'14px', color: 'red', marginRight: '10px'}} name='fas fa-exclamation' />
              {roundFloatTwoDigit(itemCost)}
            </span> : roundFloatTwoDigit(itemCost)}</td> : <td><FontAwesome name='fas fa-ban' style={{color: 'grey'}} /></td>
          }
          {
            (item.attribute.customOptions.canUseCosts || price.isMaterial) ? <td>{price.costTotal <= 0 ? <span>
            <FontAwesome style={{fontSize:'14px', color: 'red', marginRight: '10px'}} name='fas fa-exclamation' />
              {roundFloatTwoDigit(price.costTotal)}
            </span> : roundFloatTwoDigit(price.costTotal)}</td> : <td><FontAwesome name='fas fa-ban' style={{color: 'grey'}} /></td>
          }

          <td>{(price.total - costTotal) <= 0 ? <span>
            <FontAwesome style={{fontSize:'14px', color: 'orange', marginRight: '10px'}} name='fas fa-exclamation' />
            {roundFloatTwoDigit(price.total - costTotal)}
          </span> : roundFloatTwoDigit(price.total - costTotal)}</td>
        </tr>
      }) : null}

    </>
  }
}

InvoiceItem.propTypes = {
  item: PropTypes.object.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onHighlightRequested: PropTypes.func,
  onEditRequired: PropTypes.func,
  highlightedVehicle: PropTypes.any,
};

InvoiceItem.defaultProps = {
  onHighlightRequested: () => {},
};

class Invoice extends Component {
  constructor(props){
    super(props);
    let date = moment();
    date.locale('cs');
    this.state = {
      selectedDate: moment(),
      date: date,
      submitActive: false,
      selAddressBookId: null,
      selAddressSpaceId: 0,
      addressBookFilterValue: '',
      invoiceText: '',
      errorMessages: [],
      datePickerFocused: false,
      datePicker2Focused: false,
      warningMessages: [],
      invoicePreviewExisting: null,
      highlightedVehicle: null,
      actualInvoiceTempIndex: null,
      invoiceTeplateEdit: false,
      activeSpace: null,
      groups: [],
    };
    this.orderIds = [];
    this.originTemplateItem = null;
    this.budgetSplitActive = false;
    this.order = null;
    this.defaultPrices = [];
    this.groupCount = -1;
  }

  componentDidMount() {
    request.get('/items/spaces/' + this.props.authenticate.activeSpaceId)
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            activeSpace: res.data,
          });
        }
      })
    this.validateProps(this.props);
  }

  validateProps(props) {
    this.checkState(props.dataRows);
    this.orderIds = Array.from(new Set([...props.dataRows.filter(o => o.orderId !== null).map(o => o.orderId)]));
    if (this.orderIds.length === 1) {
      const order = props.dataRows.find(o => o.orderId === this.orderIds[0]).order;
      this.defaultPrices = order.other.prices.filter(o => o.enabled).map(o => ({
        id: o.attributeId,
        name: o.attributeName,
        ...o
      }));
      const abi = order ? (order.abi) : [];
      if (abi.length) {
        if (this.state.selAddressBookId !== abi[0].addressBookId) {
          this.setState({
            selAddressBookId: abi[0].addressBookId,
            selectedDate: order.duePeriod ? moment(this.state.date).add(order.duePeriod, "days") : this.state.selectedDate,
          });
        }
      }
      this.order = order;
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.validateProps(nextProps);
  }

  calculateTotal() {
    const { highlightedVehicle } = this.state;
    const { dataRows, priceAttributes } = this.props;
    const rows = highlightedVehicle ? dataRows.filter(o => o.vehicleId === highlightedVehicle || o.subOriginVehicleId === highlightedVehicle) : dataRows;
    const prices = rows.map(o => o.calculation.priceEq);
    let ids = [];
    prices.forEach(price => {
      if (price) {
        price.map(o => o.attributeId).forEach(id => {
          if (ids.findIndex(o => o === id) === -1) {
            ids.push(id);
          }
        })
      }
    });

    let results = [];
    let resultsMat = [];

    ids.forEach(id => {
      const attr = priceAttributes.find(o => o.id === id);
      let total = 0;
      let totalCost = 0;
      let totalAmount = 0;
      let totalMat = 0;
      let totalCostMat = 0;
      let totalAmountMat = 0;
      prices.map((priceEq, index) => {
        if(priceEq) {
          priceEq.forEach(price => {
            if (price.attributeId === id) {
              let total_ = 0;
              let totalCost_ = 0;
              let totalAmount_ = 0;
              if (attr.customOptions.useKmRange) {
                const i = getRangeIndex(price.amount, attr.customOptions.rangeList);
                if (i > -1) {
                  total_ += parseFloat(price.total);
                  if (price.costPrice)
                    totalCost_ += isNaN(price.costPrice[i]) ? 0 : price.costPrice[i];
                  if (attr.customOptions.rangeList[i].multipleByHours) {
                    totalAmount_ += getTimeDiff(rows[index]).sum;
                  } else {
                    totalAmount_ += parseFloat(price.amount);
                  }
                }
              } else {
                totalAmount_ += parseFloat(price.amount);
                total_ += parseFloat(price.total);
                totalCost_ += isNaN(price.costTotal) ? 0 : price.costTotal;
              }
              if (price.isMaterial) {
                totalMat += total_;
                totalCostMat += totalCost_;
                totalAmountMat += totalAmount_;
              } else {
                total += total_;
                totalCost += totalCost_;
                totalAmount += totalAmount_;
              }
            }
          })
        }
      });

      resultsMat.push({
        attribute: attr,
        total: totalMat,
        totalCost: totalCostMat,
        totalAmount: totalAmountMat,
      })

      results.push({
        attribute: attr,
        total,
        totalCost,
        totalAmount,
      })
    });
    return {
      results,
      resultsMat
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.invoiceText.length === 0) {
      store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
        children: (
          <div>
            Text fakturace není vyplněn
          </div>
        ),
      }, 'error'));
      return;
    }
    let date = moment();
    date.locale('cs');
    if (date.isAfter(this.state.selectedDate)) {
      store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
        children: (
          <div>
            Datum splatnosti je menší než dnešní datum
          </div>
        ),
      }, 'error'));
      return;
    }
    if (date.add(7, 'day').isBefore(this.state.date)) {
      store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
        children: (
          <div>
            Nelze fakturovat více jak týden dopředu
          </div>
        ),
      }, 'error'));
      return;
    }
    this.setState({submitActive: true});
    const abi = this.order && this.orderIds.length < 2 ? (this.order.abi) : [];
    request.post('/items/pohoda/importinvoice', {
      dataRows: this.props.dataRows,
      groupCount: this.groupCount,
      attrId: this.groupCount > -1 ? this.budgetObject.attrId : null,
      invoice: {
        zeroDph: this.props.dataRows.filter(o => !o.vehicle.isZeroDph).length === 0,
        dateDue: this.state.selectedDate.format(dateFromat),
        date: this.state.date.format(dateFromat),
        text: this.state.invoiceText,
      },
      foreignBookKeepingContractId: this.order ? this.order.foreignBookKeepingContractId : undefined,
      addressSpaceId: this.state.selAddressSpaceId,
      addressBookId: abi.length > 1 ? abi.map(o => ({
        addressBookId: o.addressBookId,
       percentage: o.percentage,
      })) : [{addressBookId: this.state.selAddressBookId, percentage: 100}],
    }).then((res) => {
      if(res.status === 200) {
        this.setState({submitActive: false});
        res.data.array.forEach(a => this.props.onDone(a.invoices));
        store.dispatch(show({ title: 'Oznámení', level: 'info', autoDismiss: 3,
          children: (
            <div>
              Faktura byla zaúčtována do účetního systému
            </div>
          ),
        }, 'info'));
      }
    }).catch((err) => {
      this.setState({submitActive: false});
      store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
        children: (
          <div>
            {`Server hlásí chybu: ${err.status} ${err.response.text}`}
          </div>
        ),
      }, 'error'));
    })
  }

  requestInvoice(id) {
    request.get('/items/logs', {
      params: {
        where: {itemId: id, tableName: 'foreignComInvoice'}
      }
    }).then((res) => {
        if (res.data.length > 0) {
          this.setState({
            invoicePreviewExisting: res.data[0],
          })
        }
      })
  }

  checkState(dataRows) {
    let warningMessages = [];
    let errorMessages = [];
    if (dataRows.filter(o => o.isConfirmed < 3).length > 0)
      errorMessages.push(`Některý z řádků není ve stavu evidován`);

    if (dataRows.filter(o => o.invoices.length > 0).length > 0) {
      const invoicesIds = [...new Set(dataRows.reduce((acc, o) => acc.concat(o.invoices.map(i => i.logItem.itemId)), []))].filter(o => o);
      errorMessages.push(<div>
        <div>{`Některý z řádků již byl fakturován, sledujte fialový pruh a použijte tlačítka pro náhled případně storno faktur`}</div>
        {
          Object.keys(invoicesIds).map(key => {
            return <button onClick={(e) => {
              e.preventDefault();
              this.requestInvoice(invoicesIds[key]);
            }} key={key}>
              {invoicesIds[key]}
            </button>
          })
        }
      </div>);
    }

    if (this.orderIds.length > 1) {
      warningMessages.push(<div>
        <div>{`Vybrané řádky obsahují dva různé odběratele. Vyberte prosím odběratele a nastavte datum splatnosti.`}</div>
      </div>);
    }

    if (dataRows.filter(o => o.calculation.priceEq.filter(p => p.total <= 0 || (o.attribute.customOptions.canUseCosts && p.costTotal <= 0)).length > 0).length > 0)
      warningMessages.push(`Některá z položek má nulovou nebo zápornou cenu`);

    // TODO: check if priceEq really exists, because after edit if priceEq is still null than error is born !
    if (dataRows.filter(o => o.calculation.priceEq.filter(p => (p.total - p.costTotal) <= 0).length > 0).length > 0)
      warningMessages.push(`Zisk z položky má nulovou nebo zápornou hodnotu`);

    if (dataRows.filter(o => o.vehicle.isZeroDph).length > 0 && dataRows.filter(o => !o.vehicle.isZeroDph).length > 0)
      warningMessages.push(`Některé vozidlo je fakturováno jako PDP a některé nikoliv. Prosím ujistěte se ,že fakturujete správně`);

    this.setState({
      errorMessages: errorMessages,
      warningMessages: warningMessages,
    });
  }

  componentWillUpdate(nextProps, nextState, nextContext) {

    if ((nextProps.active && !this.props.active) || (!nextProps.rowEditIsActive && this.props.rowEditIsActive) || this.budgetSplitActive) {
      this.budgetSplitActive = false;
      const { dataRows } = nextProps;
      this.checkState(dataRows);
    }
  }

  requestSpaceSettingChange(space) {
    request.put('/items/spaces', space)
      .then();
  }

  generateText(item) {
    let text = item.text;
    if (this.orderIds.length === 1) {
      text = text.replace('%dfrom%', moment(this.order.dateFrom, dateFromat).format(dateFormatVisible));
      text = text.replace('%dto%', moment(this.order.dateTo, dateFromat).format(dateFormatVisible));
      text = text.replace('%onum%',this.order.receivedOrderNumber);
    } else {
      text = text.replace('%dfrom%', 'NELZE VYBRAT');
      text = text.replace('%dto%', 'NELZE VYBRAT');
      text = text.replace('%onum%','NELZE VYBRAT');
    }
    return text;
  }

  splitBudget(attrId, amount, price) {
    const { priceAttributes } = this.props;
    const dataRows = this.props.dataRows.slice().map(o => ({...o}));
    const attribute = priceAttributes.find(o => o.id === attrId);
    let defaultPrice = null;
    if (this.defaultPrices.length > 0) {
      defaultPrice = this.defaultPrices.find(o => o.id === attrId);
    }

    if (attribute) {
      dataRows.forEach((data) => {
        if (!data.priceEq)
          data.priceEq = [];
        const priceAttribute = data.priceEq.find(o => o.attributeId === attrId);
        if (!priceAttribute) {
          data.priceEq.push({
            attributeId: attrId,
            price: 0,
            amount: 0,
            total: 0,
            costTotal: 0,
            costPrice: 0,
          });
        }
        if (data.attribute.customOptions.canUseCosts) {
          data.priceEq.forEach(p => {p.price = 0; p.total = 0});
        } else {
          data.priceEq = data.priceEq.filter(o => o.attributeId === attrId);
        }
      })
    }
    /*const uniqueGroups = _.uniqWith(_.map(dataRows, o => o.vehicle.attribute ?
      ({ name: o.vehicle.attribute.name, id: o.vehicle.attributeId, noGroup: false, price: 0, totalPrice: price, fixedPrice: false, enabled: true, missing: false }) :
      ({ name: o.vehicle.identifier, id: o.vehicle.id, noGroup: true, price: 0, totalPrice: price, fixedPrice: false, enabled: true, missing: false })), (itA, itB) =>
      itA.name === itB.name && itA.id === itB.id && itA.noGroup === itB.noGroup);*/
    const uniqueGroups = _.uniqWith(_.map(dataRows, o => {
      if (o.vehicle.attribute && o.vehicle.attribute.customOptions) {
        const customOptions = o.vehicle.attribute.customOptions;
        return customOptions.activityId ?
          ({ name: customOptions.activityName, id: customOptions.activityId, noGroup: false, price: 0, totalPrice: price, fixedPrice: false, enabled: true, missing: false }) :
          ({ name: o.vehicle.identifier, id: o.vehicle.id, noGroup: true, price: 0, totalPrice: price, fixedPrice: false, enabled: false, missing: false })
      } else {
        return ({ name: o.vehicle.identifier, id: o.vehicle.id, noGroup: true, price: 0, totalPrice: price, fixedPrice: false, enabled: false, missing: false });
      }
    }), (itA, itB) =>
      itA.name === itB.name && itA.id === itB.id && itA.noGroup === itB.noGroup);
    uniqueGroups.forEach((group) => {
      if (defaultPrice) {
        if (defaultPrice.groups) {
          const g = defaultPrice.groups.find(o => o.id === group.id && !group.noGroup);
          if (g) {
            group.enabled = g.enabled;
            group.price = g.price;
          } else {
            group.price = 0;
          }
        } else {
          group.price = price / uniqueGroups.length;
        }
      } else {
        group.price = price / uniqueGroups.length;
      }
    });

    if (defaultPrice) {
      if (defaultPrice.groups) {
        defaultPrice.groups.forEach(g => {
          const index = uniqueGroups.findIndex(o => o.id === g.id)
          if (index === -1 && g.enabled) {
            uniqueGroups.push({
              ...g,
              missing: true,
            })
          }
        })
      }
    }

    this.budgetObject = {
      dataRows,
      attrId,
      amount,
      price
    };
    this.setState({ groups: uniqueGroups });
  }

  splitBudgetContinue = (groups) => {
    this.groupCount = groups.filter(o => !o.missing).length;
    const { dataRows, attrId, amount } = this.budgetObject;

    // Assign price from groups to its row calculation
    dataRows.forEach(row => {
      const gIndex = groups.findIndex(o => {
        if (row.vehicle.attribute && row.vehicle.attribute.customOptions && row.vehicle.attribute.customOptions.activityId)
          return o.id === row.vehicle.attribute.customOptions.activityId;
        else
          return o.id === row.vehicle.id;
      })

      const g = groups[gIndex];
      row.gIndex = gIndex;

      const attribute = row.priceEq.find(o => o.attributeId === attrId);
      if (attribute)
        attribute.price = g.price;
    })
    groups.map((g, index) => {
      const groupRows = _.filter(dataRows, o => o.gIndex === index);
      groupRows.forEach(row => {
        if(row.priceEq) {
          const attribute = row.priceEq.find(o => o.attributeId === attrId);
          attribute.amount = (amount / groupRows.length);// * (price / attribute.price)) / vehiclesRows.length;
          attribute.total = attribute.amount * attribute.price;
        }
      })
    })
    request.post('/items/multipleDataRowEdit', {
      dataRows: dataRows,
    })
      .then(() => {
        this.budgetSplitActive = true;
        this.props.onDataRowsUpdated(dataRows);
        this.setState({ groups: [] });
      })
      .catch(() => {
        store.dispatch(show({
          title: 'Chyba !',
          level: 'error',
          autoDismiss: 5,
          children: (
            <div>
              {`Nepodařilo se uložit data`}
            </div>
          ),
        }, 'error'));
      })
  }

  render() {
    const {active, addressBook, dataRows, navbar, authenticate} = this.props;
    const { groups, errorMessages, warningMessages, invoicePreviewExisting, actualInvoiceTempIndex, activeSpace, invoiceTeplateEdit} = this.state;
    const resultsTotal = this.calculateTotal();

    if (addressBook.length === 0 || activeSpace === null)
      return null;
    const activeTemplate = activeSpace.setting.invoiceTeplates && actualInvoiceTempIndex !== null ? activeSpace.setting.invoiceTeplates[actualInvoiceTempIndex] : null;
    const abi = this.order && this.orderIds.length < 2 ? (this.order.abi) : [];
    console.log({abi, order: this.order, orderIds: this.orderIds});
    return <>
      {groups.length !== 0 && <BudgetModal
        totalPrice={this.budgetObject.price}
        disable={this.defaultPrices.length > 0}
        onDone={this.splitBudgetContinue}
        groups={groups}
        onCloseClick={() => this.setState({groups: []})}/>}
      <Modal dialogClassName="modal-90w" show={!!invoicePreviewExisting}
             onHide={() => this.setState({invoicePreviewExisting: null})}>
        <Modal.Header closeButton>
          {invoicePreviewExisting ? <h3 className='text-center'>{`Faktura ${invoicePreviewExisting.description.number}`}</h3> : null}
        </Modal.Header>
        <Modal.Body>
          {invoicePreviewExisting ? <InvoicePreview onCanceled={ () => {
            this.setState({invoicePreviewExisting: null});
            this.props.onStorno(invoicePreviewExisting);
            this.checkState(dataRows);
          }}
                                                    item={invoicePreviewExisting}/> : null}
        </Modal.Body>
      </Modal>
      <Modal dialogClassName="modal-90w" show={active}
             onHide={() => this.props.onHide()}>
        <Modal.Header closeButton={!this.state.submitActive} >
          <h3 className='text-center'>Faktura</h3>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <Row>
              <Col sm={3}>
                <h3>Podklady</h3>
                <FormGroup>
                  <ControlLabel>
                    Odběratel
                  </ControlLabel>
                  {abi.length < 2 ? <div>
                    <ItemsSelector defaultSelectedId={this.state.selAddressBookId}
                                   disabled={this.orderIds.length === 1}
                                   externList={addressBook}
                                   itemsToFetch={'addressBook'}
                                   newEditComponent={ AddressBookEdit }
                                   filterEnable
                                   value={'companyWithIc'}
                                   onSelected={(address) => this.setState({selAddressBookId: address.id})}/>
                  </div> : <div>
                    {abi.map(o => `${o.addressBookItem.company} (${o.percentage} %)`).join(', ')}
                  </div>}
                </FormGroup>
                {
                  navbar.spaces.length > 1 && <FormGroup>
                    <ControlLabel>
                      Prostředník
                    </ControlLabel>
                    <div title='Umožňuje fakturovat přes zpřátelenou firmu, vytvoří fakturu s odběretalem vybraného prostředníka
                    a vytvoří fakturu s finálním odběratelem v účetním systému prostředníka.
                    Zároveň vytvoří přijatou fakturu v účetním systému prostředníka'>
                      <ItemsSelector defaultSelectedId={this.state.selAddressSpaceId}
                                     appendItemsToStart={[{id: 0, name: 'Bez prostředníka'}]}
                                     externList={navbar.spaces.filter(o => o.id !== authenticate.activeSpaceId)}
                                     itemsToFetch={'addressBook'}
                                     filterEnable
                                     value={'name'}
                                     onSelected={(space) => this.setState({selAddressSpaceId: space.id})}/>
                    </div>
                  </FormGroup>
                }
                <FormGroup>
                  <ControlLabel>
                    Datum vystavení
                  </ControlLabel>
                  <div>
                    <SingleDatePicker
                      small
                      date={this.state.date} // momentPropTypes.momentObj or null
                      displayFormat={ dateFormatVisible }
                      isOutsideRange = {() => false}
                      onDateChange={date => {
                        if (this.orderIds.length === 1) {
                          this.setState({
                            date,
                            selectedDate: this.order.duePeriod ? moment(date).add(this.order.duePeriod, "days") : this.state.selectedDate,
                          });
                        } else this.setState({ date });

                      }} // PropTypes.func.isRequired
                      focused={this.state.datePickerFocused} // PropTypes.bool
                      onFocusChange={({ focused }) => this.setState({ datePickerFocused: focused })} // PropTypes.func.isRequired
                      id="your_unique_id" // PropTypes.string.isRequired,
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    Datum splatnosti
                  </ControlLabel>
                  <div>
                    <SingleDatePicker
                      small
                      date={this.state.selectedDate} // momentPropTypes.momentObj or null
                      displayFormat={ dateFormatVisible }
                      isOutsideRange = {() => false}
                      onDateChange={date => {
                        this.setState({ selectedDate: date });
                        //this.handleDateMove(date)
                      }} // PropTypes.func.isRequired
                      focused={this.state.datePicker2Focused} // PropTypes.bool
                      onFocusChange={({ focused }) => this.setState({ datePicker2Focused: focused })} // PropTypes.func.isRequired
                      id="your_unique_id" // PropTypes.string.isRequired,
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <ControlLabel>
                    Splatnost
                  </ControlLabel>
                  <div>
                    {
                      this.orderIds.length === 1 ? this.order.duePeriod : ''
                    }
                  </div>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <h3>Text Faktury</h3>
                <FormGroup>
                  <ControlLabel style={{marginRight: '15px'}}>
                    Výběr šablony
                  </ControlLabel>
                  <ItemsSelector
                    value='name'
                    customMenuItems={<>
                      <button onClick={(e) => {
                        e.preventDefault();
                        let list = activeSpace.setting.invoiceTeplates ? activeSpace.setting.invoiceTeplates.slice() : [];
                        list.push({
                          id: list.length,
                          name: 'Nová šablona',
                          text: '',
                        });
                        activeSpace.setting.invoiceTeplates = list;
                        this.setState({activeSpace, invoiceTeplateEdit: true})
                      }} className='transparentButton'>
                        <FontAwesome style={{marginRight: '15px', marginLeft: '10px'}} name='fas fas fa-plus'/>
                        Nová
                      </button>
                      <MenuItem divider/>
                    </>}
                    onSelected={(item, index) => {
                      this.setState({
                        actualInvoiceTempIndex: index,
                        invoiceText: this.generateText(item),
                      })
                    }}
                    externList={activeSpace.setting.invoiceTeplates ? activeSpace.setting.invoiceTeplates : []}/>

                </FormGroup>
                <UpdateButtonOnHover
                  disableCloseButton
                  useSubmitButton={false}
                  hideButtons={actualInvoiceTempIndex === null || (activeSpace.setting.invoiceTeplates ? activeSpace.setting.invoiceTeplates.length === 0 : false)}
                  deleteButton
                  editing={invoiceTeplateEdit}
                  requestActive={false}
                  onEditChange={(invoiceTeplateEdit) => {
                    this.setState({ invoiceTeplateEdit })
                  }}
                  onBack={() => this.setState({ invoiceTeplateEdit: false })}
                  onOk={() => {
                    this.requestSpaceSettingChange(activeSpace);
                    this.setState({ invoiceTeplateEdit: false, invoiceText: this.generateText(activeTemplate) });
                  }}
                  onDelete={() => {
                    let list = activeSpace.setting.invoiceTeplates.slice();
                    list.splice(actualInvoiceTempIndex, 1);
                    activeSpace.setting.invoiceTeplates = list;
                    this.setState({ activeSpace });
                    this.requestSpaceSettingChange(activeSpace);
                  }}
                  editingComponents={
                    activeTemplate ?
                    <>
                      <FormGroup>
                        <ControlLabel>
                          Jméno šablony
                        </ControlLabel>
                        <FormControl type="text"
                                     value={activeTemplate.name}
                                     placeholder="Jméno šablony"
                                     onChange={(e) => {
                                      let list = activeSpace.setting.invoiceTeplates.slice();
                                      list[actualInvoiceTempIndex].name = e.target.value;
                                      activeSpace.setting.invoiceTeplates = list;
                                      this.setState({ activeSpace });
                                     }}/>
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          Text šablony
                        </ControlLabel>
                        <textarea style={{ width: '100%', minHeight: '160px', padding:'10px'}}
                                  onChange={(e) => {
                                    let list = activeSpace.setting.invoiceTeplates.slice();
                                    list[actualInvoiceTempIndex].text = e.target.value;
                                    activeSpace.setting.invoiceTeplates = list;
                                    this.setState({ activeSpace });
                                  }}
                                  value={activeTemplate.text} />
                      </FormGroup>
                      <FormGroup>
                        <ControlLabel>
                          Aplikační tagy
                        </ControlLabel>
                        <p>
                          <b>%dfrom%</b> - Datum plnění Od, <b>%dto%</b> - Datum plnění Do, <b>%onum%</b> - Číslo objednávky/smlouvy
                        </p>
                      </FormGroup>
                    </> : null
                  }>
                  <textarea style={{ marginBottom: '20px', width: '100%', minHeight: '160px', padding:'10px'}}
                            onChange={(e) => {
                              this.setState({
                                invoiceText: e.target.value,
                              });
                            }}
                            value={this.state.invoiceText} />
                </UpdateButtonOnHover>
              </Col>
              <Col sm={3}>
                <h3>Chybová hlášení</h3>
                <ul>
                  {
                    Object.keys(errorMessages).map((key) => {
                      return <li style={{fontSize: 12, color: 'red'}}>
                        {errorMessages[key]}
                      </li>
                    })
                  }
                </ul>
                <ul>
                  {
                    Object.keys(warningMessages).map((key) => {
                      return <li style={{fontSize: 12, color: 'orange'}}>
                        {warningMessages[key]}
                      </li>
                    })
                  }
                </ul>
              </Col>
            </Row>
            <Row>
              <Col sm={6} smOffset={3}>
                <BudgetOption
                  onPriceBudgetDone={this.splitBudget.bind(this)}
                  disablePrice={this.defaultPrices.length > 0}
                  priceAttributes={this.defaultPrices.length > 0 ? this.defaultPrices : this.props.priceAttributes} />
                <div className='dataTable' style={{ marginTop: '10px',
                  width: '100%',
                  maxHeight: '500px',
                  marginBottom: '10px',
                  overflowY: 'auto',
                  fontSize: '11px' }}>
                  <table >
                    <thead>
                      <tr>
                        <th></th>
                        <th>Datum</th>
                        <th>Text</th>
                        <th>M.J</th>
                        <th>Množství</th>
                        <th>Cena / J</th>
                        <th>Cena / M</th>
                        <th>Náklady / J</th>
                        <th>Náklady / M</th>
                        <th>Zisk</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      Object.keys(dataRows).map(key =>
                        <InvoiceItem item={dataRows[key]}
                                     highlightedVehicle={this.state.highlightedVehicle}
                                     onHighlightRequested={(highlightedVehicle) => {
                                       if (highlightedVehicle !== this.state.highlightedVehicle)
                                         this.setState({highlightedVehicle});
                                       else
                                         this.setState({highlightedVehicle: null});
                                     }}
                                     onEditRequired={this.props.onEditRequired}
                                     attributes={this.props.priceAttributes}/>
                      )
                    }
                    </tbody>
                  </table>
                </div>
                <LoadingOrRender requestActive={this.state.submitActive}>
                  <SubmitButton disabled={errorMessages.length > 0} bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                    {`Odeslat ${warningMessages.length > 0 ? 'a ignorovat upozornění' : ''} ${this.groupCount > -1 ? '(Úkolová faktura)' : ''}`}
                  </SubmitButton>
                </LoadingOrRender>
              </Col>
              <Col sm={3}>
                {Object.keys(resultsTotal).map(key => {
                  const total = resultsTotal[key].map(o => o.total).reduce((total, num) => {return total + num}, 0);
                  const totalCost = resultsTotal[key].map(o => o.totalCost).reduce((total, num) => {return total + num}, 0);
                  return <>
                    <h4>{key === 'resultsMat' ? 'Materiál' : 'Práce'}</h4>
                    <div className='dataTable'>
                      <table>
                        <tbody>
                        <tr>
                          <th>M.J</th>
                          <th>Množství</th>
                          <th>Cena</th>
                          <th>Náklady</th>
                          <th>Zisk</th>
                        </tr>
                        {
                          resultsTotal[key].map(result => {
                            return <tr>
                              <td>{result.attribute.name}</td>
                              <td>{roundFloatTwoDigit(result.totalAmount)}</td>
                              <td>{roundFloatTwoDigit(result.total)}</td>
                              <td>{roundFloatTwoDigit(result.totalCost)}</td>
                              <td>{roundFloatTwoDigit(result.total - result.totalCost)}</td>
                            </tr>
                          })
                        }
                        <tr>
                          <td colSpan={2}></td>
                          <td style={{fontWeight: 'bold'}}>{roundFloatTwoDigit(total)}</td>
                          <td style={{fontWeight: 'bold'}}>{roundFloatTwoDigit(totalCost)}</td>
                          <td style={{fontWeight: 'bold'}}>{roundFloatTwoDigit(total - totalCost)}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                })}

              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  }
}

const mapStateToProps = state => {
  return {
    authenticate: state.authenticate,
    navbar: state.navbar,
  }
};

Invoice.propTypes = {
  active: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onStorno: PropTypes.func.isRequired,
  priceAttributes: PropTypes.arrayOf(PropTypes.object),
  materialAttributes: PropTypes.arrayOf(PropTypes.object),
  storeAttributes: PropTypes.arrayOf(PropTypes.object),
  addressBook: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEditRequired: PropTypes.func.isRequired,
  rowEditIsActive: PropTypes.bool,
  authenticate: PropTypes.object,
  navbar: PropTypes.object,
};

Invoice.defaultProps = {
  rowEditIsActive: false,
};

export default connect(mapStateToProps)(Invoice);

export {
  InvoiceItem
}