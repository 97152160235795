import {Component} from "react";
import React from "react";
import { BaseEditPropTypes } from '../CommonListContainer';
import { Form, FormControl, FormGroup, Col, Row, ControlLabel, Checkbox } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SubmitButton from "../../loginsrc/SubmitButton";
import { LoadingOrRender } from '../../loginsrc/Profile';
import request from 'axios';
import {BackHeader} from "../Setting";
import ContentWithFixedHeader from "../ContentWithFixedHeader";

const dispatchLabelMap = {
  canNote: 'Sloupec s poznámkou',
  canOrderNumber: 'Sloupec číslo zakázky',
  canVehicle: 'Sloupec se stroji',
  canRealVehicle: 'Používat sloupec se skutečnými stroji dodavatele',
  canUser: 'Sloupec s uživateli/dodavateli',
  canTool: 'Sloupec s vybavením',
  canLoading: 'Sloupce nákladky/výkladky',
  canCompany: 'Sloupec "Firma"',
  canConstruction: 'Sloupec "Stavba"',
  canContactPerson: 'Sloupec "Kontaktní osoba"',
  canTime: 'Sloupce pro záznam docházky (od, do, přestávka)',
  canFraction: 'Sloupec "Frakce"',
  canCalculation: 'Sloupce pro kalkulaci',
  canUseCosts: 'Sloupce pro náklady (např. subdodavatel), a zisk'
};

class Rights2 extends Component {

  handleChange(type, value) {
    switch(type) {
      case 'view':
        if(!value) {
          this.props.onBooleanUpdate('update', value);
          this.props.onBooleanUpdate('delete', value);
        }
        break;
      case 'update':
        if(value) {
          this.props.onBooleanUpdate('view', value);
        } else {
          this.props.onBooleanUpdate('delete', value);
        }
        break;
      case 'delete':
        if(value) {
          this.props.onBooleanUpdate('view', value);
          this.props.onBooleanUpdate('update', value);
        }
        break;
    }
    this.props.onBooleanUpdate(type, value);
  }

  render() {
    return <tr>
      <td><div style={{fontSize: '14px', fontWeight: 'bold'}}>
        {this.props.name}
      </div></td>
      <td>
        <Checkbox checked={this.props.item.view}
                  onChange={(e) => this.handleChange('view',e.target.checked)}/>
      </td>
      <td>
        <Checkbox checked={this.props.item.update}
                  onChange={(e) => this.handleChange('update',e.target.checked)}/>
      </td>
      <td>
        <Checkbox checked={this.props.item.delete}
                  onChange={(e) => this.handleChange('delete',e.target.checked)}/>
      </td>
      {
        typeof this.props.other !== 'undefined' ?
          Object.keys(this.props.other).map((key) => {
            if (this.props.other[key] === 'nop' || typeof this.props.other[key] === 'undefined')
              return <td key={key}>
                ---
              </td>;
            return <td key={key}>
              <Checkbox checked={this.props.item[this.props.other[key]]}
                        onChange={(e) => this.props.onBooleanUpdate(this.props.other[key],e.target.checked)}>
                {this.props.customLabels[key]}
              </Checkbox>
            </td>
          }) : [<td>---</td>,<td>---</td>, <td>---</td>]
      }
    </tr>
  }
}

Rights2.propTypes = {
  name: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  onBooleanUpdate: PropTypes.func.isRequired,
  other: PropTypes.array,
  customLabels: PropTypes.array,
};

Rights2.defaultProps = {
  customLabels: ['', '', '']
}

export default class RoleEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: {view: false, update: false, delete: false,},
      addressBook: {view: false, update: false, delete: false,},
      roles: {view: false, update: false, delete: false,},
      vehicles: {view: false, update: false, delete: false,},
      phm: {view: false, update: false, delete: false,},
      attendance: {view: false, update: false, delete: false, onlySelf: false, attributes: false},
      tasks: {view: false, update: false, delete: false, onlySelf: false, canConfirm: false},
      tools: {view: false, update: false, delete: false,},
      sms: {view: false, update: false, delete: false, onlySelf: false},
      orders: {view: false, update: false, delete: false},
      salary: {view: false, update: false, delete: false},
      creditCard: {view: false, update: false, delete: false, pin: false},
      dispatching: {view: false, update: false, delete: false, attributes: false,
        other: {
          canNote: true,
          canOrderNumber: true,
          canVehicle: true,
          canRealVehicle: true,
          canUser: true,
          canTool: true,
          canLoading: true,
          canCompany: true,
          canConstruction: true,
          canContactPerson: true,
          canTime: true,
          canFraction: true,
          canCalculation: true,
          canUseCosts: true,
        }
      },
      visibleForCalendar: 0,
      isContactUser: 0,
      name: '',
      isAdmin: false,
      requestActive: this.props.match.params.id !== 'new',
      submitActive: false,
    }
  }

  booleanUpdate(which, item, value) {
    let rights = this.state[which];
    rights[item] = value;
    this.setState({
      [which]: rights,
    })
  }

  componentWillMount() {
    if (this.props.match.params.id !== 'new' ) {
      request.get('/items/roles/' + this.props.match.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState(res.data);
            this.setState({ requestActive: false })
          }
        })
        .catch(err => {
          this.setState({ requestActive: false });
        })
    }
  }

  render() {
     return (
       <div className='editForm'>
         <ContentWithFixedHeader header={
           <BackHeader
             fontSize='18px'
             headerText={this.state.name === '' ? 'Projektová role' : this.state.name}
             onBackClick={() => this.props.history.replace(
               this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
         }>
           <div className='nest'>
             <LoadingOrRender requestActive={this.state.requestActive}>

               <Form onSubmit={(e) => {
                 e.preventDefault();
                 this.props.onSubmit(this.props.match.params.id, this, this.state);
               }}>
                 <Row>

                   <Col sm={3}>
                     <FormGroup>
                       <h3>Název</h3>
                       <FormControl type='text'
                                    value={this.state.name}
                                    onChange={(e) => this.setState({ name: e.target.value })}
                                    placeholder='Jméno'
                       />
                     </FormGroup>
                     <Checkbox checked={this.state.visibleForCalendar === 1}
                               onChange={(e) => this.setState({
                                 visibleForCalendar: e.target.checked ? 1 : 0,
                               })}>
                       Zobrazovat v kalendáři jako strojníka ?
                     </Checkbox>
                     <Checkbox checked={this.state.isContactUser === 1}
                               onChange={(e) => this.setState({
                                 isContactUser: e.target.checked ? 1 : 0,
                               })}>
                       Zobrazovat v kalendáři jako kontaktní osobu pro zakázky ?
                     </Checkbox>
                     <Checkbox checked={this.state.isProjectManager === 1}
                               onChange={(e) => this.setState({
                                 isProjectManager: e.target.checked ? 1 : 0,
                               })}>
                       Projektový manažer pro zakázky ?
                     </Checkbox>
                   </Col>
                   <Col sm={5}>
                     <h3>Práva</h3>
                     <div className='dataTable'>
                       <table>
                         <tbody>
                         <tr>
                           <th>---</th>
                           <th>Čtení</th>
                           <th>Úprava</th>
                           <th>Mazání</th>
                           <th>Pouze své</th>
                           <th>Potvrzovat</th>
                           <th>Atributy</th>
                         </tr>
                         <Rights2 item={this.state.users}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('users', item, value)}
                                  name='Uživatelé'/>
                         <Rights2 item={this.state.roles}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('roles', item, value)}
                                  name='Role'/>
                         <Rights2 item={this.state.vehicles}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('vehicles', item, value)}
                                  name='Auta'/>
                         <Rights2 item={this.state.attendance}
                                  other={['onlySelf', 'nop', 'attributes']}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('attendance', item, value)}
                                  name='Dovolené'/>
                         <Rights2 item={this.state.sms}
                                  other={['onlySelf', 'nop', 'nop']}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('sms', item, value)}
                                  name='SMS'/>
                         <Rights2 item={this.state.tasks}
                                  other={['onlySelf', 'canConfirm', 'nop']}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('tasks', item, value)}
                                  name='Úkoly'/>
                         <Rights2 item={this.state.tools}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('tools', item, value)}
                                  name='Vybavení'/>
                         <Rights2 item={this.state.dispatching}
                                  other={['nop', 'nop', 'attributes']}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('dispatching', item, value)}
                                  name='Kalendář'/>
                         <Rights2 item={this.state.addressBook}
                                  other={['nop', 'nop', 'nop']}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('addressBook', item, value)}
                                  name='Adresář'/>
                         <Rights2 item={this.state.orders}
                                  other={['nop', 'nop', 'nop']}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('orders', item, value)}
                                  name='Zakázky'/>
                         <Rights2 item={this.state.phm}
                                  other={['nop', 'nop', 'nop']}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('phm', item, value)}
                                  name='PHM kontorla'/>
                         <Rights2 item={this.state.salary}
                                  other={['nop', 'nop', 'nop']}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('salary', item, value)}
                                  name='Mzdy'/>
                         <Rights2 item={this.state.creditCard}
                                  other={['pin', 'nop', 'nop']}
                                  customLabels={['Pin', '', '']}
                                  onBooleanUpdate={(item,value) => this.booleanUpdate('creditCard', item, value)}
                                  name='Platební karty'/>
                         </tbody>
                       </table>
                     </div>
                   </Col>
                   <Col sm={4}>
                     <h3 style={{marginBottom: '10px', color: 'darkred'}}>
                       Individuální nastavení pro dispečink
                     </h3>
                     <p style={{marginBottom: '30px', color: 'darkred'}}>Pokud nebude sloupec zaškrtnut uživatel neuvidí sloupec!</p>
                     {
                       Object.keys(dispatchLabelMap).map((key) => {
                         return <Checkbox checked={this.state.dispatching.other[key]}
                                          key={key}
                                          onChange={(e) => {
                                            let dis = this.state.dispatching;
                                            dis.other[key] = e.target.checked;
                                            this.setState({
                                              dispatching: dis,
                                            })
                                          }}
                                          style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '-7px' }}>
                           {dispatchLabelMap[key]}
                         </Checkbox>
                       })
                     }
                   </Col>
                 </Row>
                 <Row>
                   <Col smOffset={4} sm={4}>
                     <SubmitButton disabled={this.state.isAdmin} bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                       {this.state.isAdmin ? 'Nelze Upravit' : 'Potvrdit'}
                     </SubmitButton>
                   </Col>
                 </Row>
               </Form>
             </LoadingOrRender>
           </div>
         </ContentWithFixedHeader>
       </div>
     )
  }
}

export {
  dispatchLabelMap,
}

RoleEdit.propTypes = BaseEditPropTypes;
