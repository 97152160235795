import {useContextSelector} from "use-context-selector";
import {ResultsContext, setEditedRow, setEditedRowIndex} from "../reducerResults";
import {Form, Modal} from "react-bootstrap";
import {FormEvent, useState} from "react";
import HeaderGenerator from "./HeaderGenerator";
import moment from "moment";
import DataRow from "../DataRow";
import {useDispatch, useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRows, setSingleEditRow} from "@cml/redux-store";
import SubmitButton from "../../../loginsrc/SubmitButton";
import store from "../../../redux/store";
import React from 'react';
import {IDispatchingAttributesCustom} from "@cml/types";
import { show } from "react-notification-system-redux";
import request from 'axios';
import {
  getAttributes,
} from "../../../fetchApi/dispatching";
import {EDataRow, Vehicles} from "@cml/models";
import { getCalculation } from "./PriceCalculation";
import DrDate from "../dataRowComponents/DrDate";
import DrAttributeSelect from "../dataRowComponents/DrAttributeSelect";

interface Props {

}
const swrOption = {
  revalidateOnFocus: false,
  revalidateOnMount: true,
}


export default function EditSingleRowModal() {

  const editedDataRowIndex = useContextSelector(ResultsContext, v => v.state.editedDataRowIndex);
  const dispatchResult = useContextSelector(ResultsContext, v => v.dispatch);


  const editedDataRow = useSelector((state: {dispatching: DataRowsState}) =>
    state.dispatching.singleEditRow) as EDataRow;


  const dispatch = useDispatch();

  const authenticate = useSelector(
    (state: { authenticate: AuthenticateState}) => state.authenticate);

  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes('price', swrOption);
  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes('material', swrOption);
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes('store', swrOption);


  const [rowSaveRequestActive, setRowSaveRequestActive] = useState(false);

  const submitOneRow = (e: FormEvent<Form>) => {
    e.preventDefault();
    if (!(editedDataRow.attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook) {
      if ((editedDataRow.userId === 0 && editedDataRow.userId_a === 0
        && (editedDataRow.attribute.customOptions as IDispatchingAttributesCustom).canUser)) {
        dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
          children: (
            <div>
              Není vybrán strojník
            </div>
          ),
        }, 'error'));
        return;
      }
    } else {
      if ((editedDataRow.addressBookId === 0
        && (editedDataRow.attribute.customOptions as IDispatchingAttributesCustom).canUser)) {
        dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
          children: (
            <div>
              Není vybrán dodavatel
            </div>
          ),
        }, 'error'));
        return;
      }
    }
    if ((editedDataRow.vehicleId === 0 && editedDataRow.vehicleId_a === 0
      && (editedDataRow.attribute.customOptions as IDispatchingAttributesCustom).canVehicle)) {
      dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
        children: (
          <div>
            Není vybrán stroj
          </div>
        ),
      }, 'error'));
      return;
    }
    setRowSaveRequestActive(true);
    request.post('/items/singleDataRowEdit', {
      dataRow: editedDataRow,
      tools: [],
    })
      .then((res) => {
        if (res.status === 200 && editedDataRow !== null) {
          let dataRows = store.getState().dispatching.dataRows.slice() as EDataRow[];

          let newRow = {
            ...res.data,
            calculation: getCalculation(res.data, {
              attribute: res.data.attribute,
              // @ts-ignore
              priceAttributes: priceAttributes,
              // @ts-ignore
              materialAttributes: materialAttributes,
              storeAttributes: storeAttributes,
              defaultMaterialTable: res.data.order ? res.data.order.other.materialTable : undefined,
            })
          }
          if (editedDataRowIndex > -1)
            dataRows[editedDataRowIndex] = newRow;
          else
            dataRows.push(newRow);
          dataRows = dataRows.map(d => ({
            ...d,
            isTimeOverlaps: newRow.date === d.date ?
              false: d.isTimeOverlaps,
          }) as EDataRow);
          setRowSaveRequestActive(false);
          dispatch(setSingleEditRow(null));
          dispatch(setDataRows(dataRows.sort((a,b) => moment(a.date).valueOf() - moment(b.date).valueOf())));
          dispatchResult(setEditedRowIndex(-1));
          dispatchResult(setEditedRow(null));

        }
      }).catch((err) => {
      setRowSaveRequestActive(false);
      if (err.status === 406) {
        store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
          children: (
            <div>
              Vybraný strojník má tento den dovolenou !
            </div>
          ),
        }, 'error'));
      } else {
        store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
          children: (
            <div>
              {`Server hlásí chybu ${err.status}`}
            </div>
          ),
        }, 'error'));
      }

    });
  }

  if (!editedDataRow) {
    return null;
  }

  return <Modal dialogClassName="modal-90w" show={editedDataRow !== null}
                onHide={() => {
                  const list = store.getState().dispatching.dataRows;
                  // @ts-ignore
                  list[editedDataRowIndex] = {
                    ...list[editedDataRowIndex],
                    ...editedDataRow,
                  }
                  dispatchResult(setEditedRowIndex(-1));
                  dispatchResult(setEditedRow(null));
                  dispatch(setDataRows(list));
                  dispatch(setSingleEditRow(null));
                }}>
    <Modal.Header closeButton>
      <h3 className='text-center'>Úprava řádku</h3>
    </Modal.Header>
    <Modal.Body>
      <Form style={{ overflowX: 'auto', height: '800px' }} onSubmit={submitOneRow}>
        <div className='dataTable' style={{ width: '100%', fontSize: '11px' }}>
          {/* @ts-ignore */}
          <table align='center'>
            <tbody>
              {editedDataRow.attributeId === 0 ? <DataRow singleRowEditMode
                //data={editedDataRow}
                                                          attribute={editedDataRow.attribute}
                                                          index={editedDataRowIndex}
                                                          shouldRender={true}
                                                          onAddConnWithInvoice={() => {}}
                                                          onLogRequested={() => {}}
                                                          onOrderRequired={() => {}}/> :
                <>
                  <HeaderGenerator
                    selectedDate={moment(editedDataRow.date)}
                    singleRowEditMode
                    unresolvedMode={false}
                    attribute={editedDataRow.attribute} onOrderRequired={() => {}}/>
                  <DataRow singleRowEditMode
                    //data={editedDataRow}
                    attribute={editedDataRow.attribute}
                    index={editedDataRowIndex}
                    shouldRender={true}
                    onAddConnWithInvoice={() => {}}
                    onLogRequested={() => {}}
                    onOrderRequired={() => {}}/>
                </>

              }
              {(editedDataRow.id === -1) && <tr>
                 <DrDate/>
                 <DrAttributeSelect/>
              </tr>}
            </tbody>
          </table>
        </div>
        <SubmitButton style={{maxWidth: '300px', margin: 'auto', marginTop: '10px'}}
                      // @ts-ignore
                      disabled={!authenticate.actualUserRole.dispatching.update}
                      bsClass='btn btn-primary btn-block'
                      loading={rowSaveRequestActive}>
          {'Uložit'}
        </SubmitButton>
      </Form>

    </Modal.Body>
  </Modal>

}