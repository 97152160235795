import {
  Checkbox,
  ControlLabel,
  FormGroup,
  MenuItem,
  Nav,
  Navbar,
  NavItem,
  Row,
  Col,
  Button,
  DropdownButton, Collapse
} from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import FontAwesome from "react-fontawesome";
import {DateRangePicker} from "react-dates";
import { dateFormatVisible } from "../../../AppObjects";
import ItemsSelector from "../../ItemsSelector";
import {SmallOrderPreview} from "../../orders/Preview";
import {SmallUserPreview} from "../../users/Preview";
import { Moment } from "moment";
import {
  getAddressBook,
  getAttributes, getCompanies, getConstructions, getOrders,
  getTools,
  getUsersWithSpecificRoleWhere,
  getVehicles
} from "../../../fetchApi/dispatching";
import {Orders, Vehicles} from "@cml/models";
import Autocomplete from "react-autocomplete";
import MapAutoComplete from "../../MapAutoComplete";
import {useSelector} from "react-redux";
import {AuthenticateState} from "@cml/redux-store";

interface PdpTriStateProps {
  onChangePdp: (e: any) => void,
  pdpTristate: number,
  onChangeWithoutPdp: (e: any) => void,
};

function PdpTriState(props: PdpTriStateProps) {

  return <>
    <div style={{marginLeft: "15px"}}>
      <Checkbox
        style={{marginTop: '5px', marginBottom: '0px'}}
        onChange={props.onChangePdp}
        checked={props.pdpTristate === 2}>
        PDP
      </Checkbox>
      <Checkbox
        style={{marginTop: '0px', marginBottom: '0px'}}
        onChange={props.onChangeWithoutPdp}
        checked={props.pdpTristate === 1}>
        Bez PDP
      </Checkbox>
    </div>
    <MenuItem divider/>
  </>;
}



export interface IFilter {
  from: Moment | null;
  to: Moment | null;
  userId: number[];
  userId_a: number[];
  contactUserId: number[];
  contactUserId_a: number[];
  invoiceState: number;
  salaryState: number;
  projectLeaderId: number[];
  vehicleId: number[],
  vehicleId_a: number[],
  company?: string,
  construction?: string,
  attributeId: number[];
  addressBookId: number[];
  orderId: number[];
  pdpTristate: number;
  pdpTristateReal: number;
  loading: string;
  unloading: string;
  materialTable?: string;
  showSynchronizedDataFromOrder: boolean;
}

export interface IFilterOrder {
  value: string;
  ascending: boolean;
}

interface Props {
  onFilterChanged: (f?: IFilter, o?: IFilterOrder) => void;
  swrOption?: object;
  appendArchived?: boolean;
  defaultVisible?: boolean;
  buttonMode?: boolean;
  orderingEnabled?: boolean;
}

export const dropDownOrder: {[key: string]: string} = {
  'order': 'Zakázka',
  'order_custom': 'Zakázka včř',
  'vehicle': 'Stroj',
  'user': 'Strojník',
  'date': 'Datum',
  'loading': 'Nákladka',
  'unloading': 'Výkladka',
  'company': 'Firma',
  'construction': 'Stavba',
  'orderForVis': 'Řadit dle uloženého pořadí',
}





export default function DispatchingFilter({ onFilterChanged,
                                            swrOption,
                                            buttonMode,
                                            orderingEnabled,
                                            defaultVisible,
                                            appendArchived }: Props) {
  const [visible, setVisible] = useState(!!defaultVisible);
  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | null>(null);
  const [filterActivated, setFilterActivated] = useState(false);
  const authenticate = useSelector((state: {authenticate: AuthenticateState}) => state.authenticate);

  const [ordering, setOrdering] = useState<IFilterOrder>({
    value: 'order',
    ascending: true,
  })

  const [filter, setFilter] = useState<IFilter>({
    from: null,
    to: null,
    attributeId: [],
    orderId: [],
    userId_a: [],
    userId: [],
    addressBookId: [],
    contactUserId: [],
    contactUserId_a: [],
    projectLeaderId: [],
    invoiceState: 0,
    salaryState: 0,
    vehicleId: [],
    vehicleId_a: [],
    pdpTristate: 0,
    pdpTristateReal: 0,
    loading: '',
    unloading: '',
    showSynchronizedDataFromOrder: false,
  })


  const prevFilterActivated = useRef(false);

  useEffect(() => {
    if(prevFilterActivated.current && !filterActivated)
      onFilterChanged(undefined, undefined)
    prevFilterActivated.current = filterActivated;
    if(filterActivated)
      if(!filter.from)
        onFilterChanged(filter, ordering);
      else if(filter.to)
        onFilterChanged(filter, ordering);
  }, [filter, filterActivated, ordering])

  const { contactUsers: users, isValidating: usersValidating } = getUsersWithSpecificRoleWhere(
    { params: { roleWhere: { visibleForCalendar: 1}, appendArchived }}, {
      revalidateOnFocus: false,
      revalidateOnMount: true,
    });
  const { contactUsers, isValidating: contactValidating } = getUsersWithSpecificRoleWhere(
    { params: { roleWhere: { isContactUser: 1 }, appendArchived }}, swrOption && swrOption);
  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { allDetails: 0, onlyForDispatching: 1, appendArchived  }}, swrOption && swrOption);
  const { tools, isValidating: toolsValidating } = getTools(
    { params: { where: { isAvailable: 1 } }}, swrOption && swrOption)
  const { attributes, isValidating: attributesValidating } = getAttributes(
    'dispatching', swrOption && swrOption);
  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes(
    'price', swrOption && swrOption);
  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes(
    'material', swrOption && swrOption);
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store', swrOption && swrOption);
  const { addressBook, isValidating: addressValidating } = getAddressBook(swrOption && swrOption);
  const { orders, isValidating: ordersValidating } = getOrders(
    { params: { where: { isConfirmed: 0 }}}, swrOption && swrOption);
  const { companies } = getCompanies(swrOption && swrOption);
  const { constructions } = getConstructions(swrOption && swrOption);

  const handleDateChange = (startDate: Moment | null, endDate: Moment | null) => {
    setFilter({
      ...filter,
      from: startDate,
      to: endDate,
    });
  }

  if(!users || !contactUsers || !vehicles || !tools || !attributes || !orders
    || !priceAttributes || !storeAttributes || !materialAttributes || !addressBook
    || !constructions || !companies
  )
    return null;

  return <>
    <button style={{
      position: 'fixed',
      height: 50,
      fontSize: 20,
      zIndex: 1004,
      marginRight: 15,
      display: !visible ? undefined : 'none',
      top: 50,
      right: 0,
      color: 'black' }}
            onClick={() => setVisible(true)} className='transparentButton2'>
      <FontAwesome name={'bars'}/>
    </button>
  <Navbar
    style={{ display: visible ? undefined : 'none' }}

    id="sideFilter"
  >

    <Navbar.Collapse className='text-left' style={{ }}>
      <div style={{ width: '100%', color: '#eeeeee',
        marginTop: '10px', fontSize: '20px' }}
           className='text-right'>
        <button style={{ color: 'black' }}
                onClick={() => setVisible(false)} className='transparentButton2'>
          <FontAwesome name={'times'}/>
        </button>
      </div>
      <Row>
        <Col sm={6}>
            {buttonMode ? <div style={{ marginBottom: '15px' }}>
              <Button
                onClick={() => onFilterChanged(filter,ordering)}
                bsStyle='primary'>
              Hledej
            </Button>
            </div> : <div style={{ marginLeft: '15px' }}>
              <Checkbox
              checked={filterActivated}
              onChange={e => setFilterActivated((e.target as HTMLInputElement).checked)}
            >
              Aktivovat filter
            </Checkbox>
            </div>}
        </Col>
        {
          orderingEnabled && <Col sm={6}>
            <div>
              <ControlLabel>Řazení dle</ControlLabel>
              <div>
                <DropdownButton id='validState'
                                onSelect={(key: any) => {
                                  setOrdering({
                                    ...ordering,
                                    value: key as string
                                  })
                                }}
                                title={dropDownOrder[ordering.value]}>
                  {Object.keys(dropDownOrder).map((key) => <MenuItem key={key} eventKey={key}>{
                    dropDownOrder[key]}</MenuItem>)}
                </DropdownButton>
              </div>
            </div>
          </Col>
        }
      </Row>
      <div style={{ marginLeft: '15px' }}>
        <FormGroup >
          <ControlLabel>
            Číslo zakázky
          </ControlLabel>
          <div>
            <ItemsSelector externList={orders.sort((a,b) => a.id - b.id).sort((a,b) =>
              (a.parentOrderId ? a.parentOrderId : a.id) - (b.parentOrderId ? b.parentOrderId : b.id))}
                           pullRight
                           style={{ minWidth: 600 }}
                           preview={SmallOrderPreview}
                           otherPreviewProps={{offsetIfSuborder: true}}
                           isFilterable
                           appendItemsToStart={[
                             {id: 0, label: 'Všechny'},
                           ]}
                           drawFirstWithDividers
                           onListSplitterCondition={(index: number,item: {id: number}) => item.id !== 0}
                           filterEnable
                           value={'label'}
                           overrideListChanged={(list: Orders[], key: number) => {
                             if(list[key] && list[key].suborders && list[key].suborders.length > 0) {
                               list[key].suborders.forEach(l => {
                                 const index = list.findIndex(ll => ll.id === l.id);
                                 if(index > -1) {
                                   // @ts-ignore
                                   list[index].filtered = list[key].filtered;
                                 }
                               })
                             }
                             return list;
                           }}
                           listChanged={(list: number[]) => {
                             setFilter({...filter, orderId: list})
                           }}
                           />
          </div>
        </FormGroup>
        {buttonMode && <FormGroup>
          <Checkbox
            style={{marginTop: '0px', marginBottom: '0px'}}
            onChange={(e) =>
              setFilter({...filter, showSynchronizedDataFromOrder: (e.target as HTMLInputElement).checked})}
            checked={filter.showSynchronizedDataFromOrder}>
            Ukaž ceny dle nastavených v zakázce
          </Checkbox>
        </FormGroup>}
      </div>
      <div style={{ marginLeft: '15px' }}>
        <FormGroup>
          <ControlLabel>
            Období
          </ControlLabel>
          <div>
            <DateRangePicker
              displayFormat={ dateFormatVisible }
              startDate={filter.from} // momentPropTypes.momentObj or null,
              startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
              startDatePlaceholderText='Začátek'
              endDatePlaceholderText='Konec'
              isOutsideRange = {() => false}
              small
              minimumNights={0}
              endDate={filter.to} // momentPropTypes.momentObj or null,
              endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => handleDateChange( startDate, endDate )} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
            />
            <Button onClick={() => handleDateChange(null, null)}>
              Vymazat datumy
            </Button>
          </div>
        </FormGroup>
      </div>
      <Row>
        <Col sm={6}>
          <FormGroup >
            <ControlLabel>
              Projektový manažer
            </ControlLabel>
            <div>
              <ItemsSelector itemsToFetch={'usersWithSpecificRoleWhere'}
                             pullRight
                             isFilterable
                             defaultQuery={{
                               roleWhere: { isProjectManager: 1}
                             }}
                             filterEnable
                             appendItemsToStart={[
                               {id: 0, nameSurname: 'Všichni',name: 'Všichni', surname: ''},
                             ]}
                             drawFirstWithDividers
                             onListSplitterCondition={(index: number,item: {id: number}) => item.id !== 0}
                             preview={SmallUserPreview}
                             value={'nameSurname'}
                             listChanged={(list: number[]) => setFilter({...filter, projectLeaderId: list})}
                             otherPreviewProps={{style: {display: 'inline'}}}
              />
            </div>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup >
            <ControlLabel>
              Atribut dispečinku
            </ControlLabel>
            <div>
              <ItemsSelector itemsToFetch={'attributes/dispatching'}
                             pullRight
                             listChanged={(list: number[]) => {
                               setFilter({...filter, attributeId: list});
                             }}
                             isFilterable
                             appendItemsToStart={[{id: 0, name: 'Všechny', color: '#ffffff'},]}
                             drawFirstWithDividers
                             onListSplitterCondition={(index: number, item: {id: number}) => item.id !== 0}
                             value={'name'}
              />

            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <FormGroup >
            <ControlLabel>
              Stroj
            </ControlLabel>
            <div>
              <ItemsSelector externList={vehicles}
                             filterEnable
                             isFilterable
                             fullSelectedList
                             appendItemsToStart={[
                               {id: 0, identifier: 'Všechny'},
                             ]}
                             listSplitter={[
                               {header: [
                                   <MenuItem header>Stroje</MenuItem>,
                                   <PdpTriState onChangePdp={(e) => {
                                     if (e.target.checked) setFilter({
                                       ...filter,
                                       pdpTristate: e.target.checked ? 2 : 0,
                                     });
                                   }} pdpTristate={filter.pdpTristate} onChangeWithoutPdp={(e) => {
                                     if (e.target.checked) setFilter({
                                       ...filter,
                                       pdpTristate: e.target.checked ? 1 : 0,
                                     });
                                   }}/>,
                                 ]},
                               {header: [
                                   <MenuItem divider />,
                                   <MenuItem header>Stroje sub.</MenuItem>,
                                 ]},
                               {header: [
                                   <MenuItem divider />,
                                   <MenuItem header>Reálné sub. stroje</MenuItem>,
                                   <PdpTriState onChangePdp={(e) => {
                                     if (e.target.checked) setFilter({
                                       ...filter,
                                       pdpTristateReal: e.target.checked ? 2 : 0,
                                     });
                                   }} pdpTristate={filter.pdpTristateReal} onChangeWithoutPdp={(e) => {
                                     if (e.target.checked) setFilter({
                                       ...filter,
                                       pdpTristateReal: e.target.checked ? 1 : 0,
                                     });
                                   }}/>,
                                 ]},
                               {header: [
                                   <MenuItem divider />,
                                   <MenuItem header>Archiv</MenuItem>,
                                 ]},
                             ]}
                             drawFirstWithDividers
                             onListSplitterCondition={(index: number, item: Vehicles) => {
                               if(item.id === 0)
                                 return false;
                               let ret = true;
                               switch (index) {
                                 case 0: ret = !item.isForSupliers && !item.isExternal && !item.isArchived; break;
                                 case 1: ret = item.isForSupliers && !item.isExternal && !item.isArchived; break;
                                 case 2: ret = item.isExternal && !item.isArchived; break;
                                 case 3: ret = item.isArchived;
                                 default: break;
                               }
                               return ret;
                             }}
                             value={'identifier'}
                             listChanged={(list: any[]) => {
                               setFilter({
                                 ...filter,
                                 vehicleId: list.filter(o => !o.isArchived).map(oo => oo.id),
                                 vehicleId_a: list.filter(o => o.isArchived).map(oo => oo.id)
                               })
                             }}/>
            </div>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup >
            <ControlLabel>
              Strojník
            </ControlLabel>
            <div>
              <ItemsSelector externList={users}
                             pullRight
                             filterEnable
                             isFilterable
                             fullSelectedList
                             appendItemsToStart={[
                               {id: 0, nameSurname: 'Všichni',name: 'Všichni', surname: ''},
                             ]}
                             drawFirstWithDividers
                             listSplitter={[
                               {header: [
                                   <MenuItem header>Strojníci</MenuItem>,
                                 ]},
                               {header: [
                                   <MenuItem divider />,
                                   <MenuItem header>Archivováni</MenuItem>,
                                 ]},
                             ]}
                             onListSplitterCondition={(index: number, item:
                               { id: number; isArchived: boolean; }) => {
                               if(item.id === 0)
                                 return false;
                               let ret = true;
                               switch (index) {
                                 case 0: ret = !item.isArchived; break;
                                 case 1: ret = item.isArchived; break;
                                 default: break;
                               }
                               return ret;
                             }}
                             preview={SmallUserPreview}
                             value={'nameSurname'}
                             listChanged={(list: any[]) => {
                               setFilter({...filter,
                                 userId: list.filter(o => !o.isArchived).map(oo => oo.id),
                                 userId_a: list.filter(o => o.isArchived).map(oo => oo.id)
                               });
                             }}
                             otherPreviewProps={{style: {display: 'inline'}}}/>
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <FormGroup >
            <ControlLabel>
              Dodavatel
            </ControlLabel>
            <div>
              <ItemsSelector externList={addressBook}
                             pullRight
                             filterEnable
                             isFilterable
                             appendItemsToStart={[
                               {id: 0, companyWithIc: 'Všichni'},
                             ]}
                             drawFirstWithDividers
                             onListSplitterCondition={(index: number,item: {id: number}) => item.id !== 0}
                             value={'companyWithIc'}
                             listChanged={(list: number[]) => setFilter({...filter, addressBookId: list})}
                             otherPreviewProps={{style: {display: 'inline'}}}/>
            </div>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup >
            <ControlLabel>
              Kontakt
            </ControlLabel>
            <div>
              <ItemsSelector externList={contactUsers}
                             pullRight
                             filterEnable
                             isFilterable
                             fullSelectedList
                             appendItemsToStart={[
                               {id: 0, nameSurname: 'Všichni',name: 'Všichni', surname: ''},
                             ]}
                             drawFirstWithDividers
                             listSplitter={[
                               {header: [
                                   <MenuItem header>Kontakty</MenuItem>,
                                 ]},
                               {header: [
                                   <MenuItem divider />,
                                   <MenuItem header>Archivováni</MenuItem>,
                                 ]},
                             ]}
                             onListSplitterCondition={(index: number, item:
                               {id: number, isArchived: boolean}) => {
                               if(item.id === 0)
                                 return false;
                               let ret = true;
                               switch (index) {
                                 case 0: ret = !item.isArchived; break;
                                 case 1: ret = item.isArchived; break;
                                 default: break;
                               }
                               return ret;
                             }}
                             preview={SmallUserPreview}
                             value={'nameSurname'}
                             listChanged={(list: any[]) => {
                               setFilter({...filter,
                                 contactUserId: list.filter(o => !o.isArchived).map(oo => oo.id),
                                 contactUserId_a: list.filter(o => o.isArchived).map(oo => oo.id)
                               });
                             }}
                             otherPreviewProps={{style: {display: 'inline'}}}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <FormGroup style={{float: 'left', marginRight: '10px', marginBottom: '5px'}}>
            <ControlLabel>
              Název Firmy
            </ControlLabel>
            <div className='autocomplete'>
              <Autocomplete
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '2px 0',
                  fontSize: '90%',
                  position: 'fixed',
                  overflow: 'auto',
                  maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                  zIndex: 998,
                }}
                getItemValue={(item) => item.company}
                items={companies.filter((o: { company: string; }) => o.company.toLowerCase()
                  .indexOf(filter.company ? filter.company.toLowerCase() : '') > -1)}
                renderItem={(item, isHighlighted) =>
                  <div key={item.company} style={{ cursor: 'pointer', padding: '3px', background: isHighlighted ? 'lightgray' : 'white' }}>
                    {item.company}
                  </div>
                }
                value={filter.company}
                onChange={(e) => setFilter({
                  ...filter,
                  company: e.target.value,
                })}
                onSelect={(val) => setFilter({
                  ...filter,
                  company: val,
                })}
              />
            </div>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup style={{float: 'left', marginRight: '10px', marginBottom: '5px'}}>
            <ControlLabel>
              Stavba
            </ControlLabel>
            <div className='autocomplete'>
              <Autocomplete
                menuStyle={{
                  borderRadius: '3px',
                  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                  background: 'rgba(255, 255, 255, 0.9)',
                  padding: '2px 0',
                  fontSize: '90%',
                  position: 'fixed',
                  overflow: 'auto',
                  maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                  zIndex: 998,
                }}
                getItemValue={(item) => item.construction}
                items={constructions.filter((o: { construction: string; }) => o.construction.toLowerCase()
                  .indexOf(filter.construction ? filter.construction.toLowerCase() : '') > -1)}
                renderItem={(item, isHighlighted) =>
                  <div key={item.construction} style={{ cursor: 'pointer', padding: '3px', background: isHighlighted ? 'lightgray' : 'white' }}>
                    {item.construction}
                  </div>
                }
                value={filter.construction}
                onChange={(e) => setFilter({
                  ...filter,
                  construction: e.target.value,
                })}
                onSelect={(val) => setFilter({
                  ...filter,
                  construction: val,
                })}
              />
            </div>
          </FormGroup>
        </Col>

      </Row>
      <Row>
        <Col sm={6}>
          <FormGroup >
            <ControlLabel>
              {authenticate.activeSpaceId === 1 ? 'Místo výkonu' : 'Nákladka'}
            </ControlLabel>
            <div className='autocomplete'>
              <MapAutoComplete inputId={`loading-map-auto`}
                               type='mapycz'
                               placeholder='Napište adresu'
                               withInternalSearchType
                               defaultValue=''
                               onPlaceSelect={(loading: string) =>
                                  setFilter({ ...filter, loading})
                               }
                               />
            </div>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <ControlLabel>
              Výkladka
            </ControlLabel>
            <div className='autocomplete'>
              <MapAutoComplete inputId={`unloading-map-auto`}
                               type='mapycz'
                               placeholder='Napište adresu'
                               withInternalSearchType
                               defaultValue=''
                               onPlaceSelect={(unloading: string) =>
                                 setFilter({ ...filter, unloading})
                               }
              />
            </div>
          </FormGroup>
        </Col>

      </Row>
      <Row>
        <Col sm={6}>
          <FormGroup >
            <ControlLabel>
              Stav faktury
            </ControlLabel>
            <div>
              <ItemsSelector externList={[{id: 0, name: 'Všechny'}, {id: 1, name: 'Vyfakturováno'}, {id: 2, name: 'Nevyfakturováno'}]}
                             pullRight
                             onSelected={(item: {id: number}) => setFilter({...filter, invoiceState: item.id})}
                             drawFirstWithDividers
                             onListSplitterCondition={(index: number,item: {id: number}) => item.id !== 0}
                             value={'name'}
              />
            </div>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup >
            <ControlLabel>
              Stav mzdy
            </ControlLabel>
            <div>
              <ItemsSelector externList={[{id: 0, name: 'Všechny'}, {id: 1, name: 'Vyřešena'}, {id: 2, name: 'Nevyřešena'}]}
                             pullRight
                             onSelected={(item: {id: number}) => setFilter({...filter, salaryState: item.id})}
                             drawFirstWithDividers
                             onListSplitterCondition={(index: number,item: {id: number}) => item.id !== 0}
                             value={'name'}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </Navbar.Collapse>

  </Navbar>
  </>
}