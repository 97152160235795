import {Component} from "react";
import React from "react";
import { BaseEditPropTypes } from '../CommonListContainer';
import {BackHeader, Setting} from "../Setting";
import request from 'axios';
import {LoadingOrRender} from "../../loginsrc/Profile";
import {
  Row, Col, ControlLabel, DropdownButton, MenuItem, FormGroup, FormControl, Form, Checkbox
} from 'react-bootstrap';
import FontAwesome from "react-fontawesome";
import ReactPhoneInput from "react-phone-input-2";
import SubmitButton from "../../loginsrc/SubmitButton";
import PropTypes from "prop-types";
import { Error403, mapAuthStateToProps} from "../../AppObjects";
import { Route, Redirect, Switch } from "react-router-dom";
import SendSms from '../SendSms';
import RecordComponent from "../RecordsComponent";
import {CanView} from "../Main";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import {connect} from "react-redux";
import qs from 'qs';
import Salary, {SalaryPreview} from "./Salary";
import {SmallCreditCardView} from "../creditCard/Preview";
import LoadUsersForeign from "./LoadUsersForeign";

class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      email: '',
      canReg: false,
      telnumb: '',
      company: '',
      spaces: [],
      requestActive: props.disableUrlCheck ? false : (this.props.match.params.id !== 'new'),
      editing:  props.disableUrlCheck ? true : (this.props.match.params.id === 'new'),
      emailRegSendActive: false,
      roleId: 0,
      creditCards: [],
      submitActive: false,
      foreignApiDriverId: [],
    };
    this.roles = [];
    this.originUser = {};
    this.originSpaces = [];
  }

  componentWillMount() {
    if (this.props.disableUrlCheck) {
      this.requestRoles();
      return;
    }
    if (this.props.match.params.id !== 'new') {
      request.get('/items/users/' + this.props.match.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState(res.data);
            this.originUser = res.data;
            this.requestRoles();
          }
        })
        .catch(err => {
          this.setState({requestActive: false});
        });
    } else {
      this.requestRoles();
    }
  }

  smsSended(sms, location) {
    store.dispatch(show({
      title: 'Info',
      level: 'info',
      autoDismiss: 3,
      children: (
        <div>
          Sms odeslána
        </div>
      ),
    }, 'info'));
  }

  requestRoles() {
    request.get('/items/roles', {
      params: { attributes: ['id', 'name'], isAdmin: 1, disableSpaceId: true },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    })
      .then((res) => {
        this.roles = res.data;
        request.get('/items/spaces', { params: this.props.defaultProps})
          .then((res) => {
            this.originSpaces = res.data;
            if (this.props.authenticate.actualUserRole.isAdmin) {
              this.originSpaces.forEach(space => {
                if (space) {
                  space.roles = !this.props.defaultProps.disableAdmin ? [
                    ...this.roles,
                    ...space.roles,
                  ] : space.roles;
                }
              });
            }

            let spaces = this.state.spaces.length === 0 ? [] : this.state.spaces.slice();
            res.data.forEach((item) => {
              if (item) {
                let index = this.state.spaces.length === 0 ? -1 : spaces.findIndex(o => o.space.id === item.id);
                if (index < 0) {
                  spaces.push({
                    enabled: this.props.authenticate.activeSpaceId === item.id,
                    space: item,
                    role: item.roles.length > 0 ? item.roles[0] : null,
                  })
                }
              }
            });
            this.setState({ requestActive: false, spaces })
          })
          .catch(err => {
            this.setState({ requestActive: false });
          });
      })
      .catch(err => {
        this.setState({ requestActive: false });
      });
  }

  sendRegistration() {
    this.setState({
      emailRegSendActive: true,
    });
    request.post('/sendregistration', {id: this.state.id})
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            emailRegSendActive: false,
          });
          store.dispatch(show({
            title: 'Oznaméní',
            level: 'info',
            autoDismiss: 3,
            children: (
              <div>
                Registrační email úspěšně odeslán
              </div>
            ),
          }, 'info'));
        }
      })
      .catch((error) => {
        this.setState({
          emailRegSendActive: false,
        });
        store.dispatch(show({
          title: 'Chyba',
          level: 'error',
          autoDismiss: 3,
          children: (
            <div>
              Nepodařilo se odeslat registrační email
            </div>
          ),
        }, 'error'));
      })
  }

  renderSpaceRole(item, index) {
    return <span
      key={index}
      style={{
        color: item.homeSpace? 'darkgreen' : undefined,
        fontWeight: item.homeSpace? 'bold' : undefined,
        marginRight: '20px'
      }}>
      {`${item.space.name} (${item.role ? item.role.name : 'NEVYBRÁNA ROLE'})`}
    </span>
  }

  renderSalaryInSpace() {
    const spaces = this.state.spaces;
    const sId = store.getState().authenticate.activeSpaceId;
    const space = spaces.find(o => o.spaceId === sId);
    if (space && space.salary) {
      return <div className='nest'>
        <h4>Mzda</h4>
        <SalaryPreview salary={space.salary}/>
      </div>
    }
    return null;
  }

  renderIcon(type) {
    if(type === 'webDisApi')
      return <div className='webdispecink'/>;
    else if(type === 'volvoApi')
      return <div className='volvo'/>;
  }

  handleSubmit(e) {
    e.preventDefault();
    let obj = this.state;
    if(obj.name.length === 0 || obj.surname.length === 0) {
      store.dispatch(show({
        title: 'Chyba',
        level: 'error',
        autoDismiss: 3,
        children: (
          <div>
            Jméno a příjmení musí být vyplněno
          </div>
        ),
      }, 'error'));
      return;
    }
    if(obj.email === '')
      obj.email = null;
    this.props.onSubmit(this.props.disableUrlCheck ?
      'new' : this.props.match.params.id, this, obj);
  }

  render() {
    let disableEdit = !this.props.authenticate.actualUserRole['users'].update;
    let toRender = null;
    let leftDetail = <Col sm={1}>
      <div className='illustration'>
        <FontAwesome name='fas fa-user'/>
      </div>
      <div className='text-center' style={{fontSize: '20px'}}>
        {this.state.name + ' ' + this.state.surname}
      </div>
    </Col>;
    let controlName = <Col sm={2} xs={4} componentClass={ControlLabel}>
      Jméno
    </Col>;
    let controlSurName = <Col sm={2} xs={4} componentClass={ControlLabel}>
      Příjmení
    </Col>;
    let controlTelNumb = <Col sm={2} xs={4} componentClass={ControlLabel}>
      Telefonní číslo
    </Col>;
    let controlEmail = <Col sm={2} xs={4} componentClass={ControlLabel}>
      Email
    </Col>;
    let controlRole = <Col sm={2} xs={4} componentClass={ControlLabel}>
      Projektová role
    </Col>;
    let controlSpaces = <Col sm={2} xs={4} componentClass={ControlLabel}>
      Prostory
    </Col>;
    let controlForeignDriversTable = <Col sm={2} xs={4} componentClass={ControlLabel}>
      Vazba na GPS
    </Col>;

    if (this.state.editing) {
      toRender = <Row>
        {leftDetail}
        <Col sm={7}>
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <FormGroup controlId="name">
              <Row>
                {controlName}
                <Col sm={10}>
                  <FormControl
                    type='text'
                    onChange={(e) => this.setState({name: e.target.value})}
                    value={this.state.name}
                    placeholder='jméno'/>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup controlId="surname">
              <Row>
                {controlSurName}
                <Col sm={10}>
                  <FormControl
                    type='text'
                    onChange={(e) => this.setState({surname: e.target.value})}
                    value={this.state.surname}
                    placeholder='přijmení'/>
                </Col>
              </Row>
            </FormGroup>

            <FormGroup controlId="email">
              <Row>
                {controlEmail}
                <Col sm={10}>
                  <FormControl
                    type='text'
                    onChange={(e) => this.setState({email: e.target.value})}
                    value={this.state.email}
                    placeholder='email'/>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup controlId="telnumb">
              <Row>
                {controlTelNumb}
                <Col sm={10}>
                  <ReactPhoneInput defaultCountry={'cz'}
                                   onChange={(value) => this.setState({telnumb: value})}
                                   value={this.state.telnumb}
                                   inputStyle={{width:'100%'}}/>
                </Col>
              </Row>
            </FormGroup>
            {<FormGroup controlId="spaces">
                <Row>
                  {controlSpaces}
                  <Col sm={10}>
                    <div className='dataTable'>
                      <table>
                        <tbody>
                        <tr>
                          <th>Prostor</th>
                          <th>Povoleno</th>
                          <th>Domácí prostor</th>
                          <th>Role v prostoru</th>
                          <th>Mzda</th>
                        </tr>
                        {
                          this.state.spaces.map((item,index) => {
                            const origin = this.originSpaces.find(o => o.id === item.space.id);
                            if (!origin)
                              return null;
                            return <tr>
                              <td>{item.space.name}</td>
                              <td>
                                <Checkbox checked={item.enabled}
                                          key={index}
                                          onChange={(e) => {
                                            let spaces = this.state.spaces.slice();
                                            spaces[index].enabled = e.target.checked;
                                            this.setState({spaces});
                                          }}
                                          style={{paddingLeft: '10px', paddingRight: '10px'}}/>
                              </td>
                              <td>
                                <Checkbox checked={item.homeSpace}
                                          key={index}
                                          onChange={(e) => {
                                            let spaces = this.state.spaces.slice();
                                            spaces.forEach(s => s.homeSpace = false);
                                            spaces[index].homeSpace = e.target.checked;
                                            this.setState({spaces});
                                          }}
                                          style={{paddingLeft: '10px', paddingRight: '10px'}}/>
                              </td>
                              <td>
                                <DropdownButton
                                  id='RolesDropDown'
                                  onSelect={(key) => {
                                    let spaces = this.state.spaces.slice();
                                    spaces[index].role = origin.roles.find(o => o.id === key);
                                    this.setState({spaces});
                                  }}
                                  title={item.role ? item.role.name : 'ŽÁDNÁ ROLE'}>
                                  {
                                    Object.keys(origin.roles).map((key) => {
                                      return <MenuItem /*active={this.state.roleId === origin.roles[key].id}*/
                                                       key={key}
                                                       eventKey={origin.roles[key].id}>
                                        {origin.roles[key].name}
                                      </MenuItem>
                                    })
                                  }
                                </DropdownButton>
                              </td>
                              <td>
                                <Salary onSalaryChange={(salary) => {
                                  let spaces = this.state.spaces.slice();
                                  spaces[index].salary = salary;
                                  this.setState({spaces});
                                }} salary={!item.salary ? undefined : item.salary}/>
                              </td>
                            </tr>

                          })
                        }
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </FormGroup>}
              <FormGroup>
                <Row>
                  {controlForeignDriversTable}
                  <Col sm={10}>
                    <LoadUsersForeign
                      onDriverChange={foreignApiDriverId => this.setState({ foreignApiDriverId })}
                      foreignApiDriverId={this.state.foreignApiDriverId} />
                  </Col>
                </Row>
              </FormGroup>
            <Row>
              <SubmitButton onClick={this.props.disableUrlCheck ? (e) => this.handleSubmit(e) : null}
                            bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                Potvrdit
              </SubmitButton>
            </Row>
          </Form>
        </Col>
        <Col sm={1}>
          {this.props.disableUrlCheck ? null : (this.props.match.params.id !== 'new' ?  <button
            onClick={() => this.setState({ editing: false, ...this.originUser })}
            className='transparentButton left'>
            <FontAwesome style={{fontSize: "24px"}} name='fas fa-times'/>
          </button> : null)}
        </Col>
      </Row>
    } else {
      toRender = <Row>
        {leftDetail}
        <Col sm={3}>
          {this.renderSalaryInSpace()}
        </Col>
        <Col sm={2}>
          <h4 title='Karty se přiřazují v hlavním menu -> platební karty -> upravit'>Platební karty</h4>
          {
            this.state.creditCards.map((item, index) => {
              return <SmallCreditCardView style={{ marginTop: '-10px' }} item={item} />
            })
          }
          <h4>GPS Vazba</h4>
          {

            this.state.foreignApiDriverId.map((f, index) => <div key={index}>
              {this.renderIcon(f.type)}
              <div style={{ display: 'inline-block', verticalAlign: 'top', marginTop: '7px', marginLeft: '10px' }}>
                {f.driver.displayName}
              </div>
            </div>)
          }
        </Col>
        <Col sm={4}>
          <Row>
            <FormGroup controlId="name">
              {controlName}
              <Col sm={10} xs={8}>
                <div>{this.state.name}</div>
              </Col>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup controlId="surname">
              {controlSurName}
              <Col sm={10} xs={8}>
                <div>{this.state.surname}</div>
              </Col>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup controlId="email">
              {controlEmail}
              <Col sm={10} xs={8}>
                <div>{this.state.email}</div>
              </Col>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup controlId="telnumb">
              {controlTelNumb}
              <Col sm={10} xs={8}>
                <div>{this.state.telnumb}</div>
              </Col>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup controlId="spaces">
              {controlSpaces}
              <Col sm={10} xs={8}>
                {
                  this.state.spaces.filter(o => o.enabled).map((item,index) => (
                      this.renderSpaceRole(item, index)
                    )
                  )
                }
              </Col>
            </FormGroup>
          </Row>
        </Col>
        <Col sm={2}>
          <Row>
              <Col sm={12} xs={4}>
                {
                    <button
                      onClick={() => this.setState({editing: true})}
                      disabled={disableEdit}
                      className='transparentButton left'>
                      <FontAwesome style={{fontSize: "24px"}} name='fas fa-edit'/>
                      <div style={{display: 'inline-block', marginLeft: '10px'}}>
                        Upravit
                      </div>
                    </button>
                }

              </Col>
              <Col sm={12} xs={4}>
                <LoadingOrRender requestActive={this.state.emailRegSendActive}>
                  <button
                    onClick={() => this.sendRegistration()}
                    className='transparentButton left'>
                    <FontAwesome style={{fontSize: "24px"}} name='fas fa-envelope'/>
                    <div style={{display: 'inline-block', marginLeft: '10px'}}>
                      Reg. email
                    </div>
                  </button>
                </LoadingOrRender>
              </Col>
              <Col sm={12} xs={4}>
                <button
                  onClick={() => this.props.history.push({
                    pathname: this.props.match.url + '/sendsms',
                    state: {
                      to: this.state.telnumb,
                      defaultText: '',
                    },
                  })}
                  className='transparentButton left'>
                  <FontAwesome style={{fontSize: "24px"}} name='fas fa-comment'/>
                  <div style={{display: 'inline-block', marginLeft: '10px'}}>
                    SMS
                  </div>
                </button>
              </Col>
          </Row>
        </Col>
      </Row>;
    }
    return (
      <>

          {this.props.disableUrlCheck ? null :
            <Route path={this.props.match.url + '/sendsms'}
                   render={(props) => <SendSms {...props}
                   onSended={(sms, location) => this.smsSended(sms, location)}/>}/>}
          <ContentWithFixedHeader drawOnlyChildren={this.props.disableUrlCheck} header={
            <CanView visible={!this.props.disableUrlCheck}>
              <BackHeader
                fontSize='18px'
                headerText={(this.state.name + this.state.surname) === '' ?
                  'Zaměstanec' :  (this.state.name + ' ' + this.state.surname) }
                onBackClick={() => this.props.history.replace(
                  this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
            </CanView>
          }>
            <div className='editForm'>
              <div className='nest'>
                <LoadingOrRender requestActive={this.state.requestActive}>
                  {toRender}
                </LoadingOrRender>
              </div>
            </div>
            {
              this.props.disableUrlCheck ? null : (this.props.match.params.id === 'new' ? null : (this.state.requestActive ? null : <div className='Record'>
                <div className='nest'><RecordComponent
                  cards={this.state.creditCards}
                  identificationProps={{
                  $or: [
                    {
                      itemId: this.state.id,
                      itemTableName: 'users',
                    },
                    {
                      itemId: this.state.creditCards.map(o => o.id),
                      itemTableName: 'creditCard',
                    }
                  ]
                }}/></div></div>))
            }
          </ContentWithFixedHeader>
      </>

    )
  }
}

UserEdit.propTypes = {
  disableUrlCheck: PropTypes.bool,
  defaultProps: PropTypes.object,
  authenticate: PropTypes.object,
  ...BaseEditPropTypes
};

UserEdit.defaultProps = {
  disableUrlCheck: false,
  defaultProps: {
    disableAdmin: false,
    rolesWhere: {},
  },
};

export default connect(mapAuthStateToProps)(UserEdit);

