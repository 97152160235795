import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import React from "react";
import LogView from "../../log/Preview";
import CommonListContainer from "../../CommonListContainer";

export default function DispatchingLog({ itemId, onHide, match }) {

  return <Modal dialogClassName="modal-90w"
                show={itemId !== null} onHide={onHide}>
    <ModalHeader closeButton>
      <Modal.Title>Log</Modal.Title>
    </ModalHeader>
    <ModalBody>
      {itemId && <CommonListContainer previewMode={ LogView }
                                      item='logs'
                                      readOnly
                                      defaultQuery={{
                                        where: {
                                          tableName: 'datarow',
                                          itemId: itemId,
                                        }
                                      }}
                                      requestMorePreviewProps={['/items/attributes/price']}
                                      match={match}
                                      disableDelete
                                      disableNew
                                      backUrl='/'/>
      }
    </ModalBody>
  </Modal>
}