import React, {Component} from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Col, Row, Dropdown, MenuItem } from 'react-bootstrap';
import {Route, RouteComponentProps} from 'react-router-dom';
import FontAwesome from "react-fontawesome";
import PropTypes from 'prop-types';
import {BackHeader} from "./Setting";
import {HeaderNames, AddButtonNames, Error403} from '../AppObjects';
import {LoadingOrRender} from "../loginsrc/Profile";
import FlipMove from 'react-flip-move';
import {CanView} from "./Main";
import LogView from "./log/Preview";
import ContentWithFixedHeader from "./ContentWithFixedHeader";
import store from "../redux/store";
import { show } from 'react-notification-system-redux';
import {connect} from "react-redux";
import {
  BaseCommonListContainer,
  BaseCommonListProps,
  BaseCommonListState,
  IEdit,
  IQuery
} from "@cml/common-list-container";
import {AuthenticateState} from "@cml/redux-store";
import {BaseEditState} from "@cml/common-list-container/src";

const mapStateToProps = (state: {
  authenticate: AuthenticateState,
}) => {
  return {
    authenticate: state.authenticate,
  }
};

interface ListState extends BaseCommonListState {
  modal: boolean;
  logModal: boolean;
}

interface MatchParams {
  id: string | undefined;
}

interface ListProps extends RouteComponentProps<MatchParams, {}, {
  defaultQuery: IQuery,
}>, BaseCommonListProps{

}

export class CommonListContainer extends BaseCommonListContainer<ListProps, ListState> implements IEdit {
  constructor(props: ListProps) {
    super(props);
    this.state = {
      ...this.state,
      modal: false,
      logModal: false,
    };
    this.setListeners(this);
  }

  onProgress(_this: Component<{}, BaseEditState>, percentage: number) {

  }

  onEditFinished(isNew: boolean, stay: boolean, id: number): void {
    if (!stay) {
      this.props.history.replace(this.props.match.url);
    } else {
      if (isNew) {
        this.props.history.push(`${this.props.match.url}/${id}`);
      }
      store.dispatch(show({
        title: 'Info',
        level: 'info',
        autoDismiss: 3,
        children: (
            <div>
              Uloženo
            </div>
        ),
      }, 'info'));
    }
  }
  onEditFailed(err: {status: number}, isNew: boolean): void {
    if (isNew) {
      store.dispatch(show({
        title: 'Error',
        level: 'error',
        autoDismiss: 5,
        children: (
            <div>
              {`Server hlasí chybu ${err.status}, nelze vytvořit nový záznam zkontrolujte správnost zadaných údajů.
                                            Chyba může být také, že podobný záznam už existuje a server nedovoluje uložit dva stejné`}
            </div>
        ),
      }, 'error'));
    } else {
      if (err.status === 403) {
        this.setState({ modal: false, });

        store.dispatch(show({
          title: 'Chyba !',
          level: 'error',
          autoDismiss: 3,
          children: (
              <div>
                {
                  // @ts-ignore
                  Error403[this.props.item]}
              </div>
          ),
        }, 'error'));
      }
    }
  }

  componentWillMount() {
    let defaultQuery = {} as IQuery;
    if (!this.props.readOnly) {
      if (this.props.location.state) {
        defaultQuery = typeof this.props.location.state.defaultQuery === 'undefined' ? this.props.defaultQuery :
            this.props.location.state.defaultQuery;
      } else {
        defaultQuery = this.props.defaultQuery;
      }
    } else {
      defaultQuery = this.props.defaultQuery;
    }

    this.defaultQuery = defaultQuery;

    this.requestCount(defaultQuery)

    this.requestMoreProps();
  }

  componentDidUpdate() {
    if(this.requestDefaultQuery && !this.props.readOnly) {
      let defaultQuery = {} as IQuery;
      if (this.props.location.state) {
        defaultQuery = typeof this.props.location.state.defaultQuery === 'undefined' ? this.props.defaultQuery :
          this.props.location.state.defaultQuery;
          this.requestDefaultQuery = false;
          this.requestData(defaultQuery, 0);
        this.defaultQuery = defaultQuery;
      }
    }
  }

  onDelete() {
    this.deleteFinish(this.state.list[this.state.selectedKey].id, this.state.selectedKey)
      .then(() => {
        this.setState({ modal: false });
      })
      .catch(err => {
        store.dispatch(show({
          title: 'Chyba !',
          level: 'error',
          autoDismiss: 3,
          children: (
            <div>
              {
                // @ts-ignore
                Error403[this.props.item]}
            </div>
          ),
        }, 'error'));
      });
  }

  handleDropDown(eventKey: string, listKey: number) {
    if (eventKey === 'update') {
      this.setState({
        selectedKey: listKey,
      });
      this.props.history.push(this.props.match.url + '/' +
        this.state.list[listKey].id);
    } else if (eventKey === 'delete') {
      this.setState({ selectedKey: listKey, modal: true, });
    } else if (eventKey === 'up') {
      this.switchOrder(listKey);
    } else if (eventKey === 'log') {
      this.setState({logModal: true, selectedKey: listKey})
    }
  }

  render() {
    const { authenticate } = this.props;
    if (this.props.checkRights) {
      // @ts-ignore
      if (!authenticate.actualUserRole[this.props.checkRights].view)
        return <div>
          Nelze zobrazit, protože nemáte práva
        </div>
    }

    let filtr: {} | null | undefined = null;
    if (!this.props.readOnly) {
      filtr = this.createFilterElement({ stateHistory: this.props.location.state });
    }

    let edit = typeof this.props.editMode === 'undefined' ? null :
      <Route path={this.props.match.url + '/:id'}
             render={(props) => this.createEditElement(props)}/>;

    let _update = typeof this.props.leftIconStyle !== 'undefined';
    let _delete = !this.props.disableDelete;
    let _attributes = true;

    if (this.props.checkRights) {
      if (this.props.item.includes('attributes'))
        {
          // @ts-ignore
          _attributes = authenticate.actualUserRole[this.props.checkRights].attributes;
        }
      if (typeof _attributes === 'undefined')
        _attributes = true;
      // @ts-ignore
      _update = authenticate.actualUserRole[this.props.checkRights].update && _attributes;
      // @ts-ignore
      _delete = authenticate.actualUserRole[this.props.checkRights].delete && _attributes;
    }

    return (
      <div>

        <Route exact path={this.props.match.url} render={() => <div>
          <ContentWithFixedHeader baseMargin={60} header={
            <Row>
              <Col sm={3}>
                  <CanView visible={!this.props.readOnly}>
                    <BackHeader
                      fontSize='18px'
                      headerText={
                        // @ts-ignore
                        HeaderNames[this.props.item]}
                      onBackClick={() => this.props.history.replace(this.props.backUrl)}/>
                  </CanView>
              </Col>
              <Col sm={1}>
                {
                  this.props.disableNew ? null :
                    <div className='text-left'>
                      <Button disabled={!_update} onClick={() =>
                        this.props.history.push(this.props.match.url + '/new')}>
                        <FontAwesome style={{ fontSize: '12px', marginRight: '10px', }} name='fas fa-plus'/>
                        <div style={{ fontSize: '14px', display: 'inline-block' }}>
                          {
                            // @ts-ignore
                            AddButtonNames[this.props.item]}
                        </div>
                      </Button>
                    </div>
                }
              </Col>
              <Col sm={8}>
                {filtr}
              </Col>
            </Row>
          }>
            <LoadingOrRender requestActive={this.state.requestActive}>
              <FlipMove duration={750} easing='ease-out'>
                {
                  Object.keys(this.state.list).map((key: string) => {
                    return <div key={this.state.list[parseInt(key)].id}
                                className='ContainerListItem fixHowirizonalScroll'>
                      <Row>
                        <Col sm={!_update && !_delete ? 12 : 11}>
                          { this.createPreviewElement({
                            history: this.props.history,
                            match: this.props.match,
                          }, Number(key))}
                        </Col>
                        <CanView visible={_update || _delete}>
                          <Col sm={1}>
                            <div className='text-right'>
                              <Dropdown id={key}  pullRight
                                        onSelect={(eventKey: any) => this.handleDropDown(eventKey, Number(key))}>
                                <Dropdown.Toggle noCaret>
                                  <FontAwesome name='fas fa-ellipsis-v'/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {_update ? typeof this.props.leftIconStyle === 'undefined' ? null : <MenuItem eventKey="update">
                                    <FontAwesome style={{marginRight: '10px'}} name={this.props.leftIconStyle}/>
                                    Upravit
                                  </MenuItem> : null}
                                  {_delete ? <MenuItem eventKey="delete">
                                    <FontAwesome style={{marginRight: '10px'}} name='fas fa-trash'/>
                                    Vymazat
                                  </MenuItem> : null}
                                  {_update ?
                                    this.props.orderForVis ? <MenuItem eventKey="up">
                                      <FontAwesome style={{marginRight: '10px'}} name='fas fa-level-up-alt'/>
                                      Nahoru
                                    </MenuItem> : null : null
                                  }
                                  <MenuItem eventKey="log">
                                    <FontAwesome style={{marginRight: '10px'}} name='fas fa-info-circle'/>
                                    Log
                                  </MenuItem>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </Col>
                        </CanView>
                      </Row>

                    </div>
                  })
                }
              </FlipMove>
              <div className='text-center'>
                {
                  // @ts-ignore
                  [...Array(Math.ceil(this.state.totalItemsCount / BaseCommonListContainer.pageLimit)).keys()].map((key) => {
                    if (Math.abs(key - this.state.activePage) < 5
                      || key === 0 || key === Math.ceil(this.state.totalItemsCount / BaseCommonListContainer.pageLimit) -1 ||
                      (Math.abs(key - this.state.activePage) > 5 && Math.abs(key - this.state.activePage) === 20))
                      return <button key={key} onClick={() => {
                        if (this.state.activePage !== key) {
                          let query = {
                            ...this.defaultQuery,
                            ...this.lastFilter,
                          } as IQuery;
                          this.requestData(query, key);
                          this.setState({activePage: key});
                        }
                      }} className='transparent'>
                        {
                          key - this.state.activePage > 5 ? '...' : ''
                        }
                        {
                          // @ts-ignore
                          <a style={{marginRight: '5px', marginLeft: '5px', fontWeight: this.state.activePage === key ? 'bold': ''}}>{key + 1}</a>
                        }
                        {
                          key - this.state.activePage < -5 ? '...' : ''
                        }
                      </button>
                  })
                }
              </div>
            </LoadingOrRender>
          </ContentWithFixedHeader>
          <Modal show={this.state.modal} onHide={() => {}}>
            <ModalHeader>
              <Modal.Title>Upozornění</Modal.Title>
            </ModalHeader>
            <ModalBody>
              Opravdu chcete vymazat vybranou položku ??
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => this.setState({ modal: false })}> Ne</Button>
              <Button onClick={() => {
                this.onDelete();
                this.setState({ modal: false });
              }
              }> Ano</Button>
            </ModalFooter>
          </Modal>
          {
            !this.props.readOnly ? <Modal dialogClassName="modal-90w"
                                          show={this.state.logModal} onHide={() => this.setState({logModal: false})}>
              <ModalHeader closeButton>
                <Modal.Title>Log</Modal.Title>
              </ModalHeader>
              <ModalBody>
                {
                  //@ts-ignore
                  <CommonListContainer previewMode={LogView}
                                       item='logs'
                                       readOnly
                                       defaultQuery={{
                                         where: {
                                           tableName: this.props.item,
                                           itemId: typeof this.state.list[this.state.selectedKey] === 'undefined' ? 0 : this.state.list[this.state.selectedKey].id,
                                         }
                                       }}
                                       match={this.props.match}
                                       disableDelete
                                       disableNew
                                       backUrl='/'/>
                }
              </ModalBody>
            </Modal> : null
          }
        </div>
        }
        />

        {edit}
      </div>
    )
  }
}

const BaseEditPropTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const BaseFilterPropTypes = {
  onFilter: PropTypes.func.isRequired,
};

const BaseViewPropTypes = {
  item: PropTypes.object.isRequired,
  onItemChange: PropTypes.func,
};

export {
  BaseEditPropTypes,
  BaseViewPropTypes,
  BaseFilterPropTypes,
};

// @ts-ignore
export default connect(mapStateToProps)(CommonListContainer);