import * as React from 'react'
import {BaseEdit, BaseEditProps, BaseEditState, IBaseEdit} from "../CommonListContainerInterfaces";
import { Form, Row, Col, FormGroup, FormControl, Dropdown, MenuItem } from 'react-bootstrap';
import { FormEvent, RefObject } from "react";
import Diagram, {createCmlEngine} from "../stormReactDiagram/Diagram";
import { CanvasModel } from "@projectstorm/react-canvas-core";
import TaskEditablePreview, {TaskObject} from "../tasks/EditablePreview";
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { AxiosResponse } from 'axios';

export interface DiagramEditState extends BaseEditState{
    name: string;
    triggerType: number;
    triggerDescription: object;
}

export interface DiagramEditProps extends BaseEditProps {

}

export class TriggerType {
    name: string;
    key: number;

    constructor(name: string, key: number) {
        this.name = name;
        this.key = key;
    }
}

export default class DiagramEdit extends BaseEdit<DiagramEditProps, DiagramEditState> implements IBaseEdit{
    private triggerTypes: TriggerType[];
    private diagramModel: ReturnType<CanvasModel['serialize']> | undefined;
    private engine: DiagramEngine;

    constructor(props: BaseEditProps) {
        super(props, 'Diagram');
        super.setListeners(this);
        this.state = {
            ...this.state,
            name: '',
            triggerDescription: {},
            triggerType: 0,
        };
        this.triggerTypes = [new TriggerType('Manuální', 0)];
        this.engine = createCmlEngine();
    }

  receivedItemData(res: AxiosResponse<any>): void {
        this.diagramModel = res.data.diagramModel;
        this.setState({
            requestActive: false,
            ...res.data
        });
    }

    handleSubmit(e: FormEvent<Form> | MouseEvent) {
        e.preventDefault();
        if (this.engine.getModel()) {
            this.diagramModel = this.engine.getModel().serialize();
        }
        this.finalizeSubmit({
            ...this.state,
            diagramModel: this.diagramModel,
        });
    }

    handleOnTriggerTypeSelect(key: any) {
        this.setState({ triggerType: key as number })
    }

    renderBody() {
        return <>
            <Row>
                <Col sm={2}>
                    <FormGroup>
                        <h4>Název</h4>
                        <FormControl type='text'
                                     value={this.state.name}
                                     onChange={(e) => this.setState({ name: (e.target as HTMLTextAreaElement).value })}
                                     placeholder='Název'
                        />
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <h4>Typ spouštění</h4>
                        <Dropdown id='triggerTypeDropDown' pullRight onSelect={(eventKey: any) => this.handleOnTriggerTypeSelect(eventKey)}>
                            <Dropdown.Toggle>
                                {this.triggerTypes[this.state.triggerType].name}
                            </Dropdown.Toggle>
                            <Dropdown.Menu pullRight>
                                {
                                    this.triggerTypes.map((trigger, key) =>
                                        <MenuItem key={key} eventKey={key}>{trigger.name}</MenuItem>
                                    )
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </FormGroup>
                </Col>
            </Row>
            <div style={{height: '600px'}}>
                <Diagram externDiagram={this.engine} defaultModel={this.diagramModel}/>
            </div>
            <div>
                <h3>Náhled úkolu</h3>
            {
                <TaskEditablePreview isForDiagramPreview
                                     diagramModel={ this.engine.getModel() ? this.engine.getModel().serialize() : this.diagramModel }
                                     onNodeSelected={(id) => {
                                         if(id) {
                                             this.engine.getModel().getNodes().map(node => node.setSelected(false));
                                             const node = this.engine.getModel().getNode(id);
                                             node.setSelected(true);
                                         }
                                     }}/>
            }
            </div>
        </>
    }
}