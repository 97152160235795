import {CanView} from "../../Main";
import {IDispatchingAttributesCustom, IGeoLocation} from "@cml/types";
import MapAutoComplete from "../../MapAutoComplete";
import React from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {Attributes, Roles} from "@cml/models";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrUnLoading({ index, attribute, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const unloadingGeo = useSelector(selectDataRowValue(index, singleRowEditMode, 'unloadingGeo')) as IGeoLocation;

  const unloading = useSelector(selectDataRowValue(index, singleRowEditMode, 'unloading'));

  const placeSelect = (address: string) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      unloadingGeo: {
        location: address,
        latitude: unloadingGeo ? unloadingGeo.latitude : 0,
        longitude: unloadingGeo ? unloadingGeo.longitude : 0,
      },
      unloading: address,
    }))
  }

  const onMapCooSelected = (unloadingGeo: IGeoLocation) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      unloadingGeo,
      unloading: unloadingGeo.location
    }))
  }

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canUnLoading && dispatchOther.canLoadingUnloading}>
    <td>
      <MapAutoComplete inputId={`unloading-map-auto ${index}`}
                       type='mapycz'
                       placeholder='Napište adresu'
                       withInternalSearchType
                       geolocation={unloadingGeo}
                       onMapCooSelected={onMapCooSelected}
                       onPlaceSelect={placeSelect}
                       defaultValue={/*unloadingGeo ? unloadingGeo.location : */unloading}/>
    </td>
  </CanView>
}