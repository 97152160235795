import {Component} from "react";
import React from "react";
import {BaseViewPropTypes} from '../CommonListContainer';
import {Col, Row, Button} from 'react-bootstrap';
import {Right} from "../roles/Preview";
import {LoadingOrRender} from "../../loginsrc/Profile";
import request from 'axios';
import {SmallUserPreview} from "../users/Preview";
import moment from "moment";
import {dateFormatVisible, dateFromat, mapAuthStateToProps} from "../../AppObjects";
import PropTypes from "prop-types";
import {CanView} from "../Main";
import {connect} from "react-redux";


class ToolView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitActive: false,
      localized: null
    };
  }

  localizeTool() {
    this.setState({submitActive: true});

    request.get('/items/tools/localize/' + this.props.item.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState({submitActive: false, localized: res.data[0]});
          }
        }).catch((err) => {
          this.setState({ submitActive: false });
        })
  }

  renderBackToStorage(){
    const { authenticate } = this.props;
    if (!this.props.item.isAvailable)
      return <>
        <Button disabled={!this.props.authenticate.actualUserRole.tools.update} onClick={() => {
          let item = this.props.item;
          item.isAvailable = 1;
          this.props.onSubmit(this.props.item.id, this, item)
        }}>
          Vrátit do skladu
        </Button>
        {
          !this.state.localized ?
          <Button disabled={!authenticate.actualUserRole.tools.update} onClick={() => {
            this.localizeTool();
          }}>
            Lokalizuj
          </Button> : <div style={{display: 'inline-block', marginLeft: '10px'}}>
                <div style={{display: 'inline-block'}}>
                  <SmallUserPreview detailEnabled={false} item={this.state.localized} />
                </div>
                <div style={{display: 'inline-block', marginLeft: '10px'}}>
                  {moment(this.state.localized.date, dateFromat).format(dateFormatVisible)}
                </div>
              </div>
        }

      </>;
    return null;
  }

  render() {
    let style = {
      inlineLabel: {
        fontSize: '18px', fontWeight: 'bold',
      }
    };
    return (<div>
        <Row>
          <Col sm={2}>
            <div style={style.inlineLabel} className='text-center'>
              {this.props.item.name}
            </div>
          </Col>
          <Col sm={2}>
            <div className='text-center'>
              {this.props.item.identifier}
            </div>
          </Col>
          <Col sm={2}>
            <div className='text-center'>
              <Right can={this.props.item.isAvailable === 1}/>
            </div>
          </Col>
          <Col sm={5}>
            <CanView visible={!this.props.isForLog}>
              <LoadingOrRender requestActive={this.state.submitActive}>
                <div className='text-left'>
                  {this.renderBackToStorage()}
                </div>
              </LoadingOrRender>
            </CanView>
          </Col>
        </Row>
      </div>)
  }
}

ToolView.propTypes = {
  isForLog: PropTypes.bool,
  authenticate: PropTypes.object,
  ...BaseViewPropTypes
};

ToolView.defaultProps = {
  isForLog: false
};

export default connect(mapAuthStateToProps)(ToolView);

