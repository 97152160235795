import React, { Component } from 'react';
import moment from "moment";
import {dateFormatVisible, dateFromat} from "../../../AppObjects";
import {AutoSizer, Column, Table} from "react-virtualized";

export interface MainTableProps {
    phmData: {
        [key: string]:{
            list: {
                foreignApiVehicleId: string;
            }[]
        }
    };
    rowSelected: (vehicleApiId: string, keyMapIndex: string) => void;
}

export interface MainTableState {
    selRecordIndex: number;
}

export default class MainTable extends Component<MainTableProps, MainTableState>{
    constructor(props: MainTableProps) {
        super(props);
        this.state = {
            selRecordIndex: -2,
        }
    }

    render() {
        const { phmData, rowSelected } =this.props;
        return <AutoSizer disableHeight>
            {({width}) => {
                const keyMap = Object.keys(phmData) as string[];
                return (
                    <Table
                        width={width}
                        height={300}
                        headerHeight={30}
                        //scrollToIndex={this.state.selRecordIndex}
                        rowStyle={({index}) => ({
                            cursor: 'pointer',
                            fontSize: '12px',
                            backgroundColor: index % 2 === 0 ?
                                (index === this.state.selRecordIndex ? '#c5ebf0' : '#dddddd')
                                : (index === this.state.selRecordIndex ? '#c5ebf0' : '#ffffff')})}
                        rowHeight={30}
                        onRowClick={({ index }) => {
                            if (phmData[keyMap[index]]) {
                                if (phmData[keyMap[index]].list.length > 0) {
                                    rowSelected(phmData[keyMap[index]].list[0].foreignApiVehicleId, keyMap[index]);
                                    this.setState({selRecordIndex: index});
                                }
                            }
                        }}
                        rowCount={Object.keys(phmData).length}
                        rowGetter={({ index }) => phmData[keyMap[index]]}
                    >
                        <Column
                            label='Vozidlo'
                            cellRenderer={(cell) => cell.cellData[0].identifier}
                            dataKey='list'
                            width={120}
                        />
                        <Column
                            label='Ujetých KM'
                            cellRenderer={(cell) => cell.cellData[0].odoDiff}
                            dataKey='list'
                            width={80}
                        />
                        <Column
                            label='Spotřeba'
                            cellRenderer={(cell) => cell.cellData[0].fuelConsuption}
                            dataKey='list'
                            width={80}
                        />
                        <Column
                            label='Natankováno'
                            cellRenderer={cell => cell.cellData.toFixed(2)}
                            dataKey='sum'
                            width={120}
                        />
                        <Column
                            label='Spotřebováno'
                            cellRenderer={(cell) => cell.cellData[0].fBdiff}
                            dataKey='list'
                            width={120}
                        />
                        <Column
                            label='Nádrž [l]'
                            cellRenderer={(cell) => cell.cellData[0].tankSize}
                            dataKey='list'
                            width={120}
                        />
                        <Column
                            label='Nádrž v začátku'
                            cellRenderer={(cell) => cell.cellData[0].fLmin}
                            dataKey='list'
                            width={120}
                        />
                        <Column
                            label='Nádrž na konci'
                            cellRenderer={(cell) => cell.cellData[0].fLmax}
                            dataKey='list'
                            width={120}
                        />
                        <Column
                            label='Rozdíl nadrže'
                            cellRenderer={(cell) => cell.cellData[0].fuelBalance !== null ? cell.cellData[0].fuelBalance.toFixed(2) : 'Neznámo'}
                            dataKey='list'
                            width={120}
                        />
                        <Column
                            label='Unik paliva'
                            cellRenderer={(cell) => {
                                const value = (cell.rowData.list[0].fBdiff - cell.rowData.sum + cell.cellData[0].fuelBalance).toFixed(2);
                                return <div style={{color: value < 0 ? 'red' : 'black'}}>
                                    {(cell.rowData.list[0].fBdiff - cell.rowData.sum + cell.cellData[0].fuelBalance).toFixed(2)}
                                </div>
                            }}
                            dataKey='list'
                            width={120}
                        />
                        <Column
                            label='Min datum'
                            cellRenderer={(cell) => moment(cell.cellData[0].minDate, dateFromat).format(dateFormatVisible) }
                            dataKey='list'
                            width={120}
                        />
                        <Column
                            label='Max datum'
                            cellRenderer={(cell) => moment(cell.cellData[0].maxDate, dateFromat).format(dateFormatVisible) }
                            dataKey='list'
                            width={120}
                        />
                    </Table>
                );
            }}
        </AutoSizer>;
    }
}