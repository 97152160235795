import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from "react-fontawesome";
import {Button, Col, ControlLabel, FormControl, FormGroup, Modal, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table} from "react-virtualized";
import {YesNoDialog} from "./YesNoDialog";
import {LoadingOrRender} from "../loginsrc/Profile";
import request from 'axios';
import { dateFormatVisible, dateFromat} from "../AppObjects";
import DateTimePicker from 'react-datetime-picker'
import moment from "moment";
import {CanView} from "./Main";
import Attachments from "./other/Attachments";
import {DateRangePicker} from "react-dates";
import ItemsSelector from "./ItemsSelector";
import {SmallCreditCardView} from "./creditCard/Preview";

const itemTypes = [
  { id: 0, name: 'obecné útraty' },
  { id: 1, name: 'Tankování' },
  { id: 2, name: 'Tankování (ručně zadáno)'},
  { id: 3, name: 'Dokumentace'}
]

export default class RecordsList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      saveRequestActive: false,
      focused: null,
      startDate: null,
      endDate: null,
      focusedInput: null,
      invoiceDate: null,
      filterType: [],
    };
    this.backUpList = {};
    if(props.autoLoad)
      this.requestItems();
  }

  setListValue(name, index, value) {
    let list = this.state.list.slice();
    list[index][name] = value;
    this.setState({ list });
  }

  _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 40,
  });

  requestItems(idProps) {
    const identificationProps = {
      ...idProps,
      ...this.props.identificationProps
    };
    request.get('/items/items', {
      params: {
        where: identificationProps
      }
    }).then(res => {
        if (res.status === 200) {
          this.setState({
            list: res.data.map(o => { return {...o,
              date: new Date(o.date),
              updating: false,
              files: o.listOfFiles ? o.listOfFiles : []}}),
          });
        }
      })
  }

  deleteItem(index) {
    if (typeof this.state.list[index].id !== 'undefined') {
      request.delete('/items/items', {
        params: { id: this.state.list[index].id }
      })
        .then(res => {
          if (res.status === 200) {
            let list = this.state.list.slice();
            list.splice(index, 1);
            this.setState({list});
          }
        })
    } else {
      let list = this.state.list.slice();
      list.splice(index, 1);
      this.setState({list});
    }
  }

  saveItem(index) {
    this.setState({
      saveRequestActive: true,
    });
    let list = this.state.list.slice();
    list[index].listOfFiles = [];
    delete list[index].invoiceDate;
    let formData = new FormData();
    list[index].files.forEach( attach => {
        formData.append(attach.name, attach);
        if (attach.url)
          list[index].listOfFiles.push(attach);
    });

    formData.append('values', JSON.stringify({
      ...list[index],
      date: moment(list[index].date).format(dateFromat + ' HH:mm:ss')
    }));

    if (typeof list[index].id === 'undefined') {
      request.post('/items/items', formData)
        .then(res => {
          if (res.status === 200) {
            let l = this.state.list.slice();
            l[index] = res.data;
            l[index].updating = false;
            l[index].files = res.data.listOfFiles;
            l[index].date = new Date(res.data.date);
            l[index].canBeUpdated = true;
            this.setState({
              saveRequestActive: false,
              list: l,
            });
          }
        }).catch(err => this.setState({saveRequestActive: false}))
    } else {
      request.put('/items/items', formData)
        .then(res => {
          if (res.status === 200) {
            this.setState({ saveRequestActive: false });
            this.setListValue('updating', index, false);
          }
        }).catch(err => this.setState({saveRequestActive: false}))
    }
  }

  handleDateChange(startDate, endDate) {
    this.setState({ startDate, endDate });
    this.requestItems({
      date: endDate !== null && startDate !== null ? {
        $between: [startDate.format(dateFromat), endDate.format(dateFromat)],
      } : undefined,
      type: this.state.filterType.length > 0 ? this.state.filterType : undefined
    })
  }

  handleTypeChange(value) {
    const {startDate, endDate} = this.state;
    this.setState({
      filterType: value,
    })
    this.requestItems({
      date: endDate !== null && startDate !== null ? {
        $between: [startDate.format(dateFromat), endDate.format(dateFromat)],
      } : undefined,
      type: value.length > 0 ? value : undefined
    })
  }


  render() {
    const { list, saveRequestActive, startDate, endDate } = this.state;
    const sum = list.reduce((acc, o) => acc + o.amount * o.unitPrice, 0);
    const sumPrivate = list.reduce((acc, o) => {
     const card = this.props.cards.find(c => c.number === o.cardNumber)
      if (card) {
        if (card.isForPrivateUse)
          return acc + o.amount * o.unitPrice;
      }
      return acc;
    }, 0);
    this._cache.clear();
    return <>
      <div style={{ display: 'inline-block', marginBottom: '10px' }}>
        <DateRangePicker
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
          startDatePlaceholderText='Začátek'
          endDatePlaceholderText='Konec'
          displayFormat={ dateFormatVisible }
          small
          isOutsideRange = {() => false}
          minimumNights={0}
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => this.handleDateChange(startDate, endDate)} // PropTypes.func.isRequired,
          focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
        />
        <ItemsSelector
          appendItemsToStart={[
            { id: 0, name: 'Všechno' },
          ]}
          itemsToFetch=''
          listChanged={(list) => this.handleTypeChange(list)}
          externList={itemTypes}
          isFilterable
          value='name'/>
      </div>

        <AutoSizer disableHeight>
          {({width}) => (
            <Table
              deferredMeasurementCache={this._cache}
              width={width}
              height={350}
              headerStyle={{ fontSize: '12px' }}
              headerHeight={30}
              rowHeight={this._cache.rowHeight}
              rowStyle={({index}) => {
                if(index > -1) {
                  return {
                    backgroundColor: index % 2 === 0 ? (list[index].updating ? 'rgba(234,181,65,0.65)' : '#f2f2f2') : (list[index].updating ? 'rgba(255,200,70,0.52)' : '#ffffff'),
                  }
                }
              }}
              rowCount={list.length}
              rowGetter={({ index }) => list[index]}
            >
              <Column
                label='Datum'
                dataKey='date'
                cellRenderer={(cell) => <CellMeasurer
                  cache={this._cache}
                  columnIndex={0}
                  key={cell.dataKey}
                  parent={cell.parent}
                  rowIndex={cell.rowIndex}>
                  {
                    cell.rowData.updating && cell.rowData.canBeUpdated ?
                      <DateTimePicker clearIcon={null} disableCalendar disableClock calendarIcon={null} value={cell.cellData} onChange={
                        (date) => this.setListValue('date', cell.rowIndex, date)}/> :
                      moment(cell.cellData).format(dateFormatVisible + ' HH:mm:ss')
                  }
                </CellMeasurer>}
                width={200}
              />
              <Column
                label='Název'
                dataKey='name'
                cellRenderer={(cell) => <CellMeasurer
                  cache={this._cache}
                  columnIndex={1}
                  key={cell.dataKey}
                  parent={cell.parent}
                  rowIndex={cell.rowIndex}>
                    <FormControl type="text"
                                 disabled={!cell.rowData.updating || !cell.rowData.canBeUpdated}
                                 value={cell.cellData}
                                 onChange={(e) => this.setListValue('name', cell.rowIndex, e.target.value)}
                    />
                </CellMeasurer>}
                width={200}
              />
              <Column
                label='Množství'
                dataKey='amount'
                cellRenderer={(cell) => <CellMeasurer
                  cache={this._cache}
                  columnIndex={2}
                  key={cell.dataKey}
                  parent={cell.parent}
                  rowIndex={cell.rowIndex}>
                  <FormControl type="number"
                               disabled={!cell.rowData.updating || !cell.rowData.canBeUpdated}
                               min={0}
                               value={cell.cellData}
                               onChange={(e) => this.setListValue('amount', cell.rowIndex, e.target.value)}
                  />
                </CellMeasurer>}
                width={100}
              />
              <Column
                label='Jednotka'
                dataKey='unit'
                cellRenderer={(cell) => <CellMeasurer
                  cache={this._cache}
                  columnIndex={3}
                  key={cell.dataKey}
                  parent={cell.parent}
                  rowIndex={cell.rowIndex}>
                  <FormControl type="text"
                               disabled={!cell.rowData.updating || !cell.rowData.canBeUpdated}
                               value={cell.cellData}
                               onChange={(e) => this.setListValue('unit', cell.rowIndex, e.target.value)}
                  />
                </CellMeasurer>}
                width={80}
              />
              <Column
                label='Cena'
                dataKey='price'
                cellRenderer={(cell) => <CellMeasurer
                  cache={this._cache}
                  columnIndex={4}
                  key={cell.dataKey}
                  parent={cell.parent}
                  rowIndex={cell.rowIndex}>
                  <FormControl type="number"
                               disabled={!cell.rowData.updating || !cell.rowData.canBeUpdated}
                               min={0}
                               value={cell.cellData}
                               onChange={(e) => this.setListValue('price', cell.rowIndex, e.target.value)}
                  />
                </CellMeasurer>}
                width={100}
              />
              <Column
                label='Celkem'
                dataKey='price'
                cellRenderer={(cell) => cell.rowData.canBeUpdated ? cell.rowData.amount * cell.rowData.price :  cell.rowData.amount * cell.rowData.unitPrice}
                width={100}
              />
              <Column
                width={100}
                dataKey='note'
                cellRenderer={cell => <OverlayTrigger trigger='click' placement='bottom' overlay={
                  <Popover id={`popover-contained ${cell.rowIndex}`}>
                    <textarea style={{ width: '100%', minHeight: '140px', padding:'10px'}}
                              disabled={!cell.rowData.updating}
                              onChange={(e) => {
                                this.setListValue('note', cell.rowIndex, e.target.value);
                              }}
                              value={cell.cellData} />
                  </Popover>
                }>
                  <div className='IconWithNumberBase'>
                    {
                      cell.cellData ? (cell.cellData.length > 0 ? <div style={{ right: '-4px', top: '0px' }}
                                                               className='warning'>
                        <FontAwesome name='fas fa-exclamation' />
                      </div> : null) : null
                    }

                    <button className='transparentButton'>
                      <FontAwesome style={{fontSize:'22px', color: cell.cellData ? (cell.cellData.length > 0 ? '#2c2c2c' : 'grey') : 'grey', }} name='fas fa-clipboard' />
                    </button>
                  </div>
              </OverlayTrigger>}
                label='Pozn.'>
              </Column>

              <Column
                label='Přílohy'
                dataKey='files'
                width={100}
                cellRenderer={(cell) => <CellMeasurer
                  cache={this._cache}
                  columnIndex={7}
                  key={cell.dataKey}
                  parent={cell.parent}
                  rowIndex={cell.rowIndex}>
                  <Attachments useModal updating={cell.rowData.updating} onListChanged={(l) => this.setListValue('files', cell.rowIndex, l)} list={cell.cellData} rowIndex={cell.rowIndex}/>
                </CellMeasurer>}
              />
              <Column
                label='Karta'
                dataKey='cardNumber'
                cellRenderer={cell => {
                  const item = this.props.cards.find(o => o.number === cell.cellData)
                  if (item) {
                    return <SmallCreditCardView item={item} />
                  }
                  return '';
                }}
              />
              <Column
                width={100}
                cellRenderer={cell => <>
                  <div style={{display: 'inline-block', marginRight: saveRequestActive ? '20px' : ''}}>
                    <LoadingOrRender height={20} width={20} requestActive={saveRequestActive}>
                      <button style={{marginRight: '15px'}} onClick={() => {
                        if (!cell.cellData) {
                          this.backUpList[cell.rowIndex] = JSON.parse(JSON.stringify(this.state.list[cell.rowIndex]));
                          this.setListValue('updating', cell.rowIndex, !cell.cellData);
                        } else {
                          this.saveItem(cell.rowIndex);
                        }

                      }}  className='text-right transparentButton'>
                        <FontAwesome style={{ fontSize: '20px' }} name={`fas fa-${cell.cellData ? 'check' : 'pen'}`} />
                      </button>
                    </LoadingOrRender>
                  </div>
                  { saveRequestActive ? null : (
                    cell.cellData && this.backUpList[cell.rowIndex] ? <button onClick={() => {
                      let list = this.state.list.slice();
                      list[cell.rowIndex] = this.backUpList[cell.rowIndex];
                      delete this.backUpList[cell.rowIndex];
                      list[cell.rowIndex].updating = false;
                      this.setState({ list });
                    }} className='text-right transparentButton'>
                      <FontAwesome style={{ fontSize: '20px' }} name={'times'} />
                    </button> : (cell.rowData.canBeUpdated ? <YesNoDialog onYes={() => {
                      this.deleteItem(cell.rowIndex);
                    }} message={'Opravdu chcete vymazat daný záznam ?'}/> : null)
                  )
                  }

                </>}
                dataKey='updating'/>
            </Table>
          )}
        </AutoSizer>
      <h5 className='text-right'>{`Celkem: ${Math.round((sum + Number.EPSILON) * 100) / 100}`}</h5>
      <h5 className='text-right'>{`Celkem privátně: ${Math.round((sumPrivate + Number.EPSILON) * 100) / 100}`}</h5>
        <CanView visible={!this.props.disableNew}>
          <button onClick={() => {
            let list = this.state.list.slice();
            list.push({
              updating: true,
              name: '',
              note: '',
              date: new Date(),
              amount: 0,
              price: 0,
              canBeUpdated: true,
              unit: '',
              files: [],
              listOfFiles: [],
              ...this.props.identificationProps,
            });
            this.setState({list})
          }} className='transparentButton'>
            <FontAwesome style={{ marginRight: '10px', fontSize: '16px', color: '#055ADA' }} name='fas fa-plus-circle' />
            Nový záznam
          </button>
        </CanView>

      </>
  }
}

RecordsList.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object),
  autoLoad: PropTypes.bool,
  disableNew: PropTypes.bool,
  identificationProps: PropTypes.object.isRequired,
};

RecordsList.defaultProps = {
  disableNew: false,
  autoLoad: true,
  identificationProps: {},
  cards: [],
};


