import {CanView} from "../../Main";
import {BaseCalculationItem, IDispatchingAttributesCustom} from "@cml/types";
import PriceCalculation from "../components/PriceCalculation";
import {roundFloatTwoDigit} from "../../../AppObjects";
import React, {useState} from "react";
import {Attributes, Roles} from "@cml/models";
import {useSelector} from "react-redux";
import {AuthenticateState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getOrders} from "../../../fetchApi/dispatching";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrPrice({ attribute, index, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const from = useSelector(selectDataRowValue(index, singleRowEditMode, 'from'));
  const to = useSelector(selectDataRowValue(index, singleRowEditMode, 'to'));
  const break_ = useSelector(selectDataRowValue(index, singleRowEditMode, 'break'));
  const orderId = useSelector(selectDataRowValue(index, singleRowEditMode, 'orderId'));

  const priceEq = useSelector(selectDataRowValue(index, singleRowEditMode, 'priceEq'));
  const materialTable = useSelector(selectDataRowValue(index, singleRowEditMode, 'materialTable'));

  const { orders, isValidating: ordersValidating } = getOrders(
    { params: { where: { isConfirmed: 0 }}}, );


  let selectedOrder = orders ? orders.find(o => o.id === (orderId)) : undefined;

  // Fake it due to rerender when vehicleId change
  const vehicleId = useSelector(selectDataRowValue(index, singleRowEditMode, 'vehicleId'));

  if (!orders)
    return <div>Loading</div>

  return <>
    <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom)
      .canCalculation && dispatchOther.canCalculation}>
      <td>
        <PriceCalculation
          singleRowEditMode={singleRowEditMode}
          attribute={attribute}
          priceEq={priceEq as BaseCalculationItem[]}
          data={{
            from,
            to,
            break: break_,
            materialTable,
          }}
          onTotalChanges={(totalPrice_, totalCost_) => {
            setTotalPrice(totalPrice_);
            setTotalCost(totalCost_);
          }}
          onPriceEqChanged={(equ) => {
            store.dispatch(setDataRowValue(index, singleRowEditMode, {
              priceEq: equ as BaseCalculationItem[],
            }))
          }}
          selectedOrder={selectedOrder}
        />
      </td>
      <td>
        {roundFloatTwoDigit(totalPrice)}
      </td>
    </CanView>
    <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canUseCosts && dispatchOther.canUseCosts}>
      <td>
        {roundFloatTwoDigit(totalCost)}
      </td>
      <td style={{color: totalPrice - totalCost < 0 ? 'red' : 'darkgreen'}}>
        {`${totalPrice - totalCost < 0 ? '' : '+'}${roundFloatTwoDigit(totalPrice - totalCost)}`}
      </td>
    </CanView>
  </>
}