import useSWR from 'swr';
import {fetcher, fetcherWithParams} from "./index";
import {AddressBook, Attributes, Tools, Users, Vehicles, Orders} from "@cml/models";
import queryString from 'qs';
import request from "axios";
import {companies, constructions} from "../AppObjects";

export const getUsersForDispatching = (query?: { params: any }, swrOption: object = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}) => {
  const {data, isValidating, error, revalidate} = useSWR(
    ['/items/usersForDispatching', query ? queryString.stringify(query.params) : ''],
    (url) => fetcherWithParams(url, query),swrOption);

  return {
    isValidating,
    error,
    users: data as Users[],
    revalidate
  }
}

export const getUsersWithSpecificRoleWhere = (query: { params: any }, swrOption: object = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}) => {
  const {data, isValidating, error, revalidate} = useSWR(
    ['/items/usersWithSpecificRoleWhere', queryString.stringify(query.params)],
    (url) => fetcherWithParams(url, query),swrOption);

  return {
    isValidating,
    error,
    contactUsers: data as Users[],
    revalidate
  }
}

export const getVehicles = (query: { params: any }, swrOption: object = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}) => {
  const {data, isValidating, error, revalidate} = useSWR(
    ['/items/vehicles', queryString.stringify(query.params)],
    url => fetcherWithParams(url, query),swrOption);

  return {
    isValidating,
    error,
    vehicles: data as Vehicles[],
    revalidate
  }
}

export const getTools = (query: { params: any }, swrOption: object = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}) => {
  const {data, isValidating, error, revalidate} = useSWR(
    ['/items/tools', queryString.stringify(query.params)],
    url => fetcherWithParams(url, query), swrOption);

  if (data)
    data.forEach((item: { id: { toString: () => any; }; }) => {
      item.id = item.id.toString();
    });

  return {
    isValidating,
    error,
    tools: data as Tools[],
    revalidate
  }
}

export const getAttributes = (type: string, swrOption: object = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}, query: { params: any } | undefined = undefined) => {

  const { data, isValidating, error, revalidate } = useSWR(
    ['/items/attributes/' + type,
      queryString.stringify(query ? query.params: undefined)],
    (url) => fetcherWithParams(url, query),swrOption);
  return {
    isValidating,
    error,
    attributes: data as Attributes[],
    revalidate
  }
}

export const getAddressBook = (swrOption: object = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}) => {
  const { data, isValidating, error, revalidate } = useSWR('/items/addressbook',
    fetcher,swrOption);
  return {
    isValidating,
    error,
    addressBook: data as AddressBook[],
    revalidate
  }
}

export const getCompanies = (swrOption: object = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}) => {
  const {
    data, isValidating, error, revalidate
  } = useSWR('/items/distinctCompanies', fetcher, swrOption);

  return {
    isValidating,
    error,
    companies: data,
    revalidate
  }
}

export const getConstructions = (swrOption: object = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}) => {
  const {
    data, isValidating, error, revalidate
  } = useSWR('/items/distinctConstructions', fetcher, swrOption);

  return {
    isValidating,
    error,
    constructions: data,
    revalidate
  }
}

export const getOrders = (query: { params: any }, swrOption: object = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}) => {
  const {data, isValidating, error, revalidate} = useSWR(
    ['/items/orders', queryString.stringify(query.params)],
    url => fetcherWithParams(url, query),swrOption);

  return {
    isValidating,
    error,
    orders: data as Orders[],
    revalidate
  }
}