import moment, {Moment} from "moment";
import FontAwesome from "react-fontawesome";
import {SingleDatePicker} from "react-dates";
import {dateFormatVisible} from "../../../AppObjects";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {DataRowsState} from "@cml/redux-store";

interface Props {
  selectedDate: Moment,
  setSelectedData: (date: Moment) => void;

}

export default function DateSelector(props: Props) {
  const [datePickerFocused, setDatePickerFocused] = useState<boolean | null>(null);
  const changesMadeState = useSelector((state: {dispatching: DataRowsState}) => state.dispatching.changesMade);

  return <div style={{ fontSize: '14px', display: 'inlineBlock', textAlign: 'center' }}>
    <button style={{fontSize: "18px", textAlign: 'center', marginRight: '15px'}}
            className='transparentButton'
            disabled={changesMadeState > 0}
            onClick={() => {
              let date = moment(props.selectedDate);
              date.add(-1, 'day');
              props.setSelectedData(date);
            }}>
      <FontAwesome name='fas fa-chevron-left'/>
    </button>
    <span style={{ fontSize: '20px',
      fontWeight: 'bold',
      marginRight: '20px',
      display: 'inlineBlock',
      textAlign: 'center' }}>
                  {props.selectedDate.format('ddd')}
                </span>
    <span style={
      moment().isBefore(props.selectedDate) ? {backgroundColor: '#FFA500', paddingTop: '8px', paddingBottom: '9px'}
        : {backgroundColor: '#FFFFFF', paddingTop: '8px', paddingBottom: '9px'}}>
                    <SingleDatePicker
                      small
                      disabled={changesMadeState > 0}
                      date={props.selectedDate} // momentPropTypes.momentObj or null
                      displayFormat={ dateFormatVisible }
                      isOutsideRange = {() => false}
                      onDateChange={date => {
                        props.setSelectedData(date as Moment);
                      }} // PropTypes.func.isRequired
                      focused={datePickerFocused} // PropTypes.bool
                      onFocusChange={({ focused }) => setDatePickerFocused(focused)} // PropTypes.func.isRequired
                      id="your_unique_id" // PropTypes.string.isRequired,
                    />
                  </span>
    <button style={{fontSize: "18px", textAlign: 'center', marginLeft: '15px'}}
            disabled={changesMadeState > 0}
            className='transparentButton'
            onClick={() => {
              let date = moment(props.selectedDate);
              date.add(1, 'day');
              props.setSelectedData(date)
            }}>
      <FontAwesome name='fas fa-chevron-right'/>
    </button>

  </div>
}