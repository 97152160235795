import React from 'react';
import { OverlayTrigger, Popover} from "react-bootstrap";

export function SidePanel2(props) {
  return <OverlayTrigger rootClose trigger='click' placement='bottom' overlay={
    <Popover id='playerColorPicker' placement='bottom'>
      <div className='sidePanelPopover'>
        <h3>Volné</h3>
        {props.children}
      </div>
    </Popover>
  }>
    <a style={{ cursor: 'pointer', color: '#FFFFFF' }}>
      {props.text}
    </a>
  </OverlayTrigger>
}
