import React, {Component, useEffect, useRef, useState} from "react";
import {
  Button,
  Col,
  ControlLabel, Dropdown,
  DropdownButton,
  Form,
  FormGroup,
  MenuItem,
  Modal,
  Row
} from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import {dateFormatVisible, dateFromat, getTimeString} from "../../AppObjects";
import {LoadingOrRender} from "../../loginsrc/Profile";
import request from 'axios';
import SubmitButton from "../../loginsrc/SubmitButton";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import MaterialTable from "./components/MaterialTable";
import {getTimeDiff, getStore} from "./components/datarowBase";
import {DataRow} from "@cml/models";
import {
  getAddressBook,
  getAttributes,
  getOrders,
  getUsersForDispatching,
  getUsersWithSpecificRoleWhere, getVehicles
} from "../../fetchApi/dispatching";
import {BaseCalculationItem, IDispatchingAttributesCustom, IPriceAttributesCustom, Material, IEmailTemplate} from "@cml/types";

interface Props {
  dataRow: DataRow;
  onDone: (data: {orderForeignId: string | null, isConfirmed: number}, deleted: boolean) => void;
  selectedDate: string;
  onHide: () => void;
  active: boolean;
}

export default function OrderDialog ({ dataRow, onDone, selectedDate, active, onHide }: Props) {

  const [submitActive, setSubmitActive] = useState(false);
  const [orderText, setOrderText] = useState('');
  const [orderNote, setOrderNote] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [warningMessages, setWarningMessages] = useState<string[]>([]);
  const [paymentType, setPaymentType] = useState('prikazem');
  const [bookNumber, setBookNumber] = useState<string | null>(null);
  const [activeTemplate, setActiveTemplate] = useState(0);

  const ccpText = useRef('');


  const { orders, isValidating: ordersValidating } = getOrders(
    { params: { where: { isConfirmed: 0 }}}, );

  const { users, isValidating: usersValidating } = getUsersForDispatching(
    { params: { date: selectedDate }});

  const { contactUsers, isValidating: contactValidating } = getUsersWithSpecificRoleWhere(
    { params: { roleWhere: { isContactUser: 1 }}}, );

  const { attributes, isValidating: attributesValidating } = getAttributes('dispatching');
  const { attributes: priceAttributes,
    isValidating: priceValidating } = getAttributes(
    'price' );
  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes(
    'material' );
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store' );

  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { allDetails: 0, onlyForDispatching: 1  }}, );

  const { addressBook, isValidating: addressValidating } = getAddressBook(

  );

  if(!vehicles || !users || !contactUsers || !orders || !attributes)
    return <div>Loading</div>;

  const attribute = attributes.find(o => o.id === dataRow.attributeId);
  const vehicle = vehicles.find(o => o.id === dataRow.vehicleId);
  const addressBook_ = addressBook.find(o => o.id === dataRow.addressBookId);

  /*constructor(props) {
    super(props);
    this.state = {
      submitActive: false,
      orderText: '',
      orderNote: '',
      errorMessages: [],
      warningMessages: [],
      paymentType: 'prikazem',
      bookNumber: null,
      activeTemplate: 0,
    };
    this.attribute = null;
    this.vehicle = null;
    this.contactUser = null;
    this.addressBook = null;
    this.ccpText = '';
  }*/

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    setSubmitActive(true);
    request.post('/items/pohoda/generateorder', {
      order: {
        paymentType,
        text: orderText,
        note: orderNote,
      },
      dataRow,
    }).then((res) => {
        store.dispatch(show({
          title: 'Info',
          level: 'info',
          autoDismiss: 3,
          children: (
            <div>
              Objednávka byla odeslána
            </div>
          ),
        }, 'info'));
        onDone(res.data, false);
        setSubmitActive(false);
      })
      .catch((err) => {
        setSubmitActive(false);
        if (err.status === 403) {
          store.dispatch(show({
            title: 'Error',
            level: 'error',
            autoDismiss: 10,
            children: (
              <div>
                {`Nepodařilo se odeslat email, objednávka byla importována do účetního systému, zkontrolujte zda je email dodavatele správný`}
              </div>
            ),
          }, 'error'));
        } else {
          store.dispatch(show({
            title: 'Error',
            level: 'error',
            autoDismiss: 10,
            children: (
              <div>
                {`Server hlásí chybu: ${err.status}`}
              </div>
            ),
          }, 'error'));
        }
      })
  }

  const generateText = (text: string, dataRow: DataRow) => {
    const oneStore = getStore(dataRow, storeAttributes);
    const vehicle = vehicles.find(o => o.id === dataRow.vehicleId);
    if (!vehicle) {
      return '';
    }
    const addressBook_ = addressBook.find(o => o.id === dataRow.addressBookId);
    if (!addressBook_) {
      return '';
    } else {
      if (addressBook_.email === '' || addressBook_.email.indexOf('@') === -1) {
        errorMessages.push(`Email neexistuje nebo nemá správny formát`);
      }
    }
    const contactUser = contactUsers.find(o => o.id === dataRow.contactUserId);
    text = text.replace('%date%', moment(dataRow.date, dateFromat).format(dateFormatVisible));
    text = text.replace('%on%', `${dataRow.orderNumber}`);
    text = text.replace('%veh%', `${vehicle.identifier}`);
    text = text.replace('%user%', `${addressBook_.company}`);
    text = text.replace('%load%', `${oneStore ? oneStore.name : dataRow.loading}`);
    text = text.replace('%unlo%', `${dataRow.unloading}`);
    text = text.replace('%com%', `${dataRow.company}`);
    text = text.replace('%con%', `${dataRow.construction}`);
    text = text.replace('%cusn%', `${contactUser ? contactUser.name : '"neuve'}${contactUser ? ' ' 
      + contactUser.surname : 'deno"'}`);
    text = text.replace('%cust%', `${contactUser ? contactUser.telnumb : '"neuvedeno"'}`);
    text = text.replace('%tf%', `${getTimeString(dataRow.from)}`);
    text = text.replace('%tt%', `${getTimeString(dataRow.to)}`);
    text = text.replace('%brea%', `${getTimeString(dataRow.break)}`);
    text = text.replace('%frac%', `${MaterialTable.GenerateString({
      materialTable: dataRow.materialTable as Material[],
      priceAttributes,
      // @ts-ignore
      materialAttributes,
      storeAttributes,
    })}`);
    text = text.replace('%ccp%', ccpText.current);
    return text;
  }

  useEffect(() => {
    if (active) {
      if (typeof dataRow === 'undefined')
        return;
      ccpText.current = '';
      let i = 0;

      let warningMessages = [];
      const sum = getTimeDiff(dataRow).sum;
      let totalPrice = 0;
      let totalCost = 0;
      if (dataRow.priceEq !== null) {
        (dataRow.priceEq as BaseCalculationItem[]).forEach((item) => {
          let total = item.total;
          let costTot = item.costTotal;
          if (costTot === 0)
            warningMessages.push(`Cena pro dodavatele je rovna 0 !!`);
          const priceAttr = priceAttributes.find(o => o.id === item.attributeId);
          const index = priceAttributes.findIndex(o => o.id === item.attributeId);
          if (priceAttr) {
            if (i > 0) {
              ccpText.current += ', '
            }
            ccpText.current += item.costPrice + ',-/' + priceAttr.name;
            i++;
            if ((priceAttr.customOptions as IPriceAttributesCustom).isHourAttribute) {
              item.amount = sum;
              item.total = item.price as number * sum;
              item.costTotal = item.costPrice as number * sum;
              total = item.price as number * sum;
              costTot = item.costPrice as number * sum;
            }
          }
          totalPrice += total;
          totalCost += costTot;
        });
      }

      if (isNaN(totalCost))
        totalCost = 0;

      let errorMessages = [];

      if (totalPrice - totalCost < 0) {
        warningMessages.push(`Zisk má zaporné číslo ujistěte se, že Vaše kalkulace ceny je správná !!`);
      }
      if (ccpText.current === "") {
        errorMessages.push(`Není vyplněná kalkulace pro řádek !!`);
      }

      if (ccpText.current === "") {
        errorMessages.push(`Není vyplněná kalkulace pro řádek !!`);
      }

      const attribute = attributes.find(o => o.id === dataRow.attributeId);
      const vehicle = vehicles.find(o => o.id === dataRow.vehicleId);
      if (!attribute) {
        return;
      }
      if (!vehicle) {
        return;
      }
      const addressBook_ = addressBook.find(o => o.id === dataRow.addressBookId);
      if (!addressBook_) {
        return;
      } else {
        if (addressBook_.email === '' || addressBook_.email.indexOf('@') === -1) {
          errorMessages.push(`Email neexistuje nebo nemá správny formát`);
        }
      }
      const contactUser = contactUsers.find(o => o.id === dataRow.contactUserId);
      let text = '';
      let note = '';
      if ((attribute.customOptions as IDispatchingAttributesCustom).orderEmailTemplates) {
        if ((attribute.customOptions as IDispatchingAttributesCustom).orderEmailTemplates.length > 0) {
          text = (attribute.customOptions as IDispatchingAttributesCustom).orderEmailTemplates[0].text;
          text = generateText(text, dataRow);
          note = (attribute.customOptions as IDispatchingAttributesCustom).orderEmailTemplates[0].note;
        } else {
          warningMessages.push(`Neexistuje šablona pro objednávku lze vyplnit v nastavení -> atributy dispečinku`);
        }
      } else {
        warningMessages.push(`Neexistuje šablona pro objednávku lze vyplnit v nastavení -> atributy dispečinku`);
      }
      setErrorMessages(errorMessages);
      setWarningMessages(warningMessages);
      setOrderText(text);
      setOrderNote(note);
    }
  }, [active])

  let orderEmailTemplates: IEmailTemplate[] = [];
  if (attribute)
    orderEmailTemplates = (attribute.customOptions as IDispatchingAttributesCustom).orderEmailTemplates ?
      (attribute.customOptions as IDispatchingAttributesCustom).orderEmailTemplates : [];
  if (typeof dataRow === 'undefined')
    return null;

  let body = <div>Není vybrán dodavatel nebo stroj</div>;
  if (addressBook_ && vehicle) {
    if (dataRow.orderForeignId !== null) {
      body = <div>
        <h3>
          Objednávka již byla odeslána
        </h3>
        <p>
          Tento řádek již obsahuje vazbu na účetní systém, POHODA bohužel neumožnuje aktualizovat záznam,
          proto lze objednávku editovat přímo v POHODĚ a ručně vygenerovat a poslat objednávku (číslo lze získat tlačítkem níže),
          nebo lze záznam v POHODĚ vymazat případně pouze jeho vazbu a poté vygenerovat zcela novou objednávku.
        </p>
        {
          bookNumber === null ?
            <LoadingOrRender requestActive={submitActive}>
              <Button onClick={() => {
                setSubmitActive(true);
                request.get('/items/pohoda/getorder', { params: {id: dataRow.orderForeignId}})
                  .then((res) => {
                    setSubmitActive(false);
                    if(typeof res.data['lst:listOrder']['lst:order'] === 'undefined') {
                      setBookNumber('Záznam již neexistuje :(');
                    } else {
                      setBookNumber(res.data['lst:listOrder']['lst:order']['ord:orderHeader']['ord:number']['typ:numberRequested']);
                    }
                  })
                  .catch((err) => setSubmitActive(false))
              }}>
                Získat číslo z účetního systému
              </Button>
            </LoadingOrRender> :
            <div style={{marginBottom: '15px'}}>{`číslo v účetním systému: ${bookNumber}`}</div>
        }

        <LoadingOrRender requestActive={submitActive}>
          <Button onClick={() => {
            setSubmitActive(true);
            request.post('/items/pohoda/deleteorder',{dataRow: dataRow} )
              .then((res) => {
                setSubmitActive(false);
                store.dispatch(show({
                  title: 'Info',
                  level: 'info',
                  autoDismiss: 6,
                  children: (
                    <div>
                      Záznam byl vymazán z účetního systému, nyní můžete vygenerovat nový
                    </div>
                  ),
                }, 'info'));
                onDone(res.data, true);
              })
              .catch((err) => setSubmitActive(false))
          }}>
            Vymazat záznam v účetním systému
          </Button>
        </LoadingOrRender>
        <Button onClick={() => {
          onDone({
            orderForeignId: null,
            isConfirmed: 0,
          }, true);
        }}>
          Vymazat pouze vazbu a vegenerovat novou objednávku
        </Button>

      </div>;
    } else {
      body = <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={4}>
            <h3>Dodavatel</h3>
            <Row>
              <Col sm={6}>
                <FormGroup controlId="ic">
                  <ControlLabel>IČ</ControlLabel>
                  <div>
                    {addressBook_.ic}
                  </div>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup controlId="dic">
                  <ControlLabel>DIČ</ControlLabel>
                  <div>
                    {addressBook_.dic}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup controlId="company">
                  <ControlLabel>Firma</ControlLabel>
                  <div>
                    {addressBook_.company}
                  </div>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup controlId="street">
                  <ControlLabel>Ulice</ControlLabel>
                  <div>
                    {addressBook_.address}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup controlId="psc">
                  <ControlLabel>PSČ</ControlLabel>
                  <div>
                    {addressBook_.psc}
                  </div>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup controlId="country">
                  <ControlLabel>Země</ControlLabel>
                  <div>
                    {addressBook_.country}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <FormGroup controlId="tel">
                  <ControlLabel>Telefon</ControlLabel>
                  <div>
                    {addressBook_.telnumb}
                  </div>
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup controlId="email">
                  <ControlLabel>Email</ControlLabel>
                  <div>
                    {addressBook_.email}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col sm={4}>
            <h3>Text objednávky</h3>
            <ControlLabel style={{marginRight: '20px', marginBottom: '14px'}}>Šablona</ControlLabel>
            <Dropdown
              onSelect={(e: any) => {
                if (!attribute)
                  return;
                let text = (attribute.customOptions as IDispatchingAttributesCustom)
                  .orderEmailTemplates[e].text;
                text = generateText(text, dataRow);
                setActiveTemplate(e as number);
                setOrderText(text);
                setOrderNote((attribute.customOptions as IDispatchingAttributesCustom)
                  .orderEmailTemplates[e].note)
              }}
              id="prices">
              <Dropdown.Toggle>
                {orderEmailTemplates.length === 0 ? 'Neexistuje žádná šablona' : orderEmailTemplates[activeTemplate].name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {
                  Object.keys(orderEmailTemplates).map(key =>
                    <MenuItem eventKey={key} key={key}>
                      {orderEmailTemplates[Number(key)].name}
                    </MenuItem>
                  )
                }
              </Dropdown.Menu>
            </Dropdown>
            <textarea style={{ marginBottom: '20px', width: '100%', minHeight: '160px', padding:'10px'}}
                      onChange={(e) => {
                        setOrderText(e.target.value);
                      }}
                      value={orderText} />
            <ControlLabel style={{marginRight: '20px'}}>Typ platby</ControlLabel>
            <DropdownButton
              onSelect={(e: any) => {
                setPaymentType(e);
              }}
              title={paymentType}
              id=''>
              <MenuItem eventKey="prikazem">prikazem</MenuItem>
              <MenuItem eventKey="hotove">hotove</MenuItem>
            </DropdownButton>
          </Col>
          <Col sm={4}>
            <h3>Poznámka</h3>
            <textarea style={{ width: '100%', minHeight: '240px', padding:'10px'}}
                      onChange={(e) => {
                        setOrderNote(e.target.value);
                      }}
                      value={orderNote} />
          </Col>
        </Row>
        <ul>
          {
            Object.keys(errorMessages).map((key) => {
              return <li style={{fontSize: 18, color: 'red'}}>
                {errorMessages[Number(key)]}
              </li>
            })
          }
        </ul>
        <ul>
          {
            Object.keys(warningMessages).map((key) => {
              return <li style={{fontSize: 18, color: 'orange'}}>
                {warningMessages[Number(key)]}
              </li>
            })
          }
        </ul>

        <SubmitButton disabled={errorMessages.length > 0 || orderText.length > 240} bsClass='btn btn-primary btn-block'
                      loading={submitActive}>
          {orderText.length > 240 && '  Nelze odeslat délka textu je větší než 240'}
          {(warningMessages.length > 0 && orderText.length <= 240) && 'Odeslat a ignorovat upozornění  '}
          { (warningMessages.length === 0 && orderText.length <= 240)  && 'Odeslat'}
        </SubmitButton>
      </Form>;
    }

  }

  return <Modal dialogClassName="modal-90w" show={active}
                onHide={() => onHide()}>
    <Modal.Header closeButton={!submitActive} >
      <h3 className='text-center'>Objednávka</h3>
    </Modal.Header>
    <Modal.Body>
      {body}
    </Modal.Body>
  </Modal>
}