import React, { Component } from 'react';
import {Checkbox, Col, ControlLabel, DropdownButton, FormControl, MenuItem, Row} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import ItemsSelector from "../ItemsSelector";
import {mapAuthStateToProps} from "../../AppObjects";
import {connect} from "react-redux";

const stateNames = {
  all: 'Všechny stavy',
  valid: 'Platná',
  warning: 'Blíží se konec',
  notvalid: 'Neplatná',
};

class VehicleFilter extends Component{

  constructor(props) {
    super(props);
    this.state = {
      selectedSpaces: [],
      filterValue: '',
      stkState: 'all',
    }
  }

  render() {
    const {filterValue, selectedSpaces, stkState} = this.state;
    return (<div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll'>
      <Row>
        <Col sm={1}>
          <div className='text-right'>
            <FontAwesome style={{fontSize:'18px', marginTop: '8px'}} name='fas fa-search' />
          </div>
        </Col>
        <Col sm={3}>
          <FormControl
            value={this.state.filterValue}
            onChange={(e) => {
              this.setState({filterValue: e.target.value});
              this.doFiltr(selectedSpaces, e.target.value, stkState);
            }}
            type='text'/>
        </Col>
        {
          this.props.authenticate.actualUserRole.isAdmin ? <Col sm={2}>
            <ItemsSelector value={'name'}
                           overrideToggle={'Filtr prostorů'}
                           listChanged={(list, isFromInit) => {
                             this.setState({selectedSpaces: list});
                             if (!isFromInit)
                               this.doFiltr(list, filterValue, stkState);
                           }}
                           appendItemsToStart={[
                             {name: 'Všechny', id: 0}
                           ]}
                           isFilterable
                           itemsToFetch={'spaces'}/>
          </Col> : null
        }
        <Col>
          <div style={{display: 'inline-block', marginRight: '10px'}}><ControlLabel>
            STK
          </ControlLabel></div>
          <DropdownButton id='stkState'
                          onSelect={(key) => {
                            this.setState({ stkState: key });
                            this.doFiltr(selectedSpaces, filterValue, key);
                          }}
                          title={stateNames[this.state.stkState]}>
            <MenuItem eventKey='all'>{stateNames.all}</MenuItem>
            <MenuItem eventKey='valid'>{stateNames.valid}</MenuItem>
            <MenuItem eventKey='warning'>{stateNames.warning}</MenuItem>
            <MenuItem eventKey='notvalid'>{stateNames.notvalid}</MenuItem>
          </DropdownButton>
        </Col>

      </Row>
    </div>)
  }

  doFiltr(spaces, value, stk) {
    this.props.onFilter({
      spaceId: spaces,
      where: {
        identifier: { $like: `%${value}%` },
        stkDate: stk, // Server is translating key into date ranges !!
      }
    })
  }
}

export default connect(mapAuthStateToProps)(VehicleFilter);