import React, {useEffect, useReducer, useRef, useState} from 'react'
import {BackHeader} from "../Setting";
import {
  Col,
  Row,
  Button
} from "react-bootstrap";
import request from 'axios';
import {
  dateFromat,
  getFloatTime,
} from "../../AppObjects";
import moment from "moment";
import {CanView} from "../Main";
import 'react-virtualized/styles.css';
import Invoice from "./Invoice";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import { useDispatch, useSelector} from "react-redux";
import ResultRows from "./components/ResultRows";
import MultiRowChange from "./components/MultiRowChange";
import {
  AuthenticateState,
  DataRowsState,
  setDataRows,
  setVehiclesDataLoading,
  setVehiclesData,
  setSingleEditRow
} from "@cml/redux-store";
import DispatchingFilter, {IFilter, IFilterOrder} from "./components/DispatchingFilter";
import {useHistory} from "react-router";
import {
  getAddressBook,
  getAttributes,
  getCompanies,
  getOrders,
  getUsersWithSpecificRoleWhere, getVehicles
} from "../../fetchApi/dispatching";
import {Attendance, DataRow, Orders, Roles, Users, Vehicles, EDataRow} from "@cml/models";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getCalculation} from "./components/PriceCalculation";
import {reducer, ResultsContext, setEditedRow, setEditedRowIndex} from "./reducerResults";
import {useContextSelector} from "use-context-selector";
import EditSingleRowModal from './components/EditSingleRowModal';
import {IDispatchingAttributesCustom, IVehicleData, OrderOther} from "@cml/types";
import Print from "./components/Print";
import {createDataRow, fillOrder, getTimeDiff} from './components/datarowBase';
import { LoadingOrRender } from '../../loginsrc/Profile';
import ReactLoading from "react-loading";
import SalaryStateProvider, { SalaryResult } from '../salary/SalaryResult';
import ResultRowsTotals from "./components/ResultRowsTotals";

const swrOption = {
  revalidateOnFocus: false,
  revalidateOnMount: true,
}

interface InvoiceContainerProps {
  onDone: (invoices: {logItem: {itemId: number, id: number}}[]) => void;
  onStorno: (logItem: { description: { ids: any[]; }; id: number; }) => void;
}

const InvoiceContainer = ({ onDone, onStorno }: InvoiceContainerProps) => {

  const [invoiceModalActive, setInvoiceModalActive] = useState(false);

  const dataRows = useSelector((state: {dispatching: DataRowsState}) =>
    state.dispatching.dataRows as EDataRow[]);

  const editedDataRowIndex = useContextSelector(ResultsContext, v => v.state.editedDataRowIndex);

  const dispatchResult = useContextSelector(ResultsContext, v => v.dispatch);

  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes(
    'price', swrOption && swrOption, {params: {where: {spaceId: [1,3]}}});

  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes(
    'material', swrOption && swrOption, {params: {where: {spaceId: [1,3]}}});

  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store', swrOption && swrOption, {params: {where: {spaceId: [1,3]}}});

  const { addressBook, isValidating: addressValidating, revalidate: addressBookRevalidate } =
    getAddressBook(swrOption);

  if (!invoiceModalActive)
    return <Button
      onClick={() => {
        if (dataRows.filter(o => o.isSelected).length === 0) {
          store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
            children: (
              <div>
                Není vybrán žádný řádek pro fakturaci
              </div>
            ),
          }, 'error'));
          return;
        }
        if (dataRows.filter(o => {
          if (!o.isAttendance) {
            return o.isSelected && !o.calculation.priceEq.length
          }
          return o.isSelected;
        }).length > 0) {
          store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 5,
            children: (
              <div>
                Některý z řádků nemá vyplněnou kalkulaci. TIP: využijte atribut dispečinku pro filtraci řádků které má smysl fakturovat, případně použijte editaci pomocí černé tužky.
              </div>
            ),
          }, 'error'));
          return;
        }
        setInvoiceModalActive(true);
      }}
      bsStyle='primary' style={{ float: 'left', marginRight: '10px', marginTop: '14px' }}>
      Fakturuj
    </Button>;

  return <Invoice active={invoiceModalActive}
                  rowEditIsActive={editedDataRowIndex > -1}
                  onEditRequired={(row: EDataRow) => {
                    dispatchResult(setEditedRow(JSON.parse(JSON.stringify(row))));
                    dispatchResult(setEditedRowIndex(dataRows.findIndex(o => o.id === row.id)));
                  }}
                  priceAttributes={priceAttributes}
                  materialAttributes={materialAttributes}
                  storeAttributes={storeAttributes}
                  addressBook={addressBook.filter(o => o.id !== 0)}
                  onDataRowsUpdated={(rows: EDataRow[]) => {
                    let dataRows_ = store.getState().dispatching.dataRows;
                    rows.forEach(row => {
                      row.calculation = getCalculation(row, {
                        attribute: row.attribute as {customOptions: IDispatchingAttributesCustom},
                        // @ts-ignore
                        priceAttributes: priceAttributes,
                        // @ts-ignore
                        materialAttributes: materialAttributes,
                        storeAttributes: storeAttributes,
                        defaultMaterialTable: row.order ? (row.order.other as OrderOther).materialTable : undefined,
                      });
                      const index = dataRows.findIndex(o => o.id === row.id);
                      dataRows_[index] = row;
                    });
                    store.dispatch(setDataRows(dataRows_));
                  }}
                  dataRows={dataRows.filter(o => o.isSelected)}
                  onHide={() => setInvoiceModalActive(false)}
                  onStorno={onStorno}
                  onDone={(item: { logItem: { itemId: number; id: number; }; }[]) => {
                    onDone(item);
                    setInvoiceModalActive(false);
                  }}/>
}

const ResultProvider = ({ children }: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(reducer, {
    editedDataRow: null,
    editedDataRowIndex: -1,
  });

  return <ResultsContext.Provider value={{ state, dispatch }}>
    {children}
  </ResultsContext.Provider>
}

export function Results () {

  const history = useHistory();
  const dispatch = useDispatch();

  const authenticate = useSelector(
    (state: { authenticate: AuthenticateState}) => state.authenticate);

  const { contactUsers: users, isValidating: usersValidating } = getUsersWithSpecificRoleWhere(
    { params: { roleWhere: { visibleForCalendar: 1}, appendArchived: true }}, {
      revalidateOnFocus: false,
      revalidateOnMount: true,
    });

  const dispatchingRole = (authenticate.actualUserRole as Roles).dispatching as DispatchingRights;

  const lastFilter = useRef<IFilter | undefined>(undefined);

  const dataRows = useSelector((state: {dispatching: DataRowsState}) => state.dispatching.dataRows as EDataRow[]);

  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes(
    'price', swrOption && swrOption, {params: {where: {spaceId: [1,3]}}});
  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes(
    'material', swrOption && swrOption, {params: {where: {spaceId: [1,3]}}});
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store', swrOption && swrOption, {params: {where: {spaceId: [1,3]}}});
  const vehiclesDataLoading = useSelector((state: {dispatching: DataRowsState}) =>
    state.dispatching.vehiclesDataLoading);

  const vehiclesData = useSelector((state: {dispatching: DataRowsState}) =>
    state.dispatching.vehiclesData);
  const { orders, isValidating: ordersValidating, revalidate: ordersRevalidate }
    = getOrders({ params: { where: { isConfirmed: 0 }}}, swrOption && swrOption);

  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { allDetails: 0, onlyForDispatching: 1, appendArchived: true  }}, swrOption && swrOption);


  const dispatchResult = useContextSelector(ResultsContext, v => v.dispatch);

  const [loadingData, setLoadingData] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setDataRows([]));
      // @ts-ignore
      dispatch(setVehiclesData([]));
    }
  }, []);

  let oneUser: Users | null = null;
  if(lastFilter.current && users) {
    if(lastFilter.current.userId.length === 1)
    { // @ts-ignore
      oneUser = users.find(o => o.id === lastFilter.current.userId[0]);
    }
  }

  let oneOrder: Orders | undefined = undefined;
  if(lastFilter.current && orders) {
    if(lastFilter.current.orderId) {
      // @ts-ignore
      oneOrder = orders.find(o => o.id === lastFilter.current.orderId);
    }
  }

  const onDispatchingFilterChanged = (f?: IFilter, o?: IFilterOrder) => {
    lastFilter.current = f;
    oneUser = null;
    if(f && users) {
      if(f.userId.length === 1)
      { // @ts-ignore
        oneUser = users.find(o => o.id === f.userId[0]);
      }
    }

    oneOrder = undefined;
    if(lastFilter.current && orders) {
      if(lastFilter.current.orderId) {
        // @ts-ignore
        oneOrder = orders.find(o => o.id === lastFilter.current.orderId);
      }
    }



    if(f) {
      if(f.showSynchronizedDataFromOrder && !disabled)
        setDisabled(true);
      else if (!f.showSynchronizedDataFromOrder && disabled)
        setDisabled(false);
      if (f.from === null || f.to === null) {
        dispatch(show({
          title: 'Chyba',
          level: 'error',
          autoDismiss: 3,
          children: (
            <div>
              Vyberte prosím období
            </div>
          ),
        }, 'error'));
        return;
      }
      dispatch(setDataRows([]));
      if(f.loading !== "") {
        const s = storeAttributes.find(s => s.name.includes(f.loading));
        if (s) {
          f.materialTable = `"storeId":${s.id},`
        }
      }

      setLoadingData(true);
      request.get('/items/datarowsResults',{
        params: {
          where: oneUser ? { spaceId: [ 1, 3 ] }: undefined,
          ...{
            ...f,
            from: f? (f.from? f.from.format(dateFromat) : undefined) : undefined,
            to: f? (f.to? f.to.format(dateFromat) : undefined) : undefined,
          },
        }
      })
        .then(async (res) => {
          let body = res.data as EDataRow[];
          if (body.length === 0) {
            dispatch(show({ title: 'Upozornění', level: 'warning', autoDismiss: 1,
              children: (
                <div>
                  Pro zadaný filtr nebyly nalezeny žádné záznamy
                </div>
              ),
            }, 'warning'));
          }
          let attendance = null;
          if (oneUser) {
            attendance = await getUserAttendance();
            //@ts-ignore
            store.dispatch(setVehiclesData([]));
            //loadGpsData(body);
          }
          body.forEach((item) => {
            if (oneOrder && f.showSynchronizedDataFromOrder) {
              item = fillOrder(item, priceAttributes, oneOrder, {price: true}) as EDataRow;
            }
            item.isSelected = false;
            item.isAttendance = false;

            item.calculation = getCalculation(item, {
              // @ts-ignore
              attribute: item.attribute,
              // @ts-ignore
              priceAttributes: priceAttributes,
              // @ts-ignore
              materialAttributes: materialAttributes,
              storeAttributes: storeAttributes,
              // @ts-ignore
              defaultMaterialTable: item.order ? item.order.other.materialTable : undefined,
            });
            item.tools.forEach((tool) => {
              // @ts-ignore
              tool.tool.id = tool.tool.id.toString();
            });

          });
          if (attendance) {
            let attendanceRows: Partial<EDataRow>[] = [];
            const {to: endDate, from: startDate} = lastFilter.current as IFilter;
            (attendance as Attendance[]).forEach(att => {
              let from = moment(att.from);
              const to = moment(att.to);
              do {
                if (from.isBetween(startDate, endDate, 'days', '[]')
                  && from.isBusinessDay()) {
                  attendanceRows.push({
                    isAttendance: true,
                    date: from.format(dateFromat),
                    construction: att.attribute.name,
                    ...att,
                    originAttendance: att,
                    from: att.isPartDay ? getFloatTime(att.timeFrom) : 8,
                    to: att.isPartDay ? getFloatTime(att.timeTo) : 16,
                    break: 0,
                  })
                }
                from.add(1, 'days');
              } while (!from.isAfter(to))
            })

            body = body.concat(attendanceRows as EDataRow[]);
            body = body.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
          }
          setLoadingData(false);
          dispatch(setDataRows(body, !!oneUser));
        })
        .catch((err) => { setLoadingData(false)})
    }
  }

  if(!users || !vehicles)
    return null;

  const getUserAttendance = () => {
    return new Promise((resolve, reject) => {
      if (oneUser && lastFilter.current) {
        const { to, from } = lastFilter.current as IFilter;
        request.get('items/attendance', {
          params: { where: {
              $or: to !== null && from !== null ? {
                from: {
                  $between: [from.format(dateFromat), to.format(dateFromat)]
                },
                to: {
                  $between: [from.format(dateFromat), to.format(dateFromat)]
                },
                $and: {
                  from: {
                    $lte: to.format(dateFromat)
                  },
                  to: {
                    $gte: to.format(dateFromat)
                  },
                },
              } : undefined,
              userId: oneUser.id
            }}
        }).then((res) => {
          resolve(res.data);
        }).catch((err) => reject(err))
      } else {
        reject();
      }
    });
  }

  const loadGpsData = (dataRows: EDataRow[]) => {
    if(!lastFilter.current) {
      store.dispatch(show({
        title: 'Chyba',
        level: 'error',
        autoDismiss: 3,
        children: (
          <div>
            žádný filtr není aktivován
          </div>
        ),
      }, 'error'));
      return;
    }
    const { from, to } = lastFilter.current as IFilter;
    if (to === null || from === null || dataRows.length === 0) {
      if (!oneUser) {
        store.dispatch(show({
          title: 'Chyba',
          level: 'error',
          autoDismiss: 3,
          children: (
            <div>
              {to === null && <div>Vyberte prosím období</div>}
              {dataRows.length === 0  && <div>Neexistují data pro které lze načíst data</div>}
            </div>
          ),
        }, 'error'));
      }
      if(!oneUser || to === null || from == null)
        return;
    }

    let vehicleIds = dataRows.filter(o => !o.isAttendance && (o.vehicle &&
      ((o.vehicle as Vehicles).type === 'myvisionlink' || (o.vehicle as Vehicles).type === 'volvo')))
        .map(o => (o.vehicle as Vehicles).foreignApiVehicleId);
    let webVehicleId = dataRows.filter(o => !o.isAttendance &&
      (o.vehicle && (o.vehicle as Vehicles).type === 'webdispecink')).map(o =>
      (o.vehicle as Vehicles).foreignApiVehicleId);
    /*if (webVehicleId.length === 0 && vehicleIds.length === 0 && oneUser && lastFilter.current) {
      if(lastFilter.current.vehicleId.length === 0
        && dataRows.filter(o => !o.isAttendance).length === 0) {
        store.dispatch(show({
          title: 'Upozornění',
          level: 'warning',
          autoDismiss: 6,
          children: (
            <div>
              Pro načtení GPS dat prosím zaškrtněte vozidla na kterých se strojník mohl vyskytovat v daném měsíci
            </div>
          ),
        }, 'warning'));
        return;
      }
      vehicleIds = lastFilter.current.vehicleId.map(v => {
        const veh = vehicles.find(o => o.id === v);
        if(veh) {
          return (veh.type === 'myvisionlink' || veh.type === 'volvo') ? veh.foreignApiVehicleId : '';
        }
        return '';
      }).filter(o => o !== '');
      webVehicleId = lastFilter.current.vehicleId.map(v => {
        const veh = vehicles.find(o => o.id === v);
        if(veh) {
          return (veh.type === 'webdispecink') ? veh.foreignApiVehicleId : '';
        }
        return '';
      }).filter(o => o !== '');
    }*/
    dispatch(setVehiclesDataLoading(true));
    const dates = oneUser ? [new Date(from.format(dateFromat)), new Date(to.format(dateFromat))]
      : dataRows.map(o => new Date(o.date));

    // @ts-ignore
    const foreignDriver = oneUser ? oneUser.foreignApiDriverId.find(o => o.type === 'webDisApi') : undefined;
    request.get('/items/volvotrackingdata', {
      params: {
        where: {
          vehicleId: vehicleIds,
          timestamp: { $between: [moment(Math.min.apply(null,dates.map(d => d.getTime()))).format(dateFromat),
              moment(Math.max.apply(null,dates.map(d => d.getTime()))).add(1, 'days').format(dateFromat)] }
        },
        // @ts-ignore
        userId: foreignDriver ? foreignDriver.driver.id : undefined,
        webVehicleId,
      }
    }).then((res) => {
      store.dispatch(setVehiclesData(res.data));
      store.dispatch(setVehiclesDataLoading(false));
    }).catch((err) => {
      store.dispatch(setVehiclesDataLoading(false));
    })
  }

  const onInvoiceDone = (invoices: any[]) => {
    let dataRows_ = store.getState().dispatching.dataRows.slice();
    invoices.forEach((inv) => {
      const index = dataRows_.findIndex(o => o.id === inv.dataRowId);
      dataRows_[index].invoices.push(inv);
    });
    dispatch(setDataRows(dataRows_));
  }

  const onInvoiceStorno = (logItem: { description: { ids: any[]; }; id: number; }) => {
    let dataRows_ = store.getState().dispatching.dataRows.slice();
    logItem.description.ids.forEach(id => {
      const index = dataRows_.findIndex(o => o.id === id);
      dataRows_[index].invoices = dataRows_[index].invoices.filter(i => i.logId !== logItem.id);
    })
    dispatch(setDataRows(dataRows_));
  }

  const requestedNewRow = () => {
    let item: Partial<DataRow> = createDataRow({
      userId: oneUser ? oneUser.id : 0,
    });
    dispatch(setSingleEditRow(item));
    dispatchResult(setEditedRow(item));
  }

  let sum = 0, priceSum = 0, costsSum = 0, profitSum = 0;
  dataRows.forEach((row) => {
    if (!row.isAttendance && row.calculation) {
      priceSum += row.calculation.totalPrice;
      costsSum += row.calculation.totalCost;
      sum += getTimeDiff(row).sum;
    }
  });

  profitSum = priceSum - costsSum;

  let salaryEnabled = oneUser && !disabled && !loadingData;
  if (lastFilter.current)
    salaryEnabled = salaryEnabled && !!lastFilter.current.from && !!lastFilter.current.to

  return <div>
    <ContentWithFixedHeader baseMargin={60} header={
      <Row>
        <Col sm={2}>
          <div >
            <BackHeader
              fontSize='18px'
              headerText={'Výsledky'}
              onBackClick={() => history.replace('/')}/>
          </div>
        </Col>
        <Col>
          {disabled && 'Tento režim slouží zatím pouze pro náhled synchronizovaných cen'}
          {!disabled && <MultiRowChange
            priceAttributes={priceAttributes}
            onStorno={onInvoiceStorno}
            onDone={onInvoiceDone}
            onMultiDone={(rows: EDataRow[]) => {
              let dataRows_ = store.getState().dispatching.dataRows.slice();
              rows.forEach(row => {
                row.calculation = getCalculation(row, {
                  attribute: row.attribute as { customOptions: IDispatchingAttributesCustom},
                  // @ts-ignore
                  priceAttributes: priceAttributes,
                  // @ts-ignore
                  materialAttributes: materialAttributes,
                  storeAttributes: storeAttributes,
                  defaultMaterialTable: row.order ?
                    (row.order.other as OrderOther).materialTable : undefined,
                });
                const index = dataRows_.findIndex(o => o.id === row.id);
                dataRows_[index] = row;
              });
              dispatch(setDataRows(dataRows_))
            }}
            datarows={dataRows.filter(o => o.isSelected)}/>}
          {!disabled && <InvoiceContainer
              onDone={onInvoiceDone}
              onStorno={onInvoiceStorno}/>}
          {!disabled && <Print style={{ float: 'left', marginRight: '10px', marginTop: '14px' }}
                 sum={sum}
                 costsSum={costsSum}
                 priceSum={priceSum}
                 profitSum={profitSum} dataRows={dataRows} priceAttributes={priceAttributes}/>}
          {!disabled && <LoadingOrRender requestActive={vehiclesDataLoading}>
            <Button onClick={() => loadGpsData(dataRows)}
                    bsStyle='primary' style={{ float: 'left', marginRight: '10px', marginTop: '14px' }}>
              Načíst GPS data
            </Button>
          </LoadingOrRender>}
        </Col>
      </Row>
    }>
      <div className='fixHowirizonalScroll' style={{ marginLeft: '20px' }}>
        <Row style={{ marginTop: '20px' }}>
          <Col sm={12}>
            <div className='dataTable' style={{ width: '100%', fontSize: '11px' }}>
              {loadingData && <ReactLoading type={'spinningBubbles'}
                                            color={'#337ab7'} height={20} width={20}/>}
              <ResultRows dataRows={dataRows}
                          vehiclesData={vehiclesData}
                          priceAttributes={priceAttributes}
                          materialAttributes={materialAttributes}
                          storeAttributes={storeAttributes}
                          user={ oneUser }
                          requestedRowChange={(index: number, row: DataRow) => {
                            dispatch(setSingleEditRow(row));
                            dispatchResult(setEditedRow(row));
                            dispatchResult(setEditedRowIndex(index));
                          }}
                          requestedRowAdd={requestedNewRow}
                          rowsChanged={(dataRows_: EDataRow[]) => dispatch(setDataRows(dataRows_))}/>
                <ResultRowsTotals/>
                <CanView visible={salaryEnabled}>
                <Row style={{marginTop: '20px', textAlign: 'right'}}>
                  <Col sm={12}>
                    {(oneUser && lastFilter.current ) && <SalaryStateProvider>
                      <SalaryResult
                      // @ts-ignore
                      startDate={lastFilter.current && lastFilter.current.from}
                      salaryCreated={(id) => {
                        const list = dataRows.slice();
                        list.forEach(r => r.salaryId = id);
                        dispatch(setDataRows(dataRows))
                      }}
                      rowsAttendanceChanged={(dataRows) =>
                        dispatch(setDataRows(dataRows))}
                      // @ts-ignore
                      endDate={lastFilter.current && lastFilter.current.to}
                      // @ts-ignore
                      user={oneUser}
                      dataRows={dataRows} />
                    </SalaryStateProvider>
                      }
                  </Col>
                </Row>
              </CanView>
            </div>

          </Col>
        </Row>
      </div>

    </ContentWithFixedHeader>
    {!disabled && <EditSingleRowModal/>}
    <DispatchingFilter
      buttonMode
      defaultVisible
      appendArchived
      swrOption={swrOption}
      onFilterChanged={onDispatchingFilterChanged}/>
  </div>
}

export default ResultProvider;
