import {CanView} from "../../Main";
import {IDispatchingAttributesCustom} from "@cml/types";
import ItemsSelector from "../../ItemsSelector";
import {AddressBook, Attributes, Roles} from "@cml/models";
import AddressBookEdit from "../../addressBook/Edit";
import React from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getAddressBook} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrAddressBook({ index, attribute, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const { addressBook, isValidating: addressValidating } = getAddressBook(

  );

  const addressBookId = useSelector(selectDataRowValue(index, singleRowEditMode, 'addressBookId'));

  const handleAddressBookChange = (addressBook: AddressBook) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode,  {
      addressBookId: addressBook.id
    }));
  }

  if (!addressBook)
    return <div>Loading</div>

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook && dispatchOther.canUser}>
    <td>
      <ItemsSelector externList={addressBook}
                     bsSize="xsmall"
                     overrideToggleFunc={(address: AddressBook) => address.id === 0 ? <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                       Nevybrán
                     </div> : address.companyWithIc}
                     itemsToFetch='addressBook'
                     value={'companyWithIc'}
                     appendItemsToStart={singleRowEditMode ? [] : [
                       {id: 0, companyWithIc: 'Nevybrán'},
                     ]}
                     drawFirstWithDividers={!singleRowEditMode}
                     onListSplitterCondition={(index: number, item: AddressBook) => item.id !== 0}
                     newEditComponent={ AddressBookEdit }
                     filterEnable
                     onSelected={handleAddressBookChange}
                     defaultSelectedId={addressBookId}/>
    </td>
  </CanView>
}