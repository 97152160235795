import {IDiet, ISalary} from "../../users/Salary";
import {roundFloatTwoDigit} from "../../../AppObjects";
import React from "react";
import { IFinalTable } from "../SalaryResult";

interface Props {
  finalTable: IFinalTable,
  salary?: ISalary | null,
  diets: IDiet[],
  customItems: {
    name: string;
    price: number;
    fixed: boolean;
  }[],
}

const SalaryTotals = (props: Props) => {
  const {salary, finalTable, customItems, diets} = props;
  const fondMissing = finalTable.totalHoursStandard - (finalTable.fond as number);
  const fondIsDone =
    (finalTable.totalHoursExtra + finalTable.totalHoursStandard + fondMissing)
    > (finalTable.fond as number);
  const style = {
    backgroundColor: '#ffff00', fontSize: '14px',
  }
  return [<tr>
    <td colSpan={2} style={{ border: 0, paddingRight: 20, }}>Skutečné součty</td>
    <td style={style} >{finalTable.workdaysRowsLength}</td>
    <td style={style} >{finalTable.weekendRowsLength}</td>
    <td style={style} >{finalTable.holidaysRowsLength}</td>
    <td style={style} >
      {`${finalTable.totalHoursStandard.toFixed(1)} / ${finalTable.fond }`}</td>
    <td style={style} >{finalTable.totalHoursExtra.toFixed(1)}</td>
    <td style={style} >{(finalTable.totalHoursExtra + finalTable.totalHoursStandard)
      .toFixed(1)}</td>
    <td style={style} >{finalTable.totalHolidayHours}</td>
    <td colSpan={5} style={{ border: 0 }}></td>
    <td colSpan={3 + diets.length} style={style}>{`Celkem diety: ${finalTable.totalDiets}`}</td>
    <td style={style}>
      {roundFloatTwoDigit(finalTable.totalSalary + customItems.reduce((acc, item) => acc += item.price, 0))}</td>
  </tr>,
  <tr>
    <td colSpan={5} style={{ border: 0, paddingRight: 20, }}>Optimalizovaný součet</td>
    <td style={style}>{`${(finalTable.totalHoursStandard - 
    (fondMissing > 0 ? fondMissing : -finalTable.totalHoursExtra)).toFixed(1)} / ${finalTable.fond }`}</td>
    <td style={style}>{(finalTable.totalHoursExtra + fondMissing).toFixed(1)}</td>
  </tr>,
    <tr>
      <td colSpan={5} style={{ border: 0, paddingRight: 20, }}>Gps hodiny</td>
      <td style={style}>{`${
        finalTable.totalGpsHours ? finalTable.totalGpsHours.toFixed(1) : 0} / ${
        finalTable.fond }`}</td>
      <td style={style}>{(finalTable.totalGpsHours - (finalTable.fond as number)).toFixed(1)}</td>
    </tr>
  ]
}

export default SalaryTotals;