import React, { Component } from 'react';
import {Col, ControlLabel, FormControl, FormGroup, Row} from "react-bootstrap";
import ItemsSelector from "../ItemsSelector";
import {dateFormatVisible, dateFromat} from "../../AppObjects";
import { SingleDatePicker } from 'react-dates';
import moment from "moment";
import FontAwesome from "react-fontawesome";
import SubmitButton from "../../loginsrc/SubmitButton";
import { Response, ResponseError } from 'superagent';
import request from 'axios';
import AddressBookEdit from "../addressBook/Edit";
import store from '../../redux/store';
import { show } from 'react-notification-system-redux';

export interface ImportPhmInvoiceState {
    addressBookId: number;
    dueDate: moment.Moment;
    date: moment.Moment;
    dateAccounting: moment.Moment;
    datePickerFocused: {
        dueDate: boolean,
        date: boolean,
        dateAccounting: boolean,
    },
    invoiceNumber: string;
    varSym: string;
    file?: File,
    importActive: boolean,
    invoiceText: string;
}

export interface ImportPhmInvoiceProps {
    type: number;
}

const PhmImportTypes = {
    uta: 0,
    shell: 1,
    eurowag: 2,
};

const DateNames = {
    dueDate: "Datum splatnosti",
    date: "Datum zápisu",
    dateAccounting: "Datum odp./doruč",
};

const routeTranslation: {
    [key: number]: string;
} = {
    0: 'uta',
    1: 'shell',
    2: 'eurowag',
}

export default class ImportPhmInvoice extends Component<ImportPhmInvoiceProps, ImportPhmInvoiceState> {
    private inputRef: React.RefObject<HTMLInputElement>;
    constructor(props: ImportPhmInvoiceProps) {
        super(props);
        this.state = {
            addressBookId: 0,
            dueDate: moment(),
            date: moment(),
            dateAccounting: moment(),
            datePickerFocused: {
                dueDate: false,
                date: false,
                dateAccounting: false,
            },
            invoiceNumber: '',
            invoiceText: '',
            varSym: '',
            file: undefined,
            importActive: false,
        };
        this.inputRef = React.createRef();
    }

    private importUta(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target) {
            if (typeof (e.target as HTMLInputElement).files === 'undefined')
                return;
            if (e.target.files)
                if (e.target.files.length > 0) {
                    this.setState({
                        file: e.target.files[0],
                    });
                }
        }
    }

    private finishImport() {
        let formData = new FormData();
        if (this.state.file && (this.state.varSym !== '' || this.props.type === PhmImportTypes.eurowag)) {
            formData.append(this.state.file.name, this.state.file);
            formData.append("values", JSON.stringify({
                addressBookId: this.state.addressBookId,
                dueDate: this.state.dueDate.format(dateFromat),
                date: this.state.date.format(dateFromat),
                dateAccounting: this.state.dateAccounting.format(dateFromat),
                invoiceNumber: this.state.invoiceNumber,
                varSym: this.state.varSym,
                invoiceText: this.state.invoiceText,
            }));
            this.setState({ importActive: true });
            // @ts-ignore
            request.post(`/items/pohoda/import${routeTranslation[this.props.type]}invoice`, formData)
                .then((res) => {
                    this.setState({importActive: false, file: undefined});
                    if (this.inputRef.current)
                        this.inputRef.current.value = '';
                    store.dispatch(show({
                        title: 'Info',
                        level: 'info',
                        autoDismiss: 15,
                        children: (
                            <div>
                                Import byl v pořádku proveden
                            </div>
                        ),
                    }, 'info'));
                })
                .catch((err: ResponseError) => {
                    this.setState({importActive: false});
                })
        } else {
            store.dispatch(show({
                title: 'Chyba',
                level: 'error',
                autoDismiss: 5,
                children: (
                    <div>
                        Soubor pro import není vybrán nebo není vyplněn variabilní symbol
                    </div>
                ),
            }, 'error'));
        }

    }

    render() {
        return <>
            <Col sm={2}>
                <FormGroup>
                    <ControlLabel>Dodavatel</ControlLabel>
                    <div>
                        <ItemsSelector
                            newEditComponent={ AddressBookEdit }
                            filterEnable
                            itemsToFetch={'addressBook'}
                            onListSplitterCondition={(index: number,item: { id: number }) => item.id !== 0}
                            value={'companyWithIc'}
                            onSelected={(address: { id: number }) => this.setState({ addressBookId: address.id })}
                            otherPreviewProps={{style: {display: 'inline'}}}/>
                    </div>
                </FormGroup>
                {
                    Object.keys(this.state.datePickerFocused).map(key => <FormGroup key={key}>

                        <ControlLabel>{
                            // @ts-ignore
                            DateNames[key]}</ControlLabel>
                        <div>
                            <SingleDatePicker
                                small
                                // @ts-ignore
                                date={this.state[key]} // momentPropTypes.momentObj or null
                                displayFormat={ dateFormatVisible }
                                isOutsideRange = {() => false}
                                onDateChange={date => {
                                    if (date) {
                                        // @ts-ignore
                                        this.setState({ [key]: date });
                                    }
                                }} // PropTypes.func.isRequired
                                // @ts-ignore
                                focused={this.state.datePickerFocused[key]} // PropTypes.bool
                                onFocusChange={({ focused }) => {
                                    const f = this.state.datePickerFocused;
                                    if (focused !== null)
                                    {
                                        // @ts-ignore
                                        f[key] = focused;
                                    }
                                    this.setState({ datePickerFocused: f })
                                }} // PropTypes.func.isRequired
                                id={key} // PropTypes.string.isRequired,
                            />
                        </div>
                    </FormGroup>)
                }
            </Col>
            <Col sm={2}>
                {
                    this.props.type === PhmImportTypes.uta ? <FormGroup>
                        <ControlLabel>Číslo přijaté faktury</ControlLabel>
                        <div>
                            <FormControl
                                placeholder={'Číslo faktury'}
                                id="invoiceNumber"
                                value={this.state.invoiceNumber}
                                onChange={(e) => {
                                    this.setState({ invoiceNumber: (e.target as HTMLTextAreaElement).value })
                                }}
                                type='text'/>
                        </div>
                    </FormGroup> : null
                }

                { this.props.type !== PhmImportTypes.eurowag && <FormGroup>
                    <ControlLabel>Variabilní symbol</ControlLabel>
                    <div>
                        <FormControl
                            placeholder={'Variabilní symbol'}
                            id="varSym"
                            value={this.state.varSym}
                            onChange={(e) => {
                                this.setState({ varSym: (e.target as HTMLTextAreaElement).value })
                            }}
                            type='text'/>
                    </div>
                </FormGroup> }
                <FormGroup>
                    <ControlLabel>Soubor pro import</ControlLabel>
                    <div>
                        {this.state.file ? this.state.file.name : ''}
                        <input onChange={(e) => this.importUta(e)}
                               ref={this.inputRef}
                               id={`new${this.props.type}File`} type='file'
                               accept={this.props.type === PhmImportTypes.eurowag ? '.xml' : '.csv,.xlsx'} className='inputfile'/>
                        <label htmlFor={`new${this.props.type}File`}>
                            <FontAwesome style={{ marginRight: '10px', fontSize: '16px', color: '#055ADA' }} name='fas fa-plus-circle' />
                            {this.props.type !== PhmImportTypes.eurowag ?
                              `Import ${this.props.type === PhmImportTypes.uta ? 'UTA' : 'SHELL'} csv` :
                              'Import eurowag xml'
                            }
                        </label>
                    </div>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Text faktury</ControlLabel>
                    <div>
                        <textarea style={{ marginBottom: '20px', width: '100%', minHeight: '80px', padding:'10px'}}
                                  onChange={(e) => {
                                      this.setState({
                                          invoiceText: e.target.value,
                                      });
                                  }}
                                  value={this.state.invoiceText} />
                    </div>
                </FormGroup>
                <SubmitButton onClick={() => this.finishImport()} bsClass='btn btn-primary btn-block' loading={this.state.importActive}>
                    Importovat
                </SubmitButton>

            </Col>
        </>
    }
}

export {
  PhmImportTypes,
  routeTranslation,
}
