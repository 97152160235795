import {CanView} from "../../Main";
import {IDispatchingAttributesCustom} from "@cml/types";
import Autocomplete from "react-autocomplete";
import React, {ChangeEvent} from "react";
import {Attributes, Roles} from "@cml/models";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getCompanies, getConstructions} from "../../../fetchApi/dispatching";
import store from "../../../redux/store";
import {swrOption} from "../DataRow";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrConstruction({ attribute, index, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const { constructions } = getConstructions( singleRowEditMode?
    swrOption : undefined);

  const construction = useSelector(selectDataRowValue(index, singleRowEditMode, 'construction'));

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      construction: e.target.value,
    }))
  }

  const onSelect = (val: string) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      construction: val,
    }))
  }

  if(!constructions)
    return <div>Loading</div>

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom)
    .canConstruction && dispatchOther.canConstruction}>
    <td >
      {
        <div className='autocomplete'>
          <Autocomplete
            getItemValue={(item) => item.construction}
            items={constructions.filter((o: { construction: string; }) => o.construction.toLowerCase()
              .indexOf(construction.toLowerCase()) > -1)}
            renderItem={(item, isHighlighted) =>
              <div style={{ cursor: 'pointer', padding: '3px', background: isHighlighted ? 'lightgray' : 'white' }}>
                {item.construction}
              </div>
            }
            value={construction}
            onChange={onChange}
            onSelect={onSelect}
          />
        </div>
      }
    </td>
  </CanView>
}