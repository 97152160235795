import React from 'react';
import {useSelector} from "react-redux";
import {DataRowsState} from "@cml/redux-store";
import {BaseCalculationItem, OrderOther} from "@cml/types";
import { getTimeDiff } from './datarowBase';
import {getAttributes} from "../../../fetchApi/dispatching";
import {getCalculation} from "./PriceCalculation";
import { roundFloatTwoDigit, markersLegendTable } from '../../../AppObjects';
import { Row, Col } from 'react-bootstrap';
import { EDataRow } from '@cml/models';

const swrOption = {
  revalidateOnFocus: false,
  revalidateOnMount: false,
}

export default function ResultRowsTotals() {
  const dataRows = useSelector((state: {dispatching: DataRowsState}) =>
    state.dispatching.dataRows as EDataRow[]);

  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes(
    'price', swrOption && swrOption, {params: {where: {spaceId: [1,3]}}});

  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes(
    'material', swrOption && swrOption, {params: {where: {spaceId: [1,3]}}});

  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store', swrOption && swrOption, {params: {where: {spaceId: [1,3]}}});


  let sum = 0, priceSum = 0, costsSum = 0, profitSum = 0;
  dataRows.forEach((row) => {
    if (!row.isAttendance) {

      const {totalPrice} = getCalculation(row, {
        // @ts-ignore
        attribute: row.attribute,
        // @ts-ignore
        priceAttributes,
        // @ts-ignore
        materialAttributes: materialAttributes,
        storeAttributes: storeAttributes,
        defaultMaterialTable: row.order ? (row.order.other as OrderOther).materialTable : undefined,
      })
      priceSum += totalPrice;
      if (row.priceEq) {
        (row.priceEq as BaseCalculationItem[]).forEach((item) => {
          if (item.costTotal)
            costsSum += item.costTotal;
        });
      }
      sum += getTimeDiff(row).sum;
    }
  });

  profitSum = Math.ceil(priceSum - costsSum);

  const invoicedLength = dataRows.filter(o => {
    if (!o.invoices || o.isAttendance)
      return false;
    return o.invoices.length > 0;
  }).length;
  const salaryDoneLength = dataRows.filter(o => o.salaryId && !o.isAttendance).length;

  const rowsLength = dataRows.filter(o => !o.isAttendance).length;

  return <Row>
    <Col sm={6}>
      <div style={{ display: 'inline-block' }} className='dataTable'>
        {markersLegendTable()}
      </div>
      <div title='Procento vyfakturovaných řádků z daného filtru' style={{ display: 'inline-block', fontSize: '24px', color: 'purple', marginLeft: '10px' }}>
        {`${Math.round((invoicedLength * 100 /rowsLength + Number.EPSILON) * 10) / 10 } %`}
      </div>
      <div title='Procento vytvořených mezd z daného filtru' style={{ display: 'inline-block', fontSize: '24px', color: 'darkgreen', marginLeft: '10px' }}>
        {`${Math.round((salaryDoneLength * 100 /rowsLength + Number.EPSILON) * 10) / 10 } %`}
      </div>
      <div style={{ fontSize: '11px' }}>
        {`Celkem řádků: ${rowsLength} z toho ${invoicedLength} vyfaturováno ${rowsLength - invoicedLength} nikoliv.`}
      </div>
      <div style={{ fontSize: '11px' }}>
        {`Celkem řádků: ${rowsLength} z toho ${salaryDoneLength} vytvořena mzda ${rowsLength - salaryDoneLength} nikoliv.`}
      </div>

    </Col>
     <Col>
       {/* @ts-ignore*/ }
       <table align='right'>
         <tbody >
         <tr>
           <th>
             Hodiny
           </th>
           <th>
             Cena celkem
           </th>
           <th>
             Náklady celkem
           </th>
           <th>
             Zisk
           </th>
         </tr>
         <tr>

           <td className='transparent' style={{fontSize: '16px', fontWeight: 'bold'}}>
             {dataRows.length === 0 ? '' : roundFloatTwoDigit(sum)}
           </td>
           <td className='transparent' style={{fontSize: '16px', fontWeight: 'bold'}}>
             {dataRows.length === 0 ? '' :  roundFloatTwoDigit(Math.ceil(priceSum))}
           </td>
           <td className='transparent' style={{color: 'red', fontSize: '16px', fontWeight: 'bold'}}>
             {dataRows.length === 0 ? '' :  roundFloatTwoDigit(Math.ceil(costsSum * -1))}
           </td>
           <td className='transparent' style={{color: profitSum < 0 ? 'red' : 'darkgreen', fontSize: '16px', fontWeight: 'bold'}}>
             {dataRows.length === 0 ? '' : `${profitSum > 0 ? '+' : ''} ${roundFloatTwoDigit(profitSum)}`}
           </td>

         </tr>
         </tbody>
       </table>
     </Col>

  </Row>

  return <div style={{marginLeft: 35, marginTop: 15, fontSize: 14}}>
    {/*<span style={{ width: 14, display: 'inline-block' }}></span>
    <span style={{ width: 20, display: 'inline-block' }}></span>
    <span style={{ width: 40, display: 'inline-block' }}></span>
    <span style={{ width: 70, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>
    <span style={{ width: 60, display: 'inline-block' }}></span>
    <span style={{ width: 40, display: 'inline-block' }}></span>
    <span style={{ width: 40, display: 'inline-block' }}></span>
    <span style={{ width: 70, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>
    <span style={{ width: 150, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>
    <span style={{ width: 50, display: 'inline-block' }}></span>
    <span style={{ width: 50, display: 'inline-block' }}></span>
    <span style={{ width: 50, display: 'inline-block' }}></span>
    <span style={{ width: 50, display: 'inline-block' }}>{sum}</span>
    <span style={{ width: 80, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>
    <span style={{ width: 100, display: 'inline-block' }}></span>*/}
    <span style={{ marginLeft: 1307, width: 50, display: 'inline-block' }}>{sum}</span>
    <span style={{ marginLeft: 250, width: 50, display: 'inline-block' }}>{priceSum}</span>
    <span style={{ width: 50, display: 'inline-block' }}>{costsSum}</span>
    <span style={{ width: 50, display: 'inline-block' }}>{profitSum}</span>

  </div>
}