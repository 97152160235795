import * as React from "react";
import FontAwesome from 'react-fontawesome';


interface BaseTagProps {
    onCloseClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    color?: string;
    style?: object;
    disableClose?: boolean;
}

export interface TagProps extends BaseTagProps{
    item: object | string;
    preview?: React.ComponentClass<{item: object | string}>;
    otherPreviewProps?: object
}

export class Tag extends React.Component<TagProps> {
    static defaultProps = {
        color: '#fc00d7',
        disableClose: false
    };

    render() {
      const { item, preview, onCloseClick, color, otherPreviewProps, disableClose, style } = this.props;
      return <span style={style} className={style ? '' : 'filterContainer'}>
          {
              preview && item ?
              React.createElement(preview, {
                  item: item,
                  ...otherPreviewProps,
              }) : item
          }
          {
              disableClose ? null : <button
                  onClick={(e) => onCloseClick(e)}
                  className='transparentButton'>
                  <FontAwesome name='fas fa-times' style={{marginLeft: '5px', color: color }}/>
              </button>
          }
    </span>
  }
}

export interface EditableTagProps extends BaseTagProps{
    onValueChange: (value: string) => void;
    defaultValue: string;
    singleRow: boolean;
}

export interface EditableTagState {
    value: string;
}

export class EditableTag extends React.Component<EditableTagProps, EditableTagState> {
    static defaultProps = {
        color: '#fc00d7',
        defaultValue: '',
        singleRow: false,
    };

    constructor(props: EditableTagProps) {
        super(props);
        this.state = {
            value: '',
        }
    }

    componentWillMount(): void {
        this.setState({ value: this.props.defaultValue});
    }

    componentWillUpdate(nextProps: Readonly<EditableTagProps>, nextState: Readonly<EditableTagState>, nextContext: any): void {
        if(nextProps.defaultValue !== this.props.defaultValue) {
            this.setState({ value: nextProps.defaultValue });
        }
    }

    onChange(e: any): void {
        this.setState(({value: e.target.value}));
        this.props.onValueChange(e.target.value);
    }

    render() {
        const { onCloseClick, color, style, singleRow } = this.props;
        const { value } = this.state;
        return <div className='filterContainer' style={style}>
            <button
                style={{ float: 'right', paddingTop: '0px' }}
                onClick={(e) => onCloseClick(e)}
                className='transparentButton'>
                <FontAwesome name='fas fa-times' style={{marginLeft: '5px', color: color }}/>
            </button>
            { singleRow ?
                <input className='edditableTagTextArea' type='text' value={value} onChange={e => this.onChange(e)}/> :
                <textarea className='edditableTagTextArea' value={value} onChange={e => this.onChange(e)}/> }
        </div>
    }
}