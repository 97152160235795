import {CanView} from "../../Main";
import {IDispatchingAttributesCustom, IGeoLocation} from "@cml/types";
import MapAutoComplete from "../../MapAutoComplete";
import React from "react";
import {getStore} from "../components/datarowBase";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {Attributes, Roles} from "@cml/models";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getAttributes} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrLoading({ index, attribute, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store', );

  const loadingGeo = useSelector(selectDataRowValue(index, singleRowEditMode, 'loadingGeo')) as IGeoLocation;

  const loading = useSelector(selectDataRowValue(index, singleRowEditMode, 'loading'));

  const materialTable = useSelector(selectDataRowValue(index, singleRowEditMode, 'materialTable'));

  if(!storeAttributes)
    return <div>loading</div>

  const oneStore = getStore({
    materialTable,
  }, storeAttributes);

  const oneStoreName = oneStore ? oneStore.name : null;

  const placeSelect = (address: string) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      loadingGeo: {
        location: address,
        latitude: loadingGeo ? loadingGeo.latitude : 0,
        longitude: loadingGeo ? loadingGeo.longitude : 0,
      },
      loading: address
    }))
  }

  const onMapCooSelected = (loadingGeo: IGeoLocation) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      loadingGeo,
      loading: loadingGeo.location
    }))
  }

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canLoading && dispatchOther.canLoadingUnloading}>
    <td>
      {oneStore ? <div>{oneStoreName}</div>
        : <MapAutoComplete inputId={`loading-map-auto ${index}`}
                           type='mapycz'
                           geolocation={loadingGeo}
                           placeholder='Napište adresu'
                           withInternalSearchType
                           onMapCooSelected={onMapCooSelected}
                           onPlaceSelect={placeSelect}
                           defaultValue={/*loadingGeo ? loadingGeo.location : */loading}/>}
    </td>
  </CanView>
}