import {DataRow} from "@cml/models";
import {DataRowsState} from "@cml/redux-store";


export const selectDataRowValue = (index: number, single: boolean, key: keyof DataRow) => (state: { dispatching: DataRowsState }) => {
  if (single) {
    if (state.dispatching.singleEditRow) {
      return state.dispatching.singleEditRow[key];
    }
  }
  return state.dispatching.dataRows[index][key];
}