import React, { Component } from 'react';
import {Checkbox, Col, DropdownButton, FormControl, Row} from "react-bootstrap";
import {SmallUserPreview} from "../users/Preview";
import { BaseFilterPropTypes } from '../CommonListContainer';
import AttributePreview from '../attributes/Preview';
import {DateRangePicker} from "react-dates";
import {dateFormatVisible, dateFromat, getFilterList} from "../../AppObjects";
import PropTypes from 'prop-types';
import ItemsSelector from "../ItemsSelector";
import FontAwesome from "react-fontawesome";
import moment from "moment";

export default class SmsFiltr extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      attributes: [],
      selectedAttributes: [],
      selectedUsers: [],
      startDate: null,
      endDate: null,
      focusedInput: null,
      userFilterValue: '',
      textFilterValue: '',
    }
  }


  handleDateChange(startDate, endDate) {
    if (endDate)
      endDate = endDate.isSame(startDate) ? moment(endDate).add(1, 'day') : endDate;
    const { selectedUsers, textFilterValue } = this.state;
    this.setState({ startDate, endDate });
    this.doFilter(startDate, endDate, selectedUsers, textFilterValue);
  }

  doFilter(startDate, endDate, users, text) {
    const from = users.map(o => o.telnumb ? o.telnumb.substring(1) : null).filter(o => o);
    const to = users.map(o => o.telnumb ? o.telnumb.substring(1) : null).filter(o => o);
    this.props.onFilter({
      where: {
        createdAt: endDate !== null  ? {
          $between: [startDate.format(dateFromat), endDate.format(dateFromat)]
        } : undefined,

        $or: to.length === 0 ? undefined: {
          to,
          from,
        },
        text: {
          $like: '%' + text + '%'
        }
      }
    })
  }

  render() {
    const { startDate, endDate, selectedUsers, textFilterValue } = this.state;
    return (
      <div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll filterStyle'>
        <Row>
          <Col sm={3}>
            <DateRangePicker
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
              startDatePlaceholderText='Začátek'
              endDatePlaceholderText='Konec'
              displayFormat={ dateFormatVisible }
              small
              isOutsideRange = {() => false}
              minimumNights={0}
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => this.handleDateChange( startDate, endDate )} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            />
          </Col>
          <Col sm={1}>
            <ItemsSelector
              value={'nameSurname'}
              defaultQuery={{attributes: ['name', 'id', 'surname', 'nameSurname', 'telnumb'] }}
              preview={SmallUserPreview}
              fullSelectedList
              listChanged={(list, isFromInit) => {
                this.setState({
                  selectedUsers: list,
                });
                if (!isFromInit)
                  this.doFilter(startDate, endDate, list, textFilterValue);
              }}
              overrideToggle={'Filtr uživatelů'}
              appendItemsToStart={[
                { nameSurname: 'Všichni', name: 'Všichni', surname: '', id: 0 },
              ]}
              filterEnable
              isFilterable
              otherPreviewProps={{
                style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
              }}
              itemsToFetch={'users'}/>
          </Col>
          <Col sm={1}>
            <div className='text-right'>
              <FontAwesome style={{fontSize:'18px', marginTop: '8px'}} name='fas fa-search' />
            </div>
          </Col>
          <Col sm={3}>
            <FormControl
              value={textFilterValue}
              onChange={(e) => {
                this.setState({textFilterValue: e.target.value});
                this.doFilter(startDate, endDate, selectedUsers, e.target.value);
              }}
              type='text'/>
          </Col>
        </Row>
      </div>
    )
  }
}

SmsFiltr.propTypes = {
  items: PropTypes.array.isRequired,
  ...BaseFilterPropTypes,
};

