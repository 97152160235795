import React, { Component } from 'react';
import {Col, ControlLabel, FormControl, FormGroup, Row} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import ItemsSelector from "../ItemsSelector";
import {SmallUserPreview} from "../users/Preview";

export default class OrdersFiltr extends Component{

  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      customNumber: '',
      onlyCustomActive: false,
      selectedManagers: [],
      selectedContacts: [],
    }
  }

  render() {
    return (<div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll filterStyle'>
      <Row>
        <Col sm={1}>
          <div className='text-right'>
            VČŘ.
          </div>
        </Col>
        <Col sm={3}>
          <FormGroup validationState={this.state.onlyCustomActive ? 'success' : undefined}>
            <FormControl
              value={this.state.customNumber}
              onChange={(e) => {
                this.setState({
                  onlyCustomActive: true,
                  customNumber: e.target.value});
                this.doFilter(
                  e.target.value,
                  this.state.selectedManagers,
                  this.state.selectedContacts, true);
              }}
              type='text'/>
          </FormGroup>

        </Col>
        <Col sm={1}>
          <div className='text-right'>
            <FontAwesome style={{fontSize:'18px', marginTop: '8px'}} name='fas fa-search' />
          </div>
        </Col>
        <Col sm={3}>
          <FormGroup>
            <FormControl
              validationState={this.state.onlyCustomActive ? 'success' : undefined}
              value={this.state.filterValue}
              onChange={(e) => {
                this.setState({filterValue: e.target.value, onlyCustomActive: false});
                this.doFilter(e.target.value,
                  this.state.selectedManagers,
                  this.state.selectedContacts,);
              }}
              type='text'/>
          </FormGroup>

        </Col>
        <FormGroup style={{float: 'left', marginRight: '10px', marginBottom: '5px'}}>
          <ControlLabel>
            Manažer
          </ControlLabel>
          <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            <ItemsSelector
              value={'nameSurname'}
              defaultQuery={{
                attributes: ['name', 'id', 'surname', 'nameSurname'] ,
                roleWhere: { isProjectManager: 1},
              }}
              preview={SmallUserPreview}
              listChanged={(list, isFromInit) => {
                this.setState({
                  selectedManagers: list,
                });
                if (!isFromInit)
                  this.doFilter(
                    this.state.onlyCustomActive ? this.state.customNumber :
                      this.state.filterValue,
                    list, this.state.selectedContacts, this.state.onlyCustomActive);
              }}
              appendItemsToStart={[
                { nameSurname: 'Všichni', name: 'Všichni', surname: '', id: 0 },
              ]}
              filterEnable
              isFilterable
              otherPreviewProps={{
                style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
              }}
              itemsToFetch={'usersWithSpecificRoleWhere'}/>
          </div>

        </FormGroup>
        <FormGroup style={{float: 'left', marginRight: '10px', marginBottom: '5px'}}>
          <ControlLabel>
            Kontakt
          </ControlLabel>
          <div style={{ display: 'inline-block', marginLeft: '10px' }}>
            <ItemsSelector
              value={'nameSurname'}
              defaultQuery={{
                attributes: ['name', 'id', 'surname', 'nameSurname'] ,
                roleWhere: { isContactUser: 1},
              }}
              preview={SmallUserPreview}
              listChanged={(list, isFromInit) => {
                this.setState({
                  selectedContacts: list,
                });
                if (!isFromInit)
                  this.doFilter(this.state.onlyCustomActive ? this.state.customNumber :
                    this.state.filterValue, this.state.selectedManagers, list, this.state.onlyCustomActive);
              }}
              //overrideToggle={'Filtr kontaktů'}
              appendItemsToStart={[
                { nameSurname: 'Všichni', name: 'Všichni', surname: '', id: 0 },
              ]}
              filterEnable
              isFilterable
              otherPreviewProps={{
                style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
              }}
              itemsToFetch={'usersWithSpecificRoleWhere'}/>
          </div>

        </FormGroup>
      </Row>
    </div>)
  }

  doFilter(value, selLeaders, selContacts, onlyCustom) {
    this.props.onFilter({
      where: {
        $and: [
          {$or: onlyCustom ?  [
              { customNumber: { $like: `%${value}%` }},
            ] : [
              { foreignBookKeepingNumber: { $like: `%${value}%` }},
              { receivedOrderNumber: { $like: `%${value}%` }},
              { loading: { $like: `%${value}%` }},
              { unloading: { $like: `%${value}%` }},
              { company: { $like: `%${value}%` }},
              { construction: { $like: `%${value}%` }},
              { customNumber: { $like: `%${value}%` }},
            ]},
          { projectLeaderId: selLeaders.length === 0 ? undefined : selLeaders },
          { contactUserId: selContacts.length === 0 ? undefined : selContacts },
        ],

      }
    })
  }
}