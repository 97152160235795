import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from "react-fontawesome";
import {ButtonToolbar, Dropdown, MenuItem, Modal} from "react-bootstrap";
import googlefav from '../assets/googlefav.png';
import mapyczfav from '../assets/mapyczfav.png';

const searchTypes = {
  mapycz: mapyczfav,
  google: googlefav,
};


class Map extends Component {
  componentDidMount() {
    //this is declaration for ESlint do not remove
    /*global google*/
    /*global SMap*/

    const { latitude, longitude } = this.props.location;
    const { onMapCooSelected } = this.props;
    const map = document.getElementById(this.props.inputId + '_map');
    const cooExists = latitude === 0 && longitude === 0;
    const center = cooExists ?
      SMap.Coords.fromWGS84(14.41790, 50.12655) : SMap.Coords.fromWGS84(longitude, latitude);
    const mapa = new SMap(map, center, 10);
    mapa.addDefaultLayer(SMap.DEF_BASE).enable();
    mapa.addDefaultControls();

    const layer = new SMap.Layer.Marker();
    mapa.addLayer(layer);
    layer.enable();

    const options = {};
    const marker = new SMap.Marker(center, "point", options);
    marker.decorate(SMap.Marker.Feature.Draggable);
    layer.addMarker(marker);

    function start(e) { /* Začátek tažení */
      var node = e.target.getContainer();
      node[SMap.LAYER_MARKER].style.cursor = "help";
    }

    function stop(e) {
      var node = e.target.getContainer();
      node[SMap.LAYER_MARKER].style.cursor = "";
      var coords = e.target.getCoords();
      alert("Cílová pozice: " + coords.toWGS84(2).reverse().join(" "));
    }

    function click(e, elm) {
      const coords = SMap.Coords.fromEvent(e.data.event, mapa);
      new SMap.Geocoder.Reverse(coords, (geocoder) => {
        marker.setCoords(coords);
        if (onMapCooSelected) {
          onMapCooSelected({
            location: geocoder.getResults().label,
            latitude: coords.y,
            longitude: coords.x,
          })
        }
      });
    }

    const nalezeno = function(route) {
      const vrstva = new SMap.Layer.Geometry();
      mapa.addLayer(vrstva).enable();

      const coords = route.getResults().geometry;
      const cz = mapa.computeCenterZoom(coords);
      mapa.setCenterZoom(cz[0], cz[1]);
      const g = new SMap.Geometry(SMap.GEOMETRY_POLYLINE, null, coords);
      vrstva.addGeometry(g);
    }

    const coords = [
      SMap.Coords.fromWGS84(14.434, 50.084),
      SMap.Coords.fromWGS84(16.600, 49.195)
    ];
    //const route = new SMap.Route(coords, nalezeno);



    const signals = mapa.getSignals();
    signals.addListener(window, "marker-drag-stop", stop);
    signals.addListener(window, "marker-drag-start", start);

    signals.addListener(window, "map-click", click);
  }

  render() {
    return <div id={this.props.inputId + '_map'} style={{ width: '100%', height: 600 }}/>
  }
}

Map.propTypes = {
  inputId: PropTypes.string.isRequired,
  location: PropTypes.object,
  onMapCooSelected: PropTypes.func,
};

class MapAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      internalType: props.type,
      mapModalShow: false,
    };
    this.autocomplete = null;
    this.onPlaceSelect = this.onPlaceSelect.bind(this);

  }

  componentDidMount() {
    //this is declaration for ESlint do not remove
    /*global google*/
    /*global SMap*/

    const input = document.getElementById(this.props.inputId);
    if (this.props.type === 'google') {
      this.autocomplete = new google.maps.places.Autocomplete(input);
      this.autocomplete.addListener('place_changed', this.onPlaceSelect);
    } else if (this.props.type === 'mapycz') {
      this.autocomplete = new SMap.Suggest(input);
      this.autocomplete.addListener("suggest", (suggestData) => {
        this.setState({value: suggestData.phrase});
        if (typeof this.props.onMapCooSelected)
          this.props.onMapCooSelected({
            location: suggestData.phrase,
            longitude: suggestData.data.longitude,
            latitude: suggestData.data.latitude,
          });
        /*if (typeof this.props.onPlaceSelect !== 'undefined')
          this.props.onPlaceSelect(suggestData.phrase, suggestData);*/
      })
    }
    document.getElementById(this.props.inputId).value = this.props.defaultValue;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if(nextProps.defaultValue !== this.props.defaultValue && this.state.value === nextState.value) {
      document.getElementById(this.props.inputId).value = nextProps.defaultValue;
    }

    return true;
  }

  onPlaceSelect() {
    const place = this.autocomplete.getPlace();
    let address = document.getElementById(this.props.inputId).value;
    this.setState({value: address});
    /*if (typeof this.props.onPlaceSelect !== 'undefined')
      this.props.onPlaceSelect(address, place);*/
    if (typeof this.props.onMapCooSelected)
      this.props.onMapCooSelected({
        location: address,
        longitude: place.geometry.location.lng(),
        latitude: place.geometry.location.lat(),
      });
  }

  handleSwitchType(key) {
    /*global google*/
    /*global SMap*/
    const input = document.getElementById(this.props.inputId);
    switch(this.state.internalType) {
      case 'google':
        google.maps.event.clearInstanceListeners(input);
        break;
      case 'mapycz':
        this.autocomplete.destroy();
        break;
    }

    switch (key) {
      case 'mapycz':
        this.autocomplete = new SMap.Suggest(input);
        this.autocomplete.addListener("suggest", (suggestData) => {
          this.setState({value: suggestData.phrase});
          if (typeof this.props.onPlaceSelect !== 'undefined')
            this.props.onPlaceSelect(suggestData.phrase, suggestData);
        });
        break;
      case 'google':
        this.autocomplete = new google.maps.places.Autocomplete(input);
        this.autocomplete.addListener('place_changed', this.onPlaceSelect);
        break;
    }

    this.setState({
      internalType: key,
    });

  }

  render() {
    if (!this.props.inputId)
      return <div>Error: "inputId" must be set</div>;
    return <div style={{display: 'table'}}>
      {
        this.props.withInternalSearchType ?
        <Dropdown style={{display: 'table-cell'}}
                  id={this.props.inputId + '_mapSelector'} pullRight onSelect={(eventKey) =>
          this.handleSwitchType(eventKey)}>
          <div bsRole="toggle" style={{marginRight: '7px', verticalAlign:'middle', cursor: 'pointer'}}>
            <img src={searchTypes[this.state.internalType]}/>
          </div>
          <Dropdown.Menu pullRight>
            <MenuItem eventKey="google">Google</MenuItem>
            <MenuItem eventKey="mapycz">Mapycz</MenuItem>
          </Dropdown.Menu>
        </Dropdown> : null
      }
      <div style={{display: 'table-cell'}}>
        <button
          className='transparentButton'
          onClick={() => this.setState({ mapModalShow: true })}>
          <FontAwesome name='map-marker-alt'/>
        </button>
        <Modal
          dialogClassName="modal-90w"
          show={this.state.mapModalShow} onHide={() => this.setState({ mapModalShow: false })}>
          <Modal.Header closeButton>
            Mapa
          </Modal.Header>
          <Modal.Body>
            <Map onMapCooSelected={this.props.onMapCooSelected}
                 location={this.props.geolocation} inputId={this.props.inputId}/>
          </Modal.Body>
        </Modal>

      </div>

      <div className='autocomplete' style={{minWidth: '180px'}}>
        <input  id={this.props.inputId} type="text"
               onChange={(e) => {
                 if (typeof this.props.defaultValue !== 'undefined') {
                   if (typeof this.props.onPlaceSelect !== 'undefined') {
                     this.props.onPlaceSelect(e.target.value, null);
                   }
                 } else {
                   this.setState({value: e.target.value})
                 }
               }}
               placeholder={this.props.placeholder}/>
      </div>
    </div>
  }
}

MapAutoComplete.propTypes = {
  inputId: PropTypes.string.isRequired,
  onMapCooSelected: PropTypes.func,
  onPlaceSelect: PropTypes.func,
  defaultValue: PropTypes.string,
  geolocation: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  withInternalSearchType: PropTypes.bool,
};

MapAutoComplete.defaultProps = {
  placeholder: 'Enter a location',
  type: 'google',
  withInternalSearchType: false,
};

export default MapAutoComplete;