import React, { Component } from 'react';
import { BaseEditPropTypes } from '../CommonListContainer';
import request from 'axios';
import {SmallUserPreview} from "../users/Preview";
import SubmitButton from "../../loginsrc/SubmitButton";
import SendSms from "../SendSms";
import {Route} from "react-router-dom";
import {BackHeader} from "../Setting";
import { Form, Row, } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import qs from 'qs';
import ItemsSelector from "../ItemsSelector";

export default class SmsEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitActive: false,
      selectedUsers: [],
      number: '',
    };
    this.users = [];
  }

  componentWillMount() {
    if (this.props.match.params.id === 'new')
      this.requestUsers();
  }

  requestUsers() {
    request.get('/items/users', {
      params: {attributes: ['name', 'id', 'surname', 'telnumb', 'nameSurname'] },
      paramsSerializer: params => {
        return qs.stringify(params)
      }
    }).then((res) => {
        if (res.status === 200) {
          this.users = res.data;
          this.setState({ userId:
              this.props.match.params.id === 'new' ? this.users[0].id : this.state.userId,
            requestActive: false,
            number: this.users[0].telnumb,
            selectedUsers: res.data,
            });
        }
      })
      .catch(err => {
        this.setState({ requestActive: false });
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { selectedUsers } = this.state;
    this.props.history.replace({
      pathname: this.props.match.url.replace('new', 'sendsms'),
      state: {
        to: this.state.number,
        defaultText: '',
        selectedUsers: selectedUsers.filter(o => o.filtered),
      },
    })
  }

  smsSended(sms, location) {
    store.dispatch(show({
      title: 'Info',
      level: 'info',
      autoDismiss: 3,
      children: (
        <div>
          Sms odeslána
        </div>
      ),
    }, 'info'));
    this.props.onSubmit(sms.id, this, sms);
  }

  multiSmsSended = (list) => {
    store.dispatch(show({
      title: 'Info',
      level: 'info',
      autoDismiss: 3,
      children: (
        <div>
          Sms odeslána
        </div>
      ),
    }, 'info'));
  }

  render() {
    const { selectedUsers } = this.state;
    if (this.props.match.params.id === 'sendsms')
      return  <Route path={this.props.match.url}
                     render={(props) => <SendSms {...props}
                                                 onMultiSmsSended={this.multiSmsSended}
                                                 onSended={(sms, location) => this.smsSended(sms, location)} />}/>

    const selected = selectedUsers.filter(o => o.filtered);

    return (
      <div style={{ paddingTop: '0px' }} className='profile'>
        <ContentWithFixedHeader header={
          <BackHeader
            fontSize='18px'
            headerText={'Nová SMS'}
            onBackClick={() => this.props.history.replace(
              this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
        }>
          <div className='nest'>
            <Form onSubmit={(e) => this.handleSubmit(e)}>
              <Row style={{marginBottom: '10px'}}>
                <ItemsSelector value={'name'}
                               overrideToggle={'Filtr role'}
                               onSelected={(role) => {
                                 selectedUsers.forEach(user => {
                                     user.filtered = user.spaces.filter(o => o.roleId === role.id && o.enabled).length > 0;
                                     if (user.id === 0)
                                       user.filtered = false;
                                 })
                                 this.setState({ selectedUsers })
                               }}
                               itemsToFetch={'roles'}/>
                <ItemsSelector
                  value={'nameSurname'}
                  defaultQuery={{attributes: ['name', 'id', 'surname', 'nameSurname', 'telnumb', 'roleId'] }}
                  preview={SmallUserPreview}
                  externList={this.state.selectedUsers}
                  fullSelectedList

                  listChanged={(list, isFromInit) => {

                    if (!isFromInit) {
                      const filtered = list.filter(o => o.filtered);
                      if (filtered.length === 1) {
                        this.setState({
                          number: filtered[0].telnumb,
                        });
                      }
                    }
                  }}
                  appendItemsToStart={[
                    { nameSurname: 'Všichni', name: 'Všichni', surname: '', id: 0, telnumb: '' },
                  ]}
                  filterEnable
                  isFilterable
                  otherPreviewProps={{
                    style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
                  }}
                  itemsToFetch={'users'}/>
              </Row>
              <Row>
                { selected.length < 2 && <ReactPhoneInput defaultCountry={'cz'}
                                 onChange={(value) => this.setState({number: value})}
                                 value={this.state.number}
                                 inputStyle={{width:'100%'}}/> }
              </Row>
              <Row>
                <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                  Pokračovat
                </SubmitButton>
              </Row>

            </Form>
          </div>
        </ContentWithFixedHeader>
      </div>
    )
  }
}

SmsEdit.propTypes = BaseEditPropTypes;