import { createContext } from 'use-context-selector';
import {Dispatch} from "react";
import {DataRow} from "@cml/models";

export const SET_EDITED_ROW = 'SET_EDITED_ROW';
export const SET_EDITED_ROW_INDEX = 'SET_EDITED_ROW_INDEX';

interface State {
  editedDataRow: DataRow | null;
  editedDataRowIndex: number;
}


const ResultsContext =
  // @ts-ignore
  createContext<{state: State, dispatch: Dispatch<ResultsActionTypes>}>({});

interface SetEditedRow {
  type: typeof SET_EDITED_ROW;
  row: Partial<DataRow> | null
}

interface SetEditedRowIndex {
  type: typeof SET_EDITED_ROW_INDEX;
  index: number;
}

export type ResultsActionTypes = SetEditedRow | SetEditedRowIndex;


export const setEditedRow = (row: Partial<DataRow> | null): SetEditedRow => ({
  type: SET_EDITED_ROW,
  row,
})

export const setEditedRowIndex = (index: number): SetEditedRowIndex => ({
  type: SET_EDITED_ROW_INDEX,
  index,
})

const reducer = (state: State, action: ResultsActionTypes): State => {
  if (action.type === SET_EDITED_ROW) {
    if (!action.row) {
      return {
        ...state,
        editedDataRow: null
      }
    }
    return {
      ...state,
      editedDataRow: {
        ...state.editedDataRow,
        ...action.row
      } as DataRow
    }
  } else if (action.type === SET_EDITED_ROW_INDEX) {
    return {
      ...state,
      editedDataRowIndex: action.index
    }
  }
  return state;
}

export {
  ResultsContext,
  reducer,
}