import * as React from 'react';
import { AbstractReactFactory, GenerateModelEvent, GenerateWidgetEvent } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams-core';
import {BoolSwitchNodeModel, ConnectionNodeModel, EndNodeModel} from "./FlowControlModels";
import {BoolSwitchNodeWidget, ConnectionNodeWidget, EndNodeWidget} from "./FlowControlWidgets";

export class EndNodeFactory extends AbstractReactFactory<EndNodeModel, DiagramEngine> {
    constructor() {
        super('endnode');
    }

    generateModel(event: GenerateModelEvent): EndNodeModel {
        return new EndNodeModel();
    }

    generateReactWidget(event: GenerateWidgetEvent<EndNodeModel>): JSX.Element {
        return <EndNodeWidget engine={this.engine} node={event.model} />;
    }
}

export class BoolSwitchNodeFactory extends AbstractReactFactory<BoolSwitchNodeModel, DiagramEngine> {
    constructor() {
        super('boolSwitch');
    }

    generateModel(event: GenerateModelEvent): BoolSwitchNodeModel {
        return new BoolSwitchNodeModel();
    }

    generateReactWidget(event: GenerateWidgetEvent<BoolSwitchNodeModel>): JSX.Element {
        return <BoolSwitchNodeWidget engine={this.engine} node={event.model} />;
    }
}

export class ConnectionNodeFactory extends AbstractReactFactory<ConnectionNodeModel, DiagramEngine> {
    constructor() {
        super('connection');
    }

    generateModel(event: GenerateModelEvent): ConnectionNodeModel {
        return new ConnectionNodeModel();
    }

    generateReactWidget(event: GenerateWidgetEvent<ConnectionNodeModel>): JSX.Element {
        return <ConnectionNodeWidget engine={this.engine} node={event.model}/>;
    }


}