import React, {Component, useState} from "react";
import Button from "react-bootstrap/lib/Button";
import Modal from "react-bootstrap/lib/Modal";
import store from "../../../redux/store";
import {show} from "react-notification-system-redux";
import {Dropdown, DropdownButton, FormControl, MenuItem, Popover} from "react-bootstrap";
import ControlLabel from "react-bootstrap/lib/ControlLabel";
import request from 'axios';
import {LoadingOrRender} from "../../../loginsrc/Profile";
import ItemsSelector from "../../ItemsSelector";
import {SmallOrderPreview} from "../../orders/Preview";
import {SmallUserPreview} from "../../users/Preview";
import {getFloatTime, getRangeIndex, getTimeString} from "../../../AppObjects";
import TimeField from "react-simple-timefield";
import {rowStates, rowStatesSupp, recalculate} from '../components/datarowBase';
import PriceCalculation from "./PriceCalculation";
import {BaseCalculationItem, IPriceAttributesCustom} from "@cml/types";
import {Attributes, DataRow, EDataRow, Items} from "@cml/models";
import Attachments from "../../other/Attachments";

interface Props {
  datarows: EDataRow[];
  onDone: (invoices: {logItem: {itemId: number, id: number}}[]) => void;
  onStorno: (logItem: { description: { ids: any[]; }; id: number; }) => void;
  onMultiDone: (datarows: EDataRow[]) => void;
  priceAttributes: Attributes[];
}

interface State {
  showModal: boolean;
  keyToChangeIndex: number;
  valueToChange: any;
  loading: boolean;
}

const dropDownMenu = [
  'invoice',
  'note',
  'userId',
  'vehicleId',
  'orderId',
];

const dropDownMenuLabel = [
  'Důvod nefakturace',
  'Poznámka',
  'Číslo zakázky',
  'Stroj',
  'Strojník',
  'Nákladka',
  'Výkladka',
  'Firma',
  'Stavba',
  'Kontakt',
  'Čas od',
  'Čas do',
  'Přestávka',
  'Stav',
  'Kalkulace',
  'Příloha',
  'Atribut',
  'Vymazat attribut ceny'
];

interface NoInvoiceClassProps {
  datarows: {
    invoices: {logItem: { itemId: number, id: number}}[]
  }[];
  onLoadingChange: (val: boolean) => void;
  onDone: (invoices: {logItem: {itemId: number, id: number}}[]) => void;
  onStorno: (inv: {logItem: {itemId: number, id: number}}) => void;
}

interface ChangeInterface {
  onDone(val: any): void;
}

function NoImport(props: NoInvoiceClassProps) {
  const [valueToChange, setValueToChange] = useState('');
  const noInvoiceSave = () => {
    props.onLoadingChange(true);
    request.post('/items/noInvoiceReason', {
      dataRows: props.datarows,
      reason: valueToChange,
    })
      .then((res) => {
        props.onDone(res.data.invoices);
        props.onLoadingChange(false);
      }).catch(err => {
        props.onLoadingChange(false);
    })
  }

  const noInvoiceDelete = () => {
    const { datarows } = props;
    props.onLoadingChange(true);
    // @ts-ignore
    const invoicesIds = [...new Set(datarows.reduce((acc, o) => acc.concat(o.invoices.map(i => i.logItem.id)), []))].filter(o => o);
    request.post('/items/noInvoiceReasonStorno', {
      logIds: invoicesIds
    })
      .then((res) => {
        // @ts-ignore
        const invoices = [...new Set(datarows.reduce((acc, o) => acc.concat(o.invoices.map(i => i.logItem)), []))].filter(o => o);
        invoices.forEach(o => props.onStorno(o));
        props.onLoadingChange(false);
      }).catch(err => {
      props.onLoadingChange(false);
    })
  }

  const { datarows } = props;
  const conditionOne = datarows.filter(o => !o.invoices.length).length === datarows.length;
  const conditionTwo = datarows.filter(o => {
    if (!o.invoices)
      return false;
    return o.invoices.filter(oo => oo.logItem.itemId === 0).length
  }).length > 0;
  const conditionThree = datarows.filter(o => {
    if (!o.invoices)
      return false;
    return o.invoices.filter(oo => oo.logItem.itemId !== 0).length
  }).length > 0;

  return <>
  {conditionOne && <div style={{ marginTop: '10px' }}>
      <ControlLabel>Důvod</ControlLabel>
      <div>
              <textarea style={{ width: '100%', minHeight: '140px', padding:'10px'}}
                        onChange={(e) => {
                          setValueToChange(e.target.value);
                        }}
                        value={valueToChange} />
      </div>
      <Button onClick={noInvoiceSave}>Uložit</Button>
    </div>}
  {conditionThree && <div style={{ marginTop: '20px' }}>
      <ControlLabel style={{ color: 'red' }}>Některý z řádků již je klasicky vyfakturován takový řádek nelze zde použít</ControlLabel>
    </div>}
  {conditionTwo && <div style={{ marginTop: '20px' }}>
      <ControlLabel style={{ color: 'red' }}>Některý z řádků má již zapsaný důvod nefakturace, nejprve jej vymažte</ControlLabel>
      <div><Button onClick={noInvoiceDelete}>Vymazat</Button></div>
    </div>}
  </>
}

function ChangeNote(props: ChangeInterface) {
  const [newNote, setNewNote] = useState('');
  return <>
    <h4>Poznámka</h4>
    <textarea style={{ width: '100%', minHeight: '140px', padding:'10px'}}
              onFocus={(e) => e.target.focus()}
              onChange={(e) => {
                setNewNote(e.target.value);
              }}
              value={newNote} />
    <div><Button onClick={() => props.onDone({
      note: newNote
    })}>Uložit</Button></div>
    </>
}

function ChangeAttribute(props: ChangeInterface) {
  const [attribute, setAttribute] = useState<Attributes | null>(null);
  return <>
    <h4>Zakázka</h4>
    <ItemsSelector
      onSelected={(attr: Attributes) => setAttribute(attr)}
      itemsToFetch='attributes/dispatching'
      value='name'
      filterEnable/>
    <div style={{ marginTop: '10px' }}><Button onClick={() => attribute && props.onDone({
      attributeId: attribute.id ,
      attribute,
    })}>Uložit</Button></div>
  </>
}

function ChangeOrder(props: ChangeInterface) {
  const [order, setOrder] = useState(0);
  return <>
    <h4>Zakázka</h4>
    <ItemsSelector
      filterEnable
      onSelected={(order: React.SetStateAction<number>) => setOrder(order)}
      itemsToFetch={'orders'}
      preview={SmallOrderPreview}
      value={'label'}
    />
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      // @ts-ignore
      orderId: order.id ,
      order,
    })}>Uložit</Button></div>
  </>
}

function ChangeVehicle(props: ChangeInterface) {
  const [vehicle, setVehicle] = useState(null);
  return <>
    <h4>Vozidlo</h4>
    <ItemsSelector
      filterEnable
      defaultQuery={{ allDetails: 0 }}
      onSelected={(vehicle: React.SetStateAction<null>) => setVehicle(vehicle)}
      itemsToFetch={'vehicles'}
      //preview={SmallOrderPreview}
      value={'identifier'}
    />
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      // @ts-ignore
      vehicleId: vehicle.id ,
      vehicle,
    })}>Uložit</Button></div>
  </>
}

function ChangeUser(props: ChangeInterface) {
  const [user, setUser] = useState(null);
  return <>
    <h4>Strojník</h4>
    <ItemsSelector
      filterEnable
      defaultQuery={{ allDetails: 0 }}
      onSelected={(user: React.SetStateAction<null>) => setUser(user)}
      itemsToFetch={'usersForDispatching'}
      preview={SmallUserPreview}
      value={'nameSurname'}
      otherPreviewProps={{style: {display: 'inline'}}}
    />
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      // @ts-ignore
      userId: user.id ,
      user,
    })}>Uložit</Button></div>
  </>
}

function ChangeLoading(props: ChangeInterface) {
  const [loading, setLoading] = useState('');
  return <>
    <h4>Nákladka</h4>
    <FormControl
      placeholder={'Nákladka'}
      id="loading"
      value={loading}
      onChange={(e) => {
        setLoading((e.target as HTMLTextAreaElement).value)
      }}
      type='text'/>
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      loading
    })}>Uložit</Button></div>
  </>
}

function ChangeUnLoading(props: ChangeInterface) {
  const [unloading, setLoading] = useState('');
  return <>
    <h4>Výkladka</h4>
    <FormControl
      placeholder={'Výkladka'}
      id="unloading"
      value={unloading}
      onChange={(e) => {
        setLoading((e.target as HTMLTextAreaElement).value)
      }}
      type='text'/>
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      unloading
    })}>Uložit</Button></div>
  </>
}

function ChangeCompany(props: ChangeInterface) {
  const [company, setCompany] = useState('');
  return <>
    <h4>Firma</h4>
    <FormControl
      placeholder={'Firma'}
      id="company"
      value={company}
      onChange={(e) => {
        setCompany((e.target as HTMLTextAreaElement).value)
      }}
      type='text'/>
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      company
    })}>Uložit</Button></div>
  </>
}

function ChangeConstruction(props: ChangeInterface) {
  const [construction, setConstruction] = useState('');
  return <>
    <h4>Stavba</h4>
    <FormControl
      placeholder={'Stavba'}
      id="construction"
      value={construction}
      onChange={(e) => {
        setConstruction((e.target as HTMLTextAreaElement).value)
      }}
      type='text'/>
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      construction
    })}>Uložit</Button></div>
  </>
}

function ChangeCalculation(props: {
  priceAttributes: Attributes[];
} & ChangeInterface) {
  const [data, setData] = useState({
    from: 0, //Fake it
    to: 0,
    break: 0,
    priceEq: [],
  });

  return <>
    <h4>Změna ceny vybrané položky</h4>
    <div style={{paddingBottom: '10px', borderBottom: 'solid 1px grey'}}>
      <PriceCalculation
        priceEq={data.priceEq}
        data={data}
        onPriceEqChanged={(priceEq) => {
          // @ts-ignore
          data.priceEq = priceEq;
          setData({
            ...data
          });
        }}
      />
    </div>
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone(data)}>Uložit</Button></div>
  </>
}

function DeleteCalculationAttribute(props: {
  priceAttributes: Attributes[];
} & ChangeInterface) {
  const [attributeId, setAttributeId] = useState(0);
  return <>
    <h4>Atribut</h4>
    <ItemsSelector
      filterEnable
      externList={props.priceAttributes}
      onSelected={(attribute: { id: number; }) => setAttributeId(attribute.id)}
      itemsToFetch={'attributes/price'}
      value={'name'}
      otherPreviewProps={{style: {display: 'inline'}}}
    />
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone(attributeId)}>Vymazat</Button></div>
  </>
}

function ChangeTime(props: {
  nameOfProp: string
} & ChangeInterface) {
  const [time, setTime] = useState(0);
  return <>
    <TimeField
      style={{padding: '5px', fontSize: '11px', minWidth: '45px',maxWidth: '45px', display: 'inline-block'}}
      value={getTimeString(time)}                     // {String}   required, format '00:00' or '00:00:00'
      onChange={(e) => setTime(getFloatTime(e.target.value))}      // {Function} required
      input={<FormControl onFocus={(e) => (e.target as unknown as HTMLInputElement).select()}/>} // {Element}  default: <input type="text" />
      colon=":"                        // {String}   default: ":"
    />
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      // @ts-ignore
      [props.nameOfProp]: time,
    })}>Uložit</Button></div>
  </>
}

function ChangeState(props: ChangeInterface) {
  const [state, setState] = useState(0);

  return <>
    <h4>Kontakt</h4>
    <Dropdown
      id={'dispatching-addressbook-select'}
      onSelect={(e: any) => setState(e)}
    >
      <Dropdown.Toggle>
        <div style={{ fontSize: '11px', display: 'inline' }}>
          {
            // @ts-ignore
            rowStates[state]}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {
          Object.keys(rowStates).map((key) => {
            return <MenuItem key={key} eventKey={Number(key)} style={{fontSize: '11px'}}>
              {
                // @ts-ignore
                rowStates[key]}
            </MenuItem>
          })
        }
      </Dropdown.Menu>
    </Dropdown>
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      isConfirmed: state
    })}>Uložit</Button></div>
  </>
}

function ChangeContactUser(props: ChangeInterface) {
  const [user, setUser] = useState(null);
  return <>
    <h4>Kontakt</h4>
    <ItemsSelector
      filterEnable
      defaultQuery={{ roleWhere: { isContactUser: 1} }}
      onSelected={(user: React.SetStateAction<null>) => setUser(user)}
      itemsToFetch={'usersWithSpecificRoleWhere'}
      preview={SmallUserPreview}
      value={'nameSurname'}
      otherPreviewProps={{style: {display: 'inline'}}}
    />
    <div style={{ marginTop: '10px' }}><Button onClick={() => props.onDone({
      // @ts-ignore
      contactUserId: user.id ,
      contactUser: user,
    })}>Uložit</Button></div>
  </>
}

interface AttaMultiProps extends ChangeInterface {
  ids: number[]
}

function AttachmentsMulti(props: AttaMultiProps) {

  return <>
    <h4>Přílohy</h4>
    <Attachments identificationProps={{
      itemId: props.ids,
      itemTableName: 'datarow',
    }}
      //ref={attachmentsRef}
      onMultiChange={props.onDone}
      multiMode
       initObject={undefined}
       saveButton
       selfListed
       updating={true}
       smSize={3}
       mdSize={2}/>
  </>
}

export default class MultiRowChange extends Component<Props, State> {
  static defaultProps: {
    datarows: [],
  }
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      keyToChangeIndex: 0,
      valueToChange: '',
      loading: false,
    }
  }

  onStandardChange = (value: any) => {
    const { datarows } = this.props;
    datarows.forEach((row, index) => {
      datarows[index] = {
        ...row,
        ...value,
      };
      datarows[index] = recalculate(datarows[index] as EDataRow, this.props.priceAttributes) as EDataRow;
    })
    this.setState({ loading: true });
    request.post('/items/multipleDataRowEdit', {
      dataRows: datarows,
    }).then(() => {
      this.props.onMultiDone(datarows);
      this.setState({ showModal: false, loading: false });
    }).catch(() => {
      this.setState({ loading: false })
    })
  }

  onAttachmentsChange = (items: Items[]) => {
    const datarows = this.props.datarows.slice();
    datarows.forEach((row, index) => {
      datarows[index] = {
        ...row,
        // @ts-ignore  I know that there can be undefined
        attachments: items.find(i => i.itemId === row.id),
      };
      datarows[index] = recalculate(datarows[index] as EDataRow, this.props.priceAttributes) as EDataRow;
    })
    this.props.onMultiDone(datarows);
    this.setState({ showModal: false, loading: false });
  }

  onCalculationDelete = (attributeId: number) => {
    const datarows = this.props.datarows.slice();
    datarows.forEach((row, index) => {
      if (row.priceEq) {
        const index_ = (row.priceEq as BaseCalculationItem[]).findIndex(p => p.attributeId === attributeId);
        if (index_ > -1) {
          (row.priceEq as BaseCalculationItem[]).splice(index_, 1);
          datarows[index] = {
            ...row,
          } as EDataRow
        }
      }
    });
    this.setState({ loading: true });
    request.post('/items/multipleDataRowEdit', {
      dataRows: datarows,
    }).then(() => {
      this.props.onMultiDone(datarows);
      this.setState({ showModal: false, loading: false });
    }).catch(() => {
      this.setState({ loading: false })
    })
  }

  onCalculationChange = (data: {priceEq: BaseCalculationItem[]}) => {
    const { datarows } = this.props;
    data.priceEq.forEach(d => {
      datarows.forEach((row, index) => {
        if (row.priceEq) {
          const p = (row.priceEq as BaseCalculationItem[]).find(o => o.attributeId === d.attributeId);

          if (p) {
            p.price = d.price;
            if (!Array.isArray(d.price)) {
              p.total = d.price * p.amount;
            } else {
              const priceAttribute = this.props.priceAttributes.find(o => o.id === p.attributeId);
              if (priceAttribute) {
                const i = getRangeIndex(p.amount, (priceAttribute.customOptions as IPriceAttributesCustom).rangeList);
                p.total = d.price[i] * p.amount;
              }

            }
          }
        }
        datarows[index] = {
          ...row,
        } as EDataRow
      })
    });
    this.setState({ loading: true });
    request.post('/items/multipleDataRowEdit', {
      dataRows: datarows,
    }).then(() => {
      this.props.onMultiDone(datarows);
      this.setState({ showModal: false, loading: false });
    }).catch(() => {
      this.setState({ loading: false })
    })
  }

  render() {
    const { showModal, keyToChangeIndex, valueToChange } = this.state;
    const { datarows } = this.props;

    const priceIds = datarows.reduce((acc, d) =>
      acc.concat(d.priceEq ? (d.priceEq as BaseCalculationItem[]).map(p => p.attributeId) : []) ,[] as number[])

    return [<Modal show={showModal}  onHide={() => this.setState({ showModal: false })}>
       <Modal.Header closeButton>
         <h4>Úprava více řádků najednou</h4>
       </Modal.Header>
        <Modal.Body>
          <div>
            <ControlLabel>Položka pro změnu</ControlLabel>
            <div>
              <DropdownButton id='validState'
                              onSelect={(key: any) => {
                                this.setState({ keyToChangeIndex: key as number });
                              }}
                              title={dropDownMenuLabel[keyToChangeIndex]}>
                {dropDownMenuLabel.map((o, index) => <MenuItem key={index} eventKey={index}>{o}</MenuItem>)}
              </DropdownButton>
            </div>
          </div>
          <LoadingOrRender requestActive={this.state.loading}>
            { keyToChangeIndex === 0 && <NoImport
              datarows={datarows}
              onLoadingChange={val => this.setState({ loading: val})}
              onDone={(data) => this.props.onDone(data)}
              // @ts-ignore
              onStorno={invoice => this.props.onStorno(invoice)}/>}
            { keyToChangeIndex === 1 && <ChangeNote onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 2 && <ChangeOrder onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 3 && <ChangeVehicle onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 4 && <ChangeUser onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 5 && <ChangeLoading onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 6 && <ChangeUnLoading onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 7 && <ChangeCompany onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 8 && <ChangeConstruction onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 9 && <ChangeContactUser onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 10 && <ChangeTime nameOfProp='from' onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 11 && <ChangeTime nameOfProp='to' onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 12 && <ChangeTime nameOfProp='break' onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 13 && <ChangeState onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 14 && <ChangeCalculation
              priceAttributes={this.props.priceAttributes} onDone={this.onCalculationChange}/>}
            { keyToChangeIndex === 15 && <AttachmentsMulti
              ids={datarows.map(o => o.id)} onDone={this.onAttachmentsChange}/>}
            { keyToChangeIndex === 16 && <ChangeAttribute onDone={this.onStandardChange}/>}
            { keyToChangeIndex === 17 && <DeleteCalculationAttribute
              priceAttributes={this.props.priceAttributes.filter(p => priceIds.includes(p.id))}
                                                                     onDone={this.onCalculationDelete}/>}
            {keyToChangeIndex > 17 && <div style={{ marginTop: '20px' }}>
              <ControlLabel style={{ color: 'red' }}>CML to ještě neumí ale bude!</ControlLabel>
            </div>}
          </LoadingOrRender>
        </Modal.Body>
    </Modal>,
    <Button bsStyle='primary' style={{ float: 'left', marginRight: '10px', marginTop: '14px' }}
      onClick={() => {
        if (datarows.length === 0) {
          store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
            children: (
              <div>
                Není vybrán žádný řádek
              </div>
            ),
          }, 'error'));
          return;
        }
        this.setState({
          showModal: true,
        })
      }}
    >
      Multi úprava
    </Button>
    ]
  }
}