import React, {Component} from "react";
import {AutoSizer, CellMeasurer, CellMeasurerCache, Column, Table} from "react-virtualized";
import {getStore, rowStateColors, rowStateColorsOdd, rowStates, rowStatesSupp} from '../components/datarowBase';
import FontAwesome from "react-fontawesome";
import Marker from "./Marker";
import moment from "moment";
import {dateFormatVisible, dateFromat, getBaseUrl, getTimeString, mapAuthStateToProps} from "../../../AppObjects";
import {OverlayTrigger, Popover} from "react-bootstrap";
import Attachments from "../../other/Attachments";
import {SmallUserPreview} from "../../users/Preview";
import GPSDataViewer from "./GPSDataViewer";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import MaterialTable from "./MaterialTable";
import {getTimeDiff} from "./datarowBase";
import {getCalculation} from "./PriceCalculation";
import ResultRowUpdateOrDelete from "./ResultRowUpdateOrDelete";
import AttendanceDelete from "./AttendanceDelete";

const mapStateToProps = state => {
  return {
    authenticate: state.authenticate,
    navbar: state.navbar,
  }
};

export const filterVehicleData = (vehicleDatas, dataRow, user, ignoreVision = false) => {
  let vehicleData = vehicleDatas;
  vehicleData = vehicleData.filter(o => o.timestamp.split(' ')[0] === dataRow.date);

  if (dataRow.vehicle && dataRow.vehicle.type !== 'custom') {

    vehicleData = vehicleData.filter(o => {
      return o.vehicleId === dataRow.vehicle.foreignApiVehicleId;
    });
  } else if (dataRow.vehicle) {
    vehicleData = [];
  }
  vehicleData = vehicleData.filter(o => o.currentDriverActivity && ![
    'Not_logged_in',
    'Resting',
  ].includes(o.currentDriverActivity));
  if(user) {
    // Due to attendance check if current user present in tracking data
    const ids = user.foreignApiDriverId.map(o => ({
      id: o.driver.id.toString(),
      type: o.type,
    }));
    vehicleData = vehicleData.filter(o => {
      if (o.type === 'volvo') {
        const id = ids.find(i => i.type === 'volvoApi');
        if (!id || !o.driverId)
          return true;
        return id.id === o.driverId;
      } else if (o.type === "webdispecink") {
        const id = ids.find(i => i.type === 'webDisApi');
        if (!id || !o.driverId)
          return true;
        return id.id === o.driverId;
      }
      return !ignoreVision;
    });
  }
  return vehicleData;
}

class ResultRows extends Component {

  constructor(props) {
    super(props);
    this.dispatchOther = props.authenticate.actualUserRole.dispatching.other;
  }

  _cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 30,
  });

  checkIfAttendance(cell, render) {
    if (cell.rowData.isAttendance) {
      return null;
    }
    return render;
  }

  getCan(name, index) {
    const { dataRows } = this.props;
    const item = dataRows[index];
    if (item.isAttendance)
      return false;
    if (!item.attribute) {
      return true;
    }
    return this.dispatchOther[name] && item.attribute.customOptions[name]
  }

  getCanLoading(name, index) {
    const { dataRows } = this.props;
    const item = dataRows[index];
    if (item.isAttendance)
      return false;
    if (!item.attribute) {
      return true;
    }
    return this.dispatchOther.canLoadingUnloading && item.attribute.customOptions[name]
  }
  
  render() {
    const { dataRows, priceAttributes, readOnly, authenticate, navbar, user } = this.props;
    return <AutoSizer disableHeight>
      {({width}) => (
        <Table
          deferredMeasurementCache={this._cache}
          width={width}
          height={window.innerHeight - 350}
          headerHeight={30}
          overscanRowCount={2}
          rowStyle={({index}) => {
            if(index > -1) {
              const item = dataRows[index];
              if (dataRows[index].isAttendance) {
                return {
                  backgroundColor: dataRows[index].attribute.color,
                }
              } else {
                return {
                  backgroundColor: index % 2 === 0 ? rowStateColors[item.isConfirmed] : rowStateColorsOdd[item.isConfirmed],
                }
              }

            }
          }}
          rowHeight={this._cache.rowHeight}
          rowCount={dataRows.length}
          rowGetter={({ index }) => {
            if (dataRows[index].isAttendance) {
              return {
                ...dataRows[index],
              }
            }
            const { vehiclesData } = this.props;
            const vehicleData = filterVehicleData(vehiclesData, dataRows[index], user);
            return {
              calculation: !dataRows[index].calculation ? getCalculation(dataRows[index], {
                attribute: dataRows[index].attribute,
                priceAttributes,
                materialAttributes: this.props.materialAttributes,
                storeAttributes: this.props.storeAttributes,
                defaultMaterialTable: dataRows[index].order ? dataRows[index].order.other.materialTable : undefined,
              }) : undefined,
              vehicleData,
              fractionString: MaterialTable.GenerateString({
                materialTable: dataRows[index].materialTable,
                priceAttributes,
                materialAttributes: this.props.materialAttributes,
                storeAttributes: this.props.storeAttributes,
              }),
              ...dataRows[index],
            };
          }}
        >
          <Column
            label={
              <input type="checkbox" onChange={(e) => {
                let rows = dataRows.slice();
                rows.forEach((row) => {
                  if (row.spaceId === authenticate.activeSpaceId && !row.isAttendance && row.id > 0)
                    row.isSelected = e.target.checked;
                });
                this.props.rowsChanged(rows);
              }}/>
            }
            dataKey='isSelected'
            width={14}
            minWidth={14}
            cellRenderer={(cell) => this.checkIfAttendance(cell, authenticate.activeSpaceId === cell.rowData.spaceId ? <input
              disabled={cell.rowData.id < 0}
              type="checkbox" checked={cell.cellData} onChange={(e) => {
              let rows = dataRows.slice();
              rows[cell.rowIndex].isSelected =  e.target.checked;
              this.props.rowsChanged(rows);
            }}/> : navbar.spaces.find(o => o.id === cell.rowData.spaceId).name)
            }>
          </Column>
          { !readOnly && <Column
            label={
              <button className='transparentButton' onClick={(e) => {
                e.preventDefault();
                this.props.requestedRowAdd();
              }}>
                <FontAwesome style={{fontSize:'14px'}} name='fas fa-plus' />
              </button>
            }
            dataKey='isSelected'
            width={40}
            minWidth={40}
            cellRenderer={(cell) => cell.rowData.isAttendance ? <AttendanceDelete
                index={cell.rowIndex}
                requestedDate={cell.rowData.date}
                attendance={cell.rowData.originAttendance} />
              : this.checkIfAttendance(cell, authenticate.activeSpaceId === cell.rowData.spaceId ?
              <ResultRowUpdateOrDelete
                id={cell.rowData.id}
                index={cell.rowIndex}
                requestedRowChange={() => this.props.requestedRowChange(cell.rowIndex, cell.rowData)}/>
                : <FontAwesome title='V aktuálním prostoru nelze upravovat řádek jiného prostoru!'
                               style={{fontSize:'14px', color: 'grey'}} name='fas fa-ban' />)
            }>
          </Column> }
          <Column
            label=''
            minWidth={40}
            dataKey='date'
            cellRenderer={(cell) => this.checkIfAttendance(cell, <div style={{border: 'none', background: 'transparent'}}
                                                                      className='buttonMarkerWrapper'>
              <Marker color='brown' type='orderForeignId' id={cell.rowData.orderForeignId}/>
              <Marker color='darkblue' type='incomeInvoiceForeignId'
                      id={cell.rowData.incomeInvoiceForeignId}/>
              {
                cell.rowData.invoices && cell.rowData.invoices.map((inv, index) => {
                  if(inv.logItem.itemId) {
                    return <Marker key={index} color='purple' morePrintOptions={[{id: '190', label: 'Faktura'}, {id:'235', label: 'Součet'}]} type='invoiceForeignId' id={inv.logItem.itemId}/>
                  }
                  return <OverlayTrigger rootClose trigger='click' placement='right' overlay={
                    <Popover id={`popover-contained ${inv.logItem}`}>
                      {inv.logItem.description.invoice.text}
                    </Popover>
                  }>
                    <div style={{ backgroundColor: 'purple' }} className='buttonMarker line'>
                      <div className='buttonMarkerLine'/>
                    </div>
                  </OverlayTrigger>
                })
              }
              <Marker disableClick color='darkgreen' type='salaryId' id={cell.rowData.salaryId}/>
            </div>)
            }
          />
          <Column
            label='Č.F.'
            dataKey='invoices'
            width={70}
            cellRenderer={(cell) => {
              if (cell.cellData && cell.cellData.length > 0) {
                return cell.cellData.map(o => o.logItem.description.number).join(', ')
              } else {
                return null;
              }
            }}
          />
          <Column
            label='Datum'
            dataKey='date'
            width={100}
            cellRenderer={(cell) => moment(cell.cellData, dateFromat).format(dateFormatVisible) + ` ${moment(cell.cellData, dateFromat).format('ddd')}`}
          />
          <Column
            label='Atribut'
            dataKey='attribute'
            width={60}
            cellRenderer={(cell) => cell.cellData ? cell.cellData.name.substring(0,6) : <FontAwesome
              title='Existuji GPS data, ale neexistije řádek, prosím vyplňte jej a uložte'
              name='fas fa-exclamation' style={{color: 'darkred'}}/>}
          />
          <Column
            width={40}
            label='Pozn.'
            dataKey='note'
            cellRenderer={cell => {
              if (!this.getCan('canNote', cell.rowIndex) || (cell.cellData ? cell.cellData.length === 0 : true)) {
                return this.checkIfAttendance(cell, <FontAwesome name='fas fa-ban' style={{color: 'grey'}}/>)
              }
              return this.checkIfAttendance(cell, <OverlayTrigger trigger='click' placement='bottom' overlay={
                <Popover id={`popover-contained ${this.props.index}`}>
                  <div>{cell.cellData}</div>
                </Popover>
              }>
                <div className='IconWithNumberBase'>
                  {
                    cell.cellData ? (cell.cellData.length > 0 ? <div style={{ right: '-4px', top: '4px' }}
                                                                     className='warning'>
                      <FontAwesome name='fas fa-exclamation' />
                    </div> : null) : null
                  }
                  <button className='transparentButton'>
                    <FontAwesome style={{fontSize:'18px', color: cell.cellData ? (cell.cellData.length > 0 ? '#2c2c2c' : 'grey') : 'grey', }} name='fas fa-clipboard' />
                  </button>
                </div>
              </OverlayTrigger>)
            }}/>
          <Column
            width={40}
            label='Př.'
            dataKey='attachments'
            cellRenderer={cell => {
              if (!this.getCan('canNote', cell.rowIndex) || (!cell.cellData)) {
                return this.checkIfAttendance(cell, <FontAwesome name='fas fa-ban' style={{color: 'grey'}}/>)
              }
              return this.checkIfAttendance(cell, <div style={{ display: 'inline-block' }}>
                <Attachments identificationProps={{
                  itemId: cell.rowData.id,
                  itemTableName: 'datarow',
                }}
                             useModal
                             saveButton
                             list={cell.cellData ? cell.cellData.listOfFiles : []}
                             updating={false}
                             smSize={3}
                             mdSize={2}/>
              </div>)
            }}/>
          <Column
            label='Č.Z'
            dataKey='order'
            width={70}
            cellDataGetter={(cell) => {
              if (cell.rowData.isAttendance)
                return null;
              return cell.rowData.order ? cell.rowData.order.label : null
            }}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canOrderNumber', cell.rowIndex) ?
                cell.cellData : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Stroj'
            dataKey='vehicle'
            width={100}
            cellDataGetter={(cell) => {
              if (cell.rowData.isAttendance)
                return null;
              return cell.rowData.vehicle ? cell.rowData.vehicle.identifier + (cell.rowData.realVehicle ? ` (${cell.rowData.realVehicle.identifier})` : '') : null
            }}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canVehicle', cell.rowIndex) ? cell.cellData
                : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Strojník/dodavatel'
            dataKey='vehicle'
            width={150}
            cellRenderer={(cell) => {
              const item = cell.rowData;
              const canUser = this.getCan('canUser', cell.rowIndex);
              return canUser || item.isAttendance ? <CellMeasurer
                cache={this._cache}
                columnIndex={0}
                key={cell.dataKey}
                parent={cell.parent}
                rowIndex={cell.rowIndex}>

                <div style={{whiteSpace: 'normal',}}>
                  {(!item.isAttendance && item.attribute) && item.attribute.customOptions.userAsAddressBook ?
                    (canUser ? <SmallUserPreview /*iconColor={item.attribute.color}*/ item={{name: item.addressBookItem !== null ? item.addressBookItem.companyWithIc : item.userRaw, surname: ''}}/> : null)
                    : (canUser ? <SmallUserPreview /*iconColor={item.attribute.color}*/ item={item.user}/> : null)
                  }
                </div>
              </CellMeasurer> : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />
            }}
          />
          <Column
            label='Vybavení'
            dataKey='tools'
            width={100}

            cellRenderer={(cell) => {
              return <CellMeasurer
                cache={this._cache}
                columnIndex={1}
                key={cell.dataKey}
                parent={cell.parent}
                rowIndex={cell.rowIndex}>
                <div style={{whiteSpace: 'normal',}}>
                  {this.checkIfAttendance(cell,this.getCan('canTool', cell.rowIndex) ?
                    cell.cellData.map((tool) => `${tool.tool.name}, `) :
                    <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)}
                </div>
              </CellMeasurer>
            }}
          />
          <Column
            label='Nákladka'
            dataKey='loading'
            width={100}
            cellDataGetter={(cell) => {
              const store = getStore(cell.rowData, this.props.storeAttributes)
              return store ? store.name : cell.rowData.loading;
            }}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCanLoading('canLoading', cell.rowIndex) ?
                cell.cellData : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Výkladka'
            dataKey='unloading'
            width={100}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCanLoading('canUnLoading', cell.rowIndex) ?
                cell.cellData : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Firma'
            dataKey='company'
            width={100}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canCompany', cell.rowIndex) ?
                cell.cellData : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Stavba'
            dataKey='construction'
            width={100}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canConstruction', cell.rowIndex) ?
                cell.cellData : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Kontakt'
            dataKey='contactUser'
            width={100}
            cellRenderer={(cell) => {
              const item = cell.rowData;
              const canContactPerson = this.getCan('canContactPerson', cell.rowIndex);
              return this.checkIfAttendance(cell, canContactPerson ? <div>
                {item.contactUser && <SmallUserPreview item={item.contactUser}/>}
              </div> : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Od'
            dataKey='from'
            width={50}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canTime', cell.rowIndex) ?
                [
                  cell.rowData.isTimeOverlaps && <FontAwesome name='fas fa-ban'
                                                              title='Čas se překrývá s jiným řádkem ve stejný den pro stejného strojníka'
                                                              style={{color: 'darkRed', fontSize: 8}} />,
                  getTimeString(cell.cellData),
                  getTimeDiff(cell.rowData).overNight &&  <FontAwesome style={{fontSize:'10px', color: 'black', marginLeft: '3px' }} name='fas fa-moon' />,
                  <GPSDataViewer fromTime={true} vehicleData={cell.rowData.vehicleData}/>,
                ] : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Do'
            dataKey='to'
            width={50}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canTime', cell.rowIndex) ?
                ([
                  getTimeString(cell.cellData),
                  <GPSDataViewer fromTime={false} vehicleData={cell.rowData.vehicleData}/>
                ]) : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Přes.'
            dataKey='break'
            width={50}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canTime', cell.rowIndex) ?
                getTimeString(cell.cellData) : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Hodiny'
            dataKey='break'
            width={50}
            cellDataGetter={(cell) => {
              if (cell.rowData.isAttendance)
                return null;
              return getTimeDiff(cell.rowData);
            }}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canTime', cell.rowIndex) ?
                getTimeString(cell.cellData.sum) : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Frakce'
            dataKey='fraction'
            width={80}
            cellDataGetter={cell => {
              if(cell.rowData.fractionString === '')
                return cell.rowData.fraction;
              return cell.rowData.fractionString;
            }}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canFraction', cell.rowIndex) ?
                cell.cellData : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Stav'
            dataKey='isConfirmed'
            width={100}
            cellRenderer={(cell) =>
              this.checkIfAttendance(cell, (!cell.rowData.isAttendance && cell.rowData.attribute) && cell.rowData.attribute.customOptions.userAsAddressBook ?
                rowStatesSupp[cell.cellData] : rowStates[cell.cellData])
            }
          />
          <Column
            label='Kalkulace'
            dataKey='calculation'
            width={100}
            cellDataGetter={(cell) => {
              if (cell.rowData.isAttendance)
                return null;
              return cell.rowData.calculation.priceCalcString;
            }}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canCalculation', cell.rowIndex) ? cell.cellData
                : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Cena'
            dataKey='calculation'
            width={50}
            cellDataGetter={(cell) => {
              if (cell.rowData.isAttendance)
                return null;
              return cell.rowData.calculation.totalPrice;
            }}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canCalculation', cell.rowIndex) ? cell.cellData
                : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Náklady'

            dataKey='calculation'
            width={50}
            cellDataGetter={(cell) => {
              if (cell.rowData.isAttendance)
                return null;
              return cell.rowData.calculation.totalCost;
            }}
            cellRenderer={(cell) => {
              const showCost = cell.rowData.calculation ? cell.rowData.calculation.showCosts : false;
              return this.checkIfAttendance(cell, this.getCan('canUseCosts', cell.rowIndex) || showCost ? cell.cellData
                : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
          <Column
            label='Zisk'
            dataKey='calculation'
            width={50}
            cellRenderer={(cell) => {
              return this.checkIfAttendance(cell, this.getCan('canCalculation', cell.rowIndex) ? <div
                  style={{color: cell.cellData.totalPrice - cell.cellData.totalCost < 0 ? 'red' : 'darkgreen'}}
                >
                  {cell.cellData.totalPrice - cell.cellData.totalCost}
                </div>
                : <FontAwesome name='fas fa-ban' style={{color: 'grey'}} />)
            }}
          />
        </Table>
      )}
    </AutoSizer>
  }
}

ResultRows.propTypes = {
  dataRows: PropTypes.arrayOf(PropTypes.object),
  vehiclesData: PropTypes.arrayOf(PropTypes.object),
  priceAttributes: PropTypes.arrayOf(PropTypes.object),
  materialAttributes: PropTypes.arrayOf(PropTypes.object),
  storeAttributes: PropTypes.arrayOf(PropTypes.object),
  rowsChanged: PropTypes.func,
  requestedRowChange: PropTypes.func,
  requestedRowAdd: PropTypes.func,
  readOnly: PropTypes.bool,
  user: PropTypes.object,
}

ResultRows.defaultProps = {
  priceAttributes: [],
  materialAttributes: [],
  storeAttributes: [],
  dataRows: [],
  vehiclesData: [],
  rowsChanged: () => {},
  requestedRowChange: () => {},
  readOnly: false,
}

export default connect(mapStateToProps)(ResultRows);