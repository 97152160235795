import {Button, Modal} from "react-bootstrap";
import React, {useState} from "react";
import {Moment} from "moment";
import {DispatchingRights} from "@cml/types/dist/types/roles";

interface Props {
  selectedDate: Moment;
  dateSelected: (date: Moment) => void;
  dispatchingRole: DispatchingRights;
}

export default function LoadFromPreviousDay(props: Props) {
  const [loadFromPreviousModal, setLoadFromPreviousModal] = useState(false);

  const renderButtons = (x: number) => {
    let date = props.selectedDate.clone();
    date.add(-x - 1, 'day');
    return <div key={x}>
      <Button onClick={() => {
        props.dateSelected(date);
        setLoadFromPreviousModal(false);
      }}>
        {
          date.format('ddd DD MMMM YYYY')
        }
      </Button>
    </div>
  }

  return <>
    <Button
      disabled={!props.dispatchingRole.update}
      onClick={() => {
        setLoadFromPreviousModal(true);
      } }
      bsStyle='primary'>
      Načíst data
    </Button>
    <Modal onHide={() => {
      setLoadFromPreviousModal(false)
    }} show={loadFromPreviousModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          Vyberte prosím den ze kterého chcete načíst data (10 dnů zpět)
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          [...Array(10).keys()].map(renderButtons)
        }
      </Modal.Body>
    </Modal>
  </>
}