import React, { Component } from 'react';
import {Checkbox, Col, DropdownButton, Row} from "react-bootstrap";
import request from 'axios';
import {SmallUserPreview} from "../users/Preview";
import { BaseFilterPropTypes } from '../CommonListContainer';
import AttributePreview from '../attributes/Preview';
import {DateRangePicker} from "react-dates";
import {dateFormatVisible, dateFromat, getFilterList} from "../../AppObjects";
import PropTypes from 'prop-types';
import ItemsSelector from "../ItemsSelector";

export default class SalaryFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedUsers: [],
      startDate: null,
      endDate: null,
    }
  }

  handleDateChange(startDate, endDate) {
    const { selectedAttributes, selectedUsers } = this.state;
    this.setState({ startDate, endDate });
    this.doFilter(startDate, endDate, selectedUsers);
  }

  doFilter(startDate, endDate, users) {
    this.props.onFilter({
      where: {
        $or: endDate !== null ? {
          startDate: {
            $between: [startDate.format(dateFromat), endDate.format(dateFromat)]
          },
          endDate: {
            $between: [startDate.format(dateFromat), endDate.format(dateFromat)]
          },
          $and: {
            startDate: {
              $lte: endDate.format(dateFromat)
            },
            endDate: {
              $gte: endDate.format(dateFromat)
            },
          },
        } : undefined,
        userId: users.length === 0 ? undefined : users,
      }
    })
  }

  render() {
    const { startDate, endDate, selectedUsers } = this.state;
    return (
      <div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll filterStyle'>
        <Row>
          <Col sm={3}>
            <DateRangePicker
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
              startDatePlaceholderText='Začátek'
              endDatePlaceholderText='Konec'
              displayFormat={ dateFormatVisible }
              small
              isOutsideRange = {() => false}
              minimumNights={0}
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => this.handleDateChange( startDate, endDate )} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            />
          </Col>
          <Col sm={3}>
            <ItemsSelector
              value={'nameSurname'}
              defaultQuery={{attributes: ['name', 'id', 'surname', 'nameSurname'] }}
              preview={SmallUserPreview}
              listChanged={(list, isFromInit) => {
                this.setState({
                  selectedUsers: list,
                });
                if (!isFromInit)
                  this.doFilter(startDate, endDate, list);
              }}
              appendItemsToStart={[
                { nameSurname: 'Všichni', name: 'Všichni', surname: '', id: 0 },
              ]}
              filterEnable
              isFilterable
              otherPreviewProps={{
                style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
              }}
              itemsToFetch={'users'}/>
          </Col>
        </Row>
      </div>
    )
  }
}

SalaryFilter.propTypes = {
  items: PropTypes.array.isRequired,
  ...BaseFilterPropTypes,
};

