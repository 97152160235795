import React, {Component} from "react";
import FontAwesome from "react-fontawesome";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import PropTypes from "prop-types";

export class YesNoDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  render() {
    const { fontSize } = this.props;
    return (
      <>
        <button onClick={(e) => {
          e.preventDefault();
          this.setState({ show: true })
        }}
                className='transparentButton'>
          <FontAwesome style={{ fontSize: fontSize }} name='fas fa-trash'/>
        </button>
        <Modal show={this.state.show}>
          <ModalHeader>
            <Modal.Title>Upozornění</Modal.Title>
          </ModalHeader>
          <ModalBody>
            {this.props.message}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ show: false })}> Ne</Button>
            <Button onClick={() => {
              this.props.onYes();
              this.setState({ show: false });
            }
            }> Ano</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

YesNoDialog.propTypes = {
  onYes: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  fontSize: PropTypes.string,
};

YesNoDialog.defaultProps = {
  fontSize: '20px',
};