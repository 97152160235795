import * as React from 'react';
import {DiagramEngine, DiagramModel} from '@projectstorm/react-diagrams';
import Diagram, {createCmlEngine, getFirstProcessPort, getTargetPorts} from "../stormReactDiagram/Diagram";
import {CanvasModel, Toolkit} from "@projectstorm/react-canvas-core";
import {TaskNodeModel} from "../stormReactDiagram/components/nodes/taskDescriptionNodes/TaskDescriptionModels";
import {CmlPortModel} from "../stormReactDiagram/components/nodes/ports/CmlPortModel";
import { Row, Col, Checkbox, ButtonGroup, Button } from 'react-bootstrap';
import styled from "@emotion/styled";
import SubmitButton from "../../loginsrc/SubmitButton";
import Attachments, {AttachmentsItemChanged} from "../other/Attachments";
import {EndNodeModel} from "../stormReactDiagram/components/nodes/flowControlNodes/FlowControlModels";
import { TaskEditState } from './Edit';
import {FileObject} from "../other/FileViewer";
import moment from "moment";
import {SmallUserPreview} from "../users/Preview";
import {AuthenticateState} from "@cml/redux-store";
import {connect} from "react-redux";

export interface TaskEditablePreviewProps {
    isForDiagramPreview: boolean;
    baseState?: TaskEditState | TaskObject;
    diagramModel?: ReturnType<CanvasModel['serialize']>;
    onNodeSelected?: (id: string | undefined) => void;
    onItemChange?: (item: object, saveRequested?: boolean) => void;
    disabled?: boolean;
    authenticate: AuthenticateState,
}

const mapStateToProps = (state: { authenticate: AuthenticateState })  => {
    return {
        authenticate: state.authenticate,
    }
};

export interface TaskObject {
    id: string | number | undefined;
    diagramModelId: string | number;
    diagramModel?: ReturnType<CanvasModel['serialize']>;
}

export interface MoveObject {
    port: CmlPortModel;
    forward: boolean;
}

export interface TaskCheckObject {
    id: string;
    nodeId: string | undefined;
    done: boolean;
}

export const historyChangeType = {
  add: 0,
  update: 1,
  delete: 2,
};

const historyChangeTypeTranslation = [
    'přidal',
    'upravil',
    'vymazal',
];

const historyChangeTypeColors = [
    'darkGreen',
    'darkOrange',
    'darkRed',
];

const historyItemType = {
  subTask: 0,
  state: 1,
  attachment: 2,
};

export interface TaskHistoryObject {
    user: {
        name: string,
        surname: string,
        id: number,
    };
    date: string;
    type: number;
    item: number;
    itemId: string;
    text: string;
    description: object;
}

export interface TaskEditablePreviewState {
    actualNodeId: string | undefined;
    actualSelectedPort: MoveObject | null;
    attachments: {
        id?: number | string | undefined,
        listOfFiles: File[] | FileObject[],
    };
    taskChangesHistory: TaskHistoryObject[];
    taskState: {
        tasksDone: TaskCheckObject[],
    };
    isConfirmed: number | boolean;
    stateChangesAt: string;
}

export const S = {
    status: styled.div<{ background: string | undefined; color: string | undefined }>`
		background-color: ${p => p.background};
		padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 5px;
        margin: 5px;
        width: fit-content;
		color: ${p => (p.color ? p.color : 'black')};
	`,
};

class TaskEditablePreview extends React.Component<TaskEditablePreviewProps, TaskEditablePreviewState> {
    static defaultProps = {
        disabled: false,
    };
    engine: DiagramEngine;
    node: TaskNodeModel | undefined;
    private historyContainer: React.RefObject<HTMLDivElement>;
    constructor(props: TaskEditablePreviewProps) {
        super(props);
        this.state = {
            actualNodeId: '',
            actualSelectedPort: null,
            attachments: {
                listOfFiles: [],
            },
            taskState: {
                tasksDone: [],
            },
            taskChangesHistory: [],
            isConfirmed: false,
            stateChangesAt: '',
        };
        this.engine = createCmlEngine();
        this.historyContainer = React.createRef();
    }

    componentWillMount(): void {
        if (this.props.diagramModel) {
            this.checkFirstNode(this.props.diagramModel);
        }
        if (this.props.onItemChange) {
            this.setState({
                ...this.state,
                ...this.props.baseState,
            })
        }
    }

    checkFirstNode(modelData: ReturnType<CanvasModel['serialize']>) {
        const model = new DiagramModel();
        model.deserializeModel(modelData, this.engine);
        this.engine.setModel(model);

        setTimeout(() => {
            if (this.state.actualNodeId !== ''
                && typeof this.state.actualNodeId !== 'undefined' && this.state.actualNodeId !== null) {
                this.node = model.getNode(this.state.actualNodeId) as TaskNodeModel;
                this.setState( {
                    actualNodeId:  this.node.getOptions().id,
                });
                if (this.props.onItemChange) {
                    this.props.onItemChange({
                        actualStateName: this.node.getOptions().name,
                    });
                }
            } else {
                const ports = getFirstProcessPort(this.engine);
                if (ports.length > 0) {
                    this.node = ports[0].getParent() as TaskNodeModel;
                    if (this.props.onNodeSelected)
                        this.props.onNodeSelected(this.node.getOptions().id);
                    this.setState( {
                        actualNodeId:  this.node.getOptions().id,
                    });
                    if (this.props.onItemChange) {
                        this.props.onItemChange({
                            actualNodeId:  this.node.getOptions().id,
                            actualStateName: this.node.getOptions().name,
                        });
                    }
                }
            }

        }, 100);
    }

    componentWillUpdate(nextProps: Readonly<TaskEditablePreviewProps>, nextState: Readonly<TaskEditablePreviewState>, nextContext: any): void {
        if (nextProps.diagramModel && this.props.diagramModel !== nextProps.diagramModel && this.state.actualNodeId === '') {
            this.checkFirstNode(nextProps.diagramModel);
        } else if (nextProps.diagramModel && this.props.diagramModel !== nextProps.diagramModel) {
            const model = new DiagramModel();
            model.deserializeModel(nextProps.diagramModel, this.engine);
            setTimeout(() => {
                this.engine.setModel(model);
                if (this.state.actualNodeId)
                {
                    this.node = model.getNode(this.state.actualNodeId) as TaskNodeModel;
                    this.forceUpdate();
                }
            }, 100);
        }
        if (nextProps.baseState) {
            if((nextProps.baseState as TaskEditState).attachments !== this.state.attachments) {
                this.setState({attachments: (nextProps.baseState as TaskEditState).attachments})
            }
        }
    }

    componentDidMount(): void {
        if (this.historyContainer.current && !this.props.isForDiagramPreview)
            this.historyContainer.current.scrollIntoView({ behavior: "smooth" });
    }

    componentDidUpdate(prevProps: Readonly<TaskEditablePreviewProps>, prevState: Readonly<TaskEditablePreviewState>, snapshot?: any): void {
        if (this.historyContainer.current && !this.props.isForDiagramPreview)
            this.historyContainer.current.scrollIntoView({ behavior: "smooth" });
    }

    putStateHistory(text: string, changeType: number, nodeId: string) {
        const { taskChangesHistory } = this.state;
        taskChangesHistory.push({
            itemId: nodeId,
            item: historyItemType.state,
            text: text,
            description: {},
            user: this.getUser(),
            date: moment().format("DD.MM.YYYY HH:mm"),
            type: changeType,
        });
        this.setState({ taskChangesHistory });
        return taskChangesHistory;
    }

    changeTaskProgress() {
        if (!this.state.actualSelectedPort && !this.state.isConfirmed) {
            return;
        }
        const { taskChangesHistory } = this.state;
        if (this.state.isConfirmed) {
            this.setState({ isConfirmed: false, stateChangesAt: moment().format("YYYY-MM-DD HH:mm") });
            if (this.props.onItemChange) {
                this.props.onItemChange({
                    isConfirmed: false,
                    stateChangesAt: moment().format("YYYY-MM-DD HH:mm"),
                    taskChangesHistory: this.putStateHistory('Znovu otevřeno', historyChangeType.update, '')}, true);
            }
        }

        if (this.state.isConfirmed && this.state.actualNodeId) {
            const node = this.engine.getModel().getNode(this.state.actualNodeId);
            if (node) {
                this.node = node as TaskNodeModel;
            }
            return;
        }
        if (!this.state.actualSelectedPort) {
            return;
        }
        const port = this.state.actualSelectedPort.port;
        const forward = this.state.actualSelectedPort.forward;
        const ports = forward ? getTargetPorts(port) : [port];
        const taskPorts = ports.filter(o => o.getParent() instanceof TaskNodeModel);
        const endPorts = ports.filter(o => o.getParent() instanceof EndNodeModel);
        const actualStepName = (this.node ? this.node.getOptions().name : '') as string;
        const actualStepId = (this.node ? this.node.getOptions().id : '') as string;
        if (taskPorts.length === 1) {
            this.node = taskPorts[0].getParent() as TaskNodeModel;
            if (this.props.onNodeSelected)
                this.props.onNodeSelected(this.node.getOptions().id);
            this.setState({
                stateChangesAt: moment().format("YYYY-MM-DD HH:mm"),
                actualNodeId: this.node.getOptions().id,
                actualSelectedPort: null,
            });
            if (this.props.onItemChange) {
                this.props.onItemChange({
                    stateChangesAt: moment().format("YYYY-MM-DD HH:mm"),
                    actualNodeId: this.node.getOptions().id,
                    actualStateName: this.node.getOptions().name,
                    userLinks: this.node.getSetting().usersList.map(o => ({
                        userId: (o as { id: number }).id ,
                        user: {
                            ...o as object,
                        }
                    })),
                    taskChangesHistory: this.putStateHistory(
                        `Stav úkolu změněn z "${actualStepName.toUpperCase()}" na "${(this.node.getOptions().name as string).toUpperCase()}"`, historyChangeType.update, actualStepId),
                }, true);
            }
        }
        if (endPorts.length === 1) {
            if(endPorts[0].getOptions().in) {
                this.setState({
                    isConfirmed: true,
                    stateChangesAt: moment().format("YYYY-MM-DD HH:mm"),
                });
                if (this.props.onItemChange) {
                    this.props.onItemChange({
                        isConfirmed: true,
                        stateChangesAt: moment().format("YYYY-MM-DD HH:mm"),
                        taskChangesHistory: this.putStateHistory('Ukol dokončen', historyChangeType.add, '')
                    }, true);
                }
            }
        }
    }

    prepareForChange(port: CmlPortModel, forward: boolean) {
        this.setState({ actualSelectedPort: { port, forward }});
    }

    renderOutputs() {
        const { isConfirmed, taskChangesHistory } = this.state;
        if (this.node) {
            let i = 0;
            return <ButtonGroup vertical>
                {
                    this.node.getInPorts().map((port: CmlPortModel) => {
                        const ports = getTargetPorts(port).filter(o => o.getParent() instanceof TaskNodeModel && !o.getOptions().in);
                        const toRenter = [] as JSX.Element[];
                        ports.forEach(p => {
                            if (taskChangesHistory.filter(o => o.itemId === p.getParent().getOptions().id).length > 0) {
                                toRenter.push(<Button disabled={isConfirmed as boolean || this.props.disabled} bsStyle={
                                    this.state.actualSelectedPort ? (this.state.actualSelectedPort.port === p ? 'success' : 'default') : 'default'
                                } key={i++} onClick={() => {
                                    this.prepareForChange(p, false);
                                }}>{`Zpět na: "${(p.getParent() as TaskNodeModel).getOptions().name}"`}</Button>)
                            }
                        });

                        return toRenter;
                    })
                }
                {
                    this.node.getOutPorts().map((port: CmlPortModel) => {
                        return <Button disabled={isConfirmed as boolean || this.props.disabled} bsStyle={
                            this.state.actualSelectedPort ? (this.state.actualSelectedPort.port === port ? 'success' : 'default') : 'default'
                        } key={i++} onClick={() => {
                            this.prepareForChange(port, true);
                        }}>{port.getOptions().label}</Button>;
                    })
                }
            </ButtonGroup>
        }
        return null;
    }

    taskCheckedChange(e: React.FormEvent<Checkbox>, id: string, nodeId: string | undefined) {
        if(e.target) {
            const taskChangesHistory = this.state.taskChangesHistory;
            const tasksDone = this.state.taskState.tasksDone;
            const item = tasksDone.find(o => o.id === id);
            if (item) {
                item.done = (e.target as HTMLInputElement).checked;
            } else {
                tasksDone.push({
                    id: id,
                    nodeId: nodeId,
                    done: (e.target as HTMLInputElement).checked,
                });
            }

            taskChangesHistory.push({
                itemId:  id,
                type: historyChangeType.update,
                date: moment().format("DD.MM.YYYY HH:mm"),
                user: this.getUser(),
                item: historyItemType.subTask,
                description: {},
                text: `${historyChangeTypeTranslation[historyChangeType.update][0].toUpperCase()
                + historyChangeTypeTranslation[historyChangeType.update].slice(1)} na ${(e.target as HTMLInputElement).checked ? 'SPLNĚNO' : 'NESPLNĚNO'} úkol: "${
                    // @ts-ignore
                    this.node.getSetting().taskList.find(o => o.id === id).description
                }"`,
            });
            this.setState({ taskState: { ...this.state.taskState, ...tasksDone }, taskChangesHistory });
            if (this.props.onItemChange) {
                this.props.onItemChange({ taskState: { ...this.state.taskState, ...tasksDone }, taskChangesHistory });
            }
        }
    }

    getTaskDoneValue(id: string) {
        const tasksDone = this.state.taskState.tasksDone;
        const item = tasksDone.find(o => o.id === id);
        if (item) {
            return item.done;
        }
        return false;
    }

    getUser() {
        const { authenticate } = this.props;
        return {
            // @ts-ignore
            name: authenticate.loggedUser.name,
            // @ts-ignore
            surname: authenticate.loggedUser.surname,
            // @ts-ignore
            id: authenticate.loggedUser.id
        };
    }

    attachmentsListChanged(filesList: File[] | FileObject[], changes: AttachmentsItemChanged[]) {
        const attachments = this.state.attachments;
        const taskChangesHistory = this.state.taskChangesHistory;
        if (changes) {
            changes.forEach(item => {
                taskChangesHistory.push({
                    date: moment().format("DD.MM.YYYY HH:mm"),
                    description: {},
                    item: historyItemType.attachment,
                    user: this.getUser(),
                    itemId: '',
                    type: item.changeType,
                    text: `${historyChangeTypeTranslation[item.changeType][0].toUpperCase() 
                    + historyChangeTypeTranslation[item.changeType].slice(1)} přílohu: "${item.name}"`,
                })
            })
        }
        attachments.listOfFiles = filesList;
        this.setState({ attachments, taskChangesHistory });
        if (this.props.onItemChange) {
            this.props.onItemChange({ attachments, taskChangesHistory });
        }
    }

    render() {
        const { isConfirmed, actualSelectedPort, taskChangesHistory, taskState } = this.state;
        return <div style={{ borderTop: '2px solid black', borderBottom: '2px solid black', paddingBottom: '5px'}}>
            <Row style={{ opacity: isConfirmed ? 0.7 : 1 }}>
                <Col sm={3}>
                    <h4 style={{ display: 'inline-block' }}>Aktuální stav: </h4>
                    <S.status style={{ display: 'inline-block' }} background={this.node ? this.node.getOptions().color : '#000000'} color='white'>
                        {this.node ? this.node.getOptions().name : null}
                    </S.status>
                    {
                        this.props.isForDiagramPreview ? null : <div style={{height: '200px'}}>
                            <Diagram readonly={!this.props.isForDiagramPreview} externDiagram={this.engine} defaultModel={this.props.diagramModel}/>
                        </div>
                    }
                </Col>
                <Col sm={3}>
                    <h4>Historie změn</h4>
                    <div className='historyContainer'>
                        {
                            taskChangesHistory.map( (item,index) => {
                                return <div key={index} className={'history' + (index % 2 === 0 ? ' even' : '')}>
                                    <span>{item.date}</span>
                                    <span><SmallUserPreview style={{marginLeft: '10px', display: 'inline-block', marginBottom: '5px'}} item={item.user}/></span>
                                    <div style={{color: historyChangeTypeColors[item.type]}}>{item.text}</div>
                                </div>
                            })
                        }
                        <div ref={this.historyContainer} />
                    </div>
                </Col>
                <Col sm={3}>

                </Col>
                <Col sm={3}>
                    <Row>
                        <Col sm={12}>
                            <h4>Úkoly pro splnění</h4>
                            {
                                this.node ? (this.node.getSetting().taskList.map((task) => <Checkbox
                                        checked={this.getTaskDoneValue(task.id)}
                                        disabled={isConfirmed as boolean || this.props.disabled}
                                        onChange={e => this.taskCheckedChange(e, task.id, this.node ? this.node.getOptions().id : '')} key={task.id}>
                                        {task.description}
                                    </Checkbox>))
                                    : null
                            }
                        </Col>
                        <Col sm={12}>
                            <h4>Možnosti ukončení</h4>

                            {this.renderOutputs()}
                            <SubmitButton disabled={(actualSelectedPort === null && !isConfirmed) || this.props.disabled}
                                          onClick={() => this.changeTaskProgress()}

                                          bsClass='btn btn-primary btn-block' >{isConfirmed ? 'Znovu otevřít' : ('Potvrdit' + (this.props.isForDiagramPreview ? '' : ' & Uložit'))}</SubmitButton>
                        </Col>
                    </Row>

                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <h4>Přílohy</h4>
                    <Attachments askBeforeDeleteFile={true} list={this.state.attachments ? this.state.attachments.listOfFiles : []}
                                 updating={!this.props.disabled}
                                 onListChanged={(filesList, changes) => this.attachmentsListChanged(filesList, changes as AttachmentsItemChanged[])}/>
                </Col>
            </Row>
        </div>
    }
}

export default connect(mapStateToProps)(TaskEditablePreview);