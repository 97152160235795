import React, {Component} from 'react';
import {BaseEditPropTypes} from '../CommonListContainer';
import request from 'axios';
import {BackHeader} from "../Setting";
import {LoadingOrRender} from "../../loginsrc/Profile";
import {
  Button,
  Col,
  ControlLabel, Dropdown,
  Form,
  FormControl,
  FormGroup, MenuItem,
  Row
} from "react-bootstrap";
import SubmitButton from "../../loginsrc/SubmitButton";
import PropTypes from "prop-types";
import {CanView} from "../Main";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';

export default class AddressBookEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      requestActive: this.props.disableUrlCheck ? false : (this.props.match.params.id === 'new' ? false : true),
      aresRequestActive: false,
      submitActive: false,
      ic: '',
      dic: '',
      company: '',
      address: '',
      psc: '',
      village: '',
      country: '',
      email: '',
      telnumb: '',
      web: '',
    }
  }

  componentWillMount() {
    if (this.props.disableUrlCheck) {
      this.setState({ requestActive: false });
      return;
    }
    if (this.props.match.params.id !== 'new' ) {
      request.get('/items/addressbook/' + this.props.match.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState(res.data);
            this.setState({
              requestActive: false,
            });
          }
        })
        .catch(err => {
          this.setState({ requestActive: false })
        })
    } else {
      this.setState({ requestActive: false })
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.ic.length < 5) {
      store.dispatch(show({
        title: 'Error',
        level: 'error',
        autoDismiss: 3,
        children: (
          <div>
            IČ není spávně zadáno
          </div>
        ),
      }, 'error'));
      return;
    }
    this.props.onSubmit(this.props.disableUrlCheck ? 'new' : this.props.match.params.id, this, this.state);
  }

  render() {
    return (
      <div>
        <div className='editForm'>
          <ContentWithFixedHeader drawOnlyChildren={this.props.disableUrlCheck} header={
            <CanView visible={!this.props.disableUrlCheck}>
              <BackHeader
                fontSize='18px'
                headerText={this.state.company}
                onBackClick={() => this.props.history.replace(
                  this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
            </CanView>
          }>
            <div className='nest'>
              <LoadingOrRender requestActive={this.state.requestActive}>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                  <Row>
                    <Col sm={4}>
                      <h3>Základní údaje</h3>
                      <Row>
                        <Col sm={6}>
                          <FormGroup controlId="ic">
                            <ControlLabel>IČ</ControlLabel>
                            <Row>
                              <Col sm={9}>
                                <FormControl type="text"
                                             value={this.state.ic}
                                             onChange={(e) => this.setState({ ic: e.target.value })}
                                />
                              </Col>
                              <Col sm={3}>
                                <LoadingOrRender requestActive={this.state.aresRequestActive}>
                                  <Button onClick={() => {
                                    this.setState({
                                      aresRequestActive: true,
                                    });
                                    request.get('/items/addressbookares', {
                                      params: {ico: this.state.ic}
                                    }).then((res) => {
                                        if (res.status === 200) {
                                          this.setState({
                                            aresRequestActive: false,
                                          });
                                          this.setState(res.data);
                                        }
                                      })
                                      .catch((err) => {
                                        this.setState({
                                          aresRequestActive: false,
                                        });
                                        store.dispatch(show({
                                          title: 'Error',
                                          level: 'error',
                                          autoDismiss: 3,
                                          children: (
                                            <div>
                                              Nenalezen žádný podnikatelský subjekt
                                            </div>
                                          ),
                                        }, 'error'));
                                      })
                                  }}>
                                    Ares
                                  </Button>
                                </LoadingOrRender>
                              </Col>
                            </Row>


                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup controlId="dic">
                            <ControlLabel>DIČ</ControlLabel>
                            <FormControl type="text"
                                         value={this.state.dic}
                                         onChange={(e) => this.setState({ dic: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup controlId="company">
                            <ControlLabel>Firma</ControlLabel>
                            <FormControl type="text"
                                         value={this.state.company}
                                         onChange={(e) => this.setState({ company: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <h3>Adresa</h3>
                      <Row>
                        <Col sm={12}>
                          <FormGroup controlId="street">
                            <ControlLabel>Ulice</ControlLabel>
                            <FormControl type="text"
                                         value={this.state.address}
                                         onChange={(e) => this.setState({ address: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={4}>
                          <FormGroup controlId="psc">
                            <ControlLabel>PSČ</ControlLabel>
                            <FormControl type="text"
                                         value={this.state.psc}
                                         onChange={(e) => this.setState({ psc: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={8}>
                          <FormGroup controlId="village">
                            <ControlLabel>Obec</ControlLabel>
                            <FormControl type="text"
                                         value={this.state.village}
                                         onChange={(e) => this.setState({ village: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup controlId="country">
                            <ControlLabel>Země</ControlLabel>
                            <FormControl type="text"
                                         value={this.state.country}
                                         onChange={(e) => this.setState({ country: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={4}>
                      <h3>Kontakty</h3>
                      <Row>
                        <Col sm={12}>
                          <FormGroup controlId="email">
                            <ControlLabel>Email</ControlLabel>
                            <FormControl type="text"
                                         value={this.state.email}
                                         onChange={(e) => this.setState({ email: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup controlId="telNumb">
                            <ControlLabel>Mobil</ControlLabel>
                            <FormControl type="text"
                                         value={this.state.telnumb}
                                         onChange={(e) => this.setState({ telnumb: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          <FormGroup controlId="country">
                            <ControlLabel>Web</ControlLabel>
                            <FormControl type="text"
                                         value={this.state.web}
                                         onChange={(e) => this.setState({ web: e.target.value })}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <SubmitButton onClick={this.props.disableUrlCheck ? (e) => this.handleSubmit(e) : null} bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                    Uložit
                  </SubmitButton>
                </Form>
              </LoadingOrRender>
            </div>
          </ContentWithFixedHeader>
        </div>
      </div>);
  }
}

AddressBookEdit.propTypes = {
  disableUrlCheck: PropTypes.bool,
  ...BaseEditPropTypes
};

AddressBookEdit.defaultProps = {
  disableUrlCheck: false,
};
