import moment, {Moment} from "moment";
import {Attendance, Attributes, DataRow, EDataRow, Vehicles} from "@cml/models";
import {dateFromat, getFloatTime} from "../../../AppObjects";
import {createDataRow, getTimeDiff} from "../../dispatching/components/datarowBase";
import ItemsSelector from "../../ItemsSelector";
import AttributeEdit from "../../attributes/Edit";
import AttributePreview from "../../attributes/Preview";
import Circle from "../../../generalComponents/Circle";
import React, {useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";
import request from "axios";
import {LoadingOrRender} from "../../../loginsrc/Profile";
import {getAttributes, getVehicles} from "../../../fetchApi/dispatching";
import {BaseCalculationItem, IAttendanceAttributesCustom, IDispatchingAttributesCustom, IVehicleData} from "@cml/types";
import {useContextSelector} from "use-context-selector";
import {SalaryStateContext, setAttendanceChanged, setAttendanceError, setBaseState} from "../reducer";
import {useSelector} from "react-redux";
import {DataRowsState} from "@cml/redux-store";
import ReactLoading from "react-loading";
import { filterVehicleData } from "../../dispatching/components/ResultRows";
import _ from "lodash";

interface Props {
  user: {id: number | string}
  dataRows: EDataRow[];
  startDate: Moment;
  endDate: Moment;
  rowsAttendanceChanged: (rows: EDataRow[]) => void;
}

interface Activity {
  type: string | number;
  attributeId: number;
  date: string;
  autoSelected: boolean;
  autoReplaced: boolean;
}

export const getAttendancesGroups = (dataRows: EDataRow[], user: {id: number | string}) => {
  const attendances = dataRows.filter(o => o.isAttendance);
  const sorted = attendances.sort((a,b) =>
    moment(a.date).valueOf() - moment(b.date).valueOf())
  const groups: Attendance[] = [];
  if (sorted.length > 0) {
    sorted.forEach((s, i) => {
      const date = moment(s.date);
      if (date.isBusinessDay()) {
        let shouldPush = false;
        if (groups.length === 0) {
          shouldPush = true;
        } else {
          let g = groups[groups.length -1];
          if (g.attributeId === s.attribute.id) {
            const findIndex = dataRows.filter(d => !d.isAttendance).findIndex(d =>
              moment(d.date).isBetween(moment(g.from), moment(s.date), 'days', '[]')
            )
            if (findIndex === -1) {
              // @ts-ignore
              groups[groups.length -1] = {
                ...g,
                to: s.date,
                workingDays: moment(g.from, dateFromat)
                  .businessDiff(moment(s.date,dateFromat).businessAdd(1))
              };
            } else {
              shouldPush = true;
            }

          } else {
            shouldPush = true;
          }
        }
        if (shouldPush) {
          // @ts-ignore
          groups.push({
            id: -1,
            userId: Number(user.id),
            isPartDay: false,
            timeTo: '00:00',
            timeFrom: '00:00',
            attributeId: s.attribute.id,
            from: s.date,
            to: s.date,
            spaceId: s.spaceId,
            workingDays: 1,
          });
        }
      }
    })
  }
  return {
    items: groups,
    toDelete: dataRows.filter(o => o.isAttendance && o.id > 0).map(d => d.id)
  }
}

const autoSelectEnabled = true;

export default function MonthAttendance({dataRows,
                                          rowsAttendanceChanged,
                                          endDate, startDate, user}: Props) {
  const [requestActive, setRequestActive] = useState(false);

  const onlyEnabledRows = dataRows.filter(d => (d.isAttendance || (d.attribute && !(d.attribute.customOptions as
    IDispatchingAttributesCustom).disableUsageInSalary)));

  const sumTimePerDay = (date: string) => {
    return onlyEnabledRows.filter(d => d.date === date).reduce((acc, d) =>
      acc += (d.isAttendance ? 0 : getTimeDiff(d).sum), 0)
  }

  const { attributes: attendanceAttributes, isValidating: attendanceValidating } = getAttributes('attendance', {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });

  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { spaceId: [1 ,3], allDetails: 0, onlyForDispatching: 1, appendArchived: true  }}, {
      revalidateOnFocus: false,
      revalidateOnMount: true,
    });



  const dispatch = useContextSelector(SalaryStateContext, v => v.dispatch);

  const attendanceChanged = useContextSelector(SalaryStateContext, v => v.state.attendanceChanged);

  const totalExtraHours = useContextSelector(SalaryStateContext, v => v.state.totalExtraHours);

  const activityAutoSelectLength = useRef(-1);

  const totalGpsHours = useRef(0);

  const rowExistsButGpsNotRef = useRef(0);

  const vehiclesData = useSelector((state: {dispatching: DataRowsState}) =>
    state.dispatching.vehiclesData)

  const vehiclesDataLoading = useSelector((state: {dispatching: DataRowsState}) =>
    state.dispatching.vehiclesDataLoading)

  useEffect(() => {
    dispatch(setAttendanceError(activityAutoSelectLength.current !== 0));
  }, [activityAutoSelectLength.current])

  useEffect(() => {
    dispatch(setBaseState({
      totalGpsHours: totalGpsHours.current,
    }))
  }, [totalGpsHours.current, vehiclesDataLoading])

  useEffect(() => {
    dispatch(setBaseState({
      rowExistsButGpsNot: (rowExistsButGpsNotRef.current !== 0),
    }))
  }, [rowExistsButGpsNotRef.current, vehiclesDataLoading])

  if(attendanceValidating || !attendanceAttributes || !vehicles)
    return null;

  const start = startDate.clone();
  const dates = [];
  const activity: Activity[] = [];
  const gpsActivities = [];
  const noRowButGps: any[] = [];
  let rowExistsButGpsNotCounter = 0;
  let extraHoursBudget = totalExtraHours;
  while (start <= endDate) {
    let data = filterVehicleData(vehiclesData,
      {date: start.format(dateFromat)},
      user, true);


    const date = start.format("ddd DD.MM");
    dates.push({
      date,
      isPublicHoliday: start.isHoliday(),
    });
    const found = onlyEnabledRows.find(d => d.date === start.format(dateFromat));
    const activityItem: Activity = {
      type: '',
      attributeId: 0,
      date: '',
      autoSelected: false,
      autoReplaced: false,
    }
    // data which not belong to founded row
    const notFoundDataGps = found ? data.filter((d: { vehicleId: string; }) => d.vehicleId
      !== ((found.vehicle as Vehicles) ? (found.vehicle as Vehicles).foreignApiVehicleId : null)) : data;
    if (notFoundDataGps.length > 0) {
      const uniqueVehicles = _.uniq(notFoundDataGps.map((o: { vehicleId: any; }) => o.vehicleId));
      const foundedDataRows = dataRows.filter(d => d.date === start.format(dateFromat));
      uniqueVehicles.forEach(v => {
        const veh = vehicles.find(_v => _v.foreignApiVehicleId === v);
        const vehData = notFoundDataGps.filter((o: { vehicleId: string; }) => o.vehicleId === v);
        if (veh && !foundedDataRows.find(f => f.vehicleId === veh.id)) {
          noRowButGps.push(createDataRow({
            from: getFloatTime(moment(vehData[0].timestamp).add(2, 'hours').format("HH:mm")),
            to: getFloatTime(moment(vehData[vehData.length - 1].timestamp).add(2, 'hours').format("HH:mm")),
            date: start.format(dateFromat),
            userId: user.id as number,
            user,
            vehicleId: veh ? veh.id : undefined,
            vehicle: veh ? veh : undefined,
          }))
        }
      })
    }
    if (found && data.length === 0 && !found.isAttendance) {
      rowExistsButGpsNotCounter++;
    }
    gpsActivities.push({
      date: start.format(dateFromat),
      hours: (found && !found.isAttendance) ? (data.length > 0 ? moment.duration(moment(data[data.length - 1].timestamp)
        .diff(moment(data[0].timestamp))).asHours() : 0) : 0
    })
    if (start.isBusinessDay()) {
      activityItem.type = found ? (!found.isAttendance ? sumTimePerDay(start.format(dateFromat)) : "D") : "?";
    } else {
      activityItem.type = found ? (!found.isAttendance ? sumTimePerDay(start.format(dateFromat)) : "D") : "-";
    }
    if (autoSelectEnabled) {
      const attr =
        extraHoursBudget > 8 ? attendanceAttributes.find(a =>
          (a.customOptions as IAttendanceAttributesCustom).overtimeAttribute):
        attendanceAttributes.find(a => (a.customOptions as IAttendanceAttributesCustom).basicVacation);
      if (attr && !found && start.isBusinessDay()) {
        activityItem.attributeId = attr.id;
        activityItem.autoSelected = true;
        extraHoursBudget -= 8;
      } else {
        if (found && found.isAttendance && attr && found.attributeId !== attr.id) {
          if((found.attribute.customOptions as IAttendanceAttributesCustom).basicVacation) {
            activityItem.attributeId = attr.id;
            activityItem.autoSelected = true;
            activityItem.autoReplaced = true;
            extraHoursBudget -= 8;
          } else {
            activityItem.attributeId = (found && found.isAttendance) ? found.attribute.id : 0;
          }

        } else {
          activityItem.attributeId = (found && found.isAttendance) ? found.attribute.id : 0;
        }

      }
    } else {
      activityItem.attributeId = (found && found.isAttendance) ? found.attribute.id : 0;
    }

    activityItem.date = start.format(dateFromat);
    activity.push(activityItem);
    start.add( 1, 'days');
    activityAutoSelectLength.current = activity.filter(a => a.autoSelected).length;
  }

  if (noRowButGps.length > 0) {
    rowsAttendanceChanged(dataRows.slice()
      .concat(noRowButGps as EDataRow[])
      .sort((a,b) => moment(a.date).valueOf() - moment(b.date).valueOf()))
  }

  rowExistsButGpsNotRef.current = rowExistsButGpsNotCounter;
  totalGpsHours.current = gpsActivities.reduce((acc, a) => acc += a.hours, 0);

  const onSelect = (index: number) => (item: Attributes) => {
    const rows = dataRows.slice();
    const foundIndex = dataRows.findIndex(d => d.date === activity[index].date && d.isAttendance)
    if (foundIndex > -1) {
      if (item.id === 0) {
        rows.splice(foundIndex, 1);
      } else {
        rows[foundIndex].attribute = item;
        rows[foundIndex].construction = item.name;
      }

    } else if(item.id > 0) {
      // @ts-ignore
      const attendance: Attendance = {
        userId: Number(user.id),
        isPartDay: false,
        timeTo: '00:00',
        timeFrom: '00:00',
        attributeId: item.id,
        from: activity[index].date,
        to: activity[index].date,
        workingDays: 1,
        attribute: item,
        spaceId: item.spaceId,
      }
      // @ts-ignore
      rows.push({
        isAttendance: true,
        date: activity[index].date,
        construction: attendance.attribute.name,
        ...attendance,
        id: -1,
        from: attendance.isPartDay ? getFloatTime(attendance.timeFrom) : 8,
        to: attendance.isPartDay ? getFloatTime(attendance.timeTo) : 16,
        break: 0,
      })
    }
    dispatch(setAttendanceChanged(true));
    rowsAttendanceChanged(rows.sort((a,b) => moment(a.date).valueOf() - moment(b.date).valueOf()));
  }

  const saveNewAttendance = () => {

    setRequestActive(true);
    request.post('/items/multiAttendance',
      getAttendancesGroups(onlyEnabledRows, user)).then((res) => {
      let attendanceRows: Partial<EDataRow>[] = [];
      res.data.forEach((att: Attendance) => {
        let from = moment(att.from);
        const to = moment(att.to);
        do {
          if (from.isBetween(startDate, endDate, 'days', '[]')
            && from.isBusinessDay()) {
            const attribute = attendanceAttributes.find(a => a.id === att.attributeId);
            if (attribute) {

              attendanceRows.push({
                isAttendance: true,
                date: from.format(dateFromat),
                construction: attribute.name,
                // @ts-ignore
                attribute,
                ...att,
                // @ts-ignore
                originAttendance: {
                  ...att,
                  attribute: attribute,
                },
                from: att.isPartDay ? getFloatTime(att.timeFrom) : 8,
                to: att.isPartDay ? getFloatTime(att.timeTo) : 16,
                break: 0,
              })
            }
          }
          from.add(1, 'days');
        } while(!from.isAfter(to))
      })
      dispatch(setAttendanceChanged(false));
      setRequestActive(false);
      rowsAttendanceChanged(dataRows
        .filter(d => !d.isAttendance).concat(attendanceRows as EDataRow[])
        .sort((a,b) => moment(a.date).valueOf() - moment(b.date).valueOf()));
    }).catch(err => {
      setRequestActive(false);
    })
  }

  const onAutoSelectedConfirm = () => {
    const rows = dataRows.slice();
    activity.filter(a => a.autoSelected).forEach(a => {
      const attr = attendanceAttributes.find(aa => aa.id === a.attributeId);
      if(attr) {
        // @ts-ignore
        const attendance: Attendance = {
          userId: Number(user.id),
          isPartDay: false,
          timeTo: '00:00',
          timeFrom: '00:00',
          attributeId: a.attributeId,
          from: a.date,
          to: a.date,
          workingDays: 1,
          attribute: attr,
          spaceId: attr.spaceId,
        }

        if (a.autoReplaced) {
          const d = rows.find(r => r.date === a.date);
          if(d) {
            d.attributeId = a.attributeId;
            d.attribute = attr;
            d.construction = attr.name;
          }
        } else {
          // @ts-ignore
          rows.push({
            isAttendance: true,
            date: a.date,
            construction: attendance.attribute.name,
            ...attendance,
            originAttendance: attendance,
            id: -1,
            from: attendance.isPartDay ? getFloatTime(attendance.timeFrom) : 8,
            to: attendance.isPartDay ? getFloatTime(attendance.timeTo) : 16,
            break: 0,
          });
        }

        a.autoSelected = false;
      }
    })
    dispatch(setAttendanceChanged(true));
    rowsAttendanceChanged(rows.sort((a,b) => moment(a.date).valueOf() - moment(b.date).valueOf()));
  }
  const salaryCheck = dataRows.filter(o => o.salaryId).length > 0;

  /* @ts-ignore */
  return <table align='right'>
    <tbody>
    <tr>
      {dates.map((d, i) => <th style={{ fontSize: '10px',
        color: d.isPublicHoliday ? 'red' : undefined }} key={i}>{d.date}</th>)}
    </tr>
    <tr>
      {activity.map((a, i) => <td key={i}>{
        (a.type === '?' || a.type === 'D' ) ? <ItemsSelector itemsToFetch={'attributes/attendance'}
                                                             noCaret
                                                             newEditComponent={ AttributeEdit }
                                                             newEditComponentDefaultProps={{
                                                               attributeName: 'attendance',
                                                             }}
                                                             preview={AttributePreview}
                                                             overrideToggleFunc={(item: Attributes) =>
                                                               item.id === 0 ? "?" : <>
                                                                 {a.autoSelected ? "?" : ''}
                                                                 <Circle style={{
                                                                   marginLeft: 0,
                                                                   width: 12, height: 12 }} color={item.color}/>
                                                               </>}
                                                             appendItemsToStart={[{id: 0, name: "?"}]}
                                                             onSelected={onSelect(i)}
          //onSelected={(item) => this.setState({ attributeId: item.id, attribute: item })}
                                                             defaultSelectedId={a.attributeId}
                                                             value={'name'}
        /> : typeof a.type === 'number' ? a.type.toFixed(2) : a.type
      }</td>)}
    </tr>
    {!vehiclesDataLoading ? <tr>
      {gpsActivities.map((a, i) => <td key={i}>{a.hours.toFixed(2)}</td>) }
    </tr> : <ReactLoading type={'spinningBubbles'} color={'#337ab7'} height={10} width={10}/>
    }

    <tr>
      <td colSpan={activity.length - 4}>
        {activity.filter(a => a.autoSelected).length > 0 && <Button
          onClick={onAutoSelectedConfirm}>
          Potvrdit docházku s otazníkem
        </Button>}
      </td>
      <td colSpan={4}>
        {(attendanceChanged && !salaryCheck) && <LoadingOrRender requestActive={requestActive}>
          <Button
            onClick={saveNewAttendance}>
            Uložit docházku
          </Button>
        </LoadingOrRender>}
      </td>
    </tr>
    </tbody>
  </table>
}