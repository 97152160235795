import {Component} from "react";
import {Col, FormControl, MenuItem, Row} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import React from "react";

export default class Filter extends Component {
  render() {
    return <MenuItem disabled>
      <Row style={{minWidth: "200px"}}>
        <Col xs={2}>
          <FontAwesome style={{fontSize: "16px", marginRight: "10px"}} name='fas fa-search'/>
        </Col>
        <Col xs={10}>
          <FormControl
            style={{fontSize: "11px", height: "22px"}}
            value={this.props.filterValue}
            onChange={this.props.onValueChange}
            type='text'/>
        </Col>
      </Row>
    </MenuItem>;
  }
}

Filter.propTypes = {
  filterValue: PropTypes.string,
  onValueChange: PropTypes.func,
};