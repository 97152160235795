import React, {Component} from 'react';
import {BaseEditPropTypes} from '../CommonListContainer';
import request from 'axios';
import {BackHeader} from "../Setting";
import {LoadingOrRender} from "../../loginsrc/Profile";
import {
  Checkbox,
  Col, ControlLabel,
  Form, FormControl, FormGroup, MenuItem,
  Row
} from "react-bootstrap";
import SubmitButton from "../../loginsrc/SubmitButton";
import PropTypes from "prop-types";
import {CanView} from "../Main";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import ItemsSelector from "../ItemsSelector";
import AttributeEdit from "../attributes/Edit";
import {SmallUserPreview} from "../users/Preview";
import UserEdit from "../users/Edit";
import VehicleEdit from "../vehicles/Edit";
import {mapAuthStateToProps} from "../../AppObjects";
import {connect} from "react-redux";
import Button from "react-bootstrap/lib/Button";
import store from "../../redux/store";
import {show} from "react-notification-system-redux";
import RecordComponent from "../RecordsComponent";

class CreditCardEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      userId: 0,
      vehicleId: 0,
      number: '',
      identifier: '',
      pin: '',
      newPin: false,
      showPin: false,
      requestActive: props.disableUrlCheck ? false : (this.props.match.params.id !== 'new'),
      submitActive: false,
      ...props.defaultProps,
    };
    this.decryptedPin = null;
  }

  componentWillMount() {
    if (this.props.disableUrlCheck)
      return;
    if (this.props.match.params.id !== 'new' ) {
      request.get('/items/creditcard/' + this.props.match.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState(res.data);
            this.setState({ requestActive: false });
          }
        })
        .catch(err => {
          this.setState({ requestActive: false });
        })
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const state = JSON.parse(JSON.stringify(this.state));
    state.userId =  this.state.userId === 0 ? null : this.state.userId;
    state.vehicleId =  this.state.vehicleId === 0 ? null : this.state.vehicleId;
    if (!this.state.newPin)
      delete state.pin;
    this.props.onSubmit(this.props.disableUrlCheck ?
      'new' : this.props.match.params.id, this, state);
  }

  getRawPin = () => {
    if (!this.state.showPin) {
      request.get('/items/creditcard/showpin/' + this.props.match.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.decryptedPin = res.data.pin;
            this.setState({ showPin: true });
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            store.dispatch(show({
              title: 'Info',
              level: 'info',
              autoDismiss: 3,
              children: (
                <div>
                  Pin není vyplněn, klikněte na Nový pin
                </div>
              ),
            }, 'info'));
          }
        })
    } else {
      this.setState({ showPin: false });
    }
  }

  render() {
    return (
      <div>
        <ContentWithFixedHeader drawOnlyChildren={this.props.disableUrlCheck} header={
          <CanView visible={!this.props.disableUrlCheck}>
            <BackHeader
              fontSize='18px'
              headerText={this.state.identifier === '' ? 'Nová platební karta' : this.state.identifier}
              onBackClick={() => this.props.history.replace(
                this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
          </CanView>
        }>
          <div className='editForm'>
            <div className='nest'>
              <LoadingOrRender requestActive={this.state.requestActive}>
                <Form onSubmit={(e) => {
                  this.handleSubmit(e);
                }}>
                  <Row>
                    <Col sm={2}>
                      <FormGroup>
                        <ControlLabel style={{ marginTop: '5px' }}>Zodpovědná osoba</ControlLabel>
                        <div>
                          <ItemsSelector itemsToFetch='users'
                                         defaultQuery={{
                                           attributes: ['name', 'id', 'surname', 'nameSurname'] ,
                                         }}
                                         overrideToggleFunc={(user) => this.state.userId > 0 ?
                                           <SmallUserPreview style={{fontSize: '11px', display: 'inline', marginRight: '5px'}}
                                                             item={user}/> : <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                           Nevybrán
                                         </div>}
                                         preview={ SmallUserPreview }
                                         newEditComponent={ UserEdit }
                                         //bsSize="xsmall"
                                         appendItemsToStart={[
                                           {id: 0, nameSurname: 'Nevybrán', name: 'Nevybrán', surname: ''},
                                         ]}
                                         filterEnable
                                         value={'nameSurname'}
                                         onSelected={(user) => this.setState({ userId: user.id, user })}
                                         defaultSelectedId={this.state.userId}/>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col sm={2}>
                      <FormGroup>
                        <ControlLabel style={{ marginTop: '5px' }}>Přiřazené vozidlo</ControlLabel>
                        <div>
                          <ItemsSelector itemsToFetch='vehicles'
                                         defaultQuery={{
                                           allDetails: 0,
                                         }}
                                         overrideToggleFunc={(vehicle) => this.state.vehicleId > 0 ?
                                           vehicle.identifier : <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                             Nevybrán
                                           </div>}
                                         newEditComponent={ VehicleEdit }
                                         appendItemsToStart={[
                                           {id: 0, identifier: 'Nevybrán'},
                                         ]}
                                         filterEnable
                                         value={'identifier'}
                                         onSelected={(vehicle) => this.setState({ vehicleId: vehicle.id, vehicle })}
                                         defaultSelectedId={this.state.vehicleId}/>
                        </div>
                      </FormGroup>
                    </Col>
                    <CanView visible={this.props.authenticate.actualUserRole.creditCard.pin}>
                      <Col sm={2}>
                        <FormGroup inline>
                          <ControlLabel>Pin</ControlLabel>
                          <FormControl type={this.state.showPin || this.state.newPin ? 'text' : 'password'}
                                       disabled={!this.state.newPin}
                                       value={this.state.showPin ? this.decryptedPin : this.state.pin}
                                       onChange={(e) => this.setState({ pin: e.target.value })}
                                       placeholder='pin'
                          />
                        </FormGroup>
                        {!this.state.newPin && <>
                          <Button onClick={() => {
                            this.setState({
                              pin: '',
                              newPin: true,
                            });
                          }}>
                            Nový pin
                          </Button>
                          <Button onClick={this.getRawPin}>
                            {`${this.state.showPin ? 'Skrýt' : 'Zobrazit'} pin`}
                          </Button>
                        </>}

                      </Col>
                    </CanView>
                    <Col sm={2}>
                      <ControlLabel>Další nastavení</ControlLabel>
                      <Checkbox checked={this.state.isForPrivateUse}
                                onChange={(e) => this.setState({
                                  isForPrivateUse: e.target.checked
                                })}>Pro osobní účely</Checkbox>
                    </Col>
                  </Row>
                  <Row>
                    <Col smOffset={3} sm={6}>
                      <SubmitButton onClick={this.props.disableUrlCheck ? (e) => this.handleSubmit(e) : null}
                                    bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                        {'Uložit'}
                      </SubmitButton>
                    </Col>
                  </Row>
                </Form>
              </LoadingOrRender>
            </div>
          </div>
          {
            this.props.disableUrlCheck ? null : (this.props.match.params.id === 'new' ? null : (this.state.requestActive ? null : <div className='Record'>
              <div className='nest'><RecordComponent
                cards={[{
                  ...this.state
                }]}
                identificationProps={{
                itemId: this.state.id,
                itemTableName: 'creditCard',
              }}/></div></div>))
          }
        </ContentWithFixedHeader>
      </div>);
  }
}

CreditCardEdit.propTypes = {
  disableUrlCheck: PropTypes.bool,
  defaultProps: PropTypes.object,
  ...BaseEditPropTypes
};

CreditCardEdit.defaultProps = {
  disableUrlCheck: false,
  defaultProps: {},
};

export default connect(mapAuthStateToProps)(CreditCardEdit);