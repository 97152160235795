import React, {Component} from "react";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import {Route, RouteComponentProps} from "react-router-dom";
import {AddButtonNames, dateFromat, Error403, HeaderNames} from "../../AppObjects";
import {BackHeader} from "../Setting";
import request from 'axios';
import moment, {Moment} from "moment";
import * as _ from 'lodash';
import Timeline, {TimelineItemBase, CustomMarker} from "react-calendar-timeline";
import AttributePreview from "../attributes/Preview";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, OverlayTrigger, Popover, Row, Col} from "react-bootstrap";
import AttendancePreview from "./Preview";
import FontAwesome from "react-fontawesome";
import {AuthenticateState} from "../../../../../packages/redux-store";
import {connect} from "react-redux";
import {BaseCommonListState, BaseCommonListContainer, BaseCommonListProps, IQuery, IEdit} from "@cml/common-list-container";
import {BaseEditState} from "@cml/common-list-container/src";
import {show} from "react-notification-system-redux";
import store from "../../redux/store";

const mapStateToProps = (state: {
  authenticate: AuthenticateState,
}) => {
  return {
    authenticate: state.authenticate,
  }
};

interface MatchParams {
  id: string | undefined;
}

interface AttendanceTimelineState extends BaseCommonListState{
  list: {
    user: {
      id: number,
      nameSurname: string,
    },
    attribute: {
      color: string;
      id: number;
      name: string;
    }
    id: number;
    from: string;
    to: string;
    isPartDay: boolean;
    timeFrom: string;
    timeTo: string;
  }[];
  modal: boolean;
  modalDelete: boolean;
  disableVisibleDate: boolean;

}

interface AttendanceTimelineProps extends RouteComponentProps<MatchParams, {}, {
  defaultQuery: IQuery,
}>, BaseCommonListProps{
  backUrl: string;
  authenticate: AuthenticateState;
}

class AttendanceTimeline extends BaseCommonListContainer<AttendanceTimelineProps, AttendanceTimelineState>
implements IEdit{


  constructor(props: AttendanceTimelineProps) {
    super(props);
    this.state = {
      ...this.state,
      list: [],
      modal: false,
      modalDelete: false,
      disableVisibleDate: true,
    }
    this.setListeners(this);
  }
  componentDidMount() {
    const { authenticate } = this.props;
    const startDate = moment(`${moment().format('YYYY')}-01-01`);
    const endDate = moment(`${moment().format('YYYY')}-12-31`);
    // @ts-ignore
    let onlySelf = authenticate.actualUserRole.attendance.onlySelf

    this.getData({
      params: {
        where: {
          $or: {
            from: {
              $between: [startDate.format(dateFromat), endDate.format(dateFromat)]
            },
            to: {
              $between: [startDate.format(dateFromat), endDate.format(dateFromat)]
            },
            $and: {
              from: {
                $lte: endDate.format(dateFromat)
              },
              to: {
                $gte: endDate.format(dateFromat)
              },
            },
          },
          // @ts-ignore
          userId: onlySelf ? authenticate.loggedUser.id : undefined,
        }
      }
    })
  }

  getData(query: object) {
    request.get('/items/attendance', query)
      .then(res => {
        this.setState({
          list: res.data,
          disableVisibleDate: false,
        });
      })
  }

  createTimeLine() {
    const { list } = this.state;
    if (list.length === 0) {
      return {
        groups: [],
        items: [],
      }
    }
    console.log(list);
    const groups = _.uniqBy(list, o => o.user.id).map(o => ({
      id: o.user.id,
      title: o.user.nameSurname,
    }))
    const items: TimelineItemBase<Moment>[] = [];
    list.forEach(l => {
      const start = moment(l.from + ' ' + l.timeFrom);
      const end = moment(l.to + ' ' + l.timeTo);
      if (!l.isPartDay) {
        end.set('hours', 23).set('minutes', 59);
      }
      items.push({
        id: l.id,
        group: l.user ? l.user.id : 0,
        start_time: start,
        end_time: end,
        itemProps: {
          style: {
            backgroundColor: l.attribute.color
          }
        },
      });
    })
    return {
      groups,
      items,
    }
  }


  itemRenderer = ({
                    // @ts-ignore
                    item,
                    // @ts-ignore
                    itemContext,
                    // @ts-ignore
                    getItemProps,
                    // @ts-ignore
                    getResizeProps
                  }) => {
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const { list } = this.state;
  const { authenticate } = this.props;
  // @ts-ignore
  const _update = authenticate.actualUserRole.attendance.update;
  // @ts-ignore
  const _delete = authenticate.actualUserRole.attendance.delete;
  const props = getItemProps(item.itemProps);
  if (!itemContext.selected) {
    props.style.background = item.itemProps.style.backgroundColor;
  }
  return (
    <OverlayTrigger
      rootClose={true}
      ref={`overlay`} trigger='click' placement='top' overlay={
      <Popover id={`attendanceDeatil_${item.id}`} placement='top'>
        <div style={{ textAlign: 'right' }}>
          {_update && <button onClick={() => {
            document.body.click();
            this.setState({
              selectedKey: item.id,
              modal: true,
            })
          }} className='transparentButton' style={{marginLeft: '5px', display: 'inline-block'}}>
            {
              <FontAwesome style={{fontSize: '16px' }} name='fas fa-edit'/>
            }
            </button>
          }
          {
            _delete && <button onClick={() => {
              document.body.click();
              this.setState({
                selectedKey: item.id,
                modalDelete: true,
              })
            }} className='transparentButton' style={{marginLeft: '5px', display: 'inline-block'}}>
            {
              <FontAwesome style={{fontSize: '16px' }} name='fas fa-trash'/>
            }
            </button>
          }
        </div>

        <AttendancePreview item={list.find(o => o.id === item.id)}/>
      </Popover>
    }>
      <div {...props}>
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

        <div
          className="rct-item-content"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
      </div>
    </OverlayTrigger>
  )}

  onEditFinished(isNew: boolean, stay: boolean, id: number): void {
    this.setState({
      selectedKey: -1,
      modal: false,
      disableVisibleDate: false,
    })
  }

  onProgress(_this: Component<{}, BaseEditState>, percentage: number) {

  }

  onEditFailed(err: { status: number; }, isNew: boolean): void {

  }

  onDelete() {
    const index = this.state.list.findIndex(o => o.id === this.state.selectedKey);
    this.deleteFinish(this.state.list[index].id, index)
      .then(() => {
        this.setState({ modalDelete: false, disableVisibleDate: false });
      })
      .catch(err => {
        store.dispatch(show({
          title: 'Chyba !',
          level: 'error',
          autoDismiss: 3,
          children: (
            <div>
              {
                // @ts-ignore
                Error403[this.props.item]}
            </div>
          ),
        }, 'error'));
      });
  }

  componentDidUpdate() {
    if (!this.state.disableVisibleDate)
      this.setState({ disableVisibleDate: true })
  }

  render() {
    const timeline = this.createTimeLine();
    const legend = _.uniqBy(this.state.list, o => o.attribute.id).map(o => o.attribute);
    const { authenticate } = this.props;
    // @ts-ignore
    const _update = authenticate.actualUserRole.attendance.update;
    // @ts-ignore
    const _delete = authenticate.actualUserRole.attendance.delete;
    const filtr = React.createElement(this.props.filter, {
      items: this.state.list,
      onFilter: (query: IQuery) => {
        this.getData(query);
        this.lastFilter = query;
      } ,},
    );

    const maxDate = this.state.list.reduce((a,b) => moment(a.to).isAfter(b.to) ? a : b, { to: moment().format('YYYY-MM-DD') });
    const minDate = this.state.list.reduce((a,b) => moment(a.from).isBefore(b.from) ? a : b, { from: moment().format('YYYY-MM-DD') });

    return <Route exact path={this.props.match.url} render={() => <div>
      <ContentWithFixedHeader baseMargin={60} header={
        <Row>
          <Col sm={3}>
            <BackHeader
              fontSize='18px'
              headerText={
                // @ts-ignore
                HeaderNames.attendance}
              onBackClick={() => this.props.history.replace(this.props.backUrl)}/>
          </Col>
          <Col sm={1}>
            {
              this.props.disableNew ? null :
                <div className='text-left'>
                  <Button disabled={!_update} onClick={() => this.setState({
                      selectedKey: -1,
                      modal: true,
                    })}>
                    <FontAwesome style={{ fontSize: '12px', marginRight: '10px', }} name='fas fa-plus'/>
                    <div style={{ fontSize: '14px', display: 'inline-block' }}>
                      {
                        // @ts-ignore
                        AddButtonNames[this.props.item]}
                    </div>
                  </Button>
                </div>
            }
          </Col>
          <Col sm={8}>
            {filtr}
          </Col>
        </Row>
        } >
        <div style={{marginLeft: '10px', marginBottom: '10px'}}>
          {legend.map((leg, index) => <AttributePreview
            key={index}
            width='16px'
            height='16px'
            marginRight='10px'
            style={{display: 'inline-block', marginRight: '10px'}} item={leg}/>)}
        </div>
        {timeline.groups.length > 0 && <Timeline
          canMove={false}
          groups={timeline.groups}
          items={timeline.items}
          itemRenderer={this.itemRenderer}
          defaultTimeStart={moment(minDate.from)}
          defaultTimeEnd={moment(maxDate.to)}
          visibleTimeStart={this.state.disableVisibleDate ? undefined : moment(minDate.from)}
          visibleTimeEnd={this.state.disableVisibleDate ? undefined : moment(maxDate.to)}
        >
          <CustomMarker date={moment().toDate()} />
        </Timeline>}
        <Modal dialogClassName="modal-60w" show={this.state.modal} onHide={() => this.setState({
          selectedKey: -1,
          modal: false,
        })}>
          <ModalHeader closeButton>
            <Modal.Title>Úprava</Modal.Title>
          </ModalHeader>
          <ModalBody>
            {this.createEditElement({
              disableUrlCheck: true,
              item: this.state.selectedKey === -1 ? {id: 'new'} : this.state.list.find(o => o.id === this.state.selectedKey),
            })}
          </ModalBody>
        </Modal>
        <Modal show={this.state.modalDelete} onHide={() => {}}>
          <ModalHeader>
            <Modal.Title>Upozornění</Modal.Title>
          </ModalHeader>
          <ModalBody>
            Opravdu chcete vymazat vybranou položku ??
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ modalDelete: false })}> Ne</Button>
            <Button onClick={() => {
              this.onDelete();
              this.setState({ modalDelete: false });
            }
            }> Ano</Button>
          </ModalFooter>
        </Modal>
      </ContentWithFixedHeader>
    </div>
    }/>
  }
}

// @ts-ignore
export default connect(mapStateToProps)(AttendanceTimeline);