import {BaseViewPropTypes} from '../CommonListContainer';
import {Component} from "react";
import React from "react";
import { Row, Col, Overlay, Popover } from 'react-bootstrap';
import FontAwesome from "react-fontawesome";
import PropTypes from 'prop-types';
import {mapAuthStateToProps} from "../../AppObjects";
import {connect} from "react-redux";

class SmallUserPreview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false
    };

  }

  handleMouseDown(e) {
    if (e.button === 2 && this.props.detailEnabled) {
      this.setState({
        show: !this.state.show,
      })
    }
  }

  render() {
    let item = this.props.item;
    if (item === null || typeof item === 'undefined')
      return null;
    let color = this.props.iconColor;
    let showTooltip = false;
    if (item.attendance && this.props.colorIfAttendance) {
      if (item.attendance.length > 0) {
        showTooltip = true;
        color = item.attendance[0].attribute.color;
      }

    }

    let errorForeignDataRow = false;
    if(this.props.errorIfForeignDataRows) {
      if(item.dataRows)
        errorForeignDataRow = item.dataRows.length !== 0;
    }
    let style = {
      color: color,
      ...this.props.style,
    };
    return (
      <div style={ style }
           data-tip={showTooltip ? `${item.attendance[0].attribute.name}:
           ${item.attendance[0].timeFrom} - ${item.attendance[0].timeTo}` : null}
           onMouseDown={(e) => this.handleMouseDown(e)}>
        {errorForeignDataRow && <FontAwesome style={{ fontSize: '14px',
          color: 'red',
          title: 'Nelze využít protože jež existuje záznam v dispečinku z jiného prostoru',
          marginRight: '6px' }} name={'exclamation-circle'}/>}
          <FontAwesome style={{ fontSize: '14px',
            color: color,
            marginRight: '6px' }} name={this.props.iconName}/>
          {item.name + ' ' + item.surname}
        <Overlay
          show={this.state.show}
          placement="bottom"
          container={this}
          containerPadding={20}>
          <Popover id="popover-contained" title="">
            <div>
              Tel: <strong>{` ${item.telnumb}`}</strong>
            </div>
            <div>
              Email:<strong>{` ${item.email}`}</strong>
            </div>
            <div>
              Firma:<strong>{` ${item.company}`}</strong>
            </div>
          </Popover>
        </Overlay>
      </div>
    )
  }
}

SmallUserPreview.propTypes = {
  style: PropTypes.object,
  detailEnabled: PropTypes.bool,
  colorIfAttendance: PropTypes.bool,
  errorIfForeignDataRows: PropTypes.bool,
  iconColor: PropTypes.string,
  iconName: PropTypes.string,
  ...BaseViewPropTypes
};

SmallUserPreview.defaultProps = {
  detailEnabled: false,
  iconColor: '#000000',
  colorIfAttendance: false,
  errorIfForeignDataRows: false,
  iconName: 'fas fa-user',
};

class UserPreview extends Component {

  renderRole() {
    const item = this.props.item;
    const activeSPace = item.spaces.find(o => o.spaceId === this.props.authenticate.activeSpaceId);
    if (!activeSPace)
      return "ŽÁDNÁ ROLE";
    return activeSPace.role ? activeSPace.role.name : 'ŽÁDNÁ ROLE';
  }

  render() {
    const item = this.props.item;
    const foreignDriver = item ? (item.foreignApiDriverId ? 
      item.foreignApiDriverId.find(o => o.type === 'webDisApi') : undefined) : undefined;
    const index = item.spaces.findIndex(o => (o.spaceId === this.props.authenticate.activeSpaceId || !o.spaceId) && o.enabled);
    return <div style={{opacity: index < 0 ? 0.5 : 1}}
                className='text-left fixHowirizonalScroll'>
      <Row>

        <Col data-tip={index < 0 ? 'Z jiného prostoru' : null} sm={2}>
          <FontAwesome style={{ fontSize: '26px', marginRight: '10px' }} name='fas fa-user'/>
          {item.name + ' ' + item.surname}
          {item.canReg ? <FontAwesome style={{ fontSize: '14px', marginLeft: '10px', color: 'green', cursor: 'pointer' }}
                                      data-tip="Uživatel se může zaregistrovat"
                                      name='fas fa-check'/> : null}
          {item.verification ? <FontAwesome style={{ fontSize: '14px', marginLeft: '10px' , color: 'green', cursor: 'pointer' }}
                                            data-tip="Uživatel se může přihlásit"
                                            name='fas fa-check'/> : null}
        </Col>
        <Col sm={2}>
          <FontAwesome style={{ fontSize: '26px', marginRight: '10px' }} name='fas fa-phone'/>
          {item.telnumb}
        </Col>
        <Col sm={2}>
          <FontAwesome style={{ fontSize: '26px', marginRight: '10px' }} name='fas fa-at'/>
          {item.email}
        </Col>
        <Col sm={2}>
          <FontAwesome style={{ fontSize: '26px', marginRight: '10px' }} name='fas fa-ruler-combined'/>
          {this.renderRole()}
        </Col>
        <Col sm={2}>
          <FontAwesome style={{ fontSize: '26px', marginRight: '10px' }} name='fas fa-building'/>
          {item.spaces.filter(o => o.enabled).map((item,index) => (
            <span title={item.homeSpace? 'Označen jako domácí prostor' : undefined} style={{marginRight: '20px',
              color: item.homeSpace? 'darkgreen' : undefined,
              fontWeight: item.homeSpace? 'bold' : undefined}}>
                      {
                        item.space.name
                      }
                    </span>
          ))}
        </Col>
        <Col sm={2}>
          <div style={{ display: 'inline-block' }} className='webdispecink'/>
          <div style={{ display: 'inline-block', marginLeft: 10 }}>{foreignDriver && foreignDriver.driver.displayName}</div>

        </Col>

      </Row>
    </div>
  }
}

export {
  SmallUserPreview,
}

UserPreview.propTypes = BaseViewPropTypes;

export default connect(mapAuthStateToProps)(UserPreview);