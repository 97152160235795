import React, { Component } from 'react';
import { Col, DropdownButton, FormControl, MenuItem, Row } from "react-bootstrap";
import { BaseFilterPropTypes } from '../CommonListContainer';
import PropTypes from 'prop-types';
import ItemsSelector from "../ItemsSelector";
import FontAwesome from "react-fontawesome";
import {SmallUserPreview} from "../users/Preview";

const isConfirmedNames = {
  all: 'Všechny stavy',
  done: 'Splněno',
  undone: 'Nesplněno',
};

export default class TasksFiltr extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isConfirmed: 'all',
      diagramModelId: 0,
      userId: 0,
      nameFilterValue: '',
    };
  }

  doFilter(nameFilterValue, isConfirmed, diagramModelId, userId) {
    console.log("Doing filter");
    let isConfirmedWhere = null;
    if (isConfirmed !== 'all')
      isConfirmedWhere = {
        isConfirmed: isConfirmed === 'done' ? 1 : 0,
      };
    let diagramModelWhere = null;
    if (diagramModelId !== 0) {
      diagramModelWhere = {
        diagramModelId
      }
    }
    let userLinksWhere = null;
    if (userId !== 0) {
      userLinksWhere = {
        id: userId,
      }
    }

    this.props.onFilter({
      where: {
        name: {$like: '%' + nameFilterValue + '%'},
        ...isConfirmedWhere,
        ...diagramModelWhere,
      },
      userLinksWhere,
    })
  }

  render() {
    const { diagramModelId, isConfirmed, nameFilterValue, userId } = this.state;
    return (
      <div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll'>
        <Row>
          <Col sm={1}>
            <div className='text-right'>
              <FontAwesome style={{fontSize:'18px', marginTop: '8px'}} name='fas fa-search' />
            </div>
          </Col>
          <Col sm={3}>
            <FormControl
              value={nameFilterValue}
              onChange={(e) => {
                this.setState({nameFilterValue: e.target.value});
                this.doFilter(e.target.value, isConfirmed, diagramModelId, userId);
              }}
              type='text'/>
          </Col>
          <Col sm={2}>
            <ItemsSelector
              value={'name'}
              appendItemsToStart={[{name: 'Všechny typy', id: 0}]}
              defaultQuery={{attributes: ['name', 'id'] }}
              onSelected={(item) => {
                this.setState({ diagramModelId: item.id }) ;
                this.doFilter(nameFilterValue, isConfirmed, item.id, userId);
              }}
              defaultSelectedId={this.state.diagramModelId}
              itemsToFetch={'diagrams/tasks'}/>
          </Col>
          <Col sm={2}>
            <DropdownButton id='isConfirmedFiltr'
                            onSelect={(key) => {
                              this.setState({ isConfirmed: key });
                              this.doFilter(nameFilterValue, key, diagramModelId, userId);
                            }}
                            title={isConfirmedNames[this.state.isConfirmed]}>
              <MenuItem eventKey='all'>{isConfirmedNames.all}</MenuItem>
              <MenuItem eventKey='done'>{isConfirmedNames.done}</MenuItem>
              <MenuItem eventKey='undone'>{isConfirmedNames.undone}</MenuItem>
            </DropdownButton>
          </Col>
          <Col sm={2}>
            <ItemsSelector
              value={'nameSurname'}
              defaultSelectedId={0}
              defaultQuery={{attributes: ['name', 'id', 'surname', 'nameSurname'] }}
              preview={SmallUserPreview}
              onSelected={(user) => {
                this.setState({ userId: user.id });
                this.doFilter(nameFilterValue, isConfirmed, diagramModelId, user.id);
              }}
              appendItemsToStart={[
                { nameSurname: 'Všichni zodpovědní', name: 'Všichni', surname: 'zodpovědní', id: 0 },
              ]}
              filterEnable
              otherPreviewProps={{
                style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
              }}
              itemsToFetch={'users'}/>
          </Col>
        </Row>
      </div>
    )
  }
}

TasksFiltr.propTypes = {
  items: PropTypes.array.isRequired,
  ...BaseFilterPropTypes,
};

