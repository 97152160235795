import React, { Component } from 'react';
import { BaseEditPropTypes } from '../CommonListContainer';
import request from 'axios';
import {BackHeader} from "../Setting";
import {
  Col,
  Form,
  FormGroup,
  Row,
  ControlLabel, Checkbox
} from "react-bootstrap";
import {LoadingOrRender} from "../../loginsrc/Profile";
import {SmallUserPreview} from "../users/Preview";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import SubmitButton from "../../loginsrc/SubmitButton";
import {dateFormatVisible, dateFromat, mapAuthStateToProps} from "../../AppObjects";
import TimePicker from 'react-times';
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import {connect} from "react-redux";
import UserEdit from "../users/Edit";
import ItemsSelector from "../ItemsSelector";
import AttributeEdit from "../attributes/Edit";


class AttendanceEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      requestActive: props.disableUrlCheck ? false : props.match.params.id !== 'new',
      submitActive: false,
      userId: 0,
      isPartDay: false,
      timeTo: '06:00',
      timeFrom: '06:00',
      attributeId: 0,
      startDate: null,
      endDate: null,
      focusedInput: null,
      workingDays: 0,
    };
  }

  componentWillMount() {
    if (this.props.disableUrlCheck) {
      if (this.props.item.id !== 'new') {
        this.setState({
          ...this.props.item,
          startDate: moment(this.props.item.from, dateFromat),
          endDate: moment(this.props.item.to, dateFromat),
        })
      }
      return;
    }
    if (this.props.match.params.id !== 'new' ) {
      request.get('/items/attendance/' + this.props.match.params.id)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              startDate: moment(res.data.from, dateFromat),
              endDate: moment(res.data.to, dateFromat),
              ...res.data,
            });
          }
        })
        .catch(err => {
          this.setState({ requestActive: false })
        })
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.endDate === null || this.state.startDate === null) {
      this.setState({
        focusedInput: this.state.startDate !== null ? 'endDate' : 'startDate'
      });
      store.dispatch(show({
        title: 'Chyba',
        level: 'error',
        autoDismiss: 3,
        children: (
          <div>
            Vyberte prosím období
          </div>
        ),
      }, 'error'));
      return;
    }
    if (this.state.isPartDay) {
      if (Date.parse('01/01/2011 ' + this.state.timeFrom) >=
        Date.parse('01/01/2011 ' + this.state.timeTo)) {
        store.dispatch(show({
          title: 'Chyba',
          level: 'error',
          autoDismiss: 3,
          children: (
            <div>
              Čas "od" je větší nebo rovno než čas "do" !
            </div>
          ),
        }, 'error'));
        return;
      }
    }
    if (this.state.attributeId < 1 || this.state.userId < 1) {
      store.dispatch(show({
        title: 'Chyba',
        level: 'error',
        autoDismiss: 5,
        children: (
          <div>
            Některá položka není vybrána
          </div>
        ),
      }, 'error'));
      return;
    }
    let obj = null;
    let id = 'new';
    if (this.props.disableUrlCheck)
      id = this.props.item.id;
    else
      id = this.props.match.params.id;
    if ( id === 'new' ) {
      obj = {
        from: this.state.startDate.format(dateFromat),
        to: this.state.endDate.format(dateFromat),
        ...this.state,
      };
    } else {
      obj = {
        ...this.state,
      };
      obj.from = this.state.startDate.format(dateFromat);
      obj.to = this.state.endDate.format(dateFromat);
    }

    delete obj['startDate'];
    delete obj['endDate'];
    delete obj['focusedInput'];
    this.props.onSubmit(id, this, obj);
  }

  handleDateChange(startDate, endDate) {
    this.setState({ startDate, endDate });
    if (endDate !== null) {
      this.setState({
        workingDays: moment(startDate.format(dateFromat), dateFromat)
          .businessDiff(moment(endDate.format(dateFromat),dateFromat).businessAdd(1))
      })
    }
  }

  render() {
    return (
      <div className='editForm'>
        <ContentWithFixedHeader header={
          !this.props.disableUrlCheck && <BackHeader
            fontSize='18px'
            headerText={'Záznam docházky'}
            onBackClick={() => this.props.history.replace(
              this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
        }>
          <div className='nest'>
            <LoadingOrRender requestActive={this.state.requestActive}>

              <Form onSubmit={(e) => this.handleSubmit(e)}>
                <FormGroup controlId="isPartDay">
                  <Row>
                    <Col sm={2} componentClass={ControlLabel}>
                      Pouze část dne ?
                    </Col>
                    <Col sm={10}>
                      <Checkbox checked={this.state.isPartDay}
                                onChange={(e) => {
                                  this.setState({
                                    isPartDay: e.target.checked
                                  });
                                }}
                      >
                      </Checkbox>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="range">
                  <Row>
                    <Col sm={2} componentClass={ControlLabel}>
                      Období
                    </Col>
                    <Col sm={4}>
                      {this.state.isPartDay ? <SingleDatePicker
                        date={this.state.startDate} // momentPropTypes.momentObj or null
                        displayFormat={ dateFormatVisible }
                        isOutsideRange = {() => false}
                        placeholder='Datum'
                        small
                        onDateChange={date => {
                          this.handleDateChange( date, date );
                        }} // PropTypes.func.isRequired
                        focused={this.state.datePickerFocused} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ datePickerFocused: focused })} // PropTypes.func.isRequired
                        id="your_unique_id" // PropTypes.string.isRequired,
                      /> : <DateRangePicker
                        displayFormat={ dateFormatVisible }
                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                        startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
                        startDatePlaceholderText='Začátek'
                        endDatePlaceholderText='Konec'
                        isOutsideRange = {() => false}
                        small
                        minimumNights={0}
                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                        endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
                        onDatesChange={({ startDate, endDate }) => this.handleDateChange( startDate, endDate )} // PropTypes.func.isRequired,
                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                      />}

                    </Col>
                    <Col sm={2} componentClass={ControlLabel}>
                      Počet pracovních dnů
                    </Col>
                    <Col sm={4} componentClass={ControlLabel}>
                      { this.state.workingDays }
                    </Col>
                  </Row>
                </FormGroup>
                {
                  this.state.isPartDay ? <FormGroup controlId="user">
                    <Row>
                      <Col sm={2} componentClass={ControlLabel}>
                        Od - Do
                      </Col>
                      <Col sm={3}>
                        <TimePicker theme='classic'
                                    onTimeChange={(options) => this.setState({
                                      timeFrom: `${options.hour}:${options.minute}`
                                    })}
                                    time={this.state.timeFrom}/>
                      </Col>
                      <Col sm={3}>
                        <TimePicker theme='classic'
                                    onTimeChange={(options) => this.setState({
                                      timeTo: `${options.hour}:${options.minute}`
                                    })}
                                    time={this.state.timeTo}/>
                      </Col>
                    </Row>
                  </FormGroup> : null
                }

                <FormGroup controlId="user">
                  <Row>
                    <Col sm={2} componentClass={ControlLabel}>
                      Zaměstnanec
                    </Col>
                    <Col sm={10}>
                      <ItemsSelector itemsToFetch='users'
                                     defaultQuery={{
                                       attributes: ['name', 'id', 'surname', 'nameSurname'] ,
                                     }}
                                     overrideToggleFunc={(user) => this.state.userId > 0 ?
                                       <SmallUserPreview style={{fontSize: '11px', display: 'inline', marginRight: '5px'}}
                                                         item={user}/> : <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                         Nevybrán
                                       </div>}
                                     preview={ SmallUserPreview }
                                     newEditComponent={ UserEdit }
                                      //bsSize="xsmall"
                                     filterEnable
                                     value={'nameSurname'}
                                     onSelected={(user) => this.setState({ userId: user.id, user })}
                                     defaultSelectedId={this.state.userId}/>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup controlId="attribute">
                  <Row>
                    <Col sm={2} componentClass={ControlLabel}>
                      Důvod nepřítomnosti
                    </Col>
                    <Col sm={10}>
                      <ItemsSelector itemsToFetch={'attributes/attendance'}
                                     newEditComponent={ AttributeEdit }
                                     newEditComponentDefaultProps={{
                                       attributeName: 'attendance',
                                     }}
                                     overrideToggleFunc={(item) => this.state.attributeId > 0 ? item.name : <div style={{fontSize: '11px', fontWeight: 'bold', color: 'red', display: 'inline'}}>
                                       Nevybrán
                                     </div>}
                                     onSelected={(item) => this.setState({ attributeId: item.id, attribute: item })}
                                     defaultSelectedId={this.state.attributeId}
                                     value={'name'}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <SubmitButton bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
                  Uložit
                </SubmitButton>
              </Form>
            </LoadingOrRender>
          </div>
        </ContentWithFixedHeader>
      </div>
    )
  }
}

AttendanceEdit.propTypes = BaseEditPropTypes;

export default connect(mapAuthStateToProps)(AttendanceEdit);