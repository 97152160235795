import React, { Component } from 'react';
import {BackHeader} from "../Setting";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import {BaseEditProps} from "../CommonListContainerInterfaces";
import ImportPhmInvoice, { PhmImportTypes } from "../phm/ImportPhmInvoice";
import {Col, Row, } from "react-bootstrap";
import ImportPhmCards from "../phm/ImportCreditCards";
import { mapAuthStateToProps } from "../../AppObjects";
import {connect} from "react-redux";
import { AuthenticateState } from "@cml/redux-store";
import ImportAutoPhm from "../phm/ImportAutoPhm";

interface Props extends BaseEditProps {
    authenticate: AuthenticateState
}

class Import extends Component<Props, {}> {
    render() {

        return <ContentWithFixedHeader baseMargin={60} header={
        <BackHeader
            headerText='Import'
            fontSize='18px'
            onBackClick={() => this.props.history.replace(
                this.props.match.url.split('/import')[0])}/>
        }>
            <div className='fixHowirizonalScroll' style={{ marginLeft: '20px' }}>
                {
                    // @ts-ignore
                    this.props.authenticate.actualUserRole.phm.update && <>
                        <Row>
                            <Col sm={4} >
                                <h3 style={{ borderBottom: '5px solid darkred'}}>Auto import Uta</h3>
                            </Col>
                            <Col sm={4}>
                                <h3 style={{ borderBottom: '5px solid darkorange'}}>Auto import Shell</h3>
                            </Col>
                            <Col sm={4}>
                                <h3 style={{ borderBottom: '5px solid blue'}}>Import Eurowag</h3>
                            </Col>
                        </Row>
                        <Row>
                            <ImportAutoPhm type={PhmImportTypes.uta}/>
                            <ImportAutoPhm type={PhmImportTypes.shell}/>
                            <ImportPhmInvoice type={PhmImportTypes.eurowag}/>
                        </Row>
                    </>
                }
                {
                    // @ts-ignore
                    this.props.authenticate.actualUserRole.creditCard.update && <>
                        <Row>
                            <Col sm={4} >
                                <h3 style={{ borderBottom: '5px solid darkred'}}>Uta karty</h3>
                            </Col>
                            <Col sm={4}>
                                <h3 style={{ borderBottom: '5px solid darkorange'}}>Shell karty</h3>
                            </Col>
                            <Col sm={4}>
                                <h3 style={{ borderBottom: '5px solid blue'}}>Eurowag karty</h3>
                            </Col>
                        </Row>
                        <Row>
                            <ImportPhmCards type={PhmImportTypes.uta}/>
                            <ImportPhmCards type={PhmImportTypes.shell}/>
                            <ImportPhmCards type={PhmImportTypes.eurowag}/>
                        </Row>
                    </>
                }
            </div>
        </ContentWithFixedHeader>
    }
}

export default connect(mapAuthStateToProps)(Import);