import {
    AbstractDisplacementState,
    AbstractDisplacementStateEvent,
    Action,
    ActionEvent,
    InputType
} from "@projectstorm/react-canvas-core";
import {
    DiagramEngine,
    DragNewLinkStateOptions,
    LinkModel,
    PointModel,
    PortModel
} from "@projectstorm/react-diagrams-core";
import {MouseEvent} from "react";
import {RightAngleLinkModel} from "@projectstorm/react-diagrams-routing";
import {ConnectionNodeModel} from "./nodes/flowControlNodes/FlowControlModels";
import {Point} from "@projectstorm/geometry";
import {CmlPortModel} from "./nodes/ports/CmlPortModel";


export class CmlDragNewLinkState extends AbstractDisplacementState<DiagramEngine> {

    port: PortModel;
    link: LinkModel;
    config: DragNewLinkStateOptions;

    constructor(options: DragNewLinkStateOptions = {}) {
        super({
            name: 'drag-new-link'
        });
        this.config = {
            allowLooseLinks: true,
            allowLinksFromLockedPorts: false,
            ...options
        };
        this.port = new PortModel({ name: "" });
        this.link = new LinkModel({});
        this.registerAction(
            new Action({
                type: InputType.MOUSE_DOWN,
                // @ts-ignore
                fire: (event: ActionEvent<MouseEvent, PortModel>) => {
                    this.engine.getModel().getNodes().forEach(item => item.setSelected(false));
                    this.port = this.engine.getMouseElement(event.event) as PortModel;
                    if (!this.config.allowLinksFromLockedPorts && this.port.isLocked()) {
                        this.eject();
                        return;
                    }
                    // @ts-ignore
                    this.link = this.port.createLinkModel();

                    // if no link is given, just eject the state
                    if (!this.link) {
                        this.eject();
                        return;
                    }
                    this.link.setSelected(true);
                    this.link.setSourcePort(this.port);
                    this.engine.getModel().addLink(this.link);
                    this.port.reportPosition();
                }
            })
        );

        this.registerAction(
            new Action({
                type: InputType.MOUSE_UP,
                // @ts-ignore
                fire: (event: ActionEvent<MouseEvent>) => {
                    const model = this.engine.getMouseElement(event.event);

                    // check to see if we connected to a new port
                    if (model instanceof PortModel) {
                        // @ts-ignore
                        if (this.port.canLinkToPort(model)) {
                            // @ts-ignore
                            this.link.setTargetPort(model);
                            model.reportPosition();
                            this.engine.repaintCanvas();
                            return;
                        }
                    } else if (model instanceof RightAngleLinkModel) {
                        if (model === this.link) {
                            this.link.remove();
                            this.engine.repaintCanvas();
                            return;
                        }
                        const points = model.getPoints();
                        let dx = Math.abs(points[model.lastHoverIndexOfPath].getX() - points[model.lastHoverIndexOfPath + 1].getX());
                        const node = new ConnectionNodeModel();
                        let p = new Point(0,0);
                        if (dx === 0) {
                            p = new Point(points[model.lastHoverIndexOfPath].getX(), this.link.getPoints()[this.link.getPoints().length - 1].getY());
                        } else {
                            p = new Point(this.link.getPoints()[this.link.getPoints().length - 1].getX(), points[model.lastHoverIndexOfPath].getY());
                        }
                        this.link.setTargetPort((node.getPort('conn') as CmlPortModel));
                        node.setPosition(p.x, p.y);
                        this.engine.getModel().addNode(node);

                        const link1 = new RightAngleLinkModel();
                        const points1 =  model.getPoints().slice(0, model.lastHoverIndexOfPath + 1);
                        link1.setSourcePort(model.getSourcePort());
                        link1.setTargetPort((node.getPort('conn') as CmlPortModel));
                        points1.map((point, index) => {
                            const p1 = new PointModel({
                                link: link1,
                                position: point.getPosition(),
                            });
                            if (index < 2) {
                                link1.getPoints()[index] = p1;
                            } else {
                                link1.addPoint(p1,index + 1);
                            }
                        });
                        link1.addPoint(new PointModel({
                            link: link1,
                            position: p,
                        }), points1.length);

                        const link2 = new RightAngleLinkModel();
                        const points2 = model.getPoints().slice(model.lastHoverIndexOfPath + 1);
                        link2.setSourcePort((node.getPort('conn') as CmlPortModel));
                        link2.setTargetPort(model.getTargetPort());
                        points2.map((point, index) => {
                            const p1 = new PointModel({
                                link: link2,
                                position: point.getPosition(),
                            });
                            if (index < 2) {
                                link2.getPoints()[index] = p1;
                            } else {
                                link2.addPoint(p1,index + 1);
                            }
                        });
                        link2.addPoint(new PointModel({
                            link: link2,
                            position: p,
                        }), 0);
                        if (points2.length < 2) {
                            link2.getPoints()[2].remove();
                        }
                        this.engine.getModel().addLink(link1);
                        this.engine.getModel().addLink(link2);
                        model.remove();
                        this.engine.repaintCanvas();
                        return;
                    }

                    if (!this.config.allowLooseLinks) {
                        // @ts-ignore
                        this.link.remove();
                        this.engine.repaintCanvas();
                    }
                }
            })
        );
    }

    /**
     * Checks whether the mouse event appears to happen in proximity of the link's source port
     * @param event
     */
    isNearbySourcePort({ clientX, clientY }: MouseEvent): boolean {
        const sourcePort = this.link.getSourcePort();
        const sourcePortPosition = this.link.getSourcePort().getPosition();

        return (
            clientX >= sourcePortPosition.x &&
            clientX <= sourcePortPosition.x + sourcePort.width &&
            (clientY >= sourcePortPosition.y && clientY <= sourcePortPosition.y + sourcePort.height)
        );
    }

    fireMouseMoved(event: AbstractDisplacementStateEvent): any {
        const pos = this.port.getPosition();
        const xOffset = event.virtualDisplacementX < 0 ? (event.virtualDisplacementX + 30) : (event.virtualDisplacementX - 15);
        const YOffset = event.virtualDisplacementY < 0 ? (event.virtualDisplacementY + 10) : (event.virtualDisplacementY + 15);
        this.link.getLastPoint().setPosition(pos.x + xOffset, pos.y + YOffset);
        this.engine.repaintCanvas();
    }
}