import React, {Component, useEffect, useState} from "react";
import Col from "react-bootstrap/lib/Col";
import request from "axios";
import {LoadingOrRender} from "../../loginsrc/Profile";
import Button from "react-bootstrap/lib/Button";
import {show} from "react-notification-system-redux";
import store from "../../redux/store";
import {routeTranslation} from "./ImportPhmInvoice";
import moment from "moment";

interface Props {
  type: number;
}

interface State {

}

function ShellInvoice(props: {
  item: {
    InvoiceNumber: string;
    InvoiceDate: string;
    Status: string;
    GrossAmountTransactionCurrency: number;
    TransactionCurrencySymbol: string;
  }

}) {
  return <div>
    {`${moment(props.item.InvoiceDate, 'YYYYMMDD')
      .format('DD.MM.YYYY')} ${props.item.InvoiceNumber} 
      ${props.item.Status} ${props.item.GrossAmountTransactionCurrency} ${props.item.TransactionCurrencySymbol}`}
  </div>
}

export default function ImportAutoPhm (props: Props) {

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [importProgress, setImportProgress] = useState(-1);

  useEffect(() => {
    request.get(`/items/${routeTranslation[props.type]}notimported`)
      .then(res => {setList(res.data); setLoading(false);})
      .catch(err => setLoading(false))
  },[]);

  const doImport = (index: number) => () => {
    setImportProgress(index);
    request.post(`/items/pohoda/${routeTranslation[props.type]}autoimport`, {
      data: list[index],
    }).then(() => {
      setImportProgress(-1);
      list.splice(index, 1)
      setList(list);
      store.dispatch(show({
        title: 'Info',
        level: 'info',
        autoDismiss: 5,
        children: (
          <div>
            Import byl v pořádku proveden
          </div>
        ),
      }, 'info'));
    }).catch(() => setImportProgress(-1))
  }

  return <Col sm={4}>
    <LoadingOrRender requestActive={loading}>
      <div style={{maxHeight: '300px', overflow: 'auto'}}>
      {
        list.map((item, index) => <div
          className='issuedOrderListItem'
          style={{display: 'table', width: '100%'}}
          key={index}>
          <span style={{marginTop: '10px', maxWidth: '300px', wordWrap: 'break-word', display: 'inline-block'}}>
            {props.type === 0 ? item : <ShellInvoice item={item}/>}
          </span>
          <span style={{float: 'right', marginBottom: '3px', marginRight: '5px'}}>
            <LoadingOrRender requestActive={importProgress === index}>
              <Button disabled={importProgress !== -1} onClick={doImport(index)}>Import</Button>
            </LoadingOrRender>
          </span>
        </div>)
      }
      </div>
    </LoadingOrRender>
  </Col>;
}