import {IDispatchingAttributesCustom, OrderOther} from "@cml/types";
import {FormControl} from "react-bootstrap";
import {CanView} from "../../Main";
import React, {ChangeEvent} from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {Attributes, Roles} from "@cml/models";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";
import MaterialTable from "../components/MaterialTable";
import {getAttributes, getOrders} from "../../../fetchApi/dispatching";
import { NewItemAddedContextProvider } from "../../ItemsSelector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrMaterial({ index, attribute, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      fraction: e.target.value,
    }))
  }

  const { attributes: priceAttributes,
    isValidating: priceValidating } = getAttributes(
    'price' );
  const { attributes: materialAttributes, isValidating: materialValidating, revalidate: matRevalidate } = getAttributes(
    'material' );
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store' );

  const fraction = useSelector(selectDataRowValue(index, singleRowEditMode, 'fraction'));
  const materialTable = useSelector(selectDataRowValue(index, singleRowEditMode, 'materialTable'));
  const orderId = useSelector(selectDataRowValue(index, singleRowEditMode, 'orderId'));

  const { orders, isValidating: ordersValidating } = getOrders(
    { params: { where: { isConfirmed: 0 }}}, );

  let selectedOrder = orders ? orders.find(o => o.id === (orderId)) : undefined;

  const itemAddedHandler = {
    onNewItemAdd: (itemToFetch: string) => {
      switch (itemToFetch) {
        case 'attributes/material':
          matRevalidate();
          break;
      }
    }
  }

  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canFraction && dispatchOther.canFraction}>
    <td >
      <NewItemAddedContextProvider value={itemAddedHandler}>
      {<MaterialTable
            materialTable={materialTable}
            defaultMaterialTable={selectedOrder ? (selectedOrder.other as OrderOther).materialTable : undefined}
            onTableChanged={(materialTable) => {
              store.dispatch(setDataRowValue(index, singleRowEditMode, {
                materialTable
              }))
            }}
            // @ts-ignore
            materialAttributes={materialAttributes}
            storeAttributes={storeAttributes}
            priceAttributes={priceAttributes}
          />}
      {/*<FormControl type="text"
                    style={{fontSize: '11px', minWidth: '100px',maxWidth: '100px'}}
                    value={fraction}
        // @ts-ignore
                    onChange={onChange}/>*/}
      </NewItemAddedContextProvider>
    </td>
  </CanView>
}