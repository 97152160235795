import {createStore, compose, applyMiddleware, combineReducers} from "redux";
import { wsMiddleware, navbarReducer, applicationReducer, reducerAuthenticate, dataRowsReducer } from '@cml/redux-store';
import thunk from 'redux-thunk';
import {reducer as notifications} from "react-notification-system-redux";

const store = createStore(combineReducers({
      navbar: navbarReducer,
      application: applicationReducer,
      authenticate: reducerAuthenticate,
      dispatching: dataRowsReducer,
      // @ts-ignore
      notifications,
  }),
    compose(
        applyMiddleware(...[thunk, wsMiddleware]),

        window.__REDUX_DEVTOOLS_EXTENSION__
            ? window.__REDUX_DEVTOOLS_EXTENSION__({trace: true})
            : (f) => f
    ));

export default store;