import * as React from 'react';
import {IconWithNumber} from "../../loginsrc/NavigationBar";
import FileViewer, {FileObject} from "./FileViewer";
import FontAwesome from 'react-fontawesome';
import { Modal, Row, Col } from 'react-bootstrap';
import {ChangeEvent} from "react";
import { historyChangeType } from '../tasks/EditablePreview';
import request, { AxiosResponse } from "axios";
import SubmitButton from "../../loginsrc/SubmitButton";
import {Items} from "@cml/models";
import _ from 'lodash';

export interface AttachmentsItemChanged {
    name: string;
    changeType: number;
}

export interface AttachmentsProps {
    useModal?: boolean;
    mdSize?: number;
    smSize?: number;
    list?: File[] | FileObject[];
    onListChanged?: (list: File[] | FileObject[], changes?: AttachmentsItemChanged[]) => void;
    updating?: boolean;
    askBeforeDeleteFile?: boolean;
    selfListed?: boolean;
    identificationProps?: {
        itemId: number | number[];
        itemTableName: string;
    },
    saveButton: boolean;
    initObject?: Items;
    multiMode?: boolean;
    onMultiChange?: (items: Items[]) => void;
}

export interface AttachmentsState {
    show: boolean;
    actualBigPreview: number;
    list: File[] | FileObject[];
    id?: number | null;
    multiId: Items[];
}

export default class Attachments extends React.Component<AttachmentsProps, AttachmentsState> {
    public static defaultProps = {
        useModal: false,
        updating: true,
        askBeforeDeleteFile: false,
        selfListed: false,
        smSize: 2,
        mdSize: 1,
        tableName: '',
        saveButton: false,
        list: [],
        onListChanged: () => {},
    };
    constructor(props: AttachmentsProps) {
        super(props);
        this.state = {
            show: false,
            actualBigPreview: -1,
            list: [],
            multiId: [],
        }
    }

    private uploadFile(item: ChangeEvent<HTMLInputElement>) {
        const { selfListed, multiMode } = this.props;
        if (item.target) {
            if (typeof (item.target as HTMLInputElement).files === 'undefined')
                return;
        }
        const changes = [] as AttachmentsItemChanged[];
        const files = (item.target as HTMLInputElement).files;
        if (files && this.props.list) {
            let list = selfListed ? this.state.list.slice() : this.props.list.slice();
            for (let i = 0; i < files.length; i++) {
                list.push(files[i] as File);
                changes.push({
                    name: (files[i] as File).name,
                    changeType: historyChangeType.add,
                })
            }
            if (multiMode && this.props.identificationProps) {
                if (this.props.identificationProps.itemId) {

                    const multiId = this.state.multiId.slice();
                    const m : any[] = [];
                    const filesList: File[] = [];

                    for (let i = 0; i < files.length; i++) {
                        filesList.push(files[i] as File);
                    }
                    (this.props.identificationProps.itemId as number[]).forEach(n => {
                        if(!(multiId).find(mm => mm.itemId === n)) {
                            m.push({
                                id: null,
                                itemId: n,
                                listOfFiles: filesList,
                            })
                        }
                    })

                    this.setState({
                        // @ts-ignore
                          multiId: multiId.map(o => ({
                              ...o,
                              // @ts-ignore
                              listOfFiles: o.listOfFiles.concat(filesList),
                          })).concat(m)
                      })
                }


            }
            if (selfListed) {
                this.setState({ list })
            } else {
                if(this.props.onListChanged)
                    this.props.onListChanged(list, changes);
            }

        }
    }

    loadItem() {
        if (this.props.identificationProps) {
            if (!this.props.identificationProps.itemId)
                return;
            request.get('/items/items', {
                params: {
                    where: this.props.identificationProps
                }
            }).then((res: AxiosResponse) => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        const list = _.uniqBy(res.data.reduce((acc: string | any[], item: { listOfFiles: any; }) =>
                          acc.concat(item.listOfFiles ? item.listOfFiles : []), []), 'name') ;

                        this.setState({
                            // @ts-ignore
                            list: list,
                            id: res.data[0].id,
                            multiId: res.data.map((o: { id: any; itemId: any; listOfFiles: any[] }) =>
                              ({ id: o.id, itemId: o.itemId, listOfFiles: o.listOfFiles })),
                        });
                    }
                }
            })
              .catch((err:any) => console.log(err));
        }
    }

    componentDidMount(): void {
        if (!this.props.initObject)
            this.loadItem();
        else {
            this.setState({
                list: this.props.initObject.listOfFiles ?
                  this.props.initObject.listOfFiles as FileObject[] : [],
                id: this.props.initObject.id,
            });
        }
    }

    private onFileDelete = (i: number | string) => {
        const { selfListed, onListChanged, multiMode  } = this.props;
        const list = this.props.selfListed ? this.state.list.slice() :
          (this.props.list ? this.props.list.slice() : []);
        let l = list.slice();
        const changes = [{name: list[i as number].name, changeType: historyChangeType.delete}] as AttachmentsItemChanged[];
        if (multiMode) {
            const multiId = this.state.multiId.slice();
            const itemToBeDeleted = l[i as number];
            multiId.forEach(m => {

                const index = (m.listOfFiles as FileObject[])
                  .findIndex((ll: { name: any; }) => ll.name === itemToBeDeleted.name);
                if (index > -1) {
                    (m.listOfFiles as FileObject[]).splice(index, 1);
                }
            })
            this.setState({ multiId });
        }
        l.splice(i as number,1);
        if (selfListed)
            this.setState({ list: l});
        else {
            if(onListChanged)
                onListChanged(l, changes);
        }
    }

    public handleSave(itemId: number | undefined) {
        return new Promise((resolve, reject) => {
            let  list: File[] | FileObject[] = this.state.list.slice();
            const item = {
                listOfFiles: [],
                ...this.props.identificationProps,
            } as {
                listOfFiles: FileObject[];
            }
            if (itemId) {
                // @ts-ignore
                item.itemId = itemId;
            }

            if (this.props.multiMode) {
                if (this.props.identificationProps) {
                    if (this.props.identificationProps.itemId) {
                        (this.props.identificationProps.itemId as number[]).reduce((chain, id) =>
                          chain.then(() => new Promise((resolve1, reject1) => {
                              const databaseInfo = this.state.multiId.find(o => o.itemId === id);

                              let formData = new FormData();
                              // @ts-ignore
                              list.forEach( (attach: File) => {
                                  if (!(attach as FileObject).url) {
                                      formData.append(attach.name, attach);
                                  }
                              });
                              item.listOfFiles = [];
                              item.listOfFiles = databaseInfo ?
                                (databaseInfo.listOfFiles as FileObject[])
                                  .filter(o => typeof o.url !== 'undefined') : [];
                              formData.set('values', JSON.stringify({
                                  ...item,
                                  itemId: id,
                                  id: databaseInfo ? (databaseInfo.id ? databaseInfo.id : undefined) : undefined
                              }));
                              if (databaseInfo && databaseInfo.id) {
                                  request.put('/items/items', formData)
                                    .then(res => {
                                        if (res.status === 200) {
                                            resolve1();
                                        }
                                    }).catch(err => reject1(err))
                              } else {
                                  request.post('/items/items', formData)
                                    .then(res => {
                                        if (res.status === 200) {
                                            resolve1();
                                        }
                                    }).catch(err => reject1(err))
                              }

                          })), Promise.resolve())
                          .then(() => {
                              this.props.onMultiChange && this.props.onMultiChange(this.state.multiId)
                              resolve();
                          })
                          .catch(err => reject(err))
                    }
                }
            } else {
                let formData = new FormData();
                // @ts-ignore
                list.forEach( (attach: File) => {
                    if ((attach as FileObject).url)
                        item.listOfFiles.push(attach);
                    else {
                        formData.append(attach.name, attach);
                    }
                });
                formData.append('values', JSON.stringify({
                    ...item,
                    id: this.state.id,
                }));
                if (!this.state.id) {
                    request.post('/items/items', formData)
                      .then(res => {
                          if (res.status === 200) {
                              resolve();
                          }
                      }).catch(err => reject(err))
                } else {
                    request.put('/items/items', formData)
                      .then(res => {
                          if (res.status === 200) {
                              resolve();
                          }
                      }).catch(err => reject(err))
                }
            }
        })
    }

    private renderBody(fileViewer: boolean) {
        const { selfListed, smSize, mdSize } = this.props;
        const list = selfListed ? this.state.list.slice() :
          (this.props.list ? this.props.list.slice() : []);
        const { onListChanged, updating, useModal, askBeforeDeleteFile } = this.props;
        const { actualBigPreview } = this.state;

        return <>
            {updating && <>
                <input onChange={(e) => this.uploadFile(e)}
                       multiple
                       id='newFile' type='file'
                       accept='image/*|video/*|.pdf' className='inputfile'/>
                <label htmlFor='newFile'>
                    <FontAwesome style={{ marginRight: '10px', fontSize: '16px', color: '#055ADA' }} name='fas fa-plus-circle' />
                    Nový soubor
                </label>
            </>}

            {fileViewer ? <FileViewer disableHover width='80%' height='500px' index={actualBigPreview} file={list[actualBigPreview]}/> : null}
            <Row>
                {
                    // @ts-ignore
                    list.map((file: File | FileObject, index: number) => {
                        return <Col md={mdSize} sm={smSize}>
                            <FileViewer onDelete={this.onFileDelete} index={index} disableUpdating={!updating}
                                        askBeforeDeleteFile={askBeforeDeleteFile}
                                        onClick={(file) => this.setState({
                                            actualBigPreview: index,
                                            show: !useModal ? true: this.state.show
                                        })}
                                        key={index} file={file}/>
                        </Col>
                    })
                }
            </Row>
            {
                this.props.saveButton && this.props.updating && <SubmitButton style={{maxWidth: '300px', margin: 'auto', marginTop: '10px'}}
                                                       onClick={() => this.handleSave(undefined).then(() => this.setState({ show: false }))}
                                                       bsClass='btn btn-primary btn-block'>
                    {'Uložit'}
                </SubmitButton>
            }
        </>;
    }

    render() {
        const { selfListed, useModal } = this.props;
        const list = selfListed ? this.state.list.slice() :
          (this.props.list ? this.props.list.slice() : []);

        const modal = this.state.show ? <Modal dialogClassName="modal-90w" show={this.state.show}
                             onHide={() => {
                                 this.setState({
                                     show: false,
                                     actualBigPreview: !useModal ? -1: this.state.actualBigPreview,
                                 })
                             }}>
            <Modal.Header closeButton>
                <h3 className='text-center'>Přílohy</h3>
            </Modal.Header>
            <Modal.Body>
                { this.renderBody(true) }
            </Modal.Body>
        </Modal> : null;

        return <>
            {useModal ? <>
                <IconWithNumber awesomeIconName='fas fa-paperclip'
                                color='grey'
                                style={{padding: '0px', marginLeft: '-5px'}}
                                onClick={() => {
                                    this.setState({show: true});
                                }} number={list.length}/>
                { this.state.show ? modal : null }
                </> : <>
                    {this.renderBody(false)}
                    {modal}
                </>
            }


        </>
    }
}