import React, { useState } from "react";
// @ts-ignore
import DateTimePicker from 'react-datetime-picker'
import {useDispatch, useSelector} from "react-redux";
import {selectDataRowValue} from "./selector";
import {setDataRowValue} from "@cml/redux-store";
import {dateFormatVisible, dateFromat} from "../../../AppObjects";
import moment, {Moment} from "moment";
import {SingleDatePicker} from "react-dates";

export default function DrDate() {

  const date = useSelector(selectDataRowValue(0, true, 'date'));

  const dispatch = useDispatch();

  const onChange = (date: Moment | null) => {
    if (date) {
      dispatch(setDataRowValue(0, true, {
        date: date.format(dateFromat),
      }))
    }
  }

  const [datePickerFocused, setDatePickerFocused] = useState(false);

  return <td>
    <SingleDatePicker
      small
      date={date ? moment(date) : null} // momentPropTypes.momentObj or null
      displayFormat={ dateFormatVisible }
      isOutsideRange = {() => false}
      onDateChange={onChange} // PropTypes.func.isRequired
      focused={datePickerFocused} // PropTypes.bool
      onFocusChange={({ focused }) => setDatePickerFocused(!datePickerFocused)} // PropTypes.func.isRequired
      id="your_unique_id" // PropTypes.string.isRequired,
    />
  </td>
}