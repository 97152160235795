import {WithContext as ReactTags} from "react-tag-input";
import {Attributes, Roles, Tools} from "@cml/models";
import {CanView} from "../../Main";
import {IDispatchingAttributesCustom} from "@cml/types";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";
import React from "react";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {getTools} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
}

export default function DrTools({ index, attribute, singleRowEditMode }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchOther = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).other;

  const toolsData = useSelector(selectDataRowValue(index, singleRowEditMode, 'tools'));


  const id = useSelector(selectDataRowValue(index, singleRowEditMode, 'id'));

  const { tools, isValidating: toolsValidating } = getTools(
    { params: { where: { isAvailable: 1 } }}, )
  
  const handleTagDelete = (i: number) => {
    if (i < 0) return;
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      tools: toolsData.filter((tool: any, index: number) => index !== i)
    }))
  }

  const handleTagAddition = (tool: Tools) => {
    if (typeof tool.isAvailable === 'undefined')
      return;
    const list = toolsData.slice();
    // @ts-ignore
    list.push({tool: tool});
    store.dispatch(setDataRowValue(index, singleRowEditMode, {
      tools: list
    }));
  }

  if (!tools)
    return <div>Loading</div>


  // @ts-ignore
  const tags = <ReactTags tags={toolsData.map(a => ({
    id: a.tool.id.toString(),
    nameWithIdentifier: a.tool.nameWithIdentifier}))}
                          minQueryLength={1}
                          placeholder="Jméno vybavení"
                          autofocus={false}
                          handleDelete={handleTagDelete}
                          handleAddition={handleTagAddition}
                          labelField={'nameWithIdentifier'}
                          suggestions={tools.filter(o => o.isAvailable === 1)}
                          delimiters={[13]} />;
  return <CanView visible={(attribute.customOptions as IDispatchingAttributesCustom).canTool
  && dispatchOther.canTool}>
    <td>
      {
        singleRowEditMode ? tags : <>
          <OverlayTrigger
            placement="bottom"
            trigger="click"
            overlay={
              <Popover id={`popover-tool ${id}`} title="">
                {tags}
              </Popover>
            }>
            <Button bsStyle='primary' bsSize="xsmall">
              {
                toolsData.length === 0 ? '------' :
                  toolsData.map((a: { tool: { identifier: string; }; }) => {
                    return a.tool.identifier + ', ';
                  })
              }
            </Button>
          </OverlayTrigger>
        </>
      }
    </td>
  </CanView>
}