import FontAwesome from "react-fontawesome";
import React, {useState} from "react";
import {YesNoDialog} from "../../YesNoDialog";
import {LoadingOrRender} from "../../../loginsrc/Profile";
import request from 'axios';
import {useDispatch, useSelector} from "react-redux";
import {AuthenticateState, deleteDataRow} from "@cml/redux-store";
import { Roles } from "@cml/models";
import {DispatchingRights} from "@cml/types/dist/types/roles";


interface Props {
  requestedRowChange: () => void;
  id: number;
  index: number;
}

export default function ResultRowUpdateOrDelete ({ requestedRowChange, id, index }: Props) {

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const authenticate =
    useSelector((state: {authenticate: AuthenticateState}) => state.authenticate);


  const onDelete = () => {
    setLoading(true);
    request.delete('/items/datarows', {
      data: {id}
    }).then(() => {
      setLoading(false);
      dispatch(deleteDataRow(index));
    }).catch(() => setLoading(false))
  }

  return <div>
    {(((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).update) && <button className='transparentButton' onClick={(e) => {
      e.preventDefault();
      requestedRowChange();
    }}>
      <FontAwesome style={{fontSize:'14px'}} name='fas fa-pen' />
    </button> }
    {(((authenticate.actualUserRole as Roles).dispatching as DispatchingRights).delete && id > 0) && <LoadingOrRender height={15} width={15} requestActive={loading}>
      <YesNoDialog fontSize={14} onYes={onDelete}
                   message='Opravdu chcete vymazat řádek? tato akce nelze vrátit zpět'/>
    </LoadingOrRender>}

  </div>
}