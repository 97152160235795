import React, {Component} from 'react';
import {
  Form, FormControl, FormGroup, ControlLabel, Row, Col, HelpBlock
} from 'react-bootstrap';
import ReactPhoneInput from 'react-phone-input-2';
import request from 'axios';
import PropTypes from 'prop-types';
import SubmitButton from './SubmitButton';
import FontAwesome from 'react-fontawesome';
import ReactLoading from 'react-loading';
import {passwordChecker} from '../AppObjects'
import {BackHeader} from "../components/Setting";
import {YesNoDialog} from "../components/YesNoDialog";
import store from "../redux/store";
import { show } from 'react-notification-system-redux';

const formObject = {
  email: 'email',
  telnumb: 'telnumb',
  password: 'password',
  name: 'name',
  surname: 'surname',
  password2: 'password2',
};

class LoadingOrRender extends Component {
  render() {
    let toRender = this.props.children;
    if (this.props.requestActive) toRender = <div style={{display: 'inline-block'}}>
      <ReactLoading type={'spinningBubbles'} color={'#337ab7'} height={this.props.height} width={this.props.width}/>
    </div>;
    return (toRender)
  }
}

LoadingOrRender.propTypes = {
  requestActive: PropTypes.bool.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
};

LoadingOrRender.defaultProps = {
  height: '40px',
  width: '40px',
};

class UpdateButtonOnHover extends Component {
  constructor(props) {
    super(props);
  }

  renderButton(show) {

    let subButton = null;
    if (this.props.useSubmitButton) {
      subButton = <td style={{width:'40%'}}>
        <SubmitButton loading={this.props.requestActive}
                      loadH={20}
                      loadW={20}
                      bsClass='transparentButton'>
          <FontAwesome name='fas fa-check'/>
        </SubmitButton>
      </td>;
    } else {
      subButton = <td style={{width:'40%'}}>
        <button onClick={(e) => {
          e.preventDefault();
          this.props.onOk()
        }} className='transparentButton'>
          <FontAwesome name='fas fa-check'/>
        </button>
      </td>
    }

    if(this.props.hideButtons)
      return null;

    if (show) {
      return <table style={{width:'100%'}}>
        <tbody>
          <td style={{width:'50%'}}>
            <button style={{float: 'left'}} onClick={() => this.props.onEditChange(true)}
                    className='transparentButton'>
              <FontAwesome style={{ fontSize: '16px' }} name='fas fa-pen'/>
            </button>
          </td>
          {
            this.props.deleteButton ? <td style={{width:'50%'}}>
              <YesNoDialog onYes={() => this.props.onDelete()} fontSize={'16px'} message={'Opravdu chcete vymazat daný záznam'}/>
            </td> : null
          }
        </tbody>
      </table>
    } else {
      return <table style={{width:'100%'}}>
        <tbody >
        <tr>
          {subButton}
          {
            this.props.disableCloseButton ? null : <td style={{width:'60%'}}>
              <div className='update-button' onClick={() => {
                this.props.onEditChange(false);
                if (typeof this.props.onBack !== 'undefined')
                  this.props.onBack();
              }}>
                <FontAwesome name='fas fa-times'/>
              </div>
            </td>
          }

        </tr>
        </tbody>
        </table>
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (typeof this.props.onOk !== 'undefined')
      this.props.onOk();
  }

  render() {
    return (<Form onSubmit={(e) => this.handleSubmit(e)}>
      <Col sm={11} xs={9}>
        {this.props.editing?this.props.editingComponents: this.props.children}
      </Col>
      <Col sm={1} xs={3}>
          {this.renderButton(!this.props.editing)}
      </Col>
    </Form>)
  }
}

UpdateButtonOnHover.propTypes = {
  editingComponents: PropTypes.object,
  useSubmitButton: PropTypes.bool,
  disableCloseButton: PropTypes.bool,
  onBack: PropTypes.func,
  onOk: PropTypes.func,
  onEditChange: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  requestActive: PropTypes.bool,
  deleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  hideButtons: PropTypes.bool,
  useCheckButton: PropTypes.bool,
};

UpdateButtonOnHover.defaultProps = {
  editingComponents: null,
  useSubmitButton: true,
  disableCloseButton: false,
  requestActive: false,
  deleteButton: false,
  hideButtons: false,
  useCheckButton: false,
  onDelete: () => {},
  onOk: () => {},
};

export {
  LoadingOrRender,
  UpdateButtonOnHover,
}

export default class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      email: '',
      newemail: '',
      telnumb: '',
      requestActive: true,
      emailUpdateFirstStage: true,
      email2StageToken: '',
      email2StagePassword: '',
      password: '',
      password2: '',
      oldpassword: '',
      [formObject.name + 'Edit']: false,
      [formObject.surname + 'Edit']: false,
      [formObject.email + 'Edit']: false,
      [formObject.telnumb + 'Edit']: false,
      [formObject.password + 'Edit']: false,
      [formObject.name + 'Active']: false,
      [formObject.surname + 'Active']: false,
      [formObject.email + 'Active']: false,
      [formObject.telnumb + 'Active']: false,
      [formObject.password + 'Active']: false,
    };
    this.user = null;
    this.emailChangeToken = null;
  }

  handleSubmit(which) {

    switch (which) {
      case formObject.name:
      case formObject.surname:
      case formObject.telnumb:
        let OK = true;
        if (which === formObject.telnumb)
          OK = this.checkTelInput(true);
        if (OK) {
          this.setState({[which + 'Active']: true});
          request.put('/items/user', {
            id: this.user.id,
            [which]: this.state[which],
          }, { timeout: 4000 })
            .then((res) => {
              this.setState({[which + 'Active']: false});
              if (res.status === 200) {
                this.setState({[which + 'Edit']: false});
                this.user[which] = this.state[which];
              }
            })
            .catch((err) => {
              this.setState({[which + 'Active']: false});
            });
        }

        break;
      case formObject.password:
        if (this.checkPasswordInputs(true)) {
          this.setState({[which + 'Active']: true});
          request.put('/items/user-password-email', {
            id: this.user.id,
            oldpassword: this.state.oldpassword,
            password: this.state.password,
          }, { timeout: 5000 })
            .then((res) => {
            this.setState({[which + 'Active']: false});
            if (res.status === 200) {
              this.setState({[formObject.password + 'Edit']: false});
            }
          })
            .catch((err) => {
              this.setState({[which + 'Active']: false});
              if(err.status === 403) {
                store.dispatch(show({
                  title: 'Chyba !',
                  level: 'error',
                  autoDismiss: 3,
                  children: (
                    <div>
                      Špatné původní heslo !
                    </div>
                  ),
                }, 'error'));
              }
            });
        }
        break;
      case formObject.email:
        if (this.state.emailUpdateFirstStage) {
          this.setState({[which + 'Active']: true});
          request.put('/items/changeuseremail', {
            newemail: this.state.newemail,
          }, { timeout: 4000})
            .then((res) => {
              if (res.status === 200) {
                this.emailChangeToken = res.data.token;
                this.setState({
                  emailUpdateFirstStage: false,
                })
              }
              this.setState({[which + 'Active']: false});
            })
            .catch((err) => {
              if (err.status === 403) {
                store.dispatch(show({
                  title: 'Chyba !',
                  level: 'error',
                  autoDismiss: 3,
                  children: (
                    <div>
                      Zadaný email již existuje !!
                    </div>
                  ),
                }, 'error'));
              }
              this.setState({[which + 'Active']: false});
            })
        } else {
          if (this.emailChangeToken === this.state.email2StageToken) {
            this.setState({[which + 'Active']: true});
            request.put('/items/user-password-email', {
              id: this.user.id,
              oldpassword: this.state.email2StagePassword,
              email: this.state.newemail,
            }, { timeout: 4000 })
              .then((res) => {
                this.setState({[which + 'Active']: false});
                if (res.status === 200) {
                  this.user.email = this.state.newemail;
                  this.setState({[formObject.email + 'Edit']: false});
                  window.location.reload();
                }
              })
              .catch((err) => {
                this.setState({[which + 'Active']: false});
                if(err.status === 403) {
                  store.dispatch(show({
                    title: 'Chyba !',
                    level: 'error',
                    autoDismiss: 3,
                    children: (
                      <div>
                        Špatné heslo !
                      </div>
                    ),
                  }, 'error'));
                }
              });
          } else {
            store.dispatch(show({
              title: 'Chyba !',
              level: 'error',
              autoDismiss: 3,
              children: (
                <div>
                  Spatný potvrzovací kod !
                </div>
              ),
            }, 'error'));
          }

        }

        break;

    }

  }

  componentWillMount() {
    request.get('/userprofile', { timeout: 4000 })
      .then((res) => {
        if (res.status === 200) {
          this.setState(res.data);
          this.user = res.data;
        }
        this.setState({requestActive: false});
      })
      .catch((err) => {
        this.props.history.replace('/');
        return;
      })
  }

  checkTelInput(canFocus) {
    let helpMessage = [];
    helpMessage[formObject.telnumb] = {
      message: null,
      error: this.state.telnumb.length < 10,
    };

    switch (this.state.focusedInput) {
      case formObject.telnumb:
        if (helpMessage[formObject.telnumb].error)
          helpMessage[formObject.telnumb].message = <HelpBlock>
            <ul>
              <li>Telefonní číslo musí být zadáno ve správném formátu</li>
            </ul>
          </HelpBlock>;
        break;
    }

    let ok = true;
    if(canFocus) {
      for (let key in helpMessage) {
        if(helpMessage[key].error){

          this.setState({focusedInput: key});
          ok = false;
          break;
        }
      }
      return ok;
    }
    return helpMessage;
  }

  checkPasswordInputs(canFocus) {
    let passStatus = passwordChecker(this.state.password);
    let pass2Status = passwordChecker(this.state.password2);
    let passSame = this.state.password2 === this.state.password;
    let notSameMessage = !passSame && !pass2Status.error ? <HelpBlock>
      <ul>
        <li>Hesla nejsou stejná !</li>
      </ul>
    </HelpBlock> : null;

    let helpMessage = [];
    helpMessage[formObject.password] = {
      message: null,
      error: passStatus.error,
    };
    helpMessage[formObject.password2] = {
      message: null,
      error: pass2Status.error | !passSame,
    };
    switch (this.state.focusedInput) {
      case formObject.password:
        helpMessage[formObject.password].message = passStatus.messages;
        break;
      case formObject.password2:
        helpMessage[formObject.password2].message = <div>
          {pass2Status.messages}
          {notSameMessage}
        </div>;
        break;
    }
    let ok = true;
    if(canFocus) {
      for (let key in helpMessage) {
        if(helpMessage[key].error){

          this.setState({focusedInput: key});
          ok = false;
          break;
        }
      }
      return ok;
    }

    return helpMessage;
  }

  render() {

    let passwordMessage = this.checkPasswordInputs(false);
    let telMessage = this.checkTelInput(false);

    let emailForm = null;
    if (this.state.emailUpdateFirstStage) {
      emailForm = <div>
        <FormGroup controlId="email">
          <Col smOffset={3} xsOffset={4} sm={9} xs={8}>
            <FormControl type="email"
                         value={this.state.newemail}
                         placeholder="Nový email"
                         onChange={(e) => this.setState({newemail: e.target.value})}/>
          </Col>

        </FormGroup>
        <FormGroup>
          <Col smOffset={3} xsOffset={4} sm={9} xs={8}>
            <SubmitButton
              bsClass='btn btn-primary btn-block'
              loading={this.state[formObject.email + 'Active']}>Odeslat</SubmitButton>
          </Col>
        </FormGroup>
      </div>
    } else {
      emailForm = <div>
        <FormGroup controlId="emailchangetoken"
                   validationState={this.emailChangeToken !== this.state.email2StageToken ? 'error' : 'success'}>
          <Col smOffset={3} xsOffset={4} sm={9} xs={8}>
            <FormControl type="text"
                         value={this.state.email2StageToken}
                         placeholder="Potvrzovaci kod"
                         onChange={(e) => this.setState({email2StageToken: e.target.value})}/>
          </Col>

        </FormGroup>
        <Col smOffset={3} xsOffset={4} sm={9} xs={8}>
          <FormGroup controlId='emailchangetokenpassword'>
            <FormControl type="password"
                         value={this.state.email2StagePassword}
                         placeholder="Heslo"
                         onChange={(e) => this.setState({email2StagePassword: e.target.value})}/>
          </FormGroup>
        </Col>
        <FormGroup>
          <Col smOffset={3} xsOffset={4} sm={9} xs={8}>
            <SubmitButton
              bsClass='btn btn-primary btn-block'
              loading={this.state[formObject.email + 'Active']}>Odeslat</SubmitButton>
          </Col>
        </FormGroup>
      </div>
    }

    return <>
      <BackHeader
        headerText={'Profil'}
        onBackClick={() => this.props.history.replace(
          this.props.match.url.split('/profile')[0])}/>
      <div className="profile">

        <div className='nest'>
          <LoadingOrRender requestActive={this.state.requestActive}>
            <Row>
              <Col sm={3} xs={12}>
                <div className='illustration'>
                  <FontAwesome name='fas fa-user'/>
                </div>
              </Col>
              <Col sm={9} xs={12}>
                <UpdateButtonOnHover
                  editing={this.state[formObject.name + 'Edit']}
                  requestActive={this.state[formObject.name + 'Active']}
                  onEditChange={(val) => this.setState({[formObject.name + 'Edit']: val})}
                  onBack={() => this.setState({name: this.user.name})}
                  onOk={() => this.handleSubmit(formObject.name)}
                  editingComponents={
                    <FormGroup controlId="name">
                      <Col sm={3} xs={4}>
                        <ControlLabel>
                          Jméno
                        </ControlLabel>
                      </Col>
                      <Col sm={9} xs={8}>
                        <FormControl
                          type="text"
                          value={this.state.name}
                          placeholder="Jméno"
                          onChange={(e) => this.setState({name: e.target.value})}
                        />
                      </Col>
                    </FormGroup>
                  }>
                  <FormGroup controlId="name">
                    <Col sm={3} xs={4}>
                      <ControlLabel>
                        Jméno
                      </ControlLabel>
                    </Col>
                    <Col sm={9} xs={8}>
                      <div>{this.state.name}</div>
                    </Col>
                  </FormGroup>
                </UpdateButtonOnHover>

                <UpdateButtonOnHover
                  editing={this.state[formObject.surname + 'Edit']}
                  requestActive={this.state[formObject.surname + 'Active']}
                  onEditChange={(val) => this.setState({[formObject.surname + 'Edit']: val})}
                  onBack={() => this.setState({surname: this.user.surname})}
                  onOk={() => this.handleSubmit(formObject.surname)}
                  editingComponents={
                    <FormGroup controlId="surname">

                      <Col sm={3} xs={4}>
                        <ControlLabel>
                          Příjmení
                        </ControlLabel>
                      </Col>
                      <Col sm={9} xs={8}>
                        <FormControl
                          type="text"
                          value={this.state.surname}
                          placeholder="Příjmení"
                          onChange={(e) => this.setState({surname: e.target.value})}
                        />
                      </Col>
                    </FormGroup>
                  }>
                  <FormGroup controlId="surname">

                    <Col sm={3} xs={4}>
                      <ControlLabel>
                        Příjmení
                      </ControlLabel>
                    </Col>
                    <Col sm={9} xs={8}>
                      <div>{this.state.surname}</div>
                    </Col>
                  </FormGroup>
                </UpdateButtonOnHover>

                <UpdateButtonOnHover
                  editing={this.state[formObject.telnumb + 'Edit']}
                  requestActive={this.state[formObject.telnumb + 'Active']}
                  onEditChange={(val) => this.setState({[formObject.telnumb + 'Edit']: val})}
                  onBack={() => this.setState({telnumb: this.user.telnumb})}
                  onOk={() => this.handleSubmit(formObject.telnumb)}
                  editingComponents={
                    <FormGroup controlId={formObject.telnumb}
                               validationState={telMessage[formObject.telnumb].error ? 'error' : 'success'}>
                      <Col sm={3} xs={4}>
                        <ControlLabel>
                          Telefon
                        </ControlLabel>
                      </Col>
                      <Col sm={9} xs={8}>
                        <ReactPhoneInput defaultCountry={'cz'}
                                         inputStyle={{width:'100%'}}
                                         value={this.state.telnumb}
                                         onFocus={() => this.setState({focusedInput: formObject.telnumb})}
                                         onChange={(value) => this.setState({telnumb: value})}/>
                        {telMessage[formObject.telnumb].message}
                      </Col>
                    </FormGroup>
                  }>
                  <FormGroup controlId="telnumb">
                    <Col sm={3} xs={4}>
                      <ControlLabel>
                        Telefon
                      </ControlLabel>
                    </Col>
                    <Col sm={9} xs={8}>
                      <div>{this.state.telnumb}</div>
                    </Col>
                  </FormGroup>
                </UpdateButtonOnHover>

                <UpdateButtonOnHover
                  editing={this.state[formObject.email + 'Edit']}
                  onEditChange={(val) => this.setState({[formObject.email + 'Edit']: val})}
                  useSubmitButton={false}
                  onBack={() => this.setState({email: this.user.email, newemail:''})}
                  onOk={() => this.handleSubmit(formObject.email)}
                  editingComponents={emailForm}>
                  <FormGroup controlId="email">
                    <Col sm={3} xs={4}>
                      <ControlLabel>
                        Email
                      </ControlLabel>
                    </Col>
                    <Col sm={9} xs={8}>
                      <div>{this.state.email}</div>
                    </Col>
                  </FormGroup>
                </UpdateButtonOnHover>
                <UpdateButtonOnHover
                  editing={this.state[formObject.password + 'Edit']}
                  requestActive={this.state[formObject.password + 'Active']}
                  onEditChange={(val) => this.setState({[formObject.password + 'Edit']: val})}
                  onOk={() => this.handleSubmit(formObject.password)}
                  editingComponents={
                    <div>
                      <Col smOffset={3} xsOffset={4} sm={9} xs={8}>
                        <FormGroup controlId='oldpassword'>
                          <FormControl type="password"
                                       value={this.state.oldpassword}
                                       placeholder="Staré heslo"
                                       onChange={(e) => this.setState({oldpassword: e.target.value})}/>
                        </FormGroup>
                      </Col>
                      <Col smOffset={3} xsOffset={4} sm={9} xs={8}>
                        <FormGroup controlId={formObject.password}
                                   validationState={passwordMessage[formObject.password].error ? 'error' : 'success'}
                        >
                          <FormControl type="password"
                                       value={this.state.password}
                                       placeholder="Nové heslo"
                                       onFocus={() => this.setState({focusedInput: formObject.password})}
                                       onChange={(e) => this.setState({password: e.target.value})}/>
                          {passwordMessage[formObject.password].message}
                        </FormGroup>
                      </Col>
                      <Col smOffset={3} xsOffset={4} sm={9} xs={8}>
                        <FormGroup controlId={formObject.password2}
                                   validationState={passwordMessage[formObject.password2].error ? 'error' : 'success'}
                        >
                          <FormControl type="password"
                                       value={this.state.password2}
                                       placeholder="Nové heslo znovu"
                                       onFocus={() => this.setState({focusedInput: formObject.password2})}
                                       onChange={(e) => this.setState({password2: e.target.value})}/>
                          {passwordMessage[formObject.password2].message}
                        </FormGroup>
                      </Col>
                    </div>
                  }>
                  <FormGroup controlId="password">
                    <Col sm={3} xs={4}>
                      <ControlLabel>
                        Heslo
                      </ControlLabel>
                    </Col>
                    <Col sm={9} xs={8}>
                      <div>********</div>
                    </Col>
                  </FormGroup>
                </UpdateButtonOnHover>
              </Col>
            </Row>
          </LoadingOrRender>
        </div>

      </div>
    </>
  }
}

Profile.propTypes = {

};
