import React, {Component, CSSProperties} from "react";
import {getTimeDiff, rowStateColors, rowStateColorsOdd, rowStates, rowStatesSupp} from "./components/datarowBase";
import {dateFormatVisible, dateFromat, getTimeString, roundFloatTwoDigit} from "../../AppObjects";
import moment from "moment";
import {CanView} from "../Main";
import {SmallUserPreview} from "../users/Preview";
import {WithContext as ReactTags} from "react-tag-input";
import { DataRow, Vehicles } from "@cml/models";
import {useSelector} from "react-redux";
import {AuthenticateState} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {BaseCalculationItem, IDispatchingAttributesCustom, IPriceAttributesCustom, OrderOther} from "@cml/types";
import {getAttributes} from "../../fetchApi/dispatching";
import {getCalculation} from "./components/PriceCalculation";

const disabledColor = '#dddddd';

interface Props {
  item: DataRow,
  isForLog: boolean,
  isForPrint: boolean,
  style: CSSProperties,
  pFilter: {
    canNote: boolean;
    canOrderNumber: boolean;
    canVehicle: boolean;
    canRealVehicle: boolean;
    canUser: boolean;
    canTool: boolean;
    canLoadingUnloading: boolean;
    canCompany: boolean;
    canConstruction: boolean;
    canContactPerson: boolean;
    canTime: boolean;
    canFraction: boolean;
    canCalculation: boolean;
    canUseCosts: boolean;
    canLoading: boolean;
  },
  index: number;
}

const swrOption = {
  revalidateOnFocus: false,
  revalidateOnMount: true,
}

export default function DataRowPreview ({ item, isForLog, pFilter, isForPrint, style, index }: Props) {
  const authenticate = useSelector(
    (state: { authenticate: AuthenticateState}) => state.authenticate);

  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes('price',
    swrOption
  );
  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes('material', swrOption);
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes('store', swrOption);
  if(!authenticate.actualUserRole || !priceAttributes || !materialAttributes || !storeAttributes)
    return null;
  const dispatchOther = (authenticate.actualUserRole.dispatching as DispatchingRights).other;



  const sum = getTimeDiff(item).sum;

  const { priceEq, totalPrice, totalCost, priceCalcString}: {
    priceEq: BaseCalculationItem[],
    totalPrice: number,
    totalCost: number,
    priceCalcString: string,
  } = getCalculation({
    ...item,
  }, {
    attribute: item.attribute as { customOptions: IDispatchingAttributesCustom},
    // @ts-ignore
    materialAttributes,
    // @ts-ignore
    priceAttributes,
    storeAttributes,
    defaultMaterialTable: item.order ? (item.order.other as OrderOther).materialTable : undefined,
  })

  const canOrderNumber = isForPrint ? pFilter.canOrderNumber :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canOrderNumber
    && dispatchOther.canOrderNumber;
  const canVehicle = isForPrint ? pFilter.canVehicle :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canVehicle
    && dispatchOther.canVehicle;
  const canUser = isForPrint ? pFilter.canUser :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canUser
    && dispatchOther.canUser;
  const canTool = isForPrint ? pFilter.canTool :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canTool
    && dispatchOther.canTool;
  const canLoading = isForPrint ? pFilter.canLoading :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canLoading
    && dispatchOther.canLoadingUnloading;
  const canCompany = isForPrint ? pFilter.canCompany :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canCompany
    && dispatchOther.canCompany;
  const canConstruction = isForPrint ? pFilter.canConstruction :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canConstruction
    && dispatchOther.canConstruction;
  const canContactPerson = isForPrint ? pFilter.canContactPerson :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canContactPerson
    && dispatchOther.canContactPerson;
  const canTime = isForPrint ? pFilter.canTime :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canTime
    && dispatchOther.canTime;
  const canFraction = isForPrint ? pFilter.canFraction :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canFraction
    && dispatchOther.canFraction;
  const canCalculation = isForPrint ? pFilter.canCalculation :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canCalculation
    && dispatchOther.canCalculation;
  const canUseCosts = isForPrint ? pFilter.canUseCosts :
    (item.attribute.customOptions as IDispatchingAttributesCustom).canUseCosts
    && dispatchOther.canUseCosts;


  return <tr style={{
    backgroundColor: index % 2 === 0 ? rowStateColors[item.isConfirmed] : rowStateColorsOdd[item.isConfirmed],
    ...style,
  }}>
    <td>
      {moment(item.date, dateFromat).format(dateFormatVisible)}
    </td>
    <CanView visible={!isForLog && !isForPrint}>
      <td>
        {item.attribute.name.substring(0,6)}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canOrderNumber)}>
      <td style={{backgroundColor: canOrderNumber ? '' : disabledColor}}>
        {canOrderNumber ? (item.order ? item.order.label : null) : null}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canVehicle)}>
      <td style={{backgroundColor: canVehicle ? '' : disabledColor}}>
        {canVehicle ? (item.vehicle ? (item.vehicle as Vehicles).identifier : item.vehicleRaw) : null}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canUser)}>
      <td style={{backgroundColor: canUser ? '' : disabledColor}}>
        {item.attribute.customOptions
        && (item.attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook ?
          (canUser ? <SmallUserPreview /*iconColor={item.attribute.color}*/ item={{name: item.addressBookItem !== null ? item.addressBookItem.companyWithIc : item.userRaw, surname: ''}}/> : null)
          : (canUser ? <SmallUserPreview /*iconColor={item.attribute.color}*/ item={item.user}/> : null)
        }
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canTool)}>
      <td style={{backgroundColor: canTool ? '' : disabledColor}}>
        {
          canTool ? <ReactTags
            // @ts-ignore
            tags={item.tools.map(a => a.tool)}
                               labelField={'name'}
                               readOnly /> : null
        }
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canLoading)}>
      <td style={{backgroundColor: canLoading ? '' : disabledColor}}>
        {canLoading ? item.loading : null}
      </td>
      <td style={{backgroundColor: canLoading ? '' : disabledColor}}>
        {canLoading ? item.unloading : null}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canCompany)}>
      <td style={{backgroundColor: canCompany ? '' : disabledColor}}>
        {canCompany ? item.company : null}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canConstruction)}>
      <td style={{backgroundColor: canConstruction ? '' : disabledColor}}>
        {canConstruction ? item.construction : null}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canContactPerson)}>
      <td style={{backgroundColor: canContactPerson ? '' : disabledColor}}>
        {item.contactUser && <SmallUserPreview item={item.contactUser}/>}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canTime)}>
      <td style={{backgroundColor: canTime ? '' : disabledColor}}>
        {canTime ? getTimeString(item.from): null}
      </td>
      <td style={{backgroundColor: canTime ? '' : disabledColor}}>
        {canTime ? getTimeString(item.to) : null}
      </td>
      <td style={{backgroundColor: canTime ? '' : disabledColor}}>
        {canTime ? getTimeString(item.break) : null}
      </td>
      <td style={{backgroundColor: canTime ? '' : disabledColor}}>
        {canTime ? getTimeString(sum) : null}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canFraction)}>
      <td style={{backgroundColor: canFraction ? '' : disabledColor}}>
        {canFraction ? item.fraction : null}
      </td>
    </CanView>
    <CanView visible={!isForPrint}>
      <td>{item.attribute.customOptions &&
      (item.attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook
        ? rowStatesSupp[item.isConfirmed] : rowStates[item.isConfirmed]}</td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canCalculation)}>
      <td style={{backgroundColor: canCalculation ? '' : disabledColor}}>
        {canCalculation ? priceCalcString : null}
      </td>
      <td style={{backgroundColor: canCalculation ? '' : disabledColor}}>
        {canCalculation ? totalPrice : null}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canUseCosts)}>
      <td style={{backgroundColor: canUseCosts ? '' : disabledColor}}>
        {canUseCosts ? totalCost : null}
      </td>
    </CanView>
    <CanView visible={!isForLog || (isForLog && canCalculation)}>
      <td style={{color: totalPrice - totalCost < 0 ? 'red' : 'darkgreen',
        backgroundColor: canCalculation ? '' : disabledColor}}>
        {`${totalPrice - totalCost < 0 ? '' : '+'}${(canCalculation ? (totalPrice - totalCost) : 0)}`}
      </td>
    </CanView>
  </tr>
}

DataRowPreview.defaultProps = {
  isForLog: false,
  isForPrint: false,
  style: {},
  pFilter: {
    canOrderNumber: true,
    canVehicle: true,
    canUser: true,
    canTool: true,
    canLoading: true,
    canCompany: true,
    canConstruction: true,
    canContactPerson: true,
    canTime: true,
    canFraction: true,
    canCalculation: true,
    canUseCosts: true,
  }
};
