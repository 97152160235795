import React, { Component } from 'react';
import {Checkbox, Col, DropdownButton, Row} from "react-bootstrap";
import request from 'axios';
import {SmallUserPreview} from "../users/Preview";
import { BaseFilterPropTypes } from '../CommonListContainer';
import AttributePreview from '../attributes/Preview';
import {DateRangePicker} from "react-dates";
import {dateFormatVisible, dateFromat, getFilterList} from "../../AppObjects";
import PropTypes from 'prop-types';
import ItemsSelector from "../ItemsSelector";

export default class AttendanceFiltr extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      attributes: [],
      selectedAttributes: [],
      selectedUsers: [],
      startDate: null,
      endDate: null,
      focusedInput: null,
      userFilterValue: '',
    }
  }

  handleDateChange(startDate, endDate) {
    const { selectedAttributes, selectedUsers } = this.state;
    this.setState({ startDate, endDate });
    this.doFilter(startDate, endDate, selectedUsers, selectedAttributes);
  }

  getNumberOfDays(id) {
    let list = this.props.items;
    return list.filter(x => x.attributeId === id).reduce((prev, cur) => {
      return prev + cur.workingDays;
    },0);
  }

  doFilter(startDate, endDate, users, attributes) {
    this.props.onFilter({
      params: {
        where: {
          $or: endDate !== null ? {
            from: {
              $between: [startDate.format(dateFromat), endDate.format(dateFromat)]
            },
            to: {
              $between: [startDate.format(dateFromat), endDate.format(dateFromat)]
            },
            $and: {
              from: {
                $lte: endDate.format(dateFromat)
              },
              to: {
                $gte: endDate.format(dateFromat)
              },
            },
          } : undefined,
          userId: users.length === 0 ? undefined : users,
          attributeId: attributes.length === 0 ? undefined: attributes,
        }
      }
    })
  }

  render() {
    const { startDate, endDate, selectedAttributes, selectedUsers } = this.state;
    return (
      <div style={{ marginLeft: '20px'}} className='text-left fixHowirizonalScroll filterStyle'>
        <Row>
          <Col sm={3}>
            <DateRangePicker
              startDate={startDate} // momentPropTypes.momentObj or null,
              startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
              startDatePlaceholderText='Začátek'
              endDatePlaceholderText='Konec'
              displayFormat={ dateFormatVisible }
              small
              isOutsideRange = {() => false}
              minimumNights={0}
              endDate={endDate} // momentPropTypes.momentObj or null,
              endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => this.handleDateChange( startDate, endDate )} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
            />
          </Col>
          <Col sm={3}>
            <ItemsSelector
              value={'nameSurname'}
              defaultQuery={{attributes: ['name', 'id', 'surname', 'nameSurname'] }}
              preview={SmallUserPreview}
              listChanged={(list, isFromInit) => {
                this.setState({
                  selectedUsers: list,
                });
                if (!isFromInit)
                  this.doFilter(startDate, endDate, list, selectedAttributes);
              }}
              //overrideToggle={'Filtr uživatelů'}
              appendItemsToStart={[
                { nameSurname: 'Všichni', name: 'Všichni', surname: '', id: 0 },
              ]}
              filterEnable
              isFilterable
              otherPreviewProps={{
                style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
              }}
              itemsToFetch={'users'}/>
          </Col>
          <Col sm={6}>
            <ItemsSelector
                           preview={AttributePreview}
                           listChanged={(list, isFromInit) => {
                             this.setState({
                               selectedAttributes: list,
                             });
                             if (!isFromInit)
                               this.doFilter(startDate, endDate, selectedUsers, list);
                           }}
                           //overrideToggle={'Filtr docházky'}
                           appendItemsToStart={[
                             { name: 'Všechny', color: '#00000000', id: 0 },
                           ]}
                           isFilterable
                           value='name'
                           otherPreviewProps={{
                             height: '16px',
                             width: '16px',
                             style: {display: 'inline'}
                           }}
                           itemsToFetch={'attributes/attendance'}/>
            {
              Object.keys(this.state.attributes).map((key) => {
                let style = {
                  width: '16px',
                  height: '16px',
                  display: 'inline-block',
                  borderRadius: '50%',
                  marginRight: '10px',
                  verticalAlign: 'middle',
                  backgroundColor: this.state.attributes[key].color,
                };

                if (key === '0')
                  return null;

                let count = this.getNumberOfDays(this.state.attributes[key].id);
                if (count === 0) {
                  return null;
                }

                return <div key={key} style={{ display: 'inline-block', marginLeft: '10px'}}>
                    <div style={style}/>
                    <div style={{ display: 'inline-block', marginRight: '10px'}}>
                      {count}
                    </div>
                  </div>;
              })
            }
          </Col>
        </Row>
      </div>
    )
  }
}

AttendanceFiltr.propTypes = {
  items: PropTypes.array.isRequired,
  ...BaseFilterPropTypes,
};

