import * as React from "react";
import { DiagramEngine } from "@projectstorm/react-diagrams";
import styled from '@emotion/styled';
import _ from "lodash";
import { TaskNodeModel } from "./TaskDescriptionModels";
import { CmlPortLabel } from "../ports/CmlPortLabelWidget";
import FontAwesome from 'react-fontawesome';
import { Modal, Row, Col, FormGroup, Dropdown, FormControl } from "react-bootstrap";
import ItemsSelector from "../../../../ItemsSelector";
import {SmallUserPreview} from "../../../../users/Preview";
import { Button } from "react-bootstrap/lib/InputGroup";
import {EditableTag, Tag} from "../../../../Tag";
import {CmlPortModel} from "../ports/CmlPortModel";
import { Toolkit } from "@projectstorm/react-canvas-core";
import {object} from "prop-types";

const S = {
    Node: styled.div<{ background: string | undefined; selected: boolean }>`
		background-color: ${p => p.background};
		border-radius: 5px;
		font-family: sans-serif;
		color: white;
		border: solid 2px black;
		overflow: visible;
		font-size: 11px;
		border: solid 2px ${p => (p.selected ? 'rgb(0,192,255)' : 'black')};
	`,
    Title: styled.div`
		background: rgba(0, 0, 0, 0.3);
		display: flex;
		white-space: nowrap;
		justify-items: center;
	`,
    TitleName: styled.div`
		flex-grow: 1;
		padding: 5px 5px;
	`,
    Ports: styled.div`
		display: flex;
		background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
	`,
    PortsContainer: styled.div`
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		&:first-child {
			margin-right: 10px;
		}

		&:only-child {
			margin-right: 0px;
		}
	`,
    ModalHeader: styled.div`
        font-size: 17px;
        font-weight: bold;
    `,
    Users: styled.div`
        background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2));
    `,
    UsersHeader: styled.div`
        padding-top: 5px;
        padding-left: 5px;
        font-weight: bold;
    `,
    UsersBody: styled.div`
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 3px;
        padding-right: 3px;
    `
};

export interface TaskNodeProps {
    node: TaskNodeModel;
    engine: DiagramEngine;
}

export interface TaskNodeState {
    configShow: boolean;
}

export class TaskNodeWidget extends React.Component<TaskNodeProps, TaskNodeState>{
    constructor(props: TaskNodeProps) {
        super(props);
        this.state = {
            configShow: false,
        };
    }

    // @ts-ignore
    generatePort = port => {
        return <CmlPortLabel engine={this.props.engine} port={port} key={port.id} />;
    };

    changeUsersList(list: Array<number | object>) {
        const setting = this.props.node.getSetting();

        setting.usersList = list.map(o => ({
            // @ts-ignore
            id: o.id,
            // @ts-ignore
            name: o.name,
            // @ts-ignore
            surname: o.surname
        }));
        this.props.node.setSetting(setting);
    }

    addTask() {
        const setting = this.props.node.getSetting();
        setting.taskList.push({
            description: '',
            id: Toolkit.UID(),
        });
        this.props.node.setSetting(setting);
        this.forceUpdate();
    }

    removeTask(index: number) {
        const setting = this.props.node.getSetting();
        setting.taskList.splice(index, 1);
        this.props.node.setSetting(setting);
        this.forceUpdate();
    }

    changeTaskText(text: string, index: number) {
        const setting = this.props.node.getSetting();
        setting.taskList[index].description = text;
        this.props.node.setSetting(setting);
    }

    addPort() {
        const port = new CmlPortModel(false,
            "out-" + (this.props.node.getOutPorts().length + 1),
            "Výstup " + (this.props.node.getOutPorts().length + 1),
            false);
        this.props.node.addPort(port);
        this.forceUpdate();
    }

    render() {
        return (
            <>
              <Modal dialogClassName="modal-60w"
                     onHide={() => {
                         this.forceUpdate();
                         this.setState({ configShow: false })
                     }}
                     show={this.state.configShow}>
                  <Modal.Header closeButton>
                      <S.ModalHeader>
                          {`Nastavení "${this.props.node.getOptions().name}"`}
                      </S.ModalHeader>
                  </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={3}>
                            <h4 style={{ display: 'inline-block', marginRight: '10px' }}>Název</h4>
                            <FormGroup>
                                <FormControl type='text'
                                             value={this.props.node.getOptions().name}
                                             onChange={(e) => {
                                                 this.props.node.getOptions().name = (e.target as HTMLTextAreaElement).value;
                                                 this.forceUpdate();
                                             }}
                                             placeholder='Jméno'
                                />
                            </FormGroup>
                            <h5 style={{ display: 'inline-block', marginRight: '10px' }}>Čas na vyřešení [h]</h5>
                            <FormGroup>
                                <FormControl type='number'
                                             value={this.props.node.getOptions().timeToSolve}
                                             onChange={(e) => {
                                                 this.props.node.getOptions().timeToSolve = parseInt((e.target as HTMLTextAreaElement).value, 10);
                                                 this.forceUpdate();
                                             }}
                                             placeholder='Čas na vyřešení'
                                />
                            </FormGroup>
                        </Col>
                        <Col smOffset={1} sm={8}>
                            <h4 style={{ display: 'inline-block', marginRight: '10px' }}>Výstupní možnosti</h4>
                            <Dropdown.Toggle onClick={() => this.addPort()} bsSize='xsmall' noCaret>
                                <FontAwesome name='fas fas fa-plus'/>
                            </Dropdown.Toggle>
                            <div>
                                {
                                    this.props.node.getOutPorts().map((port, index) =>
                                        <EditableTag key={index}
                                                     singleRow
                                                     style={{ marginBottom: '5px' }}
                                                     defaultValue={port.getOptions().label}
                                                     onValueChange={(name) => {
                                                         port.getOptions().label = name;
                                                         this.forceUpdate();
                                                     }}
                                                     onCloseClick={() => {
                                                         if (port.getOptions().name !== 'out-1') {
                                                             this.props.node.removePort(port);
                                                             this.forceUpdate();
                                                         }
                                                     }}/>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <h4 style={{ display: 'inline-block', marginRight: '10px' }}>Zodpovědné osoby</h4>
                            <ItemsSelector
                                fullSelectedList
                                multi
                                multiDefaultList={this.props.node.getSetting().usersList}
                                listChanged={(list: Array<object | number>) => this.changeUsersList(list)}
                                bsSize='xsmall'
             
                                value={'nameSurname'}
                                defaultQuery={{attributes: ['name', 'id', 'surname', 'nameSurname'] }}
                                preview={SmallUserPreview}
                                filterEnable
                                otherPreviewProps={{
                                    style: {display: 'inline', paddingLeft: '10px', paddingRight: '10px'}
                                }}
                                itemsToFetch={'users'}/>
                        </Col>
                        <Col sm={8}>
                            <h4 style={{ display: 'inline-block', marginRight: '10px' }}>Úkoly</h4>
                            <Dropdown.Toggle onClick={() => this.addTask()} bsSize='xsmall' noCaret>
                                <FontAwesome name='fas fas fa-plus'/>
                            </Dropdown.Toggle>
                            <div>
                                {
                                    this.props.node.getSetting().taskList.map((task, index) =>
                                        <EditableTag
                                            defaultValue={task.description}
                                            style={{ marginBottom: '5px' }}
                                            key={index}
                                            onCloseClick={() => this.removeTask(index)}
                                            onValueChange={(value: string) => {this.changeTaskText(value, index)}}/>)
                                }
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
              </Modal>
                <S.Node
                    data-default-node-name={this.props.node.getOptions().name}
                    selected={this.props.node.isSelected()}
                    background={'rgb(249,124,0)'}>
                    <S.Title>
                        <S.TitleName>{this.props.node.getOptions().name}</S.TitleName>
                        <button type='button' onClick={(e) => this.setState({ configShow: true })}
                                className='transparentButton'>
                            <FontAwesome name='fas fas fa-cog'/>
                        </button>
                    </S.Title>

                    <S.Users>
                        <S.UsersHeader>Zodpovědné osoby:</S.UsersHeader>
                        <S.UsersBody>
                            {
                                this.props.node.getSetting().usersList.map((user, index) =>
                                    <SmallUserPreview style={{ marginBottom: '2px', color: 'white' }} key={index} item={user} />
                                )
                            }
                        </S.UsersBody>
                    </S.Users>
                    <S.Users>
                        <S.UsersHeader>Úkoly:</S.UsersHeader>
                        <S.UsersBody style={{marginLeft: '5px'}}>

                                {
                                    this.props.node.getSetting().taskList.map((task, index) =>
                                        <li style={{ marginBottom: '1px', color: 'white' }} key={index}>
                                            {`${task.description.substring(0,30)}${task.description.length > 30 ? ' ... ' : ''}`}
                                        </li>
                                    )
                                }

                        </S.UsersBody>
                    </S.Users>
                    <S.Ports>
                        <S.PortsContainer>{_.map(this.props.node.getInPorts(), this.generatePort)}</S.PortsContainer>
                        <S.PortsContainer>{_.map(this.props.node.getOutPorts(), this.generatePort)}</S.PortsContainer>
                    </S.Ports>
                </S.Node>
            </>

        );
    }
}