import React, {Component} from "react";
import {Button, Modal} from "react-bootstrap";
import ModalHeader from "react-bootstrap/lib/ModalHeader";
import ModalBody from "react-bootstrap/lib/ModalBody";
import ItemsSelector, { NewItemAddedContextProvider } from "../../ItemsSelector";
import FontAwesome from "react-fontawesome";
import Checkbox from "react-bootstrap/lib/Checkbox";
import FormControl from "react-bootstrap/lib/FormControl";
import {BaseCalculationItem, IMaterialAttributesCustom, Material} from '@cml/types';
import AttributeEdit from "../../attributes/Edit";

interface Props {
  forDefault?: boolean;
  materialTable: Material[] | null;
  materialAttributes: {
    id: number;
    name: string;
    customOptions: IMaterialAttributesCustom
  }[];
  onTableChanged?: (material: Material[]) => void;
  storeAttributes: {
    id: number
  }[];
  priceAttributes: {
    id: number;
    name: string;
  }[];
  defaultMaterialTable?: Material[]; // From order for example
}

interface State {
  show: boolean;
}

export default class MaterialTable extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: false,
    }
  }

  static GenerateCalculationArray(props: Props) {
    let materialTable: Material[] = [];
    if (props.materialTable) {
      materialTable = props.materialTable;
    }

    let calculation: BaseCalculationItem[] = [];
    materialTable.map((m, index) => {
      if (m.useInCalculation) {
        const defM = props.defaultMaterialTable ? props.defaultMaterialTable
          .find(o => o.storeId === m.storeId && o.attributeId === m.attributeId) : null;
        const matAttribute = props.materialAttributes.find(o => o.id === m.attributeId);
        const storePrice = matAttribute ? matAttribute.customOptions.prices.find(p => p.storeId === m.storeId) : null;
        const priceUnit = storePrice ? props.priceAttributes.find(p => p.id === storePrice.priceId) : null;
        if (priceUnit && storePrice && matAttribute) {
          const price = defM ? defM.defaultPrice as number : storePrice.price;
          calculation.push({
            attributeId: priceUnit.id,
            price: price,
            costPrice: storePrice.basePrice,
            amount: m.amount,
            total: m.amount * price,
            costTotal: m.amount * storePrice.basePrice,
            isMaterial: true,
            name: matAttribute.name,
          })
        }
      }
    })
    return calculation;
  }

  static GenerateString(props: Props, onlyRepeats?: boolean) {
    let materialTable: Material[] = [];
    if (props.materialTable) {
      materialTable = props.materialTable;
    }
    let buttonString = '';

    materialTable.map((m, index) => {
      const matAttribute = props.materialAttributes.find(o => o.id === m.attributeId);
      const storePrice = matAttribute ? matAttribute.customOptions.prices.find(p => p.storeId === m.storeId) : null;
      const priceUnit = storePrice ? props.priceAttributes.find(p => p.id === storePrice.priceId) : null;
      if (onlyRepeats) {
        buttonString += `${index > 0 ? ', ' : ''}${m.repeatAmount}x ${matAttribute ? matAttribute.name : ''}`
      } else {
        buttonString += `${index > 0 ? ', ' : ''}${m.repeatAmount}x ${matAttribute ? matAttribute.name : ''}: ${m.amount} [${priceUnit ? priceUnit.name : ''}]`
      }
    })
    return buttonString;
  }

  render() {
    let materialTable: Material[] = [];
    if (this.props.materialTable) {
      materialTable = this.props.materialTable;
    }

    const { forDefault, defaultMaterialTable } =this.props;

    const buttonString = MaterialTable.GenerateString(this.props);



    const body = <>
      <div className='dataTable text-center' style={{marginBottom: '15px', fontSize: '12px'}}>
        <table>
          <tr>
            {!forDefault && <th>Počet toček</th>}
            {!forDefault && <th>Množství</th>}
            {!forDefault && <th>Jednotka</th>}
            <th>Material</th>
            <th>Místo odběru</th>
            {!forDefault && <th>Použít v celkové ceně</th>}
            <th>Nákup</th>
            <th>Cena / J</th>
          </tr>
          {
            materialTable.map((m, index) => {
              const defM = defaultMaterialTable ? defaultMaterialTable
                .find(o => o.storeId === m.storeId && o.attributeId === m.attributeId) : null;
              const matAttribute = this.props.materialAttributes.find(o => o.id === m.attributeId);
              const storePrice = matAttribute ? matAttribute.customOptions.prices.find(p => p.storeId === m.storeId) : null;
              const priceUnit = storePrice ? this.props.priceAttributes.find(p => p.id === storePrice.priceId) : null;
              // @ts-ignore
              return <tr>
                {!forDefault && <td>
                  <FormControl
                    value={m.repeatAmount}
                    style={{maxWidth: '120px'}}
                    type='number'
                    onFocus={(e) => (e.target as unknown as HTMLInputElement).select()}
                    onChange={e => {
                      const list = materialTable.slice();
                      list[index].repeatAmount = Number((e.target as HTMLInputElement).value);
                      this.props.onTableChanged && this.props.onTableChanged(list);
                    }}
                  />
                </td>}
                {!forDefault && <td>
                  <FormControl
                    value={m.amount}
                    style={{maxWidth: '120px'}}
                    type='number'
                    onFocus={(e) => (e.target as unknown as HTMLInputElement).select()}
                    onChange={e => {
                      const list = materialTable.slice();
                      list[index].amount = Number((e.target as HTMLInputElement).value);
                      this.props.onTableChanged && this.props.onTableChanged(list);
                    }}
                  />
                </td>}
                {!forDefault && <td>{priceUnit ? priceUnit.name : null}</td>}
                <td>
                  <ItemsSelector
                    externList={this.props.materialAttributes}
                    newEditComponent={ AttributeEdit }
                    newEditComponentDefaultProps={{
                      attributeName: 'material',
                    }}
                    defaultSelectedId={m.attributeId}
                    onSelected={(mat: { id: number; }) => {
                      const list = materialTable.slice();
                      list[index].attributeId = mat.id;
                      const matAttribute_ = this.props.materialAttributes.find(o => o.id === mat.id);
                      const storeList = this.props.storeAttributes.filter(s =>
                        matAttribute_ ? matAttribute_.customOptions.prices.filter(p => p.storeId === s.id).length > 0 : false)
                      list[index].storeId = storeList.length > 0 ? storeList[0].id : 0;
                      this.props.onTableChanged && this.props.onTableChanged(list);
                    }}
                    filterEnable
                    itemsToFetch='attributes/material'
                    value='name'/>
                </td>
                <td>
                  <ItemsSelector
                    // @ts-ignore
                    externList={[{id: 0, name: 'Nevybrán'}].concat(this.props.storeAttributes.filter(s =>
                      matAttribute ? matAttribute.customOptions.prices.filter(p => p.storeId === s.id).length > 0 : false))}
                    onSelected={(store: { id: number; }) => {
                      const list = materialTable.slice();
                      list[index].storeId = store.id;
                      this.props.onTableChanged && this.props.onTableChanged(list);
                    }}
                    getExternIndex
                    forceUseExternList
                    defaultSelectedId={m.storeId}
                    filterEnable
                    itemsToFetch='attributes'
                    value='name' />
                </td>
                {!forDefault && <td>
                  <Checkbox
                    checked={m.useInCalculation}
                    onChange={e => {
                      const list = materialTable.slice();
                      list[index].useInCalculation = (e.target as HTMLInputElement).checked;
                      this.props.onTableChanged && this.props.onTableChanged(list);
                    }}
                  />
                </td>}
                <td>{storePrice ? storePrice.basePrice : 'neznámo'}</td>
                {!forDefault ? <td title={defM ? 'Cena ze zakázky' : undefined}>
                    {defM ? defM.defaultPrice : (storePrice ? storePrice.price : 'neznámo')}</td>
                  : <FormControl
                    value={m.defaultPrice}
                    style={{maxWidth: '120px'}}
                    type='number'
                    onFocus={(e) => (e.target as unknown as HTMLInputElement).select()}
                    onChange={e => {
                      const list = materialTable.slice();
                      list[index].defaultPrice = Number((e.target as HTMLInputElement).value);
                      this.props.onTableChanged && this.props.onTableChanged(list);
                    }}
                  />}
                <td>
                  <button className='transparentButton' onClick={(e) => {
                    e.preventDefault();
                    const list = materialTable.slice();
                    list.splice(index, 1);
                    this.props.onTableChanged && this.props.onTableChanged(list);
                  }}>
                    <FontAwesome style={{fontSize:'18px', color: 'grey', }} name='fas fa-times' />
                  </button>
                </td>
              </tr>
            })
          }
        </table>
      </div>
      { this.props.materialAttributes.length > 0 ? <Button onClick={() => {
          const list = materialTable.slice();
          const store =  this.props.materialAttributes[0].customOptions.prices[0];

          list.push({
            repeatAmount: 0,
            amount: 0,
            attributeId: this.props.materialAttributes[0].id,
            storeId: store ? store.storeId : 0,
            useInCalculation: true,
            defaultPrice: forDefault ? store.price : undefined,
          })
          this.props.onTableChanged && this.props.onTableChanged(list);
        }}>
          Nová položka
      </Button> : <div>Neexistují žádné materiálové položky, prosím vyplň v nastavení</div> }
    </>;

    if (this.props.forDefault) {
      return body;
    }


    return [<Modal dialogClassName="modal-60w" onHide={() => {
      this.setState({ show: false });
    }} show={ this.state.show }>
      <ModalHeader closeButton>
        <Modal.Title>
          Tabulka zboží
        </Modal.Title>
      </ModalHeader>
      <ModalBody>
        {body}
      </ModalBody>
    </Modal>,
      <Button bsStyle='primary' bsSize="xsmall" onClick={(e) => this.setState({show: true})}>
        {buttonString !== '' ? buttonString : '----'}
      </Button>
    ]
  }
}