import {getAttributes} from "../../../fetchApi/dispatching";
import ItemsSelector from "../../ItemsSelector";
import React from "react";
import {Attributes} from "@cml/models";
import {useDispatch} from "react-redux";
import {setDataRowValue} from "@cml/redux-store";


export default function DrAttributeSelect() {
  const { attributes, isValidating: attributesValidating } =
    getAttributes('dispatching', {
      revalidateOnFocus: false,
      revalidateOnMount: true,
    });

  const dispatch = useDispatch()

  if (!attributes)
    return <div>loading</div>

  return <ItemsSelector itemsToFetch={'attributes/dispatching'}
                        externList={attributes}
                        onSelected={(item: Attributes) => {
                          dispatch(setDataRowValue(0, true, {
                            attributeId: item.id,
                            attribute: item
                          }))
                        }}
                        value={'name'}
  />
}