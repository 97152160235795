import React, { Component } from 'react';
import { Button, Dropdown, MenuItem, Navbar, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome'
import {withRouter, Link} from "react-router-dom";
import moment from "moment";
import {connect} from "react-redux";
import {
  changeSpaceEnvironment, checkBookingStatus,
  doLogout,
  reloadNavBar,
  reloadSmsNumber,
  reloadTasksNumber,
  startBooking, stopBooking
} from "@cml/redux-store";
import store from "../redux/store";
import {show} from "react-notification-system-redux";


class IconWithNumber extends Component {
  render() {
    return <div className='IconWithNumberBase' style={this.props.style}>
      <div style={{ right: '0px', top: '6px' }}
           className='RedCircle'>{this.props.number}</div>
      <button type='button' className='transparentButton' onClick={() => this.props.onClick()}>
        <FontAwesome style={{fontSize:'25px', color: this.props.color, }} name={this.props.awesomeIconName} />
      </button>
    </div>
  }
}

IconWithNumber.propTypes = {
  number: PropTypes.number,
  awesomeIconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.any,
  style: PropTypes.any,
};

IconWithNumber.defaultProps = {
  color: 'white',
  style: {width:'100%', padding:'5px'}
};

const mapStateToProps = state => {
  return {
    authenticate: state.authenticate,
    navbar: state.navbar,
  }
};

class CustomToggle extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.onClick(e);
  }

  render() {
    return (
      <button onClick={this.handleClick} className='transparentButton'>
        {this.props.children}
      </button>
    );
  }
}

class NavigationBar extends Component {

  constructor(props) {
    super(props);

    setInterval(() => {
      if(this.props.authenticate.isAuthenticated) {
        store.dispatch(reloadSmsNumber());
      }
    },120000)
  }

  handleOnSelect(event) {
    switch (event) {
      case 'logout':
        store.dispatch(doLogout()).then(() => localStorage.removeItem("auth_token"));
        this.props.history.push('/');
        break;
      case 'emailValidation':
        this.props.history.push('/emailvalidation');
        break;
      case 'profile':
        this.props.history.push('/profile');
        break;
      default:
        break;
    }
  }

  handleSpaceOnSelect(index) {
    store.dispatch(changeSpaceEnvironment(this.props.navbar.spaces[index].id))
      .then(() => window.location.reload());
  }

  render() {
    const { authenticate, navbar } = this.props;
    let loginButton = <Dropdown id='userDropDownBox' pullRight onSelect={(eventKey) => this.handleOnSelect(eventKey)}>
        <Dropdown.Toggle noCaret>
          <div>
            <div style={{marginRight: '15px', display: 'inline-block', verticalAlign:'middle'}}>
              <FontAwesome style={{fontSize:'25px', color: 'green', }} name='fas fa-user-circle' />
            </div>
            <div style={{marginRight: '15px', display: 'inline-block', fontSize: '13px'}}>{this.props.authenticate.registeredEmail}</div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu pullRight>
          <MenuItem eventKey="profile">Profil</MenuItem>
          <MenuItem eventKey="logout">Odhlasit</MenuItem>
        </Dropdown.Menu>
      </Dropdown>;

    let shortcutButtons = [];

    if (!authenticate.isAuthenticated && !authenticate.isAuthenticatedRaw) {
      shortcutButtons = [];
      loginButton = <div>
        <Link to='/login' style={{marginRight: '10px'}}>Přihlášení</Link>
        <Button bsStyle='primary' onClick={() => this.props.history.push('/signup')}>Registrace</Button>
      </div>
    } else if (authenticate.isAuthenticatedRaw && !authenticate.isAuthenticated) {
      shortcutButtons = [];
      loginButton =
        <Dropdown id='userDropDownBox' pullRight onSelect={(eventKey) => this.handleOnSelect(eventKey)}>
          <Dropdown.Toggle noCaret>
            <div>
              <div style={{marginRight: '15px', display: 'inline-block', verticalAlign:'middle'}}>
                <FontAwesome style={{fontSize:'25px', color: 'red', }} name='fas fa-exclamation-circle' />
              </div>

              <div style={{marginRight: '15px', display: 'inline-block', fontSize: '13px'}}>{this.props.email}</div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <MenuItem eventKey="emailValidation">Dokončení registrace</MenuItem>
            <MenuItem eventKey="logout">Odhlasit</MenuItem>
            <MenuItem eventKey="profile">Profil</MenuItem>
          </Dropdown.Menu>
        </Dropdown>;
    } else {
      if (authenticate.actualUserRole.tasks.view) {
        shortcutButtons.push(
          <IconWithNumber number={navbar.taskNumber}
                          onClick={() => {
                            this.props.history.replace({
                              pathname: '/tasks',
                              state: {defaultQuery: {
                                  where: {isConfirmed: 0},
                                  userLinksWhere: {
                                    id: authenticate.loggedUser.id,
                                  }
                                }},
                            });
                          }}
                          awesomeIconName='fas fa-clipboard-list'/>
        );
      }
      if (authenticate.actualUserRole.sms.view) {
        shortcutButtons.push(
          <IconWithNumber number={navbar.smsNumber}
                          onClick={() => {
                            this.props.history.replace({
                              pathname: '/sms',
                              state: {defaultQuery: {where: {wentOut: 0, readBy: null}}},
                            });
                          }}
                          awesomeIconName='fas fa-comments'/>
        );
      }
      if (authenticate.actualUserRole.dispatching.view) {
        shortcutButtons.push(
          <IconWithNumber number={navbar.datarowsNumber}
                          onClick={() => {
                            this.props.history.replace({
                              pathname: '/unresolved',
                            });
                          }}
                          awesomeIconName='fas fa-table'/>
        );
      }

    }
    const selectedIndex = navbar.spaces.findIndex(o => o.id === authenticate.activeSpaceId);
    return (
        <Navbar inverse fixedTop>
          <Navbar.Header>
            <Navbar.Brand>
              <div style={{color: 'white', display: 'inline-block', marginLeft: '30px'}}>
                {
                  moment().format('ddd DD MMMM YYYY')
                }
              </div>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>


          <Navbar.Collapse>
            <Nav pullRight>
              <div style={{width:'100%', padding:'5px'}}>
                {loginButton}
              </div>
            </Nav>
            {
              authenticate.isAuthenticated ? <Nav>

                <div style={{width:'100%', paddingTop:'8px'}}>
                  <Dropdown id='userDropDownBox' pullLeft onSelect={(eventKey) => this.handleSpaceOnSelect(eventKey)}>
                    <Dropdown.Toggle noCaret>
                      {navbar.spaces.length > 0  && selectedIndex > -1 ? navbar.spaces[selectedIndex].name : ''}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {
                        navbar.spaces.map((item, index) => (
                          <MenuItem key={index} eventKey={index}>{item.name}</MenuItem>
                        ))
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown id='arenaDropDownBox' pullRight onSelect={(eventKey) => {
                    switch (eventKey) {
                      case 0:
                        store.dispatch(startBooking('pohoda')).then(() => {
                          setTimeout(() => store.dispatch(checkBookingStatus('pohoda')), 5000);
                        }).catch(() => {
                          store.dispatch(show({
                            title: 'Chyba !',
                            level: 'error',
                            autoDismiss: 15,
                            children: (
                              <div>Chybné údaje pro spuštění pohody, kontaktujte podporu</div>
                            ),
                          }, 'error'));
                        });
                        break;
                      case 1:
                        store.dispatch(stopBooking('pohoda')).then(() => {
                          setTimeout(() => store.dispatch(checkBookingStatus('pohoda')), 7000);
                        }).catch(() => {
                          store.dispatch(show({
                            title: 'Chyba !',
                            level: 'error',
                            autoDismiss: 15,
                            children: (
                              <div>Chybné údaje pro ukončení pohody, kontaktujte podporu</div>
                            ),
                          }, 'error'));
                        })
                        break;
                      case 2:
                        store.dispatch(checkBookingStatus('pohoda'));
                        break;
                    }
                  }}>
                    <CustomToggle bsRole="toggle">
                      <span style={{ marginLeft: '15px' }}>
                        <span style={{ color: 'white' }}>POHODA</span>
                        <div style={{ verticalAlign: 'middle', marginLeft: '10px' }}
                          className={`Circle ${this.props.navbar.statuses.pohoda ? 'green' : 'red'}`}/>
                      </span>
                    </CustomToggle>

                    <Dropdown.Menu pullRight>
                      <MenuItem eventKey={0}>Spustit konektor pohody</MenuItem>
                      <MenuItem eventKey={1}>Vypnout konektor pohody</MenuItem>
                      <MenuItem eventKey={2}>Otestovat stav pohody</MenuItem>
                    </Dropdown.Menu>
                  </Dropdown>
                  <span style={{ marginLeft: '15px', color: 'white', marginTop: '15px', verticalAlign: 'middle'}}>
                    {`SMS: ${navbar.spaces.length > 0  && selectedIndex > -1 && navbar.spaces[selectedIndex].smsGateBalance} e`}
                  </span>
                </div>

              </Nav> : null
            }

            {
              Object.keys(shortcutButtons).map((key) => {
                return <Nav pullRight key={key}>{shortcutButtons[key]}</Nav>
              })
            }
          </Navbar.Collapse>
        </Navbar>
    )
  }
}

NavigationBar.propTypes = {
  spaceContext: PropTypes.object.isRequired,
  navBarRefreshRequested: PropTypes.bool.isRequired,
  onRefreshDone: PropTypes.func.isRequired,
  authenticate: PropTypes.object,
  navbar: PropTypes.object,
};

export default withRouter(connect(mapStateToProps)(NavigationBar));
export {
  IconWithNumber,
}