import { AbstractModelFactory } from "@projectstorm/react-canvas-core";
import { DiagramEngine } from "@projectstorm/react-diagrams";
import {CmlPortModel} from "./CmlPortModel";

export class CmlPortFactory extends AbstractModelFactory<CmlPortModel, DiagramEngine> {
    constructor() {
        super('cmlport');
    }

    generateModel(): CmlPortModel {
        return new CmlPortModel({
            name: 'unknown'
        });
    }
}