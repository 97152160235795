import React, {Component} from 'react';
import moment from "moment";
import {dateFormatVisible} from "../../../AppObjects";
import { VictoryLine, VictoryChart, VictoryAxis, VictoryLabel, VictoryLegend, VictoryGroup } from 'victory';

interface TankIndexes {
    index: number;
    unresolved: boolean;
    amount: number;
    Consuption: number;
}

export interface VehicleDataProps {
    tankIndexes: TankIndexes[],
    unresolvedTankIndexes: TankIndexes[],
    vehicleData: object[];
    suspiciousLeaks: TankIndexes[],
    showUnresolved: true;
}

export default class VehicleData extends Component<VehicleDataProps, {}>{

    renderMarkers(tankIndexes: TankIndexes[]) {
        const { showUnresolved } = this.props;
        let lastOffset = 0;
        return tankIndexes.map((item,key) => {
            if (tankIndexes[key].unresolved && !showUnresolved) {
                return null;
            }
            lastOffset += 20;
            if (lastOffset > 200) {
                lastOffset = 0;
            }
            return <VictoryLine key={key}
                                style={{
                                    data: { stroke: tankIndexes[key].unresolved ? "rgba(255,140,0,0.5)" : "rgb(0,100,0, 0.5)", strokeWidth: 2 },
                                    // @ts-ignore
                                    labels: { angle: -90, fill: tankIndexes[key].unresolved ? "rgba(255,140,0,0.8)" : "rgb(0,100,0, 0.8)", fontSize: 12 }
                                }}
                                labels={[tankIndexes[key].amount]}
                                labelComponent={<VictoryLabel angle={0} dx={25} y={120 + lastOffset}/>}
                                x={() => tankIndexes[key].index}/>
        })
    }

    render() {
        const { tankIndexes, vehicleData, unresolvedTankIndexes, suspiciousLeaks } = this.props;
        let lastOffset = 0;
        // @ts-ignore

        return <VictoryChart height={400} responsive={false} width={1100}>
            <VictoryLegend x={500} y={0}
                           orientation="horizontal"
                           gutter={20}
                            // @ts-ignore
                           style={{ border: { stroke: "black" }, title: {fontSize: 20 } }}
                           data={[
                               { name: "Poklesy [%]", symbol: { fill: 'rgba(255,0,0,1)' } },
                               { name: "Nepřířazeno [L]", symbol: { fill: "rgba(255,140,0,1)" } },
                               { name: "Tankování [L]", symbol: { fill: "rgb(0,100,0, 1)" } }]} />
            { this.renderMarkers(tankIndexes) }
            { this.renderMarkers(unresolvedTankIndexes) }
            { suspiciousLeaks.map((item,key) => {
                lastOffset += 20;
                if (lastOffset > 200) {
                    lastOffset = 0;
                }
                return <VictoryLine key={key}
                                    style={{
                                        data: { stroke: 'rgba(255,0,0,0.5)', strokeWidth: 2 },
                                        // @ts-ignore
                                        labels: { angle: -90, fill: 'rgba(255,0,0,1)', fontSize: 12 }
                                    }}
                                    labels={[suspiciousLeaks[key].Consuption]}
                                    labelComponent={<VictoryLabel angle={0} dx={25} y={120 + lastOffset}/>}
                                    x={() => suspiciousLeaks[key].index}/>
            }) }
            <VictoryLine
                interpolation='linear'
                data={vehicleData}
                // data accessor for x values
                x="timestamp"
                // data accessor for y values
                y="currentFuelLevel"
            />
            <VictoryAxis
                fixLabelOverlap={true}
                style={{
                    axis: {stroke: "#756f6a"},
                    axisLabel: {fontSize: 16, padding: 30},
                    // @ts-ignore
                    ticks: {stroke: "grey", size: 3},
                    tickLabels: {fontSize: 9, paddingLeft: 20}
                }}
                tickFormat={(x) => (moment(x).format(dateFormatVisible + ' HH'))}
                label={"Čas"}
            />
            <VictoryAxis
                domain={{y: [0, 105]}}
                dependentAxis
                fixLabelOverlap={true}
                style={{
                    axis: {stroke: "#756f6a"},
                    axisLabel: {fontSize: 16, margin: 50},
                    // @ts-ignore
                    ticks: {stroke: "grey", size: 2},
                    tickLabels: {fontSize: 9, padding: 5}
                }}
                label={"Stav nádrže"}
                // tickFormat specifies how ticks should be displayed
                //tickFormat={(x) => (`${x / 1000}`)}
            />
        </VictoryChart>;
    }
}