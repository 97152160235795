import {Component} from "react";
import React from "react";
import {BaseViewPropTypes} from '../CommonListContainer';
import {Button, Checkbox, Col, Collapse, Row} from 'react-bootstrap';
import FontAwesome from "react-fontawesome";
import PropTypes from 'prop-types';
import {dispatchLabelMap} from "./Edit";

class Right extends Component {
  render() {
    if (this.props.can) {
      return <FontAwesome style={{color: 'green', fontSize: '16px', ...this.props.style}} name='fas fa-check'/>;
    } else {
      return <FontAwesome style={{color: 'red', fontSize: '16px', ...this.props.style}} name='fas fa-times'/>;
    }
  }
}

Right.propTypes = {
  can: PropTypes.bool.isRequired,
  style: PropTypes.any,
  fontSize: PropTypes.string,
};

Right.defaultProps = {
  style: {},
};

class FullRightView2 extends Component {
  render() {
    const { item, name } = this.props;
    return (<tr>
      <td>{name}</td>
      <td><div className='text-center'><Right can={item.view}/></div></td>
      <td><div className='text-center'><Right can={item.update}/></div></td>
      <td><div className='text-center'><Right can={item.delete}/></div></td>
      {
        typeof this.props.other !== 'undefined' ?
          Object.keys(this.props.other).map((key) => {
            if (this.props.other[key] === 'nop') {
              return <td key={key}><div className='text-center' style={{fontSize: '16px', fontWeight: 'bold'}}>
                ---
              </div></td>
            }
            return <td key={key}>
              <div className='text-center'>
                <Right can={this.props.item[this.props.other[key]]}/>
                <span style={{ marginLeft: '10px' }}>
                  {this.props.customLabel[key]}
                </span>

              </div>
            </td>
          }) : null
      }
    </tr>);
  }
}

FullRightView2.propTypes = {
  item: PropTypes.object.isRequired,
  name: PropTypes.string,
  other: PropTypes.array,
  customLabel: PropTypes.array,
};

FullRightView2.defaultProps = {
  customLabel: ['', '', ''],
}

export default class RoleView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    }
  }
  render() {
    let style = {
      inlineLabel: {
        fontSize: '26px', fontWeight: 'bold',
      }
    };
    const { open } = this.state;
    return (<Row>
      <Col sm={2}>
        <div style={style.inlineLabel} className='text-left'>
          {this.props.item.name}
        </div>
        <div className='text-left'>
          Pro dispečink ? <Right can={this.props.item.visibleForCalendar === 1}/>
        </div>
        <div className='text-left'>
          Kontaktní osoba ? <Right can={this.props.item.isContactUser === 1}/>
        </div>
        <div className='text-left' style={{marginBottom: '10px'}}>
          Projektový manažer ? <Right can={this.props.item.isProjectManager === 1}/>
        </div>
      </Col>
      <Col sm={8}>
        <div className='dataTable'>
          <table>
            <tbody>
            <tr>
              <th>---</th>
              <th>Čtení</th>
              <th>Úprava</th>
              <th>Mazání</th>
              <th>Pouze své</th>
              <th>Potvrzovat</th>
              <th>Atributy</th>
            </tr>
            <FullRightView2 other={['nop','nop','nop']} name='Uživatelé' item={this.props.item.users}/>
            <FullRightView2 other={['nop','nop','nop']} name='Role' item={this.props.item.roles}/>
            <FullRightView2 other={['nop','nop','nop']} name='Auta' item={this.props.item.vehicles}/>
            <FullRightView2 other={['onlySelf', 'nop', 'attributes']} name='Dovolené' item={this.props.item.attendance}/>
            <FullRightView2 other={['onlySelf', 'nop', 'nop']} name='SMS' item={this.props.item.sms}/>
            <FullRightView2 other={['onlySelf', 'canConfirm', 'nop']} name='Úkoly' item={this.props.item.tasks}/>
            <FullRightView2 other={['nop','nop','nop']} name='Vybavení' item={this.props.item.tools}/>
            <FullRightView2 other={['nop','nop','attributes']} name='Dispečink' item={this.props.item.dispatching}/>
            <FullRightView2 other={['nop','nop','nop']} name='Adresář' item={this.props.item.addressBook}/>
            <FullRightView2 other={['nop','nop','nop']} name='Zakázky' item={this.props.item.orders}/>
            <FullRightView2 other={['nop','nop','nop']} name='PHM kontrola' item={this.props.item.phm}/>
            <FullRightView2 other={['nop','nop','nop']} name='Mzdy' item={this.props.item.salary}/>
            <FullRightView2 other={['pin','nop','nop']} customLabel={['Pin', '', '']} name='Platební karty' item={this.props.item.creditCard}/>
            </tbody>
          </table>
        </div>
      </Col>
      <Col sm={2}>
        <div style={{marginTop: '15px', marginBottom: '5px', display: ''}} className='row text-center'>
          <Button
            onClick={() => this.setState({ open: !open })}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            {open ? 'Skrýt další nastavení' : 'Zobrazit další nastavení'}
          </Button>
          <Collapse in={this.state.open}>
            <div className='text-center' id="example-collapse-text">
              {
                Object.keys(dispatchLabelMap).map((key) => {
                  return <div style={{marginTop: '10px'}}>{dispatchLabelMap[key]}
                    <span style={{marginLeft: '10px'}}>
                    <Right can={this.props.item.dispatching.other[key]}>Ahoj</Right>
                  </span></div>
                })
              }
            </div>
          </Collapse>
        </div>
      </Col>
    </Row>)
  }
}

export {
  Right,
}

RoleView.propTypes = {
  isForLog: PropTypes.bool,
  ...BaseViewPropTypes
};

RoleView.defaultProps = {
  isForLog: false
};
