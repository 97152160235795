import {Dropdown, MenuItem} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import {IDispatchingAttributesCustom} from "@cml/types";
import React, {useRef, useState} from "react";
import {Attributes, Roles} from "@cml/models";
import {useDispatch, useSelector} from "react-redux";
import {AuthenticateState, copyDataRow, DataRowsState, deleteDataRow, setDataRowValue} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import store from "../../../redux/store";
import {dateFormatVisible, dateFromat} from "../../../AppObjects";
import moment, {Moment} from "moment";
import {selectDataRowValue} from "./selector";
import {SingleDatePicker} from "react-dates";

interface Props {
  index: number;
  attribute: Attributes;
  singleRowEditMode: boolean;
  elapsedHoursFromCreation: number;
  onAddConnWithInvoice: (type: string, index: number) => void;
  onLogRequested: (index: number) => void;
  showDate: boolean | undefined;
}

export default function DrMenu({
                                 index,
                                 singleRowEditMode,
                                 attribute,
                                 elapsedHoursFromCreation,
                                 onAddConnWithInvoice,
                                 onLogRequested,
  showDate,
                               }: Props) {
  const authenticate =
    useSelector((state: { authenticate: AuthenticateState }) => state.authenticate);
  const dispatchingRights = ((authenticate.actualUserRole as Roles).dispatching as DispatchingRights);
  const id = useSelector(selectDataRowValue(index, singleRowEditMode, 'id'));
  const date = useSelector(selectDataRowValue(index, singleRowEditMode, 'date'));
  const [datePickerFocused, setDatePickerFocused] = useState(false);

  const dispatch = useDispatch();

  const onChange = (date_: Moment | null) => {
    if (date_) {
      setDatePickerFocused(false);
      dispatch(setDataRowValue(index, singleRowEditMode, {
        date: date_.format(dateFromat),
      }))
    }
  }

  return <td>
   <span style={{marginLeft: '-3px', marginRight: '3px', fontSize: '14px'}}>
   {
     (id < 0 ? '*' : '')
   }
    </span>
    <span>
            <Dropdown id={'dispatching-row-menu'} onSelect={(eventKey: any) => {
              switch (eventKey as string) {
                case 'copyRow':
                  store.dispatch(copyDataRow(index, false));
                  break;
                case 'copyRow1':
                  store.dispatch(copyDataRow(index, true));
                  break;
                case 'connToIncomeInvoice':
                  onAddConnWithInvoice('receivedInvoice', index);
                  break;
                case 'connToIssuedInvoice':
                  onAddConnWithInvoice('issuedInvoice', index);
                  break;
                case 'log':
                  onLogRequested(index);
                  break;
                case 'delete':
                  store.dispatch(deleteDataRow(index));
                  //props.onDelete(props.index);
                  break;
                default:
                  break;
              }
            }}>
             <Dropdown.Toggle noCaret>
                <FontAwesome name='fas fa-ellipsis-v'/>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {!singleRowEditMode ? <MenuItem
                  disabled={((attribute.customOptions as IDispatchingAttributesCustom) as IDispatchingAttributesCustom).enableDeletePeriod <
                  elapsedHoursFromCreation && !dispatchingRights.delete}
                  title={(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod < elapsedHoursFromCreation
                  && !dispatchingRights.delete ? `Vypršela možnost přidávat řádky, máte povoleno ${(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod} h` : undefined}
                  eventKey="copyRow">
                  <FontAwesome style={{marginRight: '10px'}} name='fas fa-plus-square'/>
                  Kopírovat řádek pod
                </MenuItem> : null}

                {!singleRowEditMode ? <MenuItem
                  disabled={(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod < elapsedHoursFromCreation && !dispatchingRights.delete}
                  title={(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod < elapsedHoursFromCreation
                  && !dispatchingRights.delete ? `Vypršela možnost přidávat řádky, máte povoleno ${(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod} h` : undefined}
                  eventKey="copyRow1">
                  <FontAwesome style={{marginRight: '10px'}} name='fas fa-plus-square'/>
                  Kopírovat řádek pod (pouze strojík/dodavatel a stroj)
                </MenuItem> : null}
                <MenuItem eventKey="connToIncomeInvoice">
                  <FontAwesome style={{marginRight: '10px'}} name='fas fa-link'/>
                  Přidat vazbu na přijatou fakturu z účetního systému
                </MenuItem>
                {/*
                <MenuItem eventKey="connToIssuedInvoice">
                  <FontAwesome style={{marginRight: '10px'}} name='fas fa-link'/>
                  Přidat vazbu na vydanou fakturu z účetního systému
                </MenuItem>
                */}
                {!singleRowEditMode ? <MenuItem
                  disabled={(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod <
                  elapsedHoursFromCreation && !dispatchingRights.delete}
                  title={(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod <
                  elapsedHoursFromCreation
                  && !dispatchingRights.delete ?
                    `Vypršela možnost vymazat řádek, na mazání máte povoleno ${(attribute.customOptions as IDispatchingAttributesCustom).enableDeletePeriod} h` : undefined}
                  eventKey="delete">
                  <FontAwesome style={{marginRight: '10px'}} name='fas fa-trash'/>
                  Vymazat
                </MenuItem> : null}
                {!singleRowEditMode ? <MenuItem eventKey="log">
                  <FontAwesome style={{marginRight: '10px'}} name='fas fa-info-circle'/>
                  Log
                </MenuItem> : null}
                      </Dropdown.Menu>
            </Dropdown>
          </span>
    <span style={{marginLeft: '5px', marginRight: '3px', fontSize: '11px'}}>
   {
     (showDate ? (id < 0 ? <SingleDatePicker
         small
         date={date ? moment(date) : null} // momentPropTypes.momentObj or null
         displayFormat={ dateFormatVisible }
         isOutsideRange = {() => false}
         onDateChange={onChange} // PropTypes.func.isRequired
         focused={datePickerFocused} // PropTypes.bool
         onFocusChange={({ focused }) => setDatePickerFocused(!datePickerFocused)} // PropTypes.func.isRequired
         id="your_unique_id" // PropTypes.string.isRequired,
       /> :
       (moment(date).format(dateFormatVisible))) : '')
   }
    </span>
  </td>
}
