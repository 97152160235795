import React, { Component } from 'react';
import {AutoSizer, Column, Table} from "react-virtualized";
import moment from "moment";
import {dateFormatVisible} from "../../../AppObjects";

interface SuspiciousLeaksState {
    selRecordIndex: number;
}

interface SuspiciousLeaksProps {
    records: {
        timestamp: string;
        vehicleId: number;
    } [];
    onRowClick: (index: number) => void;
}

export default class SuspiciousLeaks extends Component<SuspiciousLeaksProps, SuspiciousLeaksState>{
    constructor(props: SuspiciousLeaksProps) {
        super(props);
        this.state = {
            selRecordIndex: -2,
        }
    }


    render() {
        const { records, onRowClick } = this.props;
        return <AutoSizer disableHeight>
            {({width}) => (
                <Table
                    width={width}
                    height={300}
                    headerHeight={30}
                    scrollToIndex={this.state.selRecordIndex}
                    rowStyle={({index}) => {
                        return {
                            cursor: 'pointer',
                            fontSize: '12px',
                            backgroundColor: index === this.state.selRecordIndex ? '#c5ebf0' : '',
                        }
                    }}
                    rowHeight={30}
                    onRowClick={({ index }) => {
                        this.setState({selRecordIndex: index});
                        onRowClick(index);
                    }}
                    rowCount={records.length}
                    rowGetter={({ index }) => records[index]}
                >
                    <Column
                        label='Vozidlo'
                        dataKey='identifier'
                        width={80}
                    />
                    <Column
                        label='Čas před'
                        cellRenderer={cell => moment(cell.cellData).format(dateFormatVisible + ' HH:mm:ss')}
                        dataKey='prevTime'
                        width={150}
                    />
                    <Column
                        label='Čas'
                        cellRenderer={cell => moment(cell.cellData).format(dateFormatVisible + ' HH:mm:ss')}
                        dataKey='timestamp'
                        width={150}
                    />
                    <Column
                        label='Abs. spotřeba před'
                        dataKey='prevAcu'
                        width={80}
                    />
                    <Column
                        label='Abs. spotřeba'
                        dataKey='accumulatedFuelConsumption'
                        width={80}
                    />
                    <Column
                        label='Rozdíl'
                        dataKey='acuDiff / 100'
                        width={80}
                    />
                    <Column
                        label='Palivo před'
                        dataKey='prevFuel'
                        width={70}
                    />
                    <Column
                        label='Palivo'
                        dataKey='currentFuelLevel'
                        width={70}
                    />
                    <Column
                        label='Rozdíl'
                        dataKey='Consuption'
                        width={70}
                    />
                    <Column
                        label='Tachometr před'
                        dataKey='prevOdo'
                        width={70}
                    />
                    <Column
                        label='Tachometr'
                        dataKey='odometer'
                        width={70}
                    />
                    <Column
                        label='Rozdíl KM'
                        dataKey='KmDiff'
                        width={50}
                    />
                </Table>
            )}
        </AutoSizer>;
    }
}