import React, {Component} from 'react';
import request from 'axios';
import {Button, Checkbox, Dropdown, MenuItem, Modal} from "react-bootstrap";
import Filter from "./utils/Filter";
import PropTypes from "prop-types";
import {getFilterList} from "../AppObjects";
import { Tag } from "./Tag";
import FontAwesome from "react-fontawesome";
import store from "../redux/store";
import { show } from 'react-notification-system-redux';
import qs from 'qs';

const NewItemAddedContext = React.createContext();

export default class ItemsSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
      selIndex: 0,
      list: null,
      newModalShow: false,
      loadAfterButton: false,
    };
  }

  componentWillMount() {
    if(!this.props.loadAfterButton)
      this.initSelf();
  }

  initSelf() {
    const { itemsToFetch, externList, defaultQuery, onMapFetchedList } = this.props;
    if (!externList) {
      request.get('/items/' + itemsToFetch, {
        params: defaultQuery,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      }).then((res) => {
        if(res.status === 200) {
          this.finishListInt(onMapFetchedList(res.data));
        }
      })
        .catch(err => {
          this.setState({ requestActive: false });
        });
    } else {
      this.finishListInt(externList);
    }
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    const { externList, appendItemsToStart } = nextProps;
    if(externList && this.state.list) {
      if (externList.length + appendItemsToStart.length !== this.state.list.length) {
        this.finishListInt(nextProps.externList);
      } else {

      }
    }
  }

  finishListInt(list) {
    const { defaultSelectedId, appendItemsToStart, isFilterable, multi, multiDefaultList, fullSelectedList } = this.props;
    let listIn = [
        ...appendItemsToStart,
        ...list,
      ];
    if (isFilterable) {
      Object.keys(listIn).map( (key) => listIn[key].filtered = key === '0');
    }
    if (multi) {
      Object.keys(listIn).map( (key) => listIn[key].filtered = multiDefaultList.findIndex(o => {
        if (fullSelectedList) {
          return o.id === listIn[key].id;
        } else {
          return o === listIn[key].id;
        }
      }) > -1);
    }
    this.setState({
      list: listIn,
      selIndex: defaultSelectedId ? listIn.findIndex(o => o.id === defaultSelectedId) : 0,
    });
    if(defaultSelectedId === null) {
      if(listIn.length > 0) {
        this.props.onSelected(listIn[0], 0, true);
      }
    }
    if (isFilterable) {
      this.props.listChanged(getFilterList(listIn), true);
    }
  }

  onFilterChange(item, key) {
    let list = this.state.list.slice();
    if (key === '0') {
      Object.keys(list).map((keyInter) => {
        list[keyInter].filtered = keyInter === '0' && item.target.checked;
      });
    } else {
      list[0].filtered = false;
      list[key].filtered = item.target.checked;
    }
    this.setState({ list: this.props.overrideListChanged ? this.props.overrideListChanged(list, key) : list });
    this.props.listChanged(getFilterList(this.props.overrideListChanged ?
      this.props.overrideListChanged(list, key) : list, this.props.fullSelectedList), false);
  }

  onFilterMultiChange(checked, key) {
    let list = this.state.list.slice();
    list[key].filtered = checked;
    this.setState({ list });
    this.props.listChanged(list.filter(o => o.filtered).map(o => this.props.fullSelectedList ? o : o.id));
  }

  render() {
    const {filterValue} = this.state;
    const list = this.props.forceUseExternList ? this.props.externList : this.state.list;
    const { disabled, loadAfterButton, value, bsSize, itemsToFetch, newEditComponentUrl, preview, otherPreviewProps, newEditComponent, newEditComponentDefaultProps, defaultSelectedId, defaultSelectedIndex,
      filterEnable, drawFirstWithDividers, isFilterable, overrideToggle, overrideToggleFunc, getExternIndex, listSplitter, onListSplitterCondition, customMenuItems, multi, noCaret } = this.props;



    if(loadAfterButton && !this.state.loadAfterButton) {
      return <Button onClick={() => {
        this.setState({ loadAfterButton: true, requestActive: true});
        this.initSelf();
      }}>
        Načíst data
      </Button>
    }

    if (list === null)
      return null;

    let isFilterableText = '';
    let selIndex = this.state.selIndex;
    if (isFilterable) {
      isFilterableText = list.filter(o => o.filtered).map(oo => oo[value]).join(", ");
    }

    if (getExternIndex) {
      selIndex = defaultSelectedId ? list.findIndex(o => o.id === defaultSelectedId) : 0;
      if (selIndex === -1)
        return null;
    }

    return <div style={{display: 'inline-block'}}>
      {
        newEditComponent ? <Modal dialogClassName="modal-90w" show={this.state.newModalShow}
                                  onHide={() => this.setState({newModalShow: false})}>
          <Modal.Header closeButton />
          <Modal.Body>
            <NewItemAddedContext.Consumer>
              {
                value => React.createElement(newEditComponent, {
                  defaultProps: newEditComponentDefaultProps,
                  disableUrlCheck: true,
                  onSubmit: (id, _this, object, callback) => {
                    _this.setState({
                      submitActive: true,
                    });
                    request.post('/items/' + (newEditComponentUrl ? newEditComponentUrl : itemsToFetch), object)
                      .then((res) => {
                        if(res.status === 200) {
                          if (typeof callback === 'function') {
                            if (callback)
                              callback(res.data);
                          }
                          _this.setState({
                            submitActive: false,
                          });
                          if (value) {
                            value.onNewItemAdd(itemsToFetch, res.data);
                            this.setState({ newModalShow: false });
                          }
                          else {
                            let list = this.state.list.slice();
                            list.push(res.data);
                            this.setState({ newModalShow: false, list });
                          }
                        }
                      })
                      .catch((err) => {
                        _this.setState({
                          submitActive: false,
                        });
                        store.dispatch(show({
                          title: 'Error',
                          level: 'error',
                          autoDismiss: 5,
                          children: (
                            <div>
                              {`Server hlasí chybu ${err.status}`}
                            </div>
                          ),
                        }, 'error'));
                      })
                  }
                })
              }
            </NewItemAddedContext.Consumer>
          </Modal.Body>
        </Modal> : null
      }
      <Dropdown
        bsSize={bsSize}
        disabled={disabled}
        style={{ ...this.props.style }}
        pullRight={this.props.pullRight}
        onSelect={(e) => {
          if (multi) {
            this.onFilterMultiChange(true, e);
          } else {
            this.setState({selIndex: e});
            this.props.onSelected(list[e], e, false);
          }
        }}
        id={itemsToFetch}>

        <Dropdown.Toggle noCaret={noCaret}>
          {!multi ? (overrideToggleFunc ?
            ( list[selIndex] ? overrideToggleFunc(list[selIndex]) : '') :
            (overrideToggle ? overrideToggle : (list.length > 0 ? (preview && !isFilterable ? React.createElement(preview, {
            item: list[selIndex],
            ...otherPreviewProps,
          }) : (isFilterable ? (isFilterableText.length > 100 ? `${isFilterableText.substr(0,100)} ...` : isFilterableText)
              : list[selIndex][value])) : ''))) : <FontAwesome name='fas fas fa-plus'/> }
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {customMenuItems}
          {filterEnable ? <>
            <Filter filterValue={filterValue} onValueChange={(e) => {
              this.setState({filterValue: e.target.value});
            }}/>
            <MenuItem divider />
          </> : null}
          { newEditComponent ?
            <div className='text-center' style={{marginBottom: '10px'}}>
              {filterEnable ? null : <MenuItem divider />}
              <Button onClick={() => this.setState({ newModalShow: true })}>Nový záznam</Button>
              <MenuItem divider />
            </div> : null
          }
          { drawFirstWithDividers ?
            [
              filterEnable || newEditComponent ? null : <MenuItem divider />,
              !isFilterable ? <MenuItem eventKey={0}>
                {preview ?
                  React.createElement(preview, {
                    item: list[0],
                    ...otherPreviewProps,
                  }) : list[0][value]}
              </MenuItem> : <Checkbox checked={list[0].filtered}
                                      onChange={(checked) => this.onFilterChange(checked, '0')}
                                      style={{paddingLeft: '10px', paddingRight: '10px'}}>
                {preview ?
                  React.createElement(preview, {
                    item: list[0],
                    ...otherPreviewProps,
                  }) : list[0][value]}
              </Checkbox>,
              <MenuItem divider />,
            ] : null
          }
          {
            listSplitter.map((split, index) => {
              return [
                split.header,
                Object.keys(list).map((key) => {

                  if (filterEnable) {
                    if (!list[key][value].toLowerCase()
                      .includes(filterValue.toLowerCase())) {
                      if (!isFilterable)
                        return null;
                      else {
                        if (!list[key].filtered)
                          return null;
                      }
                    }
                  }
                  if (!onListSplitterCondition(index, list[key]))
                    return null;
                  if (!isFilterable) {
                    return <MenuItem active={multi ? list[key].filtered : false} key={key} eventKey={key}>
                      {preview ?
                        React.createElement(preview, {
                          item: list[key],
                          ...otherPreviewProps,
                        }) : list[key][value]}
                    </MenuItem>;
                  } else {
                    return <Checkbox key={key}
                                     checked={list[key].filtered}
                                     onChange={(checked) => this.onFilterChange(checked, key)}
                                     style={{paddingLeft: '10px', paddingRight: '10px'}}>
                      {preview ?
                        React.createElement(preview, {
                          item: list[key],
                          ...otherPreviewProps,
                        }) : list[key][value]}
                    </Checkbox>;
                  }
                })
              ];
            })
          }
        </Dropdown.Menu>
      </Dropdown>
      {
        multi ? <>
          {list.map((item, index) => {
            if (item.filtered) {
              return <div><Tag onCloseClick={() => this.onFilterMultiChange(false, index)}
                               item={
                                 overrideToggleFunc ?
                                   ( list[selIndex] ? overrideToggleFunc(item) : '') :
                                   (overrideToggle ? overrideToggle : (list.length > 0 ? (preview ? React.createElement(preview, {
                                     item: item,
                                     ...otherPreviewProps,
                                   }) : item[value]) : ''))
                               }/></div>
            }
            return null;
          })}
        </> : null
      }
    </div>
  }
}

ItemsSelector.propTypes = {
  onMapFetchedList: PropTypes.func,
  onSelected: PropTypes.func,
  defaultSelectedId: PropTypes.number,
  value: PropTypes.string.isRequired,
  itemsToFetch: PropTypes.string.isRequired,
  preview: PropTypes.func,
  otherPreviewProps: PropTypes.object,
  appendItemsToStart: PropTypes.any,
  externList: PropTypes.arrayOf(PropTypes.object),
  multiDefaultList: PropTypes.arrayOf(PropTypes.object),
  defaultQuery: PropTypes.object,
  style: PropTypes.object,
  isFilterable: PropTypes.bool,
  filterEnable: PropTypes.bool,
  getExternIndex: PropTypes.bool,
  fullSelectedList: PropTypes.bool,
  forceUseExternList: PropTypes.bool,
  drawFirstWithDividers: PropTypes.bool,
  listChanged: PropTypes.func,
  overrideListChanged: PropTypes.func,
  newEditComponent: PropTypes.func,
  newEditComponentDefaultProps: PropTypes.object,
  newEditComponentUrl: PropTypes.string,
  overrideToggle: PropTypes.string,
  bsSize: PropTypes.string,
  overrideToggleFunc: PropTypes.func,
  listSplitter: PropTypes.object,
  onListSplitterCondition: PropTypes.func,
  disabled: PropTypes.bool,
  customMenuItems: PropTypes.object,
  multi: PropTypes.bool,
  noCaret: PropTypes.bool,
  pullRight: PropTypes.bool,
  loadAfterButton: PropTypes.bool,
};

ItemsSelector.defaultProps = {
  onMapFetchedList: (list) => { return list; },
  onSelected: () => {},
  listChanged: () => {},
  defaultSelectedId: null,
  disabled: false,
  preview: null,
  otherPreviewProps: {},
  appendItemsToStart: [],
  externList: null,
  customMenuItems: null,
  defaultQuery: {},
  isFilterable: false,
  filterEnable: false,
  getExternIndex: false,
  overrideToggle:null,
  newEditComponent: null,
  newEditComponentDefaultProps: {},
  newEditComponentUrl: null,
  fullSelectedList: false,
  drawFirstWithDividers: false,
  multi: false,
  forceUseExternList: false,
  pullRight: false,
  loadAfterButton: false,
  multiDefaultList: [],
  bsSize: '',
  style: undefined,
  listSplitter: [
    {
      header: null,
    }
  ],
  noCaret: false,
  onListSplitterCondition: () => true,
};

const NewItemAddedContextProvider = NewItemAddedContext.Provider;

export {
  NewItemAddedContextProvider
}