import React, {PureComponent, useEffect, useState} from 'react';
import {BaseEditPropTypes} from '../CommonListContainer';
import request from 'axios';
import {BackHeader} from "../Setting";
import moment from "moment";
import {
  dateFormatVisible,
  dateFromat,
  generateValueIfIsNotForRanges, getFloatTime,
  getTimeString
} from "../../AppObjects";
import {LoadingOrRender} from "../../loginsrc/Profile";
import {DateRangePicker} from "react-dates";
import {
  Button,
  Checkbox,
  Col,
  ControlLabel,
  Dropdown,
  Form,
  FormControl,
  FormGroup,
  MenuItem,
  Modal,
  Row
} from "react-bootstrap";
import SubmitButton from "../../loginsrc/SubmitButton";
import {SmallUserPreview} from "../users/Preview";
import ItemsSelector from "../ItemsSelector";
import FontAwesome from "react-fontawesome";
import AddressBookEdit from "../addressBook/Edit";
import PropTypes from "prop-types";
import {CanView} from "../Main";
import {AutoSizer, Column, Table} from "react-virtualized";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import UserEdit from "../users/Edit";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import TimeField from "react-simple-timefield";
import Attachments from "../other/Attachments";
import BudgetModal from "../dispatching/components/BudgetModal";
import ResultRows from "../dispatching/components/ResultRows";
import {fillOrder} from "../dispatching/components/datarowBase";
import MaterialTable from "../dispatching/components/MaterialTable";
import {connect} from "react-redux";
import NumberQueue from "../utils/NumberQueue";
import {SmallOrderPreview} from "./Preview";

const generateDayWorkTime = () => {
  let dayWorkTime = [];
  for (let i = 0; i < 7; i++) {
    dayWorkTime.push({
      from: 0,
      to: 0,
      break: 0,
    })
  }
  return dayWorkTime;
};

const baseOrder = {
  contactUserId: null,
  projectLeaderId: null,
  company: '',
  construction: '',
  loading: '',
  unloading: '',
  fraction: '',
  meetingLocation: '',
  foreignBookKeepingId: '',
  foreignBookKeepingNumber: '',
  receivedOrderNumber: '',
  text: '',
  other: {
    dayWorkTime: generateDayWorkTime(),
    prices: [],
    vehiclePrices: [],
    vehiclePrices2: [],
    materialTable: [],
  },
  startDate: null,
  endDate: null,
  parentOrderId: null,
  addressBookId: null,
  focusedInput: null,
  suborders: [],
  vehicles: [],
  canUseTravelTime: false,
  travelTime: 0,
  travelFixedPrice: 0,
  addressBookIds: [],
};

const syncPrices = function (other, prices, groups) {
  prices.forEach(price => {
    const index = other.prices.findIndex(o => o.attributeId === price.id);
    if (index === -1) {
      other.prices.push({
        attributeId: price.id,
        attributeName: price.name,
        price: 0,
        enabled: false,
        groups
      });
    } else {
      let oGroups = other.prices[index].groups
      if (oGroups) {
        groups.forEach(g => {
          const gIndex = oGroups.findIndex(o => o.id === g.id);
          if (gIndex === -1) {
            oGroups.push({...g});
          } else {
            oGroups[gIndex].name = g.name;
          }
        });
        let oGroupsChecked =  [];
        oGroups.forEach(g => {
          const index = groups.findIndex(o => o.id === g.id);
          if (index > -1) {
            oGroupsChecked.push(g);
          }
        });
        other.prices[index].groups = oGroupsChecked;
      } else {
        other.prices[index].groups = groups.slice().map(o => ({...o}));
      }
    }
  });

  let pricesChecked = [];
  other.prices.forEach(price => {
    const index = prices.findIndex(o => o.id === price.attributeId);
    if (index > -1) {
      pricesChecked.push(price);
    }
  });
  other.prices = pricesChecked;
  return other;
};

export const SalaryStrategy = {
  0: 'Nevybrán',
  1: 'Procuntuálně z fakturace',
  2: 'Hodinově'
}

const optionsNames = {
  contactUserId: 'Kontaktní osoba',
  company: 'Firma',
  loading: 'Nákladka',
  unloading: 'Výkladka',
  fraction: 'Frakce',
  construction: 'Stavba',
  time: 'Hodiny',
  price: 'Kalkulace ceny',
}

function RowsOfOrder(props)  {
  const [dataRows, setDataRows] = useState([]);
  const [loading, setLoading] = useState(props.disableAutoLoad !== true);
  const [options, setOptions] = useState({
    contactUserId: false,
    company: false,
    loading: false,
    unloading: false,
    fraction: false,
    construction: false,
    time: false,
    price: false,
  })

  useEffect(() => {
    if(loading) {
      request.get('/items/datarowsResults', {
        params: {
          orderId: props.ids,
        }
      }).then(res => {setDataRows(res.data); setLoading(false)})
    }
  },[loading])

  return <div className='dataTable' style={{ width: '100%', fontSize: '11px', color: 'black' }}>
    <LoadingOrRender requestActive={loading}>
      <div>
        {props.disableAutoLoad && <Button onClick={() => setLoading(true)}>
          Načíst data zakázky</Button>}
      </div>
      <Button
        onClick={() => {
          const rows = dataRows.slice();
          rows.forEach((row) => {
            if (row.invoiceForeignId === null)
              if (row.isSelected)
                row = fillOrder(row, props.prices, props.order, options);
          })
          request.post('/items/multipleDataRowEdit', {
            dataRows: rows,
          })
            .then(() => {
              setDataRows(rows);
            })
            .catch(() => {
              store.dispatch(show({
                title: 'Chyba !',
                level: 'error',
                autoDismiss: 5,
                children: (
                  <div>
                    {`Nepodařilo se uložit data`}
                  </div>
                ),
              }, 'error'));
            })

        }}
        disabled={props.order.suborders && props.order.suborders.length > 0}
        style={{ marginBottom: '10px' }}>
        Synchronizovat výchozí hodnoty pro vybrané řádky
      </Button>
      {Object.keys(options).map(key => <Checkbox
        value={options[key]}
        onChange={e => {
          options[key] = e.target.checked;
          setOptions(options);
        }}
        style={{ marginLeft: '10px', display: 'inline-block' }} key={key}>
        {optionsNames[key]}
      </Checkbox>)}
      {dataRows.filter(o => o.isSelected && o.invoiceForeignId).length > 0 &&
        <div style={{ display: 'inline-block', marginLeft: '20px' }}>
          <FontAwesome style={{fontSize:'14px', color: 'orange', marginRight: '10px'}} name='fas fa-exclamation' />
          Některý z vybraných řádků je již vyfakturován a jeho úprava nebude provedena
        </div>
      }
      <ResultRows readOnly
                  storeAttributes={props.stores}
                  priceAttributes={props.prices}
                  materialAttributes={props.materials}
                  dataRows={dataRows} rowsChanged={(rows) => setDataRows(rows)}/>
    </LoadingOrRender>
  </div>
}

const mapStateToProps = (state) => {
  return {
    activeSpace: state.authenticate.activeSpace,
  }
};

class OrderEdit extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      requestActive: this.props.disableUrlCheck ? false : (this.props.match.params.id !== 'new'),
      submitActive: false,
      actualOrderEdit: -1,
      actualBudgetEdit: -1,
      selectedVehiclePricesKey: null,
      isConfirmed: false,
      salaryStrategy: 0,
      vehicleFilterValue: '',
      ...baseOrder,
    };
    this.originOrder = null;
    this.prices = [];
    this.materialAttributes = [];
    this.storeAttributes = [];
    this.originVehicles = [];
    this.attachmentsRef = null;
  }

  setAttachmentsRef = element => {
    if(element)
      this.attachmentsRef = element;
  }

  componentWillMount = () => {

    request.get('/items/vehicles', { params: { allDetails: 0 }})
      .then((res) => {
        let vehicles = res.data;
        vehicles.forEach((b) => {
          b.isSelected = false;
        });
        this.originVehicles = vehicles;
        request.get('/items/attributes/price')
          .then(async res => {
            const resAct = await request.get('/items/attributes/invoice-activity');
            this.materialAttributes = await request.get('/items/attributes/material');
            this.materialAttributes = this.materialAttributes.data;
            this.storeAttributes = await request.get('/items/attributes/store');
            this.storeAttributes = this.storeAttributes.data;
            let groups = [];
            if (resAct.data) {
              groups = resAct.data.map(o => ({
                name: o.name, id: o.id, noGroup: false, price: 0, totalPrice: 0, fixedPrice: false, enabled: true
              }));
            }
            this.prices = res.data;
            let {other} = this.state;
            other = syncPrices(other, this.prices, groups);
            if (this.props.disableUrlCheck) {
              this.setState({ other, vehicles, requestActive: false });
              return;
            }
            if (this.props.match.params.id !== 'new' ) {
              request.get('/items/orders/' + this.props.match.params.id)
                .then((res) => {
                  if (res.status === 200) {
                    let body = res.data;
                    body.other = syncPrices(body.other, this.prices, groups);
                    body.suborders.forEach((sub) => {
                      sub.vehicles = JSON.parse(JSON.stringify(this.originVehicles));
                      sub.other = syncPrices(sub.other, this.prices, groups);
                      sub.other.vehiclePrices.forEach((p) => {
                        let vIndex = sub.vehicles.findIndex(o => o.id === p.id);
                        if (vIndex > -1) {
                          sub.vehicles[vIndex].other.priceEq = p.priceEq;
                          sub.vehicles[vIndex].isSelected = true;
                        }
                      })
                    });
                    body.suborders = body.suborders.sort((a,b) => a.isConfirmed - b.isConfirmed);
                    body.other.vehiclePrices.forEach((p) => {
                      let vIndex = vehicles.findIndex(o => o.id === p.id);
                      if (vIndex > -1) {
                        vehicles[vIndex].other.priceEq = p.priceEq;
                        vehicles[vIndex].isSelected = true;
                      }
                    });
                    this.setState({
                      ...body,
                      startDate: moment(res.data.dateFrom, dateFromat),
                      endDate: moment(res.data.dateTo, dateFromat),
                      requestActive: false,
                      vehicles,
                    });
                  }
                })
                .catch(err => {
                  this.setState({ requestActive: false });
                })
            } else {
              this.setState({ other, vehicles });
            }
          })
          .catch(err => {
            this.setState({ requestActive: false });
          })
      })
      .catch(err => {
        this.setState({ requestActive: false });
      })
  }

  async handleSubmit(e) {
    e.preventDefault();
    if (this.state.actualOrderEdit > -1) {
      this.setState({actualOrderEdit: -1});
      return;
    }
    if (this.state.endDate === null || this.state.startDate === null) {
      this.setState({
        focusedInput: this.state.startDate !== null ? 'endDate' : 'startDate'
      });
      store.dispatch(show({
        title: 'Chyba',
        level: 'error',
        autoDismiss: 3,
        children: (
          <div>
            Vyberte prosím období
          </div>
        ),
      }, 'error'));
      return;
    }
    if(this.state.addressBookIds.length === 0) {
      store.dispatch(show({
        title: 'Chyba',
        level: 'error',
        autoDismiss: 3,
        children: (<div>Není vybrán žádný odběratel</div>),
      }, 'error'));
      return;
    }
    if(this.state.addressBookIds.reduce((acc, item) => (acc += parseInt(item.percentage)),0) !== 100) {
      store.dispatch(show({
        title: 'Chyba',
        level: 'error',
        autoDismiss: 3,
        children: (<div>Procenta pro rozložení mezi odběratele není 100%</div>),
      }, 'error'));
      return;
    }
    if(this.state.addressBookIds.filter(o => parseInt(o.addressBookId) === 0).length > 0) {
      store.dispatch(show({
        title: 'Chyba',
        level: 'error',
        autoDismiss: 3,
        children: (<div>Některý odběratel v tabulce není vybrán</div>),
      }, 'error'));
      return;
    }

    let obj = {
      dateFrom: this.state.startDate.format(dateFromat),
      dateTo: this.state.endDate.format(dateFromat),
      ...this.state,
    };

    obj.suborders.forEach(item => {
      item.dateFrom = this.state.startDate.format(dateFromat);
      item.dateTo = this.state.endDate.format(dateFromat);
      item.company = this.state.company;
      if (item.vehicles) {
        item.other.vehiclePrices = item.vehicles.filter(o => o.isSelected).map(o => ({
          id: o.id,
          priceEq: o.other.priceEq,
        }))
      }
    });

    obj.other.vehiclePrices = obj.vehicles.filter(o => o.isSelected).map(o => ({
      id: o.id,
      priceEq: o.other.priceEq,
    }));

    delete obj['startDate'];
    delete obj['endDate'];
    delete obj['focusedInput'];
    this.doSubmit(obj);
  }

  doSubmit(obj) {
    this.props.onSubmit(this.props.disableUrlCheck ? 'new' : this.props.match.params.id, this, obj, async (item, err) => {
      if (!err) {
        await this.attachmentsRef.handleSave(item.id);
      } else {
        if (err.response && err.response.data) {
          if (err.response.data.error === 1) {
            store.dispatch(show({
              title: 'Varování !',
              level: '',
              autoDismiss: 5,
              children: (
                <div>
                  <div>{`Beru na vědomí a přesto potřebuji uložit zakázku`}</div>
                  <Button onClick={() => {
                    obj['forceSave'] = true;
                    this.doSubmit(obj);
                  }}>Pokračovat v uložení</Button>
                </div>
              ),
            }, 'warning'));
          }
        }
      }
    });
  }

  handleStateChange(values) {
    const {other, actualOrderEdit} = this.state;

    if (actualOrderEdit === -1) {
      this.setState({
        ...values
      })
    } else {

      let suborders = this.state.suborders.slice();

      suborders[actualOrderEdit] = {
        ...suborders[actualOrderEdit],
        ...values,
      };
      this.setState({ suborders });
    }
  }

  handleConfirmedChangedFromTop(key, isConfirmed) {
    let suborders = this.state.suborders.slice();

    suborders[key] = {
      ...suborders[key],
      isConfirmed,
    };
    this.setState({ suborders });
  }

  createBody(state, isBase) {
    const {other, selectedVehiclePricesKey, suborders} = this.state;
    const { vehicles } = state;

    return <>
      <Form onSubmit={(e) => this.handleSubmit(e)}>
        <Row>
          <Col sm={!isBase ? 4 : 5}>
            <h3>Obecné</h3>
            {isBase ? <Row>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Doba plnění</ControlLabel>
                  <div>
                    <DateRangePicker
                      displayFormat={ dateFormatVisible }
                      startDate={state.startDate} // momentPropTypes.momentObj or null,
                      startDateId='your_unique_start_date_id' // PropTypes.string.isRequired,
                      startDatePlaceholderText='Začátek'
                      endDatePlaceholderText='Konec'
                      isOutsideRange = {() => false}
                      small
                      minimumNights={0}
                      endDate={state.endDate} // momentPropTypes.momentObj or null,
                      endDateId='your_unique_end_date_id' // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => this.handleStateChange({ startDate, endDate })} // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                    />
                  </div>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Odběratelé</ControlLabel>
                  {/*<div>
                    <ItemsSelector defaultSelectedId={state.addressBookId}
                                   itemsToFetch={'addressBook'}
                                   newEditComponent={ AddressBookEdit }
                                   filterEnable
                                   value={'companyWithIc'}
                                   onSelected={(address) => this.handleStateChange({
                                     addressBookId: address.id,
                                     addressBookItem: address,
                                     company: address.company,
                                   })}/>
                  </div>*/}
                  <div className='dataTable'>
                    <table>
                      <tbody>
                        <tr>
                          <th>Odběratel</th>
                          <th>Procentuální rozložení</th>
                        </tr>
                        {this.state.addressBookIds && this.state.addressBookIds.map((item, index) => {
                          return <tr key={item.id ? item.id : -index}>
                            <td>
                              <ItemsSelector defaultSelectedId={item.addressBookId}
                                             itemsToFetch={'addressBook'}
                                             newEditComponent={ AddressBookEdit }
                                             filterEnable
                                             appendItemsToStart={[{companyWithIc: 'nevybrán', id: 0}]}
                                             value={'companyWithIc'}
                                             onSelected={(address) => {
                                               const list = this.state.addressBookIds.slice();
                                               list[index].addressBookId = address.id;
                                               list[index].addressBookItem.company = address.company;
                                               this.setState({ addressBookIds: list, company: list.map(o => o.addressBookItem.company).join(', ') });
                                             }}/>
                            </td>
                            <td><FormControl
                              placeholder={'Procenta'}
                              id="percentage"
                              value={item.percentage}
                              min={0}
                              max={100}
                              onChange={(e) => {
                                const list = this.state.addressBookIds.slice();
                                list[index].percentage = e.target.value;
                                this.setState({ addressBookIds: list });
                              }}
                              type='number'/></td>
                            <td><button onClick={(e) => {
                              e.preventDefault();
                              let list = this.state.addressBookIds.slice();
                              list.splice(index, 1);
                              this.setState({ addressBookIds: list, company: list.map(o => o.addressBookItem.company).join(', ') });
                            }} className='transparentButton'><FontAwesome name='fas fa-times'/></button></td>
                          </tr>
                        })}
                      </tbody>
                    </table>
                    <Button onClick={() => {
                      const list = this.state.addressBookIds.slice();
                      list.push({addressBookId: 0, percentage: 0, addressBookItem: { company: '' }});
                      this.setState({ addressBookIds: list, company: list.map(o => o.addressBookItem.company).join(', ') });
                    }}>
                      Nový
                    </Button>
                  </div>
                </FormGroup>
              </Col>
            </Row> : null}
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>{isBase ? 'Číslo objednávky / R.S' : 'Číslo objednávky' }</ControlLabel>
                  <div>
                    <FormControl
                      placeholder={'Číslo objednávky'}
                      id="receivedOrderNumber"
                      value={state.receivedOrderNumber}
                      onChange={(e) => {
                        this.handleStateChange({receivedOrderNumber: e.target.value});
                      }}
                      type='text'/>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>{'Vlastní číselná řada'}</ControlLabel>
                  <div>
                    {this.props.activeSpace && this.props.activeSpace.setting.ordersNumberTemplate &&
                      <NumberQueue
                        subOrder={!isBase}
                        orderId={state.id}
                        disabled={!!state.foreignBookKeepingContractId}
                        production={true}
                        value={state.customNumber}
                        onChangeText={(t) => this.handleStateChange({customNumber: t})}
                        template={this.props.activeSpace.setting.ordersNumberTemplate}/>}
                    {/*<FormControl
                      placeholder={'Číslo'}
                      id="customNumber"
                      value={state.customNumber}
                      onChange={(e) => {
                        this.handleStateChange({customNumber: e.target.value});
                      }}
                      type='text'/>*/}
                  </div>
                </FormGroup>
              </Col>
              {suborders.length === 0 || !isBase ? <Col sm={ !isBase ? 6 : 4 }>
                <FormGroup>
                  <ControlLabel>Kontaktní osoba</ControlLabel>
                  <div>
                    <ItemsSelector defaultSelectedId={state.contactUserId}
                                   preview={SmallUserPreview}
                                   filterEnable
                                   otherPreviewProps={{style: {display: 'inline'}}}
                                   itemsToFetch={'usersWithSpecificRoleWhere'}
                                   newEditComponent={ UserEdit }
                                   newEditComponentDefaultProps={{
                                     disableAdmin: true,
                                     rolesWhere: {where: {isContactUser: 1}}
                                   }}
                                   newEditComponentUrl='users'
                                   defaultQuery={{
                                     roleWhere: { isContactUser: 1}
                                   }}
                                   value={'nameSurname'}
                                   onSelected={(user) => this.handleStateChange({
                                     contactUserId: user.id,
                                     contactUser: user,
                                   })}/>
                  </div>
                </FormGroup>
              </Col> : null}
              <Col sm={ !isBase ? 6 : 4 }>
                <FormGroup>
                  <ControlLabel>Splatnost (dny)</ControlLabel>
                  <div>
                    <FormControl
                      placeholder={'Splatnost'}
                      id="duePeriod"
                      value={state.duePeriod}
                      min={0}
                      onChange={(e) => {
                        this.handleStateChange({duePeriod: e.target.value});
                      }}
                      type='number'/>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Projektový manažer</ControlLabel>
                  <div>
                    <ItemsSelector defaultSelectedId={state.projectLeaderId}
                                   newEditComponent={UserEdit}
                                   appendItemsToStart={[{id: 0, nameSurname: 'Nevybrán', name: 'Nevybrán', surname: ''}]}
                                   newEditComponentDefaultProps={{
                                     disableAdmin: true,
                                     rolesWhere: {where: {isProjectManager: 1}}
                                   }}
                                   newEditComponentUrl='users'
                                   preview={SmallUserPreview}
                                   filterEnable
                                   otherPreviewProps={{style: {display: 'inline'}}}
                                   itemsToFetch={'usersWithSpecificRoleWhere'}
                                   defaultQuery={{
                                     roleWhere: { isProjectManager: 1}
                                   }}
                                   value={'nameSurname'}
                                   onSelected={(user) => this.handleStateChange({
                                     projectLeaderId: user.id,
                                     projectLeaderUser: user,
                                   })}/>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Zakázka dokončena</ControlLabel>
                  <div>
                    <Checkbox checked={state.isConfirmed}
                              onChange={(e) => {
                                this.handleStateChange({ isConfirmed: e.target.checked });
                              }}/>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <FormGroup>
                  <ControlLabel>Mzdová strategie</ControlLabel>
                  <div>
                    <Dropdown onSelect={e => this.setState({salaryStrategy: e})}>
                      <Dropdown.Toggle>
                        {SalaryStrategy[this.state.salaryStrategy]}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <MenuItem eventKey={0}>{SalaryStrategy[0]}</MenuItem>
                        <MenuItem eventKey={1}>{SalaryStrategy[1]}</MenuItem>
                        <MenuItem eventKey={2}>{SalaryStrategy[2]}</MenuItem>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={2}>
                <FormGroup>
                  <ControlLabel>Používat diety</ControlLabel>
                  <div>
                    <Checkbox checked={state.canUseTravelTime}
                              onChange={(e) => {
                                this.handleStateChange({ canUseTravelTime: e.target.checked });
                              }}/>
                  </div>
                </FormGroup>
              </Col>
              {state.canUseTravelTime && [<Col sm={4}>
                <FormGroup>
                  <ControlLabel>Celkový čas na cestě (23:59 pro nocování)</ControlLabel>
                  <div>
                    <TimeField
                      style={{padding: '5px', fontSize: '11px', minWidth: '55px',maxWidth: '55px'}}
                      value={getTimeString(state.travelTime)}  // {String}   required, format '00:00' or '00:00:00'
                      onChange={(e) => {
                        this.handleStateChange({travelTime : e.target.value === '' ? 0 : getFloatTime(e.target.value)});
                      }}      // {Function} required
                      input={<FormControl onFocus={(e) => e.target.select()}/>} // {Element}  default: <input type="text" />
                      colon=":"                        // {String}   default: ":"
                    />
                  </div>
                </FormGroup>
              </Col>,
                <Col sm={3}>
                  <FormGroup>
                    <ControlLabel>Pevná dieta (0 = nepoužívat)</ControlLabel>
                    <div>
                      <FormControl
                        placeholder={'Pevná dieta'}
                        id="fixedTravelPrice"
                        value={state.travelFixedPrice}
                        min={0}
                        onChange={(e) => {
                          this.handleStateChange({travelFixedPrice: e.target.value});
                        }}
                        type='number'/>
                    </div>
                  </FormGroup>
                </Col>
              ]}
            </Row>
            {isBase ? <>
              <Row>
                <Col sm={12}>
                  <FormGroup>
                    <ControlLabel>Text</ControlLabel>
                    <div>
                            <textarea style={{ width: '100%', minHeight: '150px', padding:'10px'}}
                                      onChange={(e) => {
                                        this.handleStateChange({
                                          text: e.target.value,
                                        });
                                      }}
                                      value={state.text} />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h3>Položky</h3>
                  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
                    {
                      Object.keys(suborders).map(key => {
                        return <div className='issuedOrderListItem' style={{width: '100%'}}>
                          <Row>
                            <Col sm={11}>
                              <Row style={{marginTop: '10px'}}>
                                <Col sm={3}>
                                  {suborders[key].projectLeaderUser ? <SmallUserPreview iconName='fas fa-user-tie' iconColor='brown' item={suborders[key].projectLeaderUser}/> : null}
                                  <SmallUserPreview item={suborders[key].contactUser}/>
                                </Col>
                                <Col sm={5}>
                                  {(suborders[key].customNumber ? `${suborders[key].customNumber} - ` : '')
                                    + suborders[key].construction
                                  + (suborders[key].loading !== '' ? ` ,${suborders[key].loading}`: '')
                                  + (suborders[key].unloading !== '' ? ` ,${suborders[key].unloading}`: '')
                                  }
                                </Col>
                                <Col style={{ cursor: 'pointer' }} sm={3}>
                                  <FormGroup
                                             onClick={() => this.handleConfirmedChangedFromTop(key, !suborders[key].isConfirmed)}>
                                    <ControlLabel style={{ cursor: 'pointer' }}>{suborders[key].isConfirmed ?
                                      'Dokončeno' : 'Aktivní'}</ControlLabel>
                                    <div style={{marginLeft: '10px', display: 'inline-block'}}>
                                      {
                                        !suborders[key].isConfirmed ? <FontAwesome style={{color: 'green', fontSize: '16px'}} name='fas fa-check'/>
                                          : <FontAwesome style={{color: 'red', fontSize: '16px'}} name='fas fa-times'/>
                                      }
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                            <Col sm={1}>
                              <div className='text-right'>
                                <Dropdown id={key}  pullRight
                                          onSelect={(eventKey) => {
                                            switch (eventKey) {
                                              case 'update':
                                                this.originOrder = this.state.suborders[key];
                                                this.setState({actualOrderEdit: key});
                                                break;
                                              case 'delete':
                                                let suborders = this.state.suborders.slice();
                                                suborders.splice(key, 1);
                                                this.setState({suborders});
                                                break;
                                              default:
                                                break;
                                            }
                                          }}>
                                  <Dropdown.Toggle noCaret>
                                    <FontAwesome name='fas fa-ellipsis-v'/>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <MenuItem eventKey="update">
                                      <FontAwesome style={{marginRight: '10px'}} name='fas fa-pen'/>
                                      Upravit
                                    </MenuItem>
                                    <MenuItem eventKey="delete">
                                      <FontAwesome style={{marginRight: '10px'}} name='fas fa-trash'/>
                                      Vymazat
                                    </MenuItem>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      })
                    }
                  </div>

                  <Button onClick={() => {
                    let suborders = this.state.suborders.slice();
                    let obj = JSON.parse(JSON.stringify(this.state));
                    obj.vehicles = this.originVehicles;
                    delete obj.id;
                    delete obj.suborders;
                    delete obj.actualOrderEdit;
                    delete obj.requestActive;
                    delete obj.submitActive;
                    delete obj.foreignBookKeepingContractId;
                    suborders.push(obj);
                    this.setState({
                      suborders,
                      actualOrderEdit: suborders.length - 1,
                    });
                    this.originOrder = null;
                  }}>
                    {suborders.length > 0 ? 'Nová položka' : 'Nová položka (zakázka se mění v rámcovou smlouvu)'}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h3>Přílohy</h3>
                  <Attachments identificationProps={{
                    itemId: this.state.id,
                    itemTableName: 'orders',
                  }} ref={this.setAttachmentsRef} selfListed updating={true} smSize={3} mdSize={2}/>
                </Col>
              </Row>
            </> : null}
          </Col>
          <Col sm={7}>
            <h3>Výchozí hodnoty</h3>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Firma</ControlLabel>
                  <div>
                    <FormControl
                      placeholder={'Firma'}
                      id="company"
                      value={state.company}
                      onChange={(e) => {
                        this.handleStateChange({company: e.target.value});
                      }}
                      type='text'/>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Stavba</ControlLabel>
                  <div>
                    <FormControl
                      placeholder={'Stavba'}
                      id="construction"
                      value={state.construction}
                      onChange={(e) => {
                        this.handleStateChange({construction: e.target.value});
                      }}
                      type='text'/>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Místo setkání</ControlLabel>
                  <div>
                    <FormControl
                      placeholder={'Místo setkání'}
                      id="meetingLocation"
                      value={state.meetingLocation}
                      onChange={(e) => {
                        this.handleStateChange({meetingLocation: e.target.value});
                      }}
                      type='text'/>
                  </div>
                </FormGroup>
              </Col>

            </Row>
            <Row>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Nákladka</ControlLabel>
                  <div>
                    <FormControl
                      placeholder={'Nákladka'}
                      id="loading"
                      value={state.loading}
                      onChange={(e) => {
                        this.handleStateChange({loading: e.target.value});
                      }}
                      type='text'/>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Výkladka</ControlLabel>
                  <div>
                    <FormControl
                      placeholder={'Výkladka'}
                      id="unloading"
                      value={state.unloading}
                      onChange={(e) => {
                        this.handleStateChange({unloading: e.target.value});
                      }}
                      type='text'/>
                  </div>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <ControlLabel>Materiál</ControlLabel>
                  <div>
                    <FormControl
                      placeholder={'Frakce'}
                      id="fraction"
                      value={state.fraction}
                      onChange={(e) => {
                        this.handleStateChange({fraction: e.target.value});
                      }}
                      type='text'/>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <h4>Výchozí pracovní doba</h4>
                <div className='dataTable'>
                  <table>
                    <tbody>
                    <tr>
                      <th></th>
                      <th>Od</th>
                      <th>Do</th>
                      <th>Přes.</th>
                    </tr>
                    {
                      Object.keys(state.other.dayWorkTime).map((key) => {
                        return <tr key={`table_row_${key}`}>
                          <td style={{fontSize: 20, padding: '10px'}}>
                            {moment().day(key).format('ddd')}
                          </td>
                          <td>
                            <TimeField
                              style={{padding: '5px', fontSize: '11px', minWidth: '55px',maxWidth: '55px'}}
                              value={getTimeString(state.other.dayWorkTime[key].from)}  // {String}   required, format '00:00' or '00:00:00'
                              onChange={(e) => {
                                let other = { ...state.other };
                                other.dayWorkTime[key].from = e.target.value === '' ? 0 : getFloatTime(e.target.value);
                                this.handleStateChange({other});
                              }}      // {Function} required
                              input={<FormControl onFocus={(e) => e.target.select()}/>} // {Element}  default: <input type="text" />
                              colon=":"                        // {String}   default: ":"
                            />
                          </td>
                          <td>
                            <TimeField
                              style={{padding: '5px', fontSize: '11px', minWidth: '55px',maxWidth: '55px'}}
                              value={getTimeString(state.other.dayWorkTime[key].to)}  // {String}   required, format '00:00' or '00:00:00'
                              onChange={(e) => {
                                let other = { ...state.other };
                                other.dayWorkTime[key].to = e.target.value === '' ? 0 : getFloatTime(e.target.value);
                                this.handleStateChange({other});
                              }}      // {Function} required
                              input={<FormControl onFocus={(e) => e.target.select()}/>} // {Element}  default: <input type="text" />
                              colon=":"                        // {String}   default: ":"
                            />
                          </td>
                          <td>
                            <TimeField
                              style={{padding: '5px', fontSize: '11px', minWidth: '55px',maxWidth: '55px'}}
                              value={getTimeString(state.other.dayWorkTime[key].break)}  // {String}   required, format '00:00' or '00:00:00'
                              onChange={(e) => {
                                let other = { ...state.other };
                                other.dayWorkTime[key].break = e.target.value === '' ? 0 : getFloatTime(e.target.value);
                                this.handleStateChange({other});
                              }}      // {Function} required
                              input={<FormControl onFocus={(e) => e.target.select()}/>} // {Element}  default: <input type="text" />
                              colon=":"                        // {String}   default: ":"
                            />
                          </td>
                        </tr>
                      })
                    }
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col sm={8}>
                <h4>Výchozí ceny</h4>
                <div className='dataTable' style={{ maxHeight: '380px',  overflowY: 'scroll' }}>
                  <table style={{ width:'100%' }}>
                    <tbody>
                    <tr>
                      <th>Položka</th>
                      <th>Cena</th>
                      <th>Rozpočet</th>
                      <th>Použít</th>
                    </tr>
                    {
                      Object.keys(state.other.prices).map(key => {
                        const pA = this.prices.find(o => o.id === state.other.prices[key].attributeId);
                        if (!pA)
                          return null;
                        return <tr>
                          <td style={{ maxWidth: '200px', whiteSpace: 'unset', wordBreak: 'break-word'}}>{state.other.prices[key].attributeName}</td>
                          <td>
                            {
                              pA.customOptions.useKmRange ? <table style={{ width:'100%' }}>
                                <tbody>
                                {
                                  pA.customOptions.rangeList.map((range, rIndex) => {
                                    return <tr>
                                      <td>{`${range.from} - ${range.to}: `}</td>
                                      <td>
                                        <FormControl type="number"
                                                     step='any'
                                                     style={{fontSize: '11px', backgroundColor: range.fixed ? '#ffc846' : ''}}
                                                     value={state.other.prices[key].price[rIndex]}
                                                     onChange={(e) => {
                                                       let other = { ...state.other };
                                                       other.prices[key].price = generateValueIfIsNotForRanges(other.prices[key].price, pA.customOptions.rangeList, true);
                                                       other.prices[key].price[rIndex] = e.target.value;
                                                       this.handleStateChange({other});
                                                     }}/>
                                      </td>
                                    </tr>
                                  })
                                }
                                </tbody>
                              </table> : <FormControl style={{fontSize: '11px', minWidth: '50px'}}
                                                      onFocus={(e) => e.target.select()}
                                                      onChange={ (e) => {
                                                        let other = { ...state.other };
                                                        other.prices[key].groups.forEach(g => {
                                                          g.totalPrice = e.target.value;
                                                          g.price = e.target.value / other.prices[key].groups.length;
                                                          g.fixedPrice = false;
                                                          g.enabled = true;
                                                        });
                                                        other.prices[key].price = e.target.value;
                                                        this.handleStateChange({other});
                                                      }}
                                                      type="number"
                                                      step='any'
                                                      min='0'
                                                      value={ state.other.prices[key].price }/>
                            }

                          </td>
                          <td>
                            <Button disabled={pA.customOptions.useKmRange} onClick={() => {
                              this.setState({ actualBudgetEdit: key })
                            }}>
                              Rozpočet
                            </Button>
                            <BudgetModal
                              noRecount
                              totalPrice={parseFloat(state.other.prices[key].price)}
                              onDone={(groups) => {
                                this.setState({ actualBudgetEdit: -1 })
                              }}
                              onUpdate={(groups) => {
                                let other = { ...state.other };
                                other.prices[key].groups = groups;
                                this.handleStateChange({ other });
                              }}
                              show={this.state.actualBudgetEdit === key}
                              groups={state.other.prices[key].groups}
                              onCloseClick={() => this.setState({ actualBudgetEdit: -1 })}/>
                          </td>
                          <td>
                            <Checkbox checked={state.other.prices[key].enabled}
                                      onChange={(e) => {
                                        let other = { ...state.other };
                                        other.prices[key].enabled = e.target.checked;
                                        this.handleStateChange({other});
                                      }}
                                      style={{ paddingLeft: '10px', paddingRight: '10px' }}/>
                          </td>
                        </tr>
                      })
                    }
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <h3>Výchozí ceny pro materiál</h3>
                <MaterialTable
                  forDefault
                  materialTable={state.other.materialTable}
                  materialAttributes={this.materialAttributes}
                  onTableChanged={(materialTable) => {
                    let other = { ...state.other };
                    other.materialTable = materialTable;
                    this.handleStateChange({other});
                  }}
                  storeAttributes={this.storeAttributes}
                  priceAttributes={this.prices}/>
              </Col>
              <Col sm={6}>
                <h3>Výchozí ceny pro stroje</h3>
                {/*<DefaultOrderVehiclesPrice vehiclePrices={state.other.vehicle()}/>*/}
                <div style={{ padding: 10 }}>
                  <ItemsSelector
                    filterEnable
                    pullRight
                    overrideToggle='Načtení cen ze zakázky'
                    appendItemsToStart={[{id: 0, label: 'nevybrán'}]}
                    preview={SmallOrderPreview}
                    onSelected={(item) => {
                      if(item.id !== 0) {
                        let other = { ...state.other };
                        let vehicles_ = [...state.vehicles ];
                        other.vehiclePrices = item.other.vehiclePrices;
                        other.vehiclePrices.forEach((p) => {
                          let vIndex = vehicles_.findIndex(o => o.id === p.id);
                          if (vIndex > -1) {
                            vehicles_[vIndex].other.priceEq = p.priceEq;
                            vehicles_[vIndex].isSelected = true;
                          }
                        });
                        this.handleStateChange({other, vehicles: vehicles_});
                      }
                    }}
                    itemsToFetch='orders'
                    value='label'/>
                </div>

                <Modal /*dialogClassName="modal-60w"*/ onHide={() => {
                  this.setState({selectedVehiclePricesKey: null});
                }} show={selectedVehiclePricesKey !== null}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Kalkulace ceny
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='dataTable' style={{marginBottom: '15px'}}>
                      <table style={{marginBottom: '15px'}}>
                        <tbody>
                        <tr>
                          <th>Položka</th>
                          <th>Cena</th>
                        </tr>
                        {selectedVehiclePricesKey !== null ? state.vehicles[selectedVehiclePricesKey].other.priceEq.map((price, index) => {

                          const pA = this.prices.find(o => o.id === price.attributeId);
                          if (!pA) {
                            // Attribute doesnt not exists in prices list delete it frow vehicle equation
                            let vehicles = state.vehicles;
                            vehicles[selectedVehiclePricesKey].other.priceEq.splice(index, 1);
                            this.handleStateChange({vehicles});
                            return null;
                          }

                          return <tr key={`${index}_priceEq`}>
                            <td>
                              <ItemsSelector externList={this.prices}
                                             value={'name'}
                                             getExternIndex
                                             onSelected={(selPrice) => {
                                               let vehicles = state.vehicles.slice();
                                               vehicles[selectedVehiclePricesKey].other.priceEq[index].attributeId = selPrice.id;
                                               vehicles[selectedVehiclePricesKey].other.priceEq[index].attributeName = selPrice.name;
                                               this.handleStateChange({vehicles});
                                             }}
                                             defaultSelectedId={price.attributeId}/>
                            </td>
                            <td>
                              {
                                pA ? (pA.customOptions.useKmRange ? <table>
                                  <tbody>
                                  {
                                    pA.customOptions.rangeList.map((range, rIndex) => {
                                      return <tr>
                                        <td>{`${range.from} - ${range.to}: `}</td>
                                        <td>
                                          <FormControl type="number"
                                                       style={{fontSize: '11px', backgroundColor: range.fixed ? '#ffc846' : ''}}
                                                       value={price.price[rIndex]}
                                                       onChange={(e) => {
                                                         let vehicles = state.vehicles.slice();
                                                         vehicles[selectedVehiclePricesKey].other.priceEq[index].price =
                                                           generateValueIfIsNotForRanges(vehicles[selectedVehiclePricesKey].other.priceEq[index].price, pA.customOptions.rangeList);
                                                         vehicles[selectedVehiclePricesKey].other.priceEq[index].price[rIndex] = e.target.value;
                                                         this.handleStateChange({other});
                                                       }}/>
                                        </td>
                                      </tr>
                                    })
                                  }
                                  </tbody>
                                </table> : <FormControl type="number"
                                                        style={{fontSize: '11px', minWidth: '40px'}}
                                                        value={price.price}
                                                        onChange={(e) => {
                                                          let vehicles = state.vehicles.slice();
                                                          vehicles[selectedVehiclePricesKey].other.priceEq[index].price = e.target.value;
                                                          this.handleStateChange({vehicles});
                                                        }}/>) : null
                              }
                            </td>
                            <td>
                              <button className='transparentButton' onClick={(e) => {
                                e.preventDefault();
                                let vehicles = state.vehicles.slice();
                                vehicles[selectedVehiclePricesKey].other.priceEq.splice(index, 1);
                                this.handleStateChange({vehicles});
                              }}>
                                <FontAwesome style={{fontSize:'18px', color: 'grey', }} name='fas fa-times'/>
                              </button>
                            </td>
                          </tr>
                        }) : null}
                        </tbody>
                      </table>
                      <Button onClick={() => {

                        let vehicles = state.vehicles.slice();

                        vehicles[selectedVehiclePricesKey].other.priceEq.push({
                          attributeId: this.prices.length > 0 ? this.prices[0].id : null,
                          attributeName: this.prices.length > 0 ? this.prices[0].name : '',
                          price: 0,
                        });
                        this.handleStateChange({vehicles});
                      }}>
                        Nový
                      </Button>
                    </div>
                  </Modal.Body>
                </Modal>
                <AutoSizer disableHeight>
                  {({width}) => (
                    <Table
                      width={width}
                      height={300}
                      headerHeight={30}
                      rowHeight={30}
                      rowStyle={({index}) => {
                        if(index > -1) {
                          return {
                            backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff',
                            opacity: vehicles[index].identifier.toLowerCase()
                              .includes(this.state.vehicleFilterValue.toLowerCase()) ? 1 : 0.3
                          }
                        }
                      }}
                      rowCount={vehicles.length}
                      rowGetter={({ index }) => vehicles[index]}
                    >
                      <Column
                        label={
                          <input type="checkbox" onChange={(e) => {
                            let vehicles = state.vehicles.slice();
                            vehicles.forEach((row) => {
                              row.isSelected = e.target.checked;
                            });
                            this.handleStateChange({ vehicles });
                          }}/>
                        }
                        dataKey='isSelected'
                        width={14}
                        minWidth={14}
                        cellRenderer={(cell) => <input type="checkbox" checked={cell.cellData} onChange={(e) => {
                          let vehicles = state.vehicles.slice();
                          vehicles[cell.rowIndex].isSelected =  e.target.checked;
                          this.handleStateChange({ vehicles });
                        }}/>
                        }>
                      </Column>
                      <Column
                        label={<FormControl
                          style={{fontSize: "11px", height: "22px"}}
                          value={this.state.vehicleFilterValue}
                          onChange={(e) => {
                          this.setState({vehicleFilterValue: e.target.value});
                        }}
                          type='text'/>}
                        dataKey='identifier'
                        width={width/2}
                      />
                      <Column
                        label='Výchozí ceny'
                        dataKey='other'
                        cellRenderer={(cell) => {
                          let s = '';
                          cell.cellData.priceEq.forEach(p => {
                            if (p) {
                              s += `${p.attributeName}: ${p.price},-  `
                            }
                          });
                          return <button onClick={(e) => {
                            e.preventDefault();
                            this.setState({selectedVehiclePricesKey: cell.rowIndex})
                          }}>
                            {
                              cell.cellData.priceEq.length === 0 ? 'Žádné položky' : s
                            }
                          </button>
                        }}
                      />
                    </Table>
                  )}
                </AutoSizer>

              </Col>
            </Row>
          </Col>
        </Row>
        <SubmitButton onClick={this.props.disableUrlCheck ? (e) => this.handleSubmit(e) : null}
                      style={{marginTop: '20px'}} bsClass='btn btn-primary btn-block' loading={this.state.submitActive}>
          Uložit
        </SubmitButton>
        <Row>
          <Col sm={12}>
            <h3>Záznamy zakázky</h3>
            <RowsOfOrder
              ids={[...state.suborders ? state.suborders.map(o => o.id) : [], state.id]}
              order={state}
              disableAutoLoad
              materials={this.materialAttributes}
              stores={this.storeAttributes}
              prices={this.prices} />
          </Col>
        </Row>

      </Form>
    </>;
  }

  render() {
    const { actualOrderEdit, suborders } = this.state;
    const body = this.createBody(this.state, true);
    const body2 = actualOrderEdit > -1 ? this.createBody(suborders[actualOrderEdit], false) : null;
    return (
      <div>
        <ContentWithFixedHeader drawOnlyChildren={this.props.disableUrlCheck} header={
          <CanView visible={!this.props.disableUrlCheck}>
            <BackHeader
              fontSize='18px'
              headerText={suborders.length > 0 ? 'Zakázka, rámcová slmouva' : 'Zakázka'}
              onBackClick={() => this.props.history.replace(
                this.props.match.url.split('/' + this.props.match.params.id)[0])}/>
          </CanView>
        }>
          <div className='editForm'>
            <div className='nest'>
              <LoadingOrRender requestActive={this.state.requestActive}>
                {body}
              </LoadingOrRender>
            </div>
          </div>
          <Modal dialogClassName="modal-90w" onHide={() => {
            let {suborders, actualOrderEdit} = this.state;
            if (this.originOrder === null) {
              suborders.splice(actualOrderEdit, 1);
            } else {
              suborders[actualOrderEdit] = this.originOrder;
            }
            this.setState({actualOrderEdit: -1, suborders});
          }} show={actualOrderEdit > -1}>
            <Modal.Header closeButton>
              <Modal.Title>
                Stavba
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {body2}
            </Modal.Body>
          </Modal>
        </ContentWithFixedHeader>
      </div>);
  }
}

OrderEdit.propTypes = {
  disableUrlCheck: PropTypes.bool,
  activeSpace: PropTypes.object,
  ...BaseEditPropTypes,
};

OrderEdit.defaultProps = {
  disableUrlCheck: false,
};

export default connect(mapStateToProps)(OrderEdit);
