import React, {Component, useState} from "react";
import {Button, Dropdown, FormControl, MenuItem, Modal} from "react-bootstrap";
import {CanView} from "../../Main";
import {generateValueIfIsNotForRanges, getRangeIndex, roundFloatTwoDigit} from "../../../AppObjects";
import FontAwesome from "react-fontawesome";
import {
  BaseCalculationItem,
  IDispatchingAttributesCustom,
  IPriceAttributesCustom,
  OrderOther,
  Material,
  IMaterialAttributesCustom
} from "@cml/types";
import { getTimeDiff } from '../components/datarowBase';
import {Attributes, DataRow, Orders} from "@cml/models";
import {useSelector} from "react-redux";
import {AuthenticateState} from "@cml/redux-store";
import Results from "../Results";
import {getAttributes} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import MaterialTable from "./MaterialTable";

export const getCalculation = (item: Partial<DataRow>, params: {
  attribute: {customOptions: IDispatchingAttributesCustom},
  materialAttributes: {
    id: number;
    name: string;
    customOptions: IMaterialAttributesCustom
  }[];
  storeAttributes: {
    id: number
  }[];
  priceAttributes: {
    id: number;
    name: string;
    customOptions: IPriceAttributesCustom;
  }[];
  defaultMaterialTable?: Material[]; // From order for example
}) => {
  const sum = getTimeDiff(item as DataRow).sum;
  let totalPrice = 0;
  let totalCost = 0;
  let priceCalcString = '';
  let first = 0;
  const canUseCosts = params.attribute ? params.attribute.customOptions.canUseCosts : false;

  let priceEq: BaseCalculationItem[] = item.priceEq
    ? (item.priceEq as BaseCalculationItem[]).slice() : [];
  if (item.materialTable) {
    priceEq = priceEq.concat(MaterialTable.GenerateCalculationArray({
      materialTable: item.materialTable as Material[],
      materialAttributes: params.materialAttributes,
      priceAttributes: params.priceAttributes,
      storeAttributes: params.storeAttributes,
      defaultMaterialTable: params.defaultMaterialTable,
    }))
  }

  const attributesPricesSum: {[key:number]: number} = {};
  const attributesCostSum: {[key:number]: number} = {};

  priceEq.forEach((item) => {
    let total = item.total;
    let costTot = item.costTotal;
    const index = params.priceAttributes.findIndex(o => o.id === item.attributeId);
    if (first > 0 && index > -1) {
      priceCalcString += ' + ';
    }
    if (index > -1) {
      first++;
      if (params.priceAttributes[index].customOptions.isHourAttribute) {
        total = (item.price as number) * sum;
        costTot = (item.costPrice as number) * sum;
      }
    }
    let priceS = item.price;
    let costS = item.costPrice;
    item.amount = typeof item.amount === 'string' ? parseFloat(item.amount) : item.amount;
    let amount = roundFloatTwoDigit(item.amount).toString();
    if (index > -1) {
      if (params.priceAttributes[index].customOptions.useKmRange) {
        const i = getRangeIndex(item.amount, params.priceAttributes[index].customOptions.rangeList);
        if (i > -1) {
          priceS = (item.price as number[])[i];
          if (item.costPrice)
            costS = (item.costPrice as number[])[i];
          if (params.priceAttributes[index].customOptions.rangeList[i].multipleByHours) {
            amount = `${amount} KM => ${sum}`;
          }
        } else {

        }
      } else {
        attributesPricesSum[item.attributeId] = attributesPricesSum[item.attributeId] ?
          (attributesPricesSum[item.attributeId] + (Number(item.price as number))) : (Number(item.price as number))
        attributesCostSum[item.attributeId] = attributesCostSum[item.attributeId] ?
          (attributesCostSum[item.attributeId] + (Number(item.costPrice as number))) : (Number(item.costPrice as number))
      }
    }
    priceCalcString += index > -1 ?
      `${item.isMaterial ? item.name : params.priceAttributes[index].name}: ${amount} * ${canUseCosts ? '(' : ''}${priceS}${canUseCosts ? '-' + costS + ')' : ''}`  : '';
    totalPrice += total;
    totalCost += costTot;
  });

  if (isNaN(totalCost))
    totalCost = 0;

  // Filter priceEq for material only and summarize same attribute
  let sumPriceEq = priceEq.filter(p => p.isMaterial).map(p => ({
    ...p,
    price: attributesPricesSum[p.attributeId],
    total: attributesPricesSum[p.attributeId] * p.amount,
    costPrice: attributesCostSum[p.attributeId],
    costTotal: attributesCostSum[p.attributeId] * p.amount,
  }));

  const sumPriceEqAttributesIds = sumPriceEq.map(p => p.attributeId);

  // @ts-ignore
  sumPriceEq = sumPriceEq.concat(priceEq.filter(p => !p.isMaterial
    && !sumPriceEqAttributesIds.includes(p.attributeId)));

  return {
    priceCalcString: priceCalcString,
    totalPrice: totalPrice,
    totalCost: totalCost,
    priceEq,
    sumPriceEq,
    showCosts: item.priceEq ? item.priceEq.length < priceEq.length: priceEq.length > 0,
  }
}

interface Props {
  attribute?: Attributes;
  priceEq: BaseCalculationItem[];
  selectedOrder?: Orders;
  data: {
    from: number;
    to: number;
    break: number;
    materialTable?: Material[];
  };
  onTotalChanges?: (totalPrice: number, totalCost: number) => void
  onPriceEqChanged: (priceEq: object[]) => void;
  singleRowEditMode?: boolean;
}

export default function PriceCalculation(props: Props) {
  const [show, setShow] = useState(false);
  const authenticate = useSelector((state: {authenticate: AuthenticateState}) => state.authenticate);

  const { attributes: priceAttributes,
    isValidating: priceValidating } = getAttributes(
    'price' );
  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes(
    'material' );
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store' );


  if(!priceAttributes || !materialAttributes || !storeAttributes)
    return <div>Loading</div>

  const {
    attribute,
    selectedOrder,
    data } = props;
  const timeDiff = getTimeDiff(data);
  let canUseCosts = true;
  if (attribute) {
    canUseCosts = (attribute.customOptions as IDispatchingAttributesCustom).canUseCosts;
  }

  const { priceEq, totalPrice, totalCost, priceCalcString}: {
    priceEq: BaseCalculationItem[],
    totalPrice: number,
    totalCost: number,
    priceCalcString: string,
  } = getCalculation({
    ...data,
    priceEq: props.priceEq,
  }, {
    attribute: props.attribute as { customOptions: IDispatchingAttributesCustom},
    // @ts-ignore
    materialAttributes,
    // @ts-ignore
    priceAttributes,
    storeAttributes,
    defaultMaterialTable: selectedOrder ? (selectedOrder.other as OrderOther).materialTable : undefined,
  })

  if(props.onTotalChanges)
    props.onTotalChanges(totalPrice, totalCost);

  let usageForChangePrice = false;
  if (typeof totalPrice === "undefined" ||  typeof totalCost === "undefined") {
    usageForChangePrice = true;
  }

  const body = <>
    <div className='dataTable text-center' style={{marginBottom: '15px'}}>
      <table>
        <tbody>
        <tr>
          <th>Položka</th>
          {!usageForChangePrice && <th>Množství</th>}
          <th>Cena</th>
          <CanView visible={canUseCosts && !usageForChangePrice}>
            <th>Náklady</th>
          </CanView>
          {!usageForChangePrice && <th>Cena za množství</th>}
          <CanView visible={canUseCosts && !usageForChangePrice}>
            <th>Náklady za množství</th>
            <th>Zisk</th>
          </CanView>

        </tr>
        {
          priceEq !== null ? priceEq.map((item, index) => {
            const priceAttribute = priceAttributes.find(o => o.id === item.attributeId);
            if(!priceAttribute)
              return null;
            const priceAttributeCustomOption = priceAttribute.customOptions as IPriceAttributesCustom;
            return <tr key={index}>
              <td>
                <Dropdown
                  // @ts-ignore
                  bsSize="xsmall"
                  disabled={item.isMaterial}
                  // @ts-ignore
                  onSelect={(e: { customOptions: any; id: number; }) => {
                    const co = e.customOptions;
                    let defaultPrice = co.useKmRange ? co.rangeList.map((o: { defaultPrice: any; }) => o.defaultPrice) : co.defaultPrice;
                    if (selectedOrder) {
                      const def = (selectedOrder.other as OrderOther).prices.find(o => o.attributeId === e.id);
                      if (def)
                        defaultPrice = def.price;
                    }
                    const pe = props.priceEq.slice();
                    pe[index].attributeId = e.id;
                    if (co.isHourAttribute)
                      pe[index].amount = getTimeDiff(data).sum;
                    else
                      pe[index].amount = 0;
                    pe[index].price = defaultPrice;
                    pe[index].costPrice =  co.useKmRange ? co.rangeList.map(() => 0) : 0;
                    if (co.useKmRange) {
                      pe[index].price = generateValueIfIsNotForRanges(pe[index].price, co.rangeList);
                      pe[index].costPrice = generateValueIfIsNotForRanges(pe[index].costPrice, co.rangeList);
                      const i = getRangeIndex(pe[index].amount, co.rangeList);
                      pe[index].total = (pe[index].price as number[])[i] * (co.rangeList[i].fixed ?
                        (co.rangeList[i].multipleByHours ? timeDiff.sum : 1) : pe[index].amount);
                      pe[index].costTotal = (pe[index].costPrice as number[])[i] * (co.rangeList[i].fixed ?
                        (co.rangeList[i].multipleByHours ? timeDiff.sum : 1) : pe[index].amount);
                    } else {
                      pe[index].total = pe[index].price as number * pe[index].amount;
                      pe[index].costTotal = pe[index].costPrice as number * pe[index].amount;
                    }
                    props.onPriceEqChanged(pe);
                  }}
                  id="prices">
                  <Dropdown.Toggle>
                    {priceAttribute.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      priceAttributes.map((priceAtt, priceAttindex) => {
                        if (priceAtt.spaceId !== authenticate.activeSpaceId)
                          return null;
                        return <MenuItem key={priceAttindex} eventKey={priceAtt}>
                          {priceAtt.name}
                        </MenuItem>
                      })
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </td>

              {!usageForChangePrice && <td>
                <FormControl type="number"
                             onFocus={(e) => (e.target as unknown as HTMLInputElement).select()}
                             disabled={priceAttributeCustomOption.isHourAttribute || item.isMaterial}
                             style={{fontSize: '11px', minWidth: '40px'}}
                             value={item.amount}
                             onChange={(e) => {
                               const pe = props.priceEq.slice();
                               pe[index].amount = (e.target as HTMLInputElement).value === '' ? 0 : Number((e.target as HTMLInputElement).value);
                               if (priceAttributeCustomOption.useKmRange) {
                                 pe[index].price = generateValueIfIsNotForRanges(pe[index].price, priceAttributeCustomOption.rangeList);
                                 pe[index].costPrice = generateValueIfIsNotForRanges(pe[index].costPrice, priceAttributeCustomOption.rangeList);
                                 const i = getRangeIndex(pe[index].amount, priceAttributeCustomOption.rangeList);
                                 pe[index].total = (pe[index].price as number[])[i] * (priceAttributeCustomOption.rangeList[i].fixed ?
                                   (priceAttributeCustomOption.rangeList[i].multipleByHours ? timeDiff.sum : 1) : pe[index].amount);
                                 pe[index].costTotal = (pe[index].costPrice as number[])[i] * (priceAttributeCustomOption.rangeList[i].fixed ?
                                   (priceAttributeCustomOption.rangeList[i].multipleByHours ? timeDiff.sum : 1) : pe[index].amount);
                               } else {
                                 pe[index].total = pe[index].price as number * pe[index].amount;
                                 pe[index].costTotal = pe[index].costPrice as number * pe[index].amount;
                               }
                               props.onPriceEqChanged(pe);
                             }}/>
              </td> }
              <td>
                {
                  priceAttributeCustomOption.useKmRange ? <table>
                      <tbody>
                      {
                        priceAttributeCustomOption.rangeList.map((range, rIndex) => {
                          return <tr>
                            <td>{`${range.from} - ${range.to}: `}</td>
                            <td>
                              <FormControl type="number"
                                           disabled={item.isMaterial}
                                           onFocus={(e) => (e.target as unknown as HTMLInputElement).select()}
                                           style={{fontSize: '11px', backgroundColor: range.fixed ? '#ffc846' : ''}}
                                           value={(item.price as number[])[rIndex]}
                                           onChange={(e) => {
                                             const pe = props.priceEq.slice();
                                             pe[index].price = generateValueIfIsNotForRanges(pe[index].price, priceAttributeCustomOption.rangeList);
                                             const i = getRangeIndex(pe[index].amount, priceAttributeCustomOption.rangeList);
                                             (pe[index].price as number[])[rIndex] = Number((e.target as HTMLInputElement).value);
                                             pe[index].total = (pe[index].price as number[])[i] * (priceAttributeCustomOption.rangeList[i].fixed ?
                                               (priceAttributeCustomOption.rangeList[i].multipleByHours ? timeDiff.sum : 1): pe[index].amount);
                                             props.onPriceEqChanged(pe);
                                           }}/>
                            </td>
                            {
                              range.multipleByHours ? <td>{` * ${timeDiff.sum}h`}</td> : null
                            }
                          </tr>
                        })
                      }
                      </tbody>
                    </table> :
                    <FormControl type="number"
                                 disabled={item.isMaterial}
                                 onFocus={(e) => (e.target as unknown as HTMLInputElement).select()}
                                 style={{fontSize: '11px', minWidth: '40px'}}
                                 value={item.price as number}
                                 onChange={(e) => {
                                   const pe = props.priceEq.slice();
                                   pe[index].price = Number((e.target as HTMLInputElement).value);
                                   pe[index].total = (pe[index].price as number) * pe[index].amount;
                                   props.onPriceEqChanged(pe);
                                 }}/>
                }
              </td>
              <CanView visible={canUseCosts && !usageForChangePrice}>
                <td>
                  {
                    priceAttributeCustomOption.useKmRange ? <table>
                        <tbody>
                        {
                          priceAttributeCustomOption.rangeList.map((range, rIndex) => {
                            return <tr>
                              <td>{`${range.from} - ${range.to}: `}</td>
                              <td>
                                <FormControl type="number"
                                             onFocus={(e) => (e.target as unknown as HTMLInputElement).select()}
                                             disabled={item.isMaterial}
                                             style={{fontSize: '11px', backgroundColor: range.fixed ? '#ffc846' : ''}}
                                             value={(item.costPrice as number[])[rIndex]}
                                             onChange={(e) => {
                                               const pe = props.priceEq.slice();
                                               pe[index].costPrice = generateValueIfIsNotForRanges(pe[index].costPrice, priceAttributeCustomOption.rangeList);
                                               const i = getRangeIndex(pe[index].amount, priceAttributeCustomOption.rangeList);
                                               (pe[index].costPrice as number[])[rIndex] = Number((e.target as HTMLInputElement).value);
                                               pe[index].costTotal = (pe[index].costPrice as number[])[i] * (priceAttributeCustomOption.rangeList[i].fixed ?
                                                 (priceAttributeCustomOption.rangeList[i].multipleByHours ? timeDiff.sum : 1) : pe[index].amount);
                                               props.onPriceEqChanged(pe);
                                             }}/>
                              </td>
                              {
                                range.multipleByHours ? <td>{` * ${timeDiff.sum}h`}</td> : null
                              }
                            </tr>
                          })
                        }
                        </tbody>
                      </table> :
                      <FormControl type="number"
                                   disabled={item.isMaterial}
                                   onFocus={(e) => (e.target as unknown as HTMLInputElement).select()}
                                   style={{fontSize: '11px', minWidth: '40px'}}
                                   value={item.costPrice as number}
                                   onChange={(e) => {
                                     const pe = props.priceEq.slice();
                                     pe[index].costPrice = Number((e.target as HTMLInputElement).value);
                                     pe[index].costTotal = pe[index].costPrice as number * pe[index].amount;
                                     props.onPriceEqChanged(pe);
                                   }}/>
                  }
                </td>
              </CanView>
              {!usageForChangePrice && <td>
                { roundFloatTwoDigit(item.total) }
              </td>}
              <CanView visible={canUseCosts && !usageForChangePrice}>
                <td>
                  { roundFloatTwoDigit(item.costTotal) }
                </td>
                <td style={{color: item.total - item.costTotal < 0 ? 'red' : 'darkgreen'}}>
                  {`${item.total - item.costTotal < 0 ? '' : '+'}${roundFloatTwoDigit(item.total - item.costTotal)}`}
                </td>
              </CanView>
              { !item.isMaterial ? <td>
                <button className='transparentButton' onClick={() => {
                  const pe = props.priceEq.slice();
                  pe.splice(index, 1);
                  props.onPriceEqChanged(pe);
                }}>
                  <FontAwesome style={{fontSize:'18px', color: 'grey', }} name='fas fa-times' />
                </button>
              </td> : <td>{item.name}</td>}

            </tr>
          }) : null
        }
        </tbody>
      </table>
    </div>,
    {priceAttributes.length > 0 ? <Button onClick={() => {
      const pe = props.priceEq.slice();
      const co = priceAttributes[0].customOptions as IPriceAttributesCustom;
      let defaultPrice = co.useKmRange ? co.rangeList.map(o => o.defaultPrice) : co.defaultPrice;
      if (selectedOrder) {
        const def = (selectedOrder.other as OrderOther).prices.find(o => o.attributeId === priceAttributes[0].id);
        if (def)
          defaultPrice = def.price;
      }
      pe.push({
        isMaterial: false,
        attributeId: priceAttributes[0].id,
        price: defaultPrice,
        costPrice: co.useKmRange ? co.rangeList.map(o => 0) : 0,
        amount: co.isHourAttribute ? getTimeDiff(data).sum : 0,
        total: 0,
        costTotal: 0
      });
      props.onPriceEqChanged(pe);
    }}>
      Nová
    </Button> : <div>
      {`Prosím vytvořte cenové položky v nastavení -> Atribut ceny dispečinku`}
    </div>}
  </>
  if (typeof totalPrice === "undefined" ||  typeof totalCost === "undefined") {
    return body;
  }

  return <>

    <Modal dialogClassName="modal-60w" onHide={() => {
      setShow(false);
    }} show={show}>
      <Modal.Header closeButton>
        <Modal.Title>
          Kalkulace ceny
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {body}
      </Modal.Body>
      <Modal.Footer>
        <div>{
          `Celková cena: ${roundFloatTwoDigit(totalPrice)}`
        }</div>
        <CanView visible={canUseCosts}>
          <div>{`Celkové náklady: ${roundFloatTwoDigit(totalCost)}`}</div>
          <div>{'-'.repeat(('Celkové náklady: ' + totalCost).length)}</div>
          <div style={{color: totalPrice - totalCost < 0 ? 'red' : 'darkgreen'}}>
            {`${totalPrice - totalCost < 0 ? '' : '+'}${roundFloatTwoDigit(totalPrice - totalCost)}`}
          </div>
        </CanView>
      </Modal.Footer>
    </Modal>
    <Button bsStyle='primary' bsSize="xsmall" onClick={() => setShow(true)}>
      {priceCalcString === '' ? 0 : priceCalcString }
    </Button>
  </>
}