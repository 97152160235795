import * as React from 'react';
import FontAwesome from 'react-fontawesome';
import {YesNoDialog} from "../YesNoDialog";

export interface FileViewerProps {
    file: FileObject | undefined | Blob;
    index: number | string;
    height?: string;
    width?: string;
    disableHover?: boolean;
    disableUpdating? :boolean;
    onDelete?: (index: number | string) => void;
    onClick?: (file: FileObject) => void;
    askBeforeDeleteFile?: boolean;
}

export interface FileViewerState {
    result: string | null | ArrayBuffer;
    hover: boolean;
}

export interface FileObject {
    url?: string | undefined;
    name: string;
    size: number;
    type: string;
}

export default class FileViewer extends React.Component<FileViewerProps,FileViewerState> {
    public static defaultProps = {
        height: '100px',
        width: '100%',
        disableHover: false,
        disableUpdating: false,
        askBeforeDeleteFile: false,
    };

    constructor(props: FileViewerProps) {
        super(props);
        this.state = {
            result: null,
            hover: false,
        };
        this.loadFile(props.file);
    }

    loadFile(file: FileObject | undefined | Blob) {
        const reader = new FileReader();
        reader.onload = (e) => {
            if(e.target)
                this.setState({ result: e.target.result });
        };
        if (file) {
            if(typeof (file as FileObject).url !== 'undefined')
                return;
            reader.readAsDataURL(file as Blob);
        }
    }

    componentWillUpdate(nextProps: Readonly<FileViewerProps>, nextState: Readonly<FileViewerState>, nextContext: any): void {
        if(this.state.result === null || nextProps.index !== this.props.index) {
            this.loadFile(nextProps.file);
        }
    }

    renderDeleteButton() {
        const { index, onDelete, askBeforeDeleteFile }  = this.props;

        if (askBeforeDeleteFile) {
            return <YesNoDialog onYes={() => {
                this.setState({result: null});
                if (onDelete) onDelete(index);
            }} fontSize={'14px'} message={'Opravdu chcete vymazat daný záznam ?'}/>
        }

        return <button type='button' style={{display: 'inline-block'}} onClick={() => {
            this.setState({result: null});
            if (onDelete) onDelete(index);
        }} className='text-right transparentButton'>
            <FontAwesome name='fas fa-trash' />
        </button>
    }

    render() {
        const { file, onClick, height, width, disableHover, disableUpdating }  = this.props;
        if(!file)
            return null;
        const { result, hover }  = this.state;
        let toRender = null;
        if (file.type === 'application/pdf') {
            toRender = <div className='text-center' style={{height: height}}>
                <FontAwesome style={{ marginRight: '10px', fontSize: '64px', color: 'red' }} name='fas fa-file-pdf' />
                <div style={{wordWrap: 'break-word'}}>
                    {(file as FileObject).name}
                </div>
            </div>;
        } else if ((file as FileObject).type.split('/')[0] === 'image') {
            toRender = <img height={height} width={width} alt={(file as FileObject).name} src={(file as FileObject).url ? (file as FileObject).url as string : result as string}/>;
        } else {
            toRender = <div className='text-center' style={{height: height}}>
                <FontAwesome style={{ marginRight: '10px', fontSize: '64px', color: 'grey' }} name='fas fa-file' />
                <div style={{wordWrap: 'break-word'}}>
                    {(file as FileObject).name}
                </div>
            </div>;
        }
        return <div
            onMouseEnter={() => this.setState({hover: true})}
            onMouseLeave={() => this.setState({hover: false})}
            className={disableHover ? 'text-center' : 'text-center RecordAttachment'}>
            <div onClick={() => onClick ? onClick(file as FileObject) : null}>
                {toRender}
            </div>
            <div className='text-right' style={{display: 'inline-block', width: '100%', marginTop: '10px'}}>
                {hover && !disableHover ? <a style={{display: 'inline-block', marginRight: '10px'}}
                                             className='text-right'
                                             href={typeof (file as FileObject).url === 'undefined' ? (file as FileObject).name : (file as FileObject).url} download>
                    <FontAwesome name='fas fa-file-download' /></a> : null}
                {hover && !disableHover && !disableUpdating ?
                    this.renderDeleteButton()
                    : <div style={{paddingBottom: '30px'}}/>}
            </div>

        </div>
    }
}