import React from "react";
import store from "../../redux/store";
import { useSelector } from "react-redux";
import {
  Attributes,
} from "@cml/models";
import { DataRowsState, setActualRowChanging} from '@cml/redux-store';

import {Draggable, DraggingStyle} from "react-beautiful-dnd";
import {rowStateColors, rowStateColorsOdd} from "./components/datarowBase";
import DataRow from "./DataRow";
import {selectDataRowValue} from "./dataRowComponents/selector";

export const swrOption = {
  revalidateOnFocus: false,
  revalidateOnMount: true,
}

interface ContainerProps {
  index: number;
  attribute: Attributes;
  showDate: boolean;
  indexForColoring: number;
  onLogRequested: (index: number) => void;
  dataRow: { id: number,
    indexForColoring: number;
    attributeId: number }
}

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle,
  index: number,
  isConfirmed: number,
  invoiceForeignId: string | null,
  isSelected: boolean) => (
  {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    //opacity: isHighlight ? 1.0 : 0.2,

    // change background colour if dragging
    background: index % 2 === 0 ? rowStateColors[isConfirmed] : rowStateColorsOdd[isConfirmed],
    //border: isSelected ? '2px solid red' : 'none',


    // styles we need to apply on draggables
    ...draggableStyle,
  });

export default function DataRowContainer({
                                           attribute,
                                           dataRow,
                                           index,
                                           showDate,
                                           onLogRequested,
                                           indexForColoring
}: ContainerProps) {
  const highLighted = useSelector((state: {dispatching: DataRowsState}) =>
    state.dispatching.actualRowIsChangingIndex === index);

  const isConfirmed = useSelector(selectDataRowValue(index, false, 'isConfirmed'));

  const invoiceForeignId = useSelector(selectDataRowValue(index, false, 'invoiceForeignId'));

  if (dataRow.attributeId !== attribute.id)
    return <Draggable key={dataRow.id} draggableId={"dndId" + dataRow.id} index={index}>
      {(draggableProvided, draggableSnapshot) => (<tr
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
      >
      </tr>)}
    </Draggable>;
  else {
    return <Draggable key={`${dataRow.id}`} draggableId={"dndId" + dataRow.id} index={index}>
      {(draggableProvided, draggableSnapshot) => (<tr
        tabIndex={index}
        onClick={() => store.dispatch(setActualRowChanging(index))}
        className={highLighted ? 'red' : 'normal'}
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        // @ts-ignore
        style={getItemStyle(
          draggableSnapshot.isDragging,
          draggableProvided.draggableProps.style as DraggingStyle,
          // @ts-ignore
          indexForColoring,
          isConfirmed,
          invoiceForeignId,
          highLighted,
        )}
      >
        <DataRow
          unresolvedMode={false}
          singleRowEditMode={false}
          attribute={attribute}
          index={index}
          onAddConnWithInvoice={() => {}}
          shouldRender={highLighted}
          showDate={showDate}
          onLogRequested={onLogRequested}
          mapUsersDoubled={0}
          mapVehiclesDoubled={0}
          onOrderRequired={() => {}}
        />
      </tr>)}
    </Draggable>;
  }
}