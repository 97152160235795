import {Component} from "react";
import React from "react";
import {BaseViewPropTypes} from '../CommonListContainer';
import FontAwesome from "react-fontawesome";
import moment from "moment";
import {dateFormatVisible, dateFromat, getBaseUrl} from "../../AppObjects";
import UserPreview, {SmallUserPreview} from "../users/Preview";
import VehiclePreview from "../vehicles/Preview";
import AttendancePreview from "../attendances/Preview";
import TaskPreview from "../tasks/Preview";
import ToolView from "../tools/Preview";
import SmsView from "../sms/Preview";
import RoleView from "../roles/Preview";
import AttributePreview from "../attributes/Preview";
import AddressBookPreview from "../addressBook/Preview";
import HeaderGenerator from "../dispatching/components/HeaderGenerator";
import Results from "../dispatching/Results";
import {Button, Col, ControlLabel, FormGroup, Modal, Row} from "react-bootstrap";
import request from 'axios';
import {InvoiceItem} from "../dispatching/Invoice";
import {CanView} from "../Main";
import {LoadingOrRender} from "../../loginsrc/Profile";
import PropTypes from "prop-types";
import store from "../../redux/store";
import { show } from 'react-notification-system-redux';
import DataRowPreview from "../dispatching/DataRowPreview";
import {getCalculation} from "../dispatching/components/PriceCalculation";

const iconMap = {
  0: 'fas fa-plus',
  1: 'fas fa-pen',
  2: 'fas fa-trash',
  3: 'fas fa-ban',
};

const iconColorMap = {
  0: 'green',
  1: 'orange',
  2: 'red',
  3: 'red',
};

const excludeList = [
  'createdAt',
  'updatedAt',
  'id',
  'spaceId',
  'vehicleRaw',
  'userRaw',
  'orderForVis',
  'password',
  'registrationHash',
  'verification',
  'validationHash',
  'validationPasswordHash',
  'contactUserId_a',
  'attributeId',
  'contactUserId',
  'userId_a',
  'foreignApiVehicleId',
];

class ChangesView extends Component {
  getPathString (path) {
    let s = '';
    let i = 0;
    path.forEach(p => {
      s += p;
      i++;
      if (i !== path.length)
        s+= '/'
    });
    return s;
  }
  render() {
    let { item } = this.props;
    return <table className='dataTable'>
      <tbody>
        <tr>
          <th>Položka</th>
          <th>Předtím</th>
          <th>Potom</th>
        </tr>
        {
          Object.keys(item).map((key) =>
            <tr>
              <td>{this.getPathString(item[key].path)}</td>
              <td>{typeof item[key].rhs === 'object' ? <pre>{JSON.stringify(item[key].rhs, null, 2) }</pre> : String(item[key].rhs)}</td>
              <td>{typeof item[key].lhs === 'object' ? <pre>{JSON.stringify(item[key].lhs, null, 2) }</pre> : String(item[key].lhs)}</td>
            </tr>
          )
        }
        {/*<tr>

          <td>
            {<pre>{JSON.stringify(item, null, 2) }</pre>}
          </td>
        </tr>*/}
      </tbody>
    </table>
  }
}

class DeletedView extends Component {
  render() {
    let { item } = this.props;
    return <table className='dataTable'>
        <tbody>
          <tr>
            {
              item ?
              Object.keys(item).map(key => {
                if (excludeList.includes(key))
                  return null;
                return <th key={key}>
                  {key}
                </th>
              }) : 'Nelze zobrazit preview'
            }
          </tr>
          <tr>
            {
              item ?
                Object.keys(item).map(key => {
                  if (excludeList.includes(key))
                    return null;
                  return <td key={key}>
                    {typeof item[key] === 'object' ? <pre>{JSON.stringify(item[key], null, 2) }</pre> : String(item[key])}
                  </td>
                }) : 'Nelze zobrazit preview'
            }
          </tr>
        </tbody>
      </table>

  }
}

class DataRowLog extends Component {

  render() {
    const { item } = this.props;
    if (!item)
      return 'Nelze zobrazit náhled';
    return <div>
        <table className='dataTable'>
          <tbody>
            <HeaderGenerator selectedDate={moment(item.date)} attribute={item.attribute} unresolvedMode/>
            <DataRowPreview item={item} isForLog priceAttributes={this.props.otherProps['/items/attributes/price']}/>
          </tbody>
        </table>
      </div>
  }
}

class InvoicePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataRows: [],
      requestActive: false,
      canceled: false,
      reason: '',
      friendInvoice: null,
    };
    this.priceAttributes = [];
  }

  loadDataRows() {
    const { item } = this.props;
    this.setState({requestActive: true});
    request.get('/items/datarowsForInvoice', {params: {
        where: {id: item.description.ids}
      }})
      .then((res) => {
          const dataRows = res.data;

          request.get('/items/attributes/price')
            .then(async (res) => {
              this.priceAttributes = res.data;
              this.materialAttributes = await request.get('/items/attributes/material');
              this.materialAttributes = this.materialAttributes.data;
              this.storeAttributes = await request.get('/items/attributes/store');
              this.storeAttributes = this.storeAttributes.data;
              dataRows.forEach(row => {
                row.calculation = getCalculation(row, {
                  attribute: row.attribute,
                  priceAttributes: this.priceAttributes,
                  materialAttributes: this.materialAttributes,
                  storeAttributes: this.storeAttributes,
                  defaultMaterialTable: row.order ? row.order.other.materialTable : undefined,
                });
              })
              this.setState({
                requestActive: false,
                dataRows,
              })
            })
            .catch(err => {
              this.setState({ requestActive: false });
            });
      }).catch(err => {
        this.setState({ requestActive: false });
      });
  }

  componentWillUpdate(nextProps, nextState, nextContext) {
    if (this.props.item.actionType === 3 && nextProps.item.actionType !== 3) {
      this.setState({canceled: false, reason: ''});
    }
  }

  componentDidMount() {
    const { item } = this.props;
    if (item.description.friendLogId) {
      request.get('/items/logs', {
        params: { where: { id: item.description.friendLogId}, allSpaces: true}
      }).then(res => this.setState({ friendInvoice: res.data.length > 0 ? res.data[0] : null }))
    }
  }

  cancelInvoice() {
    this.setState({requestActive: true});
    const { item } = this.props;
    request.post('/items/pohoda/invoicestorno', {
      reason: this.state.reason,
      itemId: item.itemId,
      id: item.id,
      spaceId: item.spaceId,
    }).then((res) => {
      if(res.status === 200) {
        if(this.props.onCanceled)
          this.props.onCanceled();
        this.setState({requestActive: false, canceled: true});
      }
    }).catch(err => {
      store.dispatch(show({ title: 'Chyba', level: 'error', autoDismiss: 3,
        children: (
          <div>
            Nepodařilo se stornovat fakturu, pravděpodobně faktura už byla stornována nebo nelze komunikovat s účetním serverem
          </div>
        ),
      }, 'error'));
      this.setState({requestActive: false});
    })
  }

  render() {
    const { item } = this.props;
    const { dataRows, friendInvoice, invoicePreviewExisting } = this.state;
    let disableStorno = false;
    if (friendInvoice) {
      if (friendInvoice.actionType !== 3)
        disableStorno = true;
    }
    return <div className='fixHowirizonalScroll'>
      {friendInvoice && <Modal dialogClassName="modal-90w" show={!!invoicePreviewExisting}
                               onHide={() => this.setState({invoicePreviewExisting: null})}>
        <Modal.Header closeButton>
          {invoicePreviewExisting ? <h3 className='text-center'>{`Faktura ${invoicePreviewExisting.description.number}`}</h3> : null}
        </Modal.Header>
        <Modal.Body>
          {invoicePreviewExisting ? <InvoicePreview onCanceled={ () => {
            friendInvoice.actionType = 3;
            this.setState({invoicePreviewExisting: null, friendInvoice});
          }}
                                                    item={invoicePreviewExisting}/> : null}
        </Modal.Body>
      </Modal>}
      <Row>
        <Col sm={2}>
          {item.itemId > 0 ?  <LoadingOrRender requestActive={this.state.requestActive}>
            {item.actionType !== 3 && !this.state.canceled ? <>
              <textarea style={{ width: '100%', minHeight: '140px', padding:'10px'}}
                        placeholder="Popiště důvod storna!!"
                        onChange={(e) => {
                          this.setState({reason: e.target.value});
                        }}
                        value={this.state.reason} />
              {disableStorno && <div style={{color: 'red', fontWeight: 'bold'}}>Nejprve stornujte výslednou fakturu</div>}
              <Button disabled={this.state.reason === '' || disableStorno} onClick={() => this.cancelInvoice()}>
                Stornuj fakturu
              </Button>
            </> : <div style={{color: 'red', fontWeight: 'bold'}}>Stornováno</div>}
            {
              <Button onClick={() => this.loadDataRows()}>
                Načíst řádky
              </Button>
            }
          </LoadingOrRender> : <Button onClick={() => this.loadDataRows()}>
            Načíst řádky
          </Button>}

        </Col>
        <Col sm={2}>
          <FormGroup>
            <ControlLabel>
              Číslo faktury
            </ControlLabel>
            <div>
              <a target="_blank" href={`${getBaseUrl()}/items/pohoda/print?id=${item.itemId}&agenda=vydane_faktury&printId=190`}>
                {item.description.number}
              </a>
            </div>
          </FormGroup>
          {
            friendInvoice && <FormGroup>
              <ControlLabel>
                Číslo faktury výsledné
              </ControlLabel>
              <div>
                {friendInvoice.actionType === 3 && <div style={{color: 'red', fontWeight: 'bold'}}>Stornováno</div>}
                <button onClick={() => this.setState({invoicePreviewExisting: friendInvoice})}>
                  {friendInvoice.description.number}
                </button>

              </div>
            </FormGroup>
          }
        </Col>
        {item.itemId > 0 && <Col sm={2}>
          <FormGroup>
            <ControlLabel>
              Odběratel
            </ControlLabel>
            <div>
              {item.description.addressBook.companyWithIc}
            </div>
          </FormGroup>
        </Col>}
        <Col>
          <FormGroup>
            <ControlLabel>
              Text
            </ControlLabel>
            <div>
              {item.description.invoice.text}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <LoadingOrRender requestActive={this.state.requestActive}>
        {
          dataRows.length === 0 ?
            null : <div className='dataTable' style={{ marginTop: '10px',
              width: '100%',
              maxHeight: '300px',
              marginBottom: '10px',
              overflowY: 'auto',
              fontSize: '11px' }}>
              <table >
                <tbody>
                <tr>
                  <th></th>
                  <th>Datum</th>
                  <th>Text</th>
                  <th>M.J</th>
                  <th>Množství</th>
                  <th>Cena / J</th>
                  <th>Cena / M</th>
                  <th>Náklady / J</th>
                  <th>Náklady / M</th>
                  <th>Zisk</th>
                </tr>
                {
                  Object.keys(dataRows).map(key =>
                    <InvoiceItem item={dataRows[key]}
                                 attributes={this.priceAttributes}/>
                  )
                }
                </tbody>
              </table>
            </div>
        }
      </LoadingOrRender>
    </div>
  }
}

InvoicePreview.propTypes = {
  item: PropTypes.object.isRequired,
  onCanceled: PropTypes.func,
};


const previewArray = {
  datarow: DataRowLog,
  addressBook: AddressBookPreview,
  attendance: AttendancePreview,
  attributes: AttributePreview,
  roles: RoleView,
  sms: SmsView,
  tasks: TaskPreview,
  tools: ToolView,
  users: UserPreview,
  vehicles: VehiclePreview,
  foreignComInvoice: InvoicePreview,
};

const previewItem = {
  'users': 'Uživatel',
  'vehicles': 'Vozidlo',
  'attendance': 'Docházka',
  'tasks': 'Úkol',
  'tools': 'Vybavení',
  'sms': 'sms',
  'datarow': 'Řádek',
  'addressBook': 'Záznam adresáře',
  'attributes': 'Atribut',
  'roles': 'Role',
  'foreignComInvoice': 'Vydaná faktura',
};

export default class LogView extends Component {

  render() {
    let { item } = this.props;

    let preview = 'Náhled není k dispozici';
    if (item[item.tableName]) {
      preview = React.createElement(previewArray[item.tableName], {
        item: item[item.tableName],
        isForLog: true,
        otherProps: this.props,
      })
    } else if (item.tableName === 'foreignComInvoice'){
      preview = <InvoicePreview item={item} />;
    } else if (item.actionType === 2) {
      preview = <DeletedView item={item.description}/>;
    }

    return (<div>
      <span>
        <FontAwesome style={{fontSize:'18px', color: iconColorMap[item.actionType]}} name={iconMap[item.actionType]} />
      </span>
      <span style={{marginLeft: '15px'}}>
       {moment(item.createdAt, dateFromat + ' HH:mm:ss').format(dateFormatVisible + ' HH:mm:ss')}
      </span>
      <span style={{marginLeft: '15px'}}>
        {
          item.user ? <SmallUserPreview style={{display: 'inline-block'}} item={item.user}/> : 'Server'
        }
      </span>
      {/*<ChangeView item={item.actionType < 2 ? item[item.tableName] : item.description}/>*/}
      <h5 style={{marginTop: '10px', fontWeight: 'bold'}}>{previewItem[item.tableName]}</h5>
      <div className='previewBox'>
        {preview}
      </div>
      {
        item.actionType === 1 ?
          <>
            <h5 style={{marginTop: '10px', fontWeight: 'bold'}}>Tabulka změn:</h5>
            <ChangesView item={item.description}/>
          </>
           : null
      }

      </div>)
  }
}

LogView.propTypes = BaseViewPropTypes;

export {
  InvoicePreview
}
