import {
  getAddressBook,
  getAttributes, getCompanies, getConstructions, getOrders,
  getTools,
  getUsersForDispatching,
  getUsersWithSpecificRoleWhere,
  getVehicles
} from "../../fetchApi/dispatching";
import {dateFormatVisible, dateFromat} from "../../AppObjects";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import moment, {Moment} from "moment";
import ContentWithFixedHeader from "../ContentWithFixedHeader";
import { useHistory } from "react-router";
import {BackHeader} from "../Setting";
import { Row, Col } from "react-bootstrap/lib";
import {AddressBook, Attributes, EDataRow, Orders, Roles, Users, Vehicles} from "@cml/models";
import HeaderGenerator from "./components/HeaderGenerator";
import request from "axios";
import { DataRow as DataRowModel } from '@cml/models';
import DataRowContainer from "./DataRowContainer";
import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {
  AuthenticateState, clearIdsToDelete,
  DataRowsState,
  pushDataRow,
  setActualRowChanging, setChangesMade,
  setDataRows
} from "@cml/redux-store";
import {DispatchingRights} from "@cml/types/dist/types/roles";
import {IDispatchingAttributesCustom, ISmsInfo} from "@cml/types";
import DateSelector from "./components/DateSelector";
import LoadFromPreviousDay from "./components/LoadFromPreviousDay";
import {show} from "react-notification-system-redux";
import store from "../../redux/store";
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { NewItemAddedContextProvider } from "../ItemsSelector";
import DispatchingLog from "./components/DispatchingLog";
import {RouteComponentProps} from "react-router-dom";
import DispatchingFilter, {IFilter, IFilterOrder, dropDownOrder} from "./components/DispatchingFilter";
import ReactLoading from "react-loading";
import SaveButton from "./components/SaveButton";
import queryString from 'qs';
import GPSLoadButton from "./components/GPSLoadButton";
import {createDataRow} from "./components/datarowBase";
import OrderDialog from "./OrderDialog";

function dataLoader({ selectedDate }: {selectedDate: Moment}) {
  const swrOption = {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  }

  const { users, isValidating: usersValidating, revalidate: usersRevalidate } = getUsersForDispatching(
    { params: { date: selectedDate.format(dateFromat) }}, swrOption);
  const { contactUsers, isValidating: contactValidating, revalidate: contactsRevalidate } = getUsersWithSpecificRoleWhere(
    { params: { roleWhere: { isContactUser: 1 }}},swrOption);
  const { vehicles, isValidating: vehiclesValidating, revalidate: vehiclesRevalidate } =
    getVehicles({params: { allDetails: 0, onlyForDispatching: 1  }}, swrOption);
  const { tools, isValidating: toolsValidating } = getTools({ params: { where: { isAvailable: 1 } }}, swrOption)
  const { attributes, isValidating: attributesValidating } = getAttributes('dispatching', swrOption);
  const { attributes: priceAttributes, isValidating: priceValidating } = getAttributes('price', swrOption);
  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes('material', swrOption);
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes('store', swrOption);
  const { addressBook, isValidating: addressValidating, revalidate: addressBookRevalidate } = getAddressBook(swrOption);
  const { orders, isValidating: ordersValidating, revalidate: ordersRevalidate } = getOrders({ params: { where: { isConfirmed: 0 }}}, swrOption);
  getCompanies(swrOption);
  getConstructions(swrOption);

  const loading = !users || !contactUsers || !vehicles || !tools || !attributes || !orders
    || !priceAttributes || !storeAttributes || !materialAttributes || !addressBook;

  const validating = usersValidating || contactValidating || vehiclesValidating || ordersValidating ||
    attributesValidating || materialValidating || addressValidating || storeValidating ||
    toolsValidating || priceValidating;

  if (loading)
    return {
      users: [],
      contactUsers: [],
      vehicles: [],
      tools: [],
      attributes: [],
      priceAttributes: [],
      materialAttributes: [],
      storeAttributes: [],
      addressBook: [],
      orders: [],
      validating,
      loading,
      ordersRevalidate,
      addressBookRevalidate,
      vehiclesRevalidate,
      contactsRevalidate,
      usersRevalidate,
    }

  return {
    users,
    contactUsers,
    vehicles,
    tools,
    attributes,
    priceAttributes,
    materialAttributes,
    storeAttributes,
    addressBook,
    orders,
    validating,
    loading,
    ordersRevalidate,
    addressBookRevalidate,
    vehiclesRevalidate,
    contactsRevalidate,
    usersRevalidate,
  }
}

export default function Dispatching(props: RouteComponentProps) {

  const dataRowsIds = useSelector((state: {dispatching: DataRowsState}) => state.dispatching.dataRowsIds);

  const [dataLoading, setDataLoading] = useState(false);
  const [selectedDate, setSelectedData] = useState(moment().locale('cs'));
  const [submitActive, setSubmitActive] = useState<boolean>(false);
  const [actualRowIsChangingIndex, setActualRowIsChangingIndex] = useState(-1);
  const [logItemId, setLogItemId] = useState<number | null>(null);
  const history = useHistory();

  const lastFilter = useRef<IFilter | undefined>(undefined);
  const lastOrdering = useRef<IFilterOrder | undefined>(undefined);

  const [idsToDelete, setIdsToDelete] = useState<number[]>([]);
  const elapsedHoursFromCreation = selectedDate ? moment().diff(moment(selectedDate.format(dateFromat), dateFromat), 'hours') : 0;

  const authenticate = useSelector(
    (state: { authenticate: AuthenticateState}) => state.authenticate);

  const dispatchingRole = (authenticate.actualUserRole as Roles).dispatching as DispatchingRights;

  useEffect(() => {
    store.dispatch(setChangesMade(0));
    store.dispatch(setDataRows([]));
    return () => {
      store.dispatch(setDataRows([]));
    }
  }, [])


  const {
    users,
    attributes,
    addressBook,
    vehicles,
    orders,
    ordersRevalidate,
    addressBookRevalidate,
    contactsRevalidate,
    usersRevalidate,
    vehiclesRevalidate,
    loading,
    validating
  } = dataLoader({selectedDate})

  useEffect(() => {
    if(!loading)
      loadDataRows(selectedDate, false, lastFilter.current);
  }, [selectedDate, !users, loading])

  const sortRows = (dataRows: DataRowModel[], byProp_: string | undefined, up: boolean) => {
    attributes.forEach(attribute => {
      let byProp = byProp_ ? byProp_
        : (attribute.customOptions as IDispatchingAttributesCustom).baseOrdering;
      let filtered = dataRows.filter(o => o.attributeId === attribute.id);
      if (byProp) {
        filtered = filtered.sort((a,b) => {
          switch (byProp) {
            case 'vehicle':
              const vehA = vehicles.find(o => o.id === a.vehicleId) as Vehicles;
              const vehB = vehicles.find(o => o.id === b.vehicleId) as Vehicles;
              if (typeof vehA === 'undefined' || typeof vehB === 'undefined' )
                return 1;
              if (vehA.identifier < vehB.identifier)
                return up ? -1 : 1;
              if (vehA.identifier > vehB.identifier)
                return up ? 1 : -1;
              break;
            case 'user':
              if (!(attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook) {
                const usA = users.find(o => o.id === a.userId) as Users;
                const usB = users.find(o => o.id === b.userId) as Users;
                if (typeof usA === 'undefined' || typeof usB === 'undefined' )
                  return 1;
                const A = usA.name + ' ' + usA.surname;
                const B = usB.name + ' ' + usB.surname;
                if (A < B)
                  return up ? -1 : 1;
                if (A > B)
                  return up ? 1 : -1;
              } else {
                const A = addressBook.find(o => o.id === a.addressBookId) as AddressBook;
                const B = addressBook.find(o => o.id === b.addressBookId) as AddressBook;
                if (typeof A === 'undefined' || typeof B === 'undefined' )
                  return 1;
                if (A.companyWithIc < B.companyWithIc)
                  return up ? -1 : 1;
                if (A.companyWithIc > B.companyWithIc)
                  return up ? 1 : -1;
              }
              break;
            case 'order':
              const orderA = orders.find(o => o.id === a.orderId) as Orders;
              const orderB = orders.find(o => o.id === b.orderId) as Orders;
              if(!orderA || !orderB)
                return -1;
              if (orderA.label < orderB.label)
                return up ? -1 : 1;
              if (orderA.label > orderB.label)
                return up ? 1 : -1;
              break;
            case 'order_custom':
              const orderA_ = orders.find(o => o.id === a.orderId) as Orders;
              const orderB_ = orders.find(o => o.id === b.orderId) as Orders;
              if(!orderA_ || !orderB_)
                return -1;
              if (orderA_.customNumber < orderB_.customNumber)
                return up ? -1 : 1;
              if (orderA_.customNumber > orderB_.customNumber)
                return up ? 1 : -1;
              break;
            case 'orderForVis':
              break;
            default:
              // @ts-ignore
              if (a[byProp] < b[byProp])
                return up ? -1 : 1;
              // @ts-ignore
              if (a[byProp] > b[byProp])
                return up ? 1 : -1;
              break;
          }
          return 0;
        });
      }
      const filteredOrderForVis = filtered.map(o => o.orderForVis).sort((a,b) => a - b);
      filtered.map((item, index) => {
        const index_ = dataRows.findIndex(o => o === item);
        dataRows[index_].orderForVis = filteredOrderForVis[index];
      });
    })
    store.dispatch(setDataRows(dataRows.sort((a,b) => a.orderForVis - b.orderForVis)));
    //store.dispatch(setChangesMade(1));
  }

  const loadDataRows = (date: Moment, append?: boolean, f?: IFilter) => {
    setActualRowIsChangingIndex(-1);
    setDataLoading(true);
    request.get('/items/datarows',{
      params: {
        where: { date: date.format(dateFromat)},
          ...{
            ...f,
            from: f? (f.from? f.from.format(dateFromat) : undefined) : undefined,
            to: f? (f.to? f.to.format(dateFromat) : undefined) : undefined,
          },
      }
    })
      .then((res) => {

        const body = (res.data as DataRowModel[]).sort((a, b) => {
          if (a.orderForVis === null)
            return 1;
          if (b.orderForVis === null)
            return 0;
          return a.orderForVis - b.orderForVis;
        })
        let rows = store.getState().dispatching.dataRows.slice();
        let counter = 1;
        let offset = rows.length === 0 ?
          0 : Math.max(...rows.map(o => o.orderForVis));
        body.forEach((item) => {
          item.orderForVis = counter;
          counter++;
          if (append) {
            item.invoiceForeignId = null;
            item.orderForVis += offset;
            item.date = selectedDate.format(dateFromat);
            item.id = -counter;
            item.fraction = '';
            item.invoiceForeignId = null;
            item.incomeInvoiceForeignId = null;
            item.salaryId = null;
            if (item.smsInfo) {
              (item.smsInfo as ISmsInfo).contactUserSmsId = null;
            }
            if (!item.orderForeignId || item.orderForeignId === '')
              item.isConfirmed = 0;
          }
        })
        if (append) {
          rows.push(...body);
          store.dispatch(setChangesMade(1));
        }
        else
          rows = body;
        sortRows(rows, lastOrdering.current ? lastOrdering.current.value : undefined, true);
        //store.dispatch(setDataRows(rows));
        setDataLoading(false);
      }).catch(() => setDataLoading(false))
  }

  const idSum = dataRowsIds.reduce((acc, d) => acc += d.id, 0);

  const onNewRow = (attribute: Attributes) => () => {
    let rows = store.getState().dispatching.dataRows.slice();

    let defUser = 0;
    let defVehicle = 0;

    const option = (attribute.customOptions as IDispatchingAttributesCustom);

    if (!option.userAsAddressBook) {
      if (option.canUser) {
        for (let i = 0; i < users.length; i++) {
          if (users[i].attendance.length === 0 && users[i].dataRows.length === 0) {
            const index = rows.findIndex(d => d.userId ===  users[i].id);
            if (index === -1) {
              defUser = users[i].id;
              break;
            }
          }
        }
      }
      if (option.canVehicle) {
        for (let i = 0; i < vehicles.length; i++) {
          const index = rows.findIndex(d => d.vehicleId ===  vehicles[i].id);
          if (index === -1) {
            defVehicle = vehicles[i].id;
            break;
          }
        }
      }
    }

    let newMinId = Math.min(...rows.map(o => o.id));
    newMinId = newMinId > 0 ? -rows.length -1 : newMinId - 1;

    let item = createDataRow({
      id: newMinId, // workaround when new rows are added id causing render, when it is not there render is not called (strange needs to be investigated further)
      userId: defUser,
      orderForVis: rows.length === 0 ?
        1 : Math.max(...rows.map(o => o.orderForVis)) + 1,
      vehicleId: defVehicle,
      attributeId: attribute.id,
      date: selectedDate.format(dateFromat),
    });

    // @ts-ignore
    store.dispatch(pushDataRow((item)));
  }

  const onDragStart = () => {
    store.dispatch(setActualRowChanging(-1));
  }

  const onLogRequested = (index: number) => {
    setLogItemId(dataRowsIds[index].id);
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination || lastFilter.current) {
      return;
    }

    let items = store.getState().dispatching.dataRows.slice();

    const up = result.destination.index < result.source.index;
    const startIndex = result.destination.index;
    const stopIndex = result.source.index;
    //let filtered = state.dataRows.filter(o => o.attributeId === result.destination.droppableId);
    const temp = items[result.destination.index].orderForVis;
    const droppableId = Number(result.destination.droppableId.split('droppable_')[1]);
    if (up) {
      let lastIndex = startIndex;
      for (let i = startIndex + 1; i < stopIndex + 1; i++) {
        if (items[i].attributeId === droppableId) {
          items[lastIndex].orderForVis = items[i].orderForVis;
          lastIndex = i;
        }
      }
    } else {
      let lastIndex = startIndex;
      for (let i = startIndex - 1; i > stopIndex - 1; i--) {
        if (items[i].attributeId === droppableId) {
          items[lastIndex].orderForVis = items[i].orderForVis;
          lastIndex = i;
        }
      }
    }
    store.dispatch(setChangesMade(
      store.getState().dispatching.changesMade === 0 ? 1 : store.getState().dispatching.changesMade));
    //setChangesMade( changesMade === 0 ? 1 : changesMade)
    items[result.source.index].orderForVis = temp;
    store.dispatch(setDataRows(items.sort((a, b) =>
      a.orderForVis - b.orderForVis)));
  }

  const renderAttributes = (attribute: Attributes, index: number) => {
    let indexForColoring = 0;
    const option = (attribute.customOptions as IDispatchingAttributesCustom);
    let byProp = lastOrdering.current ? lastOrdering.current.value
      : (attribute.customOptions as IDispatchingAttributesCustom).baseOrdering;
    return <div style={{ height: '100%' }} key={`attributeSpace${attribute.id}`}>
      <div style={{fontSize: '22px',
        marginLeft: '10px',
        fontWeight: 'bold',
        display: 'inline-block',
        color: attribute.color}}>
        {`${attribute.name}`}
      </div>
      <div style={{fontSize: '11px',
        opacity: 0.7,
        marginLeft: '10px',
        fontWeight: 'bold',
        display: 'inline-block',
        color: attribute.color}}>{`řazeno dle ${dropDownOrder[byProp] ? dropDownOrder[byProp] : '???' }`}</div>
      <DragDropContext onDragStart={onDragStart}
                       onDragEnd={onDragEnd}
      >
        <Droppable droppableId={`droppable_${attribute.id}`}>
          {(droppableProvided, droppableSnapshot) => (
            <table
              ref={droppableProvided.innerRef}
              onContextMenu={(e) => {
                e.preventDefault();
              }}>
              <tbody>
              <HeaderGenerator key={'head' + attribute.id}
                               singleRowEditMode={false}
                               unresolvedMode={false}
                               selectedDate={selectedDate}
                //onOrderRequired={(up, byProp) => onOrderRequired(up, byProp, attribute)}
                               attribute={attribute} onOrderRequired={() => {}}/>
              {
                dataRowsIds.map((dataRow, index) => {
                  if (dataRow.attributeId === attribute.id)
                    indexForColoring++;
                  // @ts-ignore
                  dataRow.indexForColoring = indexForColoring;

                  return <DataRowContainer
                    indexForColoring={indexForColoring}
                    showDate={checkFilterDates()}
                    attribute={attribute}
                    onLogRequested={onLogRequested}
                    // @ts-ignore
                    dataRow={dataRow}
                    index={index}
                  />

                })
              }
              <tr key={'button' + attribute.id}>
                <td colSpan={10} className='transparent'>
                  <div className='text-center'>
                    <Button
                      disabled={(!dispatchingRole.delete && option.enableDeletePeriod < elapsedHoursFromCreation)
                      || (!dispatchingRole.update && option.enableDeletePeriod >= elapsedHoursFromCreation)}
                      onClick={onNewRow(attribute)}
                    >
                      Nový řádek
                    </Button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  }



  const saveHandle = () => {
    setSubmitActive(true);
    const rows = store.getState().dispatching.dataRows.slice();
    const f = lastFilter.current;
    request.post('/items/datarows', {
      dataRows: rows.map((row) => {
        const r = {
          ...row,
        }
        if(r.id < 0)
          delete r.id;
        return r;
      }),
      idsToDelete: store.getState().dispatching.idsToDelete,
      query: queryString.stringify({
        where: { date: selectedDate.format(dateFromat)},
        ...{
          ...f,
          from: f? (f.from? f.from.format(dateFromat) : undefined) : undefined,
          to: f? (f.to? f.to.format(dateFromat) : undefined) : undefined,
        },
      }),
    })
      .then((res) => {
        setSubmitActive(false);
        if (res.status === 200) {
          let body = res.data;
          store.dispatch(setChangesMade(0));
          store.dispatch(clearIdsToDelete());
          sortRows((body as DataRowModel[])
            .sort((a,b) => a.orderForVis - b.orderForVis),
            lastOrdering.current ? lastOrdering.current.value : undefined, true);
          /*store.dispatch(setDataRows((body as DataRowModel[])
            .sort((a,b) => a.orderForVis - b.orderForVis)));*/
          setIdsToDelete([]);
          setActualRowIsChangingIndex(-1);
        }
      })
      .catch((err) => {
        setSubmitActive(false);
        if (err.status === 409) {
          store.dispatch(show({
            title: 'Chyba !',
            level: 'error',
            autoDismiss: 7,
            children: (
              <div>
                {`Číslo zakázky: "${err.response.data.orderNumber}" již existuje,
                musíte použít jiné číslo nebo políčko nechat prázdné !`}
              </div>
            ),
          }, 'error'));
        } else {
          store.dispatch(show({
            title: 'Chyba !',
            level: 'error',
            autoDismiss: 15,
            children: (
              <div>
                {`Nastala chyba při komunikaci se serverem! error: ${err.status}`}
              </div>
            ),
          }, 'error'));
        }
      })
  }

  const onLoadDateSelected = (date: Moment) => {
    loadDataRows(date, true);
  }

  const itemAddedHandler = {
    onNewItemAdd: (itemToFetch: string) => {
      switch (itemToFetch) {
        case 'orders':
          ordersRevalidate();
          break
        case 'addressBook':
          addressBookRevalidate();
          break;
        case 'users':
          contactsRevalidate();
          usersRevalidate();
          break;
        case 'vehicles':
          vehiclesRevalidate();
          break;
      }
    }
  }

  const onDispatchingFilterChanged = (f?: IFilter, o?: IFilterOrder) => {
    if(store.getState().dispatching.changesMade === 0) {
      lastFilter.current = f;
      lastOrdering.current = o;
      loadDataRows(selectedDate, false, f);
    } else {
      store.dispatch(show({
        title: 'Chyba !',
        level: 'error',
        autoDismiss: 2,
        children: (
          <div>
            {`Filtr lze využít pouze při uloženém stavu`}
          </div>
        ),
      }, 'error'));
    }
  }

  const checkFilterDates = (): boolean => {
    if(lastFilter.current)
      return lastFilter.current.from !== null && lastFilter.current.to !== null;
    return false;
  }

  const lastTime = useRef(Date.now());
  const nowTime = Date.now();
  //console.log(nowTime - lastTime.current);
  lastTime.current = nowTime;

  const rowsView = useMemo(() => {
    if(attributes)
      return attributes.map(renderAttributes);
  }, [idSum, !attributes, dataLoading,
    idsToDelete.length, dataRowsIds,
    loading, actualRowIsChangingIndex])

  return <div >
    <DispatchingLog itemId={logItemId} match={props.match} onHide={() => setLogItemId(null)}/>
    <ContentWithFixedHeader header={
      <Row>
        <Col sm={2}>
          <div >
            <BackHeader
              fontSize='18px'
              headerText={'Dispečink'}
              onBackClick={() => history.replace('/')}/>
          </div>
        </Col>
        <Col sm={2}>
          {checkFilterDates() ? <div style={{ marginTop: '7px', fontWeight: 'bold' }}>Datum vybráno z filtru</div>
            : <DateSelector selectedDate={selectedDate} setSelectedData={(date: Moment) => {
            setSelectedData(date);
            store.dispatch(setChangesMade(0));
          }}/>}
        </Col>
        <Col>
          <span>
            {!lastFilter.current && <LoadFromPreviousDay
              selectedDate={selectedDate}
              dateSelected={onLoadDateSelected}
              dispatchingRole={dispatchingRole}/>}
          </span>
          <GPSLoadButton selectedDate={selectedDate} />
          <SaveButton filterUsed={ !!lastFilter.current } onSave={saveHandle} selectedDate={selectedDate} onLoadRequested={() => {
            loadDataRows(selectedDate, false, lastFilter.current);
            store.dispatch(setChangesMade(0));
          }}/>
        </Col>
      </Row>
      }>

      <NewItemAddedContextProvider value={itemAddedHandler}>
        <div style={{ paddingTop: '50px' }} className='dispatchingTable'>
          {(loading || validating || dataLoading) && <ReactLoading type={'spinningBubbles'} color={'#337ab7'} height='30px' width='30px'/>}
          {(loading || validating) && (validating ? 'Validating' : 'Loading')}
          {!loading && rowsView}
        </div>
      </NewItemAddedContextProvider>


    </ContentWithFixedHeader>
    <DispatchingFilter
      orderingEnabled
      onFilterChanged={onDispatchingFilterChanged}/>
  </div>

}