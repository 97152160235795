import {LoadingOrRender} from "../../../loginsrc/Profile";
import {Dropdown, MenuItem} from "react-bootstrap";
import {BaseCalculationItem, IDispatchingAttributesCustom, Material} from "@cml/types";
import {getStore, rowStates, rowStatesSupp} from "../components/datarowBase";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {dateFormatVisible, dateFromat, getTimeString} from "../../../AppObjects";
import request from "axios";
import {show} from "react-notification-system-redux";
import {Attributes, DataRow, Vehicles, Users, Orders} from "@cml/models";
import {useSelector} from "react-redux";
import {AuthenticateState, DataRowsState, setDataRowValue} from "@cml/redux-store";
import {
  getAttributes, getOrders,
  getUsersForDispatching,
  getUsersWithSpecificRoleWhere,
  getVehicles
} from "../../../fetchApi/dispatching";
import {swrOption} from "../DataRow";
import store from "../../../redux/store";
import {selectDataRowValue} from "./selector";
import MaterialTable from "../components/MaterialTable";
import {getValuesOfCustomNumber} from "../../utils/NumberQueue";
import OrderDialog from "../OrderDialog";

interface Props {
  index: number,
  attribute: Attributes,
  onOrderRequired: (index: number) => void;
  singleRowEditMode: boolean;
}

export function getSmsTextPrice(text: string, data: DataRow, priceAttributes: Attributes[]) {
  if (data.priceEq) {
    text = text.replace('%price%',
      (data.priceEq as BaseCalculationItem[]).map(o => {
        const a = priceAttributes.find(p => p.id === o.attributeId);
        return `${a ? a.name : ' - '}/${o.price}`
      }).join(', '));
  }
  return text;
}

export function getSmsTextSenderUser(text: string, user: Users) {
  return text.replace('%suser%', `${user.nameSurname}`);
}

export function getSmsText(
                           text: string,
                           data: DataRow,
                           vehicle: Vehicles | Vehicles[],
                           oneStore: Attributes | undefined | null,
                           user: Users | Users[],
                           order: Orders | null,
                           contactUser: Users | undefined) {

  text = text.replace('%date%', moment(data.date, dateFromat).format(dateFormatVisible));
  text = text.replace('%on%', `${data.orderNumber}`);
  text = text.replace('%onf%', `${order ? order.receivedOrderNumber : 'neznámo'}`)
  text = text.replace('%oml%', `${order ? order.meetingLocation : 'neznámo'}`)


  const positions =
    // @ts-ignore
    getValuesOfCustomNumber((store.getState().authenticate as AuthenticateState).activeSpace.setting.ordersNumberTemplate,
      (order && order.customNumber) ? order.customNumber : '');


  while(text.indexOf('%onc') > -1) {
    const index = text.indexOf('%onc');
    let posIndex = -1;
    let actIndex = index + '%onc'.length;
    while(posIndex === -1 && actIndex < text.length) {
      if(text[actIndex] === '%') {
        posIndex = parseInt(text.substring(index + '%onc'.length, actIndex))
      }
      actIndex++
    }
    if(isNaN(posIndex))
      break;
    text = text.replace(`%onc${posIndex}%`,
      `${positions ? (posIndex < positions.length ? positions[posIndex].value : 'neznámo') : 'neznámo'}`)
  }

  text = text.replace('%veh%', `${Array.isArray(vehicle) ? 
    vehicle.map(o => o.identifier).join(', ')  : vehicle.identifier}`);
  text = text.replace('%user%', Array.isArray(user) ?
    `${user.map(u => u.nameSurname + ': ' + u.telnumb).join(', ')}` : `${user.name} ${user.surname}`);
  text = text.replace('%load%', `${oneStore ? oneStore.name : data.loading}`);
  text = text.replace('%unlo%', `${data.unloading}`);
  text = text.replace('%com%', `${data.company}`);
  text = text.replace('%con%', `${data.construction}`);
  text = text.replace('%cusn%', ` ${contactUser ? contactUser.name : 'NA'} ${contactUser ? contactUser.surname : 'NA'}`);
  text = text.replace('%cust%', `${contactUser ? contactUser.telnumb : 'NA'}`);
  text = text.replace('%tf%', `${getTimeString(data.from)}`);
  text = text.replace('%tt%', `${getTimeString(data.to)}`);
  text = text.replace('%brea%', `${getTimeString(data.break)}`);
  //text = text.replace('%frac%', `${data.fraction}`);
  text = text.replace('%note%', `${data.note}`);
  return text
}

export default function DrState({index, attribute, onOrderRequired, singleRowEditMode}: Props) {
  const [requestActive, setRequestActive] = useState(false);
  const [orderDialogShow, setOrderDialogShow] = useState(false);

  const isConfirmed = useSelector(selectDataRowValue(index, singleRowEditMode, 'isConfirmed'));

  const userId = useSelector(selectDataRowValue(index, singleRowEditMode, 'userId'));

  const contactUserId = useSelector(selectDataRowValue(index, singleRowEditMode, 'contactUserId'));

  const date = useSelector(selectDataRowValue(index, singleRowEditMode, 'date'));

  const { orders, isValidating: ordersValidating } = getOrders(
    { params: { where: { isConfirmed: 0 }}}, );

  const { users, isValidating: usersValidating } = getUsersForDispatching(
    { params: { date: date }}, singleRowEditMode
      ? swrOption : undefined);

  const { contactUsers, isValidating: contactValidating } = getUsersWithSpecificRoleWhere(
    { params: { roleWhere: { isContactUser: 1 }}}, );

  const { attributes: priceAttributes,
    isValidating: priceValidating } = getAttributes(
    'price' );
  const { attributes: materialAttributes, isValidating: materialValidating } = getAttributes(
    'material' );
  const { attributes: storeAttributes, isValidating: storeValidating } = getAttributes(
    'store' );

  const { vehicles, isValidating: vehiclesValidating } = getVehicles(
    {params: { allDetails: 0, onlyForDispatching: 1  }}, );

  if(!vehicles || !users || !contactUsers || !orders)
    return <div>Loading</div>;

  const handleStateChange = (key: string) => {
    let _key = parseInt(key, 10);

    if (((attribute.customOptions as IDispatchingAttributesCustom) as IDispatchingAttributesCustom)
      .userAsAddressBook && isConfirmed === 0 && _key === 1) {
      onOrderRequired(index);
      setOrderDialogShow(true);
      return;
    }

    if (userId !== 0 &&  isConfirmed === 0 && _key === 1) {
      const data = singleRowEditMode
        ? store.getState().dispatching.singleEditRow as DataRow
        : store.getState().dispatching.dataRows[index];
      let user = users.find(o => o.id === userId);
      if (!user)
        return;
      let contactUser = contactUsers.find(o => o.id === data.contactUserId);
      const vehicle = vehicles.find(o => o.id === data.vehicleId);
      const order = orders.find(o => o.id === data.orderId);
      setRequestActive(true);
      const oneStore = getStore(data, storeAttributes);

      let text = ((attribute.customOptions as IDispatchingAttributesCustom) as IDispatchingAttributesCustom).smsTemplateText;
      text = getSmsText(
        text,
        data, vehicle as Vehicles, oneStore as Attributes, user as Users, order as Orders, contactUser as Users
      )
      text = getSmsTextSenderUser(text, (store.getState().authenticate as AuthenticateState).loggedUser as Users);
      text = getSmsTextPrice(text, data, priceAttributes);
      text = text.replace('%frac%', `${MaterialTable.GenerateString({
        materialTable: data.materialTable as Material[],
        priceAttributes,
        // @ts-ignore
        materialAttributes,
        storeAttributes
      }, true)}`);
      request.post('/items/sms', {
        to: user.telnumb.replace('+', ''),
        text: text,
      }, { timeout: 7000 })
        .then((res) => {
          if (res.status === 200) {
            store.dispatch(setDataRowValue(index, singleRowEditMode, {
              isConfirmed: _key,
            }))
            setRequestActive(false);
            store.dispatch(show({
              title: 'Info',
              level: 'info',
              autoDismiss: 3,
              children: (
                <div>
                  Sms řidiči odeslána
                </div>
              ),
            }, 'info'));
          }
        })
        .catch((error) => {
          setRequestActive(false);
          if(user) {
            store.dispatch(show({
              title: 'Chyba',
              level: 'error',
              autoDismiss: 10,
              children: (
                <div>
                  {`SMS nelze odeslat číslu: ${user.telnumb},zkontrolujte zda číslo existuje. Pokud potíže přetrvají kontaktujte podporu`}
                </div>
              ),
            }, 'error'));
          }
        })
    } else {
      store.dispatch(setDataRowValue(index, singleRowEditMode, {
        isConfirmed: _key,
      }))
    }
  }

  return <td>
    {orderDialogShow && <OrderDialog
      onHide={() => setOrderDialogShow(false)}
      active={orderDialogShow}
      dataRow={store.getState().dispatching.dataRows[index]}
                 onDone={(data, deleted) => {
                   store.dispatch(setDataRowValue(index, singleRowEditMode, {
                     orderForeignId: data.orderForeignId,
                     isConfirmed: data.isConfirmed,
                   }))
                   setOrderDialogShow(false);
                 }}
                 selectedDate={date}/>}
    <LoadingOrRender requestActive={requestActive}>
      <Dropdown
        id={'dispatching-addressbook-select'}
        onSelect={(e: any) => handleStateChange(e)}
      >
        <Dropdown.Toggle>
          <div style={{ fontSize: '11px', display: 'inline' }}>
            {(attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook ?
              rowStatesSupp[isConfirmed] : rowStates[isConfirmed]}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            Object.keys((attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook ? rowStatesSupp : rowStates).map((key) => {
              return <MenuItem key={key} eventKey={key} style={{fontSize: '11px'}}>
                {(attribute.customOptions as IDispatchingAttributesCustom).userAsAddressBook ? rowStatesSupp[Number(key)] : rowStates[Number(key)]}
              </MenuItem>
            })
          }
        </Dropdown.Menu>
      </Dropdown>
    </LoadingOrRender>
  </td>
}