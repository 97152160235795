import React, {Component} from 'react';
import {AutoSizer, Column, Table} from "react-virtualized";
import moment from "moment";
import {dateFormatVisible} from "../../../AppObjects";
import { VehicleDataProps } from './VehicleData';
import request from 'axios';
import { Response } from "superagent";

export interface TanksDetailProps extends VehicleDataProps {
    onRowClick: (index: number) => void;
}

interface TanksDetailState {
    selTankIndex: number;
    vehicles: object[];
}

export default class TanksDetail extends Component<TanksDetailProps, TanksDetailState> {
    constructor(props: TanksDetailProps) {
        super(props);
        this.state = {
            selTankIndex: -2,
            vehicles: [],
        };
    }

    componentDidMount(): void {
        request.get('/items/vehicles', { params: { allDetails: 0 }})
            .then((res) => {
                  this.setState({ vehicles: res.data });
            })
    }

    render() {
        const { tankIndexes, showUnresolved } = this.props;
        const { selTankIndex } = this.state;
        return <AutoSizer disableHeight>
            {({width}) => {
                return (
                    <Table
                        width={width}
                        height={300}
                        headerHeight={30}
                        rowHeight={30}
                        rowStyle={({index}) => ({
                            cursor: 'pointer',
                            fontSize: '12px',
                            backgroundColor: selTankIndex === index ? '#c5ebf0' : index % 2 === 0 ?
                                (tankIndexes[index] ? (tankIndexes[index].unresolved ? 'rgba(255, 0, 0, 0.4)' : '#dddddd') : '') :
                                (tankIndexes[index] ? ( tankIndexes[index].unresolved ? 'rgba(255, 0, 0, 0.2)' : '#ffffff') : '' )
                        })}
                        onRowClick={({ index }) => {
                            this.props.onRowClick(index);
                            this.setState({selTankIndex: index});
                        }}
                        rowCount={tankIndexes.length}
                        rowGetter={({ index }) => tankIndexes[index]}
                    >
                        <Column
                            label='Datum'
                            cellRenderer={(cell) => moment(cell.cellData).format(dateFormatVisible + ' HH:mm:ss') }
                            dataKey='date'
                            width={150}
                        />
                        <Column
                            label='Množství'
                            dataKey='amount'
                            width={120}
                        />
                        <Column
                            label='Místo'
                            dataKey='location'
                            width={180}
                        />
                        <Column
                            label='Produkt'
                            dataKey='name'
                            width={180}
                        />
                    </Table>)}
            }
        </AutoSizer>;
    }
}